import {
  FormBuilderComponentTypes,
  FormBuilderContainers,
  FormBuilderFields,
  FormBuilderConfigInterface,
  NestedFormInstanceInterface,
} from '../FormBuilder/FormBuilderInterfaces';

export const backendValues = {
  formBuilder: {
    // Example
    // responseToTreatment: { text: 'Partial Response', value: 'partial', label: 'Partial Response' },
  },
};

export const backendOptions = {
  formBuilder: {
    numberRangeAndText: [
      {
        text: '0',
        value: '0',
      },
      {
        text: '1',
        value: '1',
      },
      {
        text: '2',
        value: '2',
      },
      {
        text: '3',
        value: '3',
      },
      {
        text: 'text',
        value: 'text',
      },
    ],
    stateToggleButtons: [
      {
        text: 'Enabled',
        value: 'Enabled',
      },
      {
        text: 'Disabled',
        value: 'Disabled',
      },
      {
        text: 'Read Only',
        value: 'Read Only',
      },
    ],
    dropdown: [
      {
        text: '0',
        value: '0',
      },
      {
        text: '1',
        value: '1',
      },
      {
        text: '2',
        value: '2',
      },
      {
        text: '3',
        value: '3',
      },
      {
        text: 'text',
        value: 'text',
      },
    ],
    toggleConditionalFields: [
      {
        text: 'None',
        value: 'None',
      },
      {
        text: 'Children',
        value: 'Children',
      },
      {
        text: 'Siblings',
        value: 'Siblings',
      },
    ],
    childDropdown: [
      {
        text: '0',
        value: '0',
      },
      {
        text: '1',
        value: '1',
      },
      {
        text: '2',
        value: '2',
      },
      {
        text: '3',
        value: '3',
      },
      {
        text: 'text',
        value: 'text',
      },
    ],
    childToggleButtons: [
      {
        text: '0',
        value: '0',
      },
      {
        text: '1',
        value: '1',
      },
      {
        text: '2',
        value: '2',
      },
      {
        text: '3',
        value: '3',
      },
      {
        text: 'text',
        value: 'text',
      },
    ],
    containedChildToggleButtons: [
      {
        text: '0',
        value: '0',
      },
      {
        text: '1',
        value: '1',
      },
      {
        text: '2',
        value: '2',
      },
      {
        text: '3',
        value: '3',
      },
      {
        text: 'text',
        value: 'text',
      },
    ],
    siblingToggleButtons: [
      {
        text: '0',
        value: '0',
      },
      {
        text: '1',
        value: '1',
      },
      {
        text: '2',
        value: '2',
      },
      {
        text: '3',
        value: '3',
      },
      {
        text: 'text',
        value: 'text',
      },
    ],
  },
  containedSiblingToggleButtons: [
    {
      text: '0',
      value: '0',
    },
    {
      text: '1',
      value: '1',
    },
    {
      text: '2',
      value: '2',
    },
    {
      text: '3',
      value: '3',
    },
    {
      text: 'text',
      value: 'text',
    },
  ],
};

export const backendForm = {
  // ---------- FORMBUILDER CONFIG -----------
  formBuilder: {
    name: 'formBuilder',
    label: 'Form Builder',
    components: [
      {
        field: {
          formControl: FormBuilderFields.ToggleButtons,
          dataType: 'text',
          name: 'disabledTrue',
          listDataCategory: undefined,
        },
        label: 'Permanently Disabled',
        dataType: 'text',
        disabled: true,
        readonly: false,
        validators: { required: false },
        tooltip: {
          header: 'Disabled input',
          body: 'Permanantly disabled, never required',
        },
        modifiers: {
          tooltip: {
            header: 'Disabled input',
            body: 'Permanantly disabled, never required',
          },
        },
      },
      {
        type: FormBuilderComponentTypes.Container,
        name: FormBuilderContainers.FormGroup,
        label: 'Controls',
        subComponents: [
          {
            type: FormBuilderComponentTypes.Field,
            field: {
              formControl: FormBuilderFields.ToggleButtons,
              dataType: 'string',
              name: 'stateToggleButtons',
              listDataCategory: 'stateToggleButtons',
            },
            label: 'State',
            validators: { required: true },
            tooltip: {
              header: 'Information',
              body: 'Unfilled (undefined) treated as "enabled" when assessing conditional logic',
            },
            modifiers: {
              // TODO not used by front end
              tooltip: {
                header: 'Information',
                body: 'Unfilled (undefined) treated as "enabled" when assessing conditional logic',
              },
            },
            subComponents: [
              {
                type: FormBuilderComponentTypes.Field,
                field: {
                  formControl: FormBuilderFields.CheckboxTile,
                  dataType: 'boolean',
                  name: 'toggleRequired',
                },
                label: 'Toggle Required',
                visible: { stateToggleButtons: [undefined, 'Enabled'] },
                disabled: { stateToggleButtons: 'Disabled' },
                readOnly: { stateToggleButtons: 'Read Only' },
              },
            ],
          },
        ],
      },
      {
        type: FormBuilderComponentTypes.Container,
        name: FormBuilderContainers.FormGroup,
        label: 'Unconditional Visibility',
        subComponents: [
          {
            type: FormBuilderComponentTypes.Field,
            field: {
              formControl: FormBuilderFields.DatePicker,
              name: 'containedDatePicker',
              dataType: 'date',
            },
            label: 'Date Picker',
            validators: {
              required: { toggleRequired: true },
            },
            tooltip: { header: 'Date Picker', body: 'I own a ham radio' },
            modifiers: { tooltip: { header: 'Date Picker', body: 'I own a ham radio' } }, // TODO not used by front end
          },
          {
            type: FormBuilderComponentTypes.Field,
            field: {
              formControl: FormBuilderFields.Input,
              name: 'rangeInput',
              dataType: 'number',
            },
            label: 'Number Range Input',
            tooltip: { header: 'A header', body: 'Must be a number between 1-2' },
            modifiers: { tooltip: { header: 'A header', body: 'Must be a number between 1-2' } }, // TODO not used by front end
            disabled: { stateToggleButtons: 'Disabled' },
            readOnly: { stateToggleButtons: 'Read Only' },
            validators: {
              required: { stateToggleButtons: [undefined, 'Enabled'], toggleRequired: true },
              range: [1, 2],
            },
          },
          {
            type: FormBuilderComponentTypes.Field,
            field: {
              formControl: FormBuilderFields.ToggleButtons,
              name: 'toggleButtons',
              listDataCategory: 'numberRangeAndText',
              dataType: 'number',
            },
            label: 'Toggle Buttons',
            validators: {
              required: { stateToggleButtons: [undefined, 'Enabled'], toggleRequired: true },
              range: [1, 2],
            },
            disabled: { stateToggleButtons: 'Disabled' },
            readOnly: { stateToggleButtons: 'Read Only' },
            tooltip: { header: 'A header', body: 'Must be a number between 1-2' },
            modifiers: { tooltip: { header: 'A header', body: 'Must be a number between 1-2' } }, // TODO not used by front end
          },
          {
            type: FormBuilderComponentTypes.Field,
            field: {
              formControl: FormBuilderFields.Dropdown,
              name: 'dropdown',
              dataType: 'object',
              listDataCategory: 'numberRangeAndText',
            },
            label: 'Dropdown',
            disabled: { stateToggleButtons: 'Disabled' },
            readOnly: { stateToggleButtons: 'Read Only' },
            validators: {
              required: { stateToggleButtons: [undefined, 'Enabled'], toggleRequired: true },
              range: [1, 2],
            },
            tooltip: { header: 'A header', body: 'Must be a number between 1-2' },
            modifiers: { tooltip: { header: 'A header', body: 'Must be a number between 1-2' } },
          },
          {
            type: FormBuilderComponentTypes.Field,
            field: {
              formControl: FormBuilderFields.CheckboxTile,
              name: 'checkBoxTile',
            },
            label: 'CheckBoxTile',
            dataType: 'string',
            disabled: { stateToggleButtons: 'Disabled' },
            readOnly: { stateToggleButtons: 'Read Only' },
            validators: {
              required: { stateToggleButtons: [undefined, 'Enabled'], toggleRequired: true },
            },
            tooltip: { header: 'A header', body: 'Should not have a label!' }, // won't show up
            modifiers: { tooltip: { header: 'A header', body: 'Should not have a label!' } }, // TODO not used by front end
          },
        ],
      },
      {
        type: FormBuilderComponentTypes.Container,
        name: FormBuilderContainers.FormGroup,
        label: 'Conditional Visibility',
        disabled: { stateToggleButtons: 'Disabled' },
        readOnly: { stateToggleButtons: 'Read Only' },
        subComponents: [
          {
            type: FormBuilderComponentTypes.Field,
            field: {
              formControl: FormBuilderFields.CheckboxTile,
              name: 'toggleContained',
              dataType: 'boolean',
              listDataCategory: 'toggleContained',
            },
            label: 'Toggle Contained',
            disabled: { stateToggleButtons: 'Disabled' },
            readOnly: { stateToggleButtons: 'Read Only' },
            tooltip: {
              header: 'A header',
              body: 'Should remain unaffected by the "Toggle Required" CheckBoxTile',
            },
            modifiers: {
              tooltip: {
                header: 'A header',
                body: 'Should remain unaffected by the "Toggle Required" CheckBoxTile',
              },
            },
          },
          {
            type: FormBuilderComponentTypes.Field,
            field: {
              formControl: FormBuilderFields.ToggleButtons,
              name: 'toggleConditionalFields',
              dataType: 'string',
              listDataCategory: 'toggleConditionalFields',
            },
            label: 'Toggle Conditional Fields',
            validators: { required: true },
            disabled: { stateToggleButtons: 'Disabled' },
            readOnly: { stateToggleButtons: 'Read Only' },
            subComponents: [
              {
                type: FormBuilderComponentTypes.Field,
                field: {
                  formControl: FormBuilderFields.Input,
                  name: 'childRangeInput',
                  dataType: 'number',
                },
                label: 'Number Range Input',
                validators: {
                  range: [1, 2],
                  required: { toggleRequired: true },
                },
                disabled: { stateToggleButtons: 'Disabled' },
                readOnly: { stateToggleButtons: 'Read Only' },
                visible: {
                  toggleConditionalFields: 'Children',
                  toggleContained: [undefined, false],
                },
              },
              {
                type: FormBuilderComponentTypes.Field,
                field: {
                  formControl: FormBuilderFields.DatePicker,
                  name: 'childDatePicker',
                  dataType: 'date',
                },
                label: 'Date Picker',
                visible: {
                  toggleConditionalFields: 'Children',
                  toggleContained: [undefined, false],
                },
                disabled: { stateToggleButtons: 'Disabled' },
                readOnly: { stateToggleButtons: 'Read Only' },
                validators: { required: { toggleRequired: true } },
              },
              {
                type: FormBuilderComponentTypes.Field,
                field: {
                  formControl: FormBuilderFields.CheckboxTile,
                  name: 'childCheckBoxTile',
                  dataType: 'boolean',
                },
                label: 'CheckBoxTile',
                visible: {
                  toggleConditionalFields: 'Children',
                  toggleContained: [undefined, false],
                },
                disabled: { stateToggleButtons: 'Disabled' },
                readOnly: { stateToggleButtons: 'Read Only' },
                validators: { required: { toggleRequired: true } },
              },
              {
                type: FormBuilderComponentTypes.Field,
                field: {
                  formControl: FormBuilderFields.Dropdown,
                  name: 'childDropdown',
                  dataType: 'object',
                  listDataCategory: 'numberRangeAndText',
                },
                label: 'Dropdown',
                visible: {
                  toggleConditionalFields: 'Children',
                  toggleContained: [undefined, false],
                },
                disabled: { stateToggleButtons: 'Disabled' },
                readOnly: { stateToggleButtons: 'Read Only' },
                validators: { required: { toggleRequired: true } },
              },
              {
                type: FormBuilderComponentTypes.Field,
                name: FormBuilderFields.ToggleButtons,
                field: {
                  formControl: FormBuilderFields.ToggleButtons,
                  name: 'childToggleButtons',
                  dataType: 'string',
                  listDataCategory: 'numberRangeAndText',
                },
                label: 'Toggle Buttons',
                visible: {
                  toggleConditionalFields: 'Children',
                  toggleContained: [undefined, false],
                },
                disabled: { stateToggleButtons: 'Disabled' },
                readOnly: { stateToggleButtons: 'Read Only' },
                validators: { required: { toggleRequired: true } },
              },
              {
                type: FormBuilderComponentTypes.Container,
                name: FormBuilderContainers.FormGroup,
                label: 'Children Container',
                disabled: { stateToggleButtons: 'Disabled' },
                readOnly: { stateToggleButtons: 'Read Only' },
                visible: {
                  toggleConditionalFields: 'Children',
                  toggleContained: true,
                },
                subComponents: [
                  {
                    type: FormBuilderComponentTypes.Field,
                    field: {
                      formControl: FormBuilderFields.Input,
                      name: 'containedChildRangeInput',
                      dataType: 'number',
                    },
                    label: 'Number Range Input',
                    validators: {
                      required: { toggleRequired: true },
                      range: [1, 2],
                    },
                    disabled: { stateToggleButtons: 'Disabled' },
                    readOnly: { stateToggleButtons: 'Read Only' },
                    visible: {
                      toggleConditionalFields: 'Children',
                      toggleContained: true,
                    },
                  },
                  {
                    type: FormBuilderComponentTypes.Field,
                    field: {
                      formControl: FormBuilderFields.DatePicker,
                      name: 'containedChildDatePicker',
                      dataType: 'date',
                    },
                    label: 'Date Picker',
                    disabled: { stateToggleButtons: 'Disabled' },
                    readOnly: { stateToggleButtons: 'Read Only' },
                    validators: { required: { toggleRequired: true } },
                    visible: {
                      toggleConditionalFields: 'Children',
                      toggleContained: true,
                    },
                  },
                  {
                    type: FormBuilderComponentTypes.Field,
                    field: {
                      formControl: FormBuilderFields.CheckboxTile,
                      name: 'containedChildCheckBoxTile',
                      dataType: 'boolean',
                    },
                    label: 'CheckBoxTile',
                    disabled: { stateToggleButtons: 'Disabled' },
                    readOnly: { stateToggleButtons: 'Read Only' },
                    validators: { required: { toggleRequired: true } },
                    visible: {
                      toggleConditionalFields: 'Children',
                      toggleContained: true,
                    },
                  },
                  {
                    type: FormBuilderComponentTypes.Field,
                    field: {
                      formControl: FormBuilderFields.Dropdown,
                      name: 'containedChildDropdown',
                      dataType: 'object',
                      listDataCategory: 'numberRangeAndText',
                    },
                    label: 'Dropdown',
                    disabled: { stateToggleButtons: 'Disabled' },
                    readOnly: { stateToggleButtons: 'Read Only' },
                    validators: { required: { toggleRequired: true } },
                    visible: {
                      toggleConditionalFields: 'Children',
                      toggleContained: true,
                    },
                  },
                  {
                    type: FormBuilderComponentTypes.Field,
                    field: {
                      formControl: FormBuilderFields.ToggleButtons,
                      name: 'containedChildToggleButtons',
                      dataType: 'string',
                      listDataCategory: 'numberRangeAndText',
                    },
                    label: 'Toggle Buttons',
                    disabled: { stateToggleButtons: 'Disabled' },
                    readOnly: { stateToggleButtons: 'Read Only' },
                    validators: { required: { toggleRequired: true } },
                    visible: {
                      toggleConditionalFields: 'Children',
                      toggleContained: true,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: FormBuilderComponentTypes.Field,
        field: {
          formControl: FormBuilderFields.Input,
          name: 'requiredSiblingRangeInput',
          dataType: 'number',
        },
        label: 'Number Range Input',
        validators: { range: [1, 2], required: { toggleRequired: true } },
        disabled: { stateToggleButtons: 'Disabled' },
        readOnly: { stateToggleButtons: 'Read Only' },
        visible: {
          toggleConditionalFields: 'Siblings',
          toggleContained: [undefined, false],
        },
      },
      {
        type: FormBuilderComponentTypes.Field,
        field: {
          formControl: FormBuilderFields.DatePicker,
          name: 'requiredSiblingDatePicker',
          dataType: 'date',
        },
        label: 'Date Picker',
        visible: {
          toggleConditionalFields: 'Siblings',
          toggleContained: [undefined, false],
        },
        disabled: { stateToggleButtons: 'Disabled' },
        readOnly: { stateToggleButtons: 'Read Only' },
        validators: { required: { toggleRequired: true } },
      },
      {
        type: FormBuilderComponentTypes.Field,
        field: {
          formControl: FormBuilderFields.CheckboxTile,
          name: 'requiredSiblingCheckBoxTile',
          dataType: 'string',
        },
        label: 'CheckBoxTile',
        visible: {
          toggleConditionalFields: 'Siblings',
          toggleContained: [undefined, false],
        },
        disabled: { stateToggleButtons: 'Disabled' },
        readOnly: { stateToggleButtons: 'Read Only' },
        validators: { required: { toggleRequired: true } },
      },
      {
        type: FormBuilderComponentTypes.Field,
        field: {
          formControl: FormBuilderFields.Dropdown,
          name: 'siblingDropdown',
          dataType: 'object',
          listDataCategory: 'numberRangeAndText',
        },
        label: 'Dropdown',
        visible: {
          toggleConditionalFields: 'Siblings',
          toggleContained: [undefined, false],
        },
        disabled: { stateToggleButtons: 'Disabled' },
        readOnly: { stateToggleButtons: 'Read Only' },
        validators: { required: { toggleRequired: true } },
      },
      {
        type: FormBuilderComponentTypes.Field,
        field: {
          formControl: FormBuilderFields.ToggleButtons,
          name: 'siblingToggleButtons',
          dataType: 'string',
          listDataCategory: 'numberRangeAndText',
        },
        label: 'Toggle Buttons',
        visible: {
          toggleConditionalFields: 'Siblings',
          toggleContained: [undefined, false],
        },
        disabled: { stateToggleButtons: 'Disabled' },
        readOnly: { stateToggleButtons: 'Read Only' },
        validators: { required: { toggleRequired: true } },
      },
      {
        type: FormBuilderComponentTypes.Container,
        name: FormBuilderContainers.FormGroup,
        label: 'Siblings Container',
        disabled: { stateToggleButtons: 'Disabled' },
        readOnly: { stateToggleButtons: 'Read Only' },
        visible: {
          toggleConditionalFields: 'Siblings',
          toggleContained: true,
        },
        subComponents: [
          {
            type: FormBuilderComponentTypes.Field,
            field: {
              formControl: FormBuilderFields.Input,
              name: 'containedSiblingRangeInput',
              dataType: 'number',
            },
            label: 'Number Range Input',
            validators: { range: [1, 2], required: { toggleRequired: true } },
            disabled: { stateToggleButtons: 'Disabled' },
            readOnly: { stateToggleButtons: 'Read Only' },
            visible: {
              toggleConditionalFields: 'Siblings',
              toggleContained: true,
            },
          },
          {
            type: FormBuilderComponentTypes.Field,
            field: {
              formControl: FormBuilderFields.DatePicker,
              name: 'containedSiblingDatePicker',
              dataType: 'date',
            },
            label: 'Date Picker',
            visible: {
              toggleConditionalFields: 'Siblings',
              toggleContained: true,
            },
            validators: { required: { toggleRequired: true } },
            disabled: { stateToggleButtons: 'Disabled' },
            readOnly: { stateToggleButtons: 'Read Only' },
          },
          {
            type: FormBuilderComponentTypes.Field,
            field: {
              formControl: FormBuilderFields.CheckboxTile,
              name: 'siblingCheckBoxTile',
              dataType: 'boolean',
            },
            label: 'CheckBoxTile',
            visible: {
              toggleConditionalFields: 'Siblings',
              toggleContained: true,
            },
            validators: { required: { toggleRequired: true } },
            disabled: { stateToggleButtons: 'Disabled' },
            readOnly: { stateToggleButtons: 'Read Only' },
          },
          {
            type: FormBuilderComponentTypes.Field,
            field: {
              formControl: FormBuilderFields.Dropdown,
              name: 'containedSiblingDropdown',
              dataType: 'object',
              listDataCategory: 'numberRangeAndText',
            },
            label: 'Dropdown',
            visible: {
              toggleConditionalFields: 'Siblings',
              toggleContained: true,
            },
            validators: { required: { toggleRequired: true } },
            disabled: { stateToggleButtons: 'Disabled' },
            readOnly: { stateToggleButtons: 'Read Only' },
          },
          {
            type: FormBuilderComponentTypes.Field,
            field: {
              formControl: FormBuilderFields.ToggleButtons,
              name: 'containedSiblingToggleButtons',
              dataType: 'string',
              listDataCategory: 'numberRangeAndText',
            },
            label: 'Toggle Buttons',
            validators: { range: [1, 2], required: { toggleRequired: true } },
            disabled: { stateToggleButtons: 'Disabled' },
            readOnly: { stateToggleButtons: 'Read Only' },
            visible: {
              toggleConditionalFields: 'Siblings',
              toggleContained: true,
            },
          },
        ],
      },
    ],
  },
};

export function fetchForm(formName: string): NestedFormInstanceInterface {
  const config = backendForm[formName as keyof typeof backendForm];
  const components = config.components;
  const options = backendOptions[formName as keyof typeof backendForm];
  const values = backendValues[formName as keyof typeof backendForm];
  //@ts-ignore
  return { config, components, values, options };
}
