import ArchiveButton from 'op-components/ArchiveButton/ArchiveButton';
import UKNavBar from 'op-components/RO/UKNavBar/UKNavBar';
import SideNavContainer from 'op-components/SideNav/Containers/SideNavContainer';
import NurseSidePatientNav from 'op-components/SideNav/NurseSidePatientNav';
import PSOSidePatientNav from 'op-components/SideNav/PSOSidePatientNav';
import ROSideNav from 'op-components/SideNav/ROSideNav';
import ROSidePatientNav from 'op-components/SideNav/ROSidePatientNav';
import GreetingsSection from 'op-components/SideNav/Sections/GreetingsSection/GreetingsSection';
import RecentPatientSection from 'op-components/SideNav/Sections/RecentPatientSection/RecentPatientSection';
import QuickAccessSectionHeader from 'op-components/SideNav/Sections/SeparateSectionHeaders/QuickAccessSectionHeader';
import SidePatientNav from 'op-components/SideNav/SidePatientNav';
import CreatePatientBasicForm from 'op-pages/OP/CreatePatient/CreatePatientBasicForm';
import ExtendLock from 'shared-components/components/ExtendLock/ExtendLock';
import CovidSubmit from 'shared-components/components/Modals/CovidSubmit/CovidSubmit';
import GenericModal from 'shared-components/components/Modals/GenericModal/GenericModal';
import ModalConsultationDiscussion from 'shared-components/components/Modals/ModalConsultationDiscussion/ModalConsultationDiscussion';
import ModalDeleteForm from 'shared-components/components/Modals/ModalDeleteForm/ModalDeleteForm';
import ModalInfo from 'shared-components/components/Modals/ModalInfo/ModalInfo';
import ModalSaveError from 'shared-components/components/Modals/ModalSaveError/ModalSaveError';
import ModalSaveExit from 'shared-components/components/Modals/ModalSaveExit/ModalSaveExit';
import ModalSubmit from 'shared-components/components/Modals/ModalSubmit/ModalSubmit';
import ModalSubmitForm from 'shared-components/components/Modals/ModalSubmitForm/ModalSubmitForm';
import ModalSubmitValidationError from 'shared-components/components/Modals/ModalSubmitValidationError/ModalSubmitValidationError';
import ModalValidationError from 'shared-components/components/Modals/ModalValidationError/ModalValidationError';
import RecordLockedModal from 'shared-components/components/Modals/RecordLockedModal/RecordLockedModal';
import WelcomeBackModal from 'shared-components/components/Modals/WelcomeBackModal/WelcomeBackModal';
import PageContainer from 'shared-components/components/PageContainer/PageContainer';
import PageTitle from 'shared-components/components/PageTitle/PageTitle';
import SearchFilter from 'shared-components/components/Search/SearchFilter';
import SearchMultiSelectField from 'shared-components/components/Search/SearchMultiSelectField';
import DocumentUpload from './DocumentUpload/DocumentUpload';
import UploadToS3 from './DocumentUpload/UploadToS3';
import ErrorModalContextProvider from './ErrorModalContextProvider/ErrorModalContextProvider';
import FileUploadField from './FileUploadField/FileUploadField';
import DefaultHeader from './HeaderBar/DefaultHeader';
import HeaderBar from './HeaderBar/HeaderBar';
import InternalHeader from './HeaderBar/InternalHeader';
import PatientFacingHeader from './HeaderBar/PatientFacingHeader';
import HeroImage from './HeroImage/HeroImage';
import ModalOptIn from './Modals/ModalOptIn/ModalOptIn';
import ModalPractitionerSearch from './Modals/ModalPractitionerSearch/ModalPractitionerSearch';
import ModalSearchResults from './Modals/ModalSearchResults/ModalSearchResults';
import ModalSelect from './Modals/ModalSelect/ModalSelect';
import AddressAutocomplete from './OP/AddressAutocomplete/AddressAutocomplete';
import AlertHeader from './OP/AlertHeader/AlertHeader';
import AlternateContactDetails from './OP/AlternateContactDetails/AlternateContactDetails';
import CovidNavigatorApollo from './OP/CovidNavigator/CovidNavigatorApollo';
import DTNavigatorApollo from './OP/DTNavigator/DTNavigatorApollo';
import HANavigatorApollo from './OP/HANavigator/HANavigatorApollo';
import MoreInfoText from './OP/MoreInfoText/MoreInfoText';
import NavigationContextProvider from './OP/NavigationContextProvider/NavigationContextProvider';
import PatientFormFooter from './OP/PatientFormFooter/PatientFormFooter';
import PatientSearch from './OP/PatientSearch/PatientSearch';
import Refresh from './OP/Refresh/Refresh';
import RegistrationContainer from './OP/RegistrationContainer/RegistrationContainer';
import RegistrationHomeClinicApollo from './OP/RegistrationContainer/RegistrationHomeClinic/RegistrationHomeClinicApollo';
import RegistrationPatientDetails from './OP/RegistrationContainer/RegistrationPatientDetails/RegistrationPatientDetails';
import RegistrationContextProvider from './OP/RegistrationContextProvider/RegistrationContextProvider';
import RegistrationNavigator from './OP/RegistrationNavigator/RegistrationNavigator';
import ReviewFormsHeader from './OP/ReviewForms/ReviewFormsHeader';
import ReviewFormsTable from './OP/ReviewForms/ReviewFormsTable';
import SummaryCard from './OP/SummaryCard/SummaryCard';
import SummaryRow from './OP/SummaryRow/SummaryRow';
import FormContainer from './OP/UIFormComponents/FormContainer';
import InfoCard from './OP/UIFormComponents/InfoCard';
import NAPageContainer from './OP/UIFormComponents/NAPageContainer';
import PSOPatientCard from './PatientCard/PSOPatientCard';
import PatientCard from './PatientCard/PatientCard';
import ROPatientCard from './PatientCard/ROPatientCard';
import PatientIdentifierBar from './PatientIdentifierBar/PatientIdentifierBar';
import ROBase from './RO/Base/Base';
import RODoseSiteCarousel from './RO/DoseSiteCarousel/DoseSiteCarousel';
import ROMiniRegoHeader from './RO/MiniRegoHeader/MiniRegoHeader';
import ROInlinePatientSearch from './RO/PatientSearch/InlinePatientSearch';
import UKPatientSearchModal from './RO/PatientSearch/UKPatientSearchModal';
import RecentPatients from './RO/RecentPatients/RecentPatients';
import ResubmissionWarningHeader from './RO/ResubmissionWarningHeader/ResubmissionWarningHeader';
import UKContainerStyles from './RO/UKContainerStyles/UKContainerStyles';
import ROSidePanel from './RO/SidePanel/SidePanel';
import UKROStepper from './RO/UKROStepper/UKROStepper';
import ROSummaryCard from './RO/SummaryCard/SummaryCard';
import ROTable from './RO/Table/Table';
import TestInstanceWarning from './RO/TestInstanceWarning/TestInstanceWarning';
import UKPatientCard from './RO/UKPatientCard/UKPatientCard';
import UKSideBar from './RO/UKSideBar/UKSideBar';
import RadioGroupField from './RadioGroupField/RadioGroupField';
import PatientEMR from './SideNav/PatientEMR';
import { StyledArrow, StyledTitle } from './SideNav/common';
import ModalSimulationSubmission from './SimulationSubmission/SimulationSubmission';
import { GET_USER_DETAILS } from './OP/PatientSearch/PatientSearchQueries';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

export const region = REACT_APP_REGION;

export {
  AddressAutocomplete,
  ArchiveButton,
  AlternateContactDetails,
  DTNavigatorApollo,
  CovidNavigatorApollo as CovidNavigator,
  ExtendLock,
  FileUploadField,
  HANavigatorApollo,
  HeaderBar,
  ModalInfo,
  ModalOptIn,
  ModalSaveError,
  ModalSaveExit,
  ModalSearchResults,
  ModalSelect,
  ModalSubmit,
  ModalSimulationSubmission,
  ModalSubmitValidationError,
  ModalPractitionerSearch,
  GenericModal,
  NavigationContextProvider,
  ErrorModalContextProvider,
  UKPatientSearchModal,
  MoreInfoText,
  PageContainer,
  PageTitle,
  PatientSearch,
  PatientFormFooter,
  ReviewFormsHeader,
  Refresh,
  RegistrationContainer,
  RegistrationContextProvider,
  RegistrationNavigator,
  ReviewFormsTable,
  ROBase,
  ResubmissionWarningHeader,
  HeroImage,
  RegistrationPatientDetails,
  TestInstanceWarning,
  SummaryCard,
  SummaryRow,
  ROSideNav,
  ROSidePanel,
  PSOSidePatientNav,
  NurseSidePatientNav,
  ROSidePatientNav,
  SidePatientNav,
  UKPatientCard,
  UKNavBar,
  UKSideBar,
  CovidSubmit,
  RegistrationHomeClinicApollo,
  ROMiniRegoHeader,
  UKContainerStyles,
  ROTable,
  UKROStepper,
  PatientCard,
  PSOPatientCard,
  ROPatientCard,
  RODoseSiteCarousel,
  AlertHeader,
  RadioGroupField,
  ROSummaryCard,
  PatientIdentifierBar,
  InfoCard,
  NAPageContainer,
  FormContainer,
  ModalValidationError,
  ROInlinePatientSearch,
  WelcomeBackModal,
  RecordLockedModal,
  DocumentUpload,
  UploadToS3,
  SearchFilter,
  SearchMultiSelectField,
  InternalHeader,
  PatientFacingHeader,
  DefaultHeader,
  ModalConsultationDiscussion,
  ModalDeleteForm,
  ModalSubmitForm,
  SideNavContainer,
  QuickAccessSectionHeader,
  GreetingsSection,
  RecentPatientSection,
  CreatePatientBasicForm,
  StyledArrow,
  StyledTitle,
  PatientEMR,
  RecentPatients,
  GET_USER_DETAILS,
};
