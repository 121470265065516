import { Tooltip, Typography, useTheme, Stack } from '@mui/material';
import ROContentContainerBody from 'op-components/RO/ContentContainer/ContentContainerBody';
import { CareplanStatusBubble } from 'op-components/SideNav/CareplanStatusBubble';

const renderSelectedDiagnosis = (diagnosisCodeSet: any): JSX.Element => {
  return (
    <>
      <div className="carepath-lhs-status-header">Selected Diagnosis</div>
      {diagnosisCodeSet.map((diagnosis: any, index: number) => {
        const id = `${diagnosis.type}-diagnosis`;
        return (
          <div key={`${diagnosis.code}-${index}`}>
            <Tooltip
              componentsProps={{
                tooltip: { sx: { minWidth: '250px', lineHeight: '1.2rem' } },
              }}
              title={
                <>
                  <span>{diagnosis.type} diagnosis:</span>
                  <br />
                  <span>
                    {diagnosis.code} {diagnosis.name} {(diagnosis.description && diagnosis.description) || '-'}
                  </span>
                </>
              }
              arrow>
              <Typography variant="body2" id={id}>
                {diagnosis.type}: <b>{diagnosis.code} </b> {diagnosis.name}
              </Typography>
            </Tooltip>
          </div>
        );
      })}
    </>
  );
};

interface Props {
  status: string;
  statusText: string;
  diagnosisCodeSet: any;
}

const isCpot = (status: string) => status === 'cpot';

const ROStepperSummary = ({ status, statusText, diagnosisCodeSet }: Props): JSX.Element => {
  const theme = useTheme();
  const { pathname } = window.location;
  const splitPathname = pathname.split('/');
  const dxIndex = splitPathname.includes('diagnosis') ? splitPathname.indexOf('diagnosis') + 1 : null;
  const diagnosisId = dxIndex ? splitPathname[dxIndex] : null;
  return (
    <Stack padding="16px">
      <Typography
        style={{
          color: theme.palette.secondary.dark,
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}>
        Careplan status
      </Typography>
      <Stack direction="row" alignItems="center" gap="16px" style={{ padding: '8px 0' }}>
        <CareplanStatusBubble status={status} />
        <Typography
          data-testid="careplan-status-text"
          style={{ fontSize: '16px', textTransform: 'capitalize', textWrap: 'wrap' }}>
          {isCpot(statusText) ? 'Change prescription on treatment' : statusText}
        </Typography>
      </Stack>
      {pathname.includes('diagnosis') &&
        diagnosisId !== 'create' &&
        diagnosisCodeSet &&
        renderSelectedDiagnosis(diagnosisCodeSet)}
    </Stack>
  );
};

export default ROStepperSummary;
