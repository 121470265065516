// Footer.js
import { styled } from '@mui/system';

const PageHeader = styled('h1')`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
`;

export default PageHeader;
