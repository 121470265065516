import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { HeaderBar, HeroImage } from 'op-components';
import { DistressThermometerVersion, FormStatus } from 'op-enums';
import { PatientHomeNavigation, FeatureOption } from 'op-interfaces';
import { getFormStatus, isUs, toFormToVersion } from 'op-utils';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Modal } from 'shared-components/components';
import { GCButton } from 'shared-components/components/FormFields';
import { GCButtonSize } from 'shared-components/enums';
import { ButtonType } from 'shared-components/enums';
import { DeviceUtilities, navigateToExternalURL } from 'shared-components/utils';
import {
  C19Icon,
  DistressThermometer,
  HealthAssesment,
  RegForm,
  Success,
  TheranosticIcon,
} from 'shared-components/images';
import './PatientNavigation.scss';
import { ProfileNavigation } from './PatientNavigationApollo';
import { useTheme, styled } from '@mui/material';
import { ErrorOutline as ErrorOutlineIcon, Update as UpdateIcon } from '@mui/icons-material';

interface PageLink {
  title: string;
  link: string;
  navigationType: 'react' | 'django';
  status: string;
  icon: JSX.Element;
  disableIfComplete?: boolean;
  isVisible: boolean;
}

const CONTENT = {
  GENERAL: {
    TIPS: {
      TITLE: 'Here are some tips for you:',
      TIPS: [
        'Have your Medicare/DVA/Pacemaker/Pension card ready (if applicable)',
        'Have your medication list ready',
        'If you need any assistance, please see the front desk',
        'Please return the iPad to the front desk once you are finished',
      ],
      TIPS_HOME_REGO: [
        'Have your Medicare/DVA/Pacemaker/Pension card ready (if applicable)',
        'Have your medication list ready',
        'If you need any assistance, please contact your local center ',
        'Session will timeout if more than 15mins inactivity',
      ],
    },
  },
  US: {
    TIPS: {
      TITLE: 'Here are some tips for you:',
      TIPS: [
        ' Have your SSN and Insurance details ready',
        'Have your medication list ready',
        'Please complete as much as you can',
        'If you need any assistance, please see the front desk',
        'Please return the iPad/tablet to the front desk once you are finished',
      ],
      TIPS_HOME_REGO: [
        'Have your SSN and Insurance details ready',
        'Have your medication list ready',
        'Please complete as much as you can',
        'If you need any assistance, please contact your local centre.',
        'Session will time out if more than 15mins inactivity',
      ],
    },
  },
};

const StyledModalContent = styled('div')`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 50px;
`;
const StyledModalContentExit = styled('div')`
  width: 296px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  align-items: center;
  text-align: center;
`;

interface DtForm {
  status: string;
  version: string;
  id: string;
}

interface Props extends RouteComponentProps<{}, any, Location | any> {
  patient: PatientHomeNavigation;
  profile: ProfileNavigation;
  featureOptions: FeatureOption[];
  haStatus: string;
  dtForm: DtForm;
  dtEotForm: DtForm;
  covidStatus: string;
  thaStatus: string;
  logout: (registrationAccessType: string, isDtEot?: boolean) => void;
  getPXInvite: () => void;
}

const PatientNavigation = (props: Props): JSX.Element => {
  const [newPageLinks, setNewPageLinks] = useState<any>([]);
  const [showExitLogoutModal, setShowExitLogoutModal] = useState(false);
  const [intervalVal, setIntervalVal] = useState<NodeJS.Timeout>();
  const [logoutSeconds, setLogoutSeconds] = useState<number>(-1);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const theme = useTheme();
  const {
    profile,
    covidStatus,
    haStatus,
    patient,
    getPXInvite,
    dtForm,
    dtEotForm,
    thaStatus,

    history,
    logout,
    featureOptions,
  } = props;
  const covidEnabled = profile?.patientFeatures?.covidEnabled;
  const isTheranostics = patient.userProfile?.systemState === 'THA';
  const { status: dtInitialStatus, id: distressInitialId } = dtForm;
  const { status: dtEotStatus, id: distressEotId } = dtEotForm;

  const version = toFormToVersion(profile?.toForm);
  const isDtEot = version === DistressThermometerVersion.EOT;
  const isDtInitial = version === DistressThermometerVersion.INITIAL;

  const dtStatus = isDtInitial ? dtInitialStatus : dtEotStatus;
  const distressId = isDtInitial ? distressInitialId : distressEotId;

  // Only show covid if it is enabled or the patient has started the form
  const showCovid = covidEnabled || !!covidStatus;

  const showNewRego = featureOptions.find(
    (featureOption: FeatureOption) => featureOption.name === 'NewAusRego' && featureOption.active,
  );
  const isMobile = DeviceUtilities.isMobileDevice();

  const showDistressThermometer = () => {
    if (isDtInitial) return profile.showDistressThermometer;
    if (isDtEot) return profile.patientFeatures.distressThermometerEotEnabled;
    return false;
  };

  const registrationLink = () => {
    const firstPage = showNewRego ? 'infonotice' : 'basic';
    const section = patient.lastVisitedSection || firstPage;

    if (isUs() || showNewRego) {
      return `/patient/${patient.id}/registration/${section}`;
    }
    return `/registration/${patient.id}/${section}`;
  };

  // Build the page links
  const pageLinks: PageLink[] = [
    {
      title: 'Covid-19 screening',
      status: covidStatus,
      link: `/patient/${patient.id}/covid/information`,
      navigationType: 'react',
      icon: <C19Icon className="link-icon" />,
      disableIfComplete: true,
      // Will not be available for Theranostic patients
      isVisible: !isUs() && showCovid && !isTheranostics,
    },
    {
      title: 'Registration Form',
      status: patient.regFormStatus,
      link: registrationLink(),
      navigationType: 'react',
      icon: <RegForm className="link-icon" />,
      isVisible: profile.showRegistration,
    },
    {
      title: 'Health Assessment',
      status: haStatus,
      link: `/patient/${patient.id}/health/info`,
      navigationType: 'react',
      icon: <HealthAssesment className="link-icon" />,
      isVisible: profile.patientFeatures.healthAssessmentEnabled && profile.showHealthAssessment,
    },
    {
      title: 'Theranostics Assessment',
      status: thaStatus,
      link: '/server/forms/theranostics',
      navigationType: 'django',
      icon: <TheranosticIcon className="link-icon" />,
      isVisible: isTheranostics,
    },
    {
      title: `Distress Thermometer ${isDtEot ? 'EOT' : ''}`,
      status: dtStatus,
      navigationType: 'react',
      link: `/patient/${patient.id}/distress/${distressId}/information`,
      icon: <DistressThermometer className="link-icon" />,
      isVisible: showDistressThermometer(),
    },
  ];

  useEffect((): void => {
    // Check version of distress thermometer to only render that tile if necessary
    let newLinksArr: PageLink[] = [];
    if (isDtEot) {
      pageLinks.forEach((link): void => {
        if (link.title.includes('Distress')) {
          newLinksArr.push(link);
        }
      });
    } else {
      newLinksArr = pageLinks;
    }
    setNewPageLinks(newLinksArr);

    const theranostics = 'THA';
    const queryParams = new URLSearchParams(window.location.search);

    if (
      profile.registrationAccessType !== 'inClinic' &&
      (history.location?.state?.pxRedirect || queryParams.get('theranosticSubmitted')) &&
      profile.overallStatus === FormStatus.REG_REVIEW_REQUIRED
    ) {
      if (patient.emr !== theranostics) {
        getPXInvite();
      } else {
        logout(profile.registrationAccessType);
        history.push({ pathname: '/endSession', state: { isTheranostics: true } });
      }
    }
  }, [patient]);

  const handleStart = () => {
    const interval = setInterval(() => {
      setLogoutSeconds((timer) => timer - 1);
    }, moment.duration(1, 's').asMilliseconds());
    setIntervalVal(interval);
    return interval;
  };

  const handleReset = () => {
    if (intervalVal) clearInterval(intervalVal);
    setLogoutSeconds(-1);
  };

  useEffect(() => {
    if (newPageLinks.length > 0) {
      const areNewPageLinksComplete = newPageLinks
        .filter((item: PageLink) => item.isVisible)
        .every(
          (pageLink: PageLink) =>
            pageLink.status === FormStatus.REG_REVIEW_REQUIRED || pageLink.status === FormStatus.REG_SUBMITTED,
        );

      if (areNewPageLinksComplete) {
        setLogoutSeconds(moment.duration(1, 'm').asSeconds());
        setShowLogoutModal(true);
        const interval = handleStart();
        return () => clearInterval(interval);
      }
    }
  }, [newPageLinks]);

  useEffect(() => {
    if (logoutSeconds === 0) {
      logout(profile.registrationAccessType, isDtEot);
    }
  }, [logoutSeconds]);

  const contents = isUs() ? CONTENT.US : CONTENT.GENERAL;
  const renderTipItems = (): JSX.Element[] => {
    let content = profile.registrationAccessType === 'atHome' ? contents.TIPS.TIPS_HOME_REGO : contents.TIPS.TIPS;

    if (isDtEot) {
      if (profile.registrationAccessType === 'atHome') {
        content = contents.TIPS.TIPS_HOME_REGO.slice(-2);
      } else {
        content = contents.TIPS.TIPS.slice(-2);
      }
    }

    const centre = (
      <a href="https://www.genesiscare.com/au/our-centres/" target="_blank">
        here
      </a>
    );
    return content.map((value, index): JSX.Element => {
      return (
        <li key={index}>
          {value}
          {index === 2 && profile.registrationAccessType === 'atHome' && !isUs() && centre}
        </li>
      );
    });
  };

  const renderStatusIcon = (status: string): JSX.Element => {
    switch (status) {
      case FormStatus.REG_SUBMITTED:
        return <Success className="icon icon-completed" />;
      case FormStatus.REG_REVIEW_REQUIRED:
        return <Success className="icon icon-completed" />;
      case FormStatus.IN_PROGRESS:
        return <UpdateIcon color="info" className="icon icon-in-progress" />;
      default:
        return <ErrorOutlineIcon color="warning" className="icon" />;
    }
  };

  const navigateToLink = (item: PageLink) => {
    const isComplete = (status: string): boolean =>
      status === FormStatus.REG_SUBMITTED || status === FormStatus.REG_REVIEW_REQUIRED;
    if (item.disableIfComplete && isComplete(item.status)) {
      return;
    }
    if (item.navigationType === 'react') {
      history.push(item.link);
      return;
    } else if (item.navigationType === 'django') {
      navigateToExternalURL(item.link);
      return;
    }
    throw 'Invalid navigation type';
  };

  const renderStepLink = (newPagelinks: PageLink[]): JSX.Element[] => {
    return newPagelinks
      .filter((item) => item.isVisible)
      .map((value, index): JSX.Element => {
        return (
          <div key={index} className="link-container">
            <div className="step-no">{`${'Step'} ${index + 1}`}</div>
            <div className="link" onClick={(): void | null => navigateToLink(value)}>
              <div className="link-title">{value.title}</div>
              <div className="status" data-testid={`${value.title}-status`}>
                {renderStatusIcon(value.status)}
                {getFormStatus(value.status, 'patient')}
              </div>
              <div className="link-icon-container">{value.icon}</div>
            </div>
          </div>
        );
      });
  };

  const renderExitLogoutModal = () => {
    return (
      <Modal
        size="sm"
        isOpen={true}
        title="Are you sure you want to Logout?"
        dismissFunction={() => {
          setShowExitLogoutModal(false);
        }}>
        <StyledModalContentExit>
          <p>
            All your changes have been saved <br /> and you can continue filling the <br />
            form when you come back.
          </p>

          <br />
          <GCButton
            title="Logout"
            id="logout-confirm"
            type={ButtonType.GREEN}
            onClick={() => logout(profile.registrationAccessType, isDtEot)}
          />
          <br />
          <GCButton title="Cancel" type={ButtonType.WHITE} onClick={() => setShowExitLogoutModal(false)} />
          <br />
          <br />
        </StyledModalContentExit>
      </Modal>
    );
  };
  return (
    <>
      <div id="page-patient-home">
        <HeaderBar />
        <div className="patient-home-content">
          <HeroImage patient={patient} />
          <div className="content">
            <div className="pn-title">
              {!isDtEot
                ? 'Please complete registration forms in order:'
                : 'Please complete the form before your appointment'}
            </div>
            <div className="pn-sub-title">
              {!isDtEot
                ? `It will take approximately ${
                    isUs() ? '20' : '15-20 '
                  }mins, please complete it before your appointment.`
                : 'It will take approximately 3-5 mins'}
            </div>
            <div className="links">{renderStepLink(newPageLinks)}</div>
            <div className="tips">
              <div className="tips-title">{contents.TIPS.TITLE}</div>
              <ol className="tip-items">{renderTipItems()}</ol>
            </div>
          </div>
        </div>
        <div className="patient-home-bottom-navigator">
          <div className="save-exit-button">
            <GCButton
              title={'Logout'}
              type={ButtonType.WHITE}
              onClick={(): void => {
                setShowExitLogoutModal(true);
              }}
              buttonSize={isMobile ? GCButtonSize.SMALL : undefined}
            />
          </div>
        </div>
      </div>
      {showExitLogoutModal && renderExitLogoutModal()}
      <Modal
        isOpen={showLogoutModal}
        title={`${isDtEot ? 'Distress thermometer' : 'Registration'} completed!`}
        leftButtonText="Continue session"
        leftButtonHandleClick={(e: any) => {
          e.preventDefault();
          setShowLogoutModal(false);
          handleReset();
        }}
        rightButtonText="Logout"
        rightButtonHandleClick={(e: any) => {
          e.preventDefault();
          logout(profile.registrationAccessType, isDtEot);
        }}
        btnContainerClasses="modal-btn-container-section">
        <StyledModalContent>
          <p>
            Thank you for completing the {isDtEot ? 'distress thermometer' : 'registration'} <br />
            Your session will expire in:
          </p>
          <p>
            <span style={{ color: theme.palette.primary.main, fontSize: '32px', fontWeight: 'bold' }}>
              {logoutSeconds}
            </span>{' '}
            second
            {logoutSeconds > 1 && 's'}
          </p>
        </StyledModalContent>
      </Modal>
    </>
  );
};

const routedComponent = withRouter(PatientNavigation);
export default routedComponent;
