import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import ROPatientDiagnosisContext from './Context';
import { GET_DIAGNOSIS } from './Queries';
import { DiagnosisType, DiagnosisActionType, DiagnosisRouterParams } from './Interface';
import ROPatientCarePlanPageFooter from '../Footer';
import { useHistory, useRouteMatch } from 'react-router-dom';
import CareplanContainer from '../Container';
import useDiagnosisWorkflow from './useDiagnosisWorkflow';
import { useTheme } from '@mui/material';

interface ButtonProps {
  id: string;
  text: string;
  desciption: string;
}

interface RenderButtonProps {
  diagnosis: DiagnosisType;
  button: ButtonProps;
  nextPage: (diagnosisType: string | null) => void;
}

const DiagnosisSelectionButton = ({ diagnosis, button, nextPage }: RenderButtonProps) => {
  const theme = useTheme();
  return (
    <div className="item-container" key={button.id} id={`button-${button.id}`}>
      <button
        style={{ cursor: 'pointer' }}
        data-testid={button.id}
        className={classNames('item', {
          [button.id]: true,
          active:
            Object.keys(diagnosis).length !== 0 &&
            diagnosis.isPrimaryDiagnosis !== null &&
            (diagnosis.isPrimaryDiagnosis ? button.id === 'primary' : button.id === 'metastasis'),
        })}
        onClick={(): void => {
          nextPage(button.id);
        }}>
        <div className="dx-icon-container">
          <i
            className={classNames('dx-icon', 'icon', {
              'icon-dx-primary': button.id === 'primary',
              'icon-dx-metastasis': button.id === 'metastasis',
            })}
          />
        </div>
        <div className="dx-title">{button.text}</div>
        <hr
          style={{
            border: `2px solid ${button.id === 'primary' ? theme.palette.primary.main : theme.palette.warning.main}`,
            marginBottom: '15px',
          }}
        />
        <div className="dx-dsecsiption">{button.desciption}</div>
      </button>
    </div>
  );
};

const ROPatientDiagnosisPrimaryOrMetastasis = (): JSX.Element => {
  const history = useHistory();
  const match = useRouteMatch<DiagnosisRouterParams>();
  const { id: patientId, careplanId, diagnosisId, oncologyType } = match.params;
  const { state, dispatch } = useContext(ROPatientDiagnosisContext);
  const {
    createCareplanAndPrimaryDiagnosis,
    createCareplanAndMetastasisDiagnosis,
    hasExistingMetastasisDiagnosis,
    hasExistingPrimaryDiagnosis,
  } = useDiagnosisWorkflow();
  const { data } = useQuery(GET_DIAGNOSIS, {
    variables: { diagnosisId },
    skip: diagnosisId === 'create' || diagnosisId === undefined,
    onCompleted: (): void => {
      if (data)
        dispatch({ type: DiagnosisActionType.REPLACE_CURRENT_DIAGNOSIS, payload: { diagnosis: data.diagnosis } });
    },
  });
  const { diagnosis } = state;

  const buttons = [
    {
      id: 'primary',
      text: 'Primary tumour',
      desciption: 'Create a careplan of the primary diagnosis for the patient.',
    },
    {
      id: 'metastasis',
      text: 'Metastasis',
      desciption: 'Create a careplan of the metastatic diagnosis for the patient.',
    },
  ];

  const nextPage = (diagnosisType: string | null): void => {
    const careplan = careplanId ? careplanId : 'create';
    const dId = diagnosisId ? diagnosisId : 'create';
    const page = diagnosisType ? diagnosisType : diagnosis.isPrimaryDiagnosis ? 'primary' : 'metastasis';

    // Determine whether the user has changed the diagnosis worklfow
    const didUserPickPrimaryDiagnosis = diagnosisType && diagnosisType === 'primary';
    const hasUserChangedDiagnosis = diagnosisType && didUserPickPrimaryDiagnosis !== diagnosis.isPrimaryDiagnosis;
    // skip the existing diagnosis selection when there are no diagnosis for the patient
    if (careplanId === 'create' && (!diagnosisId || diagnosisId === 'create')) {
      const callback = (newCareplanId: string, newDiagnosisId: string): void => {
        const subPage = page === 'primary' ? 'newPrimary' : 'newMetastasis';
        history.push(
          `/${oncologyType}/patient/${patientId}/careplan/${newCareplanId}/diagnosis/${newDiagnosisId}/${page}/${subPage}`,
        );
      };
      if (page === 'primary' && !hasExistingPrimaryDiagnosis()) {
        createCareplanAndPrimaryDiagnosis(callback);
        return;
      }
      if (page === 'metastasis' && !hasExistingMetastasisDiagnosis()) {
        createCareplanAndMetastasisDiagnosis(callback);
        return;
      }
    }
    if (hasUserChangedDiagnosis) {
      dispatch({
        type: DiagnosisActionType.RESET,
        payload: { isPrimaryDiagnosis: page === 'primary' },
      });
      history.push(`/${oncologyType}/patient/${patientId}/careplan/${careplan}/diagnosis/create/${page}`);
    }
    history.push(`/${oncologyType}/patient/${patientId}/careplan/${careplan}/diagnosis/${dId}/${page}`);
  };

  return (
    <div className="main-container-wrapper">
      <CareplanContainer>
        <div className="page-header">
          <h1>Diagnosis Selection</h1>
        </div>
        <div className="create-diagnosis-list">
          <div className="item-list-container">
            <div className="item-list">
              {buttons.map((button) => (
                <DiagnosisSelectionButton diagnosis={diagnosis} button={button} nextPage={nextPage} key={button.id} />
              ))}
            </div>
          </div>
        </div>
      </CareplanContainer>
      <ROPatientCarePlanPageFooter
        onBack={(): void => {
          // Disabled since its the starting page
        }}
        onNext={(): void => {
          nextPage(null);
        }}
        nextDisabled={diagnosis.isPrimaryDiagnosis === null}
        backDisabled={true}
      />
    </div>
  );
};

export default ROPatientDiagnosisPrimaryOrMetastasis;
