// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { ROPatientCarePlanRoute } from '../Interface';

import './Prescription.scss';
import ROPatientCarePathSidePanel from '../SidePanel/SidePanel';
import { CAREPLAN_PAGES, VARIATION_MESSAGE, PEER_REVIEW_MESSAGE, MAX_DOSAGE } from '../Constants';
import { GET_PEER_REVIEW } from '../SidePanel/SidePanelQueries';
import { CareplanBanners } from '../Banner/Banner';
import ModalCpotCancel from '../ModalCpotCancel/ModalCpotCancel';
import { ROTextField, RODoseField, ROAutocomplete, ROToggleButtons } from 'shared-components/components/FormFields';
import { CurrentAppConfig } from '../AppConfig';
import ROPatientCarePlanPageFooter from '../Footer';
import { PrescriptionSite } from './Interface';
import { LOAD_CAREPLAN_LIST_DATA } from '../ListData/ListQueries';
import { decimalFormatter } from '../VolumingPage/OrgansAtRisk/OrgansAtRisk';
import { GET_CAREPLAN_SITE_GROUPS, GET_CAREPLAN, GET_CAREPLAN_STATUS, TRIGGER_CPOT } from '../Queries';
import {
  LOAD_PRESCRIPTION,
  UPDATE_PRESCRIPTION,
  RESET_PRESCRIPTION,
  RESET_TO_LAST_PRESCRIPTION,
  LOAD_SITE_TEMPLATE_VARIATION_VALUES,
  RESET_CONDITIONAL_FIELDS,
  RESET_CONDITIONAL_FIELDS_TO_LAST_PRESCRIPTION,
} from './Queries';
import { GET_USER_PROFILE } from 'op-graphql/queries';
import { GET_DIAGNOSIS_FROM_CAREPLAN } from '../SidePanel/SidePanelQueries';
import {
  ScrollToTop,
  setYesNo,
  ManualSiteAccordion,
  CONDITIONAL_FIELD_MAP,
  mapConditionalFields,
  alphabeticalOrder,
  getTreatmentSiteHeader,
  getTreatmentSiteWithLocation,
  IsShownValueField,
  getManualSiteTumourStreamMap,
} from '../Common';
import { validateField, isPageValid } from '../ValidationEngine';
import { PeerReviewContext } from '../PeerReviewContext';
import { filterListdata } from '../ListData/ListData';
import { LoadingSpinner } from 'shared-components/components';
import CareplanContainer from '../Container';
import { useErrorModalContext } from 'op-contexts';
import {
  calculateRevisedDose,
  calculateRemainingFractions,
  calculateRemainingDose,
  calculateTotalDoses,
  calculateTotalFractions,
} from './CpotMaths';
import FractionDoseWarningModal from './FractionDoseWarningModal';
import BolusForm, { getBolusValidationStructure } from './BolusForm';
import { SectionTitle } from 'op-pages/RO/FormBuilder/Forms/FormElements';
import { Logger } from 'shared-components/utils';
import { logMissingDiagnosisCode } from '../Common';
import Box from '@mui/material/Box';
import { SelectOptionType } from 'shared-components/components/FormFields';

const logger = new Logger('PrescriptionPage.tsx');

const END = 'end';
const AMEND = 'amend';
const NO = 'no';
const YES = 'yes';

export const BOOLEAN_OPTIONS = [
  { label: 'Yes', value: YES },
  { label: 'No', value: NO },
];

const CPOT_CHANGE_OPTIONS = [
  { label: 'End treatment early', value: END },
  { label: 'Amend treatment', value: AMEND },
  { label: 'No change', value: NO },
];
const CPOT_FRACTIONS_MESSAGE = 'Revised total fractions';
const CPOT_DOSE_MESSAGE = 'Revised total dose';
const CPOT_FRACTION_DOSE_MESSAGE = 'Fraction dose updated from:';

const baseValidationDependencyStructure = [
  {
    parent: null,
    expansionValue: null,
    children: ['technique', 'modality', 'fractions', 'phase', 'doseTo', 'dose', 'doseFrequency'],
  },
  {
    parent: 'treatmentOption',
    expansionValue: 'end',
    children: ['changeReason'],
  },
  {
    parent: 'treatmentOption',
    expansionValue: 'amend',
    children: ['cpotFraction', 'changeReason'],
  },
  {
    parent: 'changeReason',
    expansionValue: 'Other',
    children: ['changeReasonOther'],
  },
  {
    parent: 'doseTo',
    expansionValue: 'Custom',
    children: ['customDoseTo'],
  },
  {
    parent: 'doseTo',
    expansionValue: 'Depth',
    children: ['customDoseTo'],
  },
  {
    parent: 'doseTo',
    expansionValue: 'Isodose',
    children: ['customDoseTo'],
  },
  {
    parent: 'doseFrequency',
    expansionValue: 'Custom',
    children: ['customDoseFrequency'],
  },
  {
    parent: 'treatmentBreak',
    expansionValue: 'true',
    children: ['treatmentBreakDuration', 'treatmentBreakFraction'],
  },
  {
    parent: null,
    expansionValue: 'true',
    children: ['isDoseInCorrectOrder', 'isDoseWithinLimit'],
  },
  {
    parent: 'microdosimetryTld',
    expansionValue: null,
    expansionCondition: () => CurrentAppConfig.PrescriptionPage.MicrodosimetryTld,
    children: ['PREVENT_CONTINUE'],
  },
  {
    parent: 'basicDoseCalculation',
    expansionValue: null,
    expansionCondition: () => CurrentAppConfig.PrescriptionPage.BasicDoseCalculation,
    children: ['PREVENT_CONTINUE'],
  },
];

let pressedContinue = false;
const isFieldValid = (field: any): boolean => {
  return pressedContinue ? validateField(field) : true;
};

const preparePageTransition = () => {
  // Reset pressed continue because this component can be re-used on the next page
  pressedContinue = false;
  ScrollToTop(document);
};

const ROPatientPrescriptionPage = (): JSX.Element => {
  const history = useHistory();
  const match = useRouteMatch<ROPatientCarePlanRoute>();
  const { id: patientId, careplanId, siteIdx: siteLocation } = match.params;
  const siteIdx = siteLocation ? parseInt(siteLocation) : 0;
  const { setError } = useErrorModalContext();
  const { data: userProfile, error: userError } = useQuery(GET_USER_PROFILE);
  const { peerReviewViolations, setPeerReviewViolations } = useContext(PeerReviewContext);

  const { data: peerReviewData } = useQuery(GET_PEER_REVIEW, {
    variables: { careplanId },
    onCompleted: () => {
      setPeerReviewViolations(peerReviewData?.peerReview?.violations);
    },
  });

  useEffect(() => {
    setPeerReviewViolations(peerReviewData?.peerReview?.violations);
  }, [peerReviewData]);

  const [validated] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const { data: careplanStatus } = useQuery(GET_CAREPLAN_STATUS, {
    variables: { id: careplanId },
  });
  const cpotTriggered = careplanStatus?.careplan?.careplanStatus === 'CPOT';

  const defaultState = {
    id: -1,
    phase: '',
    technique: '',
    modality: '',
    dose: [null],
    fractions: '',
    doseTo: '',
    customDoseTo: '',
    doseFrequency: '',
    customDoseFrequency: '',
    imagingFrequency: { value: '', isShown: true },
    imagingTechnique: '',
    additionalPrescriptionInfo: '',
    bolusLocationType: '',
    bolusLocation: '',
    bolusThickness: '',
    bolusThicknessCustom: '',
    bolus: { value: '', isShown: true },
    treatmentSite: '',
    bolusFrequency: '',
    is3dBolus: { value: '', isShown: true },
    microdosimetryTld: null,
    treatmentBreak: undefined,
    treatmentBreakDuration: '',
    treatmentBreakFraction: '',
    location: null,
    isDoseInCorrectOrder: true,
    isDoseWithinLimit: true,
    treatmentOption: NO,
    cpotFraction: null,
    changeReason: '',
    changeReasonOther: '',
    originalDose: [null],
    originalFractions: '',
    ctv: [''],
    ptv: [''],
    basicDoseCalculation: null,
    laterality: '',
    userAcknowledgedExceeded5gFraction: null,
  };
  const [state, setState] = useState<PrescriptionSite>(defaultState);
  const { data: diagnosisData, error: diagnosisDataError } = useQuery(GET_DIAGNOSIS_FROM_CAREPLAN, {
    variables: { careplanId: careplanId },
    onCompleted: (data: any): void => {
      logMissingDiagnosisCode(logger, data.careplan, 'Prescription');
    },
  });

  const diagnosisTumourStream = diagnosisData?.careplan?.diagnosis?.diagnosisCode?.tumourStream?.name ?? null;

  const getDoseOverrideMessage = () => {
    let doseErrorMessage = '';

    if (!state.isDoseInCorrectOrder) {
      doseErrorMessage += 'Dose values are not in order of high to low ';
    }

    if (!state.isDoseWithinLimit) {
      if (doseErrorMessage !== '') {
        doseErrorMessage += 'and ';
      }
      doseErrorMessage += `Dose exceeds limitations of ${MAX_DOSAGE} Gy `;
    }

    return doseErrorMessage;
  };

  const [cancelCpot] = useMutation(TRIGGER_CPOT, {
    awaitRefetchQueries: true,
    variables: {
      id: careplanId,
      careplanStatus: 'Prescription',
      cpotTriggered: false,
    },
    refetchQueries: [
      { query: LOAD_PRESCRIPTION, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
    ],
    onCompleted: () => {
      history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SUBMISSION}`);
    },
  });
  const [resetConditionalFields] = useMutation(RESET_CONDITIONAL_FIELDS, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: LOAD_PRESCRIPTION, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
    ],
    onCompleted: () => {
      const newData = presData.siteListByCareplan[siteIdx];

      setState({
        ...state,
        bolusFrequency: newData.bolusFrequency,
        is3dBolus: newData.is3dBolus,
        bolusLocationType: newData.bolusLocationType,
        bolusLocation: newData.bolusLocation,
        bolusThickness: newData.bolusThickness,
        bolusThicknessCustom: newData.bolusThicknessCustom,
        bolus: newData.bolus,
        treatmentBreak: newData.treatmentBreak,
        treatmentBreakDuration: newData.treatmentBreakDuration,
        treatmentBreakFraction: newData.treatmentBreakFraction,
        treatmentSite: newData.treatmentSite,
      });
    },
  });

  const [resetConditionalFieldsToPrevious] = useMutation(RESET_CONDITIONAL_FIELDS_TO_LAST_PRESCRIPTION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: LOAD_PRESCRIPTION, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
    ],
    onCompleted: () => {
      const newData = presData.siteListByCareplan[siteIdx];

      setState({
        ...state,
        bolusFrequency: newData.bolusFrequency,
        is3dBolus: newData.is3dBolus,
        bolusLocationType: newData.bolusLocationType,
        bolusLocation: newData.bolusLocation,
        bolusThickness: newData.bolusThickness,
        bolusThicknessCustom: newData.bolusThicknessCustom,
        bolus: newData.bolus,
        treatmentBreak: newData.treatmentBreak,
        treatmentBreakDuration: newData.treatmentBreakDuration,
        treatmentBreakFraction: newData.treatmentBreakFraction,
        treatmentSite: newData.treatmentSite,
      });
    },
  });

  const {
    loading,
    error: presDataError,
    data: presData,
  } = useQuery(LOAD_PRESCRIPTION, {
    fetchPolicy: 'network-only',
    variables: { careplanId },
    onCompleted: (data: any): void => {
      const newData = data.siteListByCareplan[siteIdx];
      setState((prevState) => {
        return {
          ...prevState,
          id: newData.id,
          phase: newData.phase,
          technique: newData.technique,
          modality: newData.modality,
          dose: validateDoseData(newData.dose),
          fractions: newData.fractions,
          doseTo: newData.doseTo,
          customDoseTo: newData.customDoseTo,
          doseFrequency: newData.doseFrequency,
          customDoseFrequency: newData.customDoseFrequency,
          imagingFrequency: newData.imagingFrequency,
          imagingTechnique: newData.imagingTechnique,
          additionalPrescriptionInfo: newData.additionalPrescriptionInfo,
          bolusLocationType: newData.bolusLocationType,
          bolusLocation: newData.bolusLocation,
          bolusThickness: newData.bolusThickness,
          bolusThicknessCustom: newData.bolusThicknessCustom,
          bolus: newData.bolus,
          microdosimetryTld: newData.microdosimetryTld,
          treatmentBreak: newData.treatmentBreak,
          treatmentBreakDuration: newData.treatmentBreakDuration,
          treatmentBreakFraction: newData.treatmentBreakFraction,
          treatmentSite: newData.treatmentSite,
          bolusFrequency: newData.bolusFrequency,
          is3dBolus: newData.is3dBolus,
          location: newData.location,
          laterality: newData.laterality,
          treatmentOption: newData.treatmentOption || NO,
          cpotFraction: newData.cpotFraction ? Number(newData.cpotFraction) : null,
          changeReason: newData.changeReason,
          changeReasonOther: newData.changeReasonOther,
          originalDose: newData.originalDose,
          originalFractions: newData.originalFractions,
          ctv: newData.ctv || [''],
          ptv: newData.ptv || [''],
          basicDoseCalculation: newData.basicDoseCalculation,
          userAcknowledgedExceeded5gFraction: newData.userAcknowledgedExceeded5gFraction,
        };
      });
    },
  });

  const [phaseOptions, setPhaseOptions] = useState<SelectOptionType[]>([]);
  const [techniqueOptions, setTechniqueOptions] = useState<SelectOptionType[]>([]);
  const [imagingTechniqueOptions, setImagingTechniqueOptions] = useState<SelectOptionType[]>([]);
  const [doseToOptions, setDoseToOptions] = useState<SelectOptionType[]>([]);
  const [imagingOptions, setImagingOptions] = useState<SelectOptionType[]>([]);
  const [frequencyOptions, setFrequencyOptions] = useState<SelectOptionType[]>([]);
  const [modalityOptions, setModalityOptions] = useState<SelectOptionType[]>([]);
  const [changeReasonOptions, setChangeReasonOptions] = useState<SelectOptionType[]>([]);
  const [show5gFractionWarning, setShow5gFractionWarning] = useState(false);
  const [, updateState] = useState();

  const [bolusOptions, setBolusOptions] = useState<Record<string, SelectOptionType[]>>({
    bolus: [],
    bolusThickness: [],
    bolusFrequency: [],
    is3dBolus: [],
    bolusLocationType: [],
  });

  // @ts-ignore allow empty object
  const forceUpdate = useCallback((): void => updateState({}), []);
  const { data: careplanData, error: careplanDataError } = useQuery(GET_CAREPLAN_SITE_GROUPS, {
    variables: { careplanId },
    fetchPolicy: 'network-only',
  });

  const {
    loading: templateValuesLoading,
    data: templateValuesData,
    error: templateValuesDataError,
  } = useQuery(LOAD_SITE_TEMPLATE_VARIATION_VALUES, {
    fetchPolicy: 'network-only',
    variables: { siteId: state.id },
    skip: state.id === -1 || !presData,
  });

  const { data: careplanStatusData, error: careplanStatusDataError } = useQuery(GET_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { id: careplanId },
  });

  const manualSiteConditionalFields = getManualSiteTumourStreamMap(careplanStatusData?.careplan.featureVersion)[
    diagnosisTumourStream
  ];
  const bolusValidationStructure = getBolusValidationStructure(careplanStatusData?.careplan.featureVersion);
  const validationDependencyStructure = [...baseValidationDependencyStructure, ...bolusValidationStructure];

  useEffect(() => {
    if (
      presDataError ||
      careplanDataError ||
      templateValuesDataError ||
      careplanStatusDataError ||
      diagnosisDataError ||
      userError
    )
      return setError();
  }, [presDataError, careplanDataError, templateValuesDataError, careplanStatusDataError, diagnosisDataError]);

  const [updatePrescription, { loading: prescriptionLoading }] = useMutation(UPDATE_PRESCRIPTION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      // { query: LOAD_PRESCRIPTION, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: GET_PEER_REVIEW, variables: { careplanId } },
    ],
  });

  const validateDoseData = (data: Array<any>) => {
    if (!data) return [null];
    const numericConversion = (doseData: any) =>
      !isNaN(parseFloat(doseData)) && isFinite(doseData) && parseFloat(doseData) <= MAX_DOSAGE
        ? parseFloat(doseData)
        : null;
    const validatedArray = data.length ? data.map((dose: any) => numericConversion(dose)) : [numericConversion(data)];
    return validatedArray;
  };

  const [resetSite] = useMutation(RESET_PRESCRIPTION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: LOAD_PRESCRIPTION, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: GET_PEER_REVIEW, variables: { careplanId } },
    ],
    onCompleted: (data: any): void => {
      const newData = data.resetPrescription.site;
      setState({
        ...state,
        id: newData.id,
        phase: newData.phase,
        technique: newData.technique,
        modality: newData.modality,
        dose: validateDoseData(newData.dose),
        fractions: newData.fractions,
        doseTo: newData.doseTo,
        customDoseTo: newData.customDoseTo,
        doseFrequency: newData.doseFrequency,
        customDoseFrequency: newData.customDoseFrequency,
        imagingFrequency: newData.imagingFrequency,
        imagingTechnique: newData.imagingTechnique,
        additionalPrescriptionInfo: newData.additionalPrescriptionInfo,
        bolusLocationType: newData.bolusLocationType,
        bolusLocation: newData.bolusLocation,
        bolusThickness: newData.bolusThickness,
        bolusThicknessCustom: newData.bolusThicknessCustom,
        bolus: newData.bolus,
        microdosimetryTld: newData.microdosimetryTld,
        treatmentBreak: newData.treatmentBreak,
        treatmentBreakDuration: newData.treatmentBreakDuration,
        treatmentBreakFraction: newData.treatmentBreakFraction,
        treatmentSite: newData.treatmentSite,
        bolusFrequency: newData.bolusFrequency,
        is3dBolus: newData.is3dBolus,
        location: newData.location,
        laterality: newData.laterality,
        ctv: newData.ctv || [''],
        ptv: newData.ptv || [''],
        basicDoseCalculation: newData.basicDoseCalculation,
      });
    },
  });

  const [resetSiteToPrevious, { loading: resetLastPrescription }] = useMutation(RESET_TO_LAST_PRESCRIPTION, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_CAREPLAN, variables: { id: careplanId } }],
    onCompleted: (data: any): void => {
      const newData = data.resetToLastPrescription.site;
      const useIsEnd = state.treatmentOption === END && cpotFractionValue;
      const dose = useIsEnd
        ? calculateRevisedDose(cpotFractionValue, originalDoseValue(state), originalFractionValue)
        : validateDoseData(newData.dose);
      const fractions = useIsEnd ? cpotFractionValue : newData.fractions;
      setState((prevState) => {
        return {
          ...prevState,
          id: newData.id,
          phase: newData.phase,
          technique: newData.technique,
          modality: newData.modality,
          dose,
          fractions,
          doseTo: newData.doseTo,
          customDoseTo: newData.customDoseTo,
          doseFrequency: newData.doseFrequency,
          customDoseFrequency: newData.customDoseFrequency,
          imagingFrequency: newData.imagingFrequency,
          imagingTechnique: newData.imagingTechnique,
          additionalPrescriptionInfo: newData.additionalPrescriptionInfo,
          bolusLocationType: newData.bolusLocationType,
          bolusLocation: newData.bolusLocation,
          bolusThickness: newData.bolusThickness,
          bolusThicknessCustom: newData.bolusThicknessCustom,
          bolus: newData.bolus,
          microdosimetryTld: newData.microdosimetryTld,
          treatmentBreak: newData.treatmentBreak,
          treatmentBreakDuration: newData.treatmentBreakDuration,
          treatmentBreakFraction: newData.treatmentBreakFraction,
          treatmentSite: newData.treatmentSite,
          bolusFrequency: newData.bolusFrequency,
          is3dBolus: newData.is3dBolus,
          location: newData.location,
          laterality: newData.laterality,
          originalDose: validateDoseData(newData.dose),
          originalFractions: decimalFormatter(newData.fractions),
          ctv: newData.ctv || [''],
          ptv: newData.ptv || [''],
          basicDoseCalculation: newData.basicDoseCalculation,
        };
      });
    },
  });

  const { data: careplanListData, loading: careplanDataLoading } = useQuery(LOAD_CAREPLAN_LIST_DATA, {
    variables: {
      listCategory: [
        'phase',
        'technique',
        'imagingTechnique',
        'doseto',
        'imaging',
        'frequency',
        'modality',
        'bolusThickness',
        'bolusFrequency',
        'bolusLocationType',
        'is3dBolus',
        'ReasonForChange',
      ],
      patientId,
    },
  });

  useEffect(() => {
    setPhaseOptions(filterListdata(careplanListData, 'phase'));
    setTechniqueOptions(filterListdata(careplanListData, 'technique'));
    setImagingTechniqueOptions(filterListdata(careplanListData, 'imagingTechnique'));
    setDoseToOptions(alphabeticalOrder(filterListdata(careplanListData, 'doseto'), 'text'));
    setImagingOptions(filterListdata(careplanListData, 'imaging'));
    setModalityOptions(filterListdata(careplanListData, 'modality'));
    setFrequencyOptions(filterListdata(careplanListData, 'frequency'));
    setChangeReasonOptions(filterListdata(careplanListData, 'ReasonForChange'));

    setBolusOptions({
      bolus: BOOLEAN_OPTIONS,
      bolusThickness: filterListdata(careplanListData, 'bolusThickness'),
      bolusLocationType: filterListdata(careplanListData, 'bolusLocationType'),
      bolusFrequency: filterListdata(careplanListData, 'bolusFrequency'),
      is3dBolus: filterListdata(careplanListData, 'is3dBolus'),
    });
  }, [careplanListData]);

  useEffect((): void => {
    pressedContinue = false;
  }, []);
  useEffect((): void => {
    const errorElems = document.getElementsByClassName('icon-form-error');
    if (errorElems && errorElems.length > 0) {
      errorElems[0].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
      (errorElems[0] as HTMLElement).focus({ preventScroll: true });
    } else {
      pressedContinue = false;
    }
  });
  useEffect((): void => {
    // Check the dose ordering
    let isDoseInCorrectOrder = true;
    if (state.dose && state.dose.length > 1) {
      for (let index = 0; index < state.dose.length - 1; index++) {
        if (parseFloat(state.dose[index] as string) < parseFloat(state.dose[index + 1] as string)) {
          isDoseInCorrectOrder = false;
          break;
        }
      }
    }
    if (isDoseInCorrectOrder !== state.isDoseInCorrectOrder) {
      setState((prevState) => {
        return { ...prevState, isDoseInCorrectOrder: isDoseInCorrectOrder };
      });
    }

    //check the dose upper limit
    let isDoseWithinLimit = true;
    if (state.dose && state.dose.length >= 1) {
      for (let index = 0; index < state.dose.length; index++) {
        if (parseFloat(state.dose[index] as string) > MAX_DOSAGE) {
          isDoseWithinLimit = false;
          break;
        }
      }
    }
    if (isDoseWithinLimit !== state.isDoseWithinLimit) {
      setState((prevState) => {
        return { ...prevState, isDoseWithinLimit: isDoseWithinLimit };
      });
    }
  }, [state.dose]);
  if (loading || careplanDataLoading || state?.id === -1)
    return (
      <LoadingSpinner loadingText={'Loading Prescription'} subtitle={'Please wait while we set things up for you'} />
    );

  const getOptionByValue = (options: SelectOptionType[], value: string): SelectOptionType | null => {
    const option = options.find((option: SelectOptionType): boolean => option.value === value);
    if (option) return option;
    if (value && value !== '') {
      return {
        label: value,
        value: value,
      };
    }
    return null;
  };

  const StringToBoolean = (string: any) => {
    if (string === 'true') return true;
    if (string === 'false') return false;
    return null;
  };
  const updateData = (): void => {
    // Conditionally add the 3d Bolus field, as the backend will create it and default to is_shown=true if we try to update it and it might not exist on the template
    const is3dBolusField = state.is3dBolus?.isShown ? { is3dBolus: state.is3dBolus.value } : {};

    updatePrescription({
      variables: {
        siteId: state.id,
        phase: state.phase,
        technique: state.technique,
        modality: state.modality,
        dose: validateDoseData(state.dose),
        fractions: state.fractions,
        doseTo: state.doseTo,
        customDoseTo: state.customDoseTo,
        doseFrequency: state.doseFrequency,
        customDoseFrequency: state.customDoseFrequency,
        imagingFrequency: state.imagingFrequency ? state.imagingFrequency.value : '',
        imagingTechnique: state.imagingTechnique,
        additionalPrescriptionInfo: state.additionalPrescriptionInfo,
        bolusLocationType: state.bolusLocationType,
        bolusLocation: state.bolusLocation,
        bolusFrequency: state.bolusFrequency,
        bolusThickness: state.bolusThickness,
        microdosimetryTld: state.microdosimetryTld,
        treatmentBreak:
          state.treatmentBreak && state.treatmentBreak.value ? StringToBoolean(state.treatmentBreak.value) : undefined,
        treatmentBreakDuration: state.treatmentBreakDuration,
        treatmentBreakFraction: state.treatmentBreakFraction,
        bolusThicknessCustom: state.bolusThicknessCustom,
        bolus: state.bolus && state.bolus.value ? StringToBoolean(state.bolus.value) : null,
        treatmentSite: state.treatmentSite,
        treatmentOption: state.treatmentOption,
        cpotFraction: state.cpotFraction,
        changeReason: state.changeReason,
        changeReasonOther: state.changeReasonOther,
        originalDose: validateDoseData(state.originalDose),
        originalFractions: state.originalFractions,
        ctv: state.ctv,
        ptv: state.ptv,
        basicDoseCalculation: state.basicDoseCalculation,
        userAcknowledgedExceeded5gFraction: state.userAcknowledgedExceeded5gFraction,
        ...is3dBolusField,
      },
    });
  };

  const prepareValues = (site: any): void => {
    if (!presData) return;
    const newData = presData.siteListByCareplan[site];
    setState({
      ...state,
      id: newData.id,
      phase: newData.phase,
      technique: newData.technique,
      modality: newData.modality,
      dose: validateDoseData(newData.dose),
      fractions: newData.fractions,
      doseTo: newData.doseTo,
      doseFrequency: newData.doseFrequency,
      customDoseFrequency: newData.customDoseFrequency,
      customDoseTo: newData.customDoseTo,
      imagingFrequency: newData.imagingFrequency,
      imagingTechnique: newData.imagingTechnique,
      additionalPrescriptionInfo: newData.additionalPrescriptionInfo,
      bolusLocationType: newData.bolusLocationType,
      bolusLocation: newData.bolusLocation,
      bolusThickness: newData.bolusThickness,
      bolusThicknessCustom: newData.bolusThicknessCustom,
      bolus: newData.bolus,
      microdosimetryTld: newData.microdosimetryTld,
      treatmentBreak: newData.treatmentBreak,
      treatmentBreakDuration: newData.treatmentBreakDuration,
      treatmentBreakFraction: newData.treatmentBreakFraction,
      treatmentSite: newData.treatmentSite,
      bolusFrequency: newData.bolusFrequency,
      is3dBolus: newData.is3dBolus,
      location: newData.location,
      laterality: newData.laterality,
      treatmentOption: newData.treatmentOption || NO,
      cpotFraction: newData.cpotFraction ? Number(newData.cpotFraction) : null,
      changeReason: newData.changeReason,
      changeReasonOther: newData.changeReasonOther,
      originalDose: validateDoseData(newData.originalDose),
      originalFractions: newData.originalFractions,
      ctv: newData.ctv || [''],
      ptv: newData.ptv || [''],
      basicDoseCalculation: newData.basicDoseCalculation,
    });
  };

  const updatePrescriptionState = (newData: any): void => {
    if (!newData) return;
    setState({
      ...state,
      ...newData,
      dose: validateDoseData(newData.dose),
      treatmentOption: newData.treatmentOption || NO,
      cpotFraction: newData.cpotFraction ? Number(newData.cpotFraction) : null,
      ctv: newData.ctv || [''],
      ptv: newData.ptv || [''],
    });
  };

  const onBack = (): void => {
    const isFirstsite = siteIdx === 0;
    preparePageTransition();
    if (isFirstsite) {
      const index = careplanData ? careplanData.siteGroupsByCareplan.length - 1 : 0;
      history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SIMULATION}/${index}`);
    } else {
      const newsiteIdx = siteIdx - 1;
      newsiteIdx === 0
        ? history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.PRESCRIPTION}`)
        : history.push(
            `/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.PRESCRIPTION}/${newsiteIdx}`,
          );
      prepareValues(newsiteIdx);
    }
  };
  const continueToNextPage = () => {
    preparePageTransition();

    const siteListByCareplan = presData ? presData.siteListByCareplan : [];
    const isLastsite = siteIdx === siteListByCareplan.length - 1;
    if (isLastsite) {
      history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.VOLUMING}`);
    } else {
      const newsiteIdx = siteIdx + 1;
      history.push(
        `/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.PRESCRIPTION}/${newsiteIdx}`,
      );
      prepareValues(newsiteIdx);
    }
  };

  const onNext = (): void => {
    pressedContinue = true;
    forceUpdate();
    const isCpotEndFractionValid = cpotIsEnd
      ? state.cpotFraction === null
        ? false
        : Number(state.cpotFraction) >= 0
      : true;
    if (!isCpotEndFractionValid || !isPageValid(validationDependencyStructure, state)) return;
    if (handle5GFractionWarning()) return;

    continueToNextPage();
  };

  const inValidsiteIndex = () => {
    window.location.replace(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.PRESCRIPTION}`);
    return presData.siteListByCareplan[0];
  };

  const site = presData
    ? presData.siteListByCareplan[siteIdx]
      ? presData.siteListByCareplan[siteIdx]
      : inValidsiteIndex()
    : [];

  const parseStringToFloat = (dose: any): number => (dose ? parseFloat(dose.toString().replace(/\,/g, '')) : dose);

  const doseValue = (state: PrescriptionSite): (number | null)[] => {
    if (state.treatmentOption === END && state.cpotFraction === 0) {
      return state.dose.map(() => null);
    }
    if (!state.dose) return [null];
    if (state.dose.length === 1) return [parseStringToFloat(state.dose[0])];
    return state.dose.map((item: string | number | null): number => parseStringToFloat(item));
  };
  const originalDoseValue = (state: PrescriptionSite): (number | null)[] => {
    if (!state.originalDose) return doseValue(state);
    if (state.originalDose.length === 1) return [parseStringToFloat(state.originalDose[0])];
    return state.originalDose.map((item: string | number | null): number => parseStringToFloat(item));
  };
  const fractionValue = Number(state.fractions);
  const originalFractionValue = Number(state.originalFractions);
  const cpotFractionValue = Number(state.cpotFraction);

  const fractionUsed = state.fractions ? parseFloat(state.fractions.toString()) : null;
  const prescriptionFractionDose = doseValue(state).map((item: any): number =>
    fractionUsed ? decimalFormatter(item / fractionUsed) : null,
  );
  const addDoseColumn = (): void => {
    setState((prevState) => {
      let newDose: any = doseValue(state);
      let newCtv: any = [...prevState.ctv];
      let newPtv: any = [...prevState.ptv];
      if (newDose.length === 1) {
        newDose = [...doseValue(prevState), null];
        newCtv = [...prevState.ctv, ''];
        newPtv = [...prevState.ptv, ''];
      } else if (newDose.length === 2) {
        newDose.splice(1, 0, null);
        newCtv.splice(1, 0, '');
        newPtv.splice(1, 0, '');
      } else if (newDose.length === 3) {
        newDose.splice(2, 0, null);
        newCtv.splice(2, 0, '');
        newPtv.splice(2, 0, '');
      }
      updatePrescription({ variables: { siteId: site.id, dose: validateDoseData(newDose), ctv: newCtv, ptv: newPtv } });
      return { ...prevState, dose: newDose, ctv: newCtv, ptv: newPtv };
    });
  };

  const removeDoseColumn = (index: any): void => {
    setState((prevState) => {
      const doseValues = [...validateDoseData(prevState.dose)];
      const ctvValues = [...prevState.ctv];
      const ptvValues = [...prevState.ptv];
      doseValues.splice(index, 1);
      ctvValues.splice(index, 1);
      ptvValues.splice(index, 1);
      updatePrescription({
        variables: { siteId: site.id, dose: doseValues, ctv: [...ctvValues], ptv: [...ptvValues] },
      });
      return { ...prevState, dose: doseValues, ctv: ctvValues, ptv: ptvValues };
    });
  };

  const getBreakValue = (): string => {
    if (state.treatmentBreak && state.treatmentBreak.value) {
      return setYesNo(state.treatmentBreak.value);
    }
    if (typeof state.treatmentBreak == 'boolean') {
      return setYesNo(state.treatmentBreak);
    }
    return '';
  };
  const variationValidation = (field: string, value: any): boolean => {
    if (templateValuesLoading) return false;
    // if (cpotTriggered) return false;

    if (templateValuesData) {
      const variationField = templateValuesData.loadSiteTemplateValuesVariation.find(
        (variation: any): any => variation.fieldName.toLowerCase() === field.toLowerCase(),
      );

      if (variationField) {
        // Check if the templated value is an actual value or if its a placeholder
        if (variationField.value === '') return false;
        if (variationField.value === null) return false;
        // Custom does option to handle the array fields
        if (field === 'dose') {
          // return false for empty value in dose array
          const emptyCheck = value.filter((item: any) => item === '').length === 0;
          if (!emptyCheck) return false;
          return (
            JSON.parse(variationField.value).toString() !==
            value
              .filter((dose: any) => dose)
              .map((dose: any) => JSON.parse(dose))
              .toString()
          );
        }
        // Fractions can be either 5.0 or 5 need todo a number comparison
        if (field === 'fractions') {
          return Number(variationField.value) !== Number(value);
        }
        // Don't change the != as the types of the variation field and the value could be different type but string equivalent remains the same.
        return variationField.value !== value; //eslint-disable-line
      }
    }

    return false;
  };

  const totalDose = calculateTotalDoses(
    cpotFractionValue,
    originalDoseValue(state),
    doseValue(state),
    originalFractionValue,
  );
  const totalFractions = calculateTotalFractions(cpotFractionValue, fractionValue);

  const peerReviewTriggered = (field: string): boolean =>
    peerReviewViolations?.some(
      (variation: any) =>
        variation?.site?.siteId === site.id &&
        variation?.ruleSets[0]?.rules.some((rule: any) => rule?.field.toLowerCase() === field),
    );

  const manyPeerReviewTriggered = (fieldArray: string[]): boolean => fieldArray.some(peerReviewTriggered);

  const fieldsVariation = {
    phase: {
      peerReview: peerReviewTriggered('phase'),
      variation: variationValidation('phase', state.phase),
    },
    technique: {
      peerReview: peerReviewTriggered('technique'),
      variation: variationValidation('technique', state.technique),
    },
    modality: {
      peerReview: peerReviewTriggered('modality'),
      variation: variationValidation('modality', state.modality),
    },
    dose: {
      peerReview: manyPeerReviewTriggered(['dose', 'number of doses', 'high dose', 'low dose']),
      variation: variationValidation('dose', cpotFractionValue ? totalDose : state.dose),
    },
    fractions: {
      peerReview: peerReviewTriggered('fractions'),
      variation: variationValidation('fractions', cpotFractionValue ? totalFractions : state.fractions),
    },
    doseTo: {
      peerReview: peerReviewTriggered('dose to'),
      variation: variationValidation('dose_to', state.doseTo),
    },
    doseFrequency: {
      peerReview: peerReviewTriggered('frequency'),
      variation: variationValidation('dose_frequency', state.doseFrequency),
    },
    fractionDose: {
      peerReview: peerReviewTriggered('fraction dose'),
      variation: null,
    },
  };

  const getVariationMessage = (peerReview: boolean, variation: boolean): string | undefined => {
    return peerReview ? PEER_REVIEW_MESSAGE : variation ? VARIATION_MESSAGE : undefined;
  };

  const hasVariation = Object.values(fieldsVariation).some((item: any) => item.variation);
  const hasPeerReview = Object.values(fieldsVariation).some((item: any) => item.peerReview);

  const cpotIsNotNo = cpotTriggered && state.treatmentOption !== NO;
  const cpotIsEnd = cpotTriggered && state.treatmentOption === END;
  const cpotReadOnly = cpotTriggered && state.treatmentOption !== AMEND;
  const isManualSite = templateValuesData?.loadSiteTemplateValuesVariation.length === 0;

  const conditionalFieldsJSX = {
    bolus: (
      <BolusForm
        version={careplanStatusData?.careplan?.featureVersion}
        state={state}
        setState={setState}
        site={site}
        updatePrescription={updatePrescription}
        updateData={updateData}
        isFieldValid={isFieldValid}
        options={bolusOptions}
        cpotReadOnly={cpotReadOnly}></BolusForm>
    ),
    treatmentBreak: (
      <>
        <SectionTitle>TREATMENT BREAK</SectionTitle>
        <ROToggleButtons
          id="treatmentBreak"
          fieldlabel="Break"
          options={BOOLEAN_OPTIONS}
          value={getBreakValue()}
          handleChange={(value): void => {
            const breakBool = value === 'yes';
            setState({
              ...state,
              treatmentBreak: { value: breakBool.toString(), isShown: true },
              treatmentBreakDuration: '',
              treatmentBreakFraction: '',
            });
            updatePrescription({
              variables: {
                siteId: site.id,
                treatmentBreak: breakBool,
                treatmentBreakDuration: '',
                treatmentBreakFraction: '',
              },
            });
          }}
          disabled={cpotReadOnly}
        />
        {state?.treatmentBreak?.value === 'true' && (
          <>
            <ROTextField
              id="treatmentBreakDuration"
              fieldlabel="Minimum break (weeks)"
              value={state.treatmentBreakDuration}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setState({ ...state, treatmentBreakDuration: event.target.value });
              }}
              onBlur={() => updateData()}
              type="number"
              error={!isFieldValid(state.treatmentBreakDuration)}
              disabled={cpotReadOnly}
              required
            />
            <ROTextField
              id="treatmentBreakFraction"
              fieldlabel="Commence break after fraction"
              value={state.treatmentBreakFraction}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setState({ ...state, treatmentBreakFraction: event.target.value });
              }}
              onBlur={() => updateData()}
              type="number"
              error={!isFieldValid(state.treatmentBreakFraction)}
              disabled={cpotReadOnly}
              required
            />
          </>
        )}
      </>
    ),
  };

  const handleManualSiteAccordionClose = () => {
    cpotTriggered
      ? resetConditionalFields({
          variables: {
            siteId: parseInt(site.id),
            fieldNames: mapConditionalFields(CONDITIONAL_FIELD_MAP, hiddenConditionals),
          },
        })
      : resetConditionalFieldsToPrevious({
          variables: {
            siteId: parseInt(site.id),
            fieldNames: mapConditionalFields(CONDITIONAL_FIELD_MAP, hiddenConditionals),
          },
        });
  };

  const allConditionals = Object.keys(conditionalFieldsJSX) as (keyof PrescriptionSite)[];
  const hiddenConditionals: (keyof PrescriptionSite)[] = [];
  const visibleConditionals: (keyof PrescriptionSite)[] = [];

  Object.keys(conditionalFieldsJSX).forEach((fieldName) => {
    if (manualSiteConditionalFields) {
      manualSiteConditionalFields.includes(fieldName)
        ? visibleConditionals.push(fieldName as keyof PrescriptionSite)
        : hiddenConditionals.push(fieldName as keyof PrescriptionSite);
    }
  });

  const hasHiddenConditionalsWithValues =
    hiddenConditionals.filter((fieldName) => {
      if (state[fieldName]) return (state[fieldName] as IsShownValueField).value;
      return false;
    }).length > 0;

  const renderConditionalFields = (fieldNameArr: (keyof PrescriptionSite)[]) => {
    return fieldNameArr.map((fieldName): JSX.Element | null => {
      if (state[fieldName]) {
        const isVisible = Boolean(state[fieldName]) && (state[fieldName] as IsShownValueField).isShown;

        if (isVisible) {
          return <div key={fieldName}>{conditionalFieldsJSX[fieldName as keyof typeof conditionalFieldsJSX]}</div>;
        }
      }
      return null;
    });
  };
  const fractionsMessage = () => {
    if (cpotTriggered) {
      if (cpotIsEnd && state.cpotFraction === 0) {
        return 'No fractions will be delivered';
      }
      if (cpotIsNotNo && cpotFractionValue) {
        const totalFractions = calculateTotalFractions(cpotFractionValue, fractionValue);
        if (state.treatmentOption === END) return `${CPOT_FRACTIONS_MESSAGE}`;
        return `${CPOT_FRACTIONS_MESSAGE}: ${totalFractions}#`;
      }
    }
    return getVariationMessage(fieldsVariation['fractions'].peerReview, fieldsVariation['fractions'].variation);
  };

  const doseMessage = () => {
    if (cpotIsEnd && state.cpotFraction === 0) {
      return 'No dose will be delivered';
    }
    if (cpotIsNotNo && cpotFractionValue) {
      // If dose lengths have changed do not calculate anything
      if (originalDoseValue(state)?.length !== state.dose.length)
        return 'Cannot calculate total dose due to SIB variation';
      if (state.treatmentOption === END) return `${CPOT_DOSE_MESSAGE}`;
      const totalDoses = calculateTotalDoses(
        cpotFractionValue,
        originalDoseValue(state),
        doseValue(state),
        originalFractionValue,
      );
      return `${CPOT_DOSE_MESSAGE}: ${totalDoses.join('Gy | ')}Gy`;
    } else return getVariationMessage(fieldsVariation['dose'].peerReview, fieldsVariation['dose'].variation);
  };

  const fractionDoseMessage = () => {
    if (hasExceeded5Gy()) {
      return 'Variation from template - Dose per fraction is >5Gy in daily fractions';
    }

    if (cpotIsEnd && state.cpotFraction === 0) {
      return 'No fraction dose will be delivered';
    }
    const originalFractionDose = originalDoseValue(state)?.map((item: any): number =>
      originalFractionValue ? decimalFormatter(item / originalFractionValue) : null,
    );
    // If dose lengths have changed do not calculate anything
    if (cpotTriggered && cpotFractionValue && originalFractionDose?.length && prescriptionFractionDose?.length) {
      if (originalDoseValue(state)?.length !== doseValue(state)?.length)
        return 'Cannot check fraction dose due to SIB variation';
      const sameFractionDoses = originalFractionDose.every(
        (value: number, index: number) => value === prescriptionFractionDose[index],
      );
      if (sameFractionDoses) return undefined;
      return `${CPOT_FRACTION_DOSE_MESSAGE} ${originalFractionDose.join('Gy | ')}Gy`;
    } else
      return fieldsVariation['fractionDose'].peerReview
        ? PEER_REVIEW_MESSAGE
        : fieldsVariation['fractions'].variation || fieldsVariation['dose'].variation
        ? VARIATION_MESSAGE
        : undefined;
  };
  const cpotFractionLabel =
    state.treatmentOption === END
      ? 'End prescription on fraction (last delivered fraction)'
      : 'Change prescription from fraction (inclusive)';

  const resetAndUpdatePreviousValues = (siteId: any, updateValues: any) => {
    resetSiteToPrevious({ variables: { siteId } }).then(() => {
      updatePrescription({ variables: { siteId, ...updateValues } }).then(({ data }: any) => {
        const newData = data?.updatePrescription?.site;
        updatePrescriptionState(newData);
      });
    });
  };

  const doseHasVariation =
    fieldsVariation['dose'].variation ||
    fieldsVariation['dose'].peerReview ||
    (cpotIsNotNo && !!cpotFractionValue) ||
    (cpotIsEnd && state.cpotFraction === 0);

  const hasExceeded5Gy = () => {
    return (
      // Fraction and Dose variation from original template
      (fieldsVariation['fractions'].variation ||
        fieldsVariation['dose'].variation ||
        fieldsVariation['doseFrequency'].variation) &&
      // Frequency selected is 'Daily'
      getOptionByValue(
        frequencyOptions.map((data) => ({ label: data.label, value: data.value })),
        state.doseFrequency ? state.doseFrequency : '',
      )?.value === 'Daily' &&
      // Fraction is greater than 1
      (cpotIsEnd && state.cpotFraction === 0 ? 0 : state.fractions ? Number(state.fractions) : 0) > 1 &&
      // Any of the Fraction Dose has exceeded 5Gy
      prescriptionFractionDose.some((number) => number > 5)
    );
  };

  const handle5GFractionWarning = () => {
    const showWarningDialog = hasExceeded5Gy() && !state.userAcknowledgedExceeded5gFraction;
    setShow5gFractionWarning(showWarningDialog);
    return showWarningDialog;
  };

  const handleFractionWarningConfirm = () => {
    updatePrescription({ variables: { siteId: site.id, userAcknowledgedExceeded5gFraction: userProfile.user.id } });
    setShow5gFractionWarning(false);
    continueToNextPage();
  };

  return (
    <>
      <FractionDoseWarningModal
        isOpen={show5gFractionWarning}
        handleClose={setShow5gFractionWarning}
        handleContinue={handleFractionWarningConfirm}
      />
      <div className="main-container-parent-wrapper">
        <div className="main-container-wrapper">
          {loading && <div className="main-container">Loading prescription....</div>}
          <CareplanBanners
            data={careplanStatusData}
            variation={hasVariation}
            variationMessage={hasPeerReview ? PEER_REVIEW_MESSAGE : hasVariation ? VARIATION_MESSAGE : ''}
            refetchQueriesList={[
              { query: GET_PEER_REVIEW, variables: { careplanId } },
              { query: GET_CAREPLAN_STATUS, variables: { id: careplanId } },
              { query: GET_DIAGNOSIS_FROM_CAREPLAN, variables: { careplanId: careplanId } },
              { query: LOAD_PRESCRIPTION, variables: { careplanId } },
              { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
              { query: LOAD_SITE_TEMPLATE_VARIATION_VALUES, variables: { siteId: state.id } },
              { query: GET_CAREPLAN, variables: { id: careplanId } },
            ]}
          />
          <CareplanContainer>
            <div className="page-header">
              <h1 data-test-id="prescription-header">
                {'Prescription'}: {getTreatmentSiteHeader(state)}
              </h1>
            </div>
            <Box component="form" id="prescriptionForm" noValidate>
              {cpotTriggered && (
                <ROToggleButtons
                  id="treatmentOption"
                  fieldlabel="Change Option"
                  options={CPOT_CHANGE_OPTIONS}
                  value={state.treatmentOption}
                  disabled={prescriptionLoading || resetLastPrescription}
                  handleChange={(value: string): void => {
                    // When changing the treatment option, we may have to update other cpot fields
                    let update_values = { treatmentOption: value };
                    const isEnd = value === END;
                    const isNo = value === NO;
                    const siteId = parseInt(site.id);
                    // If changing the value, we also need to reset the cpot values
                    const cpotResetValues = {
                      cpotFraction: null,
                      changeReason: '',
                      changeReasonOther: '',
                      dose: originalDoseValue(state),
                      fractions: originalFractionValue || fractionValue,
                    };
                    update_values = { ...update_values, ...cpotResetValues };
                    setState((prevState) => {
                      return { ...prevState, ...update_values };
                    });
                    // If option is end or no, reset the site values to the last prescription,
                    // then update the cpot values
                    if (isEnd || isNo) {
                      resetAndUpdatePreviousValues(siteId, update_values);
                      return;
                    }
                    updatePrescription({ variables: { siteId, ...update_values } }).then(({ data }: any) => {
                      const newData = data?.updatePrescription?.site;
                      updatePrescriptionState(newData);
                    });
                    return;
                  }}
                  required
                  error={!isFieldValid(state.treatmentOption)}
                />
              )}
              {cpotTriggered && state.treatmentOption !== NO && (
                <ROAutocomplete
                  id="changeReason"
                  fieldlabel={`Reason for ${state.treatmentOption === END ? 'end' : 'change'}`}
                  options={changeReasonOptions}
                  value={getOptionByValue(
                    changeReasonOptions.map((data) => ({ label: data.label, value: data.value })),
                    state.changeReason ? state.changeReason : '',
                  )}
                  inputProps={{
                    error: !isFieldValid(state.changeReason),
                  }}
                  required
                  onChange={(option: SelectOptionType | string): void => {
                    const value = typeof option === 'string' ? option : option?.value;
                    setState({ ...state, changeReason: value });
                    updatePrescription({ variables: { siteId: site.id, changeReason: value } });
                  }}
                />
              )}
              {cpotTriggered && state.treatmentOption !== NO && ['Other'].includes(state.changeReason) && (
                <ROTextField
                  id="changeReasonOther"
                  fieldlabel={`Additional reason for ${state.treatmentOption === END ? 'end' : 'change'} info:`}
                  value={state.changeReasonOther || ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    setState({ ...state, changeReasonOther: event.target.value });
                  }}
                  onBlur={() => updateData()}
                  required
                />
              )}
              {cpotIsNotNo && (
                <ROTextField
                  id="cpotFraction"
                  fieldlabel={cpotFractionLabel}
                  required
                  value={state.cpotFraction || state.cpotFraction === 0 ? Number(state.cpotFraction).toString() : ''}
                  error={
                    cpotIsEnd && pressedContinue
                      ? state.cpotFraction === null
                        ? true
                        : !(Number(state.cpotFraction) >= 0)
                      : !isFieldValid(state.cpotFraction)
                  }
                  placeholder={`Enter fraction # (${state.treatmentOption === END ? 0 : 1} and above)`}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    const value = event.target.value;
                    const parsedValue = value ? Math.trunc(Number(value)) : null;
                    const isEnd = state.treatmentOption === END;
                    // If the field is cleared, reset the doses and fractions to their original values
                    const originalFractions = Number(state.originalFractions);
                    if (!parsedValue) {
                      const cpotFraction = state.treatmentOption === AMEND && parsedValue === 0 ? null : parsedValue;
                      setState({
                        ...state,
                        cpotFraction,
                        dose: originalDoseValue(state),
                        fractions: originalFractions,
                      });
                      return;
                    }
                    // Enforce that the cpot fraction is always less than or equal to the original fractions
                    const cpotFraction = Math.min(parsedValue, originalFractions);

                    // If end treatment, set the fractions to the cpotFraction and revise the dose totals
                    // If amending treatment, calculate the new remaining dose and fractions
                    // As cpot is the fraction we are changing from we add 1 to the new
                    // fractions
                    const fractions = isEnd
                      ? cpotFraction
                      : calculateRemainingFractions(cpotFraction, originalFractions);
                    const dose = isEnd
                      ? calculateRevisedDose(cpotFraction, originalDoseValue(state), originalFractions)
                      : calculateRemainingDose(cpotFraction, originalDoseValue(state), originalFractions);
                    setState({ ...state, cpotFraction, dose, fractions });
                  }}
                  onBlur={(): void => updateData()}
                />
              )}
              {cpotTriggered && <div className="simulation-hr"></div>}
              <ROTextField
                id="treatmentSite"
                fieldlabel={'Treatment Site'}
                value={getTreatmentSiteWithLocation(state)}
                disabled={true}
              />
              <ROAutocomplete
                id="prescriptionPhase"
                fieldlabel={CurrentAppConfig.QuestionLabel.phase}
                options={phaseOptions}
                value={getOptionByValue(
                  phaseOptions.map((data) => ({ label: data.label, value: data.value })),
                  state.phase ? state.phase : '',
                )}
                required
                inputProps={{
                  error: !isFieldValid(state.phase),
                  warning: !cpotTriggered && fieldsVariation['phase'].variation,
                  helperText: getVariationMessage(
                    fieldsVariation['phase'].peerReview,
                    fieldsVariation['phase'].variation,
                  ),
                }}
                onChange={(option: SelectOptionType | string): void => {
                  const value = typeof option === 'string' ? option : option?.value;
                  setState({ ...state, phase: value });
                  updatePrescription({ variables: { siteId: site.id, phase: value } });
                }}
                disabled={cpotReadOnly}
              />

              <ROAutocomplete
                id="prescriptionTechnique"
                fieldlabel="Technique"
                options={techniqueOptions}
                value={getOptionByValue(
                  techniqueOptions.map((data) => ({ label: data.label, value: data.value })),
                  state.technique ? state.technique : '',
                )}
                inputProps={{
                  error: !isFieldValid(state.technique),
                  warning: !cpotTriggered && fieldsVariation['technique'].variation,
                  helperText: !isFieldValid(state.technique)
                    ? ''
                    : getVariationMessage(
                        fieldsVariation['technique'].peerReview,
                        fieldsVariation['technique'].variation,
                      ),
                }}
                required
                onChange={(option: SelectOptionType | string): void => {
                  const value = typeof option === 'string' ? option : option?.value;
                  setState({ ...state, technique: value });
                  updatePrescription({ variables: { siteId: site.id, technique: value } });
                }}
                disabled={cpotReadOnly}
              />
              <ROAutocomplete
                id="prescriptionModality"
                fieldlabel="Modality"
                options={modalityOptions}
                value={getOptionByValue(
                  modalityOptions.map((data) => ({ label: data.label, value: data.value })),
                  state.modality ? state.modality : '',
                )}
                inputProps={{
                  helperText: !isFieldValid(state.modality)
                    ? ''
                    : getVariationMessage(
                        fieldsVariation['modality'].peerReview,
                        fieldsVariation['modality'].variation,
                      ),
                  warning: !cpotTriggered && fieldsVariation['modality'].variation,
                  error: !isFieldValid(state.modality),
                }}
                required
                onChange={(option: SelectOptionType | string): void => {
                  const value = typeof option === 'string' ? option : option?.value;
                  setState({ ...state, modality: value });
                  updatePrescription({ variables: { siteId: site.id, modality: value } });
                }}
                disabled={cpotReadOnly}
              />
              {CurrentAppConfig.PrescriptionPage.BasicDoseCalculation && (
                <ROToggleButtons
                  id="basicDoseCalculation"
                  fieldlabel="Basic Dose Calculation"
                  options={BOOLEAN_OPTIONS}
                  required
                  value={state.basicDoseCalculation !== null ? setYesNo(state.basicDoseCalculation) : ''}
                  error={!isFieldValid(state?.basicDoseCalculation)}
                  disabled={cpotReadOnly}
                  handleChange={(value): void => {
                    // onChange value can only be "yes" or "no" (button values)
                    const selected = String(value === 'yes');
                    setState({
                      ...state,
                      basicDoseCalculation: selected,
                    });
                    updatePrescription({ variables: { siteId: site.id, basicDoseCalculation: selected } });
                  }}
                />
              )}
              <RODoseField
                id="dose"
                fieldlabel={`${state.treatmentOption === AMEND ? 'Remaining ' : ''}Dose(Gy)`}
                value={doseValue(state)}
                onChange={(value: any[]): void => {
                  setState((prevState) => {
                    return { ...prevState, dose: value, userAcknowledgedExceeded5gFraction: null };
                  });
                }}
                onBlur={(): void => updateData()}
                disabled={cpotReadOnly || (cpotTriggered && !cpotFractionValue)}
                addColumn={addDoseColumn}
                removeColumn={removeDoseColumn}
                required
                helperText={
                  !isFieldValid(state.dose) ? 'This field is required' : getDoseOverrideMessage() || doseMessage()
                }
                error={!isFieldValid(state.dose) || !state.isDoseInCorrectOrder || !state.isDoseWithinLimit}
                warning={doseHasVariation && !cpotIsNotNo}
                info={state.isDoseInCorrectOrder && cpotIsNotNo && !!doseMessage()}
              />
              <ROTextField
                id="prescriptionFractions"
                fieldlabel={`${state.treatmentOption === AMEND ? 'Remaining ' : ''}Fractions(#)`}
                value={
                  cpotIsEnd && state.cpotFraction === 0 ? '' : state.fractions ? Number(state.fractions).toString() : ''
                }
                helperText={fractionsMessage()}
                alwaysShowHelper={!!cpotTriggered} // always show if theres a cpot message
                error={!isFieldValid(state.fractions)}
                warning={!cpotTriggered && fieldsVariation['fractions'].variation}
                info={(cpotIsEnd && state.cpotFraction === 0) || (cpotIsNotNo && !!cpotFractionValue)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                  setState({
                    ...state,
                    fractions: Math.trunc(Number(event.target.value)),
                    userAcknowledgedExceeded5gFraction: null,
                  });
                }}
                onBlur={(): void => updateData()}
                disabled={cpotReadOnly || (cpotTriggered && !cpotFractionValue)}
                required
              />
              <RODoseField
                id="prescriptionFractionDose"
                fieldlabel="Fraction Dose(Gy/#)"
                value={prescriptionFractionDose}
                helperText={fractionDoseMessage()}
                warning={
                  (hasExceeded5Gy() || cpotTriggered
                    ? !!fractionDoseMessage()
                    : fieldsVariation['fractions'].variation || fieldsVariation['dose'].variation) && !cpotIsNotNo
                }
                info={cpotIsNotNo && !cpotIsEnd && !!fractionDoseMessage()}
                disabled
              />
              <ROAutocomplete
                id="prescriptionDoseTo"
                fieldlabel="Dose To"
                options={doseToOptions}
                value={getOptionByValue(
                  doseToOptions.map((data) => ({ label: data.label, value: data.value })),
                  state.doseTo ? state.doseTo : '',
                )}
                inputProps={{
                  error: !isFieldValid(state.doseTo),
                  warning: !cpotTriggered && fieldsVariation['doseTo'].variation,
                  helperText: !isFieldValid(state.doseTo)
                    ? ''
                    : getVariationMessage(fieldsVariation['doseTo'].peerReview, fieldsVariation['doseTo'].variation),
                }}
                required
                onChange={(option: SelectOptionType | string): void => {
                  const value = typeof option === 'string' ? option : option?.value;
                  setState({ ...state, doseTo: value });
                  updatePrescription({ variables: { siteId: site.id, doseTo: value } });
                }}
                disabled={cpotReadOnly}
              />
              {['Custom', 'Depth', 'Isodose'].includes(state.doseTo) && (
                <ROTextField
                  id="prescriptionCustomDoseTo"
                  fieldlabel="Dose Clarification"
                  value={state.customDoseTo || ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    setState({ ...state, customDoseTo: event.target.value });
                  }}
                  error={!isFieldValid(state.customDoseTo)}
                  onBlur={() => updateData()}
                  disabled={cpotReadOnly}
                  required
                />
              )}
              <ROAutocomplete
                id="prescriptionFrequency"
                fieldlabel="Frequency"
                options={frequencyOptions}
                value={getOptionByValue(
                  frequencyOptions.map((data) => ({ label: data.label, value: data.value })),
                  state.doseFrequency ? state.doseFrequency : '',
                )}
                inputProps={{
                  error: !isFieldValid(state.doseFrequency),
                  warning: !cpotTriggered && fieldsVariation['doseFrequency'].variation,
                  helperText: !isFieldValid(state.doseFrequency)
                    ? ''
                    : getVariationMessage(
                        fieldsVariation['doseFrequency'].peerReview,
                        fieldsVariation['doseFrequency'].variation,
                      ),
                }}
                required
                onChange={(option: SelectOptionType | string): void => {
                  const value = typeof option === 'string' ? option : option?.value;
                  state.doseFrequency === 'Custom' && state.doseFrequency !== value
                    ? setState({ ...state, doseFrequency: value, customDoseFrequency: '' })
                    : setState({ ...state, doseFrequency: value });
                  updatePrescription({
                    variables: {
                      siteId: site.id,
                      doseFrequency: value,
                      customDoseFrequency: state.customDoseFrequency,
                      userAcknowledgedExceeded5gFraction: null,
                    },
                  });
                }}
                disabled={cpotReadOnly}
              />
              {state.doseFrequency === 'Custom' && (
                <ROTextField
                  id="prescriptionCustomDoseFrequency"
                  fieldlabel="Enter custom frequency"
                  error={!isFieldValid(state.customDoseFrequency)}
                  value={state.customDoseFrequency || ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    setState({ ...state, customDoseFrequency: event.target.value });
                  }}
                  onBlur={() => updateData()}
                  disabled={cpotReadOnly}
                  required
                />
              )}
              <ROAutocomplete
                id="prescriptionImaging"
                fieldlabel="Imaging"
                options={imagingOptions}
                value={getOptionByValue(
                  imagingOptions.map((data) => ({ label: data.label, value: data.value })),
                  state.imagingFrequency && state.imagingFrequency.value ? state.imagingFrequency.value : '',
                )}
                onChange={(option: SelectOptionType | string): void => {
                  const value = typeof option === 'string' ? option : option?.value;
                  setState({ ...state, imagingFrequency: { value: value, isShown: true } });
                  updatePrescription({ variables: { siteId: site.id, imagingFrequency: value } });
                }}
                disabled={cpotReadOnly}
              />
              <ROAutocomplete
                id="prescriptionImagingTechnique"
                fieldlabel="Imaging Technique"
                options={imagingTechniqueOptions}
                value={getOptionByValue(
                  imagingTechniqueOptions.map((data) => ({ label: data.label, value: data.value })),
                  state.imagingTechnique ? state.imagingTechnique : '',
                )}
                onChange={(option: SelectOptionType | string): void => {
                  const value = typeof option === 'string' ? option : option?.value;
                  setState({ ...state, imagingTechnique: value });
                  updatePrescription({ variables: { siteId: site.id, imagingTechnique: value } });
                }}
                disabled={cpotReadOnly}
              />
              {CurrentAppConfig.PrescriptionPage.MicrodosimetryTld && (
                <ROToggleButtons
                  id="microdosimetryTld"
                  fieldlabel="Microdosimetry/TLD"
                  options={BOOLEAN_OPTIONS}
                  required
                  value={state.microdosimetryTld !== null ? setYesNo(state.microdosimetryTld) : ''}
                  error={!isFieldValid(state?.microdosimetryTld)}
                  disabled={cpotReadOnly}
                  handleChange={(value): void => {
                    // onChange value can only be "yes" or "no" (button values)
                    const microdosimetryTldString = String(value === 'yes');
                    setState({
                      ...state,
                      microdosimetryTld: microdosimetryTldString,
                    });
                    updatePrescription({ variables: { siteId: site.id, microdosimetryTld: microdosimetryTldString } });
                  }}
                />
              )}
              {isManualSite ? (
                renderConditionalFields(visibleConditionals)
              ) : (
                <>{renderConditionalFields(allConditionals)}</>
              )}
              <div className="simulation-hr"></div>
              <ROTextField
                id="prescriptionAdditionalPrescriptionInfo"
                fieldlabel="Additional Prescription Info"
                value={state.additionalPrescriptionInfo || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                  setState({ ...state, additionalPrescriptionInfo: event.target.value });
                }}
                onBlur={() => updateData()}
                disabled={cpotReadOnly}
                multiline
                maxRows={4}
              />
              {isManualSite && hiddenConditionals.length ? (
                <ManualSiteAccordion
                  title={{ open: 'Clear & close conditional fields', closed: 'See more conditional fields' }}
                  onClose={handleManualSiteAccordionClose}
                  readOnly={cpotReadOnly}
                  open={hasHiddenConditionalsWithValues}>
                  {renderConditionalFields(hiddenConditionals)}
                </ManualSiteAccordion>
              ) : null}
            </Box>
          </CareplanContainer>
          <ROPatientCarePlanPageFooter
            onReset={(): void => {
              pressedContinue = false;
              if (cpotTriggered) {
                setShowModal(true);
              } else {
                resetSite({ variables: { siteId: parseInt(site.id) } });
              }
            }}
            resetText={cpotTriggered ? 'Cancel Change' : 'Reset to templated values'}
            onBack={() => onBack()}
            onNext={() => onNext()}
            nextDisabled={loading}
            backDisabled={loading}
          />
        </div>
        <ROPatientCarePathSidePanel />
        <ModalCpotCancel
          isOpen={showModal}
          submitFunction={() => cancelCpot()}
          dismissFunction={() => setShowModal(false)}
        />
      </div>
    </>
  );
};

export default ROPatientPrescriptionPage;
