// eslint-disable-next-line no-use-before-define
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Stack } from '@mui/material';
import React from 'react';
import { ModalProps, ModalHeaderProps, ModalBodyProps, ModalFooterProps } from './types';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';

const Modal = (props: ModalProps): JSX.Element => (
  <Dialog fullWidth sx={{ '&:root': { justifySelf: 'center' } }} {...props}>
    {props.children}
  </Dialog>
);
const ModalHeader = (props: ModalHeaderProps): JSX.Element => (
  <DialogTitle className="modal-title" {..._.omit(props, ['toggle'])}>
    <Stack direction="row">
      {props.children}
      {props.toggle && (
        <IconButton
          data-testid="close-modal"
          sx={{ marginLeft: 'auto', marginTop: '0px', height: '48px', width: '48px' }}
          onClick={() => props.toggle && props.toggle()}>
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  </DialogTitle>
);
const ModalBody = (props: ModalBodyProps) => (
  <DialogContent className="modal-body" {...props}>
    {props.children}
  </DialogContent>
);
const ModalFooter = (props: ModalFooterProps) => (
  <DialogActions className="modal-footer" {...props}>
    {props.children}
  </DialogActions>
);

export { Modal, ModalHeader, ModalBody, ModalFooter };
