import { useMutation, useQuery } from '@apollo/client';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FormContainer, ModalDeleteForm, ModalSubmitForm } from 'op-components';
import { useErrorModalContext } from 'op-contexts';
import { default as React, useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router';
import { Prompt, useHistory } from 'react-router-dom';
import { FormRow, LoadingSpinner } from 'shared-components/components';
import { SelectFieldBase, TextAreaField } from 'shared-components/components/FormikComponents';
import { RemoveOutlineCircle } from 'shared-components/images';
import { usePreventUnload } from 'shared-components/utils/CustomHooks';
import { styled } from '@mui/system';
import {
  AddIcon,
  AddReferralWrapper,
  FormWrapper,
  GreyLine,
  HeaderTitle,
  LEAVE_PAGE_WARNING,
  SectionTitle,
  SectionTitleWrapper,
  SectionWrapper,
} from '../common';
import { mapListData } from 'op-utils';
import {
  ALL_REFERRAL_EXISTING_SERVICE_QUERY,
  CREATE_REFERRAL,
  DELETE_REFERRAL,
  DELETE_REFERRAL_EXISTING_SERVICE,
  REFERRAL_EXISTING_SERVICE_QUERY,
  SUBMIT_REFERRAL_EXISTING_SERVICE,
  UPDATE_REFERRAL,
} from '../graphql/ManagementPlanQueries';
import { formRoutes, managementDashboardLink } from '../routes';
import { Stack } from '@mui/material';

const StyledFormRow = styled(FormRow)`
  margin-top: 0.3rem !important;
  margin-bottom: 0.3rem !important;

  label {
    padding-left: 1rem !important;
  }
`;

const StyledRemoveSpan = styled('span')`
  margin-top: 1.5rem !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
`;

interface Props {
  patient: any;
  listData: any;
}

interface FormikProps {
  values: any;
  setFieldValue: any;
}

const ReferralPage = ({ patient, listData }: Props): JSX.Element => {
  const componentName = 'referral';
  const history = useHistory();
  const { patientId, formId } = useParams<{ patientId: string; formId: string }>();
  const { setError } = useErrorModalContext();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [preventUnload, setPreventUnload] = useState(true);

  const [createReferralMutation] = useMutation(CREATE_REFERRAL, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: REFERRAL_EXISTING_SERVICE_QUERY, variables: { formId } }],
  });
  const [deleteReferralMutation] = useMutation(DELETE_REFERRAL, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: REFERRAL_EXISTING_SERVICE_QUERY, variables: { formId } }],
  });
  const [updateReferralMutation] = useMutation(UPDATE_REFERRAL);
  const [submitMutation] = useMutation(SUBMIT_REFERRAL_EXISTING_SERVICE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ALL_REFERRAL_EXISTING_SERVICE_QUERY, variables: { patientId } }],
  });
  const [deleteMutation] = useMutation(DELETE_REFERRAL_EXISTING_SERVICE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: REFERRAL_EXISTING_SERVICE_QUERY, variables: { formId } }],
  });

  const {
    loading: formLoading,
    data: formData,
    error: formError,
  } = useQuery(REFERRAL_EXISTING_SERVICE_QUERY, {
    variables: { formId },
  });

  const initialValues = { referral: formData?.referralExistingServices?.referral };

  useEffect(() => {
    if (formError) return setError();
  }, [formError]);

  useEffect(() => {
    if (showDeleteModal || showSubmitModal) {
      setPreventUnload(false);
    }
  }, [showDeleteModal, showSubmitModal]);

  const createReferral = () => {
    createReferralMutation({ variables: { referralExistingServiceId: formId } });
  };

  const deleteReferral = (referralId: string) => deleteReferralMutation({ variables: { referralId } });

  const updateReferral = (referralId: string, fieldName: string, value: string) => {
    // If Type is no longer 'Other', clear 'Description' from the database
    if (fieldName === 'serviceType' && value !== 'Other') {
      updateReferralMutation({ variables: { referralId, [fieldName]: value, description: '' } });
    } else {
      updateReferralMutation({ variables: { referralId, [fieldName]: value } });
    }
  };

  const submitReferrals = () => {
    submitMutation({ variables: { referralExistingServiceId: formId } });
    history.push(generatePath(managementDashboardLink, { patientId }));
  };

  const deleteReferrals = () => {
    deleteMutation({ variables: { referralExistingServiceId: formId } });
    history.push(generatePath(managementDashboardLink, { patientId }));
  };

  const formStandardValues = {
    updatedBy: '', // TEMP
    patient: patient,
    saveAndExitLink: generatePath(managementDashboardLink, { patientId }),
    setDeleteModalIsOpen: setShowDeleteModal, // TEMP
    deleteModal: () => {},
    submitForm: () => setShowSubmitModal(true),
    showSaveAndExit: false,
    createAmendmentAndRedirect: () => {}, // TEMP
    handleValidation: () => {},
    pageNumber: 1,
    sectionTitle: formRoutes.consultation.sectionTitle,
    continueLink: '',
  };

  usePreventUnload(preventUnload);

  return (
    <>
      <FormContainer
        isLocked={false}
        {...formStandardValues}
        handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
          void event;
        }}
        sidePanelWidth={'756px'}
        showMxPlanSidePanel={true}
        showDT={false}
        activeTabOption={componentName}>
        {formLoading && <LoadingSpinner />}
        {formData && formData.referralExistingServices?.referral && (
          <FormWrapper>
            <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
              {({ values }: FormikProps) => (
                <Form>
                  <HeaderTitle data-test-id="referral-page-header">Referral</HeaderTitle>
                  <SectionTitleWrapper>
                    <SectionTitle>REFERRAL</SectionTitle>
                    <GreyLine />
                  </SectionTitleWrapper>
                  <FieldArray
                    name={componentName}
                    render={({ remove }) => {
                      const referrals = values?.referral;
                      const referralLength = referrals?.length ?? 0;

                      return (
                        <div>
                          {referralLength > 0 &&
                            referrals?.map((referral: any, index: number) => (
                              <SectionWrapper key={index}>
                                <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                                  <Stack sx={{ width: '100%' }}>
                                    <StyledFormRow fieldLabel={`Referral #${index + 1}`} fieldName="name">
                                      <Field
                                        name={`referral.${index}.name`}
                                        component={TextAreaField}
                                        placeholder={'Type referral name'}
                                        updateMutation={(value: any) => updateReferral(referral.id, 'name', value)}
                                      />
                                    </StyledFormRow>
                                    <StyledFormRow fieldLabel="Type" fieldName={`serviceType.${index}`}>
                                      <Field
                                        name={`referral.${index}.serviceType`}
                                        component={SelectFieldBase}
                                        options={mapListData(listData?.serviceTypeOptions)}
                                        placeholder="Please select..."
                                        updateMutation={(value: any) =>
                                          updateReferral(referral.id, 'serviceType', value)
                                        }
                                      />
                                    </StyledFormRow>
                                    {referral.serviceType === 'Other' && (
                                      <StyledFormRow fieldName="description">
                                        <Field
                                          name={`referral.${index}.description`}
                                          component={TextAreaField}
                                          placeholder={'Please specify other referral type'}
                                          updateMutation={(value: any) =>
                                            updateReferral(referral.id, 'description', value)
                                          }
                                        />
                                      </StyledFormRow>
                                    )}
                                    <StyledFormRow fieldLabel="Contact details" fieldName="contactDetails">
                                      <Field
                                        name={`referral.${index}.contactDetails`}
                                        component={TextAreaField}
                                        placeholder={
                                          'Please provide contact details such as phone number, email address or clinic address'
                                        }
                                        updateMutation={(value: any) =>
                                          updateReferral(referral.id, 'contactDetails', value)
                                        }
                                        rows={3}
                                      />
                                    </StyledFormRow>
                                  </Stack>
                                  <Stack sx={{ width: '80px' }}>
                                    {referralLength > 1 && (
                                      <StyledRemoveSpan
                                        data-test-id={`remove-icon-referral-${index}`}
                                        onClick={() => {
                                          remove(index);
                                          deleteReferral(referral.id);
                                        }}>
                                        <RemoveOutlineCircle />
                                      </StyledRemoveSpan>
                                    )}
                                  </Stack>
                                </Stack>
                              </SectionWrapper>
                            ))}
                          <Stack>
                            <Stack>
                              <StyledFormRow>
                                <AddReferralWrapper
                                  onClick={() => {
                                    createReferral();
                                  }}
                                  data-test-id="add-referral-button">
                                  <AddIcon />
                                  Add another referral
                                </AddReferralWrapper>
                              </StyledFormRow>
                            </Stack>
                            {referralLength > 1 && <Stack />}
                          </Stack>
                        </div>
                      );
                    }}
                  />
                </Form>
              )}
            </Formik>
          </FormWrapper>
        )}
      </FormContainer>
      {showDeleteModal && (
        <ModalDeleteForm
          isOpen={showDeleteModal}
          title="Discard New Referral Details?"
          setIsOpen={setShowDeleteModal}
          deleteFormMutation={() => deleteReferrals()}
        />
      )}
      {showSubmitModal && (
        <ModalSubmitForm
          isOpen={showSubmitModal}
          setIsOpen={setShowSubmitModal}
          submitFormMutation={() => {
            submitReferrals();
          }}
          submitText={'Submit referral'}
        />
      )}
      <Prompt when={preventUnload} message={LEAVE_PAGE_WARNING} />
    </>
  );
};

export default ReferralPage;
