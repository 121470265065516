// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Collapse, Button } from '@mui/material';

import './AppointmentDetails.scss';
import AppointmentDetailsViewModel from './AppointmentDetailsViewModel';
import AppointmentDetailsStaffViewModel from './AppointmentDetailsStaffViewModel';
import CovidAppointment from '../Covid/CovidAppointment';
import { DateTimeConverter } from '../../utils';
import { DeviceUtilities } from 'shared-components/utils';
import { AppointmentDetailLocation, AppointmentObject } from '../../models';
import { Success, Info } from 'shared-components/images';
import { theme } from 'theme';
import {
  EventOutlined as EventOutlinedIcon,
  HeadsetMicOutlined as HeadsetMicOutlinedIcon,
  AssignmentIndOutlined as AssignmentIndOutlinedIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
} from '@mui/icons-material';

const UPCOMING_APPOINTMENT_ON_SAME_DAY_MESSAGE = 'You have another appointment occurring on the same day';
const CONTACT_DETAILS_TITLE = 'Contact details';
const ICONS = {
  EVENT: 'event',
  LOCATION: 'location',
  STAFF: 'staff',
  SUCCESS: 'success',
  INFO: 'info',
  HEADSET: 'headset',
};
const CONFIRMED_APPOINTMENT_STATUS = ['initial consultation', 'follow up', 'follow-up consultation'];

/**
 * Interfaces
 */
interface Props {
  appointment: AppointmentObject;
  isSameDayAppointment: boolean;
  applyTransitionToDetailsPanel: boolean;
}

interface State {
  isContactOpen: boolean;
  appointmentId: string;
}

class AppointmentDetails extends Component<Props, State> {
  private appointmentDetailsViewModel: AppointmentDetailsViewModel;
  private containerRef: HTMLDivElement | null = null;

  public constructor(props: Props) {
    super(props);

    // Generate the view model used for business logic
    this.appointmentDetailsViewModel = new AppointmentDetailsViewModel();

    this.state = {
      isContactOpen: false,
      appointmentId: props.appointment.id,
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    // Need to close tooltip modals if the props change, and the change is a different appointment has been loaded.
    if (props.appointment.id !== state.appointmentId) {
      return {
        appointmentId: props.appointment.id,
        isContactOpen: false,
      };
    }

    return state;
  }

  public render(): JSX.Element {
    return this.generateDetailsListing();
  }

  /**
   * Generates the details listing.
   * @returns {JSX.Element} A JSX.Element that contains one or more child elements to render.
   */
  private generateDetailsListing = (): JSX.Element => {
    // If there is no appointment object, return an empty fragment.
    if (this.props.appointment === null) {
      return <React.Fragment />;
    }
    // Else, return the details listing.
    return (
      <div className={this.toggleStyles()}>
        {this.generateDetailsPage()}
        {this.generateUpcomingAppointmentDetailsMessage()}
      </div>
    );
  };

  /**
   * Toggles the display of the selected appointment details pane in Mobile view
   * by returning the appropriate CSS styles.
   * @returns {string} The CSS classes to be applied.
   */
  private toggleStyles = (): string => {
    const { applyTransitionToDetailsPanel } = this.props;

    if (DeviceUtilities.isMobileDevice() && applyTransitionToDetailsPanel) {
      return 'appointment-details-container mobile-transition-effect';
    }
    return 'appointment-details-container';
  };

  private generateDetailsPage = (): JSX.Element => {
    const detailsListing = this.appointmentDetailsViewModel.generateDetailsDataDictionary(this.props.appointment);
    if (detailsListing === null) {
      return <React.Fragment />;
    }
    const remoteTypes = [
      'Telehealth Phone',
      'Telehealth Video',
      'Consultation Phone',
      'Consultation Video',
      'Telehealth Consult',
    ];
    let appointmentType = this.props.appointment.appointmentType;
    if (appointmentType === undefined) {
      appointmentType = '';
    }
    return (
      <div
        ref={(ref): void => {
          this.containerRef = ref;
        }}>
        {this.generateTypeTitle()}
        {this.generateConfirmation()}
        {this.generateTimeDetails(detailsListing.dateTime)}
        {remoteTypes.includes(appointmentType) && this.generateTelehealthInfo()}
        {this.generateLocationDetails(detailsListing.location)}
        {this.generateStaffDetails(detailsListing.staff)}
        {!remoteTypes.includes(appointmentType) && (
          <div className="appointment-details-covid-panel">
            <div>
              <CovidAppointment />
            </div>
          </div>
        )}
      </div>
    );
  };

  /**
   * Generates an upcoming appointment details message element.
   * @returns {JSX.Element | null} A JSX.Element to render, or null if the message is not applicable for the current selected appointment.
   */
  private generateUpcomingAppointmentDetailsMessage = (): JSX.Element | null => {
    let sameDayAppointmentMessageElement = null;
    if (this.props.isSameDayAppointment) {
      sameDayAppointmentMessageElement = (
        <div className="same-day-appointments-exist-message">{UPCOMING_APPOINTMENT_ON_SAME_DAY_MESSAGE}</div>
      );
    }
    return sameDayAppointmentMessageElement;
  };

  private generateTypeTitle = (): JSX.Element => {
    const isPending = this.isPending();
    const classColour = isPending ? 'blue-text' : '';
    return <div className={`appointment-details-title ${classColour}`}>{this.props.appointment.appointmentType}</div>;
  };

  private generateConfirmation = (): JSX.Element => {
    const isPending = this.isPending();
    const appointmentDate = this.props.appointment.startTime ? new Date(this.props.appointment.startTime) : null;
    if (appointmentDate === null) {
      return <div />;
    }

    let icon = this.generateIcon(ICONS.INFO);
    let confirmationText = 'Time May Vary';
    const entity = this.props.appointment.entity;
    let confirmationMsg =
      entity && ['SA', 'VIC', 'QLD'].includes(entity)
        ? 'Appointment time will be confirmed by Friday the week before the appointment date.'
        : 'Appointment time will be confirmed by Thursday the week before the appointment date.';

    if (!isPending) {
      icon = this.generateIcon(ICONS.SUCCESS);
      confirmationText = 'Confirmed';
      confirmationMsg =
        'These appointments have been confirmed in our system and we will notify you should they change.';
    }

    return (
      <div className="row">
        <div id="appointment-confirmation">
          {icon}
          <div className="appointment-details-confirmation">{confirmationText}</div>
        </div>
        <div className="appointment-details-status-sub-heading no-bold-text">{confirmationMsg}</div>
      </div>
    );
  };

  private generateIcon = (icon: string): JSX.Element => {
    switch (icon) {
      case ICONS.LOCATION:
        return <LocationOnOutlinedIcon htmlColor={theme.palette.grey[600]} className="icon" />;
      case ICONS.STAFF:
        return <AssignmentIndOutlinedIcon htmlColor={theme.palette.grey[600]} className="icon" />;
      case ICONS.SUCCESS:
        return <Success className="icon icon-green" />;
      case ICONS.INFO:
        return <Info className="icon icon-blue" />;
      case ICONS.HEADSET:
        return <HeadsetMicOutlinedIcon htmlColor={theme.palette.grey[600]} className="icon" />;
      default:
        return <EventOutlinedIcon className="icon" htmlColor={theme.palette.grey[600]} />;
    }
  };

  private generateTimeDetails = (timeDetails: {
    date: string;
    time: string;
    durationInMinutes?: number;
  }): JSX.Element => {
    let timestamp = timeDetails.time;
    const isPending = this.isPending();
    const currentLocale = window.navigator.language;
    const appointmentDate = this.props.appointment.startTime ? new Date(this.props.appointment.startTime) : null;
    if (appointmentDate === null) {
      return <div />;
    }
    let formattedDateComponents;
    if (appointmentDate) {
      formattedDateComponents = DateTimeConverter.getFormattedDateComponents(
        currentLocale,
        appointmentDate,
      ).formattedComponents;
    } else {
      formattedDateComponents = { dayOfWeek: '', dayOfMonth: '', time: '' }; // Default
    }

    if (timeDetails.durationInMinutes) {
      timestamp += ` (${timeDetails.durationInMinutes}'mins')`;
    }
    timestamp = isPending ? `${formattedDateComponents.dayOfWeek}, TBC` : timestamp;
    return (
      <div className="row">
        {this.generateIcon(ICONS.EVENT)}
        <div>
          <div className="appointment-details-date-heading">
            <span>{timeDetails.date}</span>
          </div>
          <div className="appointment-details-sub-heading">{timestamp}</div>
        </div>
      </div>
    );
  };

  private generateTelehealthInfo = (): JSX.Element => {
    return (
      <div className="row">
        <div id="telehealth-appointment">
          {this.generateIcon(ICONS.HEADSET)}
          <div>
            <div className="appointment-details-sub-heading">
              <span>{'Telehealth Appointment'}</span>
            </div>
            <div className="appointment-details-sub-heading no-bold-text">
              "As this appointment won't be in-person, you do not need to come into the clinic unless staff have advised
              otherwise. If you have any questions, please contact the clinic directly."
            </div>
          </div>
        </div>
      </div>
    );
  };

  private generateLocationDetails = (locationDetails: AppointmentDetailLocation): JSX.Element => {
    const content: JSX.Element[] = [];
    if (locationDetails.department) {
      content.push(
        <div className="appointment-details-sub-heading" key="department-name">
          {locationDetails.name}
          {locationDetails.address.city && <span>{`, ${locationDetails.address.city}`}</span>}
        </div>,
      );
    }

    if (locationDetails.address.formattedAddress && locationDetails.address.mapSearchUrl) {
      const { formattedAddress, mapSearchUrl } = locationDetails.address;
      content.push(
        <div className="appointment-details-location-item" key="address-details">
          <a
            id="appointments-details-location-detail"
            className="detail"
            target="_blank"
            rel="noopener noreferrer"
            href={mapSearchUrl}>
            {formattedAddress}
          </a>
        </div>,
      );
    }

    if (true || locationDetails.phone) {
      // const phone = locationDetails.phone;
      const phone = '0400000000';
      content.push(
        <div className="appointment-details-location-item" key="phone-details">
          <Button
            sx={{
              textTransform: 'unset',
              outline: '0px !important',
              border: '0px !important',
              WebkitTextFillColor: theme.palette.text.primary,
              fontSize: '16px',
            }}
            onClick={() => this.toggleCollapse()}
            endIcon={this.state.isContactOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
            {CONTACT_DETAILS_TITLE}
          </Button>
          <Collapse in={this.state.isContactOpen}>
            <div className="appointment-details-phone-label">
              <div>Phone:</div>
              <div>
                <a className="department-phone-number" href={`tel:${phone}`}>
                  {phone}
                </a>
              </div>
            </div>
          </Collapse>
        </div>,
      );
    }

    return (
      <div className="row">
        {this.generateIcon(ICONS.LOCATION)}
        <div className="appointment-details-location-items">{content}</div>
      </div>
    );
  };

  private toggleCollapse = (): void => {
    const collapse = !this.state.isContactOpen;
    this.setState({
      isContactOpen: collapse,
    });
  };

  private isPending = (): boolean => {
    const appointmentType = this.props.appointment.appointmentType ? this.props.appointment.appointmentType : '';
    const isConfirmedType = CONFIRMED_APPOINTMENT_STATUS.includes(appointmentType.toLowerCase());
    const cutOffDate = DateTimeConverter.getAppointmentCutOffDate(this.props.appointment.entity);
    const appointmentDate = this.props.appointment.startTime ? new Date(this.props.appointment.startTime) : null;
    return appointmentDate ? appointmentDate >= cutOffDate && !isConfirmedType : true;
  };

  private generateStaffDetails = (details: { name?: string; prefix?: string; qualification?: string }): JSX.Element => {
    const staffModel = new AppointmentDetailsStaffViewModel(details.name, details.qualification, details.prefix);
    const staffName = staffModel.getStaffDisplayName();
    const staffTitle = staffModel.getStaffDisplayTitle();

    let staffDetails = (
      <div id="appointments-details-staff-title-detail" className="appointment-details-sub-heading">
        See front desk
      </div>
    );

    if (staffName) {
      staffDetails = (
        <div>
          <div id="appointments-details-staff-title-detail" className="appointment-details-sub-heading">
            {staffName}
          </div>
          {staffTitle && <div id="appointments-details-staff-title-subtext">{staffTitle}</div>}
        </div>
      );
    }

    return (
      <div className="appointment-details-staff-panel">
        {this.generateIcon(ICONS.STAFF)}
        <div>
          <div className="appointment-details-sub-heading no-bold-text">Staff in attendance</div>
          {staffDetails}
        </div>
      </div>
    );
  };
}

//@ts-ignore
export default AppointmentDetails;
