import React, { ReactNode } from 'react';
import { Grid, TextField, Checkbox, FormControlLabel, Select as MUISelect, MenuItem, GridSize } from '@mui/material';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { FieldProps, getIn } from 'formik';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material';

interface InputProps extends FieldProps {
  children: ReactNode;
  units?: string;
  label: string;
  inputXs: number;
  info?: ReactNode;
  warning?: ReactNode;
  reserveUnitsSpace?: boolean;
}

const Label = ({ children }: any) => (
  <Grid item xs={4}>
    <b>{children}</b>
  </Grid>
);

const InputInnerContainer = ({ children, xs }: any) => (
  <Grid item container xs={xs} spacing={1} alignItems="center">
    {children}
  </Grid>
);

const ErrorMessage = styled('div')`
  color: ${(props) => props.theme.palette.error.main};
  font-size: 13px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  gap: 5px;
  .MuiSvgIcon-root {
    font-size: 17px;
  }
`;

const InputError = (props: any) => (
  <>
    <Grid item xs={4} />
    <Grid item xs={8} className="input-error-message" {...props}>
      <ErrorMessage>
        <ErrorOutlineIcon />
        <span style={{ textAlign: 'left' }}>{props.message}</span>
      </ErrorMessage>
    </Grid>
  </>
);

const InfoMessage = styled('div')`
  font-size: 13px;
  padding-top: 0;
  padding-bottom: -10px;
  display: flex;
  align-items: center;
  gap: 5px;
  .MuiSvgIcon-root {
    fill: ${(props) => props.theme.palette.info.main};
    font-size: 17px;
  }
`;

const WarningMessage = styled('div')`
  font-size: 13px;
  padding-top: 0;
  padding-bottom: -10px;
  display: flex;
  align-items: center;
  gap: 5px;
  .MuiSvgIcon-root {
    fill: ${(props) => props.theme.palette.warning.main};
    font-size: 17px;
  }
`;

const InputInfo = ({ message }: any) => (
  <>
    <Grid item xs={4} />
    <Grid item xs={8}>
      <InfoMessage>
        <ErrorOutlineIcon />
        <span style={{ textAlign: 'left' }}>{message}</span>
      </InfoMessage>
    </Grid>
  </>
);

const InputWarning = ({ message }: any) => (
  <>
    <Grid item xs={4} />
    <Grid item xs={8}>
      <WarningMessage>
        <ErrorOutlineIcon />
        <span style={{ textAlign: 'left' }}>{message}</span>
      </WarningMessage>
    </Grid>
  </>
);

export const FormRow = (props: any): JSX.Element => (
  <Grid item container spacing={1} alignItems="center" {...props}>
    {props.children}
  </Grid>
);

export const Input = ({
  children,
  units,
  label,
  field,
  inputXs = 7,
  info,
  warning,
  reserveUnitsSpace = true,
  ...props
}: InputProps): JSX.Element => {
  const error = getIn(props.form.errors, field.name);
  const touched = getIn(props.form.touched, field.name);
  return (
    <FormRow data-test-id={`${field.name}-row`}>
      <Label>{label}</Label>
      <InputInnerContainer xs={inputXs}>
        <Grid item xs={reserveUnitsSpace ? 9 : 12}>
          {children}
        </Grid>
        {reserveUnitsSpace && (
          <Grid item xs={3}>
            {units && <b>{units}</b>}
          </Grid>
        )}
      </InputInnerContainer>
      {info && <InputInfo message={info} />}
      {warning && <InputWarning message={warning} />}
      {touched && error && <InputError data-test-id={`${field.name}-error`} message={error} />}
    </FormRow>
  );
};

// others lol

const CheckBoxLabel = styled(FormControlLabel)`
  && {
    display: flex;
    flex: 1;
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    border-radius: 4px;
    margin: 0;
    user-select: none;
  }
  .Mui-checked {
    color: ${(props) => props.theme.palette.primary.main};
  }
  .MuiCheckbox-root {
    padding: 7px;
  }
  .MuiFormControlLabel-label {
    font-size: 11pt;
  }
`;

interface GridItemProps {
  xs?: GridSize;
  style?: React.CSSProperties;
}
interface NumberWithCheckboxInputProps extends InputProps {
  onCheckboxChange: (_: any, checked: boolean) => void;
  min?: number;
  max?: number;
  checkboxLabel?: string;
  checkboxValue?: boolean;
  gridItemProps?: { number?: GridItemProps; unit?: GridItemProps; checkbox?: GridItemProps };
}

export const NumberWithCheckboxInput = ({
  units,
  label,
  checkboxLabel,
  checkboxValue,
  onCheckboxChange,
  gridItemProps,
  ...props
}: NumberWithCheckboxInputProps): JSX.Element => (
  <Input {...{ label, ...props }}>
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={gridItemProps?.number?.xs || 4} style={gridItemProps?.number?.style}>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          fullWidth
          {...props.field}
          {...props}
          InputProps={{ inputProps: { max: props.max, min: props.min } }}
        />
      </Grid>
      <Grid item xs={gridItemProps?.unit?.xs || 1} style={gridItemProps?.unit?.style}>
        {units && <b>{units}</b>}
      </Grid>
      <Grid item xs={gridItemProps?.checkbox?.xs || 7} style={gridItemProps?.checkbox?.style}>
        <CheckBoxLabel
          label={checkboxLabel}
          labelPlacement="end"
          control={<Checkbox color="default" onChange={onCheckboxChange} checked={checkboxValue} />}
        />
      </Grid>
    </Grid>
  </Input>
);

interface NumberInputProps extends InputProps {
  min?: number;
  max?: number;
  error?: boolean | undefined;
}

export const NumberInput = ({ min, max, label, ...props }: NumberInputProps): JSX.Element => {
  const theme = useTheme();
  const borderColorOne = props.error ? theme.palette.error.main : theme.palette.secondary.main;
  const borderColorTwo = props.error ? theme.palette.error.main : theme.palette.primary.main;
  return (
    <Input {...{ label, ...props }}>
      <TextField
        variant="outlined"
        size="small"
        type="number"
        fullWidth
        {...props.field}
        {...props}
        InputProps={{ inputProps: { min, max } }}
        sx={{
          '.MuiOutlinedInput-notchedOutline:-moz-dir': {
            borderColor: `${borderColorOne} !important`,
            border: '1px solid',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `${borderColorTwo} !important`,
            border: '1px solid',
          },
        }}
      />
    </Input>
  );
};

const Select = styled(MUISelect)`
  height: 40px;
  .MuiSelect-root {
    padding: 10px;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.primary.main} !important;
    border: 1px solid;
  }
`;

export interface SelectOption {
  value: number | string;
  label: string;
}

interface SelectInputProps extends InputProps {
  options: SelectOption[];
}

export const SelectInput = ({ label, options, ...props }: SelectInputProps): JSX.Element => (
  <Input {...{ label, ...props }}>
    <Select
      variant="outlined"
      fullWidth
      data-cy={props.field.name}
      {...props.field}
      {...props}
      error={getIn(props.form.errors, props.field.name)}>
      {options?.map((opt: SelectOption) => (
        <MenuItem data-cy={opt.label} key={opt.value} value={opt.value}>
          {opt.label}
        </MenuItem>
      ))}
    </Select>
  </Input>
);

interface TextInputProps extends InputProps {
  multiline: boolean;
  rows: number;
}
export const TextInput = ({ label, ...props }: TextInputProps): JSX.Element => {
  const error = getIn(props.form.errors, props.field.name);
  return (
    <Input {...{ label, ...props }}>
      <TextField variant="outlined" fullWidth size="small" name={props.field.name} error={error} label="" {...props} />
    </Input>
  );
};

interface FormContainerProps {
  children: (JSX.Element | boolean)[];
}

export const FormContainer = (props: FormContainerProps): JSX.Element => (
  <Grid container spacing={3}>
    {props.children}
  </Grid>
);

interface OutputProps {
  dataCy?: string;
  label: string;
  value: string | number;
  units?: string;
}

export const Output = ({ label, value, units, dataCy }: OutputProps): JSX.Element => (
  <FormRow>
    <Label>{label}</Label>
    <Grid item xs={6} data-cy={dataCy}>
      {value} {units && <b>{units}</b>}
    </Grid>
  </FormRow>
);

const StyledCheckbox = styled(Checkbox)`
  && {
    margin: -12px;
    &.Mui-checked {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

export const CheckboxInput = ({ label, ...props }: InputProps): JSX.Element => (
  <Input {...{ label, ...props }}>
    <StyledCheckbox size="medium" color="default" {...props.field} />
  </Input>
);
