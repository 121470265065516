// eslint-disable-next-line no-use-before-define
import React from 'react'; //, { Fragment }
import { FormBuilderComponentProps } from '../../FormBuilderInterfaces';
import { fieldMeetsConditions } from '../../common';
import { CheckboxTile } from 'gc-ui';

export default function FBCheckBoxTile(props: FormBuilderComponentProps): JSX.Element {
  const { component, formikProps, handleUpdate } = props;

  return (
    <CheckboxTile
      key={component.field.name}
      id={component.field.name}
      name={component.field.name}
      checked={formikProps.values[component.field.name] ?? false}
      label={component.label}
      onChange={(value) => {
        handleUpdate(formikProps, component, value);
        // TODO EVE-10683 an unfortunate hack that wasn't necessary once, using setFieldTouched causes state issues (shows required errors when filled, visa versa)
        formikProps.touched[component.field.name] = true; // should be setFieldTouched or nothing at all
        // ensure validation immediately appears on interaction with fields that update using onChange event, (otherwise will validate on blur)
        formikProps.handleBlur(component.field.name);
      }}
      disabled={fieldMeetsConditions(formikProps.values, component.disabled, false)}
      // readOnly={fieldMeetsConditions(formikProps.values, component.disabled, false)} doesn't have one
    />
  );
}
