// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'gc-ui';

import './ModalSaveError.scss';

import { ButtonType } from 'shared-components/enums';
import { GCButton } from 'shared-components/components/FormFields';
import { Oops } from 'shared-components/images';
import { MODAL_HEADER, ERROR, CONTACT_SUPPORT, SEE_FRONT_DESK, HOME, LOGOUT } from './constants';

interface Props {
  isPSO: boolean;
  isOpen: boolean;
  callbackRouting?: () => void;
}

class ModalInfo extends Component<Props> {
  public render(): JSX.Element {
    const { isOpen, isPSO } = this.props;
    return (
      <Fragment>
        <Modal open={isOpen} className="modal-gc modal-save-error">
          <ModalHeader>{MODAL_HEADER}</ModalHeader>
          <ModalBody>
            <div>
              <Oops />
            </div>
            <div className="centre-text">{isPSO ? this.renderPSOContent() : this.renderPatientContent()}</div>
            <div className="modal-error-buttons">
              <GCButton
                name="return"
                title={this.textForButton()}
                onClick={(e): void => {
                  e.preventDefault();
                  if (this.props.callbackRouting) {
                    this.props.callbackRouting();
                  }
                }}
                type={ButtonType.WHITE}
              />
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }

  private renderPSOContent = (): JSX.Element => {
    return (
      <Fragment>
        <div>{ERROR}</div>
        <div>{CONTACT_SUPPORT}</div>
      </Fragment>
    );
  };

  private renderPatientContent = (): JSX.Element => {
    return (
      <Fragment>
        <div>{ERROR}</div>
        <div>{SEE_FRONT_DESK}</div>
      </Fragment>
    );
  };

  private textForButton = (): string => {
    const { isPSO } = this.props;
    return isPSO ? HOME : LOGOUT;
  };
}

export default ModalInfo;
