import { gql } from '@apollo/client';

export const CREATE_DIAGNOSIS = gql`
  mutation createDiagnosis($patientId: ID!, $diagnosisInput: DiagnosisInputType, $secondaryInput: DiagnosisInputType) {
    createDiagnosis(patientId: $patientId, diagnosisInput: $diagnosisInput, secondaryInput: $secondaryInput) {
      diagnosis {
        id
        stageType
        draft
        stageTCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageNCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageMCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageTCodeClinical {
          id
          subCode
          description
          mainCode
        }
        stageMCodeClinical {
          id
          subCode
          description
          mainCode
        }
        stageNCodeClinical {
          id
          subCode
          description
          mainCode
        }
        morphology {
          id
          displayName
        }
        otherInfo
        laterality
        diagnosisDate
        diagnosisCode {
          id
          diagnosisCode
          diagnosisDescription
          stageNumber
          tumourStream {
            id
            name
          }
        }
        isPrimaryDiagnosis
        relatedPrimaryDiagnosis {
          id
          stageType
          stageTCodePathological {
            id
            subCode
            description
            mainCode
          }
          stageNCodePathological {
            id
            subCode
            description
            mainCode
          }
          stageMCodePathological {
            id
            subCode
            description
            mainCode
          }
          stageTCodeClinical {
            id
            subCode
            description
            mainCode
          }
          stageMCodeClinical {
            id
            subCode
            description
            mainCode
          }
          stageNCodeClinical {
            id
            subCode
            description
            mainCode
          }
          morphology {
            id
            displayName
          }
          otherInfo
          laterality
          diagnosisDate
          diagnosisCode {
            id
            diagnosisCode
            diagnosisDescription
            stageNumber
            tumourStream {
              id
              name
            }
          }
          isPrimaryDiagnosis
        }
      }
    }
  }
`;

export const GET_PATIENT_DIAGNOSIS_LIST = gql`
  query getPatientDiagnosisList($patientId: ID!, $diagnosisType: String!, $secondaryCode: String) {
    diagnosisList(patientId: $patientId, diagnosisType: $diagnosisType, secondaryCode: $secondaryCode) {
      id
      stageType
      draft
      stageTCodePathological {
        id
        subCode
        description
        mainCode
      }
      stageNCodePathological {
        id
        subCode
        description
        mainCode
      }
      stageMCodePathological {
        id
        subCode
        description
        mainCode
      }
      stageTCodeClinical {
        id
        subCode
        description
        mainCode
      }
      stageMCodeClinical {
        id
        subCode
        description
        mainCode
      }
      stageNCodeClinical {
        id
        subCode
        description
        mainCode
      }
      morphology {
        id
        displayName
      }
      otherInfo
      laterality
      diagnosisDate
      diagnosisCode {
        id
        diagnosisCode
        diagnosisDescription
        stageNumber
        tumourStream {
          id
          name
        }
      }
      isPrimaryDiagnosis
      relatedPrimaryDiagnosis {
        id
        stageType
        stageTCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageNCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageMCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageTCodeClinical {
          id
          subCode
          description
          mainCode
        }
        stageMCodeClinical {
          id
          subCode
          description
          mainCode
        }
        stageNCodeClinical {
          id
          subCode
          description
          mainCode
        }
        morphology {
          id
          displayName
        }
        otherInfo
        laterality
        diagnosisDate
        diagnosisCode {
          id
          diagnosisCode
          diagnosisDescription
          stageNumber
          tumourStream {
            id
            name
          }
        }
        isPrimaryDiagnosis
      }
    }
  }
`;

export const CHECK_DIAGNOSIS_CAREPLAN_STATUS = gql`
  query CheckDiagnosisToCareplanStatus($diagnosisId: ID!, $careplanId: ID!) {
    checkDiagnosisToCareplanStatus(diagnosisId: $diagnosisId, careplanId: $careplanId) {
      id
      status
    }
  }
`;

export const GET_DIAGNOSIS = gql`
  query getPatientDiagnosis($diagnosisId: ID!) {
    diagnosis(id: $diagnosisId) {
      ajccMajorVersion
      medId
      id
      isPrimaryDiagnosis
      stageType
      draft
      hasTreatmentStarted
      disease {
        id
        diseaseId
        title
        chapter
      }
      stageTCodePathological {
        id
        subCode
        description
        mainCode
      }
      stageNCodePathological {
        id
        subCode
        description
        mainCode
      }
      stageMCodePathological {
        id
        subCode
        description
        mainCode
      }
      stageTCodeClinical {
        id
        subCode
        description
        mainCode
      }
      stageMCodeClinical {
        id
        subCode
        description
        mainCode
      }
      stageNCodeClinical {
        id
        subCode
        description
        mainCode
      }
      morphology {
        id
        displayName
        code
      }
      otherInfo
      laterality
      diagnosisDate
      diagnosisCode {
        id
        diagnosisCode
        diagnosisDescription
        stageNumber
        tumourStream {
          id
          name
        }
      }
      relatedPrimaryDiagnosis {
        medId
        id
        isPrimaryDiagnosis
        stageType
        draft
        disease {
          id
          diseaseId
          title
          chapter
        }
        stageTCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageNCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageMCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageTCodeClinical {
          id
          subCode
          description
          mainCode
        }
        stageMCodeClinical {
          id
          subCode
          description
          mainCode
        }
        stageNCodeClinical {
          id
          subCode
          description
          mainCode
        }
        morphology {
          id
          displayName
        }
        otherInfo
        laterality
        diagnosisDate
        diagnosisCode {
          id
          diagnosisCode
          diagnosisDescription
          stageNumber
          tumourStream {
            id
            name
          }
        }
      }
    }
    user {
      id
      isShowcaseUser
    }
  }
`;

export const UPDATE_DIAGNOSIS = gql`
  mutation updateDiagnosis(
    $id: ID!
    $stageType: String
    $diagnosisCodeId: ID
    $stageTCodePathological: ID
    $stageNCodePathological: ID
    $stageMCodePathological: ID
    $stageTCodeClinical: ID
    $stageNCodeClinical: ID
    $stageMCodeClinical: ID
    $morphology: String
    $laterality: String
    $otherInfo: String
    $diagnosisDate: String
    $isPrimaryDiagnosis: Boolean
    $relatedPrimaryDiagnosis: ID
    $draft: Boolean
    $isSubmitDiagnosisOnly: Boolean
    $disease: String
    $majorVersion: Int
  ) {
    updateDiagnosis(
      id: $id
      diagnosisCodeId: $diagnosisCodeId
      stageType: $stageType
      stageTCodePathological: $stageTCodePathological
      stageNCodePathological: $stageNCodePathological
      stageMCodePathological: $stageMCodePathological
      stageTCodeClinical: $stageTCodeClinical
      stageNCodeClinical: $stageNCodeClinical
      stageMCodeClinical: $stageMCodeClinical
      morphology: $morphology
      laterality: $laterality
      otherInfo: $otherInfo
      diagnosisDate: $diagnosisDate
      isPrimaryDiagnosis: $isPrimaryDiagnosis
      relatedPrimaryDiagnosis: $relatedPrimaryDiagnosis
      draft: $draft
      isSubmitDiagnosisOnly: $isSubmitDiagnosisOnly
      disease: $disease
      majorVersion: $majorVersion
    ) {
      diagnosis {
        id
        stageType
        draft
        hasTreatmentStarted
        disease {
          id
          diseaseId
          title
          chapter
        }
        stageTCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageNCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageMCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageTCodeClinical {
          id
          subCode
          description
          mainCode
        }
        stageMCodeClinical {
          id
          subCode
          description
          mainCode
        }
        stageNCodeClinical {
          id
          subCode
          description
          mainCode
        }
        morphology {
          id
          displayName
        }
        otherInfo
        laterality
        diagnosisDate
        diagnosisCode {
          id
          diagnosisCode
          diagnosisDescription
          stageNumber
          tumourStream {
            id
            name
          }
        }
        relatedPrimaryDiagnosis {
          id
          stageType
          draft
          stageTCodePathological {
            id
            subCode
            description
            mainCode
          }
          stageNCodePathological {
            id
            subCode
            description
            mainCode
          }
          stageMCodePathological {
            id
            subCode
            description
            mainCode
          }
          stageTCodeClinical {
            id
            subCode
            description
            mainCode
          }
          stageMCodeClinical {
            id
            subCode
            description
            mainCode
          }
          stageNCodeClinical {
            id
            subCode
            description
            mainCode
          }
          morphology {
            id
            displayName
          }
          otherInfo
          laterality
          diagnosisDate
          diagnosisCode {
            id
            diagnosisCode
            diagnosisDescription
            stageNumber
            tumourStream {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const LOAD_STAGE_MORPHOLOGY_LIST = gql`
  query morphologyLookupList($tumourStream: String!) {
    morphologyLookupList(tumourStream: $tumourStream) {
      id
      displayName
    }
  }
`;

export const LOAD_STAGE_TNM_LIST = gql`
  query tnmLookupGroupList($groupNumber: String!) {
    tnmLookupGroupList(groupNumber: $groupNumber) {
      id
      mainCode
      subCode
      description
    }
  }
`;

export const GET_ICD10_CATEGORY_LIST = gql`
  query icd10($isPrimaryDiagnosis: Boolean!) {
    diagnosisCategoryList(isPrimaryDiagnosis: $isPrimaryDiagnosis) {
      tumourStream
      diagnosisList {
        id
        diagnosisCode
        diagnosisDescription
        stageNumber
      }
    }
  }
`;

export const GET_ORDERED_DIAGNOSIS_LIST = gql`
  query diagnosisOrdered($patientId: ID!, $isPrimaryDiagnosis: Boolean) {
    diagnosisCodeList(patientId: $patientId, isPrimaryDiagnosis: $isPrimaryDiagnosis) {
      id
      diagnosisCode
      diagnosisDescription
      stageNumber
      tumourStream {
        id
        name
      }
    }
  }
`;

export const GET_CLINEX_CODES = gql`
  query clinexDiagnosis($patientId: ID!) {
    clinexDiagnosisCode(patientId: $patientId) {
      clinexUuid
      diagnosisList {
        id
        diagnosisCode
        diagnosisDescription
        stageNumber
        certainty
        tumourStream {
          id
          name
        }
      }
    }
  }
`;

export const CLINEX_DIAGNOSIS_DATA = gql`
  query clinexDiagnosisDataFull($diagnosisId: ID!) {
    clinexDiagnosisDataFull(diagnosisId: $diagnosisId) {
      diagnosisData
    }
  }
`;

export const LOG_SIGMA_MUTATION = gql`
  mutation logSigma($data: String!) {
    sigmaLogEvent(data: $data) {
      success
    }
  }
`;

export const LOG_CLINEX_MUTATION = gql`
  mutation logClinex($data: String!) {
    clinexLogEvent(data: $data) {
      success
    }
  }
`;

export const GET_DISEASES = gql`
  query diseases($ajccMajorVersion: Int!, $relatedIcdCode: String) {
    diseases(ajccMajorVersion: $ajccMajorVersion, relatedIcdCode: $relatedIcdCode) {
      id
      diseaseId
      title
      chapter
    }
  }
`;

export const GET_MORPHOLOGY_LIST_BY_DISEASE = gql`
  query morphologyListByDisease($diseaseId: String!, $majorVersion: Int!) {
    morphologyListByDisease(diseaseId: $diseaseId, majorVersion: $majorVersion) {
      id
      displayName
    }
  }
`;

export const GET_TNM_LIST_BY_DISEASE = gql`
  query tnmListByDisease($diseaseId: String!, $majorVersion: Int!) {
    tnmListByDisease(diseaseId: $diseaseId, majorVersion: $majorVersion) {
      id
      mainCode
      subCode
      description
    }
  }
`;
