import { gql } from '@apollo/client';

const OAR_FRAGMENT = gql`
  fragment OrganAtRisk on RoOrganAtRiskType {
    organ
    maxValue
    maxValueUnit
    meanValue
    meanValueUnit
    alara
    volumeConstraintData {
      firstValue
      firstValueUnit
      secondValue
      secondValueUnit
      operator
    }
    dConstraintData {
      dosetoPrefixOperator
      firstValue
      firstValueUnit
      secondValue
      secondValueUnit
      operator
    }
  }
`;

export const OAR_QUERY = gql`
  ${OAR_FRAGMENT}
  query roOrganAtRisk($siteGroupId: ID!) {
    roOrganAtRisk(siteGroupId: $siteGroupId) {
      ...OrganAtRisk
    }
  }
`;

export const OAR_TEMPLATE_QUERY = gql`
  ${OAR_FRAGMENT}
  query templateOrganAtRisk($templateId: ID!) {
    templateOrganAtRisk(templateId: $templateId) {
      ...OrganAtRisk
    }
  }
`;

export const ORGAN_AT_RISK_TEMPLATE_VALUE_VARIATION = gql`
  ${OAR_FRAGMENT}
  query organAtRiskTemplateValuesVariation($siteGroupId: ID!) {
    organAtRiskTemplateValuesVariation(siteGroupId: $siteGroupId) {
      templateId
      ...OrganAtRisk
    }
  }
`;

export const ADD_OAR = gql`
  ${OAR_FRAGMENT}
  mutation roCreateOrganAtRisk(
    $siteGroupId: ID!
    $alara: Boolean
    $organ: String
    $volumeConstraintData: [RoOrganAtRiskConstraintInputType]
    $dConstraintData: [RoOrganAtRiskConstraintInputType]
    $maxValue: [Float]
    $maxValueUnit: String
    $meanValue: [Float]
    $meanValueUnit: String
  ) {
    roCreateOrganAtRisk(
      siteGroupId: $siteGroupId
      alara: $alara
      organ: $organ
      maxValue: $maxValue
      maxValueUnit: $maxValueUnit
      meanValue: $meanValue
      meanValueUnit: $meanValueUnit
      volumeConstraintData: $volumeConstraintData
      dConstraintData: $dConstraintData
    ) {
      organAtRisk {
        siteGroupId
        ...OrganAtRisk
      }
    }
  }
`;

export const UPDATE_OAR = gql`
  ${OAR_FRAGMENT}
  mutation roUpdateOrganAtRisk(
    $siteGroupId: ID!
    $index: ID!
    $alara: Boolean
    $organ: String
    $volumeConstraintData: [RoOrganAtRiskConstraintInputType]
    $dConstraintData: [RoOrganAtRiskConstraintInputType]
    $maxValue: [Float]
    $maxValueUnit: String
    $meanValue: [Float]
    $meanValueUnit: String
  ) {
    roUpdateOrganAtRisk(
      siteGroupId: $siteGroupId
      index: $index
      alara: $alara
      organ: $organ
      maxValue: $maxValue
      maxValueUnit: $maxValueUnit
      meanValue: $meanValue
      meanValueUnit: $meanValueUnit
      volumeConstraintData: $volumeConstraintData
      dConstraintData: $dConstraintData
    ) {
      organAtRisk {
        siteGroupId
        ...OrganAtRisk
      }
    }
  }
`;

export const GET_ORGAN_LIST = gql`
  query organList {
    oarOrganList {
      id
      organ
    }
  }
`;

export const DELETE_OAR = gql`
  mutation roDeleteOrganAtRisk($siteGroupId: ID!, $index: ID!) {
    roDeleteOrganAtRisk(siteGroupId: $siteGroupId, index: $index) {
      ok
    }
  }
`;

export const RESET_OAR = gql`
  mutation roResetOrganAtRisk($siteGroupId: ID!, $organ: String!) {
    roResetOrganAtRisk(siteGroupId: $siteGroupId, organ: $organ) {
      success
    }
  }
`;
