import React, { Component, ChangeEvent, Fragment } from 'react';

import { withRouter, RouteComponentProps, Redirect, Link } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import validate from 'validate.js';
import { ForgotPasswordMutationType } from 'op-px-models';
import { ButtonType } from 'shared-components/enums';

import { LoadingSpinner } from 'shared-components/components';
import { FormContent, FormSection, GCButton } from 'shared-components/components/FormComponents';
import { FreeTextField } from 'shared-components/components/FormFields';
import { FORGOT_PASSWORD_MUTATION_GQL } from 'op-px-graphql';
import { EMAIL_REGEX, MOBILE_NUMBER_REGEX } from 'shared-components/utils';

const FORGOT_DESCRIPTION = 'Enter your email to reset your password';
const RESET_PASSWORD = 'Reset password';
const EXPIRED_DESCRIPTION = 'Your one time link has expired.\nClick button below to resend link';
const EXPIRED_TITLE = 'Expired link';
const EMAIL = 'Email';
const GET_RESET = 'Get reset link';

const FIELD_NAMES: { [key: string]: string } = {
  USERNAME: 'username',
};

interface Props extends RouteComponentProps<{}, any, Location | any> {
  children?: React.ReactNode;
}

interface State {
  username: string;
  goBack: boolean;
  validationObject?: any;
}

class ForgotPassword extends Component<Props, State> {
  private title: string;
  private description: string;
  private showCancel = false;

  public constructor(props: Props) {
    super(props);

    // Get the expired link state from the router state that will be passed in
    this.title = RESET_PASSWORD;
    this.description = FORGOT_DESCRIPTION;

    let username = '';
    const { location } = this.props;

    if (location && location.state) {
      if (location.state.expiredLink) {
        this.title = EXPIRED_TITLE;
        this.description = EXPIRED_DESCRIPTION;
        this.showCancel = true;
      }

      if (location.state.username) {
        username = location.state.username;
      }
    }

    this.state = {
      username: username,
      goBack: false,
    };
  }

  public render(): JSX.Element {
    if (this.state.goBack) {
      if (this.props.location && this.props.location.state && this.props.location.state.expiredLink) {
        this.props.history.goBack();
        return <Fragment />;
      }
      return <Redirect from="/" to="/login" />;
    }
    return (
      <Mutation<{ resetPassword: { resetLink: string; errors: string[] } }> mutation={FORGOT_PASSWORD_MUTATION_GQL}>
        {(requestForgotPassword: ForgotPasswordMutationType, { called, loading, data }): JSX.Element => {
          if (loading) return <LoadingSpinner />;

          // TODO: Make me better
          let resetLink: string | undefined;
          if (data && data.resetPassword && data.resetPassword.resetLink) {
            resetLink = data.resetPassword.resetLink;
          }

          return this.renderContents(requestForgotPassword, called, resetLink);
        }}
      </Mutation>
    );
  }

  private renderContents = (
    requestForgotPassword: ForgotPasswordMutationType,
    shouldRedirect: boolean,
    resetLink?: string,
  ): JSX.Element => {
    const usernameField = 'username';

    if (shouldRedirect) {
      const redirectionParams = {
        pathname: '/px/resetLink',
        state: {
          username: this.state.username,
          resetLink: resetLink,
        },
      };
      return <Redirect push to={redirectionParams} />;
    }

    return (
      <div className="auth-container">
        <div className="auth-container-inner">
          <form id="forgot-password-form" className="form-wrapper">
            <FormContent>
              <div className="title-container">
                <div
                  className="back-arrow"
                  onClick={(): void => {
                    this.setState({ goBack: true });
                  }}
                />
                <div className="title">{this.title}</div>
              </div>
              <div className="description">{this.description}</div>
              <FormSection>
                <div className="form-section-field">
                  <label className="field-label" htmlFor={usernameField}>
                    {EMAIL}
                  </label>
                  <FreeTextField
                    inputName={FIELD_NAMES.USERNAME}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.setState({
                        username: e.target.value,
                      });
                    }}
                    errors={
                      this.state.validationObject && this.state.validationObject.username
                        ? this.state.validationObject.username
                        : undefined
                    }
                  />
                </div>
                <div className="flex-horizontal-center">
                  <GCButton
                    onClick={(e): void => {
                      if (e) {
                        e.preventDefault();
                      }
                      this.validateAndSubmit(requestForgotPassword);
                    }}
                    name="reset-link"
                    type={ButtonType.GREEN}
                    inputType="submit"
                    title={GET_RESET}
                  />
                </div>
                {this.showCancel && (
                  <div className="flex-horizontal-center">
                    <Link className="link" to="/" replace>
                      {'Cancel'}
                    </Link>
                  </div>
                )}
              </FormSection>
            </FormContent>
          </form>
        </div>
      </div>
    );
  };

  private validateAndSubmit = (requestForgotPassword: ForgotPasswordMutationType): void => {
    const globalValidationRules: { [key: string]: object } = {
      username: {
        presence: {
          allowEmpty: false,
          message: 'Please enter an email',
        },
        format: {
          pattern: `${EMAIL_REGEX}${MOBILE_NUMBER_REGEX}`,
          message: 'Please enter a valid email',
        },
      },
    };

    // Disable pre-appending of argument name to error messages
    const disableFullMessages = { fullMessages: false };
    // Run validation on all these feilds
    const objectToValidate = {
      username: this.state.username,
    };
    const validationObject = validate(objectToValidate, globalValidationRules, disableFullMessages);

    if (validationObject) {
      // Invalid input, don't sumit
      this.setState({ validationObject });
    } else {
      requestForgotPassword({
        variables: {
          username: this.state.username,
        },
      });
    }
  };
}

const routedComponent = withRouter(ForgotPassword);
const component = routedComponent;
export default component;
