// eslint-disable-next-line no-use-before-define
import React from 'react';

import { DELETE_OAR, OAR_QUERY } from './Queries';
import {
  SITE_GROUP_TEMPLATE_VALUE_VARIATION,
  SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION,
  GET_CAREPLAN,
} from '../../Queries';
import { useMutation } from '@apollo/client';
import { Edit, DeleteOutline } from '@mui/icons-material';

export interface OrgansAtRiskRowProps {
  organName: string;
  constraints: string[];
  itemId?: any;
  data?: any;
  popup?: any;
  hidePopup?: any;
  isVisible?: boolean;
  editPopup: () => void;
  siteGroupId: number;
  careplanId: number;
  index: number;
}

const OrgansAtRiskRow = ({
  organName,
  constraints,
  siteGroupId,
  careplanId,
  editPopup,
  index,
}: OrgansAtRiskRowProps) => {
  const [deleteOAR, { loading }] = useMutation(DELETE_OAR, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: OAR_QUERY,
        variables: { siteGroupId: siteGroupId },
      },
      {
        query: SITE_GROUP_TEMPLATE_VALUE_VARIATION,
        variables: { siteGroupId },
      },
      {
        query: SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION,
        variables: { siteGroupId, volumingPage: true },
      },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
    ],
  });

  return (
    <>
      <tr className="no-border-table-row">
        <td></td>
      </tr>
      <tr className="oar-table-row">
        <td>
          <strong>{organName}</strong>
        </td>
        <td>
          {constraints.map(
            (item, index): JSX.Element => (
              <div key={index} className="constraint-oar">
                {item}
              </div>
            ),
          )}
        </td>
        <td>
          <button style={{ cursor: 'pointer' }} className="edit-oar oar-small-text" onClick={(): void => editPopup()}>
            <Edit sx={{ margin: '8px' }} color="primary" />
            <strong>Edit</strong>
          </button>
        </td>
        <td>
          <button
            style={{ cursor: 'pointer' }}
            className="delete-oar oar-small-text"
            onClick={() => deleteOAR({ variables: { siteGroupId, index } })}
            disabled={loading}>
            <DeleteOutline sx={{ margin: '8px' }} color="error" />
            <strong>Delete</strong>
          </button>
        </td>
      </tr>
    </>
  );
};

export default OrgansAtRiskRow;
