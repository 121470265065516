// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import classnames from 'classnames';
import moment from 'moment';

import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ROSideNav, RecentPatients } from 'op-components';
import { StyledQuickAccessRibbon } from 'op-components/SideNav/common';
import { useErrorModalContext } from 'op-contexts';
import { RoDashboardTabs, RoleType, CareplanStatusFilter, CareplanFilter } from 'op-enums';
import { GET_RECENT_PATIENTS, GET_USER_PROFILE } from 'op-graphql/queries';
import { ROCarePlanDashboardApollo, ROClinicListApollo } from 'op-pages';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { generateGreetingText } from 'op-utils/helpers';
import { styled } from '@mui/system';

import { Card, LoadingSpinner } from 'shared-components/components';
import { ButtonRO } from 'shared-components/components/FormFields';
import ROProfilePhoto from 'shared-components/components/ROProfilePhoto/ROProfilePhoto';
import { ButtonMode, ButtonType, Region } from 'shared-components/enums';

import RODailyAppointment from './DailyAppointments/RODailyAppointment';
import { CREATE_PATIENT, GET_UNSUBMITTED_COUNT } from './DashboardQueries';
import { SimpleSummarySelectHOC } from './Onboarding';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import './Dashboard.scss';
import { Box, Divider } from '@mui/material';

interface StyledNotificationCountProps {
  $count: number;
}

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const isUkRegion = REACT_APP_REGION === Region.UK;

export const StyledTile = styled(Card)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  margin-left: 5px;
`;

const SideUserDetailWrapper = styled('div')`
  width: 100%;
  padding: 16px;
  background-color: white;
`;

const BaseSpan = styled('span')`
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.secondary.dark};
`;
const SideOutStandingHeader = styled(BaseSpan)`
  width: 100%;
  text-transform: uppercase;
`;
const StyledPatientCreateWrapper = styled('div')`
  display: flex;
  justify-content: center;
  padding-bottom: 1.7em;
`;
const StyledNotification = styled('div')`
  cursor: pointer;
  font-weight: 700;
`;
const StyledNotificationText = styled('span')`
  text-decoration: underline;
  color: ${(props) => props.theme.palette.info.main};
`;

const StyledNotificationCount = styled('span')<StyledNotificationCountProps>`
  color: ${(props) => props.theme.palette.primary?.contrastText};
  text-decoration: none;
  border-radius: 50%;
  padding: 2px 5px;
  margin: 5px;
  font-size: 12px;
  background: ${(props) => (props.$count !== 0 ? props.theme.palette.error.main : props.theme.palette.secondary.dark)};
`;

const StyleDivWrapper = styled('div')`
  padding-top: 16px;
`;

const RODashboard = (): JSX.Element => {
  const { setError } = useErrorModalContext();
  const [activeTab, setActiveTab] = useState(CurrentAppConfig.RadiationDashboard.active);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [careplanFilter, setCareplanFilter] = useState<string[]>(['']);

  const history = useHistory();
  const location = useLocation();
  const { data: userProfile, loading: userLoading, error: userError } = useQuery(GET_USER_PROFILE);
  const { data: recentPatients, error: recentPatientsError } = useQuery(GET_RECENT_PATIENTS, {
    fetchPolicy: 'network-only',
    skip: userProfile?.user?.primaryRole === RoleType.CAREPLANVIEWER,
  });
  const { data: unsubmittedCareplansData, loading: unsubmittedCareplansLoading } = useQuery(GET_UNSUBMITTED_COUNT, {
    skip: userProfile?.user?.primaryRole === RoleType.CAREPLANVIEWER,
    fetchPolicy: 'cache-and-network',
  });

  const isRo = userProfile?.user?.isRo;

  const getPageTab = (): string | undefined => {
    const tab = location.search.split('tab=');
    if (tab.length > 1) {
      return tab[1];
    }
    return;
  };

  const toggleTabs = (tabId: string) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
  };

  useEffect(() => {
    const currentTab = getPageTab();
    if (currentTab !== undefined) {
      toggleTabs(currentTab);
    }
  }, [getPageTab, toggleTabs]);

  useEffect(() => {
    if (userError || !userProfile?.user?.hasStaffId) return setError('ACCOUNT');
    if (recentPatientsError) return setError();
  }, [userError, recentPatientsError, userProfile, userProfile?.user?.hasStaffId, setError]);

  const [createPatient, { loading: createPatientLoading }] = useMutation(CREATE_PATIENT, {
    onCompleted: (data: any) => {
      const { id } = data.createPatient.patient;
      history.push(`/radiation/registration/patient/${id}/basic`);
    },
  });
  const hasCareplanViewerRole = userProfile?.user?.primaryRole === RoleType.CAREPLANVIEWER;

  if (userLoading) return <LoadingSpinner />;

  if (!isRo && !hasCareplanViewerRole) {
    return <Redirect to="/error" />;
  }

  const TabDict = {
    [RoDashboardTabs.CLINIC_LIST]: ROClinicListApollo,
    [RoDashboardTabs.CAREPLANS]: ROCarePlanDashboardApollo,
    [RoDashboardTabs.APPOINTMENTS]: RODailyAppointment,
    [RoDashboardTabs.CAREPLAN_VIEWER]: ROCarePlanDashboardApollo,
  };

  const tabs = CurrentAppConfig.RadiationDashboard.tabs.filter((tab) =>
    tab.userTypes.includes(userProfile?.user?.primaryRole),
  );

  const userName = isRo ? `Dr. ${userProfile?.user?.name}` : userProfile?.user?.name;
  const greeting = generateGreetingText();
  const draftCareplanCount = !unsubmittedCareplansLoading ? unsubmittedCareplansData?.getUnsubmittedCount?.drafts : 0;
  const unsubmittedCareplanCount = !unsubmittedCareplansLoading
    ? unsubmittedCareplansData?.getUnsubmittedCount?.unsubmitted
    : 0;
  const text = createPatientLoading ? 'Loading' : 'Create new patient';

  const handleTabChange = (_event: React.SyntheticEvent, newValue: RoDashboardTabs) => {
    toggleTabs(newValue);
    if ([RoDashboardTabs.CAREPLANS, RoDashboardTabs.CAREPLAN_VIEWER].indexOf(newValue) !== -1) {
      setStatusFilter([]);
      setCareplanFilter([]);
    }
  };
  return (
    <div key="RO-dashboard" className="RO-home-wrapper">
      <ROSideNav>
        <SideUserDetailWrapper>
          <div className="side-date-wrapper">{moment().format('ddd, DD MMMM YYYY')}</div>
          <div className="side-user-photo">
            <ROProfilePhoto />
          </div>
          <div className="side-greeting-wrapper">{greeting}</div>
          <div className="side-username-wrapper">{userName}</div>
          <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />
          {isRo && (
            <>
              <div>
                <SideOutStandingHeader>OUTSTANDING CAREPLANS</SideOutStandingHeader>
              </div>

              <StyleDivWrapper>
                <StyledNotification
                  id={'draft-careplan-count-button'}
                  data-test-id={'draft-careplan-count-button'}
                  onClick={() => {
                    setStatusFilter([CareplanStatusFilter.DRAFT]);
                    setCareplanFilter([]);
                    setActiveTab(RoDashboardTabs.CAREPLANS);
                  }}>
                  <StyledNotificationText>Draft</StyledNotificationText>

                  <StyledNotificationCount data-test-id={'draft-careplan-count'} $count={Number(draftCareplanCount)}>
                    {draftCareplanCount}
                  </StyledNotificationCount>
                </StyledNotification>
              </StyleDivWrapper>

              <StyleDivWrapper>
                <StyledNotification
                  id={'unsubmitted-count-button'}
                  data-test-id={'unsubmitted-count-button'}
                  onClick={() => {
                    setStatusFilter([]);
                    setCareplanFilter([CareplanFilter.UNSUBMITTED]);
                    setActiveTab(RoDashboardTabs.CAREPLANS);
                  }}>
                  <StyledNotificationText>Unsubmitted changes</StyledNotificationText>
                  <StyledNotificationCount
                    data-test-id={'unsubmitted-careplan-count'}
                    $count={Number(unsubmittedCareplanCount)}>
                    {unsubmittedCareplanCount}
                  </StyledNotificationCount>
                </StyledNotification>
              </StyleDivWrapper>
            </>
          )}
        </SideUserDetailWrapper>

        {isRo && <StyledQuickAccessRibbon>QUICK ACCESS</StyledQuickAccessRibbon>}
        {isUkRegion && (
          <StyledPatientCreateWrapper>
            <ButtonRO
              id={'new-patient-button'}
              title={text}
              type={ButtonType.WHITE}
              mode={ButtonMode.CREATE}
              onClick={() => {
                createPatient();
              }}
              loading={createPatientLoading}
            />
          </StyledPatientCreateWrapper>
        )}
        <RecentPatients recentPatients={recentPatients?.getRecentPatients} isRo={isRo} />
      </ROSideNav>

      <div
        className={classnames('main-container', 'ro-tab-switcher', {
          'ro-uk-full-width': REACT_APP_REGION === Region.UK,
        })}>
        <TabContext value={activeTab}>
          {tabs.length > 1 && (
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="Dashboard Tabs">
                {tabs.map((tab: any) => {
                  return (
                    <Tab
                      data-test-id={`tab-${tab.key}`}
                      value={tab.key}
                      key={`ro-nav-tab-${tab.key}`}
                      label={tab.displayText}
                    />
                  );
                })}
              </TabList>
            </Box>
          )}
          {tabs.map((tab: any) => {
            //@ts-ignore
            const TabComp = TabDict[tab.key];
            return (
              <TabPanel
                sx={{ padding: '24px 0' }}
                data-test-id={`tab-${tab.key}`}
                value={tab.key}
                key={`ro-dashboard-tab-${tab.key}`}>
                <Box>
                  <TabComp tabId={tab.key} statusFilter={statusFilter} careplanFilter={careplanFilter} />
                </Box>
              </TabPanel>
            );
          })}
        </TabContext>
        {isRo && <SimpleSummarySelectHOC />}
      </div>
    </div>
  );
};

export default RODashboard;
