import React, { createRef, forwardRef, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LoadingSpinner, Popover } from 'shared-components/components';
import moment from 'moment';
import { BaseDatePicker } from 'shared-components/components/FormFields';
import { CareplanDose, DrugOrderInterface, NewSupportingDrugOrder } from 'op-pages/MO/interfaces';
import {
  DrugOrderScheduleInterface,
  CycleDayInterface,
  TableColumn,
  EmptyTableColumn,
  CareplanInterface,
  CareplanCycle,
} from '../interfaces';
import {
  AlwaysHorizontalScroll,
  StyledCycleTable,
  DayLabel,
  DrugNameContainer,
  GreyBox,
  HeaderContainer,
  FirstHeaderContainer,
  EmptyContainer,
  ScrollbarDiv,
} from './StyledComponents';
import { deepCopyFunction } from 'shared-components/utils/CustomHooks';
import { DoseInfo } from './DoseEditModal';
import SupportingDrugModal from './SupportingDrugModal';
import { getDrugName, isNewSupportingDrugApplicable } from 'op-pages/MO/utils';
import { useMutation } from '@apollo/client';
import { ADD_CYCLE_BREAK_PERIOD, ADD_DRUG_ORDER } from '../Queries';
import { useRouteMatch } from 'react-router-dom';
import { UserContext } from 'op-contexts/index';
import _ from 'lodash';
import SequencePopper from './SequencePopper';
import DrugRow from './DrugRow';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { Button, useTheme } from '@mui/material';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import DatePickerHeader from './DatePickerHeader';

enum DateChangeAction {
  MOVE,
  ADD,
  BOTH,
}

const findDosesInRange = (
  doses: CareplanDose[],
  startCycle: number,
  endCycle: number,
  startDay: number,
  endDay: number,
): CareplanDose[] => {
  if (startCycle === endCycle) {
    const foundDoses = doses.filter((dose) => startCycle === dose.cycle && startDay <= dose.day && dose.day <= endDay);
    return foundDoses;
  }
  // falls across the cycles
  const foundDoses = doses.filter(
    (dose) =>
      (startCycle === dose.cycle && startDay <= dose.day) ||
      (startCycle < dose.cycle && dose.cycle < endCycle) ||
      (endCycle === dose.cycle && dose.day <= endDay),
  );
  return foundDoses;
};

interface CycleTableProps {
  careplan: CareplanInterface;
  cycles: CareplanCycle[];
  selectedCycle: number;
  drugOrders: DrugOrderInterface[];
  openDoseEdit: (doseInfo: DoseInfo) => void;
  openDeleteDrugOrder: (doseInfo: DoseInfo, day?: number) => void;
  newSupportingDrugOrder: NewSupportingDrugOrder | null;
  setNewSupportingDrugOrder: (value: NewSupportingDrugOrder | null) => void;
}

export const CycleTable = ({
  careplan,
  cycles,
  selectedCycle,
  drugOrders,
  openDoseEdit,
  openDeleteDrugOrder,
  newSupportingDrugOrder,
  setNewSupportingDrugOrder,
}: CycleTableProps): JSX.Element => {
  const match = useRouteMatch<{ careplanId: string }>();
  const { careplanId } = match.params;
  const [addCycleBreakMutation] = useMutation(ADD_CYCLE_BREAK_PERIOD, {
    refetchQueries: ['moAdditionalInfo', 'latestCycles'],
  });
  const [addDrugOrder] = useMutation(ADD_DRUG_ORDER, { refetchQueries: ['moCareplanData', 'latestCycles'] });
  const [supportingDrugModalOpen, setSupportingDrugModalOpen] = useState(false);
  const { state: userDetails } = useContext(UserContext);

  const headRowRef = useRef<HTMLTableRowElement | null>(null);
  const drugContainerRef = useRef<HTMLTableColElement | null>(null);
  const tableBodyRef = useRef<HTMLTableElement | null>(null);
  const verticalScrollRef = useRef<HTMLDivElement | null>(null);
  const horizontalScrollRef = useRef<HTMLDivElement | null>(null);
  const [bounds, setBounds] = useState({ width: 0, height: 0, scrollHeight: 0, scrollWidth: 0 });

  useEffect(() => {
    tableBodyRef.current?.scrollBy({ top: Number.MAX_SAFE_INTEGER, behavior: 'auto' });
  }, [newSupportingDrugOrder]);

  useLayoutEffect(() => {
    // Purpose of this is to make all the table column widths consistent with the largest column in the table
    if (!headRowRef.current) {
      return;
    }

    const trElement = headRowRef.current;

    const children = Array.from(trElement.children).slice(1);
    const draggable = document.querySelector('[data-rbd-draggable-id="new"]');
    if (draggable !== null) {
      const draggableChildren = Array.from(draggable.children).slice(1);
      children.push(...draggableChildren);
    }

    // Measure
    const thWidths: number[] = children.map((child) => child.getBoundingClientRect().width);
    const maxThWidth = Math.max(...thWidths);

    // Set to max
    children
      .filter((child) => child.className !== 'empty')
      .forEach((child) => {
        child.children[0].setAttribute('style', `min-width: ${Math.max(120, maxThWidth - 1)}px`);
      });

    return () => {
      children.forEach((child) => {
        // set everything to default
        child.children[0].setAttribute('style', 'min-width: unset;');
      });
    };
  });

  useLayoutEffect(() => {
    if (tableBodyRef.current) {
      setBounds({
        width: tableBodyRef.current.clientWidth,
        height: tableBodyRef.current.clientHeight,
        scrollHeight: tableBodyRef.current.scrollHeight,
        scrollWidth: tableBodyRef.current.scrollWidth,
      });
    }
  }, [careplan.cycleSchedule, newSupportingDrugOrder]);

  // Show spinner if loading
  if (!drugOrders || !careplan.cycleSchedule?.length) {
    return <LoadingSpinner />;
  }

  // Create list of day objects that has both the date and index within the cycle, to be used when we split out empty days
  const cycleDays: CycleDayInterface[] = careplan.cycleSchedule[selectedCycle].days.filter((day) => !day.hidden);
  // zero out time values to allow for comparisons
  const today = moment().toDate(); //.tz(userDetails.timezone).toDate();

  // Determine if today is in the current cycle, and if so get the index
  const todayInCycle = cycleDays.find((day: CycleDayInterface) => moment(day.date).isSame(today, 'day'));
  const closestCycleDay = cycleDays.find((day: CycleDayInterface) => moment(day.date).isSameOrAfter(today, 'day'));
  // Separate days into those with doses and those without
  const daysWithDoses = cycleDays.filter(
    (day) =>
      careplan.doses.some((dose) => dose.cycle === selectedCycle && dose.day === day['day']) ||
      newSupportingDrugOrder?.days.includes(day['day']),
  );

  // Build list of columns including days with doses and empty ranges in between
  const tableColumns: (TableColumn | EmptyTableColumn)[] = [];
  let emptyRange: number[] = [];
  for (const day of cycleDays) {
    if (daysWithDoses.includes(day)) {
      if (emptyRange.length > 0) {
        tableColumns.push({
          isEmpty: true,
          dayRange: emptyRange,
        });
        emptyRange = [];
      }

      tableColumns.push({ ...day, isEmpty: false });
    } else {
      day.day >= 0 && emptyRange.push(day.day);
    }
  }

  // If we have an empty range at the end of the loop, push it in as well
  if (emptyRange.length > 0) {
    tableColumns.push({
      isEmpty: true,
      dayRange: emptyRange,
    });
  }

  // Get list of unique drugs in order of appearance
  const cycleDoses = [...careplan.doses.filter((dose) => dose.cycle === selectedCycle)].sort(
    (a, b) => a.order - b.order,
  );
  const drugOrderMap = Object.assign(
    {},
    ...drugOrders.map((drugOrder) => ({ [drugOrder.base || drugOrder.id]: drugOrder })),
  );
  const careplanDrugOrderMap = Object.assign(
    {},
    ...cycleDoses.map((dose) => ({ [dose.drugOrder.id]: dose.drugOrder.drugOrderBaseId })),
  );
  const uniqueDrugOrderIds = Array.from(new Set(cycleDoses.map((dose) => dose.drugOrder.id)));

  const doseDays = tableColumns.map((day) =>
    day.isEmpty
      ? {
          isEmptyColumn: true,
        }
      : {
          isEmptyColumn: false,
          day: day.day,
          date: day.date,
          doseValue: '',
          dose: '',
        },
  );

  // Build empty cycle schedule
  const cycleSchedule: DrugOrderScheduleInterface[] = uniqueDrugOrderIds.map((drugOrderId) => ({
    ...deepCopyFunction(drugOrderMap[careplanDrugOrderMap[drugOrderId]]),
    drugName: getDrugName(drugOrderMap[careplanDrugOrderMap[drugOrderId]]),
    drugOrderId: drugOrderId,
    dragDisabled: true,
    doseDays: deepCopyFunction(doseDays),
  }));

  // check the drug order needs delete button
  const drugOrderLastDose = cycleDoses.reduce<Record<string, CareplanDose>>((drugOrderLastDose, dose) => {
    if (!(dose.drugOrder.id in drugOrderLastDose)) {
      drugOrderLastDose[dose.drugOrder.id] = dose;
      return drugOrderLastDose;
    }
    const existingDose = drugOrderLastDose[dose.drugOrder.id];
    if (dose.cycle > existingDose.cycle || (dose.cycle === existingDose.cycle && dose.day >= existingDose.day))
      drugOrderLastDose[dose.drugOrder.id] = dose;
    return drugOrderLastDose;
  }, {});

  if (newSupportingDrugOrder && isNewSupportingDrugApplicable(selectedCycle, newSupportingDrugOrder)) {
    const spliceIndex = newSupportingDrugOrder.insertIndex ?? cycleSchedule.length;
    cycleSchedule.splice(spliceIndex, 0, {
      ...deepCopyFunction(newSupportingDrugOrder.drugOrder),
      drugName: getDrugName(newSupportingDrugOrder.drugOrder),
      dragDisabled: false,
      drugOrderId: 'new',
      movedFromStaging: false,
      doseDays: deepCopyFunction(doseDays).map((doseday: any) => {
        return {
          ...doseday,
          dose: newSupportingDrugOrder.days.includes(doseday.day) && {
            id: -1,
            drugOrder: newSupportingDrugOrder.drugOrder,
            cycle: selectedCycle,
            day: doseday.day,
            doseValue: newSupportingDrugOrder.drugOrder.doseBasis || newSupportingDrugOrder.drugOrder.minimumDose,
          },
        };
      }),
    });
  }
  // Loop through each dose in the careplan insert it into the schedule at the correct day
  cycleDoses.forEach((dose) => {
    // Find index of the drug within the cycleSchedule
    const drugOrderIndex = cycleSchedule.findIndex((drugOrder) => drugOrder.drugOrderId === dose.drugOrder.id);
    // Find the index of the column for this dose (the specific day)
    const columnIndex = tableColumns.findIndex((day) => !day.isEmpty && day.day === dose.day);

    if (drugOrderIndex >= 0 && columnIndex >= 0) {
      cycleSchedule[drugOrderIndex].doseDays[columnIndex].dose = dose;
    }
  });

  const handleDrag = (result: any) => {
    // dropped outside the list
    if (!result.destination || !newSupportingDrugOrder) {
      return;
    }

    const x: NewSupportingDrugOrder = {
      ...newSupportingDrugOrder,
      insertIndex: result.destination.index,
      inStaging: result.destination.index === cycleSchedule.length,
    };

    setNewSupportingDrugOrder(x);
  };

  const startAddingSupportingDrug = () => {
    setSupportingDrugModalOpen(true);
    setNewSupportingDrugOrder(null);
  };

  const cancelAddingDrugOrder = () => {
    setSupportingDrugModalOpen(false);
    setNewSupportingDrugOrder(null);
  };

  const confirmAddingDrugOrder = () => {
    addDrugOrder({
      variables: {
        careplanId,
        drugOrderBaseId: newSupportingDrugOrder?.drugOrder.base ?? newSupportingDrugOrder?.drugOrder.id,
        drugOrderVersion: newSupportingDrugOrder?.drugOrder.version,
        days: newSupportingDrugOrder?.days,
        order: newSupportingDrugOrder?.insertIndex,
        cycle: selectedCycle,
        applyTo: newSupportingDrugOrder?.applyTo,
        instructions: newSupportingDrugOrder?.instructions,
      },
    });
    setNewSupportingDrugOrder(null);
  };

  const getMinimumDate = (day: TableColumn): Dayjs | undefined => {
    if (careplan.cycleSchedule[selectedCycle].days[0].day === day.day && selectedCycle === 0) return;
    // check previous cycle if there are any empty days

    // last dose day of previous cycle
    // TODO: do we sort this to pick the last???
    const doses = _.orderBy(
      careplan.doses.filter(
        (dose) => dose.cycle < selectedCycle || (dose.cycle === selectedCycle && dose.day < day.day),
      ),
      ['cycle', 'day'],
      ['asc', 'asc'],
    );
    if (doses.length === 0) return;
    const lastDose = doses[doses.length - 1];
    const cycle = lastDose.day + 1 !== careplan.daysPerCycle ? lastDose.cycle : lastDose.cycle + 1;
    const lastDay = lastDose.day + 1 !== careplan.daysPerCycle ? lastDose.day : 0;
    const foundDay = careplan.cycleSchedule[cycle].days.find((cday) => cday.day === lastDay);
    if (!foundDay) return;
    return dayjs(foundDay.date).add(1, 'days');
  };

  const actionForNewDate = (date: Dayjs | null, day: number): DateChangeAction => {
    //cycle and day in prescriptioin
    const selectedDate = moment(date?.toString());
    const selectedDay = careplan.cycleSchedule[selectedCycle].days.find((cday) => cday.day === day);
    if (!selectedDay) {
      throw new Error('Invalid selection day');
    }
    const selectedDayDate = moment(selectedDay.date);
    // if the selected date is after the last day of the careplan then it is DateChangeAction.ADD
    const lastCycleIdOfCareplan = Math.max(...cycles.map((cycle) => cycle.cycleId));
    const lastCycleOfCareplan = cycles.find((cycle) => cycle.cycleId === lastCycleIdOfCareplan);
    const isSelectedDateAfterLastDayOfCareplan =
      lastCycleOfCareplan && selectedDate.isAfter(moment(lastCycleOfCareplan.treatmentEndDate), 'days');
    if (isSelectedDateAfterLastDayOfCareplan) {
      return DateChangeAction.ADD;
    }
    // selected date is before the last date of the careplan
    const isNegativeSelection = selectedDate.isBefore(selectedDay.date, 'days');
    const nearestCycle = cycles.find((cycle) =>
      selectedDate.isBetween(moment(cycle.treatmentStartDate), moment(cycle.treatmentEndDate), 'days', '[]'),
    );
    if (nearestCycle) {
      const fallsOnDay = careplan.cycleSchedule[nearestCycle.cycleId].days.find((cycleDay) =>
        moment(cycleDay.date).isSame(selectedDate, 'days'),
      );
      const closesetDayAfterSelectedDate = careplan.cycleSchedule[nearestCycle.cycleId].days.find(
        (cycleDay) => !cycleDay.hidden && moment(cycleDay.date).isSameOrAfter(selectedDate, 'days'),
      );
      if (isNegativeSelection) {
        if (closesetDayAfterSelectedDate?.day === day) {
          return DateChangeAction.BOTH;
        }
        if (fallsOnDay !== undefined) {
          return DateChangeAction.BOTH;
        }
        throw new Error('Invalid date selection');
      }
      // days between selected day and new date
      const selectedCycleSlice =
        selectedCycle < nearestCycle.cycleId
          ? [selectedCycle, nearestCycle.cycleId + 1]
          : [nearestCycle.cycleId, selectedCycle + 1];
      // check if there are any doses between day and new day its landing on
      const doseSearchParams = careplan.cycleSchedule.slice(...selectedCycleSlice).reduce(
        (result, schedule, scheduleIndex) => {
          const offSetIndex = selectedCycleSlice[0] + scheduleIndex;
          return schedule.days.reduce((dayResult, cycleDay) => {
            if (!cycleDay.hidden && moment(cycleDay.date).isBetween(selectedDayDate, selectedDate, 'days', '(]')) {
              if (
                offSetIndex < dayResult.startCycle ||
                (offSetIndex === dayResult.startCycle && cycleDay.day < dayResult.startDay)
              ) {
                dayResult.startCycle = offSetIndex;
                dayResult.startDay = cycleDay.day;
              }
              if (
                (offSetIndex === dayResult.endCycle && cycleDay.day > dayResult.endDay) ||
                offSetIndex > dayResult.endCycle
              ) {
                dayResult.endCycle = offSetIndex;
                dayResult.endDay = cycleDay.day;
              }
            }
            return dayResult;
          }, result);
        },
        {
          startCycle: Number.MAX_SAFE_INTEGER,
          endCycle: Number.MIN_SAFE_INTEGER,
          startDay: Number.MAX_SAFE_INTEGER,
          endDay: Number.MIN_SAFE_INTEGER,
        },
      );

      const foundDoses = findDosesInRange(
        careplan.doses,
        doseSearchParams.startCycle,
        doseSearchParams.endCycle,
        doseSearchParams.startDay,
        doseSearchParams.endDay,
      );
      if (foundDoses.length !== 0) return DateChangeAction.ADD;
      return DateChangeAction.BOTH;
    }
    return DateChangeAction.BOTH;
  };
  const addCycleBreak = (date: Dayjs | null, day: number, selectedCycle: number, propagate: boolean): void => {
    if (!date) return;
    addCycleBreakMutation({
      variables: {
        careplanId,
        day,
        cycle: selectedCycle,
        newDate: moment(date.toString()).tz(userDetails.timezone).startOf('day').format('YYYY-MM-DD'),
        propagate,
      },
    });
  };

  interface ColumnDateProps {
    column: TableColumn;
  }

  const ColumnDateHeader = ({ column }: ColumnDateProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Dayjs>();
    const triggerCloseRef = useRef<number>();
    const anchor = createRef<HTMLInputElement>();
    const [anchorEl, setAnchorEl] = useState<HTMLInputElement>();
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const HeaderInput = forwardRef(
      (
        props,
        ref: ((instance: HTMLInputElement | null) => void) | React.MutableRefObject<HTMLInputElement | null> | null,
      ) => (
        <HeaderContainer data-cy={`cycle-table-date-header-${column.day}`} {...props} ref={ref}>
          <span className="mb-1">{moment(column.date).format('L')}</span>
          <DayLabel> DAY {column.day + 1}</DayLabel>
        </HeaderContainer>
      ),
    );

    useEffect(() => {
      if (isOpen && anchor.current) {
        anchor.current.focus();
        setAnchorEl(anchor.current);
      }
    }, [isOpen, anchor]);

    useEffect(() => {
      return () => {
        if (triggerCloseRef.current) {
          clearTimeout(triggerCloseRef.current);
        }
      };
    }, []);

    return (
      <div>
        <BaseDatePicker
          id="cycleBreak"
          slots={{ field: DatePickerHeader }}
          slotProps={{ field: { setOpen: setDatePickerOpen, column } as any }}
          minDate={getMinimumDate(column)}
          onChange={(date) => {
            const action = actionForNewDate(date, column.day);
            if (action === DateChangeAction.BOTH) {
              setSelectedDate(date || undefined);
              setIsOpen((open) => !open);
            } else {
              addCycleBreak(date, column.day, selectedCycle, action === DateChangeAction.ADD);
            }
          }}
          value={dayjs(column.date)}
          open={datePickerOpen}
          onOpen={() => setDatePickerOpen(true)}
          onClose={() => setDatePickerOpen(false)}
        />
        <input
          data-cy={`cycle-table-date-anchor-${column.day}`}
          ref={anchor}
          style={{ opacity: 0, width: 0, height: 0, padding: 0, margin: 0, border: 0, position: 'absolute' }}
        />
        {anchorEl && (
          <Popover
            id="date-change-propagate"
            title="Reschedule days"
            open={isOpen}
            anchorEl={anchorEl}
            trianglePosition="50%"
            handleClickAway={() => {
              setIsOpen(false);
            }}>
            <div style={{ paddingBottom: '1em' }}>Which days would you like to reschedule?</div>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', paddingRight: '24px' }}>
              <Button
                data-cy="date-change-propagate-no"
                variant={'outlined'}
                onClick={() => {
                  setIsOpen(false);
                  selectedDate && addCycleBreak(selectedDate, column.day, selectedCycle, false);
                }}>
                Selected day only
              </Button>
              <Button
                data-cy="date-change-propagate-yes"
                variant={'contained'}
                sx={{ color: theme.palette.primary.contrastText }}
                onClick={() => {
                  setIsOpen(false);
                  selectedDate && addCycleBreak(selectedDate, column.day, selectedCycle, true);
                }}>
                Rest of careplan
              </Button>
            </div>
          </Popover>
        )}
      </div>
    );
  };

  const theme = useTheme();

  return (
    <GreyBox>
      <AlwaysHorizontalScroll>
        <DragDropContext
          onDragEnd={(result: any) => {
            handleDrag(result);
          }}>
          <Droppable droppableId="droppable">
            {(provided: any) => (
              <div style={{ display: 'flex', width: '100%' }}>
                <StyledCycleTable
                  ref={tableBodyRef}
                  style={{ paddingBottom: newSupportingDrugOrder !== null ? 90 : 0 }}
                  data-test-id="drug-order-table"
                  onScroll={(event) => {
                    if (verticalScrollRef.current) {
                      verticalScrollRef.current.scrollTop = event.currentTarget.scrollTop;
                    }
                    if (horizontalScrollRef.current) {
                      horizontalScrollRef.current.scrollLeft = event.currentTarget.scrollLeft;
                    }
                  }}>
                  <thead>
                    <tr ref={headRowRef}>
                      <th>
                        <DrugNameContainer ref={drugContainerRef}>
                          <FirstHeaderContainer>Drug Orders</FirstHeaderContainer>
                          {newSupportingDrugOrder !== null || (
                            <Button
                              variant="text"
                              data-cy="open-supporting-drug-modal-btn"
                              startIcon={<AddCircleOutline color="primary" />}
                              onClick={startAddingSupportingDrug}>
                              Add Supporting Drug
                            </Button>
                          )}
                        </DrugNameContainer>
                      </th>
                      {tableColumns.map((column, i) =>
                        column.isEmpty ? (
                          <th key={i} className="empty">
                            <EmptyContainer>
                              <span className="mb-1">EMPTY</span>
                              {column.dayRange.length === 1 ? (
                                <span>Day {column.dayRange[0] + 1}</span>
                              ) : (
                                <span>
                                  {column.dayRange[0] + 1}-{column.dayRange[column.dayRange.length - 1] + 1}
                                </span>
                              )}
                            </EmptyContainer>
                          </th>
                        ) : (
                          <th key={i} className={moment(column.date).isSame(today, 'day') ? 'currentDate' : ''}>
                            <ColumnDateHeader column={column} />
                          </th>
                        ),
                      )}
                    </tr>
                  </thead>

                  <tbody ref={provided.innerRef}>
                    {cycleSchedule
                      .slice(0, cycleSchedule.length - (newSupportingDrugOrder?.inStaging ? 1 : 0))
                      .map((drugOrder, drugOrderIndex) => {
                        const drugDeleted =
                          drugOrder.drugOrderId !== undefined &&
                          closestCycleDay !== undefined &&
                          drugOrderLastDose[drugOrder.drugOrderId] &&
                          drugOrderLastDose[drugOrder.drugOrderId].cycle <= selectedCycle &&
                          drugOrderLastDose[drugOrder.drugOrderId].day < closestCycleDay?.day;

                        return (
                          <DrugRow
                            key={drugOrder.drugOrderId}
                            {...{
                              drugOrder,
                              drugOrderIndex,
                              drugDeleted,
                              openDeleteDrugOrder,
                              selectedCycle,
                              closestCycleDay,
                              todayInCycle,
                              openDoseEdit,
                            }}
                          />
                        );
                      })}
                    {newSupportingDrugOrder?.inStaging && (
                      <Draggable
                        isDragDisabled={true}
                        key="divider"
                        draggableId="divider"
                        index={cycleSchedule.length - 1}>
                        {(provided, _snapshot) => (
                          <tr
                            data-cy="divider-row"
                            key="divider-row"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <td colSpan={100} style={{ backgroundColor: 'transparent', border: 0 }}>
                              <hr style={{ borderTop: '1px dashed gray' }} />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    )}
                    {newSupportingDrugOrder?.inStaging && (
                      <DrugRow
                        key={cycleSchedule[cycleSchedule.length - 1].drugOrderId}
                        {...{
                          drugOrder: cycleSchedule[cycleSchedule.length - 1],
                          drugOrderIndex: cycleSchedule.length,
                          drugDeleted: false,
                          openDeleteDrugOrder,
                          selectedCycle,
                          closestCycleDay,
                          todayInCycle,
                          openDoseEdit,
                        }}
                      />
                    )}
                    {provided.placeholder}
                  </tbody>
                </StyledCycleTable>
                <div>
                  <div
                    style={{ minHeight: drugContainerRef.current ? drugContainerRef.current.clientHeight : '40px' }}
                  />
                  <ScrollbarDiv
                    ref={verticalScrollRef}
                    style={{
                      maxHeight: drugContainerRef.current
                        ? bounds.height - drugContainerRef.current.clientHeight
                        : '450px',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}
                    onScroll={(event) => {
                      if (tableBodyRef.current) tableBodyRef.current.scrollTop = event.currentTarget.scrollTop;
                    }}>
                    <ScrollbarDiv
                      style={{
                        minHeight: drugContainerRef.current
                          ? bounds.scrollHeight - drugContainerRef.current.clientHeight
                          : '800px',
                        width: '6px',
                        backgroundColor: theme.palette.secondary.light,
                      }}></ScrollbarDiv>
                  </ScrollbarDiv>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </AlwaysHorizontalScroll>
      <div style={{ display: 'flex' }}>
        <div style={{ minWidth: drugContainerRef.current ? drugContainerRef.current.clientWidth : '40px' }} />
        <ScrollbarDiv
          ref={horizontalScrollRef}
          style={{
            minHeight: '2px',
            maxWidth: drugContainerRef.current ? bounds.width - drugContainerRef.current.clientWidth + 17 : '500px',
            overflow: 'scroll',
          }}
          onScroll={(event) => {
            if (tableBodyRef.current) {
              tableBodyRef.current.scrollLeft = event.currentTarget.scrollLeft;
            }
          }}>
          <div
            style={{
              minWidth: drugContainerRef.current ? bounds.scrollWidth - drugContainerRef.current.clientWidth : '800px',
              minHeight: '5px',
              backgroundColor: theme.palette.secondary.light,
            }}></div>
        </ScrollbarDiv>
      </div>
      <SupportingDrugModal
        cycleSchedule={careplan.cycleSchedule}
        selectedCycle={selectedCycle}
        open={supportingDrugModalOpen}
        setNewSupportingDrugOrder={setNewSupportingDrugOrder}
        setSupportingDrugModalOpen={setSupportingDrugModalOpen}
      />
      <SequencePopper
        open={newSupportingDrugOrder !== null}
        onCancel={cancelAddingDrugOrder}
        onSave={confirmAddingDrugOrder}
        saveDisabled={newSupportingDrugOrder?.inStaging}
      />
    </GreyBox>
  );
};
