import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'gc-ui';

interface Props {
  startingCycle: number;
  maxCycles: number;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}
const ProtocolCyclesErrorModal = (props: Props): JSX.Element => {
  return (
    <Modal width="small" open={props.isOpen}>
      <ModalHeader>Protocol cycles exceeded</ModalHeader>
      <ModalBody>
        <div
          style={{
            display: 'grid',
            gridAutoFlow: 'row',
            gridTemplateColumns: '1fr 0.6fr',
            gridTemplateRows: '1fr 1fr',
            gap: '16px 16px',
            fontWeight: 700,
          }}>
          <div>Total protocol cycles:</div>
          <div>{props.maxCycles}</div>
          <div>Starting cycle:</div>
          <div>{props.startingCycle}</div>
        </div>
        <div style={{ paddingTop: '16px' }}>The starting cycle will be a copy of the last protocol cycle.</div>
      </ModalBody>
      <ModalFooter>
        <Button data-cy="protocol-cycle-modal-cancel" mode="outlined" onClick={props.onCancel} size={'auto'}>
          Cancel
        </Button>
        <Button data-cy="protocol-cycle-modal-confirm" mode="contained" onClick={props.onConfirm}>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProtocolCyclesErrorModal;
