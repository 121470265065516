// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { styled } from '@mui/system';
import CareplanButton from './CareplanButton';
import GenericModal from 'shared-components/components/Modals/GenericModal/GenericModal';
import GCButton from 'shared-components/components/UIFormComponents/GCButton';
import { CareplanTemplate } from '../../Interface';
import { useTheme } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

const AnnotationText = styled('p')`
  font-size: 80%;
`;

const SuggestedTemplates = (props: any): JSX.Element => {
  const { tumourStream, onSelect, templates = [], title, subheading, generateFooter, type } = props;
  const theme = useTheme();
  const [modalIsOpen, setIsModalOpen] = useState(false);

  const onClickHandler = (template: CareplanTemplate) =>
    onSelect(tumourStream, template.name, template.id, '', type === 'freq' ? 'frequently_used' : type);

  return (
    <>
      <div className="page-sub-header">
        <h4 style={{ display: 'flex', alignItems: 'center' }}>
          {title}
          {type === 'sigma' && (
            <HelpOutline
              color="info"
              sx={{ marginLeft: '6px', cursor: 'pointer' }}
              data-testid="sigma-question-mark-"
              onClick={() => setIsModalOpen(true)}
            />
          )}
        </h4>
        <h2>{templates.length > 0 && subheading}</h2>
      </div>
      {templates.length > 0 ? (
        <div className="suggested-careplan-grid">
          {templates.slice(0, 6).map((currTemplate: CareplanTemplate, index: number) => {
            const footer = generateFooter(currTemplate);
            return (
              <CareplanButton
                dataTestId="suggested-careplan-item"
                key={currTemplate.id}
                currentTemplate={currTemplate}
                className={'suggested-careplan-item'}
                index={index}
                footer={footer}
                onClickHandler={() => onClickHandler(currTemplate)}
              />
            );
          })}
        </div>
      ) : (
        <div className="suggested-careplan-grid">No careplan suggestions for this tumour stream.</div>
      )}
      <GenericModal
        maxWidth="100%"
        centered
        headerText={'Sigma Suggestions'}
        bodyElements={
          <>
            <p>
              Sigma suggests careplan templates based on the diagnosis data entered by analysing past prescriptions for
              patients with similar diagnoses.
            </p>
            <p>
              The yellow bars show the <strong>Sigma score</strong> (0.0 - 1.0) for each suggestion. These scores are
              calculated by Sigma and used to rank templates, with the highest scores shown first.
            </p>
            <p>
              A <strong>higher score</strong> means that more patients with a similar diagnosis have been prescribed a
              careplan similar* to the template in the past.
            </p>
            <p>
              The score would be 1.0 (full bar) for a template if in the past all patients with the same diagnosis were
              prescribed exactly this template.
            </p>
            <AnnotationText>
              *Similar in terms of treatment sites, phases, techniques, dose and fractions.
            </AnnotationText>
          </>
        }
        footerElements={
          <GCButton
            buttonText={'Close'}
            backgroundColor={theme.palette.primary.main}
            textColor={theme.palette.primary.contrastText}
            fontWeight={'700'}
            onClick={(e): void => {
              e.preventDefault();
              setIsModalOpen(false);
            }}
            borderColor={theme.palette.primary.main}
          />
        }
        isOpen={modalIsOpen}
        dismissFunction={() => {
          setIsModalOpen(false);
        }}></GenericModal>
    </>
  );
};

export default SuggestedTemplates;
