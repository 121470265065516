import React from 'react';
import { PatientTooltip as IPatientTooltip } from 'op-interfaces';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import { Link } from 'react-router-dom';
import PatientTooltip from '../../../pages/RO/Dashboard/PatientTooltip';
import { isDemo } from 'op-utils';
import { patientGenderStockImage } from 'op-utils/PatientHelper';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

interface Props {
  recentPatients: IPatientTooltip[];
  isRo: boolean;
}

const BaseSpan = styled('span')`
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.secondary.dark};
`;

const StyleDivWrapper = styled('div')`
  padding-top: 16px;
`;

const StyledPatientList = styled(BaseSpan)`
  margin-left: 16px;
`;

const StyledPatientLink = styled(Link)`
  width: 100%;
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledImageDiv = styled('div')`
  float: left;
  margin-left: 16px;
  padding-top: 4px;
`;

const NoRecentPatients = styled('div')`
  white-space: break-spaces;
  line-height: 24px;
  padding-left: 16px;
  font-size: 16px;
`;

const RecentPatients = (props: Props): JSX.Element | null => {
  const { recentPatients, isRo } = props;

  const heading = isRo ? (
    <div>
      <StyledPatientList data-test-id="last-5-patients">Last 5 Patients</StyledPatientList>
    </div>
  ) : (
    <></>
  );

  if (!recentPatients?.length) {
    return isRo ? (
      <>
        {heading}
        <StyleDivWrapper />
        <NoRecentPatients data-test-id="no-recent-patients">
          No recent patients available because you have not visited any patient summaries.
        </NoRecentPatients>
      </>
    ) : null;
  }
  return (
    <>
      {heading}
      <StyleDivWrapper />
      {recentPatients.map((recentPatient: IPatientTooltip, index: number): JSX.Element => {
        const patientImage = isDemo ? patientGenderStockImage(recentPatient.gender) : recentPatient.photoUrl;
        return (
          <PatientTooltip patient={recentPatient}>
            <Box sx={{ paddingTop: '8px' }}>
              <StyledPatientLink to={`/radiation/patient/${recentPatient.id}/summary`}>
                <Stack direction="row" key={recentPatient.id} id={`patient-${recentPatient.id}`} gap="8px">
                  <StyledImageDiv>
                    <PhotoWidget url={patientImage} enableOverlay={true} size={'sm'} />
                  </StyledImageDiv>
                  <Stack direction="column">
                    <Typography variant="subtitle1" data-test-id={`patient-${index}-full-name`}>
                      {recentPatient.fullName}
                    </Typography>
                    <Typography variant="body2" data-test-id={`patient-${index}-ida`}>
                      ID: {recentPatient.ida}
                    </Typography>
                    <Typography variant="body2" data-test-id={`patient-${index}-emr`}>
                      EMR: {recentPatient.emrInstance}
                    </Typography>
                  </Stack>
                </Stack>
              </StyledPatientLink>
            </Box>
          </PatientTooltip>
        );
      })}
    </>
  );
};
export default RecentPatients;
