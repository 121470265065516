import { Query } from '@apollo/client/react/components';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import React, { Component, Fragment, useEffect } from 'react';
import validate from 'validate.js';
import './FileUploadField.scss';
import { ModalSubmit as Modal } from 'op-components';
import { useErrorModalContext } from 'op-contexts';
import { PatientAttachment } from 'op-interfaces';
import { documentTypeOptions, getDocumentType } from 'op-pages/OP/RegistrationForm/utils';
import { registrationAttachments } from 'op-utils';
import { Prompt } from 'react-router-dom';
import { Checkbox, DropDownField, ErrorInfo, GCButton, BaseDatePicker } from 'shared-components/components/FormFields';
import ModalInfo from 'shared-components/components/Modals/ModalInfo/ModalInfo';
import { ButtonType, Region } from 'shared-components/enums';
import { Delete, Info } from 'shared-components/images';
import { ListData } from 'shared-components/interfaces';
import { DeviceUtilities } from 'shared-components/utils';
import FileUploadCard from './FileUploadCard';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import {
  ChangeDocumentTypeMutation,
  ChangeEncounterDateMutation,
  CreateUKAttachmentMutation,
  DeleteDocumentMutation,
  GET_UPLOAD_DETAILS_QUERY,
} from './FileUploadQueries';
import UploadCard from './UploadCard';
import {
  ATTACHMENT_AMOUNT_ERROR,
  AUSFIELD,
  FILE_SIZE_ERROR,
  FORGOT_UPLOAD_TEXT,
  FORGOT_UPLOAD_TITLE,
  UKFIELD,
  UNKNOWN_ERROR,
} from './constants';
import { checkFileExtension } from './helper';
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  Table,
  TableBody,
  TableRow as MuiTableRow,
  TableHead,
  TableCell,
  tableCellClasses,
  styled,
} from '@mui/material';
import { Close as CloseIcon, DescriptionOutlined as DescriptionOutlinedIcon } from '@mui/icons-material';

// interfaces
interface RowProps {
  attachmentPk: string;
  downloadUrl: string;
  documentTypeRefData: ListData[];
  documentType: string;
  filename: string;
  filesize: number;
  btnTitle: string;
  loading: boolean;
  type: ButtonType;
  disableBtn: boolean;
  disableSelect: boolean;
  onClick?: (e: any) => void;
  changeDocument: (e: any) => void;
  removeDocument: (e: any) => void;
  progress: number;
  errors: string[];
  submittedToMosaiq: boolean;
  allowDelete: boolean;
  handleDateChange: (value: any) => void;
  encounterDate: Dayjs;
}

interface InnerProps extends WithApolloClient<{}> {
  file: File;
  fileLimitReached: boolean;
  documentTypeRefData: ListData[];
  handleFileDelete: (file: File) => void;
  refetchCallback: () => void;
  documentType: string;
  key: number;
  patient: any;
  user: any;
  fileUploaded: boolean;
  onCloseUploadErrorModal: any;
  onOpenUploadErrorModal: any;
  uploadInfoErrorModalOpen: boolean;
}

interface InnerState {
  fields: any;
  documentType: string;
  url: string;
  progress: number;
  loading: boolean;
  errors: string[];
  encounterDate: Dayjs;
  hasBeganUpload?: boolean;
}

interface Props extends WithApolloClient<{}> {
  patient: any;
  user: any;
  refetchCallback: () => void;
  documentTypeRefData: ListData[];
  fileUploaded: boolean;
  onIncompleteFileUpload: any;
  onCompleteFileUpload: any;
  onCloseUploadErrorModal: any;
  onOpenUploadErrorModal: any;
  uploadInfoErrorModalOpen: boolean;
  isPso: boolean;
  updateAttachmentAcknowledged: (id: string, attachmentAcknowledged: boolean) => void;
  validateOnLoad: boolean;
}

interface State {
  selectedFiles: any[];
  documentType: string | null;
  dropzoneClass: string;
  deleteModalOpen: boolean;
  continueModalOpen: boolean;
  selectedDocumentId: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(MuiTableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledDownloadLink = styled('a')`
  margin-top: 16px,
  color: ${(props) => props.theme.palette.primary.main},
  fontWeight: bolder,
  display: inline-block,
`;

const StyledDocumentContainer = styled('div')`
  padding-bottom: 24px;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.main};
  margin-bottom: 16px;
`;

// components
export class TableRow extends Component<RowProps> {
  // the basic tr element of the table showing uploaded file attachments
  private humanFileSize(bytes: number): string {
    // convert raw bytes to a human readable string
    const thresh = 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    const units = ['KB', 'MB', 'GB', 'TB'];
    let u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }

  public render(): JSX.Element {
    const {
      downloadUrl,
      filename,
      filesize,
      btnTitle,
      loading,
      type,
      disableBtn,
      disableSelect,
      onClick,
      progress,
      changeDocument,
      documentType,
      documentTypeRefData,
      errors,
      removeDocument,
      attachmentPk,
      submittedToMosaiq,
      allowDelete,
      handleDateChange,
      encounterDate,
    } = this.props;

    // @ts-ignore
    return (
      <StyledTableRow id="table-header">
        <TableCell>
          {allowDelete && (
            <IconButton onClick={removeDocument}>
              <CloseIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          {downloadUrl ? (
            <StyledDownloadLink id="link-adjustment" href={downloadUrl}>
              {filename}
            </StyledDownloadLink>
          ) : (
            <p id="file-adjustments">{filename}</p>
          )}
          <br />
          <ErrorInfo type="table-error" errors={errors} />
        </TableCell>
        <TableCell>
          <p id="file-adjustments">{this.humanFileSize(filesize)}</p>
        </TableCell>
        <TableCell>
          <DropDownField
            disabled={disableSelect}
            inputName={attachmentPk}
            controlled={true}
            placeholder="Select"
            defaultValue={documentType}
            options={documentTypeRefData}
            onChange={changeDocument}
          />
        </TableCell>
        <TableCell>
          <p id="file-adjustments">{submittedToMosaiq ? 'Yes' : 'No'}</p>
        </TableCell>
        <TableCell>
          <div className="dobDiv">
            <BaseDatePicker
              id="dobDiv"
              readOnly={disableSelect}
              disableFuture
              value={dayjs(encounterDate)}
              onChange={handleDateChange}
            />
          </div>
        </TableCell>
        <TableCell>
          <GCButton title={btnTitle} loading={loading} type={type} disabled={disableBtn} onClick={onClick} />
        </TableCell>
        <TableCell>
          <LinearProgress id="bar-adjustments" color="primary" variant="determinate" value={progress} />
        </TableCell>
      </StyledTableRow>
    );
  }
}
class Uploader extends Component<InnerProps, InnerState> {
  private region = import.meta.env.REACT_APP_REGION;
  public constructor(props: InnerProps) {
    super(props);
    const { file } = this.props;

    const extension = file.name.split('.')[file.name.split('.').length - 1];

    const errors = [];

    if (extension) {
      const error = checkFileExtension(extension);
      if (error) {
        errors.push(error);
      }
    }

    this.state = {
      progress: 0,
      documentType: props.documentType.toString(),
      loading: false,
      fields: [],
      errors,
      url: '',
      encounterDate: dayjs(),
    };
  }

  private closeUploadInfoErrorModal = (): void => {
    this.props.onCloseUploadErrorModal();
  };

  private openUploadInfoErrorModal = (): void => {
    this.props.onOpenUploadErrorModal();
  };

  private handleBlockedNavigation = (): boolean => {
    this.openUploadInfoErrorModal();
    return false;
  };

  private uploadFile(url: string, fields: any): void {
    this.setState({ ...this.state, loading: true });
    const { file, refetchCallback, handleFileDelete } = this.props;
    const { documentType, encounterDate } = this.state;
    const xhr = new XMLHttpRequest();
    const formData = new FormData();

    const parsedPayload = JSON.parse(fields.awsPayload);
    for (const [key, value] of Object.entries(parsedPayload.fields)) {
      formData.append(key, String(value));
    }
    formData.append('Content-Type', file.type);
    formData.append('file', file);

    xhr.upload.addEventListener('progress', (e): void => {
      const progress = (e.loaded / e.total) * 100;
      this.setState({ ...this.state, progress });
    });
    xhr.addEventListener('load', (): void => {
      const { patient, user, client } = this.props;
      const variables = {
        awsKey: fields.awsKey,
        patientPk: patient.id,
        userPk: user.id,
        isRegistrationAttachment: true,
        documentType,
        encounterDate,
      };

      const statusCode = xhr.status;
      if ([200, 201, 204].includes(statusCode)) {
        //@ts-ignore
        client
          .mutate({
            mutation: CreateUKAttachmentMutation,
            variables,
          })
          .then(async function (): Promise<void> {
            await refetchCallback();
            handleFileDelete(file);
          })
          .catch((): void => {
            this.setState({ ...this.state, errors: [UNKNOWN_ERROR], loading: false });
          });
      } else {
        this.setState({ ...this.state, errors: [UNKNOWN_ERROR], loading: false });
      }
    });
    xhr.addEventListener('error', (): void => {
      this.setState({ ...this.state, errors: [UNKNOWN_ERROR], loading: false });
    });

    xhr.open('POST', url);
    xhr.send(formData);
  }

  public render(): JSX.Element {
    const { file, documentTypeRefData, handleFileDelete } = this.props;
    const { documentType, encounterDate } = this.state;
    const variables = {
      fileName: file.name,
    };
    const handleDateChange = (encounterDate: Dayjs): void => {
      this.setState({ encounterDate });
    };

    return (
      <Query query={GET_UPLOAD_DETAILS_QUERY} variables={variables}>
        {({ loading, data, error }: any): JSX.Element => {
          const { setError } = useErrorModalContext();
          useEffect(() => {
            if (error) return setError();
          }, [error]);

          if (data && data.fileUploadPayload) {
            const { fields, url } = data.fileUploadPayload;

            if (file.size > 20971520) {
              // 20971520 = 20Mb
              if (this.state.errors.indexOf(FILE_SIZE_ERROR) === -1) {
                this.setState({ ...this.state, errors: [...this.state.errors, FILE_SIZE_ERROR] });
              }
            }

            const { loading, progress, errors } = this.state;
            const downloadUrl = this.state.url;
            if (this.state && !this.state.fields) {
              this.setState({ ...this.state, fields, url });
            }

            const handleDocumentTypeChange = (e: any): void => {
              if (e.target && e.target.value) {
                this.setState({ ...this.state, documentType: e.target.value });
              }
            };

            const uploadComplete = progress >= 100;

            let disableBtn = uploadComplete || errors.length > 0;
            if (this.region === Region.UK) {
              disableBtn = uploadComplete || !documentType || !encounterDate || errors.length > 0;
            }

            const attachmentPk = `${file.name}-${file.lastModified}`;

            return (
              <Fragment>
                <Prompt message={this.handleBlockedNavigation}></Prompt>
                <ModalInfo
                  isOpen={this.props.uploadInfoErrorModalOpen}
                  title={FORGOT_UPLOAD_TITLE}
                  text={FORGOT_UPLOAD_TEXT}
                  extraClass="pending-submit-modal"
                  dismissFunction={(): void => {
                    this.closeUploadInfoErrorModal();
                  }}
                />
                <TableRow
                  attachmentPk={attachmentPk}
                  downloadUrl={downloadUrl}
                  errors={errors}
                  documentTypeRefData={documentTypeRefData}
                  documentType={documentType}
                  changeDocument={handleDocumentTypeChange}
                  removeDocument={handleFileDelete}
                  submittedToMosaiq={false}
                  filename={file.name}
                  filesize={file.size}
                  encounterDate={encounterDate}
                  handleDateChange={handleDateChange}
                  btnTitle={uploadComplete ? 'Uploaded' : 'Upload'}
                  loading={loading}
                  type={disableBtn ? ButtonType.DISABLED : ButtonType.WHITE}
                  disableBtn={disableBtn}
                  allowDelete={true}
                  disableSelect={progress >= 100}
                  onClick={(e: Event): void => {
                    e.preventDefault();
                    this.uploadFile(url, fields);
                  }}
                  progress={progress}
                />
              </Fragment>
            );
          }
          if (loading) {
            return (
              <tr>
                <td>loading</td>
              </tr>
            );
          }
          return <div></div>;
        }}
      </Query>
    );
  }
}
//@ts-ignore
export const ApolloUploader = withApollo(Uploader);

class FileUploadField extends Component<Props, State> {
  private readonly fileInputRef: React.RefObject<HTMLInputElement>;
  private region = import.meta.env.REACT_APP_REGION;
  public constructor(props: Props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      documentType: '',
      selectedFiles: [],
      dropzoneClass: 'dropzone',
      deleteModalOpen: false,
      continueModalOpen: false,
      selectedDocumentId: '',
    };
  }
  uploadFile = (): void => {
    // @ts-ignore
    this.fileInputRef.current.click();
  };

  public componentDidMount(): void {
    window.addEventListener('dragover', (event: DragEvent): void => {
      event.preventDefault();
    });
    window.addEventListener('drop', (event: DragEvent): void => {
      event.preventDefault();
    });
  }

  private selectFiles(files: File[]): void {
    const { selectedFiles } = this.state;
    const file = Array.from(files)[0];
    // file must exist and have File properties
    if (file instanceof File) {
      selectedFiles.push(file);
      this.setState({ selectedFiles });
    }
  }

  private removeFile(file: File): void {
    const { selectedFiles } = this.state;
    const filtered = selectedFiles.filter((oldFile: File): boolean => {
      let include = true;
      if (file.name) {
        if (file.name === oldFile.name && file.lastModified === oldFile.lastModified) include = false;
      }
      return include;
    });

    this.setState({ ...this.state, selectedFiles: filtered });
  }

  private handleDocumentTypeChange(attachmentPk: string, documentType: string): void {
    //@ts-ignore
    this.props.client.mutate({
      mutation: ChangeDocumentTypeMutation,
      variables: {
        attachmentPk,
        documentType,
      },
    });
  }

  private handleDateChange(attachmentPk: string, encounterDate: string): void {
    const { refetchCallback } = this.props;
    //@ts-ignore
    this.props.client
      .mutate({
        mutation: ChangeEncounterDateMutation,
        variables: {
          attachmentPk,
          encounterDate,
        },
      })
      .then((): void => refetchCallback());
  }

  private handleDocumentRemoval(attachmentPk: string): void {
    const { refetchCallback } = this.props;
    //@ts-ignore
    this.props.client
      .mutate({
        mutation: DeleteDocumentMutation,
        variables: {
          attachmentPk,
        },
      })
      .then((): void => refetchCallback());
  }

  private renderAUSfield(globalProps: any): JSX.Element {
    const {
      selectedFiles,
      patient,
      refetchCallback,

      handleDrop,
      onDragOver,
      onDragLeave,
      fileLimitReached,
      isPso,
      updateAttachmentAcknowledged,
      validateOnLoad,
    } = globalProps;

    const showTabletText = DeviceUtilities.isTabletDevice();

    const fileUploadProgress = selectedFiles.map(
      (file: any, key: number): JSX.Element => (
        <FileUploadCard
          key={key}
          patient={patient}
          file={file}
          refetchCallback={refetchCallback}
          handleFileDelete={(): void => {
            this.removeFile(file);
          }}
        />
      ),
    );

    const docTypeValidationObject = validateOnLoad && this.validateAttachments(patient);
    const acknowledgeValidationObject = validateOnLoad && this.validateAcknowledge(patient, isPso);
    return (
      <div className="attachments-body">
        {!isPso ? (
          <>
            <div>
              <p className="attachments-helper-text">{AUSFIELD.HELPER_TEXT}</p>
            </div>
            <div>
              <h4>{AUSFIELD.HELPER_TITLE}</h4>
              <div className={'unordered-lists'}>
                <ul className={'attachments-list'}>
                  <li key={'medicare'}>{AUSFIELD.DOCUMENTS.MEDICARE}</li>
                  <li key={'insurance'}>{AUSFIELD.DOCUMENTS.PRIVATE_INSURANCE}</li>
                  <li key={'Pension'}>{AUSFIELD.DOCUMENTS.PENSION}</li>
                  <li key={'DVA'}>{AUSFIELD.DOCUMENTS.DVA}</li>
                  <li key={'Test'}>{AUSFIELD.DOCUMENTS.TEST_RESULTS}</li>
                  <li key={'Pacemaker'}>{AUSFIELD.DOCUMENTS.PACEMAKER}</li>
                </ul>
                <ul className={'attachments-list'}>
                  <li key={'Referral'}>{AUSFIELD.DOCUMENTS.REFERRAL}</li>
                  <li key={'attorney'}>{AUSFIELD.DOCUMENTS.ATTORNEY}</li>
                  <li key={'Ambulance'}>{AUSFIELD.DOCUMENTS.AMBULANCE}</li>
                  <li key={'Medications'}>{AUSFIELD.DOCUMENTS.MEDICATIONS}</li>
                  <li key={'directive'}>{AUSFIELD.DOCUMENTS.ADVANCE_CARE}</li>
                </ul>
              </div>
              <div className="attachments-info-text">{AUSFIELD.ATTACHMENTS_INFO}</div>
            </div>
          </>
        ) : (
          <>
            {patient.userProfile?.systemState !== 'THA' && <h3>{AUSFIELD.SYSTEM_STATE_MSG}</h3>}

            {registrationAttachments(patient.attachments).map(
              (attachment: PatientAttachment, key: number): JSX.Element => {
                return (
                  <div className="attachment-container" key={attachment.id}>
                    <Modal
                      isOpen={this.state.deleteModalOpen || this.state.continueModalOpen}
                      submitForm={(): void => {
                        this.handleDocumentRemoval(this.state.selectedDocumentId);
                        this.setState({ ...this.state, deleteModalOpen: false, selectedDocumentId: '' });
                      }}
                      dismissFunction={(): void => {
                        this.setState({ ...this.state, deleteModalOpen: false });
                      }}
                      headerText={AUSFIELD.MODAL.HEADER}
                      submitText={AUSFIELD.MODAL.SUBMIT}
                      emptyBody
                      removeHeaderPadding
                      keepUnlocked
                    />
                    <StyledDocumentContainer className="document-container">
                      <div className="document-list">
                        <strong>{AUSFIELD.PATIENT_ATTACHMENTS.FILE_NAME}</strong>
                        <div className="preview-delete-container">
                          <a
                            data-testid={`preview-${attachment.filename}`}
                            className="document-icon-column"
                            href={attachment.url}
                            target="_blank"
                            rel="noopener noreferrer">
                            <DescriptionOutlinedIcon color="primary" />
                            <strong>{AUSFIELD.PATIENT_ATTACHMENTS.PREVIEW}</strong>
                          </a>
                          <span
                            data-testid={`delete-${attachment.filename}`}
                            className="document-icon-column"
                            onClick={(): void => {
                              this.setState({
                                ...this.state,
                                deleteModalOpen: true,
                                selectedDocumentId: attachment.id,
                              });
                            }}>
                            <Delete id="delete-icon" />
                            <strong>{AUSFIELD.PATIENT_ATTACHMENTS.DELETE}</strong>
                          </span>
                        </div>
                      </div>
                      <span>{attachment.filename}</span>
                      {patient.userProfile?.systemState !== 'THA' && (
                        <>
                          <div className="document-type-header">
                            <strong>{AUSFIELD.PATIENT_ATTACHMENTS.DOC_TYPE}</strong>
                          </div>
                          <DropDownField
                            inputKey={`document-type-${key}`}
                            inputName="document-type"
                            placeholder="Please choose..."
                            options={documentTypeOptions}
                            onChange={(e): void => {
                              this.handleDocumentTypeChange(attachment.id, e.target.value);
                            }}
                            className={`document-type-dropdown document-type-${key}`}
                            defaultValue={getDocumentType(attachment)}
                            errors={
                              !attachment.documentType &&
                              docTypeValidationObject &&
                              docTypeValidationObject[attachment.filename]
                                ? docTypeValidationObject[attachment.filename]
                                : ''
                            }
                          />
                          {attachment.suggestedDocumentType &&
                            getDocumentType(attachment) === attachment.suggestedDocumentType && (
                              <span id="suggested-document-container">
                                <Info id="info-icon" />
                                {AUSFIELD.PATIENT_ATTACHMENTS.SUGGESTED_DOC_TYPE}
                              </span>
                            )}
                        </>
                      )}
                    </StyledDocumentContainer>
                  </div>
                );
              },
            )}
            {fileUploadProgress}
            {patient.userProfile?.systemState !== 'THA' && registrationAttachments(patient.attachments).length >= 1 && (
              <>
                <h2 className="acknowledgement-title">{AUSFIELD.ACKNOWLEDGMENT.TITLE}</h2>
                <p className="acknowledgement-subtitle">{AUSFIELD.ACKNOWLEDGMENT.SUBTITLE}</p>
                <Checkbox
                  inputName="attachmentAcknowledged"
                  inputLabel={AUSFIELD.ACKNOWLEDGMENT.INPUT_LABEL}
                  isChecked={patient.attachmentAcknowledged}
                  onChange={(isChecked): void => updateAttachmentAcknowledged(patient.id, isChecked)}
                  errors={
                    acknowledgeValidationObject?.attachmentAcknowledged
                      ? [AUSFIELD.ACKNOWLEDGMENT.INPUT_ERROR]
                      : undefined
                  }
                />
                {!acknowledgeValidationObject?.attachmentAcknowledged && <div className="checkbox-padding"></div>}
              </>
            )}
          </>
        )}
        <Stack
          onDrop={fileLimitReached ? (): void => {} : handleDrop}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          className={'AU-dropzone'}
          id="dropContainer"
          onClick={this.uploadFile}>
          {!fileLimitReached ? (
            <>
              <div className={'cloud-upload-image'} />
              <Typography variant="subtitle2" className="dropzone-text">
                {showTabletText && AUSFIELD.CARD.TAKE_OR_ATTACH}
                {!showTabletText && AUSFIELD.CARD.DRAG_AND_DROP}
                {!showTabletText && <br />}
                {!showTabletText && 'OR'}
              </Typography>
              <div className={'choose-file-button'}>{AUSFIELD.CARD.CHOOSE_FILE}</div>
            </>
          ) : (
            <>
              <Typography variant="subtitle2" className="dropzone-text">
                {AUSFIELD.CARD.LIMIT_REACHED}
                <br />
                {AUSFIELD.CARD.REMOVE_EXISTING}
              </Typography>
            </>
          )}
          <input
            id="fileInput"
            type="file"
            disabled={fileLimitReached}
            ref={this.fileInputRef}
            className="invisibleFiles"
            onClick={(e: any): void => {
              e.target.value = null;
            }}
            onChange={(e: any): void => {
              this.selectFiles(e.target.files);
              this.props.onIncompleteFileUpload();
            }}
          />

          {!fileLimitReached && <div className={'dropzone-file-types'}>{AUSFIELD.CARD.FILE_TYPES}</div>}
        </Stack>
        {!isPso &&
          patient.attachments.map((attachment: PatientAttachment): JSX.Element => {
            return (
              <UploadCard
                downloadUrl={attachment.url}
                errors={[]}
                removeDocument={(): void => {
                  this.handleDocumentRemoval(attachment.id);
                }}
                filename={attachment.filename}
                progress={100}
                key={attachment.filename + attachment.id}
              />
            );
          })}
        {!isPso && fileUploadProgress}
      </div>
    );
  }

  private renderUKfield(UKprops: any): JSX.Element {
    const {
      selectedFiles,
      patient,
      documentTypeRefData,
      user,
      refetchCallback,

      handleDrop,
      onDragOver,
      onDragLeave,
      dropzoneClass,
      fileLimitReached,
    } = UKprops;
    return (
      <Fragment>
        <Table>
          <TableHead>
            <tr id="table-header">
              <StyledTableCell />
              <StyledTableCell>{UKFIELD.HEADER.FILENAME}</StyledTableCell>
              <StyledTableCell>{UKFIELD.HEADER.FILE_SIZE}</StyledTableCell>
              <StyledTableCell>{UKFIELD.HEADER.DOC_TYPE}</StyledTableCell>
              <StyledTableCell>{UKFIELD.HEADER.SUBMIT_TO_MOSAIQ}</StyledTableCell>
              <StyledTableCell>{UKFIELD.HEADER.ENCOUNTER_DATE}</StyledTableCell>
              <StyledTableCell>{UKFIELD.HEADER.UPLOAD}</StyledTableCell>
              <StyledTableCell>{UKFIELD.HEADER.PROGRESS}</StyledTableCell>
            </tr>
          </TableHead>
          <TableBody>
            {patient.attachments.map((attachment: any, key: number): JSX.Element => {
              return (
                <TableRow
                  key={selectedFiles.length + key}
                  attachmentPk={attachment.id}
                  downloadUrl={attachment.url}
                  filename={attachment.filename}
                  filesize={attachment.filesize}
                  errors={[]}
                  submittedToMosaiq={attachment.submitted}
                  changeDocument={(e: any): void => {
                    this.handleDocumentTypeChange(attachment.id, e.target.value);
                  }}
                  removeDocument={(): void => {
                    this.handleDocumentRemoval(attachment.id);
                  }}
                  handleDateChange={(value: any): void => {
                    this.handleDateChange(attachment.id, value);
                  }}
                  encounterDate={dayjs(attachment.encounterDate)}
                  documentTypeRefData={documentTypeRefData}
                  documentType={attachment.documentType}
                  btnTitle="Uploaded"
                  loading={false}
                  allowDelete={!attachment.submitted}
                  type={ButtonType.DISABLED}
                  disableBtn={true}
                  disableSelect={attachment.submitted}
                  progress={100}
                />
              );
            })}

            {selectedFiles.map((file: any, key: number): JSX.Element => {
              return (
                <ApolloUploader
                  //@ts-ignore
                  handleFileDelete={(): void => {
                    this.removeFile(file);
                    this.props.onCompleteFileUpload();
                  }}
                  documentType=""
                  refetchCallback={refetchCallback}
                  documentTypeRefData={documentTypeRefData}
                  key={key}
                  file={file}
                  patient={patient}
                  user={user}
                  fileUploaded={this.props.fileUploaded}
                  onCloseUploadErrorModal={this.props.onCloseUploadErrorModal}
                  onOpenUploadErrorModal={this.props.onOpenUploadErrorModal}
                  uploadInfoErrorModalOpen={this.props.uploadInfoErrorModalOpen}
                />
              );
            })}
          </TableBody>
        </Table>
        <Stack
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          style={fileLimitReached || selectedFiles.length > 0 ? { backgroundColor: 'lightgrey' } : {}}
          className={dropzoneClass}
          onDrop={fileLimitReached || selectedFiles.length > 0 ? (): void => {} : handleDrop}
          id="dropContainer"
          onClick={this.uploadFile}>
          <Box>
            <Typography variant="subtitle2" className="dropzone-text">
              {fileLimitReached
                ? ATTACHMENT_AMOUNT_ERROR
                : selectedFiles.length > 0
                ? UKFIELD.REMOVE_OR_UPLOAD
                : UKFIELD.CLICK_OR_DRAG}
            </Typography>
            <input
              id="fileInput"
              type="file"
              disabled={fileLimitReached || selectedFiles.length > 0}
              ref={this.fileInputRef}
              className="invisibleFiles"
              onClick={(e: any): void => {
                e.target.value = null;
              }}
              onChange={(e: any): void => {
                this.selectFiles(e.target.files);
                this.props.onIncompleteFileUpload();
              }}
            />
          </Box>
        </Stack>
      </Fragment>
    );
  }

  public render(): JSX.Element {
    const {
      documentTypeRefData,
      patient,
      user,
      refetchCallback,

      isPso,
      updateAttachmentAcknowledged,
      validateOnLoad,
    } = this.props;

    const handleDrop = (event: any): void => {
      const newFiles = [];
      if (event.dataTransfer.items.length && event.dataTransfer.items[0].kind === 'file') {
        newFiles.push(event.dataTransfer.items[0].getAsFile());
      }
      this.selectFiles(newFiles);
      this.setState({ ...this.state, dropzoneClass: 'dropzone' });
    };

    const onDragOver = (): void => {
      this.setState({ ...this.state, dropzoneClass: 'hovered' });
    };

    const onDragLeave = (): void => {
      this.setState({ ...this.state, dropzoneClass: 'dropzone' });
    };

    const { selectedFiles, dropzoneClass } = this.state;

    const attachmentCount = patient.attachments.length + selectedFiles.length;

    const fileLimitReached = attachmentCount >= 10;

    const props = {
      selectedFiles,
      patient,
      documentTypeRefData,
      user,
      refetchCallback,

      handleDrop,
      onDragOver,
      onDragLeave,
      dropzoneClass,
      fileLimitReached,
      isPso,
      updateAttachmentAcknowledged,
      validateOnLoad,
    };

    return this.region === Region.UK ? this.renderUKfield(props) : this.renderAUSfield(props);
  }

  private validateAttachments = (patient: any): any => {
    const { attachments } = patient;
    const attachmentRules = {
      documentType: {
        presence: {
          allowEmpty: false,
        },
      },
    };
    const validatedItems = {};
    attachments &&
      attachments.forEach((item: any): void => {
        const validatedItem = validate(item, attachmentRules);
        if (validatedItem) {
          //@ts-ignore
          validatedItems[`${item.filename}`] = validatedItem.documentType;
        }
      });
    if (Object.keys(validatedItems).length !== 0) {
      return validatedItems;
    }
  };

  private validateAcknowledge = (patient: any, isPso: boolean): any => {
    let validationRules: { [key: string]: object } = {};
    if (!patient.attachmentAcknowledged) {
      validationRules = {
        attachmentAcknowledged: {
          presence: isPso,
          inclusion: {
            within: [true],
          },
        },
      };
    }
    return validate(patient, validationRules);
  };
}

const apolloComponent = withApollo<Props>(FileUploadField);
export default apolloComponent;
