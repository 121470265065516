import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import {
  GET_CAREPLAN_SITE_GROUPS,
  UPDATE_SITE_VALUE,
  SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION,
  GET_CAREPLAN,
  SITE_TEMPLATE_VALUES_IN_SITE_GROUP,
  SITE_GROUP_TEMPLATE_VALUE_VARIATION,
} from '../Queries';
import { ROPatientCarePlanRoute } from '../Interface';
import classnames from 'classnames';
import './VolumingPage.scss';
import { CleanSpecifyFromSiteName, isSpecifySite, removeUnicodeQuotes } from '../Common';
import { useErrorModalContext } from 'op-contexts';
import TargetValueField from './TargetValueField';
import { Typography, Stack, useTheme, Divider } from '@mui/material';

interface TreatmentRuleSite {
  id: number;
  field: {
    id: number;
    name: string;
  };
  defaultValue: string;
}

interface TemplateSet {
  id: number;
  treatmentrulesiteSet: TreatmentRuleSite[];
}

interface SiteGroupTemplate {
  id: number;
  treatmentsiteprescriptiontemplateSet: TemplateSet[];
}
interface SiteTemplate {
  siteGroupTemplate: SiteGroupTemplate;
}

interface SiteValue {
  id: number;
  field: {
    id: number;
    name: string;
  };
  value: string;
}

interface Site {
  id: number;
  treatmentSite: {
    id: number;
    treatmentSite: string;
    defaultLaterality: string;
  };
  template: {
    id: number;
  };
  sitevaluesSet: SiteValue[];
}

interface SiteGroupsByCareplan {
  id: number;
  siteSet: Site[];
}

interface VolumingTVValues {
  siteGroupsByCareplan: SiteGroupsByCareplan[];
}

interface Props {
  cpotTriggered: boolean;
  refresh: boolean;
}

interface SiteTargetValuesState {
  name: string;
  id: number;
  templateSiteId: number;
  GTVId: number;
  CTVId: number;
  PTVId: number;
  GTV: string;
  CTV: string[];
  PTV: string[];
  Dose: number[];
  PTVOffTemplate: boolean;
  CTVOffTemplate: boolean;
  GTVOffTemplate: boolean;
}

interface SiteTemplateValues {
  GTV: string;
  CTV: string[];
  PTV: string[];
}

interface SiteTemplateState {
  [key: number]: SiteTemplateValues;
}

const SiteTargetValues = (props: Props): JSX.Element => {
  const { setError } = useErrorModalContext();
  const theme = useTheme();
  const match = useRouteMatch<ROPatientCarePlanRoute>();
  const { careplanId, siteGroupIdx } = match.params;
  const [state, setState] = useState<SiteTargetValuesState[]>([]);
  const [templateState, setTemplateState] = useState<SiteTemplateState>();
  const [siteGroupId, setSiteGroupId] = useState(-1);

  /* This jank is used to force a refresh after the state update
  Needed due to the component not automatically re-rendering when the data
  refreshes, which causes stale data to persist in the TV fields if the site group
  changes */
  const [idForRefresh, setIdForRefresh] = useState(0);
  const cpotTriggered = props?.cpotTriggered;
  const setVolumingTValues = (data: VolumingTVValues): void => {
    if (data) {
      const siteGroup = data.siteGroupsByCareplan[currentSiteGroupIndex || 0];
      setSiteGroupId(siteGroup.id);
      const siteSet = siteGroup.siteSet;
      const siteList = siteSet.map((site: Site): SiteTargetValuesState => {
        const location = site.sitevaluesSet.reduce(
          (loc: string, value: SiteValue): string => (value.field.name === 'Location' ? JSON.parse(value.value) : loc),
          '',
        );
        return site.sitevaluesSet.reduce(
          (siteAttr: any, sitevalue: SiteValue): any => {
            const fieldName = sitevalue.field.name;
            if (['GTV', 'PTV', 'CTV'].includes(fieldName)) {
              siteAttr[fieldName] = JSON.parse(sitevalue.value);
              siteAttr[`${fieldName}Id`] = sitevalue.id;
            }
            if (fieldName === 'Dose') siteAttr[fieldName] = JSON.parse(sitevalue.value);
            return siteAttr;
          },
          {
            name: isSpecifySite(site.treatmentSite.treatmentSite)
              ? CleanSpecifyFromSiteName(`${site.treatmentSite.treatmentSite} (${location})`)
              : site.treatmentSite.treatmentSite,
            id: site.id,
            templateSiteId: site.template?.id,
            Dose: [],
            GTVId: '',
            CTVId: '',
            PTVId: '',
            GTV: '',
            CTV: [''],
            PTV: [''],
            PTVOffTemplate: false,
            CTVOffTemplate: false,
          },
        );
      });
      setState(siteList);
      setIdForRefresh(Math.random());
    }
  };
  const { loading, data, error, refetch } = useQuery(GET_CAREPLAN_SITE_GROUPS, {
    variables: { careplanId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: VolumingTVValues): void => {
      setVolumingTValues(data);
    },
  });

  // Trigger a refetch of data if the siteGroupIdx changes (switch to new voluming page)
  useEffect(() => {
    refetch();
  }, [siteGroupIdx, props.refresh]);

  useEffect(() => {
    if (error) return setError();
  }, [error]);

  let currentSiteGroupIndex: number = Number(siteGroupIdx || 0);
  if (data && siteGroupIdx === 'last') {
    currentSiteGroupIndex = data.siteGroupsByCareplan.length - 1;
  }
  useQuery(SITE_TEMPLATE_VALUES_IN_SITE_GROUP, {
    variables: { siteGroupId },
    skip: siteGroupId === -1,
    fetchPolicy: 'network-only',
    onCompleted: (data: SiteTemplate): void => {
      if (!data?.siteGroupTemplate) return;

      const newTemplateTargetValues = data.siteGroupTemplate.treatmentsiteprescriptiontemplateSet.reduce(
        (templateSite: any, site: TemplateSet): SiteTemplateState => {
          templateSite[site.id] = site.treatmentrulesiteSet.reduce(
            (volRules: any, rule: TreatmentRuleSite): SiteTemplateValues => {
              if (['CTV', 'GTV', 'PTV'].includes(rule.field.name)) {
                volRules[rule.field.name] = JSON.parse(rule.defaultValue);
              }
              return volRules;
            },
            {
              GTV: '',
              PTV: [],
              CTV: [],
            },
          );
          return templateSite;
        },
        {},
      );
      setTemplateState(newTemplateTargetValues);
    },
  });
  const [updateSiteVoluming] = useMutation(UPDATE_SITE_VALUE, {
    refetchQueries: [
      {
        query: SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION,
        variables: {
          siteGroupId: data?.siteGroupsByCareplan[currentSiteGroupIndex]?.id || '',
          volumingPage: true,
        },
      },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      {
        query: SITE_GROUP_TEMPLATE_VALUE_VARIATION,
        variables: {
          siteGroupId: data?.siteGroupsByCareplan[currentSiteGroupIndex]?.id || '',
        },
      },
    ],
  });

  if (loading) return <div className="loading-error-page-settings">Loading....</div>;
  if (!data) return <div className="loading-error-page-settings">Error loading data...</div>;
  const doseNames = [
    [''],
    ['High Dose', 'Low Dose'],
    ['High Dose', 'Intermediate Dose', 'Low Dose'],
    ['High Dose', 'Intermediate 1 Dose', 'Intermediate 2 Dose', 'Low Dose'],
  ];

  return (
    <div key={`siteTargetValue-${idForRefresh}`}>
      {state.map((site: SiteTargetValuesState, siteIndex: number): JSX.Element => {
        const maxTvLength = site.Dose.length;
        return (
          <div key={`fragment-${siteIndex}`}>
            <Divider />
            <div className={classnames('volume-page-site-header')}>{site.name}</div>
            <TargetValueField
              index={0}
              prefix="G"
              initialValue={site?.GTV}
              templateValue={templateState?.[site.templateSiteId]?.GTV || ''}
              cpotTriggered={cpotTriggered}
              updateField={(value: string) => {
                const newState = [...state];
                newState[siteIndex].GTV = value;
                setState(newState);
                updateSiteVoluming({
                  variables: {
                    id: site.GTVId,
                    siteId: site.id,
                    fieldName: 'GTV',
                    value: JSON.stringify(removeUnicodeQuotes(value)),
                  },
                });
              }}
            />
            {site.Dose.map((_: number, tvIndex: number): JSX.Element => {
              const label = doseNames[maxTvLength - 1][tvIndex];
              const templateCtv = templateState?.[site.templateSiteId]?.CTV[tvIndex] || '';
              const templatePtv = templateState?.[site.templateSiteId]?.PTV[tvIndex] || '';
              return (
                <Stack>
                  {label && (
                    <Typography
                      variant="subtitle2"
                      sx={{ color: theme.palette.secondary.dark, textTransform: 'uppercase', fontWeight: 'bold' }}>
                      {label}
                    </Typography>
                  )}
                  <TargetValueField
                    index={tvIndex}
                    prefix="C"
                    initialValue={site.CTV?.[tvIndex] || ''}
                    templateValue={templateCtv}
                    cpotTriggered={cpotTriggered}
                    updateField={(value: string) => {
                      // Put the value into the array
                      const ctvArray = [...state[siteIndex].CTV];
                      ctvArray[tvIndex] = removeUnicodeQuotes(value);
                      const CTVOffTemplate = !cpotTriggered && !!templateCtv && templateCtv !== value;
                      // Set the state with the new array and the Off Template value
                      const newState = [...state];
                      newState[siteIndex].CTV = ctvArray;
                      newState[siteIndex].CTVOffTemplate = CTVOffTemplate;
                      setState(newState);
                      updateSiteVoluming({
                        variables: {
                          id: site.CTVId,
                          siteId: site.id,
                          fieldName: 'CTV',
                          value: JSON.stringify(ctvArray),
                        },
                      });
                    }}
                  />
                  <TargetValueField
                    index={tvIndex}
                    prefix="P"
                    initialValue={site.PTV?.[tvIndex] || ''}
                    templateValue={templatePtv}
                    cpotTriggered={cpotTriggered}
                    updateField={(value: string) => {
                      // Put the value into the array
                      const ptvArray = [...state[siteIndex].PTV];
                      ptvArray[tvIndex] = removeUnicodeQuotes(value);
                      const PTVOffTemplate = !cpotTriggered && !!templatePtv && templatePtv !== value;
                      // Set the state with the new array and the Off Template value
                      const newState = [...state];
                      newState[siteIndex].PTV = ptvArray;
                      newState[siteIndex].PTVOffTemplate = PTVOffTemplate;
                      setState(newState);
                      updateSiteVoluming({
                        variables: {
                          id: site.PTVId,
                          siteId: site.id,
                          fieldName: 'PTV',
                          value: JSON.stringify(ptvArray),
                        },
                      });
                    }}
                  />
                </Stack>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default SiteTargetValues;
