import React, { Fragment, useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import { isDemo } from 'op-utils';

import { useErrorModalContext } from 'op-contexts';
import UserContext, { UserContextType } from 'op-contexts/UserContext/UserContext';
import { ROPatientContext } from 'op-pages/RO/PatientSummary/context';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';

import { GET_PATIENT_PROFILE } from './PatientCardQueries';
import PSOPatientCard from './PSOPatientCard';
import ROPatientCard from './ROPatientCard';
import { Props, SimpleCardProps, DetailedCardProps, PatientProfileQueryResultData } from './types';
import SimplifiedPatientCard from './SimplifiedPatientCard';
import { calculateMomentAge } from 'shared-components/utils';
import { PAT_ID_1_REFRESH_TIME } from 'shared-components/enums';
import { genderTitle, patientGenderStockImage } from 'op-utils/PatientHelper';
import useInterval from 'shared-components/utils/useInterval';
import { isUs } from 'op-utils';
import { useTheme } from '@mui/material';

const DEFAULT_PROFILE = {
  firstName: '',
  middleName: '',
  lastName: '',
  fullName: '',
  ida: '',
  gpFullName: '',
  dob: '',
  patientGender: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    postcode: '',
    formattedAddress: '',
    ukFormattedAddress: '',
  },
  allergy: [],
  carepathPatientDiagnosis: [],
  healthMedicareNumber: '',
  healthMedicareIrn: '',
  medicareExpiryRawYear: '',
  medicareExpiryRawMonth: '',
  secondaryPhone: '',
  primaryPhone: '',
  photoUrl: '',
  patientState: '',
  pxOptedIn: '',
  horizonCenterId: '',
  email: '',
  pxOptOutComment: '',
  tags: [],
  emrPatientId: '',
  emrInstance: '',
  userProfile: {
    hasPatId1: true,
  },
};

const roleComponentMap = {
  ro: ROPatientCard,
  nurse: PSOPatientCard,
  roSimplified: SimplifiedPatientCard,
  pso: PSOPatientCard,
};

const renderDetailedCard = (props: DetailedCardProps) => {
  const { patientCardView } = props;
  if (props.primaryRole) {
    // @ts-ignore
    const Card = roleComponentMap[patientCardView ? patientCardView : props.primaryRole];
    return <Card {...props} />;
  }
  return <Fragment />;
};

const SimpleCard = ({ attributes }: SimpleCardProps): JSX.Element => {
  const address = attributes?.address?.ukFormattedAddress || '-';
  const theme = useTheme();

  return (
    <div className="patient-card-wrapper patient-card-wrapper--simple">
      <div className="patient-avatar-wrapper">
        <div className="photo-widget simple">
          <PhotoWidget url={attributes.photoUrl} enableOverlay={true} size={'sm'} />
        </div>
        <div style={{ color: theme.palette.primary.dark }} className="patient-name-block">
          {attributes.fullName}
        </div>
      </div>
      <div className="patient-id-wrapper simple">
        <div className="header-text">Patient ID</div>
        <div className="body-text">{attributes.ida}</div>
      </div>
      <div className="patient-dob-wrapper simple">
        <div className="header-text">Date of birth</div>
        <div className="body-text">{attributes.fullAge}</div>
      </div>
      <div className="patient-address-wrapper simple">
        <div className="header-text">Address</div>
        <div className="body-text">{address}</div>
      </div>
    </div>
  );
};

const PatientCard = (props: Props): JSX.Element => {
  const { id, simple = true, patientCardView } = props;
  const { setPatientDetails } = useContext(ROPatientContext);
  const { setError } = useErrorModalContext();
  const { state } = useContext<UserContextType>(UserContext);

  const {
    data: patientProfileData,
    loading: patientProfileLoading,
    error: patientProfileError,
    refetch,
  } = useQuery(GET_PATIENT_PROFILE, {
    variables: { patientId: id },
    onCompleted: (data: PatientProfileQueryResultData) => {
      setPatientDetails(data?.patient);
    },
  });

  // Refresh profile every few seconds until patient has pat_id_1
  useInterval(
    () => {
      refetch();
    },
    patientProfileData?.patient?.userProfile?.hasPatId1 ? null : PAT_ID_1_REFRESH_TIME,
  );

  useEffect(() => {
    if (patientProfileError) {
      return setError();
    }
  }, [patientProfileError]);

  if (!id) return <Fragment />;

  const sharedProps = {
    id,
    roPatient: patientProfileData?.patient || DEFAULT_PROFILE,
  };

  const formattedDateOfBirth = sharedProps.roPatient.dob
    ? moment(sharedProps.roPatient.dob).format(`${isUs() ? 'MMM Do' : 'Do MMM'} YYYY`)
    : '-';
  const calculatedAge = sharedProps.roPatient.dob ? calculateMomentAge(moment(sharedProps.roPatient.dob)) : '-';

  const attributes: any = {};
  attributes.dob = sharedProps.roPatient.dob;
  attributes.fullAge =
    formattedDateOfBirth !== '-' ? `${formattedDateOfBirth} (Age ${calculatedAge})` : formattedDateOfBirth;
  attributes.address = sharedProps.roPatient.address;

  const concatName = [
    sharedProps.roPatient.firstName,
    sharedProps.roPatient.middleName,
    sharedProps.roPatient.lastName,
    genderTitle(sharedProps.roPatient.patientGender),
  ]
    .filter(Boolean)
    .map((item: any) => item.trim())
    .filter(Boolean)
    .join(' ');

  attributes.fullName = concatName !== '' ? concatName : 'Name not provided';
  attributes.ida =
    sharedProps.roPatient.ida && sharedProps.roPatient.ida.replace(/ /g, '') !== '' ? sharedProps.roPatient.ida : '-';
  attributes.allergy = sharedProps.roPatient.allergy;
  attributes.carepathPatientDiagnosis = sharedProps.roPatient.carepathPatientDiagnosis.filter(
    (item: any) => item.medId !== null,
  );
  attributes.photoUrl = isDemo
    ? patientGenderStockImage(sharedProps.roPatient.patientGender)
    : sharedProps.roPatient.photoUrl;
  attributes.patientId = id;
  attributes.mobile = sharedProps.roPatient.primaryPhone || '-';
  attributes.landline = sharedProps.roPatient.secondaryPhone || '-';
  attributes.pxOptedIn = sharedProps.roPatient.pxOptedIn;
  attributes.horizonCenterId = sharedProps.roPatient.horizonCenterId;
  attributes.tags = sharedProps.roPatient.tags;
  attributes.selectedPatientOptIn = {
    id: id,
    firstName: sharedProps.roPatient.firstName,
    lastName: sharedProps.roPatient.lastName,
    pxOptedIn: sharedProps.roPatient.pxOptedIn,
    primaryPhone: sharedProps.roPatient.primaryPhone,
    email: sharedProps.roPatient.email,
    pxOptOutComment: sharedProps.roPatient.pxOptOutComment,
    emrInstance: sharedProps.roPatient.emrInstance,
  };
  attributes.emrPatientId = sharedProps.roPatient.emrPatientId;
  attributes.emrInstance = sharedProps.roPatient.emrInstance;
  attributes.hasPatId1 = sharedProps.roPatient.userProfile.hasPatId1;

  const detailedCardProps = {
    id: props.id,
    moreDetailsClickHandler: props.moreDetailsClickHandler,
    showMoreDetails: props.showMoreDetails,
    primaryRole: state.primaryRole,
    attributes: attributes,
    loading: patientProfileLoading,
    patientCardView: patientCardView,
  };

  return simple ? <SimpleCard attributes={attributes} /> : renderDetailedCard(detailedCardProps);
};

export default PatientCard;
