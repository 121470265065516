// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'gc-ui';
import { ButtonType } from 'shared-components/enums';
import { GCButton } from 'shared-components/components/FormFields';
import { GCButtonSize } from 'shared-components/enums';

import './WelcomeBackModal.scss';
import Typography from '@mui/material/Typography';

interface Props {
  isOpen: boolean;
  dismissFunction: () => void;
  returnToStart: () => void;
  updateFirstReturn: (arg0: boolean) => void;
  children?: React.ReactNode;
}

class WelcomeBackModal extends Component<Props> {
  public render(): JSX.Element {
    const { isOpen, dismissFunction, returnToStart, updateFirstReturn } = this.props;
    return (
      <Modal open={isOpen} onClose={dismissFunction} className="modal-gc save-exit-modal" centered={true}>
        <ModalHeader toggle={dismissFunction}>
          <Typography variant="h5">Welcome Back!</Typography>
        </ModalHeader>
        <ModalBody>
          <div className="modal-save-exit-body">
            Would you like to continue from your last change or start from the beginning of this form?
            <br />
            Any information you previously entered will remain saved.
          </div>
          <div
            className="modal-save-exit-buttons"
            style={{ display: 'flex', flexDirection: 'row', gap: '8px', paddingTop: '16px' }}>
            <GCButton
              name="returnToStart"
              title="Go back to start"
              onClick={(e): void => {
                e.preventDefault();
                dismissFunction();
                returnToStart();
              }}
              buttonSize={GCButtonSize.REGULAR}
              type={ButtonType.WHITE}
            />
            <GCButton
              name="continueFromHere"
              title={'Continue from here'}
              onClick={(e): void => {
                e.preventDefault();
                updateFirstReturn(false);
                dismissFunction();
              }}
              buttonSize={GCButtonSize.REGULAR}
              type={ButtonType.GREEN}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default WelcomeBackModal;
