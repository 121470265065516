// eslint-disable-next-line no-use-before-define
import lodash from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Pagination } from 'shared-components/components';
import { DropDownField, BaseDatePicker } from 'shared-components/components/FormFields';
import dayjs from 'dayjs';
import './UKAppointmentsDashboard.scss';
import {
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableHead,
  TableRow as MuiTableRow,
  TableCell,
  Grid,
  Typography,
  styled,
  tableCellClasses,
  TableContainer,
  Paper,
} from '@mui/material';
import { theme } from 'theme';

const CENTRE_HEADER_PLACEHOLDER = 'Select Centre';
const STATUS_HEADER_PLACEHOLDER = 'Select Status';

interface State {
  active?: boolean;
  isFilterOpen: boolean;
}

interface Props {
  currentPage: number;
  status: string;
  startTime: any;
  locationAlias: string;
  totalPages: number;
  onPageChange: any;
  onDateChange: any;
  onStatusChange: any;
  onCentreChange: any;
  data: any;
}

interface RowProps {
  key: number;
  patientName: string;
  appointmentTime: string;
  locationAlias: string;
  patientID: string;
  regoFormStatus: string;
  description: string;
  formLink: string;
  statusTypes: any;
  practitioner: any;
}

interface RORefData {
  id: string;
  alias?: string;
  appKey?: string;
  name: string;
}
interface ListData {
  id: string;
  name: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(MuiTableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class TableRow extends Component<RowProps> {
  public render(): JSX.Element {
    const {
      patientName,
      appointmentTime,
      locationAlias,
      patientID,
      description,
      regoFormStatus,
      formLink,
      statusTypes,
      practitioner,
    } = this.props;
    // @ts-ignore

    let statusType = statusTypes.find((statusObj) => statusObj.id === regoFormStatus);
    statusType = typeof statusType === 'undefined' ? 'Unknown' : statusType?.name;

    return (
      <StyledTableRow id="table-header">
        <TableCell>{appointmentTime}</TableCell>
        <TableCell>{patientID}</TableCell>
        <TableCell>{patientName}</TableCell>
        <TableCell>{locationAlias}</TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>{practitioner?.name}</TableCell>
        <TableCell>{statusType}</TableCell>
        <TableCell>
          <a target="_blank" href={`/registration/${formLink}/basic`}>
            <span className="header-form-link">View form</span>
          </a>
        </TableCell>
      </StyledTableRow>
    );
  }
}

class UKAppointmentsDashboard extends Component<Props, State> {
  private readonly selectCentreRef: React.RefObject<HTMLSelectElement>;
  private readonly selectStatusRef: React.RefObject<HTMLSelectElement>;
  public constructor(props: Props) {
    super(props);
    this.selectCentreRef = React.createRef();
    this.selectStatusRef = React.createRef();
    this.state = {
      isFilterOpen: true,
    };
  }

  private changePage = (pageNumber: number) => {
    this.props.onPageChange(pageNumber);
  };

  private changeStatus = (status: string, currentPage: number) => {
    this.props.onStatusChange(status, currentPage);
  };

  private changeCentre = (centre: string, currentPage: number) => {
    this.props.onCentreChange(centre, currentPage);
  };

  private formatRefData = (refData: RORefData[]): ListData[] => {
    return refData.map((value) => {
      const valueClone = lodash.cloneDeep(value);
      valueClone.id = valueClone.alias || valueClone.appKey || valueClone.name;
      return valueClone;
    });
  };

  public render(): JSX.Element {
    //Populate the Centre dropdown
    let centreHeaders: JSX.Element;
    if (this.props.data && this.props.data.configs) {
      const locObj: any = {};
      const configData = this.formatRefData(this.props.data.configs);
      configData.map((loc: any) => {
        locObj[loc.id] = loc.name.split(',').pop();
      });
      centreHeaders = (
        <div className="flex-container">
          <DropDownField
            innerRef={this.selectCentreRef}
            inputName="Centre"
            placeholder={CENTRE_HEADER_PLACEHOLDER}
            defaultValue={this.props.locationAlias}
            options={configData}
            onChange={(e): void => {
              this.changeCentre(e.target.value, 1);
            }}
          />
          <span
            className="clear-filter"
            onClick={(): void => {
              this.changeCentre('', 1);
              // @ts-ignore
              this.selectCentreRef.current.selectedIndex = 0;
            }}>
            Clear
          </span>
        </div>
      );
    } else {
      centreHeaders = <div></div>;
    }

    //Populate the Status dropdown
    let statusHeaders: JSX.Element;
    if (this.props.data && this.props.data.listData) {
      const formattedListData = this.formatRefData(this.props.data.listData);
      statusHeaders = (
        <div className="flex-container">
          <DropDownField
            innerRef={this.selectStatusRef}
            inputName="Status"
            placeholder={STATUS_HEADER_PLACEHOLDER}
            defaultValue={this.props.status}
            options={formattedListData}
            onChange={(e): void => {
              this.changeStatus(e.target.value, 1);
            }}
          />
          <span
            className="clear-filter"
            onClick={(): void => {
              this.changeStatus('', 1);
              // @ts-ignore
              this.selectStatusRef.current.selectedIndex = 0;
            }}>
            Clear
          </span>
        </div>
      );
    } else {
      statusHeaders = <div></div>;
    }

    //populate the table rows
    let tableRows: JSX.Element;
    let statusTypes: any;
    if (this.props.data && this.props.data.listData) {
      statusTypes = this.formatRefData(this.props.data.listData);
    }
    if (this.props.data && this.props.data.ukAppointments) {
      if (this.props.data.ukAppointments.length === 0) {
        tableRows = <div className="no-appointments-wrapper">No appointments for today</div>;
      } else {
        tableRows = this.props.data.ukAppointments.map((appointment: any, key: number): JSX.Element => {
          return (
            <TableRow
              key={key}
              patientName={appointment.patient.fullName}
              appointmentTime={moment(appointment.startTime).format('HH:mm')}
              locationAlias={appointment.department.name}
              patientID={appointment.patient.ida}
              formLink={appointment.patient.id}
              description={appointment.description}
              regoFormStatus={appointment.patient.regFormStatus}
              statusTypes={statusTypes}
              practitioner={appointment.practitioner}
            />
          );
        });
      }
    } else {
      tableRows = <div />;
    }

    //populate all pagination items
    let paginationWrapperElem: JSX.Element;
    if (this.props.data && this.props.data.ukAppointmentsCount) {
      paginationWrapperElem = (
        <Stack justifyContent="center">
          <Pagination
            currentPage={this.props.currentPage}
            totalPages={this.props.data.ukAppointmentsCount}
            onPageChange={this.changePage}></Pagination>
        </Stack>
      );
    } else {
      paginationWrapperElem = <div />;
    }

    //populate the datepicker
    const dateSelector: JSX.Element = (
      <BaseDatePicker
        id="startDateTime"
        value={dayjs(this.props.startTime)}
        onChange={(e): void => {
          // @ts-ignore
          this.props.onDateChange(moment(e).format('YYYY-MM-DD'), 1);
        }}
        // @ts-ignore
        format="ddd, MMM Do YYYY"
      />
    );

    return (
      <>
        <Grid container sx={{ padding: '24px 16px' }} gap={'16px'}>
          <Grid item xs={12}>
            <Accordion
              defaultExpanded
              onChange={(_event, expanded) => {
                this.setState({ isFilterOpen: expanded });
              }}
              sx={{
                border: `1px solid ${theme.palette.grey[300]}`,
                borderTop: `2px solid ${theme.palette.primary.main}`,
              }}>
              <AccordionSummary
                sx={{
                  backgroundColor: theme.palette.grey[200],
                  minHeight: '40px !important',
                  height: '40px !important',
                }}>
                <Stack direction="row" justifyContent="space-between" width={'100%'}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    Filters
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    (click to {this.state.isFilterOpen ? 'close' : 'open'})
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid-container">
                  <div className="grid-item">
                    <span className="header-span">Select date</span>
                  </div>
                  <div className="grid-item">
                    <span className="header-span">Select Centre</span>
                  </div>
                  <div className="grid-item">
                    <span className="header-span">Select Status</span>
                  </div>
                  <div className="grid-item">
                    <div className="header-with-filter">{dateSelector}</div>
                  </div>
                  <div className="grid-item">
                    <div className="header-with-filter">
                      <div className="header-filter">{centreHeaders}</div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="header-with-filter">
                      <div className="header-filter">{statusHeaders}</div>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: `calc(100vh - ${this.state.isFilterOpen ? 400 : 200}px)` }}>
              <Table stickyHeader>
                <TableHead>
                  <MuiTableRow>
                    <StyledTableCell>Appointment Time</StyledTableCell>
                    <StyledTableCell>Patient ID</StyledTableCell>
                    <StyledTableCell>Patient Name</StyledTableCell>
                    <StyledTableCell>Centre</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Practitioner</StyledTableCell>
                    <StyledTableCell>Submission Status</StyledTableCell>
                    <StyledTableCell>Form link</StyledTableCell>
                  </MuiTableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <footer className="footer">{paginationWrapperElem}</footer>
      </>
    );
  }
}

export default UKAppointmentsDashboard;
