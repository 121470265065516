// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Query } from '@apollo/client/react/components';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { gql } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import './Signup.scss';
import { Logger } from 'shared-components/utils';
import { ButtonType } from 'shared-components/enums';

import { Form, FormContent, FormSection, SectionField, GCButton } from 'shared-components/components/FormComponents';
import { FreeTextField } from 'shared-components/components/FormFields';

import LoginFormViewModel from './LoginFormViewModel';
import { DEFAULT_DASHBOARD } from 'op-px-pages/Login/Login';
import { LoadingSpinner } from 'shared-components/components';
import axios from 'axios';
import Cookies from 'js-cookie';

const API_ENDPOINT_SIGNUP_PATH = '/server/patient/signup_account';

/**
 * Interfaces
 */

export const PATIENT_QUERY = gql`
  {
    pxPatientBasic {
      id
      username
      mobileLocalised
      lastThreeMobile
      email
    }
  }
`;

export const SEND_PX_EMAIL_REMINDER = gql`
  mutation SendPXSignupReminder {
    sendPxSignupReminder {
      success
      inviteLink
    }
  }
`;

const logger = new Logger('PatientSearch');

export interface PatientQuerySignup {
  pxPatientBasic: {
    id: string;
    username: string;
    lastThreeMobile: string;
    mobileLocalised: string;
    email: string;
  };
}

export interface GraphUser {
  id: string;
  username: string;
  lastThreeMobile: string;
}

interface RedirectionParams {
  pathname: string;
  state?: {
    next?: string | null;
    mfaToken?: string;
    user: GraphUser;
    resetPassword?: boolean;
  };
}

export interface SendPXSignupReminderData {
  data: { sendPxSignupReminder: { success: boolean; inviteLink: string } };
}

interface State {
  loading: boolean;
  sentReminder: boolean;
}

class Login extends Component<RouteComponentProps, State> {
  public loginFormViewModel: LoginFormViewModel;

  public constructor(props: RouteComponentProps) {
    super(props);
    this.loginFormViewModel = new LoginFormViewModel();

    this.state = {
      loading: false,
      sentReminder: false,
    };
  }
  private getCSRFCookie(): string {
    const csrfToken = Cookies.get('csrftoken');
    if (csrfToken) {
      return csrfToken;
    }
    return 'invalid_token';
  }
  private submit = (userID: string, lastThreeMobile: string, username: string): void => {
    this.setState({ loading: true, sentReminder: true });

    const loginUser: GraphUser = {
      id: userID,
      lastThreeMobile: lastThreeMobile,
      username: username,
    };

    axios
      .post(API_ENDPOINT_SIGNUP_PATH)
      .then((response): void => {
        const { redirectTo, token } = response.data;
        if (redirectTo === '/px/mfa') {
          const mfaToken = token ? token : '';
          const redirectionParams: RedirectionParams = {
            pathname: '/px/mfa/',
            state: { next: DEFAULT_DASHBOARD, mfaToken: mfaToken, user: loginUser },
          };
          this.props.history.push(redirectionParams);
        } else {
          this.props.history.push(redirectTo);
        }
      })
      .catch((_error): void => {
        this.setState({ loading: false, sentReminder: false });
      });
  };

  private loginSuccess = (userId: string, username: string): void => {
    {
      const scope = Sentry.getCurrentScope();
      scope.setTag('user_type', 'patientPortal');
      scope.setUser({
        id: userId,
        username: username,
      });
    }
  };

  private handleSendPXSignupReminder = (): void => {
    const { sentReminder } = this.state;
    if (!sentReminder) {
      this.setState({ sentReminder: true });
      //@ts-ignore
      this.props.client
        .mutate({
          mutation: SEND_PX_EMAIL_REMINDER,
        })
        //@ts-ignore
        .then((result: SendPXSignupReminderData): void => {
          logger.info('Invite link is:', result.data.sendPxSignupReminder.inviteLink);
          this.props.history.replace('/px/home');
        });
    }
  };

  /**
   * Set the sent reminder state and callback a function if passed in
   * Callback needed if navigating to differnt urls to not send reminder to ensure state is updated
   * @param sentReminder
   * @param callback
   */
  private setSentReminder = (sentReminder: boolean, callback?: () => void): void => {
    this.setState({ sentReminder: sentReminder }, (): void => {
      if (callback) {
        callback();
      }
    });
  };

  public render(): JSX.Element {
    return (
      <Query<PatientQuerySignup> query={PATIENT_QUERY}>
        {({ loading, error, data }): JSX.Element => {
          if (loading || this.state.loading) {
            return <LoadingSpinner relativeSpinner={true} />;
          }

          if (error || !data) {
            this.props.history.replace('/login');
            return <div>Error...</div>;
          }

          // Close tab event/timeout will send Signup email to user

          if (data && data.pxPatientBasic) {
            const { mobileLocalised, email, username, lastThreeMobile, id } = data.pxPatientBasic;
            this.loginSuccess(id, username);
            window.addEventListener('beforeunload', (e: any): void => {
              e.preventDefault();
              this.handleSendPXSignupReminder();
            });
            return (
              <div className={'signup-page'}>
                <div className="auth-container login-page">
                  <div className={'header-container'}>
                    <div className="gc-logo"></div>
                    <div className="signup-subheader">Thank you for registering</div>
                    <div className="signup-header">Sign up to your Patient Portal now!</div>
                  </div>
                  <div className="auth-container-inner login-page">
                    <Form
                      id="patient-login-form"
                      formData={this.loginFormViewModel.loginForm}
                      submit={(): void => this.submit(id, lastThreeMobile, username)}>
                      <FormContent>
                        <FormSection>
                          <SectionField htmlFor="mobile" title="Mobile" invalidInput={false}>
                            <FreeTextField
                              inputName="mobile"
                              defaultValue={mobileLocalised}
                              placeholder=""
                              disabled={true}
                            />
                          </SectionField>
                          <SectionField htmlFor="email" title="Email" invalidInput={false}>
                            <FreeTextField inputName="email" defaultValue={email} placeholder="" disabled={true} />
                          </SectionField>
                          {/* If there is an error take the auth error and render it */}
                          <div className="flex-horizontal-center">
                            <GCButton
                              onClick={(e): void => {
                                if (e) {
                                  e.preventDefault();
                                }
                                this.setState({ sentReminder: true }, (): void => {
                                  this.submit(id, lastThreeMobile, username);
                                });
                              }}
                              name="login"
                              type={ButtonType.GREEN}
                              inputType="submit"
                              loading={loading}
                              title="Sign up"
                            />
                          </div>
                        </FormSection>
                      </FormContent>
                    </Form>
                    <div className={'signup-do-it-later'}>
                      <div
                        className="do-it-later-text"
                        onClick={(): void => {
                          this.handleSendPXSignupReminder();
                        }}>
                        I'll do it later
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'info-container'}>
                  <div className={'left-align-tile'}>
                    <div className={'info-tile'}>
                      <div className={'info-tile-image signup-all-devices-image'}></div>
                      <div className={'info-tile-body'}>
                        <div id="device-header" className={'info-tile-header'}>
                          On all devices
                        </div>
                        <div id="device-text" className={'info-tile-text'}>
                          View on your mobile, tablet or desktop.
                        </div>
                      </div>
                    </div>
                    <div className={'info-tile'}>
                      <div className={'info-tile-image signup-appointments-image'}></div>
                      <div className={'info-tile-body'}>
                        <div id="appt-header" className={'info-tile-header'}>
                          Appointments
                        </div>
                        <div id="appt-text" className={'info-tile-text'}>
                          Be up-to-date with your schedule and plan ahead.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'right-align-tile'}>
                    <div className={'info-tile '}>
                      <div className={'info-tile-image signup-safe-secure-image'}></div>
                      <div className={'info-tile-body'}>
                        <div id="safe-header" className={'info-tile-header'}>
                          Safe & Secure
                        </div>
                        <div id="safe-text" className={'info-tile-text'}>
                          Your personal data is secure with us.
                        </div>
                      </div>
                    </div>
                    <div className={'info-tile'}>
                      <div className={'info-tile-image signup-information-image'}></div>
                      <div className={'info-tile-body'}>
                        <div id="info-header" className={'info-tile-header'}>
                          Information
                        </div>
                        <div id="info-text" className={'info-tile-text'}>
                          Be better informed on what's important to you.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="security-text">
                  If you choose to skip sign up now, GenesisCare will send you a reminder email with a link to sign up
                  to Patient Portal when you're ready.
                </div>
                <div className="security-text">
                  If your mobile or email address are incorrect please contact your clinic.
                </div>
              </div>
            );
          }

          return <div>Error</div>;
        }}
      </Query>
    );
  }
}

//@ts-ignore
// Wrap with apollo for cache access
const apolloLogin = withApollo(Login);
// Export component with router
//@ts-ignore
export default withRouter(apolloLogin);
