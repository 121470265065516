import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik';
import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { GCButton } from 'shared-components/components/FormFields';
import { ButtonType } from 'shared-components/enums';
import { ErrorCross, Success } from 'shared-components/images';
import './FeedbackContent.scss';
import { SUBMIT_FEEDBACK_MUTATION } from './FeedbackQueries';
import { generateValidationSchema } from './validation';
import { Stack } from '@mui/material';

interface IFeedbackContentFormikValues {
  feedbackMsg: string;
}

const FeedbackContent = (): JSX.Element => {
  const componentId = 'feedback-content';

  const [sendFeedback, { data: sendFeedbackData, loading: sendFeedbackLoading }] =
    useMutation(SUBMIT_FEEDBACK_MUTATION);
  const isSubmitted = useMemo<boolean>(() => !!sendFeedbackData?.submitPatientFeedback?.success, [sendFeedbackData]);

  return (
    <Stack
      id={`${componentId}-inner-container`}
      className="p-3 text-center"
      sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {!isSubmitted ? (
        <>
          <h2 className={classNames(`${componentId}-header`, 'py-4 px-2')} role="title" style={{ fontSize: '1.5rem' }}>
            How can we improve the Patient Portal to make your experience better?
          </h2>
          <Formik
            initialValues={{ feedbackMsg: '' }}
            validate={(values: FormikValues) => generateValidationSchema(values)}
            validateOnChange
            validateOnBlur
            onSubmit={(values: IFeedbackContentFormikValues) => {
              sendFeedback({
                variables: {
                  comments: values.feedbackMsg,
                  previousUrl: '/px/feedback', // TODO
                },
              });
            }}>
            <Form style={{ width: '80%', maxWidth: '1100px' }}>
              <div>
                <Field data-test-id={`${componentId}-textarea`} name="feedbackMsg">
                  {({ field, meta }: Partial<FieldProps>) => (
                    <>
                      <textarea
                        rows={6}
                        style={{ padding: '8px' }}
                        className={classNames({ 'validation-error': meta?.error })}
                        disabled={sendFeedbackLoading}
                        placeholder={
                          'Please outline your issues and/or suggestions so we can support you. For emergency or clinical concerns, please contact your clinic directly.' ??
                          ''
                        }
                        {...field}
                      />
                      {meta?.touched && meta?.error && (
                        <div style={{ padding: '8px' }}>
                          <ErrorCross className="icon" />
                          <span className="error-message">{meta?.error}</span>
                        </div>
                      )}
                    </>
                  )}
                </Field>

                <div className="p-2">
                  <GCButton
                    inputType="submit"
                    data-testid="Submit"
                    disabled={sendFeedbackLoading}
                    title="Submit"
                    type={ButtonType.GREEN}
                    name="submitFeedback"
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </>
      ) : (
        <>
          <h2 className={classNames(`${componentId}-header`, 'py-4 px-2')}>Thank you for your submission.</h2>
          <Success className="icon submitted-success-icon" />
          <Link
            to="/px/appointments" // TODO
            className="p-4">
            <GCButton inputType="button" type={ButtonType.GREEN} name="feedback-go-home" title="Back to home page" />
          </Link>
        </>
      )}
    </Stack>
  );
};

export default memo(FeedbackContent);
