// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { styled } from '@mui/system';
import { generatePath, Link } from 'react-router-dom';
import { StyledArrow, StyledTitle } from 'op-components';
import { Stack } from '@mui/material';
import { GET_USER_PROFILE } from 'op-graphql/queries';
import {
  createPatientSteps,
  LoadingSpinner,
  OncologyStepper,
  MiddleContainerCol,
  RightContainerCol,
} from 'shared-components/components';
import CreatePatientBasicForm from '../../OP/CreatePatient/CreatePatientBasicForm';
import { useTheme } from '@mui/material';

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px 16px 16px;
  text-decoration: none;
  height: 63px;
  border-bottom: solid 1px ${(props) => props.theme.palette.secondary.main};
  transition: border-color 0.15s ease-in-out;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const CreatePatient = (): JSX.Element => {
  const [error, setError] = useState(false);
  const theme = useTheme();
  const { data: userProfile, loading: userLoading, error: userError } = useQuery(GET_USER_PROFILE);

  useEffect(() => {
    if (userError) return setError(true);
    if (userProfile && !userProfile.user.isMo) return setError(true);
  }, [userError, userProfile]);

  if (userLoading) return <LoadingSpinner />;
  if (error) return <Redirect to="/error" />;

  const BackToDashboard = (): JSX.Element => {
    const url = generatePath('/medonc');
    return (
      <StyledLink to={url} data-test-id="back-to-search-button">
        <StyledArrow />
        <StyledTitle>Back to dashboard</StyledTitle>
      </StyledLink>
    );
  };

  return (
    <Stack>
      <Stack sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <Stack
          style={{
            width: '240px',
            backgroundColor: theme.palette.secondary.light,
            borderRight: '1px solid rgba(0, 0, 0, 0.2)',
          }}>
          <OncologyStepper steps={createPatientSteps}>
            <BackToDashboard />
          </OncologyStepper>
        </Stack>

        <MiddleContainerCol>
          <CreatePatientBasicForm physiciansRefData={[{ id: userProfile.user.id, name: userProfile.user.name }]} />
        </MiddleContainerCol>
        <RightContainerCol />
      </Stack>
    </Stack>
  );
};

export default CreatePatient;
