import React from 'react';
import { MainSection, SubSection } from 'shared-components/components/UIFormComponents/Modal';
import { sharedContent } from '../constants';

interface Props {
  formName: string;
  submittedByNurse: string;
  isAmendment?: boolean;
}

const ModalContentSubmit = ({ isAmendment, formName, submittedByNurse }: Props): JSX.Element => {
  const {
    mainText1,
    mainText2,
    subText1,
    subText2,
    additionalText = '',
  } = isAmendment ? sharedContent.reviewAndSubmit.submitAmendment : sharedContent.reviewAndSubmit.submit;

  return (
    <>
      <MainSection>
        <div>{`${mainText1} ${formName} ${mainText2}`}</div>
        <b>{submittedByNurse}</b>
      </MainSection>
      <SubSection>{`${subText1} ${formName} ${subText2}`}</SubSection>
      {additionalText ? <SubSection>{additionalText}</SubSection> : ''}
    </>
  );
};

export default ModalContentSubmit;
