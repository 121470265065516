import React, { ReactElement } from 'react';
import moment from 'moment';
import { Stack, Tooltip, Typography } from '@mui/material';
import { PatientTooltip as IPatientTooltip } from 'op-interfaces';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import { useTheme } from '@mui/material';

interface Props {
  patient: IPatientTooltip;
  children: ReactElement;
}

const PatientTooltip = ({ patient, children }: Props): JSX.Element => {
  const theme = useTheme();
  return (
    <Tooltip
      enterDelay={700}
      enterNextDelay={700}
      componentsProps={{
        tooltip: { sx: { bgcolor: 'white', minWidth: '350px', border: `1px solid ${theme.palette.grey[300]}` } },
      }}
      arrow
      title={
        <React.Fragment>
          <Stack direction="row" style={{ fontSize: '14px', color: theme.palette.text.primary }} gap="8px">
            <PhotoWidget url={patient.photoUrl} enableOverlay={true} size={'reg'} />
            <Stack direction="column">
              <Typography variant="subtitle1" fontWeight={700}>
                {patient.fullName}
              </Typography>
              <Typography variant="subtitle2" color={theme.palette.secondary.dark} sx={{ paddingBottom: '8px' }}>
                Patient ID: {patient.id}
              </Typography>
              <Typography variant="subtitle2" sx={{ paddingTop: '8px' }}>
                Date of birth
              </Typography>
              <b>
                {moment(patient.dob).format('D MMM YYYY')} (age {patient.age})
              </b>
              <Typography variant="subtitle2" sx={{ paddingTop: '8px' }}>
                Address
              </Typography>
              <b>{patient.address?.formattedAddress}</b>
            </Stack>
          </Stack>
        </React.Fragment>
      }>
      {children}
    </Tooltip>
  );
};

export default PatientTooltip;
