// eslint-disable-next-line no-use-before-define
import React from 'react';
import { styled } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material';
interface Step {
  idx: number;
  text: string;
  page: any;
}

interface StepObject {
  key: string;
  header: string;
  short?: string;
  steps: Step[];
}

interface Props {
  steps: StepObject[];
  children?: React.ReactNode;
  showTopDivider?: boolean;
}

const StyledStepContainer = styled('div')`
  min-width: 239px;
`;
StyledStepContainer.displayName = 'stepContainer';

const StyledStepHeader = styled('div')`
  text-align: left;
  color: ${(props) => props.theme.palette.secondary.dark};
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  margin: 16px auto 8px auto;
  padding-left: 16px;
`;

const StyledBubble = styled('div')<any>`
  background: ${(props) => (props.current ? props.theme.palette.primary.main : 'white')};
  color: ${(props) => (props.current ? 'white' : props.theme.palette.secondary.dark)};
  border: 2px solid
    ${(props) => (props.current ? props.theme.palette.primary.light : props.theme.palette.secondary.main)};
  border-radius: 50%;
  font-size: 13px;
  font-weight: 700;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 21px;
  margin-right: -13px;
  z-index: 2;
`;

const WarningBubble = styled('div')<any>`
  background: ${(props) => props.theme.palette.warning.dark};
  color: white;
  border: 2px solid ${(props) => props.theme.palette.secondary.main};
  border-radius: 50%;
  font-size: 13px;
  font-weight: 700;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 21px;
  margin-right: -13px;
  z-index: 2;
`;

const StyledStepText = styled('div')<any>`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: black;
  min-height: 40px;
  padding: 10px 0;
  padding-left: 21px;
  border-left: ${(props) => (props.current ? '' : `2px solid ${props.theme.palette.secondary.main}`)};
  border-image: ${(props) => props.borderImage};
  border-image-slice: 1;
`;
StyledStepText.displayName = 'stepText';

const StyledStep = styled('div')<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  background: ${(props) => (props.current ? 'white' : props.theme.palette.secondary.light)};
  border-left: 3px solid ${(props) => (props.current ? props.theme.palette.primary.main : 'transparent')};
`;
StyledStep.displayName = 'step';

const OncologyStepper = (props: Props): JSX.Element => {
  const { steps, showTopDivider } = props;
  const theme = useTheme();

  const renderStepList = (stepsObj: Step[]): JSX.Element[] => {
    return stepsObj.map((step: Step, index: number): JSX.Element => {
      // If step page has multiple pages/subpages then can send array eg ['primary','metastasis']
      const current = Array.isArray(step.page)
        ? step.page.some((term) => window.location.pathname.includes(term))
        : window.location.pathname.includes(step.page);
      const firstStep = index === 0;
      const lastStep = index === stepsObj.length - 1;
      // Construct border image here

      const borderImage = () => {
        if (firstStep && lastStep) return 'linear-gradient(to bottom, transparent 50%, transparent 50%) 100% 1';
        if (firstStep) return `linear-gradient(to bottom, transparent 50%, ${theme.palette.secondary.main} 50%) 100% 1`;
        if (lastStep) return `linear-gradient(to bottom, ${theme.palette.secondary.main} 50%, transparent 50%) 100% 1`;
        return '';
      };

      return (
        <StyledStep
          className={current && 'current'}
          current={current}
          key={step.idx}
          data-testid={`nav-step-${step.idx + 1}`}>
          {step.idx === -1 ? (
            <WarningBubble>!</WarningBubble>
          ) : (
            <StyledBubble current={current}>{step.idx + 1}</StyledBubble>
          )}
          <StyledStepText data-testid={`nav-step-text-${step.page}`} current={current} borderImage={borderImage()}>
            {step.text}
          </StyledStepText>
        </StyledStep>
      );
    });
  };

  return (
    <div data-testid={'side-nav-stepper'}>
      {props?.children}
      {showTopDivider && <Divider />}
      {steps.map(
        (step: StepObject, index: number): JSX.Element => (
          <StyledStepContainer key={step.key}>
            <StyledStepHeader data-testid={`step-header-${index}`}>{step.header}</StyledStepHeader>
            <Accordion sx={{ boxShadow: 'none', '::before': { backgroundColor: theme.palette.secondary.light } }}>
              <AccordionDetails sx={{ padding: '0px' }}>
                {renderStepList(step.steps)}
                <Box sx={{ padding: '16px 0px', backgroundColor: theme.palette.secondary.light }}>
                  <Divider />
                </Box>
              </AccordionDetails>
            </Accordion>
          </StyledStepContainer>
        ),
      )}
    </div>
  );
};

export default OncologyStepper;
