import React from 'react';
import { FreeTextField } from 'shared-components/components/FormFields';
import { styled } from '@mui/system';
import { HADate } from '../HADate';

const StyledLabelDiv = styled('div')`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: ${(props) => props.theme.palette.secondary.dark};
  padding-bottom: 9px;
`;

const StyledTextfieldSubformDiv = styled((props: any) => <div {...props} />)`
  input {
    width: 100%;
  }
`;

const StyledDateSubformDiv = styled((props: any) => <div {...props} />)`
  .rodropdown-wrapper {
    padding: 2px;
    flex: 1;
  }
  .default-option-search__value-container {
    position: unset;
    height: 34px;
    align-content: center;
  }
`;

interface TextFieldProps {
  inputName: string;
  label?: string;
  placeholder?: string;
  inputType?: string;
  onChange: (value: string) => void;
  value: string;
}

interface DateFieldProps {
  label?: string;
  onChange: (key: string, value: string | number) => void;
  dayField: string;
  monthField: string;
  yearField: string;
  dayValue: string;
  monthValue: number;
  yearValue: string;
}

export const TextFieldSubform = (props: TextFieldProps): JSX.Element => {
  const { inputName, value, label, onChange, placeholder = 'Enter value here', inputType = 'text' } = props;
  return (
    <StyledTextfieldSubformDiv>
      {label && <StyledLabelDiv>{label}</StyledLabelDiv>}
      <FreeTextField
        inputName={inputName}
        dataTestId={`${inputName}-subform-text`}
        defaultValue={value}
        placeholder={placeholder}
        inputType={inputType}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => onChange(e.target.value)}
      />
    </StyledTextfieldSubformDiv>
  );
};

export const DateFieldSubform = (props: DateFieldProps): JSX.Element => {
  const { label } = props;

  return (
    <StyledDateSubformDiv>
      {label && <StyledLabelDiv>{label}</StyledLabelDiv>}
      <HADate {...props} />
    </StyledDateSubformDiv>
  );
};
