import React from 'react';
import { styled } from '@mui/system';
import { FieldProps, getIn } from 'formik';
import { ActionButton, HelperMessage, Select, UserIcon } from 'shared-components/components';

interface ContainerProps {
  fitToContent?: boolean;
  grow?: string;
}

const Container = styled('div')<ContainerProps>`
  display: flex;
  flex-grow: ${(props: ContainerProps): string => (props.grow ? props.grow : '1')};
  flex-direction: ${(props: ContainerProps): string => (props.fitToContent ? 'row' : 'column')};
`;

const SelectWrapper = styled('div')`
  align-items: center;
  display: flex;
`;

interface Props extends FieldProps {
  fitToContent?: boolean;
  options: any;
  placeholder: string;
  updateMutation: (value: string) => void;
  removable?: boolean;
  handleRemoveButton?: () => void;
  conditionalHandleChange?: (value: string) => void;
  errorMargin?: string;
  grow?: string;
  lastUpdateUser?: string;
  width?: string;
  borderRadius?: string;
  padding?: string;
  alternateStyle?: boolean;
}

const SelectFieldBase = ({
  field,
  fitToContent,
  form,
  options,
  placeholder,
  updateMutation,
  removable,
  handleRemoveButton,
  conditionalHandleChange,
  errorMargin,
  grow,
  lastUpdateUser,
  width,
  borderRadius,
  padding,
  alternateStyle,
}: Props): JSX.Element => {
  const { name, value, onChange, onBlur } = field;
  const { errors, touched, submitCount } = form;
  const fieldError = getIn(errors, name);
  const fieldTouched = getIn(touched, name);
  const fieldIsNotEmpty = value && value !== '';
  const isFieldInvalid = (submitCount > 0 || fieldIsNotEmpty || fieldTouched) && fieldError;
  const selectedObject = options.find((option: any) => option.value === value);

  const handleChange = async (event: React.ChangeEvent<any>): Promise<any> => {
    onChange(event);
    updateMutation(event.target.value);
    conditionalHandleChange && conditionalHandleChange(event.target.value);
  };

  return (
    <Container fitToContent={fitToContent} grow={grow}>
      <SelectWrapper>
        <Select
          name={name}
          width={width}
          value={selectedObject && selectedObject.value}
          invalid={isFieldInvalid}
          onChange={(e: any): Promise<any> => handleChange(e)}
          onBlur={onBlur}
          options={options}
          placeholder={placeholder}
          removable={removable}
          borderRadius={borderRadius}
          padding={padding}
        />
        {removable && handleRemoveButton && <ActionButton fieldId={name} type="remove" onClick={handleRemoveButton} />}
        {lastUpdateUser && <UserIcon userType={lastUpdateUser} />}
      </SelectWrapper>
      {isFieldInvalid && (
        <HelperMessage
          fieldName={name}
          fieldText={fieldError}
          errorMargin={errorMargin}
          fontSize={alternateStyle ? 14 : 16}
        />
      )}
    </Container>
  );
};

export default SelectFieldBase;
