import React from 'react';
import { StyledTile } from '../Dashboard';
import Grid from '@mui/material/Grid';
import { Search } from '@mui/icons-material';
import { Stack, Tab, Tabs, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import MultiSelectWithSearch, { MultiSelectOptions } from '../CarePlanDashboard/MultiSelectWithSearch';
import ROSearchFilter from '../CarePlanDashboard/ROFilterMenu';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Fuse from 'fuse.js';
import { patientData } from './PatientData';
import PatientTable from './PatientTable';

const CLINICS = [
  { label: 'Sacramento', value: 'Sacramento' },
  { label: 'Roseville', value: 'Roseville' },
  { label: 'Fairfield', value: 'Fairfield' },
  { label: 'Lincoln', value: 'Lincoln' },
];

const STATUSES = [
  { label: 'To do', value: 'To do' },
  { label: 'Due today', value: 'Due today' },
  { label: 'Overdue', value: 'Overdue' },
  { label: 'Complete', value: 'Complete' },
];
const TABS = [
  { key: '1', displayText: 'show all' },
  { key: '2', displayText: 'planning' },
  { key: '3', displayText: 'on treatment' },
  { key: '4', displayText: 'follow up' },
  { key: '5', displayText: 'custom view 1' },
  { key: '6', displayText: 'add view' },
];
// import '../Dashboard.scss';
const PatientTracker = () => {
  const [activeTab, setActiveTab] = React.useState('2');
  const [clinicFilters, setClinicFilter] = React.useState<MultiSelectOptions[] | undefined>(undefined);
  const [statusFilters, setStatusFilter] = React.useState<MultiSelectOptions[] | undefined>(undefined);
  const [searchFilter, setSearchFilter] = React.useState('');
  const [filteredData, setFilteredData] = React.useState<any>(patientData);
  //   const [clinicSearchFilters, setClinicSearchFilter] = React.useState<string[]>([]);
  //   const [statusSearchFilters, setStatusSearchFilter] = React.useState<string[]>([]);
  const handleReset = () => {};

  const filterData = (filterType: any, filterValue: any) => {
    let newFilteredData = patientData;
    if (filterType === 'status') {
      if (filterValue.length > 0) {
        setStatusFilter(filterValue);
        newFilteredData = filterStatus(filterValue, newFilteredData);
      } else {
        setStatusFilter([]);
      }
      if (clinicFilters && clinicFilters.length > 0) {
        newFilteredData = filterClinic(clinicFilters, newFilteredData);
      }
      if (searchFilter != '') {
        newFilteredData = filterText(searchFilter, newFilteredData);
      }
    }
    if (filterType === 'clinic') {
      if (filterValue.length > 0) {
        setClinicFilter(filterValue);
        newFilteredData = filterClinic(filterValue, newFilteredData);
      } else {
        setClinicFilter(undefined);
      }
      if (statusFilters && statusFilters.length > 0) {
        newFilteredData = filterStatus(statusFilters, newFilteredData);
      }
      if (searchFilter != '') {
        newFilteredData = filterText(searchFilter, newFilteredData);
      }
    }
    if (filterType === 'search') {
      if (filterValue != '') {
        setSearchFilter(filterValue);
        newFilteredData = filterText(filterValue, newFilteredData);
      } else {
        setSearchFilter('');
      }
      if (statusFilters && statusFilters.length > 0) {
        newFilteredData = filterStatus(statusFilters, newFilteredData);
      }
      if (clinicFilters && clinicFilters.length > 0) {
        newFilteredData = filterClinic(clinicFilters, newFilteredData);
      }
    }
    setFilteredData(newFilteredData);
  };
  const filterText = (filterValue: any, data: any) => {
    const options = {
      keys: ['patientName'],
      threshold: 0.4,
    };
    const fuse = new Fuse(data, options);
    const results = fuse.search(filterValue);
    return results.map((result: any) => result.item);
  };

  const filterClinic = (clinicOptions: any, data: any): any => {
    const clinicsList = clinicOptions.map((item: any) => item.value);
    return data.filter((item: any) => {
      return clinicsList.includes(item.firstTreatmentLocation);
    });
  };
  const filterStatus = (statusOptions: any, data: any): any => {
    const statusList = statusOptions.map((item: any) => item.value);
    return data.filter((item: any) => {
      return (
        statusList.includes(item.firstTreatmentStatus) ||
        statusList.includes(item.preAuthStatus) ||
        statusList.includes(item.SIMRequestStatus) ||
        statusList.includes(item.prescriptionStatus) ||
        statusList.includes(item.complianceDocsStatus) ||
        statusList.includes(item.simulationStatus) ||
        statusList.includes(item.fusionStatus) ||
        statusList.includes(item.targetVolumeStatus) ||
        statusList.includes(item.contouringStatus) ||
        statusList.includes(item.planApprovalStatus)
      );
    });
  };

  return (
    <div className="RO-dashboard-wrapper" style={{ padding: '20px 0' }}>
      <StyledTile
        className="careplan-dashboard-wrapper"
        style={{
          padding: '20px 0px 0px 20px',
        }}>
        <div className="filter-wrapper" id="careplan-viewer-title">
          <div className="table-title-style">My Patients</div>
        </div>
        <div className="filter-wrapper">
          <Grid container spacing={2} sx={{ paddingBottom: '15px' }}>
            <Grid item xs={12}>
              <Tabs
                value={activeTab}
                onChange={(_event, seleced) => {
                  setActiveTab(seleced);
                }}>
                {TABS.map((tab: any) => (
                  <Tab
                    value={tab.key}
                    key={tab.key}
                    label={
                      <Stack gap="4px" alignItems="center" direction="row" fontWeight={600}>
                        {tab.key == '6' && <AddOutlinedIcon fontSize="small" style={{ color: '#6B788E' }} />}{' '}
                        {tab.displayText}
                      </Stack>
                    }
                  />
                ))}
              </Tabs>
            </Grid>
          </Grid>
        </div>
        <div className="filter-wrapper">
          <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              placeholder="Search patient name"
              variant="filled"
              size="small"
              sx={{
                maxWidth: '250px',
                backgroundColor: 'unset',
                borderRadius: '8px',
                '& .MuiInputBase-root': {
                  backgroundColor: 'unset',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(ev: any) => {
                if (ev.key === 'Enter') {
                  filterData('search', ev.target.value);
                }
              }}
              onChange={(event: any) => {
                filterData('search', event.target.value);
              }}
            />

            <ROSearchFilter
              showAlert={clinicFilters ? clinicFilters?.length > 0 : false}
              title="CLINIC"
              id="radiationOncologistFilter"
              startIcon={<LocationCityIcon />}>
              <MultiSelectWithSearch
                options={CLINICS}
                id={'multi-select-search-filter-by-practitioner'}
                value={clinicFilters}
                label={'Clinics'}
                placeholder={'Clinics'}
                onChange={(value: any) => {
                  filterData('clinic', value);
                }}
                disableCloseOnSelect
              />
            </ROSearchFilter>
            <ROSearchFilter
              showAlert={statusFilters ? statusFilters?.length > 0 : false}
              title="STATUS"
              id="radiationOncologistFilter"
              startIcon={<PlaylistAddCheckIcon />}>
              <MultiSelectWithSearch
                options={STATUSES}
                id={'multi-select-search-filter-by-practitioner'}
                value={statusFilters}
                label={'Status'}
                placeholder={'Select status'}
                onChange={(value: any) => {
                  filterData('status', value);
                }}
                disableCloseOnSelect
              />
            </ROSearchFilter>
          </Grid>
        </div>
        <div className="filter-wrapper">
          <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
            <PatientTable patientData={filteredData} />
          </Grid>
        </div>
      </StyledTile>
    </div>
  );
};

export default PatientTracker;
