import { isUs } from 'op-utils';

export const OTHER = 'Other';
export const YES = 'YES';
export const NO = 'NO';
export const UNSURE = 'UNSURE';
export const MALE = 'MALE';
export const FEMALE = 'FEMALE';

export const OP_FIELDS = {
  PREV_OPERATION: {
    NAME: 'previous-operation',
    TITLE: 'Have you had any previous operations?',
    KEY: 'prevOperation',
  },
};

export enum TREATMENT_TYPES {
  SURGERY = 'Surgery',
  RADIATION_THERAPY = 'Radiation therapy',
  CHEMOTHERAPY = 'Chemotherapy',
  IMMUNOTHERAPY = 'Immunotherapy',
  HORMONAL_THERAPY = 'Hormonal therapy',
  BRACHYTHERAPY = 'Brachytherapy',
  THERANOSTICS = 'Theranostics',
  NONE = 'None - observation',
  OTHER = 'Other',
  UNSURE = 'Unsure',
}

export const SA_FIELDS = {
  ALCOHOL: {
    NAME: 'alcohol',
    TITLE: 'Do you drink alcohol?',
    KEY: 'alcoholDrinker',
  },
  ALCOHOL_FREQUENCY: {
    NAME: 'alcohol-frequency',
    TITLE: 'How frequently do you drink?',
    KEY: 'alcoholFrequency',
  },
  SMOKING: {
    NAME: 'smoking',
    TITLE: 'Do you or have you ever smoked?',
    KEY: 'alcoholFrequency',
  },
  SMOKING_HISTORY: {
    NAME: 'smoking-history',
    TITLE: 'Smoking history',
    KEY: 'smokingHistory',
    MORE_INFO: 'Round up to the nearest year, e.g. 1.7 = 2 years',
  },
  SMOKING_FREQUENCY: {
    NAME: 'smoking-frequency',
    TITLE: 'How frequently do/did you smoke?',
    KEY: 'smokingFrequency',
  },
  SMOKING_STOP: {
    NAME: 'smoking-stop',
    TITLE: 'Date stopped smoking',
    KEY: 'smokingStop',
    MORE_INFO: 'Please enter date if known e.g. Dec 2001',
  },
  EXERCISE: {
    NAME: 'exercise',
    TITLE: 'Do you exercise?',
    KEY: 'exercise',
  },
  EXERCISE_FREQUENCY: {
    NAME: 'exercise-frequency',
    TITLE: 'How frequently do you exercise?',
    KEY: 'exerciseFrequency',
  },
  ACCOMMODATION: {
    NAME: 'accommodation',
    TITLE: 'Do you require accommodation assistance?',
    KEY: 'accommodationAssitance',
  },
  ACCOMMODATION_REASON: {
    NAME: 'accommodation-reason',
    TITLE: 'Reason accommodation assistance required',
    KEY: 'accommodationReason',
  },
  TRANSPORTATION: {
    NAME: 'transportation',
    TITLE: 'Do you require transportation assistance?',
    KEY: 'transportationAssistance',
  },
  TRANSPORTATION_REASON: {
    NAME: 'transportation-reason',
    TITLE: 'Reason transportation assistance required',
    KEY: 'transportationReason',
  },
};

export const ILL_FIELDS = {
  DEVICES: {
    NAME: 'internalDevices',
    TITLE: 'Do you have any implanted/artificial internal devices?',
    KEY: 'internalDevice',
    INFO: 'e.g. pacemaker, heart stent, port internal defibrillator or joint replacement',
    PACEMAKER_INFO: 'Please bring your pacemaker card with you',
  },
  CLOT: {
    NAME: 'blood-clot',
    TITLE: 'Have you ever had a blood clot in your legs (DVT) or lungs (PE)?',
    KEY: 'bloodClot',
    DETAIL: {
      NAME: 'blood-clot-detail',
      TITLE: 'Please specify',
      KEY: 'bloodClotAnswer',
    },
  },
  PROBLEMS: {
    NAME: 'heart-problems',
    TITLE: 'Have you had any heart or lung problems?',
    KEY: 'heartOrLungProblem',
    DETAIL: {
      NAME: 'heart-problems-detail',
      TITLE: 'Please specify',
      KEY: 'heartOrLungAnswer',
    },
  },
  PRESSURE: {
    NAME: 'blood-pressure',
    TITLE: 'Do you suffer from high or low blood pressure?',
    KEY: 'highOrLowBloodPressure',
    DETAIL: {
      NAME: 'blood-pressure-detail',
      TITLE: 'Please specify',
      KEY: 'bloodPressureAnswer',
    },
  },
  DIABETES: {
    NAME: 'diabetes',
    TITLE: 'Do you have diabetes?',
    KEY: 'diabetes',
    DETAIL: {
      NAME: 'diabetes-detail',
      TITLE: 'Diabetes type',
      KEY: 'diabetesResponses',
    },
  },
  RESISTANT: {
    NAME: 'resistant',
    TITLE:
      'Have you ever been diagnosed with an antibiotic-resistant illness OR an infection that has been hard to treat?',
    KEY: 'intractableInfectionOrIllness',
    INFO: 'e.g. MRSA – Golden Staph, VRE, MRO, or other infections that have been hard to treat',
    PERMISSION: {
      NAME: 'permission',
      TITLE: 'Did you have clearance from your doctor?',
    },
  },
  SUPPRESSIVE: {
    NAME: 'suppressive',
    TITLE: 'Do you have an immune suppressive condition or current infectious disease?',
    KEY: 'immunosuppressiveCondition',
    INFO: 'e.g. HIV, hepatitis, chicken pox, influenza or tuberculosis',
  },
};

export const CR_FIELDS = {
  CANCER: {
    NAME: 'previousCancer',
    TITLE: 'Have you had any form of cancer previously (including skin)?',
    KEY: '',
  },
  TREATMENT: {
    NAME: 'treatment',
    TITLE: 'Treatment',
    KEY: '',
  },
  RADIOTHERAPY: {
    NAME: 'otherRadiotherapy',
    INFO: 'Start with the most recent treatment',
    TITLE: `Have you had any other condition for which you received ${
      isUs() ? 'Radiotherapy' : 'radiation treatment'
    }?`,
    DETAILS: {
      NAME: 'otherRadiotherapyConditions',
    },
  },
  HISTORY: {
    NAME: 'familyHistory',
    TITLE: 'Do you have any family history of cancer or blood disease?',
    DETAILS: {
      NAME: 'familyHistoryAnswer',
      TITLE: 'Please specify',
    },
  },
};

export const CR_MODAL_FIELDS = {
  CANCER_TYPE: {
    NAME: 'cancer-type',
    TITLE: 'Type of cancer',
    KEY: 'cancerType',
  },
  CANCER_STAGE: {
    NAME: 'cancer-stage',
    TITLE: 'Stage of cancer (if known)',
    KEY: 'cancerStage',
  },
  TREATMENT_TYPE: {
    NAME: 'treatment-type',
    TITLE: 'Treatment type',
    KEY: '',
  },
  HOSPITAL: {
    NAME: 'hospital',
    TITLE: 'Name of the clinic/hospital',
    KEY: 'hospital',
  },
  HORMONAL: {
    NAME: 'medication',
    TITLE: 'Medication',
    KEY: 'medication',
  },
  DOCTOR: {
    NAME: 'doctor',
    TITLE: 'Doctor',
    KEY: 'doctor',
    HINT: 'e.g. Dr Michael Stephens',
  },
  BODY: {
    NAME: 'body-part',
    TITLE: 'What part of your body?',
    KEY: 'bodyPart',
    HINT: 'e.g. left hand, nose, left, side of neck',
  },
  STAGE: {
    NAME: 'stage',
    TITLE: 'Stage',
    KEY: 'stage',
  },
  OTHER: {
    NAME: 'other',
    TITLE: 'Please specify',
    KEY: 'other',
  },
  TREATMENT_DATE: {
    NAME: 'treatment-date',
    KEY: 'treatmentDate',
  },
  TREATMENT_DATE_HEADING: 'Date of the last treatment',
  TREATMENT_DATE_EXAMPLE_TEXT: 'Please enter date if known',
  TREATMENT_DATE_RAW_DAY: {
    NAME: 'treatment-day',
    KEY: 'treatmentDay',
  },
  TREATMENT_DATE_RAW_MONTH: {
    NAME: 'treatment-month',
    KEY: 'stage',
  },
  TREATMENT_DATE_RAW_YEAR: {
    NAME: 'treatment-year',
    KEY: 'stage',
  },
  RADIOTHERAPY: {
    NAME: 'radiotherapy',
    TITLE: `Have you had any other condition for which you received ${
      isUs() ? 'Radiotherapy' : 'radiation treatment'
    }?`,
  },
  HISTORY: {
    NAME: 'history',
    TITLE: 'Do you have any family history of cancer or blood disease?',
    DETAILS: {
      NAME: 'history-details',
      TITLE: 'Please specify.',
    },
  },
  ADD_BUTTON: 'Add',
};

export const FAMILY_HISTORY_MODAL = {
  FAMILY_MEMBER: {
    NAME: 'familyMember',
    TITLE: 'Family member',
    KEY: 'familyMember',
  },
  TYPE_OF_CANCER: {
    NAME: 'cancerType',
    TITLE: 'Type of cancer or blood disease',
    KEY: 'cancerType',
  },
  STILL_ALIVE: {
    NAME: 'stillAlive',
    TITLE: 'Is this family member still alive?',
    KEY: 'stillAlive',
  },
  AGE_OF_FAMILY_MEMBER: {
    NAME: 'ageOfFamilyMember',
    TITLE: 'Age of family member',
    KEY: 'ageOfFamilyMember',
  },
  OTHER: {
    NAME: 'other',
    TITLE: 'Please specify',
    KEY: 'other',
  },
};

export const MHO_FIELDS = {
  WALKING: {
    NAME: 'assistance-walking',
    TITLE: 'Do you need assistance with walking?',
    MORE_INFO: 'e.g. artificial limb, require a walking aid or a wheelchair',
    KEY: 'mobilityAid',
  },
  MOBILITY_AIDS: {
    KEY: 'mobilityAids',
  },
  MOBILITY_AIDS_OTHER: {
    KEY: 'mobilityAidsOther',
  },
  MENTAL: {
    NAME: 'mental-health',
    TITLE: 'Do you have any wellbeing or mental health issues?',
    MORE_INFO: 'e.g. depression, anxiety, claustrophobia, memory loss or other mental health issues',
    KEY: 'wellbeingIssue',
  },
  MENTAL_ISSUES: {
    KEY: 'wellbeingIssues',
  },
  ACTIVE_INJURY: {
    NAME: 'active-injury',
    TITLE: 'Do you currently have any pressure injuries, ulcers, or open wounds?',
    KEY: 'activeInjury',
  },
  ACTIVE_INJURY_REASON: {
    NAME: 'active-injury-reason',
    TITLE: 'Please specify',
    KEY: 'activeInjuryReason',
  },
  IN_PAIN: {
    NAME: 'in-pain',
    TITLE: 'Are you currently in pain?',
    KEY: 'inPain',
  },
  IN_PAIN_REASON: {
    NAME: 'in-pain-reason',
    TITLE: 'Please specify',
    KEY: 'inPainReason',
  },
  FALLEN: {
    NAME: 'fallen',
    TITLE: 'Have you had a fall within the past 12 months?',
    KEY: 'fallen',
  },
  FALLEN_REASON: {
    NAME: 'fallen-reason',
    TITLE: 'Please describe what happened',
    KEY: 'fallenReason',
  },
  PREGNANT: {
    NAME: 'pregnant',
    TITLE: 'Could you be pregnant?',
    KEY: 'pregnant',
  },
  ADVANCED: {
    NAME: 'advanced-care-directive',
    TITLE: 'Do you have an Advance Care Directive?',
    MORE_INFO: "Please bring the original or a certified copy with you to your next doctor's appointment.",
    KEY: 'advancedCareDirective',
  },
  INFO: {
    NAME: 'further-info',
    KEY: 'advancedCareAdditionalInformation',
    TITLE: 'Would you like further information about the Advance Care Directive? (optional)',
  },
};

export const MOBILITY_FIELDS = {
  MOBILITY_AID: {
    NAME: 'mobilityAid',
    TITLE: 'Do you need assistance with walking?',
    MORE_INFO: 'e.g. artificial limb, walking aid or a wheelchair',
    KEY: 'mobilityAid',
  },
  FALLEN: {
    NAME: 'fallen',
    TITLE: 'Have you fallen before?',
    KEY: 'fallen',
  },
  FALLEN_HOW_MANY: {
    NAME: 'fallenHowMany',
    TITLE: 'How many falls in the past 12 months?',
    KEY: 'fallenHowMany',
  },
  FALLEN_INJURIES: {
    NAME: 'fallenInjuries',
    TITLE: 'Please list any injuries related to the falls',
    KEY: 'fallenInjuries',
  },
  FOOT_PAIN: {
    NAME: 'footPain',
    TITLE:
      'Do you have foot ulcers, bunions, hammertoes, or calluses that are painful or cause you to adjust your steps while walking?',
    KEY: 'footPain',
  },
  UNSTEADY: {
    NAME: 'unsteady',
    TITLE: 'Do you feel unsteady on your feet or shuffle when you walk?',
    KEY: 'unsteady',
  },
  LIGHTHEADED: {
    NAME: 'lightheaded',
    TITLE: 'Do you feel dizzy/lightheaded when you stand up?',
    KEY: 'lightheaded',
  },
};

export const BIOLOGICAL_FIELDS = {
  GENDER: {
    NAME: 'gender',
    TITLE: 'Your biological gender (sex at birth)',
  },
  PERIOD_AGE: {
    NAME: 'periodAge',
    TITLE: 'Age when you had your first menstrual period',
  },
  ABNORMAL_MAMMOGRAMS: {
    NAME: 'abnormalMammograms',
    TITLE: 'Have you had prior abnormal mammograms?',
  },
  BREAST_BIOPSY: {
    NAME: 'breastBiopsy',
    TITLE: 'Have you had prior breast biopsy?',
  },
  PERIOD_WHEN: {
    NAME: 'periodWhen',
    TITLE: 'When was your last menstrual period',
    MORE_INFO: 'e.g. last week, two weeks ago etc.',
  },
  MENOPAUSE: {
    NAME: 'menopause',
    TITLE: 'Have you been through menopause?',
  },
  MENOPAUSE_AGE: {
    NAME: 'menopauseAge',
    TITLE: 'Age at menopause',
  },
  PREGNANT: {
    NAME: 'pregnant',
    TITLE: 'Any chance you could be pregnant?',
  },
  PREGNANT_COUNT: {
    NAME: 'pregnantCount',
    TITLE: 'Number of pregnancies',
  },
  PREGNANT_DELIVERIES: {
    NAME: 'pregnantDeliveries',
    TITLE: 'Number of deliveries',
  },
  PREGNANT_AGE: {
    NAME: 'pregnantAge',
    TITLE: 'Your age when you had your first delivery, if applicable',
  },
  BREASTFEED: {
    NAME: 'breastfeed',
    TITLE: 'Did you breastfeed?',
  },
  HORMONES: {
    NAME: 'hormones',
    TITLE: 'Did you ever take hormones?',
    MORE_INFO: 'e.g. estrogen, birth control pills, androgens, etc.',
  },
  HORMONES_DURATION: {
    NAME: 'hormonesDuration',
    TITLE: 'How long did you take hormones?',
  },
  BRA_SIZE: {
    NAME: 'braSize',
    TITLE: 'Bra cup size',
  },
  NIPPLE_DISCHARGE: {
    NAME: 'nippleDischarge',
    TITLE: 'Do you have nipple discharge?',
  },
  NIPPLE_DISCHARGE_EXPLAIN: {
    NAME: 'nippleDischargeExplain',
    TITLE: 'Please explain',
  },
  VAGINAL_BLEEDING: {
    NAME: 'vaginalBleeding',
    TITLE: 'Do you have vaginal bleeding?',
  },
  VAGINAL_BLEEDING_EXPLAIN: {
    NAME: 'vaginalBleedingExplain',
    TITLE: 'Please explain',
  },
  VAGINAL_DISCHARGE: {
    NAME: 'vaginalDischarge',
    TITLE: 'Do you have vaginal discharge?',
  },
  VAGINAL_DISCHARGE_EXPLAIN: {
    NAME: 'vaginalDischargeExplain',
    TITLE: 'Please explain',
  },
  STD_FEMALE: {
    NAME: 'stdFemale',
    TITLE: 'History of sexually transmitted diseases?',
  },
  PAP_SMEAR_DATE: {
    NAME: 'papSmearDate',
    TITLE: 'Date of PAP smear (if known)',
    MORE_INFO: 'e.g. Jan 31 2007',
  },
  MAMMOGRAM_DATE: {
    NAME: 'mammogramDate',
    TITLE: 'Date of last mammogram (if known)',
    MORE_INFO: 'e.g. Jan 31 2007',
  },

  PSA_DATE: {
    NAME: 'psaDate',
    TITLE: 'Date of last PSA: ',
    MORE_INFO: 'Please enter date if known e.g. Jan 31 2007',
  },
  PSA_SCORE: {
    NAME: 'psaScore',
    TITLE: 'Score of last PSA:',
  },
  PSA_LOCATION: {
    NAME: 'psaLocation',
    TITLE: 'Where was the last PSA done?',
  },
  STD_MALE: {
    NAME: 'stdMale',
    TITLE: 'History of sexually transmitted diseases?',
  },
};

export const SOCIAL_US_FIELDS = {
  SMOKING_BOOL: {
    NAME: 'smokingBool',
    TITLE: 'Do you or have you ever smoked?',
  },
  SMOKING_HISTORY: {
    NAME: 'smokingHistory',
    TITLE: 'Smoking history',
    MORE_INFO: 'Round up to the nearest year, e.g. 1.7 = 2 years',
  },
  SMOKING_FREQUENCY: {
    NAME: 'smokingFrequency',
    TITLE: 'How frequently do/did you smoke?',
  },
  SMOKING_STOP: {
    NAME: 'smokingStop',
    TITLE: 'Date stopped smoking (optional)',
    MORE_INFO: 'e.g. 12 2001',
  },
  TOBACCO_BOOL: {
    NAME: 'tobaccoBool',
    TITLE: 'Have you ever chewed tobacco?',
  },
  TOBACCO_HISTORY: {
    NAME: 'tobaccoHistory',
    TITLE: 'How much tobacco have you chewed?',
  },
  TOBACCO_STOP: {
    NAME: 'tobaccoStop',
    TITLE: 'Date stopped chewing tobacco (optional)',
    MORE_INFO: 'e.g. 12 2001',
  },
  TOBACCO_CLASSES: {
    NAME: 'tobaccoClasses',
    TITLE: 'Have you ever attended tobacco cessation classes',
    MORE_INFO: 'e.g. 12 2001',
  },
  ALCOHOL_BOOL: {
    NAME: 'alcoholBool',
    TITLE: 'Do/did you drink alcohol?',
  },
  ALCOHOL_FREQUENCY: {
    NAME: 'alcoholFrequency',
    TITLE: 'How frequently do/did you drink?',
  },
  ALCOHOL_STOP: {
    NAME: 'alcoholStop',
    TITLE: 'Date stopped drinking (optional)',
    MORE_INFO: 'e.g. 12 2001',
  },
  STREET_DRUGS_BOOL: {
    NAME: 'streetDrugsBool',
    TITLE: 'Do you use any recreational drugs?',
  },
  STREET_DRUGS_OPTIONS: {
    NAME: 'streetDrugsOptions',
    TITLE: 'Please describe which ones?',
  },

  MEDICAL_MARIJUANA_CARD_BOOL: {
    NAME: 'medicalMarijuanaCardBool',
    TITLE: 'Do you have a medical marijuana card? ',
  },
  SOCIAL_SUPPORT_SYSTEM_BOOL: {
    NAME: 'socialSupportSystemBool',
    TITLE: 'Do you have a strong social support system?',
  },
  SOCIAL_SUPPORT_SYSTEM: {
    NAME: 'socialSupportSystem',
    TITLE: 'If yes, who?',
  },
  RELIGIOUS_BELIEFS_BOOL: {
    NAME: 'religiousBeliefsBool',
    TITLE: 'Do you adhere to any religious beliefs that you would like us to know about?',
  },
  RELIGIOUS_BELIEFS: {
    NAME: 'religiousBeliefs',
    TITLE: 'Specify any religious beliefs (optional)',
  },
  STILL_WORKING_BOOL: {
    NAME: 'stillWorkingBool',
    TITLE: 'Are you still working?',
  },
  STILL_WORKING: {
    NAME: 'stillWorking',
    TITLE: 'If no, please explain',
  },
  FINANCIAL_ASSISTANCE_BOOL: {
    NAME: 'financialAssisstance',
    TITLE:
      'Do you need any help with any of the following: coping, financial assistance, nutrition, social work, transportation, home assistance?',
  },
  FINANCIAL_ASSISTANCE_REASON: {
    NAME: 'financialAssisstanceReason',
    TITLE: 'If yes, please explain',
  },
  DIFFICULTY_TRAVELLING_BOOL: {
    NAME: 'difficultyTravellingBool',
    TITLE: 'Do you have difficulty travelling?',
  },
  DIFFICULTY_TRAVELLING: {
    NAME: 'difficultyTravelling',
    TITLE: 'If yes, please explain',
  },
  SERVED_IN_MILITARY_BOOL: {
    NAME: 'servedInMilitaryBool',
    TITLE: 'Have you served in the military?',
  },
  SERVED_IN_MILITARY: {
    NAME: 'servedInMilitary',
    TITLE: 'Which branch?',
  },
  CHEMICALS_BOOL: {
    NAME: 'chemicalsBool',
    TITLE:
      'Did you ever work in an occupation that involved exposure to asbestos or any other cancerous chemicals, fumes, or carcinogens?',
  },
  CHEMICALS: {
    NAME: 'chemicals',
    TITLE: 'If yes, please explain',
  },
  PHYSICALLY_ACTIVE: {
    NAME: 'physicallyActive',
    TITLE: 'Are you physically active?',
  },
  PHYSICALLY_ACTIVE_EXERCISE: {
    NAME: 'physicallyActiveExercise',
    TITLE: 'How much exercise',
  },
};

export const MEDICATION_FIELDS = {
  MEDICATION: {
    NAME: 'medicationBool',
    TITLE: 'Are you currently on any medications?',
    HINT: 'If you do not have a current printed list of your medications from your GP, please list current medications and dosage (if known) below',
  },
  PHARMACY_NAME: {
    NAME: 'pharmacyName',
    TITLE: 'Pharmacy Name',
  },
  PHARMACY_PHONE: {
    NAME: 'pharmacyPhone',
    TITLE: 'Pharmacy Phone',
  },
  MEDICATION_NAME: {
    NAME: 'medicationName',
    TITLE: 'Medication',
  },
  DOSAGE: {
    NAME: 'dosage',
    TITLE: 'Dosage and Frequency',
    HINT: 'e.g. 2 tablets per day, 10ml once a week',
  },
  ROUTE: {
    NAME: 'route',
    TITLE: 'Route',
    HINT: 'e.g. Oral, Injection',
  },
  PHYSICIAN: {
    NAME: 'prescribingPhysician',
    TITLE: 'Prescribing Physician',
  },
};

export const PAIN_ASSESSMENT_FIELDS = {
  IN_PAIN: {
    NAME: 'inPain',
    TITLE: 'Are you currently in pain?',
  },
  PAIN_START: {
    NAME: 'painStart',
    TITLE: 'When did the pain start?',
    MORE_INFO: 'e.g. 2 weeks ago, 3 months ago etc.',
  },
  PAIN_SEVERITY: {
    NAME: 'painSeverity',
    TITLE: 'How severe is your pain?',
    MORE_INFO: '0 - No pain, 10 - Worst possible pain',
  },
  PAIN_LOCATION: {
    NAME: 'painLocation',
    TITLE: 'What is the location of your pain?',
    MORE_INFO: 'Please use commas to separate locations',
  },
  PAIN_QUALITY: {
    NAME: 'painQuality',
    TITLE: 'What is the quality of the pain?',
    MORE_INFO: 'Please use commas to separate locations',
  },
  PAIN_LENGTH: {
    NAME: 'painLength',
    TITLE: 'How long have you been in pain?',
  },
  PAIN_MANAGEMENT: {
    NAME: 'painManagement',
    TITLE: 'How is your pain being managed?',
  },
  PAIN_BETTER: {
    NAME: 'painBetter',
    TITLE: 'Is anything making it better?',
  },
  PAIN_WORSE: {
    NAME: 'painWorse',
    TITLE: 'Is anything making it worse?',
  },
};
