// eslint-disable-next-line no-use-before-define
import React, { Fragment } from 'react';
import './ClinicList.scss';
import moment from 'moment';
import classNames from 'classnames';
import { ComposedModal } from 'gc-ui';
import { ButtonMode, ButtonType } from 'shared-components/enums';
import { ButtonRO, BaseDatePicker } from 'shared-components/components/FormFields';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import { StyledTile } from '../Dashboard';
import { WarningSharp } from '@mui/icons-material';
import { styled } from '@mui/system';
import PatientTooltip from '../PatientTooltip';
import dayjs from 'dayjs';
import { Stack, IconButton, Box } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { TodayButton } from '../DailyAppointments/RODailyAppointment';
import { useTheme } from '@mui/material';

const APPT_NFA_TITLE = 'Lock patient record?';
const APPT_NFA_BODY =
  'This will prevent future careplan creation. Only proceed if you are sure there is No Further Action required for this patient record.';
const QUEUED_NFA_TITLE = 'Remove patient record?';
const QUEUED_NFA_BODY =
  ' This will remove the patient record from the Clinic List. Only proceed if patient will not be receiving Radiotherapy at the present time';

interface Patient {
  fullName: string;
  ida: string;
  id: string;
  photoUrl: string;
  age: string;
  dob: string;
  gender: string;
  address: {
    formattedAddress: string;
  };
  emrInstance: string;
}

interface Location {
  name: string;
}

interface AppointmentObject {
  id: string;
  startTime: string;
  duration: number;
  patient: Patient | null;
  appointmentType: string;
  description: string;
  location: Location;
  department: Location;
  queuedPatient: boolean;
  noFurtherAction: boolean;
}

interface Props extends RouteComponentProps {
  appointments: AppointmentObject[];
  currentDate: string;
  onDateChange: (date: string) => void;
  onNFAClick: () => void;
  nfaWarning: boolean;
  onShowNFAModal: (appointment?: AppointmentObject) => void;
  currAppointment?: AppointmentObject | null | undefined;
}
interface TableProps {
  tableHeaders: string[];
  colWidths: string[];
  appointments: any[];
  onShowNFAModal: (appointment?: AppointmentObject) => void;
  // isActive?: boolean;
}

const StyledRow = styled('tr')`
  &:hover {
    background-color: white !important;
    cursor: default !important;
  }
`;

const InnerTable = ({ tableHeaders, colWidths, appointments, onShowNFAModal }: TableProps) => {
  return (
    <Fragment>
      <table className="resp-table">
        <thead>
          <tr>
            {tableHeaders.map((tableHeader, index) => {
              return (
                <th key={`clinic-list-header-${index}`} style={{ width: colWidths[index] }}>
                  <div
                    className={classNames('header-style', {
                      'header-right-aligned': tableHeader === 'Last Modified',
                    })}>
                    {tableHeader}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {appointments.map((appointment: AppointmentObject) => {
            return (
              <StyledRow
                key={`appointment-${appointment.id}`}
                className={classNames({ nfaStyle: appointment.noFurtherAction })}>
                <td>
                  {appointment.startTime && !appointment.queuedPatient
                    ? moment(appointment.startTime).format('hh:mm A')
                    : '-'}
                </td>
                <td>
                  {!appointment.queuedPatient ? `${appointment.duration && appointment.duration / 60} mins` : '-'}
                </td>
                <td data-test-data={appointment.patient?.fullName.toLowerCase()}>
                  {appointment.patient ? (
                    <PatientTooltip patient={appointment.patient}>
                      <Box>
                        <div className={'patient-name-col'} id={`patient-${appointment.patient.id}`}>
                          <div className="photo-widget">
                            <PhotoWidget url={appointment.patient.photoUrl} enableOverlay={true} size={'sm'} />
                          </div>
                          <div className={'patient-name-col-fullname-ida'}>
                            <div>
                              <a href={`/radiation/patient/${appointment.patient.id}/summary`}>
                                {appointment.patient.fullName}
                              </a>
                            </div>
                            <div>{appointment.patient.ida}</div>
                          </div>
                        </div>
                      </Box>
                    </PatientTooltip>
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  {!appointment.queuedPatient
                    ? appointment.description
                      ? appointment.description
                      : appointment.appointmentType
                    : 'Queued'}
                </td>
                <td>{!appointment.queuedPatient && appointment.department ? appointment.department.name : '-'}</td>
                <td>
                  <div className="action-buttons">
                    <ButtonRO
                      backgroundColor="none"
                      id={`remove-button-${appointment.id}`}
                      title={!appointment.queuedPatient ? 'No Further Action' : 'Remove Patient'}
                      type={ButtonType.TRANSPARENT}
                      mode={ButtonMode.REMOVE}
                      onClick={(e): void => {
                        e.stopPropagation();
                        onShowNFAModal(appointment);
                      }}
                    />
                  </div>
                </td>
              </StyledRow>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};

const ROClinicListTable = InnerTable;

const ROClinicList = ({
  appointments,
  currentDate,
  onDateChange,
  onNFAClick,
  nfaWarning,
  onShowNFAModal,
  currAppointment,
}: Props) => {
  const tableHeaders = ['Time', 'Duration', 'Patient name', 'Appointment type', 'Location', 'Actions'];
  const colWidths = ['10%', '6%', '18%', '16%', '18%', '30%'];
  const currDate = currentDate ? moment(currentDate).toDate() : null;
  const theme = useTheme();

  return (
    <Fragment>
      <ComposedModal
        isOpen={nfaWarning}
        dismissFunction={() => {
          onShowNFAModal();
        }}
        width="520px"
        headerText={currAppointment ? (currAppointment.queuedPatient ? QUEUED_NFA_TITLE : APPT_NFA_TITLE) : ''}
        icon={<WarningSharp style={{ fill: theme.palette.warning.dark }}></WarningSharp>}
        secondaryRightButton={{
          buttonText: 'Cancel',
          onClick: (e: any): void => {
            e.preventDefault();
            onShowNFAModal();
          },
        }}
        primaryRightButton={{
          buttonText: currAppointment
            ? currAppointment.queuedPatient
              ? 'Yes, remove record'
              : 'Yes, lock record'
            : '',
          onClick: (e: any): void => {
            e.preventDefault();
            onNFAClick();
            onShowNFAModal();
          },
          type: 'DESTRUCTIVE',
        }}>
        <>
          <span id="modal-info-body" style={{ lineHeight: 1.5 }}>
            {currAppointment ? (currAppointment.queuedPatient ? QUEUED_NFA_BODY : APPT_NFA_BODY) : ''}
          </span>
        </>
      </ComposedModal>
      <div className="RO-dashboard-wrapper">
        <StyledTile className="clinicList-dashboard-wrapper">
          <div className="filter-wrapper">
            <div className="table-title-style">
              <span className="table-title" data-test-id="table-title">
                Clinic List
              </span>
            </div>
            <div className="table-filters">
              <Stack direction="row" alignItems="center" sx={{ marginLeft: 'auto', marginBottom: '6px' }} gap="2px">
                <TodayButton
                  onClick={() => {
                    onDateChange(moment().format('YYYY-MM-DD'));
                  }}>
                  Today
                </TodayButton>
                <IconButton
                  onClick={() =>
                    onDateChange((currDate ? moment(currDate).subtract(1, 'days') : moment()).format('YYYY-MM-DD'))
                  }>
                  <ArrowBackIosNew color="primary" />
                </IconButton>
                <BaseDatePicker
                  id="dailyAppointmentDatePicker"
                  value={dayjs(currDate)}
                  onChange={(date, context) => {
                    if (context.validationError || !date) return;
                    onDateChange(date.format('YYYY-MM-DD'));
                  }}
                />
                <IconButton
                  onClick={() =>
                    onDateChange((currDate ? moment(currDate).add(1, 'days') : moment()).format('YYYY-MM-DD'))
                  }>
                  <ArrowForwardIos color="primary" />
                </IconButton>
              </Stack>
            </div>
          </div>
          <div className="table-wrapper">
            <ROClinicListTable
              tableHeaders={tableHeaders}
              colWidths={colWidths}
              appointments={appointments}
              onShowNFAModal={onShowNFAModal}
            />
          </div>
        </StyledTile>
      </div>
    </Fragment>
  );
};

export default withRouter(ROClinicList);
