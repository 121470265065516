// eslint-disable-next-line no-use-before-define
import { Close as CloseIcon } from '@mui/icons-material';
import React from 'react';
import { Card, Popover, CardHeader, CardContent, CardActions, Divider, Stack, useTheme } from '@mui/material';
import GCButton from 'shared-components/components/UIFormComponents/GCButton';
import { OnboardingPopoverPageLogData } from 'shared-components/interfaces/PageLogData';
import { POPOVER_LOG_EVENTS } from 'shared-components/utils/ElasticsearchHelpers';
import { styled } from '@mui/system';
import './OnboardingPopover.scss';
import { OnboardingPopoverFeatures } from './OnboardingPopoverFeatures';
import { PopoverContentsCreatePatient } from './PopoverContentsCreatePatient';
import {
  PopoverContentsGPReferrerStep1,
  PopoverContentsGPReferrerStep2,
  PopoverContentsGPReferrerStep3,
} from './PopoverContentsGPReferrer';

interface OnboardingPopoverIProps {
  handleClose: () => void;
  popoverOpen: boolean;
  target: HTMLElement | null;
  children: React.ReactNode;
  handleVisitLink: () => void;
  feature: string;
  handleNext?: () => void;
  step?: string;
  hidePopOverComponent?: boolean;
  logEvent?: (pageData: OnboardingPopoverPageLogData) => {};
}

const StyledBackdrop = styled('div')`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(90, 90, 90, 0.5);
  z-index: 7099;
`;

const ImportantDiv = styled('div')<{ $popoverOpen?: boolean }>`
  ${({ $popoverOpen }) =>
    $popoverOpen &&
    `
  position: relative !important;
  z-index: 7100;
  `}
`;

const OnboardingPopover = (props: OnboardingPopoverIProps): JSX.Element => {
  const theme = useTheme();
  const onBoardingContent = (): JSX.Element => {
    switch (props.feature) {
      case OnboardingPopoverFeatures.CREATE_PATIENT:
        return <PopoverContentsCreatePatient handleVisitLink={props.handleVisitLink} />;
      case OnboardingPopoverFeatures.GP_REFERRER:
        if (props.step === '1') {
          return <PopoverContentsGPReferrerStep1 pageLoadEvent={props.logEvent} />;
        } else if (props.step === '2') {
          return <PopoverContentsGPReferrerStep2 />;
        } else {
          return <PopoverContentsGPReferrerStep3 />;
        }
      default:
        return <PopoverContentsCreatePatient handleVisitLink={props.handleVisitLink} />;
    }
  };

  const localHandleClose = () => {
    if (props.feature === OnboardingPopoverFeatures.GP_REFERRER) {
      const pageData: OnboardingPopoverPageLogData = {
        eventName: POPOVER_LOG_EVENTS.EXIT,
        exitStep: props.step || '',
      };

      props.logEvent && props.logEvent(pageData);
    }

    props.handleClose();
  };

  const handleButtonClick = () => {
    switch (props.feature) {
      case OnboardingPopoverFeatures.GP_REFERRER:
        if (props.step === '1' || props.step === '2') {
          const pageData: OnboardingPopoverPageLogData = {
            eventName: POPOVER_LOG_EVENTS.NEXT_STEP,
            currentStep: props.step || '',
          };
          props.logEvent && props.logEvent(pageData);
        } else {
          const pageData: OnboardingPopoverPageLogData = {
            eventName: POPOVER_LOG_EVENTS.COMPLETE,
            currentStep: props.step || '',
          };
          props.logEvent && props.logEvent(pageData);
        }

        props.handleNext && props.handleNext();
        break;
      case OnboardingPopoverFeatures.CREATE_PATIENT:
      default:
        localHandleClose();
    }
  };

  const getButtonText = (): string => {
    switch (props.step) {
      case '1':
        return 'Show me!';
      case '2':
        return 'Next';
      default:
        return 'Got it!';
    }
  };

  // unit tests wont run if popover surrounds children
  if (props.hidePopOverComponent) return <>{props.children}</>;

  return (
    <>
      {props.popoverOpen && <StyledBackdrop />}
      <ImportantDiv $popoverOpen={props.popoverOpen}>
        {props.children}
        <Popover
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={props.popoverOpen}
          anchorEl={props.target}
          sx={{ maxWidth: '800px' }}>
          <Card>
            <CardHeader
              action={
                <CloseIcon style={{ fill: theme.palette.primary.main, cursor: 'pointer' }} onClick={localHandleClose} />
              }
            />
            <CardContent>{onBoardingContent()}</CardContent>
            <Divider color="primary" />
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Stack direction="row-reverse" alignItems="center" spacing={2}>
                <GCButton
                  id="onboarding-popover-ok"
                  onClick={() => handleButtonClick()}
                  buttonText={getButtonText()}
                  backgroundColor={theme.palette.primary.main}
                  textColor="white"
                  fontWeight="bold"
                />
                {props.step === '2' && (
                  <div className="l-l" onClick={localHandleClose}>
                    Skip (1 of 2)
                  </div>
                )}
              </Stack>
            </CardActions>
          </Card>
        </Popover>
      </ImportantDiv>
    </>
  );
};

export default OnboardingPopover;
