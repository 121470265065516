// eslint-disable-next-line no-use-before-define
import React from 'react';
import { FormBuilderComponentProps } from '../../FormBuilderInterfaces';
import { fieldMeetsConditions } from '../../common';
import { BaseAutocomplete } from 'shared-components/components/FormFields';
import { SelectOptionType } from 'shared-components/components/FormFields';

export default function FBDropdownSearch(props: FormBuilderComponentProps): JSX.Element {
  const { component, formikProps, handleUpdate, options } = props;

  // Slight workaround so that typescript doesn't complain (duplicated in now unused FBDropdown)
  const dropdownOptions = options[component.field.listDataCategory]?.map((option: SelectOptionType) => {
    return {
      ...option,
      label: option.value,
      // text: option.value // we may need this or to standardise the use of text or value key across components
    };
  });

  function convertValueToObject(value: string | number) {
    return {
      value: value,
      label: value,
    };
  }
  const fieldValue = formikProps.values[component.field.name];
  return (
    <BaseAutocomplete
      key={component.field.name}
      id={component.field.name}
      options={dropdownOptions ?? []}
      value={component.field.dataType === 'object' ? fieldValue : fieldValue ? convertValueToObject(fieldValue) : null} // need to send falsy value if undefined so placeholder will show
      onChange={(option: SelectOptionType | string): void => {
        const value = typeof option === 'string' ? option : option?.value;
        handleUpdate(formikProps, component, value || '');
        // ensure validation immediately appears on interaction with fields that update using onChange event, (otherwise will validate on blur)
        formikProps.handleBlur(component.field.name);
      }}
      disabled={fieldMeetsConditions(formikProps.values, component.disabled, false)}
      // readOnly={fieldMeetsConditions(formikProps.values, component.disabled, false)} doesn't have one
      placeholder={component.placeholder ?? ''}
    />
  );
}
