// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

interface ListDataType {
  count: number;
  label: string;
}

interface Props {
  listData: ListDataType[];
}

const ChartReferrals = (props: Props) => {
  const [barData, setBarData] = useState([]);

  useEffect(() => {
    if (props?.listData) {
      const data: any = [['Referrers', 'Count']];

      props.listData.forEach((element, index) => {
        if (index < 10) {
          data.push([element.label, element.count]);
        }
      });

      setBarData(data);
    }
  }, [props.listData]);

  const options = {
    chartArea: {
      top: 20,
      height: '50%',
      width: '80%',
    },
    bar: { groupWidth: '90%' },
    legend: { position: 'none' },
    tooltip: {
      trigger: 'hover',
    },
    enableInteractivity: true,
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
    },

    hAxis: {
      slantedText: true,
      slantedTextAngle: 90,
      textPosition: 'out',
      textStyle: {
        fontSize: 10,
      },
    },
    colors: ['#081E43'],
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      {barData && (
        <div>
          <h2 style={{ textAlign: 'center', paddingTop: '20px' }}>Top 10 Referrers</h2>
          <Chart chartType="ColumnChart" width="100%" height="610px" data={barData} options={options} />
        </div>
      )}
    </div>
  );
};

export default ChartReferrals;
