// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ROBase, StyledArrow, StyledTitle } from 'op-components';
import { GET_INTERNAL_ONCOLOGISTS, GET_USER_PROFILE } from 'op-graphql/queries';
import { generatePath, Link, Redirect } from 'react-router-dom';
import {
  createPatientSteps,
  LoadingSpinner,
  OncologyStepper,
  MiddleContainerCol,
  RightContainerCol,
} from 'shared-components/components';
import { styled } from '@mui/system';
import CreatePatientBasicForm from './CreatePatientBasicForm';
import { Stack, useTheme } from '@mui/material';

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px 16px 16px;
  text-decoration: none;
  height: 63px;
  border-bottom: solid 1px ${(props) => props.theme.palette.secondary.main};
  transition: border-color 0.15s ease-in-out;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const CreatePatient = (): JSX.Element => {
  const [error, setError] = useState(false);
  const { data: userProfile, loading: userLoading, error: userError } = useQuery(GET_USER_PROFILE);
  const theme = useTheme();
  const {
    data: physiciansRefData,
    loading: physiciansRefLoading,
    error: physiciansRefError,
  } = useQuery(GET_INTERNAL_ONCOLOGISTS);

  useEffect(() => {
    if (userError || physiciansRefError) return setError(true);
    if (userProfile && !(userProfile.user.isPso || userProfile.user.isRo)) return setError(true);
  }, [userError, userProfile, physiciansRefError]);

  if (userLoading || physiciansRefLoading) return <LoadingSpinner />;
  if (error) return <Redirect to="/error" />;

  const BackToDashboard = (): JSX.Element => {
    const url = userProfile?.user?.isPso ? generatePath('/search') : generatePath('/radiation');
    return (
      <StyledLink to={url} data-test-id="back-to-search-button">
        <StyledArrow />
        <StyledTitle>Back to dashboard</StyledTitle>
      </StyledLink>
    );
  };

  return (
    <ROBase>
      <Stack sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <Stack
          style={{
            width: '240px',
            backgroundColor: theme.palette.secondary.light,
            borderRight: '1px solid rgba(0, 0, 0, 0.2)',
          }}>
          <OncologyStepper steps={createPatientSteps}>
            <BackToDashboard />
          </OncologyStepper>
        </Stack>
        <MiddleContainerCol>
          <CreatePatientBasicForm physiciansRefData={physiciansRefData.internalOncologists} />
        </MiddleContainerCol>
        <RightContainerCol />
      </Stack>
    </ROBase>
  );
};

export default CreatePatient;
