// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { LoadingSpinner } from 'shared-components/components';
import { GET_CAREPLANS_QUERY } from './graphql/queries';
import ROCarePlanDashboard from './CarePlanDashboard';
import ROCarePlanViewerDashboard from './CarePlanViewerDashboard';
import { TABLE_LABELS } from './ROCarePlanTable';
import { CurrentAppConfig } from '../../Careplan/AppConfig';
import { useErrorModalContext } from 'op-contexts';
import { RoDashboardTabs } from 'op-enums';
import { MultiSelectOptions } from './MultiSelectWithSearch';
import { DateRangeValue } from './DateRange';
import { deepCopyFunction } from 'shared-components/utils/CustomHooks';
import { Dayjs } from 'dayjs';
const { CAREPLANS, CAREPLAN_VIEWER } = RoDashboardTabs;

const LIST_OPTIONS = {
  LATERALITY: 'lateralitySite',
};

interface DropDownObject {
  id: string;
  name: string;
  checked: boolean;
}

interface OrderingObject {
  colName: string;
  ascending: boolean;
}

interface Props extends WithApolloClient<{}>, RouteComponentProps {
  isActive?: boolean;
  tabId: string;
  statusFilter: string[];
  careplanFilter: string[];
}

interface DashboardState {
  currentPage: number;
  searchString: string;
  submittedBy: MultiSelectOptions[];
  dateRange: DateRangeValue;
  planStatus: string[];
  careplanFilterSelected: string[];
  priority: string;
  ordering: OrderingObject;
  statusDropDownData: DropDownObject[];
  orderString: string;
  orderingDirection: string;
  orderType: string;
  careplanDropDownData: DropDownObject[];
}

const getRODashboardConfig = (tabId: string) => {
  const roDash = CurrentAppConfig.RadiationDashboard;
  return tabId === CAREPLAN_VIEWER ? roDash.careplanViewerDashboard : roDash.careplanDashboard;
};

const DashboardComponents = {
  [CAREPLANS]: ROCarePlanDashboard,
  [CAREPLAN_VIEWER]: ROCarePlanViewerDashboard,
};

const ROCarePlanDashboardApollo = (props: Props): JSX.Element => {
  const { careplanFilter, statusFilter, tabId } = props;

  const config = getRODashboardConfig(tabId);

  const INIT_STATE = {
    currentPage: 1,
    searchString: '',
    submittedBy: [],
    dateRange: { startingDate: undefined, endingDate: undefined },
    planStatus: config.careplanStatusFilters.map((e) => e.name),
    careplanFilterSelected: config.careplanFilters.filter((e) => e.checked).map((item) => item.name),
    priority: '',
    ordering: { colName: '', ascending: true },
    orderString: '',
    orderingDirection: 'reverse',
    orderType: 'date',
    statusDropDownData: JSON.parse(JSON.stringify(config.careplanStatusFilters)),
    careplanDropDownData: JSON.parse(JSON.stringify(config.careplanFilters)),
  };
  const [state, setState] = useState<DashboardState>(INIT_STATE);

  const changePage = (pageNumber: number): void => {
    setState({ ...state, currentPage: pageNumber });
  };

  const changeSearchString = (searchString: string): void => {
    setState({ ...state, searchString: searchString });
  };

  const initialOrderingStatus = deepCopyFunction(config.careplanStatusFilters);
  const initialPlanStatus = deepCopyFunction(config.careplanStatusFilters.map((e) => e.name));

  const initialOrderingCareplan = deepCopyFunction(config.careplanFilters);
  const initialCareplanStatus = deepCopyFunction(
    config.careplanFilters.filter((e) => e.checked).map((item) => item.name),
  );

  const initDropDownFilter = (setFilter: string[], dropDownOptionsList: DropDownObject[]): DropDownObject[] => {
    if (setFilter.length === 0) {
      return dropDownOptionsList;
    }

    const newDropDownData = dropDownOptionsList.map((item: DropDownObject) => {
      item.checked = setFilter.indexOf(item.name) !== -1 ? true : false;
      return item;
    });
    return newDropDownData;
  };

  useEffect(() => {
    const initStatusFilter = (setStatues: string[], statusList: string[]): string[] => {
      return setStatues.length === 0 ? statusList : setStatues;
    };
    setState({
      ...state,
      statusDropDownData: initDropDownFilter(statusFilter, initialOrderingStatus),
      planStatus: initStatusFilter(statusFilter, initialPlanStatus),
      careplanDropDownData: initDropDownFilter(careplanFilter, initialOrderingCareplan),
      careplanFilterSelected: initStatusFilter(careplanFilter, initialCareplanStatus).filter((item) =>
        Boolean(item !== ''),
      ),
    });
  }, [careplanFilter, statusFilter]);

  const changePlanStatus = (ids: string[], checked: boolean) => {
    const planStatus: any = [];
    let newStatusDropDownData: DropDownObject[] = state.statusDropDownData;

    ids.forEach((id) => {
      newStatusDropDownData = newStatusDropDownData.map((chkObj: any) =>
        chkObj.id === id ? Object.assign(chkObj, { checked: checked }) : chkObj,
      );
    });

    newStatusDropDownData.forEach((statusData: DropDownObject) => {
      if (statusData.checked === true) {
        planStatus.push(statusData.id);
      }
    });
    setState({ ...state, statusDropDownData: [...newStatusDropDownData], planStatus: planStatus });
  };

  const changeCareplanFilter = (ids: string[], checked: boolean) => {
    const careplanFilterSelected: any = [];
    let newCareplanFilter = state.careplanDropDownData;
    ids.forEach((id) => {
      newCareplanFilter = newCareplanFilter.map((chkObj: any) =>
        chkObj.id === id ? Object.assign(chkObj, { checked: checked }) : chkObj,
      );
    });
    newCareplanFilter.forEach((careplanFilter) => {
      if (careplanFilter.checked === true) {
        careplanFilterSelected.push(careplanFilter.id);
      }
    });
    setState({ ...state, careplanDropDownData: [...newCareplanFilter], careplanFilterSelected });
  };

  const changeSubmittedBy = (submittedByFilters: MultiSelectOptions[]): void => {
    setState({ ...state, submittedBy: submittedByFilters });
  };

  const changeDateRange = (newStartDate?: Dayjs, newEndDate?: Dayjs): void => {
    setState({ ...state, dateRange: { startingDate: newStartDate, endingDate: newEndDate } });
  };

  const changePriority = (priority: string) => {
    setState({ ...state, priority: priority });
  };

  const changeOrdering = (colName: string) => {
    const newOrdering = state.ordering;
    const newOrderString = '';
    let orderingDirection = 'reverse';
    let orderType = '';
    if (colName === newOrdering.colName) {
      newOrdering.ascending = !newOrdering.ascending;
    } else {
      newOrdering.colName = colName;
      newOrdering.ascending = true;
      newOrdering.ascending = true;
    }
    if (newOrdering.colName === TABLE_LABELS.PATIENT_NAME) {
      orderingDirection = newOrdering.ascending ? 'forward' : 'reverse';
      orderType = 'patient';
    } else if ([TABLE_LABELS.LAST_MODIFIED, TABLE_LABELS.LAST_SUBMITTED].includes(newOrdering.colName)) {
      orderingDirection = newOrdering.ascending ? 'forward' : 'reverse';
      orderType = 'date';
    }
    setState({
      ...state,
      ordering: newOrdering,
      orderString: newOrderString,
      orderingDirection: orderingDirection,
      orderType: orderType,
    });
  };

  const openCareplan = (careplanUrl: string) => {
    props.history.push(careplanUrl);
  };

  const openDocuments = (documentUrl: string) => {
    window.open(documentUrl, '_blank');
  };
  const {
    statusDropDownData,
    currentPage,
    searchString,
    planStatus,
    priority,
    orderString,
    careplanDropDownData,
    careplanFilterSelected,
  } = state;
  const variables = {
    page: currentPage,
    search: searchString,
    status: planStatus,
    priority: priority,
    ordering: orderString,
    careplanChanged: careplanFilterSelected,
    listCategory: [LIST_OPTIONS.LATERALITY],
    onlyForRequestingPractitioner: props.tabId !== CAREPLAN_VIEWER,
    submittedBy: state.submittedBy.map((practitioner: MultiSelectOptions) => {
      return practitioner.value;
    }),
    onOrBeforeDate: state.dateRange.endingDate?.toISOString().split('T')[0],
    onOrAfterDate: state.dateRange.startingDate?.toISOString().split('T')[0],
  };
  const { setError } = useErrorModalContext();
  return (
    <Query query={GET_CAREPLANS_QUERY} variables={variables} fetchPolicy="network-only">
      {({ loading, data, error }: any): JSX.Element => {
        if (error) return setError();
        if (loading)
          return (
            <LoadingSpinner
              loadingText={'Loading Careplan Selection'}
              subtitle={'Please wait while we set things up for you'}
            />
          );

        if (data && data.careplans && data.careplansCount) {
          const DashComp = DashboardComponents[tabId as keyof typeof DashboardComponents];
          return (
            <div>
              <DashComp
                onRowClick={tabId === CAREPLANS ? openCareplan : openDocuments}
                careplans={data.careplans}
                searchTerm={searchString}
                statusDropDownData={statusDropDownData}
                selectedStatus={planStatus}
                totalPages={data.careplansCount}
                currentPage={currentPage}
                onPageChange={changePage}
                onSearchStringChange={(searchString: string) => changeSearchString(searchString)}
                onPlanStatusChange={changePlanStatus}
                onPriorityChange={changePriority}
                onOrderingChange={changeOrdering}
                orderingDirection={state.orderingDirection}
                orderType={state.orderType}
                careplanDropDownData={careplanDropDownData}
                onCareplanFilterChange={changeCareplanFilter}
                careplanFilterSelected={careplanFilterSelected}
                onSubmittedByChange={changeSubmittedBy}
                onDateRangeChange={changeDateRange}
                dateRange={state.dateRange}
                submittedByFilters={state.submittedBy}
              />
            </div>
          );
        }
        return <div></div>;
      }}
    </Query>
  );
};

const routedComponent = withRouter(withApollo<Props>(ROCarePlanDashboardApollo));
export default routedComponent;
