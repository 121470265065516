import React, { useCallback, useState } from 'react';
import { Button } from 'gc-ui';
import CycleMenu from './CycleMenu';
import { CareplanCycle } from '../interfaces';
import { useMutation } from '@apollo/client';
import { UPDATE_CAREPLAN_CYCLE, CareplanCycleAction } from '../Queries';
import useCyclePdf from './useCyclePdf';
import { Tooltip } from '@mui/material';

interface ApproveCycleProps {
  careplanId: string;
  cycleInfo: CareplanCycle;
  disabled: boolean;
  isApproved: boolean;
  tooltip?: string;
}

const ApproveCycle = (props: ApproveCycleProps): JSX.Element => {
  const [savePdfCallback, printPdfCallback] = useCyclePdf();
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateCycle] = useMutation(UPDATE_CAREPLAN_CYCLE, { refetchQueries: ['latestCycles', 'patient'] });
  const handleViewPdf = useCallback(() => {}, []);
  const handleApprove = () => {
    setIsUpdating(true);
    updateCycle({
      variables: {
        careplanId: props.careplanId,
        cycleId: props.cycleInfo.cycleId,
        action: CareplanCycleAction.APPROVE,
      },
    }).finally(() => {
      setIsUpdating(false);
    });
  };
  return (
    <>
      {props.isApproved ? (
        <CycleMenu
          handlePrintPdf={() => printPdfCallback(props.cycleInfo.cycleId)}
          handleSavePdf={() => {
            savePdfCallback(props.cycleInfo.cycleId);
          }}
          handleViewPdf={handleViewPdf}
          cycleInfo={props.cycleInfo}
        />
      ) : (
        <Button
          disabled={props.disabled}
          data-cy="prescription-approve-cycle-btn"
          mode={'contained'}
          size={'small'}
          onClick={handleApprove}
          busy={isUpdating}>
          <Tooltip
            componentsProps={{
              tooltip: { sx: { bgcolor: 'black', minWidth: '250px', fontSize: '0.9rem' } },
              arrow: { sx: { color: 'black' } },
            }}
            title={props.tooltip || ''}
            arrow>
            <div id="approve-cycle-btn">Approve cycle</div>
          </Tooltip>
        </Button>
      )}
    </>
  );
};

export default ApproveCycle;
