import { useQuery } from '@apollo/client';
import moment from 'moment';
import { isUs } from 'op-utils/helpers';
import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import { GraphQLID } from 'shared-components/interfaces';
import { calculateMomentAge } from 'shared-components/utils';
import './PatientCardModalHeader.scss';
import { GET_PATIENT_PROFILE } from './PatientCardQueries';

interface IPatientCardModalHeaderProps {
  patientId: GraphQLID;
}

const PatientCardModalHeader = (props: IPatientCardModalHeaderProps): JSX.Element => {
  const { patientId } = props;
  const componentId = 'patient-card-modal-header';

  const { data: patientProfileData, loading: patientProfileLoading } = useQuery(GET_PATIENT_PROFILE, {
    variables: { patientId },
  });

  const patient = useMemo<any>(() => {
    if (patientProfileData) {
      return patientProfileData.patient;
    }
  }, [patientProfileData]);

  const formattedBirthdayAge = useMemo<string>(() => {
    // TODO Extract to a util.ts and reuse in similar cases
    if (patient?.dob) {
      const formattedBirthday = moment(patient.dob).format(`${isUs() ? 'MMM D' : 'D MMM'} YYYY`) ?? '-';
      const calculatedAge = calculateMomentAge(moment(patient.dob)) ?? '-';
      return `${formattedBirthday} (age ${calculatedAge})`;
    } else return '-';
  }, [patient]);

  if (patientProfileLoading)
    return (
      <div style={{ textAlign: 'center' }}>
        <div className="spinner-border" role="status">
          <div className="sr-only" />
        </div>
      </div>
    );

  return (
    <Grid id={componentId} container>
      <Grid item className="patient-avatar-wrapper" xs={4}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div id="patient-avatar">
            <PhotoWidget url={patient?.photoUrl ?? ''} enableOverlay size={'sm'} />
          </div>
          <div id="patient-text-info">
            <div id="patient-identifications" className="">
              <div id="patient-fullname">{patient?.fullName ?? ''}</div>
              <div id="patient-id-emr">
                <div id="patient-id">{`ID: ${patient?.ida ?? ''}`}</div>
                <div id="patient-emr">{`EMR: ${patient?.emrInstance ?? ''}`}</div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item id="patient-dob-age" xs={4}>
        {formattedBirthdayAge}
      </Grid>
      <Grid item id="patient-address" xs={4}>
        {patient?.address?.formattedAddress ?? '-'}
      </Grid>
    </Grid>
  );
};

export default PatientCardModalHeader;
