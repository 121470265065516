import React from 'react';
import { styled } from '@mui/system';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { TextareaAutosize } from '@mui/material';

interface Props {
  placeholder: string;
  value: string;
  onBlur: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  invalid?: boolean;
  warning?: boolean;
  name: string;
  rows?: string;
  maxLength?: string;
  alternateStyle?: boolean;
  disabled?: boolean;
}

export const StyledTextArea = styled(TextareaAutosize)<Props>`
  border: 1px solid
    ${(props) =>
      props.warning
        ? props.theme.palette.warning.dark
        : props.invalid
        ? props.theme.palette.error.main
        : props.theme.palette.secondary.main};
  width: 100%;
  resize: ${({ alternateStyle }: Props): string => (alternateStyle ? 'none' : 'both')};
  padding: 8px;
  font-size: ${({ alternateStyle }: Props): string => (alternateStyle ? getRemSize(14) : getRemSize(16))};
  border-radius: ${({ alternateStyle }: Props): string => (alternateStyle ? '4px' : '')};
  height: ${({ alternateStyle }: Props): string => (alternateStyle ? '40px' : '')};
  font-size: 14px;
`;

const TextArea = ({
  value,
  onBlur,
  placeholder,
  invalid,
  warning,
  name,
  onChange,
  rows,
  maxLength,
  alternateStyle = false,
  disabled = false,
}: Props): JSX.Element => {
  const styledProps = {
    $alternateStyle: alternateStyle,
  };
  return (
    <StyledTextArea
      value={value || ''}
      invalid={invalid}
      warning={warning}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={(event: any) => {
        event.target.setAttribute('autocomplete', 'random-text');
      }}
      data-test-id={`${name}-textarea`}
      rows={rows}
      maxLength={maxLength as any}
      disabled={disabled}
      {...styledProps}
    />
  );
};

export default TextArea;
