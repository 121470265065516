// eslint-disable-next-line no-use-before-define
import React from 'react';
import { FormBuilderComponentProps } from '../../FormBuilderInterfaces';
import { fieldMeetsConditions } from '../../common';
import '../FBFormControl.scss';
import TextField from '@mui/material/TextField';

export default function FBTextArea(props: FormBuilderComponentProps): JSX.Element {
  const { component, formikProps, handleUpdate } = props; //options,
  return (
    <TextField
      key={component.field.name}
      id={component.field.name}
      name={component.field.name}
      rows={(component.modifiers && component.modifiers['rows']) || 3}
      value={formikProps.values[component.field.name] ?? ''}
      onChange={(event): void => {
        handleUpdate(formikProps, component, event.target.value);
      }}
      onBlur={formikProps.handleBlur}
      disabled={fieldMeetsConditions(formikProps.values, component.disabled || component.readOnly, false)}
      placeholder={component.placeholder ?? ''}
      fullWidth
      multiline
    />
  );
}
