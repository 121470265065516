// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'gc-ui';

import './CovidSubmit.scss';

import { ButtonType } from 'shared-components/enums';
import { GCButton } from 'shared-components/components/FormFields';

import { COVID_SUBMIT } from './constants';

interface Props {
  isOpen: boolean;
  isProd?: boolean;
  isPSO?: boolean;
  dismissFunction: () => void;
  submitForm: () => void;
  submitText: string;
  isComplete: boolean;
  continueNextForm: boolean;
}

interface State {
  locked: boolean;
}

class CovidSubmit extends Component<Props, State> {
  public static defaultProps = {
    isPSO: true,
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      locked: false,
    };
  }

  public render(): JSX.Element {
    const { dismissFunction, isOpen, submitForm, continueNextForm } = this.props;
    const { locked } = this.state;

    const headerText = this.props.isComplete
      ? continueNextForm
        ? COVID_SUBMIT.HEADER_TEXT_CONTINUE
        : COVID_SUBMIT.HEADER_TEXT_EXIT
      : COVID_SUBMIT.HEADER_TEXT_ERROR;

    const buttonText = this.props.isComplete
      ? continueNextForm
        ? COVID_SUBMIT.SUBMIT_AND_CONTINUE
        : COVID_SUBMIT.SUBMIT_AND_EXIT
      : COVID_SUBMIT.FIX_ERRORS;

    return (
      <div className="covid-submit-modal">
        <Modal id="covid-submit-modal" open={isOpen} onClose={dismissFunction} className="modal-gc submit-modal">
          <ModalHeader toggle={dismissFunction}>{headerText}</ModalHeader>
          <ModalBody>
            {this.props.isComplete ? this.renderChangeOfCircumstance() : this.renderErrorText()}
            <div className="modal-submit-buttons">
              <GCButton
                name="submitRegistration"
                title={buttonText}
                disabled={locked}
                onClick={(e): void => {
                  e.preventDefault();
                  {
                    this.props.isComplete ? submitForm() : dismissFunction();
                  }
                }}
                type={ButtonType.GREEN}
              />
              <GCButton
                name="cancel"
                title="Cancel"
                onClick={(e): void => {
                  e.preventDefault();
                  dismissFunction();
                }}
                type={ButtonType.WHITE}
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }

  private renderErrorText = (): JSX.Element => {
    return (
      <Fragment>
        <div className="modal-submit-body">
          <div className="modal-text">{COVID_SUBMIT.MODAL_TEXT_ERROR}</div>
        </div>
      </Fragment>
    );
  };

  private renderChangeOfCircumstance = (): JSX.Element => {
    return (
      <Fragment>
        <div className="modal-submit-body">
          <div className="green-bold">{COVID_SUBMIT.MODAL_TITLE}</div>
          <div className="modal-text">{COVID_SUBMIT.MODAL_TEXT}</div>
        </div>
      </Fragment>
    );
  };
}

export default CovidSubmit;
