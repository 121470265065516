// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import './ROConstraintField.scss';
import { Button, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { BaseTextField, BaseSelect, ROTwoValueTextField } from 'shared-components/components/FormFields';

interface FieldValue {
  dosetoPrefixOperator: string | undefined;
  firstValue: string | undefined;
  firstValueUnit: string | undefined;
  operator: string | undefined;
  secondValue: string[] | undefined;
  secondValueUnit: string | undefined;
}

interface ROConstraintRowProps {
  id: string;
  label: string;
  placeholder?: string;
  value: FieldValue;
  required?: boolean;
  disabled?: boolean;
  onChange?: (index: number, value: FieldValue) => void;
  onBlur?: () => void;
  block?: boolean;
  index: number;
  isVolumeData: boolean;
  length: number;
  addButton?: boolean;
  percentage?: boolean;
  removeConstraint: () => void;
}

interface Props {
  id: string;
  label: string;
  placeholder?: string;
  value: FieldValue[];
  required?: boolean;
  readOnly?: boolean;
  onChange?: (index: number, value: FieldValue) => void;
  onBlur?: () => void;
  block?: boolean;
  validated?: boolean | undefined;
  invalid?: boolean;
  percentage?: boolean;
  isVolumeData: boolean;

  addConstraint?: () => void;
  removeConstraint?: (item: any) => void;
}

const WIDTH = '79px';
const LESS_THAN_OR_EQUAL = '≤';

const CONSTRAINT_OPERATOR_OPTIONS = [
  { value: LESS_THAN_OR_EQUAL, label: LESS_THAN_OR_EQUAL },
  { value: '<', label: '<' },
];

const VOL_FIRST_VALUE_UNIT_OPTIONS = [
  { value: 'Gy', label: 'Gy' },
  { value: '%', label: '%' },
];

const VOL_SECOND_VALUE_UNIT_OPTIONS = [
  { value: '%', label: '%' },
  { value: 'cc', label: 'cc' },
];

const D_FIRST_VALUE_UNIT_OPTIONS = [
  { value: '%', label: '%' },
  { value: 'cc', label: 'cc' },
];

const D_SECOND_VALUE_UNIT_OPTIONS = [
  { value: 'Gy', label: 'Gy' },
  { value: '%', label: '%' },
];

const ROConstraintRow = (props: ROConstraintRowProps) => {
  const { id, required } = props;
  const label: string = props.index === 0 ? props.label : ' ';

  const objValue = props.value;
  const firstValueUnit = props.value.firstValueUnit || 'Gy';
  const secondValueUnit = props.value.secondValueUnit || '%';
  const operator = props.value.operator || LESS_THAN_OR_EQUAL;
  const isVolumeData = props.isVolumeData;
  const firstValueUnitOptions = isVolumeData ? VOL_FIRST_VALUE_UNIT_OPTIONS : D_FIRST_VALUE_UNIT_OPTIONS;
  const secondValueUnitOptions = isVolumeData ? VOL_SECOND_VALUE_UNIT_OPTIONS : D_SECOND_VALUE_UNIT_OPTIONS;
  return (
    <Stack>
      <Stack direction="row" sx={{ paddingBottom: '8px' }} alignItems="center">
        <Stack
          direction="row"
          sx={{ width: '200px', minWidth: '200px' }}
          justifyContent="space-between"
          alignItems="center">
          <Typography data-testid={`label-${id}`} variant="h6">
            {required ? `${label}*` : label}
          </Typography>
          {!isVolumeData && (
            <BaseTextField
              size="small"
              id={`${id}-prefixOperator`}
              select
              value={objValue.dosetoPrefixOperator}
              disabled={props.disabled}
              sx={{ width: '4rem', marginRight: '4px' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (props.onChange) {
                  objValue.dosetoPrefixOperator = event.target.value;
                  props.onChange(props.index, objValue);
                }
              }}>
              <MenuItem value="">blank</MenuItem>
              <MenuItem value=">">{'>'}</MenuItem>
            </BaseTextField>
          )}
        </Stack>
        <Grid container>
          <Grid item container xs={10}>
            <Grid item container gap="4px">
              <Grid item xs={2}>
                <BaseTextField
                  id={`firstValue-${id}`}
                  type="number"
                  value={props.value.firstValue || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    const value = e.target.value;
                    if (props.onChange) {
                      objValue.firstValue = value || undefined;
                      props.onChange(props.index, objValue);
                    }
                  }}
                  disabled={props.disabled}
                  onBlur={(): void => {
                    if (props.onBlur) props.onBlur();
                  }}
                />
              </Grid>
              <Grid item>
                <BaseSelect
                  id={`firstValueUnit-${id}`}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    if (props.onChange) {
                      objValue.firstValueUnit = value;
                      props.onChange(props.index, objValue);
                    }
                  }}
                  value={firstValueUnit}
                  options={firstValueUnitOptions}
                  disabled={props.disabled}
                  sx={{ width: WIDTH }}
                />
              </Grid>
              <Grid item>
                <div className="oar-operator">
                  <div className="oar-vertical-border-line"></div>
                  <BaseSelect
                    id={`oarOperator-${id}`}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value;
                      if (props.onChange) {
                        objValue.operator = value;
                        props.onChange(props.index, objValue);
                      }
                    }}
                    value={operator}
                    options={CONSTRAINT_OPERATOR_OPTIONS}
                    disabled={props.disabled}
                    sx={{ width: WIDTH }}
                  />
                  <div className="oar-vertical-border-line"></div>
                </div>
              </Grid>
              <Grid item md={3}>
                <ROTwoValueTextField
                  id={`secondValue-${id}`}
                  value={props.value.secondValue || ['']}
                  onChange={(values: string[]): void => {
                    if (props.onChange) {
                      objValue.secondValue = values || undefined;
                      props.onChange(props.index, objValue);
                    }
                  }}
                  onBlur={(): void => {
                    if (props.onBlur) props.onBlur();
                  }}
                  readOnly={props.disabled}
                />
              </Grid>
              <Grid item>
                <BaseSelect
                  id={`secondValueUnit-${id}`}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    if (props.onChange) {
                      objValue.secondValueUnit = value;
                      props.onChange(props.index, objValue);
                    }
                  }}
                  value={secondValueUnit}
                  options={secondValueUnitOptions}
                  disabled={props.disabled}
                  sx={{ width: WIDTH }}
                />
              </Grid>
            </Grid>
          </Grid>
          {props.length > 1 && (
            <Grid item xs={2}>
              <Button className={'remove-btn'} onClick={(): void => props.removeConstraint()}>
                <RemoveCircleOutline color="primary" sx={{ marginRight: '4px' }} />
                <div>Remove</div>
              </Button>
            </Grid>
          )}
          {props.length <= 1 && (
            <Grid item xs={2}>
              <div className="remove-btn"></div>
            </Grid>
          )}
        </Grid>
      </Stack>
    </Stack>
  );
};

class ROConstraintField extends Component<Props, { numberOfRows: number[]; value: [] }> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      numberOfRows: this.props.value ? this.props.value.map((value, index): number => index) : [0],
      value: [],
    };
  }

  private onAddConstraint(): void {
    this.props.addConstraint && this.props.addConstraint();
  }

  private onRemoveConstraint(item: any): void {
    this.props.removeConstraint && this.props.removeConstraint(item);
  }

  public render(): JSX.Element {
    const { id, label } = this.props;

    const valueList =
      this.props.value && this.props.value.length
        ? this.props.value
        : [
            {
              firstValue: undefined,
              firstValueUnit: this.props.isVolumeData ? 'Gy' : '%',
              operator: LESS_THAN_OR_EQUAL,
              secondValue: undefined,
              secondValueUnit: this.props.isVolumeData ? '%' : 'Gy',
            },
          ];

    return (
      <div className="ro-constraint-field">
        {valueList.map((item: any, index: number): any => (
          <ROConstraintRow
            key={label + index}
            removeConstraint={() => this.onRemoveConstraint(index)}
            index={index}
            value={item}
            id={`${id}-${index}`}
            placeholder={this.props.placeholder}
            label={this.props.label}
            onChange={this.props.onChange}
            disabled={this.props.readOnly}
            length={valueList.length}
            isVolumeData={this.props.isVolumeData}
          />
        ))}
        <Stack
          id="imagingAdd"
          sx={{ marginLeft: '200px', width: 'fit-content' }}
          onClick={(): void => {
            if (!this.props.readOnly)
              this.setState({ numberOfRows: [...this.state.numberOfRows, this.state.numberOfRows.length] });
          }}>
          <Button
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={(): void => {
              this.onAddConstraint();
            }}
            disabled={this.props.readOnly}>
            <AddCircleOutline color="primary" sx={{ marginRight: '4px' }} />
            Add constraint
          </Button>
        </Stack>
      </div>
    );
  }
}

export default ROConstraintField;
