// eslint-disable-next-line no-use-before-define
import { Form, Formik, FormikProps } from 'formik';
import { FormContainer } from 'op-components';
import { RegistrationContext } from 'op-contexts';
import { CheckboxField } from 'shared-components/components/UIFormComponents';
import { HelperMessage } from 'shared-components/components';
import React, { useState, useContext } from 'react';
import { supportedSize } from 'shared-components/StyledComponents/breakpoints';
import { useHistory } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import {
  CONTINUE_BUTTON_TEXT,
  HeaderSubTitle,
  HeaderTitle,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  sharedFormContainerProps,
} from '../Helper';
import { PatientInformationNotice, RegistrationFormUSIProps } from '../interfaces';
import { FIELDS } from './constants';
import { styled } from '@mui/system';

const INVALID =
  'Information notice must be read and acknowledged to process registration. Please contact your local Centre with any questions on this.';

interface InformationNoticeContent {
  id: string;
  informationNotice: string;
}

interface InformationNoticeFormIProps extends RegistrationFormUSIProps {
  patient: PatientInformationNotice;
  informationNoticeContent: InformationNoticeContent;
}

const BoldTitle = styled('div')`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  padding: 8px 0px;
`;

const Content = styled('div')`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.palette.text.primary}
  flex: none;
  order: 2;
  flex-grow: 0;
`;

const InfoNoticeWrapper = styled('div')`
  height: 416px;
  overflow-y: auto;
  border: 2px solid ${(props) => props.theme.palette.secondary.main};
  background-color: ${(props) => props.theme.palette.secondary.light};
  padding: 16px;
  margin-bottom: 32px;
  font-size: 1rem;
  line-height: 1.5;
  ul {
    list-style-type: disc;
  }

  @media all and (max-width: ${supportedSize.tablet}px) {
    height: 300px;
    margin-top: 10px;
  }
`;

const InformationNoticeForm = (props: InformationNoticeFormIProps): JSX.Element => {
  const { updateField, patient, informationNoticeContent, handleShowSaveExitConfirm, previousPageLink, isPso } = props;
  const registrationContext = useContext(RegistrationContext);
  const history = useHistory();

  const goToSummary = registrationContext?.registrationSummaryVisited;
  const [invalid, setIsInvalid] = useState(false);
  return (
    <Formik
      initialValues={patient}
      onSubmit={(values) => {
        // Don't set invalid if user is a pso
        if (!values.informationNoticeAccepted && !isPso) {
          setIsInvalid(true);
          return;
        }
        setIsInvalid(false);

        history.push(registrationPath(patient?.id, goToSummary ? 'summary' : 'basic'));
      }}>
      {({ submitForm, setFieldValue, values }: FormikProps<PatientInformationNotice>) => {
        const sharedProps = sharedFormContainerProps(FIELDS.FORM_HEADING.TITLE, 1, previousPageLink);

        const formContainerProps = {
          ...sharedProps,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          continueDisabled: invalid,
          handleShowSaveExitConfirm,
        };

        const updateCheckbox = (name: string, value: boolean) => {
          setIsInvalid(!value);
          updateField(name, value);
          setFieldValue(name, value);
        };

        return (
          <FormContainer
            {...formContainerProps}
            submitForm={submitForm}
            handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
              void event;
            }}>
            <Form>
              <HeaderTitle data-test-id="rego-page-title">{FIELDS.FORM_HEADING.TITLE}</HeaderTitle>
              <HeaderSubTitle>* = required field</HeaderSubTitle>
              <InfoNoticeWrapper id={'information-notice'}>
                <Markdown>{informationNoticeContent.informationNotice}</Markdown>
              </InfoNoticeWrapper>
              <CheckboxField
                label={FIELDS.NOTICE.CONTENT}
                name={FIELDS.NOTICE.NAME}
                handleMutation={(value: boolean) => updateCheckbox(FIELDS.NOTICE.NAME, value)}
                field={{ value: values.informationNoticeAccepted }}
                width="100%"
                invalid={!!invalid}
                disabled={!!values.informationNoticeAccepted}
                marginTop="30px"
                marginBottom={invalid ? '10px' : '30px'}
                required={true}
              />
              {invalid && (
                <HelperMessage fieldName={FIELDS.NOTICE.NAME} fieldText={INVALID} helperType="error" fontSize={14} />
              )}
              <BoldTitle>Additional options</BoldTitle>

              <Content>
                If you do not provide your consent to the following options, this will not affect our ability to provide
                you with healthcare. You are free to withdraw your consent at any time by contacting us.
              </Content>
              <br />
              <br />
              <Content>{FIELDS.ALLIED_HEALTH.SUB_TITLE}</Content>
              <span>
                <CheckboxField
                  label={FIELDS.ALLIED_HEALTH.CONTENT}
                  name={FIELDS.ALLIED_HEALTH.NAME}
                  handleMutation={(value: boolean) => updateField(FIELDS.ALLIED_HEALTH.NAME, value)}
                  field={{ value: values.informationNoticeAlliedHealthReceiveUpdates }}
                  width="100%"
                  marginTop="30px"
                  marginBottom="30px"
                />
              </span>
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default InformationNoticeForm;
