import React from 'react';
import { Modal } from 'shared-components/components/UIFormComponents';
import { styled } from '@mui/system';

const ConsultationDiscussionText = styled('div')`
  margin-bottom: 16px;
  line-height: 24px;
`;

const ModalContentSection = styled('div')`
  padding-bottom: 16px;
`;

const ModalContentSectionHeader = styled('div')`
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
`;

interface Props {
  title: string;
  isOpen: boolean;
  setIsOpen: any;
  discard?: boolean;
}

const ModalConsultationDiscussion = ({ title, isOpen, setIsOpen }: Props): JSX.Element => {
  const consultationDiscussionText = (
    <ConsultationDiscussionText>
      <ModalContentSection>
        <ModalContentSectionHeader>Patient concerns/needs</ModalContentSectionHeader>
        <div>
          <div>
            Discuss items raised in distress/PROMs screening, main worries patient has about getting through treatment:
          </div>
          <div>e.g. being able to continue working, managing pain, body being exposed to strangers</div>
        </div>
      </ModalContentSection>

      <ModalContentSection>
        <ModalContentSectionHeader>Goal of care</ModalContentSectionHeader>
        <div>
          <div>
            Based on addressing concerns raised: e.g. body being exposed to strangers, goal of care = maintaining
            dignity by minimising amount and duration of body exposure;{' '}
          </div>
          <div>managing pain, goal of care = pain control to enable maintenance of ADLs; </div>
          <div>being able to continue working, goal of care = maintaining independence</div>
        </div>
      </ModalContentSection>

      <ModalContentSection>
        <ModalContentSectionHeader>Plan of care</ModalContentSectionHeader>
        <div>
          <div>Action required to achieve goal of care and how you will assess impact of action taken: </div>
          <div>
            e.g. maintaining dignity = handover to RTs use sheets/covers and only expose required visualisation points
            for set up;{' '}
          </div>
          <div>Pain control = pain regime, keep pain diary, escalate if any significant changes, review in 2 days</div>
        </div>
      </ModalContentSection>
    </ConsultationDiscussionText>
  );

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      title={title}
      headerClasses="pb-0"
      dismissFunction={() => setIsOpen(false)}
      rightButtonText="Close"
      rightButtonWidth={'170px'}
      rightButtonHandleClick={(e: any) => {
        e.preventDefault();
        setIsOpen(false);
      }}>
      {consultationDiscussionText}
    </Modal>
  );
};

export default ModalConsultationDiscussion;
