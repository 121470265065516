import { FormikProps, FormikValues } from 'formik';
import { SelectOptionType } from 'shared-components/components/FormFields';

export interface OptionInterface {
  value: string;
  text: string;
  label: string;
  options?: OptionInterface[]; // provision to accommodate FilteredDropdown when it comes along
}

export type FormValueType = any;
export type FormBuilderSubValidatorType = boolean | Record<string, string>;

export type FormBuilderValidatorType = boolean | Record<string, FormBuilderSubValidatorType>;

export type FormBuilderValueType = Date | number | string | boolean | SelectOptionType;
export interface ListDataInterface {
  [key: string]: OptionInterface[];
}

export interface TooltipInterface {
  header: string;
  body: string;
}

export interface FormBuilderField {
  listDataCategory: string;
  formControl: string;
  name: string;
  dataType: string;
}

export interface BaseComponentConfig {
  id: string; // is this from database or generated by graphQL?
  type: string;
  field: FormBuilderField;
  visible: Record<string, FormBuilderValidatorType> | boolean; //TODO EVE-10691 this should be under a generic "state" perhaps
  validators: Record<string, boolean | Record<string, FormBuilderValidatorType>>;
  modifiers: Record<string, any>;
  tooltip: TooltipInterface; //TODO EVE-10691 this should be under modifiers...
  disabled?: Record<string, FormBuilderValidatorType> | boolean; //TODO EVE-10691 this should be under a generic "state" perhaps
  readOnly?: Record<string, FormBuilderValidatorType> | boolean; //TODO EVE-10691 this should be under a generic "state" perhaps
  variant?: string;
  label?: string;
  value?: string | boolean;
  placeholder?: string;
}
export interface FlatComponentConfig extends BaseComponentConfig {
  parentId?: string;
}

export interface FormBuilderComponentConfig extends BaseComponentConfig {
  subComponents?: FormBuilderComponentConfig[];
}

export interface FormBuilderComponentProps {
  formikProps: FormikProps<FormikValues>;
  component: FormBuilderComponentConfig;
  options: ListDataInterface;
  handleUpdate: (
    formikProps: FormikProps<any>,
    component: FormBuilderComponentConfig,
    value: FormBuilderValueType,
  ) => void;
  parent?: FormBuilderComponentConfig;
  parentIsVisible?: boolean;
  children?: React.ReactNode;
}

export interface RawValue {
  field: string;
  value: any;
}

export interface FormBuilderConfigInterface {
  name: string;
  title?: string;
  components: BaseComponentConfig[];
}

export interface NestedFormInstanceInterface {
  config: FormBuilderConfigInterface;
  components: FormBuilderComponentConfig[];
  values: Record<string, FormBuilderValueType>;
  options: Record<string, OptionInterface[]>;
}

export interface FlatFormConfigInterface {
  formConfig: FormBuilderConfigInterface;
  values: RawValue[];
  listData: any[];
}

export enum FormBuilderFields {
  Button = 'Button',
  DatePicker = 'DatePicker',
  ToggleButtons = 'ToggleButtons',
  DropDownSearchField = 'DropdownSearchField',
  Input = 'Input',
  TextArea = 'TextArea',
  Checkbox = 'Checkbox',
  Dropdown = 'Dropdown',
  Radio = 'Radio',
  CheckboxTile = 'CheckboxTile',
}

export enum FormBuilderComponentTypes {
  Container = 'Container',
  Field = 'Field',
}

export enum FormBuilderContainers {
  FormGroup = 'formGroup',
}
