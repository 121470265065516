import { Stack, useTheme } from '@mui/material';

interface Props {
  children: React.ReactNode;
  title: string;
  topBorderColor: string;
}

const SidePanelItem = ({ title, topBorderColor, children }: Props): JSX.Element => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        display: 'block',
        border: `1px solid ${theme.palette.secondary.main}`,
        margin: '10px 0 10px',
        borderRadius: '7px',
        width: '100%',
      }}>
      <Stack
        data-testid="side-panel-title"
        sx={{
          borderTop: `4px solid ${topBorderColor}`,
          borderRadius: '6px 6px 0 0',
          padding: '10px',
          borderBottom: `1px solid ${theme.palette.secondary.main}`,
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '20px',
          margin: '-1px',
        }}>
        {title}
      </Stack>
      {children}
    </Stack>
  );
};

export default SidePanelItem;
