import React from 'react';
import { styled } from '@mui/material';

export interface ReviewInfo {
  icon: React.FC;
  label: string;
}

interface Props {
  sectionTitle: string;
  reviewInfo?: ReviewInfo[];
}

interface ReviewInfoProps {
  reviewInfo?: ReviewInfo[];
}

const BannerContainer = styled('div')<ReviewInfoProps>`
  margin-top: 24px;
  margin-left: 32px;
  border-bottom: ${(props) => (props.reviewInfo ? `1px solid ${props.theme.palette.secondary.main}` : '')};
  padding-bottom: 20px;
  width: 800px;
`;

const BannerTitle = styled('div')`
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 28px;
  margin-bottom: 20px;
`;

const InfoContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const ReportStatus = styled('div')`
  display: flex;
  flex-direction: row;
  & p {
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:last-child {
    margin-left: 20px;
  }
`;

const ReviewBanner = ({ sectionTitle, reviewInfo }: Props): JSX.Element => {
  return (
    <BannerContainer>
      <BannerTitle>{sectionTitle}</BannerTitle>
      <InfoContainer>
        {reviewInfo &&
          reviewInfo.map((info: ReviewInfo, idx: number): JSX.Element => {
            return (
              <ReportStatus key={idx}>
                <info.icon />
                <p>{info.label}</p>
              </ReportStatus>
            );
          })}
      </InfoContainer>
    </BannerContainer>
  );
};

export default ReviewBanner;
