// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import classNames from 'classnames';
import { Region } from 'shared-components/enums';
import { styled } from '@mui/system';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

interface Props {
  children?: React.ReactNode;
  isClinex?: boolean;
}

const StyledSidePanel = styled('div')`
  margin-left: auto;
  width: 320px;
  min-width: 320px;
  height: 100%;
  padding: 15px 15px 60px;
  border-left: 1px solid ${(props) => props.theme.palette.secondary.main};
  overflow: auto;
  -ms-overflow-style: none;
  background: $white;
`;

class ROSidePanel extends Component<Props> {
  public render(): JSX.Element {
    const { children, isClinex } = this.props;
    const isInUK = REACT_APP_REGION === Region.UK;
    return (
      <StyledSidePanel
        className={classNames('ro-side-panel', {
          'add-profile-bar-offset': isInUK,
          clinex: isClinex,
        })}>
        {children}
      </StyledSidePanel>
    );
  }
}

export default ROSidePanel;
