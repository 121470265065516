import { useTheme } from '@mui/material';
import React from 'react';
import { AccountCircleOutlined as AccountCircleOutlinedIcon } from '@mui/icons-material';

interface Props {
  image?: HTMLImageElement;
  inHeader?: boolean;
}

const ROProfilePhoto = ({ image, inHeader }: Props): JSX.Element => {
  // TODO: Replace empty return with image file when data available
  if (image) {
    return <></>;
  }

  if (inHeader) {
    return <AccountCircleOutlinedIcon htmlColor="white" />;
  }
  const theme = useTheme();
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill={theme.palette.secondary.main} />
      <path
        style={{ transform: 'scale(1.75)' }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0003 8C13.7903 8 12.0003 9.79 12.0003 12C12.0003 14.21 13.7903 16 16.0003 16C18.2103 16 20.0003 14.21 20.0003 12C20.0003 9.79 18.2103 8 16.0003 8ZM18.0003 12C18.0003 10.9 17.1003 9.99996 16.0003 9.99996C14.9003 9.99996 14.0003 10.9 14.0003 12C14.0003 13.1 14.9003 14 16.0003 14C17.1003 14 18.0003 13.1 18.0003 12ZM22.0003 22C21.8003 21.29 18.7003 20 16.0003 20C13.3103 20 10.2303 21.28 10.0003 22H22.0003ZM8.00037 22C8.00037 19.34 13.3304 18 16.0004 18C18.6704 18 24.0004 19.34 24.0004 22V24H8.00037V22Z"
        fill="white"
      />
    </svg>
  );
};

export default ROProfilePhoto;
