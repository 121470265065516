// eslint-disable-next-line no-use-before-define
import React from 'react';

import { ButtonType } from 'shared-components/enums';
import { GCButton } from 'shared-components/components/FormComponents';
import { GenesisCareLogoMobileGreenText } from 'shared-components/images';

import './LoginBox.scss';

interface Props {
  header: string;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const LoginBox: React.FC<Props> = ({ header, handleClick }): JSX.Element => {
  return (
    <div className="login-box">
      <GenesisCareLogoMobileGreenText />
      <div className="login-header">{header}</div>
      <GCButton onClick={handleClick} name="login" type={ButtonType.GREEN} inputType="button" title={'Log in'} />
    </div>
  );
};

export default LoginBox;
