// eslint-disable-next-line no-use-before-define
import React, { useContext } from 'react';
import { useFormikContext, Field, getIn } from 'formik';

import { getOptionByValue } from '../Utils';
import { ROAutocomplete, ROToggleButtons } from 'shared-components/components/FormFields';
import { isFieldLowCertainty, LOW_CERTAINTY, ClinexTooltip } from '../Clinex/helpers';
import { ClinexContext } from '../Clinex/context';
import {
  POSITIVE_NEGATIVE_UNKNOWN_OPTIONS,
  SMOKING_HISTORY_OPTIONS,
  ECOG_PERFORMANCE_STATUS_OPTIONS,
  WEIGHT_LOSS_OPTIONS,
  VALUE_REQUIRED,
} from './helpers';
import { IntakePageProps, FormikValues } from './Interface';
import { SelectOptionType } from 'shared-components/components/FormFields';
import ClinexFieldWrapper from '../Clinex/ClinexFieldWrapper';

const ROPatientDiagnosisIntakeLungPage = ({ updateIntakeHandler }: IntakePageProps): JSX.Element => {
  const { values, touched } = useFormikContext<FormikValues>();
  const clinexContexts = useContext(ClinexContext);
  const { clinexData } = clinexContexts;

  if (values.intake?.lung) {
    const { ecogPerformanceStatus, weightLoss, smokingHistory, egfr, alk, pdl1 } = values.intake.lung;
    return (
      <>
        <Field
          name="ecogPerformanceStatus"
          component={ROAutocomplete}
          id="ecogPerformanceStatus"
          fieldlabel={'ECOG Performance Status'}
          options={ECOG_PERFORMANCE_STATUS_OPTIONS}
          value={getOptionByValue(ECOG_PERFORMANCE_STATUS_OPTIONS, ecogPerformanceStatus ? ecogPerformanceStatus : '')}
          required
          inputProps={{
            error: getIn(touched, 'ecogPerformanceStatus') && !ecogPerformanceStatus,
            helperText: VALUE_REQUIRED,
          }}
          onChange={(selectedOption: SelectOptionType): void => {
            updateIntakeHandler({
              ecogPerformanceStatus: selectedOption ? selectedOption.value : '',
            });
          }}
        />
        <Field
          name="weightLoss"
          component={ROToggleButtons}
          id="weightLoss"
          fieldlabel={'Weight Loss'}
          options={WEIGHT_LOSS_OPTIONS}
          value={weightLoss}
          error={getIn(touched, 'weightLoss') && !weightLoss}
          required
          handleChange={(value: string): void => {
            updateIntakeHandler({ weightLoss: value });
          }}
        />
        <Field
          name="smokingHistory"
          component={ROToggleButtons}
          id="smokingHistory"
          fieldlabel={'Smoking History'}
          options={SMOKING_HISTORY_OPTIONS}
          value={smokingHistory}
          error={getIn(touched, 'smokingHistory') && !smokingHistory}
          required
          handleChange={(value: string): void => {
            updateIntakeHandler({ smokingHistory: value });
          }}
        />
        <ClinexFieldWrapper field="egfr">
          <ClinexTooltip clinexData={clinexData} clinexField={'egfr'} formValues={values.intake.lung}>
            <Field
              name="egfr"
              component={ROToggleButtons}
              id="egfr"
              fieldlabel="EGFR"
              options={POSITIVE_NEGATIVE_UNKNOWN_OPTIONS}
              value={egfr}
              error={getIn(touched, 'egfr') && !egfr}
              required
              handleChange={(value: string): void => {
                updateIntakeHandler({ egfr: value });
              }}
              helperText={!egfr ? VALUE_REQUIRED : LOW_CERTAINTY}
              warning={isFieldLowCertainty(clinexData, 'egfr', values)}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>
        <ClinexFieldWrapper field="alk">
          <ClinexTooltip clinexData={clinexData} clinexField={'alk'} formValues={values.intake.lung}>
            <Field
              name="alk"
              component={ROToggleButtons}
              id="alk"
              fieldlabel="ALK"
              options={POSITIVE_NEGATIVE_UNKNOWN_OPTIONS}
              value={alk}
              error={getIn(touched, 'alk') && !alk}
              required
              handleChange={(value: string): void => {
                updateIntakeHandler({ alk: value });
              }}
              helperText={!alk ? VALUE_REQUIRED : LOW_CERTAINTY}
              warning={isFieldLowCertainty(clinexData, 'alk', values)}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>
        <ClinexFieldWrapper field="pdl1">
          <ClinexTooltip clinexData={clinexData} clinexField={'pdl1'} formValues={values.intake.lung}>
            <Field
              name="pdl1"
              component={ROToggleButtons}
              id="pdl1"
              fieldlabel="PDL1"
              options={POSITIVE_NEGATIVE_UNKNOWN_OPTIONS}
              value={pdl1}
              error={getIn(touched, 'pdl1') && !pdl1}
              required
              handleChange={(value: string): void => {
                updateIntakeHandler({ pdl1: value });
              }}
              helperText={!pdl1 ? VALUE_REQUIRED : LOW_CERTAINTY}
              warning={isFieldLowCertainty(clinexData, 'pdl1', values)}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>
      </>
    );
  }
  return <> </>;
};

export default ROPatientDiagnosisIntakeLungPage;
