// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { GCButton } from 'shared-components/components/FormFields';
import { ButtonType } from 'shared-components/enums';
import { Box, Stack, Tooltip } from '@mui/material';
import { styled } from '@mui/system';

interface Props {
  onReset?: () => void;
  onBack?: () => void;
  onNext?: () => void;
  onAdditionLeft?: () => void;
  onAdditionLeftMouseEnter?: () => void;
  onAdditionLeftMouseLeave?: () => void;
  showAdditionLeftTooltip?: boolean;
  onAdditionRight?: () => void;
  resetText?: string;
  backText?: string;
  backButtonId?: string;
  nextText?: string;
  nextTextId?: string;
  additionLeftText?: string;
  additionLeftTextId?: string;
  additionRightText?: string;
  additionRightTextId?: string;
  nextDisabled?: boolean;
  backDisabled?: boolean;
  resetDisabled?: boolean;
  nextHidden?: boolean;
  resetHidden?: boolean;
  additionRightDisabled?: boolean;
  additionLeftDisabled?: boolean;
  nextLoading?: boolean;
  additionRightLoading?: boolean;
  backHidden?: boolean;
  additionalLeftTooltip?: string;
  backButtonTooltip?: string;
}

const StyledNavbarFooter = styled(Stack)`
  display: flex;
  flex-direction: row;
  height: 60px;
  border-top: 2px solid ${(props) => props.theme.palette.primary.main};
  padding: 12px 16px;
  background: white;
  transition: box-shadow 0.15s ease-in-out;
`;

class ROPatientCarePlanPageFooter extends Component<Props> {
  public render(): JSX.Element {
    const {
      onReset,
      onBack,
      onNext,
      onAdditionLeft,
      onAdditionRight,
      resetText,
      backText,
      backButtonId,
      backHidden,
      nextText,
      nextTextId,
      additionLeftText,
      additionLeftTextId,
      additionRightText,
      additionRightTextId,
      nextDisabled,
      backDisabled,
      resetDisabled,
      nextHidden,
      resetHidden,
      additionRightDisabled,
      additionLeftDisabled,
      nextLoading,
      additionRightLoading,
      additionalLeftTooltip,
      backButtonTooltip,
    } = this.props;
    return (
      <div className="gc-navbar-footer-container">
        <StyledNavbarFooter className="gc-navbar-footer" gap="4px">
          <div className="button-list">
            {!resetHidden && !!onReset && (
              <GCButton
                id={'gc-reset-button'}
                title={resetText || 'Reset page'}
                onClick={onReset}
                type={ButtonType.WHITE}
                disabled={resetDisabled}
              />
            )}
          </div>
          <div className="button-list float-right" style={{ gap: '8px' }}>
            {onAdditionLeft && (
              <Tooltip title={additionalLeftTooltip || ''} arrow>
                <Box sx={{ paddingRight: '16px' }}>
                  {/* This Box is necessary as Tooltip can't attach to GCButton */}
                  <GCButton
                    additionalId={'additional-left-parent'}
                    disabled={additionLeftDisabled}
                    title={additionLeftText || ''}
                    id={additionLeftTextId || 'additional-left-text'}
                    onClick={onAdditionLeft}
                    type={additionLeftDisabled ? ButtonType.DISABLED : ButtonType.WHITE}
                  />
                </Box>
              </Tooltip>
            )}
            {!backHidden && (
              <Tooltip title={backButtonTooltip} arrow>
                <Box>
                  {/* This Box is necessary as Tooltip can't attach to GCButton */}
                  <GCButton
                    id="footer-button-back"
                    additionalId={backButtonId}
                    title={backText || 'Back'}
                    onClick={onBack}
                    type={backDisabled ? ButtonType.DISABLED : ButtonType.WHITE}
                    disabled={backDisabled}
                  />
                </Box>
              </Tooltip>
            )}
            {!nextHidden && (
              <GCButton
                id={nextTextId || 'footer-button-continue'}
                title={nextText || 'Continue'}
                loading={nextLoading}
                onClick={onNext}
                type={nextDisabled ? ButtonType.DISABLED : ButtonType.GREEN}
                disabled={nextDisabled}
              />
            )}
            {!!onAdditionRight && (
              <GCButton
                title={additionRightText || ''}
                id={additionRightTextId}
                loading={additionRightLoading}
                onClick={onAdditionRight}
                disabled={additionRightDisabled}
                type={additionRightDisabled ? ButtonType.DISABLED : ButtonType.GREEN}
              />
            )}
          </div>
        </StyledNavbarFooter>
      </div>
    );
  }
}

export default ROPatientCarePlanPageFooter;
