import { ReferralType } from 'op-enums';
import { PractitionerSearch } from 'op-pages';
import { Modal, ModalBody, ModalHeader } from 'gc-ui';
import { PatientReferrerRego } from 'shared-components/interfaces';
import './ModalPractitionerSearch.scss';

const GP_HEADER_TITLE = 'General practitioner lookup';
const SPECIALIST_HEADER_TITLE = 'Referring specialist lookup';
interface ModalPractitionerSearchIProps {
  userInfo: PatientReferrerRego;
  header?: string;
  isOpen: boolean;
  referralType: ReferralType;
  dismissFunction: (status: boolean) => void;
  makeFieldsEditable: (referral: ReferralType) => void;
  disableFields: (referral: ReferralType) => void;
  logTime?: boolean;
  enableLogging?: boolean;
  popOverOpen?: boolean;
  popOverClose?: () => void;
}

const ModalPractitionerSearch = (props: ModalPractitionerSearchIProps): JSX.Element => {
  const {
    userInfo,
    isOpen,
    referralType,
    dismissFunction,
    makeFieldsEditable,
    disableFields,
    logTime = true,
    enableLogging = false,
    popOverOpen,
    popOverClose,
  } = props;

  const handleCancelModal = () => dismissFunction(true);
  const handleCloseModal = (status: boolean) => dismissFunction(status);

  return (
    <Modal open={isOpen} onClose={handleCancelModal} maxWidth="md" className="modal-gc-practitioner-lookup modal-gc">
      <ModalHeader toggle={handleCancelModal}>
        <div id="practitioner-search-title">
          {referralType === ReferralType.GP ? GP_HEADER_TITLE : SPECIALIST_HEADER_TITLE}
        </div>
      </ModalHeader>
      <ModalBody>
        <PractitionerSearch
          referralType={referralType}
          userInfo={userInfo}
          closeModal={handleCloseModal}
          makeFieldsEditable={makeFieldsEditable}
          disableFields={disableFields}
          logTime={logTime}
          enableLogging={enableLogging}
          popOverOpen={popOverOpen}
          popOverClose={popOverClose}
        />
      </ModalBody>
    </Modal>
  );
};

export default ModalPractitionerSearch;
