// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { styled } from '@mui/system';
import { ComposedModal } from 'gc-ui';
import { SimpleSummarySelectImage } from 'shared-components/images/ro_portal';

const StyleModalBody = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 48px;
  margin-right: 48px;
  gap: 8px;
`;

const StyledSimpleSummarySelectImage = styled(SimpleSummarySelectImage)`
  align-self: center;
  margin-top: 20px;
  margin-bottom: 24px;
  padding-botton: 24px;
`;

const StyledHeading = styled('h1')`
  font-style: normal;
  weigth: 500;
  font-size: 24px;
  line-height: 28px;
  && span {
    color: ${(props) => props.theme.palette.warning.dark};
  }
`;

const StyledParagraph = styled('p')`
  font-style: normal;
  weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
interface props {
  keepDefaultView: () => void;
  setSimpleView: () => void;
  cancelOnboarding: () => void;
}

const SimpleSummarySelectModal = (props: props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(true);
  const { keepDefaultView, setSimpleView, cancelOnboarding } = props;
  const onTryNewViewClick = () => {
    setSimpleView();
    setIsOpen(false);
  };
  const onKeepCurrentViewClick = () => {
    keepDefaultView();
    setIsOpen(false);
  };
  const onCancelModal = (e: any) => {
    if (e.currentTarget?.className?.includes('close')) {
      cancelOnboarding();
      setIsOpen(false);
    }
  };
  const ModalBody = () => (
    <StyleModalBody>
      <StyledSimpleSummarySelectImage />
      <StyledHeading>
        Try the <span>NEW</span> Patient Summary View
      </StyledHeading>
      <StyledParagraph>
        A simpler, more organised patient summary. More space for adding notes with a new dose site summary table.
      </StyledParagraph>
    </StyleModalBody>
  );
  return (
    <ComposedModal
      headerText=""
      isOpen={isOpen}
      width={'711px'}
      dismissFunction={onCancelModal}
      backdrop={'static'}
      secondaryRightButton={{
        buttonText: 'Keep current view',
        onClick: onKeepCurrentViewClick,
      }}
      primaryRightButton={{
        buttonText: "Try 'NEW' view",
        onClick: onTryNewViewClick,
      }}>
      <ModalBody />
    </ComposedModal>
  );
};

export default SimpleSummarySelectModal;
