import React from 'react';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { GCButton } from 'shared-components/components/FormFields';
import { GCButtonSize } from 'shared-components/enums';
import { ButtonType } from 'shared-components/enums';
import { DeviceUtilities } from 'shared-components/utils';

const FooterContainer = styled('div')`
  z-index: 1000;
  display: flex;
  background: white;
  border-top: 2px solid ${(props) => props.theme.palette.primary.dark};
  padding: 16px 24px;
  flex: 1;
  flex-grow: 0;
`;

const SaveContinueContainer = styled('div')`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  > * {
    margin-right: 10px;
  }
  > a {
    padding-right: 20px;
  }
`;

const SaveContinueContainerMobile = styled(SaveContinueContainer)`
  margin-right: auto;
`;

const ExitButton = styled(Link)`
  margin-right: 10px;
  text-decoration: none;

  .default-gc-button {
    width: inherit;
    min-width: 208px;
    max-width: 250px;
    padding: 0 20px;
  }
`;

interface Props {
  pageNumber?: number;
  backLink?: string;
  saveAndExitLink?: string;
  continueLink?: string;
  handleClickOnBack?: any;
  handleClickOnContinue?: any;
  submitForm?: () => void;
  showSaveAndExit?: boolean;
  isLocked?: boolean;
  isAmendment?: boolean;
  deleteModal: () => void;
  setDeleteModalIsOpen?: (value: boolean) => void;
  createAmendmentAndRedirect?: () => void;
  sidePanelWidth?: string;
  submitButtonText?: string;
  showDiscard: boolean;
  footerPosition?: string;
  isLoading?: boolean;
  continueDisabled?: boolean;
  isShowSaveExitConfirm?: boolean;
  handleShowSaveExitConfirm?: () => void;
}

const FormFooter = ({
  pageNumber,
  backLink,
  saveAndExitLink,
  continueLink,
  handleClickOnBack,
  handleClickOnContinue,
  submitForm,
  showSaveAndExit = true,
  isLocked,
  deleteModal,
  setDeleteModalIsOpen,
  createAmendmentAndRedirect,
  sidePanelWidth,
  submitButtonText = 'Submit',
  showDiscard = true,
  footerPosition = 'fixed',
  isLoading = false,
  continueDisabled = false,
  isShowSaveExitConfirm = false,
  handleShowSaveExitConfirm,
}: Props): JSX.Element => {
  const styledProps = {
    $sidePanelWidth: sidePanelWidth || '320px',
    $footerPosition: footerPosition,
  };
  const isMobile = DeviceUtilities.isMobileDevice();
  const ButtonContainer = isMobile ? SaveContinueContainerMobile : SaveContinueContainer;

  return (
    <FooterContainer {...styledProps} id="form-footer">
      <>
        {deleteModal()}
        {!isMobile && showSaveAndExit && !isShowSaveExitConfirm && (
          <ExitButton to={saveAndExitLink ? saveAndExitLink : ''}>
            <GCButton
              dataTestId="exit-button"
              title={isLocked ? 'Exit to patient summary' : 'Save and Exit'}
              type={ButtonType.WHITE}
              buttonSize={isMobile ? GCButtonSize.SMALL : undefined}
            />
          </ExitButton>
        )}
        {!isMobile && showSaveAndExit && isShowSaveExitConfirm && handleShowSaveExitConfirm && (
          <GCButton
            dataTestId="save-and-exit"
            title={'Save and Exit'}
            type={ButtonType.WHITE}
            onClick={() => handleShowSaveExitConfirm()}
            buttonSize={isMobile ? GCButtonSize.SMALL : undefined}
          />
        )}
        {isLocked && createAmendmentAndRedirect && (
          <GCButton
            dataTestId="amendAssessment"
            title={'Amend assessment'}
            type={ButtonType.WHITE}
            onClick={() => createAmendmentAndRedirect()}
          />
        )}
        {!isLocked && showDiscard && setDeleteModalIsOpen && (
          <GCButton
            dataTestId="discard"
            title={'Discard'}
            type={ButtonType.WHITE}
            onClick={() => setDeleteModalIsOpen(true)}
          />
        )}
        {!isLocked && (
          <ButtonContainer>
            {pageNumber && pageNumber > 1 && (
              <Link to={backLink ? backLink : ''} style={{ textDecoration: 'unset' }}>
                <GCButton
                  dataTestId="Back"
                  title={'Back'}
                  type={ButtonType.WHITE}
                  onClick={handleClickOnBack}
                  buttonSize={isMobile ? GCButtonSize.SMALL : undefined}
                />
              </Link>
            )}
            {continueLink && (
              <Link to={continueLink ? continueLink : ''} style={{ textDecoration: 'unset' }}>
                <GCButton
                  dataTestId="Continue"
                  title={'Continue'}
                  type={ButtonType.GREEN}
                  onClick={handleClickOnContinue}
                  buttonSize={isMobile ? GCButtonSize.SMALL : undefined}
                />
              </Link>
            )}
            {submitForm && (
              <GCButton
                dataTestId="Submit"
                title={submitButtonText}
                type={ButtonType.GREEN}
                disabled={continueDisabled}
                loading={isLoading}
                onClick={() => submitForm()}
                buttonSize={isMobile ? GCButtonSize.SMALL : undefined}
              />
            )}
          </ButtonContainer>
        )}
      </>
    </FooterContainer>
  );
};

export default FormFooter;
