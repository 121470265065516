// eslint-disable-next-line no-use-before-define
import React from 'react';
import FormRow from '../FormRow/FormRow';
import { BaseTextField, ROHelperText } from 'shared-components/components/FormFields';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import Stack from '@mui/system/Stack';
import { Button, Divider, IconButton } from '@mui/material';

const getPlaceholder = (index: number, length: number, disabled?: boolean): string => {
  if (disabled) return '';
  if (length === 1) return '';
  if (index === 0) return 'High';
  if (index === length - 1) return 'Low';
  return 'Mid';
};

interface NoListProps {
  id: string;
  value: any;
  required?: boolean;
  disabled?: boolean;
  index: number;
  addButton?: boolean;
  data: any[];
  error?: boolean;
  warning?: boolean;
  info?: boolean;
  onChange?: (value: any[]) => void;
  onBlur?: () => void;
  removeColumn?: (index: any) => void;
}

const ROMultiColumn = ({
  disabled,
  index,
  id,
  value,
  data,
  error,
  onChange,
  removeColumn,
  ...rest
}: NoListProps): JSX.Element => {
  const placeholder = getPlaceholder(index, data.length, disabled);
  const showRemoveButton = !disabled && removeColumn && index !== 0;

  return (
    <BaseTextField
      id={`${id}-${index}`}
      type="number"
      fullWidth
      placeholder={placeholder}
      value={value || ''}
      neverShowHelper={true}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        if (onChange && value.slice(0, 1) !== '-') {
          const updated = data;
          updated[index] = value !== '' ? value : null;
          onChange(updated);
        }
      }}
      disabled={disabled}
      InputProps={{
        endAdornment: showRemoveButton && (
          <IconButton onClick={() => removeColumn(index)} disableRipple data-testid={`remove-dose-${index}`}>
            <RemoveCircleOutline color="primary" style={{ marginTop: '0px' }} />
          </IconButton>
        ),
        sx: { height: '37px' },
      }}
      error={error && !disabled}
      {...rest}
    />
  );
};

interface Props {
  id: string;
  fieldlabel: string;
  value: any[];
  required?: boolean;
  error?: boolean;
  warning?: boolean;
  info?: boolean;
  helperText?: string;
  disabled?: boolean;
  addColumn?: () => void;
  onChange?: (value: any[]) => void;
  onBlur?: () => void;
  removeColumn?: (index: any) => void;
}

const RODoseField = ({
  id,
  fieldlabel,
  value,
  required,
  error,
  warning,
  info,
  helperText,
  disabled,
  addColumn,
  ...rest
}: Props): JSX.Element => {
  const valueList = value ? value : [null];

  return (
    <FormRow fieldlabel={fieldlabel} required={required} id={id}>
      <Stack flex={1} maxWidth={'400px'}>
        <Stack direction="row" gap={1} id={id} divider={<Divider orientation="vertical" flexItem />}>
          {valueList.map((item, index) => (
            <ROMultiColumn
              key={fieldlabel + index}
              index={index}
              value={item}
              id={id}
              disabled={disabled}
              data={valueList}
              error={error}
              warning={warning}
              info={info}
              {...rest}
            />
          ))}
        </Stack>
        <Stack alignItems={'flex-start'}>
          {!disabled && valueList.length < 4 && addColumn && (
            <Button
              id="imagingAdd"
              size="large"
              startIcon={<AddCircleOutline color="primary" />}
              onClick={addColumn}
              data-testid="add-dose-level-btn"
              sx={{ textTransform: 'none' }}>
              Add dose level
            </Button>
          )}
          <Stack sx={{ marginTop: '4px' }}>
            <ROHelperText id={id} error={error} warning={warning} info={info} helperText={helperText} />
          </Stack>
        </Stack>
      </Stack>
    </FormRow>
  );
};

export default RODoseField;
