// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import { MoreInfoText, PageTitle } from 'op-components';
import { HADate } from 'op-pages/OP/HealthAssessment/HASharedComponents';
import { HA_BIOLOGICAL_QUERY, UPDATE_HA_BIOLOGICAL } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import { HABiologicalItem } from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import { BIOLOGICAL_RULES as VALIDATION_RULES } from 'op-utils/HealthAssessmentValidation/HealthAssessmentValidation';
import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { FreeTextField, SectionField, SegmentedInput } from 'shared-components/components/FormFields';
import { ListData } from 'shared-components/interfaces';
import { SegementedInputGender, SegementedInputYesNoUnsure } from 'shared-components/utils';
import { styled } from '@mui/system';
import validate from 'validate.js';
import { BIOLOGICAL_FIELDS, FEMALE, MALE, YES } from '../constants';

const initialViewedFields = ['biologicalGender'];
interface Props {
  validateOnLoad: boolean;
}

/*
  Adds initial fields to the viewed fields list. Used for validating when navigating back to this page after
  viewing it already
*/
const initialValidationFields = (staticFields: string[]): string[] => {
  const validationFields = staticFields;
  return validationFields;
};

const GenderTitle = styled('div')`
  font-family: GenesisCare Sans, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 36px;
  color ${(props) => props.theme.palette.secondary.dark};
  margin-bottom: 20px;
`;

const getValidationRules = (viewedFields: Set<string>) => {
  const validationObject = {};
  viewedFields.forEach((field: string) => {
    // @ts-ignore
    validationObject[field] = VALIDATION_RULES[field];
  });
  return validationObject;
};

/*
  Runs validation on the current data against the currently viewed fields
*/
const runValidation = (data: HABiologicalItem, viewedFields: Set<string>) => {
  const validationRules = getValidationRules(viewedFields);
  const options = { fullMessages: false };
  return validate(data, validationRules, options);
};

const HABiological = (props: Props): JSX.Element => {
  const { validateOnLoad } = props;
  const { patientId } = useParams<{ patientId: string }>();

  const [viewedFields, setViewedFields] = useState(new Set<string>());
  const [validationObject, setValidationObject] = useState(Object);

  const { data, error } = useQuery(HA_BIOLOGICAL_QUERY, {
    variables: { patientId },
    onCompleted: () => {
      if (!validateOnLoad) return; // Only run the below if returning to the page
      const newViewedFields = new Set(initialValidationFields(initialViewedFields));
      setViewedFields(newViewedFields);
      setValidationObject(runValidation(data?.healthAssessment, newViewedFields));
    },
  });

  const [updateHaBiological] = useMutation(UPDATE_HA_BIOLOGICAL, {
    onCompleted: (data: { updateHaBiological: { healthAssessment: HABiologicalItem } }) => {
      setValidationObject(runValidation(data?.updateHaBiological?.healthAssessment, viewedFields));
    },
  });

  const resetFields = (gender: any) => {
    switch (gender) {
      case FEMALE:
        // reset male fields
        updateHaBiological({
          variables: {
            haId,
            patientId,
            psaDateRawMonth: '',
            psaDateRawDay: '',
            psaDateRawYear: '',
            psaScore: '',
            psaLocation: '',
            stdMale: '',
          },
        });
        break;
      case MALE:
        // reset female fields
        updateHaBiological({
          variables: {
            haId,
            patientId,
            periodAge: '',
            abnormalMammograms: '',
            breastBiopsy: '',
            periodWhen: '',
            menopause: '',
            menopauseAge: '',
            biologicalPregnant: '',
            pregnantCount: '',
            pregnantDeliveries: '',
            pregnantAge: '',
            breastfeed: '',
            hormones: '',
            hormonesDuration: '',
            braSize: '',
            nippleDischarge: '',
            nippleDischargeExplain: '',
            vaginalBleeding: '',
            vaginalBleedingExplain: '',
            vaginalDischarge: '',
            vaginalDischargeExplain: '',
            stdFemale: '',
            papSmearDateRawMonth: '',
            papSmearDateRawDay: '',
            papSmearDateRawYear: '',
            mammogramDateRawMonth: '',
            mammogramDateRawDay: '',
            mammogramDateRawYear: '',
          },
        });
        break;
      default:
        // uncertain selected. clear all fields
        updateHaBiological({
          variables: {
            haId,
            patientId,
            periodAge: '',
            abnormalMammograms: '',
            breastBiopsy: '',
            periodWhen: '',
            menopause: '',
            menopauseAge: '',
            biologicalPregnant: '',
            pregnantCount: '',
            pregnantDeliveries: '',
            pregnantAge: '',
            breastfeed: '',
            hormones: '',
            hormonesDuration: '',
            braSize: '',
            nippleDischarge: '',
            nippleDischargeExplain: '',
            vaginalBleeding: '',
            vaginalBleedingExplain: '',
            vaginalDischarge: '',
            vaginalDischargeExplain: '',
            stdFemale: '',
            papSmearDateRawMonth: '',
            papSmearDateRawDay: '',
            papSmearDateRawYear: '',
            mammogramDateRawMonth: '',
            mammogramDateRawDay: '',
            mammogramDateRawYear: '',
            psaDateRawMonth: '',
            psaDateRawDay: '',
            psaDateRawYear: '',
            psaScore: '',
            psaLocation: '',
            stdMale: '',
          },
        });
    }
  };

  if (error || !data?.healthAssessment) return <></>;

  const healthAssessment = data.healthAssessment;
  const haId = healthAssessment.id;

  const updateField = (field: string, value: string | number) => {
    updateHaBiological({ variables: { haId, patientId, [field]: value } });

    if (field === 'biologicalGender') {
      resetFields(value);
    }
  };

  return (
    <>
      <PageTitle title={'Medical history: 3'} idPrefix="ha" />
      <div id="ha-fields" className="ha-medical-history-biological">
        <SectionField htmlFor={BIOLOGICAL_FIELDS.GENDER.NAME} title={BIOLOGICAL_FIELDS.GENDER.TITLE}>
          <SegmentedInput
            fieldName={BIOLOGICAL_FIELDS.GENDER.NAME}
            options={SegementedInputGender}
            defaultSelected={healthAssessment.biologicalGender}
            itemSelected={(selectedItem): void => {
              const selectedValue = (selectedItem as ListData).id;
              updateField('biologicalGender', selectedValue);
            }}
          />
        </SectionField>
        {healthAssessment.biologicalGender === FEMALE && (
          <div key={'genderFemale'}>
            <GenderTitle>Female History</GenderTitle>

            <SectionField htmlFor={BIOLOGICAL_FIELDS.PERIOD_AGE.NAME} title={BIOLOGICAL_FIELDS.PERIOD_AGE.TITLE}>
              <FreeTextField
                inputKey={healthAssessment.periodAge}
                inputName="periodAge"
                inputType="number"
                defaultValue={healthAssessment.periodAge}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateField('periodAge', e.target.value);
                }}
                errors={validationObject?.periodAge}
              />
            </SectionField>
            <SectionField
              htmlFor={BIOLOGICAL_FIELDS.ABNORMAL_MAMMOGRAMS.NAME}
              title={BIOLOGICAL_FIELDS.ABNORMAL_MAMMOGRAMS.TITLE}>
              <SegmentedInput
                fieldName={BIOLOGICAL_FIELDS.ABNORMAL_MAMMOGRAMS.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={healthAssessment.abnormalMammograms}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  updateField('abnormalMammograms', selectedValue);
                }}
                errors={validationObject?.abnormalMammograms}
              />
            </SectionField>
            <SectionField htmlFor={BIOLOGICAL_FIELDS.BREAST_BIOPSY.NAME} title={BIOLOGICAL_FIELDS.BREAST_BIOPSY.TITLE}>
              <SegmentedInput
                fieldName={BIOLOGICAL_FIELDS.BREAST_BIOPSY.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={healthAssessment.breastBiopsy}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  updateField('breastBiopsy', selectedValue);
                }}
                errors={validationObject?.breastBiopsy}
              />
            </SectionField>
            <SectionField htmlFor={BIOLOGICAL_FIELDS.PERIOD_WHEN.NAME} title={BIOLOGICAL_FIELDS.PERIOD_WHEN.TITLE}>
              <MoreInfoText moreInfoText={BIOLOGICAL_FIELDS.PERIOD_WHEN.MORE_INFO}>
                <FreeTextField
                  inputKey={healthAssessment.periodWhen}
                  inputName="periodWhen"
                  inputType="text"
                  defaultValue={healthAssessment.periodWhen}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    updateField('periodWhen', e.target.value);
                  }}
                  errors={validationObject?.periodWhen}
                />
              </MoreInfoText>
            </SectionField>

            <SectionField htmlFor={BIOLOGICAL_FIELDS.MENOPAUSE.NAME} title={BIOLOGICAL_FIELDS.MENOPAUSE.TITLE}>
              <SegmentedInput
                fieldName={BIOLOGICAL_FIELDS.MENOPAUSE.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={healthAssessment.menopause}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  updateField('menopause', selectedValue);
                }}
                errors={validationObject?.menopause}
              />
            </SectionField>
            {healthAssessment.menopause === YES && (
              <SectionField
                htmlFor={BIOLOGICAL_FIELDS.MENOPAUSE_AGE.NAME}
                title={BIOLOGICAL_FIELDS.MENOPAUSE_AGE.TITLE}>
                <FreeTextField
                  inputKey={healthAssessment.menopauseAge}
                  inputName="menopauseAge"
                  inputType="number"
                  defaultValue={healthAssessment.menopauseAge}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    updateField('menopauseAge', e.target.value);
                  }}
                  errors={validationObject?.menopauseAge}
                />
              </SectionField>
            )}

            <SectionField htmlFor={BIOLOGICAL_FIELDS.PREGNANT.NAME} title={BIOLOGICAL_FIELDS.PREGNANT.TITLE}>
              <SegmentedInput
                fieldName={BIOLOGICAL_FIELDS.PREGNANT.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={healthAssessment.biologicalPregnant}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  updateField('biologicalPregnant', selectedValue);
                }}
                errors={validationObject?.biologicalPregnant}
              />
            </SectionField>
            <SectionField
              htmlFor={BIOLOGICAL_FIELDS.PREGNANT_COUNT.NAME}
              title={BIOLOGICAL_FIELDS.PREGNANT_COUNT.TITLE}>
              <FreeTextField
                inputKey={healthAssessment.pregnantCount}
                inputName="pregnantCount"
                inputType="number"
                defaultValue={healthAssessment.pregnantCount}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateField('pregnantCount', e.target.value);
                }}
                errors={validationObject?.pregnantCount}
              />
            </SectionField>
            <SectionField
              htmlFor={BIOLOGICAL_FIELDS.PREGNANT_DELIVERIES.NAME}
              title={BIOLOGICAL_FIELDS.PREGNANT_DELIVERIES.TITLE}>
              <FreeTextField
                inputKey={healthAssessment.pregnantDeliveries}
                inputName="pregnantDeliveries"
                inputType="number"
                defaultValue={healthAssessment.pregnantDeliveries}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateField('pregnantDeliveries', e.target.value);
                }}
                errors={validationObject?.pregnantDeliveries}
              />
            </SectionField>
            <SectionField htmlFor={BIOLOGICAL_FIELDS.PREGNANT_AGE.NAME} title={BIOLOGICAL_FIELDS.PREGNANT_AGE.TITLE}>
              <FreeTextField
                inputKey={healthAssessment.pregnantAge}
                inputName="pregnantAge"
                inputType="number"
                defaultValue={healthAssessment.pregnantAge}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateField('pregnantAge', e.target.value);
                }}
                errors={validationObject?.pregnantAge}
              />
            </SectionField>

            <SectionField htmlFor={BIOLOGICAL_FIELDS.BREASTFEED.NAME} title={BIOLOGICAL_FIELDS.BREASTFEED.TITLE}>
              <SegmentedInput
                fieldName={BIOLOGICAL_FIELDS.BREASTFEED.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={healthAssessment.breastfeed}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  updateField('breastfeed', selectedValue);
                }}
                errors={validationObject?.breastfeed}
              />
            </SectionField>
            <SectionField htmlFor={BIOLOGICAL_FIELDS.HORMONES.NAME} title={BIOLOGICAL_FIELDS.HORMONES.TITLE}>
              <SegmentedInput
                fieldName={BIOLOGICAL_FIELDS.HORMONES.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={healthAssessment.hormones}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  updateField('hormones', selectedValue);
                }}
                errors={validationObject?.hormones}
              />
            </SectionField>
            {healthAssessment.hormones === YES && (
              <SectionField
                htmlFor={BIOLOGICAL_FIELDS.HORMONES_DURATION.NAME}
                title={BIOLOGICAL_FIELDS.HORMONES_DURATION.TITLE}>
                <FreeTextField
                  inputKey={healthAssessment.hormonesDuration}
                  inputName="hormonesDuration"
                  inputType="text"
                  defaultValue={healthAssessment.hormonesDuration}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    updateField('hormonesDuration', e.target.value);
                  }}
                  errors={validationObject?.hormonesDuration}
                />
              </SectionField>
            )}

            <SectionField htmlFor={BIOLOGICAL_FIELDS.BRA_SIZE.NAME} title={BIOLOGICAL_FIELDS.BRA_SIZE.TITLE}>
              <FreeTextField
                inputKey={healthAssessment.braSize}
                inputName="braSize"
                inputType="text"
                defaultValue={healthAssessment.braSize}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateField('braSize', e.target.value);
                }}
                errors={validationObject?.braSize}
              />
            </SectionField>

            <SectionField
              htmlFor={BIOLOGICAL_FIELDS.NIPPLE_DISCHARGE.NAME}
              title={BIOLOGICAL_FIELDS.NIPPLE_DISCHARGE.TITLE}>
              <SegmentedInput
                fieldName={BIOLOGICAL_FIELDS.NIPPLE_DISCHARGE.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={healthAssessment.nippleDischarge}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  updateField('nippleDischarge', selectedValue);
                }}
                errors={validationObject?.nippleDischarge}
              />
            </SectionField>
            {healthAssessment.nippleDischarge === YES && (
              <SectionField
                htmlFor={BIOLOGICAL_FIELDS.NIPPLE_DISCHARGE_EXPLAIN.NAME}
                title={BIOLOGICAL_FIELDS.NIPPLE_DISCHARGE_EXPLAIN.TITLE}>
                <FreeTextField
                  inputKey={healthAssessment.nippleDischargeExplain}
                  inputName="nippleDischargeExplain"
                  inputType="text"
                  defaultValue={healthAssessment.nippleDischargeExplain}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    updateField('nippleDischargeExplain', e.target.value);
                  }}
                  errors={validationObject?.nippleDischargeExplain}
                />
              </SectionField>
            )}

            <SectionField
              htmlFor={BIOLOGICAL_FIELDS.VAGINAL_BLEEDING.NAME}
              title={BIOLOGICAL_FIELDS.VAGINAL_BLEEDING.TITLE}>
              <SegmentedInput
                fieldName={BIOLOGICAL_FIELDS.VAGINAL_BLEEDING.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={healthAssessment.vaginalBleeding}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  updateField('vaginalBleeding', selectedValue);
                }}
                errors={validationObject?.vaginalBleeding}
              />
            </SectionField>

            {healthAssessment.vaginalBleeding === YES && (
              <SectionField
                htmlFor={BIOLOGICAL_FIELDS.VAGINAL_BLEEDING_EXPLAIN.NAME}
                title={BIOLOGICAL_FIELDS.VAGINAL_BLEEDING_EXPLAIN.TITLE}>
                <FreeTextField
                  inputKey={healthAssessment.vaginalBleedingExplain}
                  inputName="vaginalBleedingExplain"
                  inputType="text"
                  defaultValue={healthAssessment.vaginalBleedingExplain}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    updateField('vaginalBleedingExplain', e.target.value);
                  }}
                  errors={validationObject?.vaginalBleedingExplain}
                />
              </SectionField>
            )}

            <SectionField
              htmlFor={BIOLOGICAL_FIELDS.VAGINAL_DISCHARGE.NAME}
              title={BIOLOGICAL_FIELDS.VAGINAL_DISCHARGE.TITLE}>
              <SegmentedInput
                fieldName={BIOLOGICAL_FIELDS.VAGINAL_DISCHARGE.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={healthAssessment.vaginalDischarge}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  updateField('vaginalDischarge', selectedValue);
                }}
                errors={validationObject?.vaginalDischarge}
              />
            </SectionField>
            {healthAssessment.vaginalDischarge === YES && (
              <SectionField
                htmlFor={BIOLOGICAL_FIELDS.VAGINAL_DISCHARGE_EXPLAIN.NAME}
                title={BIOLOGICAL_FIELDS.VAGINAL_DISCHARGE_EXPLAIN.TITLE}>
                <FreeTextField
                  inputKey={healthAssessment.vaginalDischargeExplain}
                  inputName="vaginalDischargeExplain"
                  inputType="text"
                  defaultValue={healthAssessment.vaginalDischargeExplain}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    updateField('vaginalDischargeExplain', e.target.value);
                  }}
                  errors={validationObject?.vaginalDischargeExplain}
                />
              </SectionField>
            )}

            <SectionField htmlFor={BIOLOGICAL_FIELDS.STD_FEMALE.NAME} title={BIOLOGICAL_FIELDS.STD_FEMALE.TITLE}>
              <SegmentedInput
                fieldName={BIOLOGICAL_FIELDS.STD_FEMALE.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={healthAssessment.stdFemale}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  updateField('stdFemale', selectedValue);
                }}
                errors={validationObject?.stdFemale}
              />
            </SectionField>

            <SectionField
              htmlFor={BIOLOGICAL_FIELDS.PAP_SMEAR_DATE.NAME}
              title={BIOLOGICAL_FIELDS.PAP_SMEAR_DATE.TITLE}>
              <MoreInfoText moreInfoText={BIOLOGICAL_FIELDS.PAP_SMEAR_DATE.MORE_INFO}>
                <HADate
                  dayValue={healthAssessment.papSmearDateRawDay}
                  dayField="papSmearDateRawDay"
                  monthValue={healthAssessment.papSmearDateRawMonth}
                  monthField="papSmearDateRawMonth"
                  yearValue={healthAssessment.papSmearDateRawYear}
                  yearField="papSmearDateRawYear"
                  onChange={updateField}
                />
              </MoreInfoText>
            </SectionField>
            <SectionField
              htmlFor={BIOLOGICAL_FIELDS.MAMMOGRAM_DATE.NAME}
              title={BIOLOGICAL_FIELDS.MAMMOGRAM_DATE.TITLE}>
              <MoreInfoText moreInfoText={BIOLOGICAL_FIELDS.MAMMOGRAM_DATE.MORE_INFO}>
                <HADate
                  dayValue={healthAssessment.mammogramDateRawDay}
                  dayField="mammogramDateRawDay"
                  monthValue={healthAssessment.mammogramDateRawMonth}
                  monthField="mammogramDateRawMonth"
                  yearValue={healthAssessment.mammogramDateRawYear}
                  yearField="mammogramDateRawYear"
                  onChange={updateField}
                />
              </MoreInfoText>
            </SectionField>
          </div>
        )}
        {healthAssessment.biologicalGender === MALE && (
          <div key={'genderMale'}>
            <GenderTitle>Male History</GenderTitle>
            <SectionField htmlFor={BIOLOGICAL_FIELDS.PSA_DATE.NAME} title={BIOLOGICAL_FIELDS.PSA_DATE.TITLE}>
              <MoreInfoText moreInfoText={BIOLOGICAL_FIELDS.PSA_DATE.MORE_INFO}>
                <HADate
                  dayValue={healthAssessment.psaDateRawDay}
                  dayField="psaDateRawDay"
                  monthValue={healthAssessment.psaDateRawMonth}
                  monthField="psaDateRawMonth"
                  yearValue={healthAssessment.psaDateRawYear}
                  yearField="psaDateRawYear"
                  onChange={updateField}
                />
              </MoreInfoText>
            </SectionField>

            <SectionField htmlFor={BIOLOGICAL_FIELDS.PSA_SCORE.NAME} title={BIOLOGICAL_FIELDS.PSA_SCORE.TITLE}>
              <FreeTextField
                inputKey={healthAssessment.psaScore}
                inputName="psaScore"
                inputType="text"
                defaultValue={healthAssessment.psaScore}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateField('psaScore', e.target.value);
                }}
                errors={validationObject?.psaScore}
              />
            </SectionField>

            <SectionField htmlFor={BIOLOGICAL_FIELDS.PSA_LOCATION.NAME} title={BIOLOGICAL_FIELDS.PSA_LOCATION.TITLE}>
              <FreeTextField
                inputKey={healthAssessment.psaLocation}
                inputName="psaLocation"
                inputType="text"
                defaultValue={healthAssessment.psaLocation}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateField('psaLocation', e.target.value);
                }}
                errors={validationObject?.psaLocation}
              />
            </SectionField>

            <SectionField htmlFor={BIOLOGICAL_FIELDS.STD_MALE.NAME} title={BIOLOGICAL_FIELDS.STD_MALE.TITLE}>
              <SegmentedInput
                fieldName={BIOLOGICAL_FIELDS.STD_MALE.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={healthAssessment.stdMale}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  updateField('stdMale', selectedValue);
                }}
                errors={validationObject?.stdMale}
              />
            </SectionField>
          </div>
        )}
      </div>
    </>
  );
};

export default HABiological;
