import { useRef, useState } from 'react';
import moment from 'moment';
import { useRouteMatch, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Banner } from 'shared-components/components';
import { CREATE_OR_UPDATE_OUTCOME, SAVE_OUTCOME_DATA } from './Queries';
import { translateTimePoint } from './helper';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { FormRow, InputContainer, FormControlLabel } from 'op-pages/RO/FormBuilder/Forms/FormElements';
import { Button } from 'gc-ui';
import Footer, { FooterGroup } from 'op-pages/RO/FormBuilder/Forms/PageFooter';
import PageHeader from 'op-pages/RO/FormBuilder/Forms/PageHeader';
import { OUTCOME_STATUS } from './Constants';
import { OutcomeInterface } from './Interfaces';
import { OutcomeFormContainer } from './StyledComponents';
import FBComponents from '../FormBuilder/FBComponents';
import { preprocessConfig } from '../FormBuilder/PreprocessConfig';
import { generateValidationSchema } from '../FormBuilder/FBValidation';
import DiscardModal from './DiscardModal';
import _ from 'lodash';
import { useErrorModalContext } from 'op-contexts/index';
import { sleepAsync } from '../FormBuilder/common';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material';

interface ROPatientOutcomesNavType {
  id: string;
  outcomeId: string;
}

interface LocationStateType {
  timePoint: string;
  diagnosisId: string;
}

interface Props {
  outcome: OutcomeInterface;
}

function OutcomeForm(props: Props): JSX.Element {
  const { outcome } = props;
  const { setError } = useErrorModalContext();
  const history = useHistory();
  const location = useLocation<LocationStateType>();
  const match = useRouteMatch<ROPatientOutcomesNavType>();
  const { id: patientId, outcomeId } = match.params;
  const theme = useTheme();

  const [update_outcome_status] = useMutation(CREATE_OR_UPDATE_OUTCOME);
  const [saveOutcomeData] = useMutation(SAVE_OUTCOME_DATA);

  const { components, options, values: intitalValues } = preprocessConfig(outcome.formInstance);
  const valuesSubmitted = useRef(intitalValues);
  const [discardOpen, setDiscardOpen] = useState(false);

  const createdAt = moment(outcome.createdAt).format('DD/MM/YYYY');
  const submittedAt = moment(outcome.submittedAt).format('DD MMM YYYY, h:mm a');

  const autosaveMethod = async (values: any) => {
    // convert any date objects to strings with no time
    const processedValues = Object.keys(values).reduce((acc: { [key: string]: any }, key) => {
      if (values[key] instanceof Date) {
        acc[key] = moment(values[key]).format('YYYY-MM-DD');
      } else {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    saveOutcomeData({
      variables: {
        outcomeId: outcomeId,
        jsonData: JSON.stringify(processedValues),
      },
    }).then((response: any) => {
      if (response.errors) {
        setError();
      } else {
        valuesSubmitted.current = values;
      }
    });
  };

  const handleBack = () => {
    history.push(`/radiation/patient/${patientId}/outcomes`);
  };

  const StatusBanner = ({ outcome }: { outcome: OutcomeInterface }) => {
    return (
      <div data-cy="status-banner">
        {outcome.status === OUTCOME_STATUS.IN_PROGRESS && (
          <Banner text="" subtext="You have unsubmitted changes, please submit" type="info"></Banner>
        )}
        {outcome.status === OUTCOME_STATUS.UNSUBMITTED_CHANGE && (
          <Banner
            text=""
            subtext={`You have unsubmitted changes, please resubmit. Last submitted at ${submittedAt}`}
            type="info"></Banner>
        )}
        {outcome.status === OUTCOME_STATUS.SUBMITTED && (
          <Banner text="" subtext="This outcome has been submitted" type="success"></Banner>
        )}
      </div>
    );
  };

  const handleSubmit = async (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>, retries = 0) => {
    const retryLimit = 5;
    formikHelpers.setSubmitting(true);
    if (retries >= retryLimit) {
      formikHelpers.setSubmitting(false);
    } else {
      if (!_.isEqual(values, valuesSubmitted.current)) {
        // wait for backend to catch up to front end (via autosave)
        await sleepAsync(500);
        handleSubmit(values, formikHelpers, ++retries);
      } else {
        update_outcome_status({
          variables: {
            timePoint: location.state.timePoint,
            diagnosisId: location.state.diagnosisId,
            values: JSON.stringify(values), // for metrics
            status: 'submitted',
          },
        });
        formikHelpers.setSubmitting(false);
        handleBack();
      }
    }
  };

  return (
    <OutcomeFormContainer>
      <StatusBanner outcome={outcome} />
      <Formik
        initialValues={intitalValues}
        validationSchema={generateValidationSchema(components, intitalValues)}
        onSubmit={handleSubmit}
        validateOnMount={true}
        validateOnBlur={true}>
        {(formikProps: FormikProps<any>) => {
          const { handleSubmit } = formikProps;
          return (
            <>
              {/* TODO EVE-10694 - Remove inline styles */}
              <div style={{ overflow: 'auto', flex: '1', padding: '24px 32px 48px' }}>
                <PageHeader>{outcome.diagnosisName}</PageHeader>
                <p>
                  <span style={{ color: theme.palette.error.main }}>*</span> = required field
                </p>

                <div style={{ width: '785px' }}>
                  <FormRow>
                    <FormControlLabel>Date created</FormControlLabel>
                    <InputContainer>
                      <TextField
                        size="small"
                        id="created_at"
                        value={createdAt}
                        disabled={true}
                        fullWidth
                        inputProps={{
                          style: {
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.text.primary,
                            WebkitTextFillColor: 'unset',
                            fontSize: '14px',
                          },
                        }}
                      />
                    </InputContainer>
                  </FormRow>

                  <FormRow>
                    <FormControlLabel>Time point</FormControlLabel>
                    <InputContainer>
                      <TextField
                        size="small"
                        data-testid="time_point"
                        name="time_point"
                        value={`${translateTimePoint(outcome.timePoint)} post treatment`}
                        disabled={true}
                        inputProps={{
                          style: {
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.text.primary,
                            WebkitTextFillColor: 'unset',
                            fontSize: '14px',
                          },
                        }}
                        fullWidth
                      />
                    </InputContainer>
                  </FormRow>

                  <FBComponents
                    components={components}
                    options={options}
                    formikProps={formikProps}
                    autosaveMethod={autosaveMethod}
                  />
                </div>
              </div>

              <Footer>
                {(outcome.status === OUTCOME_STATUS.IN_PROGRESS || outcome.status === OUTCOME_STATUS.NOT_STARTED) &&
                  !outcome.isDefaultTimepoint && (
                    <Button
                      data-cy="outcome-form-discard"
                      mode="outlined"
                      onClick={() => {
                        setDiscardOpen(true);
                      }}>
                      Discard
                    </Button>
                  )}
                <FooterGroup>
                  <Button mode="outlined" onClick={handleBack} data-cy="outcome-form-back">
                    Back
                  </Button>
                  <Button
                    disabled={formikProps.isSubmitting}
                    data-cy="outcome-form-submit"
                    onClick={() => {
                      handleSubmit();
                    }}>
                    Submit
                  </Button>
                </FooterGroup>
              </Footer>
            </>
          );
        }}
      </Formik>
      <DiscardModal
        open={discardOpen}
        setOpen={setDiscardOpen}
        outcome={outcome}
        handleBack={handleBack}
        patientId={patientId}
      />
    </OutcomeFormContainer>
  );
}

export default OutcomeForm;
