// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { BaseDatePicker } from 'shared-components/components/FormFields';
import { styled } from '@mui/system';

const RangeGroup = styled('div')`
  whitespace: nowrap;
`;

const SpacedInlineItem = styled('div')`
  display: inline-block;
  margin-left: 0.5%;
  margin-right: 0.5%;
`;

interface DateRangeValue {
  startingDate?: Dayjs;
  endingDate?: Dayjs;
}

interface DateRangeProps {
  id?: string;
  onRangeChange: (startingDate?: Dayjs, endingDate?: Dayjs) => void;
  value?: DateRangeValue;
}

const DateRange = (props: DateRangeProps): JSX.Element => {
  const { id, onRangeChange, value } = props;

  const [startingDate, setStartingDate] = useState<Dayjs | undefined>(value?.startingDate);
  const [endingDate, setEndingDate] = useState<Dayjs | undefined>(value?.endingDate);

  return (
    <RangeGroup data-testid="dateRange">
      <b>Between</b>
      <BaseDatePicker
        id={id || 'startingFilterDate'}
        value={startingDate ? dayjs(startingDate) : undefined}
        maxDate={endingDate}
        onChange={(date: Dayjs | null): void => {
          setStartingDate(date || undefined);
          onRangeChange(date || undefined, endingDate);
        }}
      />
      <SpacedInlineItem>
        <p>and</p>
      </SpacedInlineItem>
      <BaseDatePicker
        id={id || 'endingFilterDate'}
        value={endingDate ? dayjs(endingDate) : undefined}
        minDate={startingDate}
        onChange={(date: Dayjs | null): void => {
          setEndingDate(date || undefined);
          onRangeChange(startingDate, date || undefined);
        }}
      />
    </RangeGroup>
  );
};

export default DateRange;
export { DateRangeValue };
