// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { Field, FieldArray, useFormikContext } from 'formik';

import { HelperMessage, UserIcon } from 'shared-components/components';
import { AddOutlineCircle, RemoveOutlineCircle } from 'shared-components/images';
import { GraphQLResponse } from 'shared-components/interfaces';
import TextAreaField from './TextAreaField';

interface Props {
  name: string;
  fieldText?: string;
  placeholder: string;
  addButtonText?: string;
  helperMessageType?: string;
  createMutation: (value: string, field: string) => any;
  deleteMutation: (id: string) => any;
  updateMutation: ({ id, name, value }: any) => any;
  values: any;
  parentField: string;
  textFieldValue: string;
  textFieldName: string;
  userIconFieldName?: string;
  userTypeValue?: string;
  isManagementForm?: boolean;
}

interface StyledProps {
  isLastItem: boolean;
}

const MultipleTextArea = styled('div')<StyledProps>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ isLastItem }: StyledProps) => (!isLastItem ? '12px' : '0')};
  margin-right: 8px;
`;

const RemoveIcon = styled(RemoveOutlineCircle)`
  margin-left: 8px;
  cursor: pointer;
`;

const AddItemContainer = styled('span')`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  margin-top: 12px;
`;

const AddIcon = styled(AddOutlineCircle)`
  margin-right: 12px;
`;

const TextAreaFieldGroup = ({
  name,
  createMutation,
  deleteMutation,
  updateMutation,
  fieldText,
  placeholder,
  addButtonText,
  helperMessageType,
  parentField,
  values,
  textFieldValue,
  textFieldName,
  userIconFieldName,
  userTypeValue,
  isManagementForm = false,
}: Props): JSX.Element => {
  const defaultTextfieldValue = '';
  const { setFieldTouched, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values?.length === 0) {
      createMutation(name, defaultTextfieldValue).then((result: GraphQLResponse) => {
        const newItem: any = Object.values(result.data)[0];
        if (userIconFieldName) {
          newItem[parentField][userIconFieldName] = '';
        }
        setFieldValue(name, [{ ...newItem[parentField] }], false);
      });
    }
  }, [values]);

  const showAdd =
    isManagementForm || (values?.length > 0 && values.filter((item: any) => item[textFieldValue] !== '').length > 0);

  return (
    <FieldArray
      name={name}
      render={({ push, remove }) => {
        return (
          <>
            {values?.length > 0 &&
              values.map((item: any, idx: number) => {
                const isLastItem = idx === values.length - 1;
                const styledProps = { isLastItem };
                const { id } = item;
                const textAreaName = `${name}[${idx}].${textFieldValue}`;

                // Fields are only used when a user icon needs to be rendered
                let userType;
                let userTypeName = '';
                if (userIconFieldName) {
                  userType = item[userIconFieldName];
                  userTypeName = `${name}[${idx}].${userIconFieldName}`;
                }

                return (
                  <MultipleTextArea {...styledProps} key={idx}>
                    <Field
                      name={textAreaName}
                      component={TextAreaField}
                      placeholder={placeholder}
                      updateMutation={(value: string) => {
                        updateMutation({ variables: { id, [textFieldName]: name, [textFieldValue]: value } });
                        setFieldTouched(name, true);
                        if (userIconFieldName) {
                          setFieldValue(userTypeName, userTypeValue);
                        }
                      }}
                      multiline
                      maxLength={2048}
                    />
                    {userIconFieldName && <UserIcon userType={userType} />}
                    {values.length > 1 && (
                      <RemoveIcon
                        data-test-id={`remove-icon-${name}-${idx}`}
                        onClick={() => {
                          remove(idx);
                          deleteMutation(id);
                        }}
                      />
                    )}
                  </MultipleTextArea>
                );
              })}
            {showAdd && (
              <>
                {fieldText && <HelperMessage fieldName={name} fieldText={fieldText} helperType={helperMessageType} />}
                <AddItemContainer
                  onClick={() => {
                    if (isManagementForm) {
                      return createMutation(name, defaultTextfieldValue);
                    } else {
                      return createMutation(name, defaultTextfieldValue).then((result: GraphQLResponse) => {
                        const newItem: any = Object.values(result.data)[0];
                        push({ ...newItem[parentField] });
                      });
                    }
                  }}>
                  <AddIcon />
                  {addButtonText}
                </AddItemContainer>
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default TextAreaFieldGroup;
