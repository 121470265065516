import React from 'react';
import MUICheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SelectedCheckbox from '../../assets/Icons/SelectedCheckbox';
import UnselectedCheckbox from '../../assets/Icons/UnselectedCheckbox';
import { SimplePaletteColorOptions, styled, useTheme } from '@mui/material';

export interface CheckboxTileProps {
  id: string;
  name?: string;
  label?: string;
  error?: boolean;
  checked: boolean;
  disabled?: boolean;
  onChange: (value: any) => void;
  'data-cy'?: string;
  errorMessage?: string;
}

interface InternalCheckboxTileProps extends CheckboxProps {
  error: boolean | undefined;
}
const StyledMUICheckbox = styled(MUICheckbox)<InternalCheckboxTileProps>`
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  #gc-ui-checked-icon #inner-rect {
    fill: ${(props) =>
      props.disabled
        ? props.theme.palette.secondary.main
        : props.error
        ? props.theme.palette.error.main
        : props.theme.palette.primary.main};
  }
  #gc-ui-checked-icon #outer-rect {
    stroke: ${(props) =>
      props.disabled
        ? props.theme.palette.secondary.main
        : props.error
        ? props.theme.palette.error.main
        : props.theme.palette.primary.main};
  }
  #gc-ui-unchecked-icon #inner-rect {
    fill: ${(props) => (props.disabled ? props.theme.palette.secondary.light : props.error ? 'white !important;' : '')};
  }
  #gc-ui-unchecked-icon #outer-rect {
    stroke: ${(props) =>
      props.disabled
        ? props.theme.palette.secondary.main
        : props.error
        ? props.theme.palette.error.main
        : props.theme.palette.primary.main};
  }
  &:hover {
    #gc-ui-checked-icon #inner-rect {
      fill: ${(props) =>
        props.disabled
          ? undefined
          : props.error
          ? props.theme.palette.error.main
          : props.theme.palette.primary.light} !important;
    }
    #gc-ui-checked-icon #outer-rect {
      stroke: ${(props) =>
        props.disabled
          ? props.theme.palette.secondary.main
          : props.error
          ? props.theme.palette.error.main
          : props.theme.palette.primary.light} !important;
    }
    #gc-ui-unchecked-icon #inner-rect {
      fill: ${(props) =>
        props.disabled ? props.theme.palette.secondary.light : props.theme.palette.primary?.contrastText} !important;
    }
    #gc-ui-unchecked-icon #outer-rect {
      stroke: ${(props) =>
        props.disabled
          ? props.theme.palette.secondary.main
          : props.error
          ? props.theme.palette.error.main
          : props.theme.palette.primary.main} !important;
    }
  }
`;

interface InternalGridContainerProps {
  checked: boolean;
  error: boolean | undefined;
  disabled: boolean | undefined;
}

const StyledGridContainer = styled(Grid)<InternalGridContainerProps>`
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin-left: 0px;
  margin-right: 0px;
  background-color: ${(props) => (props.disabled ? props.theme.palette.secondary.light : '')};
  border-color: ${(props) =>
    props.disabled
      ? props.theme.palette.secondary.main
      : props.error
      ? props.theme.palette.error.main
      : props.checked
      ? props.theme.palette.primary.main
      : props.theme.palette.secondary.main};
  &:hover {
    border-color: ${(props) =>
      props.disabled
        ? props.theme.palette.secondary.main
        : props.error
        ? props.theme.palette.error.main
        : props.theme.palette.primary?.main};
    background-color: ${(props) =>
      props.disabled
        ? props.theme.palette.secondary.light
        : props.error
        ? props.theme.palette.error?.contrastText
        : props.theme.palette.primary?.contrastText};
  }
`;

const StyledLabelGridItem = styled(Grid)`
  padding-top: 8px;
  padding-left: 0px;
  padding-right: 16px;
  padding-bottom: 8px;
`;

const StyledTypography = styled(Typography)`
  font-family: 'GenesisCareSans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
`;

const CheckboxTile = (props: CheckboxTileProps) => {
  const theme = useTheme();
  const fill = props.disabled
    ? theme.palette.secondary.main
    : props.error
    ? theme.palette.error.main
    : theme.palette.primary.main;
  const fillTwo = props.disabled ? theme.palette.secondary.light : props.error ? 'white !important' : '';
  return (
    <>
      <StyledGridContainer
        container
        theme={theme}
        id={`checkbox-container-${props.id}`}
        checked={props.checked}
        error={props.error}
        disabled={props.disabled}
        onClick={() => props.onChange(!props.checked)}
        data-cy={props['data-cy']}>
        <StyledMUICheckbox
          theme={theme}
          error={props.error}
          key={`checkbox-${props.id}`}
          disabled={props.disabled}
          name={props.name}
          onClick={() => props.onChange(!props.checked)}
          checked={props.checked}
          disableRipple
          checkedIcon={<SelectedCheckbox key={'gc-ui-checked-icon'} id={'gc-ui-checked-icon'} />}
          icon={<UnselectedCheckbox key={'gc-ui-unchecked-icon'} id="gc-ui-unchecked-icon" />}
          data-cy={`${props['data-cy']}-${props.id}`}
          style={{ marginRight: '8px', marginLeft: '8px' }}
          sx={{
            paddingRight: '16px',
            paddingLeft: '16px',
            paddingTop: '8px',
            paddingBottom: '8px',
            '#gc-ui-checked-icon #inner-rect': {
              fill,
            },
            '#gc-ui-checked-icon #outer-rect': {
              stroke: fill,
            },
            '#gc-ui-unchecked-icon #inner-rect': {
              fill: fillTwo,
            },
            '#gc-ui-unchecked-icon #outer-rect': {
              stroke: fill,
            },
            '&:hover': {
              '#gc-ui-checked-icon #inner-rect': {
                fill: `${
                  props.disabled ? undefined : props.error ? theme.palette.error.main : theme.palette.primary.light
                } !important`,
              },
              '#gc-ui-checked-icon #outer-rect': {
                stroke: `${
                  props.disabled
                    ? theme.palette.secondary.main
                    : props.error
                    ? theme.palette.error.main
                    : theme.palette.primary.light
                } !important`,
              },
              '#gc-ui-unchecked-icon #inner-rect': {
                fill: `${
                  props.disabled ? theme.palette.secondary.light : theme.palette.primary?.contrastText
                } !important`,
              },
              '#gc-ui-unchecked-icon #outer-rect': {
                stroke: `${fill} !important`,
              },
            },
          }}
        />
        <StyledLabelGridItem item xs key={`label-${props.id}`}>
          <StyledTypography>{props.label}</StyledTypography>
        </StyledLabelGridItem>
      </StyledGridContainer>
      <div style={{ marginTop: '8px' }}>
        {props.error && (
          <span data-cy={`checkbox-tile-error-${props['data-cy']}`} style={{ color: theme.palette.error.main }}>
            {props.errorMessage}
          </span>
        )}
      </div>
    </>
  );
};

export default CheckboxTile;
