// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'gc-ui';
import classNames from 'classnames';

import './ModalSubmit.scss';

import { ButtonType } from 'shared-components/enums';
import { GCButton } from 'shared-components/components/FormFields';
import { Info } from 'shared-components/images';
import { SUBMIT_MODAL } from './constants';
import { Region } from 'shared-components/enums';
import Stack from '@mui/system/Stack';
import { Typography } from '@mui/material';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

interface Props {
  isOpen: boolean;
  isProd?: boolean;
  isPSO?: boolean;
  dismissFunction: () => void;
  submitForm: () => void;
  submitText: string;
  headerText?: string;
  bodyText?: string | JSX.Element;
  isUs?: boolean | JSX.Element;
  emptyBody?: boolean;
  removeHeaderPadding?: boolean;
  keepUnlocked?: boolean;
  continueNextForm?: boolean;
}

interface State {
  locked: boolean;
}

const region = REACT_APP_REGION;

class ModalSubmit extends Component<Props, State> {
  public static defaultProps = {
    isPSO: true,
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      locked: false,
    };
  }
  private setLocked() {
    this.setState({ locked: true });
  }
  public render(): JSX.Element {
    const {
      dismissFunction,
      isOpen,
      isPSO,
      submitForm,
      submitText,

      headerText,
      bodyText,
      isUs,
      emptyBody,
      removeHeaderPadding,
      keepUnlocked,
    } = this.props;
    const { locked } = this.state;

    const UsModalBodyText = 'Please ensure details are correct.';
    return (
      <div id="submit-modal">
        <Modal
          id="submit-modal"
          open={isOpen}
          onClose={dismissFunction}
          className="modal-gc submit-modal"
          PaperProps={{ style: { borderRadius: '24px' } }}>
          <ModalHeader
            id="submit-modal-header"
            toggle={dismissFunction}
            className={classNames({ 'no-padding': removeHeaderPadding, 'modal-title': 'modal-title' })}>
            <Typography variant="h5" fontWeight={600} paddingBottom="24px">
              {headerText ? headerText : isPSO ? SUBMIT_MODAL.CONFIRM_EXIT : SUBMIT_MODAL.CONFIRM_SUBMIT}
            </Typography>
          </ModalHeader>
          <ModalBody
            className="modal-content"
            style={{ lineHeight: '1.4rem', textAlign: 'center', alignSelf: 'center', padding: '0px 24px 24px 24px' }}>
            {emptyBody
              ? null
              : bodyText
              ? bodyText
              : isUs
              ? UsModalBodyText
              : isPSO
              ? this.renderPSOContent()
              : this.renderPatientContent()}
          </ModalBody>
          <ModalFooter sx={{ borderTop: '0px !important', justifyContent: 'center !important' }}>
            <Stack
              direction="row"
              gap="8px"
              justifyContent="center"
              className={classNames('modal-submit-buttons', { 'empty-body': emptyBody })}>
              <GCButton
                name="cancel"
                title="Cancel"
                onClick={(e): void => {
                  e.preventDefault();
                  dismissFunction();
                }}
                type={ButtonType.WHITE}
              />
              <GCButton
                name="submitRegistration"
                title={submitText}
                disabled={locked}
                onClick={(e): void => {
                  e.preventDefault();
                  submitForm();
                  if (!keepUnlocked) {
                    this.setLocked();
                  }
                }}
                type={ButtonType.GREEN}
              />
            </Stack>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  private renderPatientContent = (): JSX.Element => {
    const { continueNextForm } = this.props;
    return (
      <Fragment>
        <div className="modal-submit-body">
          <div>{continueNextForm ? SUBMIT_MODAL.CONFIRM_DETAILS_CONTINUE : SUBMIT_MODAL.CONFIRM_DETAILS_COMPLETE}</div>
        </div>
      </Fragment>
    );
  };

  private renderPSOContent = (): JSX.Element => {
    const { isProd } = this.props;
    if (region === Region.UK) {
      return (
        <div className="modal-submit-body pso-view">
          <ul>
            <li>{SUBMIT_MODAL.CONFIRM_PATIENT_DETAILS}</li>
            <li>{SUBMIT_MODAL.PATIENT_INFO_UPDATE}</li>
            <li id="warning-message">{isProd ? SUBMIT_MODAL.SUBMIT_TO_MOSAIQ : SUBMIT_MODAL.SUBMIT_TO_TEST}</li>
          </ul>
        </div>
      );
    } else {
      return (
        <div className="modal-submit-body pso-view">
          <ul>
            <li>{SUBMIT_MODAL.CONFIRM_PATIENT_DETAILS}</li>
            <li>{SUBMIT_MODAL.PDF_SENT}</li>
            <li>{SUBMIT_MODAL.PATIENT_INFO_UPDATE}</li>
          </ul>
          <div className="info-container">
            <Info className="info icon" />
            <div>{SUBMIT_MODAL.PATIENT_PORTAL_REMINDER}</div>
          </div>
        </div>
      );
    }
  };
}

export default ModalSubmit;
