// eslint-disable-next-line no-use-before-define
import React from 'react';
import BaseAutocomplete, { Props as BaseProps } from './BaseAutocomplete';
import FormRow from '../FormRow/FormRow';

interface Props extends BaseProps {
  fieldlabel?: string;
  required?: boolean;
}

const ROAutocomplete = ({ sx, ...rest }: Props): JSX.Element => {
  return (
    <FormRow id={`row-${rest.id}`} fieldlabel={rest.fieldlabel} required={rest.required}>
      <BaseAutocomplete
        {...rest}
        sx={{
          ...{
            width: '100%',
            maxWidth: '400px',
            minWidth: '250px',
            alignItems: 'center',
            '& .MuiTextField-root': {
              verticalAlign: 'unset',
            },
          },
          ...sx,
        }}
        placeholder={rest.placeholder || 'Please select'}
      />
    </FormRow>
  );
};

export default ROAutocomplete;
