import { gql } from '@apollo/client';
const ATTACHMENT_FRAGMENT = gql`
  fragment Attachment on AttachmentType {
    id
    url
    documentType
    submitted
    encounterDate
    isRegistrationAttachment
  }
`;
export const SITE_GROUP_OPTIONS_FRAGMENT = gql`
  fragment SiteGroupOptions on SiteGroupSimulationType {
    additionalSimInfo {
      value
      isShown
    }
    bladder {
      value
      isShown
    }
    bolus {
      value
      isShown
    }
    bolusLocationType {
      value
      isShown
    }
    bolusLocation {
      value
      isShown
    }
    bolusThickness {
      value
      isShown
    }
    bolusThicknessCustom {
      value
      isShown
    }
    bolusFrequency {
      value
      isShown
    }
    is3dBolus {
      value
      isShown
    }
    bowelPrep {
      value
      isShown
    }
    ctSimulation {
      value
      isShown
    }
    contrastRequired {
      value
      isShown
    }
    contrastType {
      value
      isShown
    }
    bloodTestLast3Months {
      value
      isShown
    }
    egfrGt30ml {
      value
      isShown
    }
    dibh {
      value
      isShown
    }
    breathHold {
      value
      isShown
    }
    extentDefined {
      value
      isShown
    }
    extentOfCt {
      value
      isShown
    }
    extraCtMarkers {
      value
      isShown
    }
    fourDct {
      value
      isShown
    }
    gating {
      value
      isShown
    }
    goldSeeds {
      value
      isShown
    }
    mouthpiece {
      value
      isShown
    }
    patientPosition {
      value
      isShown
    }
    positionSetup {
      value
      isShown
    }
    roRequired {
      value
      isShown
    }
    mask {
      value
      isShown
    }
    spacerGel {
      value
      isShown
    }
    stomach {
      value
      isShown
    }
    abdomenCompression {
      value
      isShown
    }
    tonguePosition {
      value
      isShown
    }
    vaginalTampon {
      value
      isShown
    }
  }
`;

export const GET_DEFAULT_VALUES = gql`
  query defaultSiteGroupValues($siteGroupId: ID!, $fieldNames: [String!]) {
    defaultSiteGroupValues(siteGroupId: $siteGroupId, fieldNames: $fieldNames) {
      fieldName
      value
      isShown
    }
  }
`;

export const LOAD_SITEGROUP_INFO = gql`
  ${SITE_GROUP_OPTIONS_FRAGMENT}
  query siteGroupsByCareplan($careplanId: ID!) {
    simulationSiteGroups(careplanId: $careplanId) {
      sites {
        treatmentSite
        location
        laterality
      }
      id
      templateId
      ...SiteGroupOptions
      imagingList {
        id
        anatomicalMatchPriority
        bodyRegion
        contrastRequired
        createdAt
        createdBy
        imagingRequirement
        instructions
        radiologyClinic
        scanExamination
      }
    }
  }
`;

export const RESET_SIMULATION = gql`
  mutation resetSimulation($siteGroupId: ID!) {
    resetSimulation(siteGroupId: $siteGroupId) {
      siteGroup {
        id
      }
    }
  }
`;

export const UPDATE_SIMULATION = gql`
  ${SITE_GROUP_OPTIONS_FRAGMENT}

  mutation updateSimulation(
    $siteGroupId: ID!
    $additionalSimInfo: String
    $bladder: String
    $bolus: Boolean
    $bolusLocationType: String
    $bolusLocation: String
    $bolusThickness: String
    $bolusThicknessCustom: String
    $bolusFrequency: String
    $is3dBolus: String
    $bowelPrep: Boolean
    $ctSimulation: Boolean
    $contrastRequired: Boolean
    $contrastType: String
    $bloodTestLast3Months: String
    $egfrGt30ml: String
    $dibh: Boolean
    $breathHold: String
    $extentDefined: String
    $extentOfCt: String
    $extraCtMarkers: String
    $fourDct: Boolean
    $gating: Boolean
    $goldSeeds: Boolean
    $mouthpiece: Boolean
    $patientPosition: String
    $positionSetup: String
    $roRequired: Boolean
    $mask: Boolean
    $spacerGel: Boolean
    $stomach: String
    $abdomenCompression: Boolean
    $tonguePosition: String
    $vaginalTampon: Boolean
  ) {
    updateSimulation(
      siteGroupId: $siteGroupId
      additionalSimInfo: $additionalSimInfo
      bladder: $bladder
      bolus: $bolus
      bolusLocationType: $bolusLocationType
      bolusLocation: $bolusLocation
      bolusThickness: $bolusThickness
      bolusThicknessCustom: $bolusThicknessCustom
      bolusFrequency: $bolusFrequency
      is3dBolus: $is3dBolus
      bowelPrep: $bowelPrep
      ctSimulation: $ctSimulation
      contrastRequired: $contrastRequired
      contrastType: $contrastType
      bloodTestLast3Months: $bloodTestLast3Months
      egfrGt30ml: $egfrGt30ml
      dibh: $dibh
      breathHold: $breathHold
      extentDefined: $extentDefined
      extentOfCt: $extentOfCt
      extraCtMarkers: $extraCtMarkers
      fourDct: $fourDct
      gating: $gating
      goldSeeds: $goldSeeds
      mouthpiece: $mouthpiece
      patientPosition: $patientPosition
      positionSetup: $positionSetup
      roRequired: $roRequired
      mask: $mask
      spacerGel: $spacerGel
      stomach: $stomach
      abdomenCompression: $abdomenCompression
      tonguePosition: $tonguePosition
      vaginalTampon: $vaginalTampon
    ) {
      siteGroup {
        id
        careplanId

        ...SiteGroupOptions
      }
    }
  }
`;

export const CREATE_IMAGING = gql`
  mutation createImaging($siteGroupId: ID, $imagingRequirement: String) {
    createImaging(siteGroupId: $siteGroupId, imagingRequirement: $imagingRequirement) {
      success
      imaging {
        id
        anatomicalMatchPriority
        bodyRegion
        contrastRequired
        imagingRequirement
        instructions
        radiologyClinic
        scanExamination
      }
    }
  }
`;

export const LOAD_IMAGING_BY_SITEGROUP = gql`
  query imagingList($siteGroupId: ID!) {
    imaging {
      id
      anatomicalMatchPriority
      bodyRegion
      contrastRequired
      imagingRequirement
      instructions
      radiologyClinic
      scanExamination
    }
  }
`;

export const UPDATE_IMAGING = gql`
  mutation updateImaging(
    $imagingId: ID!
    $anatomicalMatchPriority: String
    $bodyRegion: String
    $contrastRequired: Boolean
    $imagingRequirement: String
    $instructions: String
    $radiologyClinic: String
    $scanExamination: String
  ) {
    updateImaging(
      imagingId: $imagingId
      anatomicalMatchPriority: $anatomicalMatchPriority
      bodyRegion: $bodyRegion
      contrastRequired: $contrastRequired
      imagingRequirement: $imagingRequirement
      instructions: $instructions
      radiologyClinic: $radiologyClinic
      scanExamination: $scanExamination
    ) {
      imaging {
        id
        anatomicalMatchPriority
        bodyRegion
        contrastRequired
        imagingRequirement
        instructions
        radiologyClinic
        scanExamination
      }
    }
  }
`;

export const DELETE_IMAGING = gql`
  mutation deleteImaging($imagingId: ID) {
    deleteImaging(imagingId: $imagingId) {
      success
    }
  }
`;

export const USER_QUERY = gql`
  query user {
    user {
      id
      isShowcaseUser
    }
  }
`;
