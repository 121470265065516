import { gql } from '@apollo/client';

export const GET_PATIENT_PROFILE = gql`
  query patient($patientId: ID!) {
    patient(id: $patientId) {
      id
      firstName
      lastName
      middleName
      fullName
      email
      pxOptedIn
      pxOptOutComment
      horizonCenterId
      ida
      dob
      patientState
      gpFullName
      patientGender
      primaryPhone
      secondaryPhone
      healthMedicareNumber
      healthMedicareIrn
      medicareExpiryRawYear
      medicareExpiryRawMonth
      policyNumber
      insurances {
        id
        policyNumber
        payor {
          id
          name
        }
        expiryDate
      }
      healthPrivateHealthFundName
      userProfile {
        id
        registrationAccessType
        showDistressThermometer
        hasPatId1
        patientFeatures {
          distressThermometerEotEnabled
          distressThermometerEnabled
          managementPlansEnabled
          covidEnabled
        }
      }
      emergencyContact {
        id
        firstName
        lastName
        relationship
        homePhoneNumber
        mobilePhoneNumber
      }
      address {
        id
        line1
        line2
        city
        state
        country
        postcode
        formattedAddress
        ukFormattedAddress
      }
      allergy {
        id
        name
        reaction
        severity
        status
      }
      appointments {
        id
        startTime
        description
        location {
          id
          name
        }
        department {
          id
          alias
        }
      }
      allReferrers {
        id
        relationshipType
        practitioner {
          id
          qualification
          firstName
          lastName
          phone
        }
      }
      carepathPatientDiagnosis {
        id
        isPrimaryDiagnosis
        medId
        isMostRecent
        diagnosisCode {
          id
          diagnosisCode
          diagnosisDescription
          tumourStream {
            id
            name
          }
        }
      }
      photoUrl
      tags {
        id
        tag {
          id
          name
        }
      }
      emrPatientId
      emrInstance
    }
  }
`;

export const GET_LOCATION_PROFILE = gql`
  query GetLocationProfile($patientId: ID!) {
    locationsPsoSystem(patientId: $patientId) {
      id
      alias
    }
  }
`;

export const GET_ALERTS = gql`
  query alerts($patientId: ID!) {
    alerts(patientId: $patientId) {
      id
      name
      comment
      status
      assigned
    }
  }
`;

export const UPDATE_CENTER_MUTATION = gql`
  mutation updateHorizonCenter($id: ID!, $horizonCenterId: ID!) {
    updateHorizonCenter(id: $id, horizonCenterId: $horizonCenterId) {
      horizonCenterId
      patient {
        id
        horizonCenterId
      }
      user {
        id
      }
    }
  }
`;

export const UPDATE_PATIENT_OPT_IN = gql`
  mutation UpdatePatient(
    $id: ID!
    $pxOptedIn: Boolean
    $email: String
    $primaryPhone: String
    $pxOptOutComment: String
  ) {
    updatePatient(
      id: $id
      pxOptedIn: $pxOptedIn
      email: $email
      primaryPhone: $primaryPhone
      pxOptOutComment: $pxOptOutComment
    ) {
      patient {
        id
        pxOptedIn
        email
        primaryPhone
        pxOptOutComment
      }
    }
  }
`;

export const GET_TAG_OPTIONS = gql`
  query tagOptions {
    tagOptions {
      id
      name
    }
  }
`;

export const UPDATE_PATIENT_TAGS = gql`
  mutation updatePatientTagItems($patientId: ID!, $updatedTags: [String]!) {
    updatePatientTagItems(patientId: $patientId, updatedTags: $updatedTags) {
      tagItems {
        id
        tag {
          id
          name
        }
      }
    }
  }
`;
