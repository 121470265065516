import { BottomSection, HeaderSection } from 'op-pages/PX/Components';
import { ReactNode, memo } from 'react';
import { Stack } from '@mui/material';

interface AuthLayoutIProps {
  children: ReactNode;
  titleOverride?: string;
  clickLogoFn?: () => void;
  showHeader?: boolean;
  showNavigation?: boolean;
  showBottomNav?: boolean;
}

const AuthLayout = (props: AuthLayoutIProps): JSX.Element => {
  const {
    children,
    titleOverride,
    clickLogoFn,
    showHeader = true,
    showNavigation = true,
    showBottomNav = true,
  } = props;
  const componentId = 'auth-layout';

  return (
    <Stack id={componentId} sx={{ padding: '0', width: '100%', display: 'flex', height: '100%' }}>
      {showHeader && (
        <HeaderSection
          titleOverride={titleOverride}
          clickLogoFn={clickLogoFn}
          showNavigation={showNavigation}
          displayProfile={showNavigation}
        />
      )}
      <div id={`${componentId}-content`} className="d-flex flex-grow-1 overflow-auto">
        {children}
      </div>
      {showBottomNav && showNavigation && <BottomSection />}
    </Stack>
  );
};

export default memo(AuthLayout);
