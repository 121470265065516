// Footer.js
import { styled } from '@mui/system';

const Footer = styled('footer')`
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  background-color: white;
  padding: 0 20px;
  border-top: 2px solid ${(props) => props.theme.palette.primary.main};

  & > * {
    margin:8px;

  &:only-child {
    margin-left: auto;
  }
`;

export const FooterGroup = styled('div')`
  display: flex;
  align-items: center;

  & > * {
    margin: 8px;
  }
`;

export default Footer;
