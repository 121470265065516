import React from 'react';
import { TextField, StandardTextFieldProps, useTheme } from '@mui/material';
import ROHelperText from '../ROHelperText/ROHelperText';
import { getBorderColor } from '../common';

export interface Props extends StandardTextFieldProps {
  maxLength?: number;
  warning?: boolean;
  info?: boolean;
  helperText?: string;
  alwaysShowHelper?: boolean;
  neverShowHelper?: boolean; // TODO: come up with a better way of handling helper text
}

/*
  Simple wrapper for the MUI text field to apply the correct border colors
  and other desired styles. Use this if you want the standard text field
  but don't need the label/wrapping for use in a form that comes with 
  the ROTextField
*/
const BaseTextField = ({
  error,
  warning,
  info,
  helperText,
  neverShowHelper,
  alwaysShowHelper,
  sx,
  size,
  maxLength,
  inputProps,
  autoComplete,
  ...rest
}: Props): JSX.Element => {
  const borderColor = getBorderColor(error, warning, info);
  const showHelperText = !neverShowHelper && (alwaysShowHelper || error || warning || info);

  const helperTextProps = {
    error,
    warning,
    info,
    helperText,
    id: rest.id,
  };
  const theme = useTheme();
  const textFieldSx = {
    ...{
      '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.secondary.light,
      },
      '& .MuiOutlinedInput-input.Mui-disabled': {
        WebkitTextFillColor: theme.palette.text.primary,
      },
      '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
        opacity: 1,
        color: theme.palette.secondary.dark,
      },
      '.MuiInputBase-root': {
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      },
      '.MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${borderColor} !important`,
      },
      ':hover .MuiOutlinedInput-notchedOutline': {
        borderColor,
      },
      'input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      'input[type="number"]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '.MuiFormHelperText-root': {
        marginLeft: 0,
      },
    },
    ...sx,
  };
  const textFieldInputProps = {
    ...{ maxLength: maxLength || 200, 'data-testid': rest.id },
    ...inputProps,
  };

  return (
    <TextField
      sx={textFieldSx}
      inputProps={textFieldInputProps}
      size={size || 'small'}
      autoComplete={autoComplete || 'off'}
      helperText={showHelperText ? <ROHelperText {...helperTextProps} /> : undefined}
      {...rest}
    />
  );
};

export default BaseTextField;
