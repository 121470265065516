import React from 'react';
import { styled } from '@mui/system';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { CheckedCircle, CircleBorder } from './icons';
import { useTheme } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  active: boolean;
  showCheckIcon?: boolean;
  buttonText: string;
  onClick?: () => void;
  activeColor?: string;
  borderColor?: string;
  textColor?: string;
  width?: string;
  invalid?: boolean;
}

interface SelectButtonProps extends React.HTMLAttributes<HTMLElement> {
  $active?: boolean;
  $activeColor: string;
  $borderColor: string;
  $textColor: string;
  $width: string;
  $invalid: boolean;
}

interface ButtonTextProps {
  $showCheckIcon?: boolean;
}

const StyledSelectButton = styled('button')<SelectButtonProps>`
  border: solid
    ${(props) =>
      ({ $active, $invalid, $activeColor, $borderColor }: SelectButtonProps): string =>
        $active ? `2px ${$activeColor}` : $invalid ? `1px ${props.theme.palette.error.main}` : `1px ${$borderColor}`};
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  min-width: ${({ $width }: SelectButtonProps): string => $width};
  min-height: 40px;
  font-size: ${getRemSize(14)};
  text-transform: none;
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
  color: ${({ $textColor }: SelectButtonProps): string => $textColor};
  outline: none !important;
`;

const IconContainer = styled('section')`
  align-self: flex-start;
  height: 24px;
  width: 24px;
`;

const ButtonText = styled('span')<ButtonTextProps>`
  width: 90%;
  margin-left: ${({ $showCheckIcon }: ButtonTextProps): string => ($showCheckIcon ? '-5px' : 'auto')};
  text-align: center;
  padding: 0 5px;
`;

const SelectButton = ({
  active,
  borderColor,
  showCheckIcon,
  buttonText,
  onClick,
  activeColor,
  textColor,
  width,
  invalid,
}: Props): JSX.Element => {
  const theme = useTheme();
  const buttonIcon = active ? (
    <CheckedCircle title={'Checked_circle.svg'} />
  ) : (
    <CircleBorder title="Border_circle.svg" />
  );

  const styleProps = {
    $active: active,
    $activeColor: activeColor || theme.palette.primary.dark,
    $borderColor: borderColor || theme.palette.secondary.main,
    $textColor: textColor || theme.palette.text.primary,
    $width: width || '168px',
    $invalid: invalid || false,
  };

  return (
    <StyledSelectButton {...styleProps} type="button" data-test-id={`${buttonText}-select`} onClick={onClick}>
      {showCheckIcon && <IconContainer>{buttonIcon}</IconContainer>}
      <ButtonText $showCheckIcon={showCheckIcon}>{buttonText}</ButtonText>
    </StyledSelectButton>
  );
};

export default SelectButton;
