import React from 'react';

import { Attributes } from './types';
import { useTheme } from '@mui/material';
import './PatientCard.scss';
import { Tooltip } from '@mui/material';

interface Props {
  attributes: Attributes;
}

const PatientId = ({ attributes }: Props): JSX.Element => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <div>
        <div className="patient-name-block" style={{ color: theme.palette.primary.dark }}>
          {attributes.fullName}
        </div>
        <Tooltip
          enterDelay={5000}
          arrow
          title={
            attributes.emrPatientId && (
              <>
                {' '}
                <div className="header-text">
                  EMR ID <b>{attributes.emrPatientId}</b>
                </div>
                <div className="header-text">
                  EMR Instance <b>{attributes.emrInstance}</b>
                </div>
              </>
            )
          }>
          <div className="header-text" id="patient-id-block">
            Patient ID <b>{attributes.ida}</b>
          </div>
        </Tooltip>
      </div>
    </React.Fragment>
  );
};

export default PatientId;
