// eslint-disable-next-line no-use-before-define
import React from 'react';
import { ComposedModal } from 'gc-ui';
import { CPOT_MODAL } from './constants';
import './ModalCpotCancel.scss';

interface Props {
  isOpen: boolean;
  dismissFunction: () => void;
  submitFunction: () => void;
}

const ModalCpotCancel = (props: Props): JSX.Element => {
  const { isOpen, dismissFunction, submitFunction } = props;

  return (
    <div id="submit-modal">
      <ComposedModal
        headerText={CPOT_MODAL.HEADER || ''}
        isOpen={isOpen}
        dismissFunction={dismissFunction}
        width={'530px'}
        secondaryRightButton={{
          buttonText: 'Back',
          onClick: dismissFunction,
          testId: 'modal-cpot-back',
        }}
        primaryRightButton={{
          buttonText: 'Quit CPOT',
          onClick: () => {
            submitFunction();
            dismissFunction();
          },
          testId: 'modal-cpot-quit',
        }}>
        <div>{CPOT_MODAL.BODY}</div>
      </ComposedModal>
    </div>
  );
};

export default ModalCpotCancel;
