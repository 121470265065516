import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

import { PatientTooltip } from 'op-interfaces';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';

import './RecentPatientBlock.scss';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';

const StyledPatientName = styled('div')`
  font-size: 16px;
  line-height: 24px;
`;

const StyledPatientDetail = styled('div')`
  font-size: 13px;
  line-height: 15px;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const StyledPopoverBody = styled(Stack)`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  padding: 16px;
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
`;

const StyledPopoverName = styled('div')`
  font-size: 18px;
  line-height: 28px;
`;

const StyledPopoverField = styled('div')`
  padding: 4px 0;
`;

const StyledPopoverLabel = styled('div')`
  font-weight: 400;
`;

const StyledPopoverId = styled(StyledPopoverField)`
  color: ${(props) => props.theme.palette.secondary.dark};
`;

interface Props {
  parentComponentID: string;
  patient: PatientTooltip;
}

const RecentPatientBlock = ({ patient }: Props): JSX.Element => {
  const theme = useTheme();
  return (
    <div>
      <Tooltip
        placement="right"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'white',
              color: 'black',
              minWidth: '300px !important',
              fontSize: '0.9rem',
              boxShadow: theme.shadows[1],
            },
          },
          arrow: {
            sx: {
              color: 'white',
              '&::before': {
                backgroundColor: theme.palette.common.white,
                border: '1px solid #ddd',
              },
            },
          },
        }}
        title={
          <StyledPopoverBody direction="row">
            <PhotoWidget url={patient.photoUrl} enableOverlay={true} size="reg" />
            <div>
              <StyledPopoverName>{patient.fullName}</StyledPopoverName>
              <StyledPopoverId>Patient ID: {patient.ida}</StyledPopoverId>
              <StyledPopoverField>
                <StyledPopoverLabel>Date of birth</StyledPopoverLabel>
                <div>{patient.dob ? `${moment(patient.dob).format('D MMM YYYY')} (age ${patient.age})` : 'N/A'}</div>
              </StyledPopoverField>
              <StyledPopoverField>
                <StyledPopoverLabel>Address</StyledPopoverLabel>
                <div>{patient.address ? patient.address.formattedAddress : 'N/A'}</div>
              </StyledPopoverField>
            </div>
          </StyledPopoverBody>
        }>
        <StyledLink to={`/navigator/patient/${patient.id}/summary`}>
          <Stack direction="row" gap="8px" padding={'4px'}>
            <PhotoWidget url={patient.photoUrl} enableOverlay={true} size="sm" />
            <div>
              <StyledPatientName className="patient-name">{patient.fullName}</StyledPatientName>
              <StyledPatientDetail className="patient-id">ID: {patient.ida}</StyledPatientDetail>
              <StyledPatientDetail className="patient-emr">EMR: {patient.emrInstance || '-'}</StyledPatientDetail>
            </div>
          </Stack>
        </StyledLink>
      </Tooltip>
    </div>
  );
};

export default RecentPatientBlock;
