import './CarePlanDashboard.scss';

// eslint-disable-next-line no-use-before-define
import React, { Fragment, useContext, useState, useRef } from 'react';
import { changesFromLastEdited, hasSubmitted } from 'op-pages/RO/Careplan/Banner/Banner';
import { getSiteRepresentation } from 'op-pages/RO/Careplan/Common';
import classNames from 'classnames';
import { CareplanStatusFilter } from 'op-enums';
import { ErrorCircleFill } from 'shared-components/images';
import { Pagination } from 'shared-components/components';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import Table, { Props } from 'shared-components/components/Table/Table';
import { UserContext } from 'op-contexts';
import moment from 'moment';
import { styled } from '@mui/system';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { CustomTheme, useTheme } from '@mui/material';
import { Search } from '@mui/icons-material';

const TABLE_KEYS = {
  PATIENT_NAME: 'patientName',
  DEPARTMENT: 'treatingDepartment',
  CAREPLAN: 'careplan',
  STATUS: 'status',
  PRIORITY: 'priority',
  SUBMITTED_BY: 'submittedBy',
  LAST_MODIFIED: 'lastModified',
  LAST_SUBMITTED: 'lastSubmitted',
};
const TABLE_LABELS = {
  PATIENT_NAME: 'Patient name',
  DEPARTMENT: 'Department',
  CAREPLAN: 'Careplan',
  STATUS: 'Status',
  PRIORITY: 'Priority',
  SUBMITTED_BY: 'Submitted by',
  LAST_MODIFIED: 'Last modified',
  LAST_SUBMITTED: 'Last submitted',
};

const dateTimeFormat = CurrentAppConfig.RadiationDashboard.dateTimeFormat;

interface DropDownObject {
  id: string;
  name: string;
  checked: boolean;
}

interface NameProps {
  careplan: any;
  showLink: boolean;
  onOpenCareplan?: (urlExtension: string) => void;
}

interface TableProps {
  onPageChange: any;
  onOrderChange: any;
  currentPage: number;
  totalPages: number;
  carePlans: any[];
  orderingDirection: string;
  orderType: string;
  onRowClick?: (_careplanUrl: string) => void;
  tableProps: Props;
}

interface InputProps {
  inputValue: string;
  id: string;
  placeholder: string;
  onSubmitSearch: any;
}

const SearchInput = (props: InputProps): JSX.Element => {
  const [currentTextValue, setCurrentTextValue] = useState(props.inputValue);
  const searchRef = useRef<HTMLInputElement>(null);

  const changeSearchTerm = (searchTerm: string) => {
    setCurrentTextValue(searchTerm);
  };

  const submitSearch = (keyType: string, searchTerm: string) => {
    if (keyType === 'Enter') {
      props.onSubmitSearch(searchTerm);
    }
  };

  const clearSearch = () => {
    setCurrentTextValue('');
    props.onSubmitSearch('');
  };

  const { id, placeholder } = props;
  return (
    <div className="input-search-wrapper">
      {/* <div onClick={() => submitSearch('Enter', currentTextValue)} className="search-button"></div>
       */}
      <Search onClick={() => submitSearch('Enter', currentTextValue)} color="primary" sx={{ margin: '0 4px' }} />
      <input
        ref={searchRef}
        value={currentTextValue}
        type="text"
        id={id}
        className={classNames('patient-gc-search')}
        placeholder={placeholder}
        onChange={(e) => changeSearchTerm(e.target.value)}
        onKeyDown={(e) => submitSearch(e.key, (e.target as HTMLInputElement).value)}
      />
      <span
        onClick={() => clearSearch()}
        className={classNames('clear-search-input', { 'clear-visible': currentTextValue !== '' })}
        aria-label="Clear Search">
        &times;
      </span>
    </div>
  );
};

const TableData = styled('div')`
  line-height: 24px;
`;

const PatientNameWrapper = styled('div')<{ showLink: boolean }>`
  ${({ showLink, theme }: { showLink: boolean; theme: any }) =>
    showLink &&
    `
    :hover {
      color: ${theme.palette.info.main};
      text-decoration: underline;
      font-weight: 400;
    }`}
`;

const StatusTableData = styled(TableData)`
  display: flex;
  align-items: center;
`;

const StatusDot = styled('div')<{ $color: string }>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${({ $color }: { $color: string }) => $color};
`;

const UnsubmittedTooltip = styled('div')`
  color: ${(props) => props.theme.palette.error.main};
  display: flex;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
`;

const getTemplateGroupRepresentation = (template: any) => {
  const values = template.siteSet
    .map((site: any, count: number) => (count < 3 ? getSiteRepresentation(site) : null))
    .filter(Boolean);

  const siteString = template.siteSet.length - values.length === 1 ? 'site' : 'sites';

  return template.siteSet.length - values.length !== 0
    ? values.join(', ') + ` + ${template.siteSet.length - values.length} ${siteString}`
    : values.join(', ');
};

const NameComponent = (props: NameProps): JSX.Element => {
  const { careplan, showLink, onOpenCareplan } = props;
  return (
    <div className={'patient-name-col'}>
      <div className="photo-widget">
        <PhotoWidget url={careplan.patient?.photoUrl} enableOverlay={true} size={'sm'} />
      </div>
      <TableData>
        <PatientNameWrapper
          role="presentation"
          showLink={showLink}
          onClick={(e) => {
            e.stopPropagation();
            props.showLink && onOpenCareplan && onOpenCareplan(`/radiation/patient/${careplan.patient.id}/summary`);
          }}>
          {careplan.patient.fullName}
        </PatientNameWrapper>
        <div>Patient ID: {careplan.patient.ida}</div>
      </TableData>
    </div>
  );
};

const CareplanNameComponent = ({ careplan }: any): JSX.Element => {
  const userContext = useContext(UserContext);
  let careplanName = '';
  careplan.sitegroupSet.forEach((template: any, index: number) => {
    if (index > 0 && careplanName !== '') {
      careplanName += ', ';
    }
    careplanName += getTemplateGroupRepresentation(template);
  });

  const hasUnsubmittedChanges = hasSubmitted(careplan, userContext.state.timezone) && changesFromLastEdited(careplan);

  if (careplanName === '') {
    careplanName = `${careplan?.diagnosis?.diagnosisCode?.diagnosisCode}: ${careplan?.diagnosis?.diagnosisCode?.diagnosisDescription}`;
  }
  return (
    <TableData>
      {careplanName}
      {hasUnsubmittedChanges && (
        <UnsubmittedTooltip>
          <ErrorCircleFill /> Unsubmitted changes
        </UnsubmittedTooltip>
      )}
    </TableData>
  );
};

const StatusComponent = ({ careplan }: any): JSX.Element => {
  const theme: CustomTheme = useTheme();
  const status =
    careplan.careplanStatus === 'CPOT'
      ? 'CPOT'
      : careplan.careplanStatus
          .toLowerCase()
          .replace('_', ' ')
          .split(' ')
          .map((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
          .join(' ');

  const statusColors: any = {
    [CareplanStatusFilter.DRAFT]: theme.palette.secondary.dark,
    [CareplanStatusFilter.PRESCRIPTION]: theme.palette.statusColors.prescription,
    [CareplanStatusFilter.SIMULATION]: theme.palette.statusColors.simulation,
    [CareplanStatusFilter.PLAN_AIM]: theme.palette.statusColors.plan,
    [CareplanStatusFilter.PRESCRIPTION_DIRECTIVE]: theme.palette.statusColors.plan,
    [CareplanStatusFilter.CPOT]: theme.palette.statusColors.cpot,
  };

  return (
    <StatusTableData>
      <StatusDot $color={statusColors[status]} />
      {status}
    </StatusTableData>
  );
};

const PriorityComponent = ({ careplan }: any): JSX.Element => {
  const priorityMapping = {
    standard: 'Standard',
    urgent: 'Urgent',
    emerg: 'Emergency',
  };
  const careplanPriority = careplan?.priority ? priorityMapping[careplan.priority as keyof typeof priorityMapping] : '';
  return <TableData>{careplanPriority}</TableData>;
};

const getFormattedDateTime = (dateTime: string) => {
  return moment(dateTime).isSame(moment(), 'day')
    ? 'Today ' + moment(dateTime).format('hh:mm A')
    : moment(dateTime).format(dateTimeFormat);
};

const LastModifiedComponent = ({ careplan }: any): JSX.Element => {
  const lastModified = getFormattedDateTime(careplan.updatedAt.toString());
  return <TableData>{lastModified}</TableData>;
};

const SubmittedByComponent = ({ careplan }: any): JSX.Element => {
  return <TableData>{careplan.createdBy}</TableData>;
};

const DepartmentComponent = ({ careplan }: any): JSX.Element => {
  const treatingDepartment = careplan.treatingDepartment || '-';
  return <TableData>{treatingDepartment}</TableData>;
};

const SubmittedAtComponent = ({ careplan }: any): JSX.Element => {
  const submittedAt = getFormattedDateTime(careplan.submittedAt?.toString());
  return <TableData>{submittedAt}</TableData>;
};

const ROCarePlanTable = (props: TableProps): JSX.Element => {
  const { onPageChange, currentPage, totalPages, tableProps } = props;

  return (
    <Fragment>
      <Table {...tableProps} defaultSortState={tableProps.defaultSortState} />
      {totalPages > 1 && (
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange}></Pagination>
      )}
    </Fragment>
  );
};

export default ROCarePlanTable;
export {
  DropDownObject,
  SearchInput,
  NameComponent,
  CareplanNameComponent,
  StatusComponent,
  PriorityComponent,
  LastModifiedComponent,
  SubmittedByComponent,
  SubmittedAtComponent,
  DepartmentComponent,
  StatusDot,
  StatusTableData,
  TableData,
  TableProps,
  TABLE_KEYS,
  TABLE_LABELS,
};
