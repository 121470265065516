import { FormStatus, DistressThermometerVersion } from 'op-enums';
import { toFormToVersion } from 'op-utils';

const REVIEWED_OR_SUBMITTED = [FormStatus.REG_REVIEW_REQUIRED, FormStatus.REG_SUBMITTED];
interface NextForm {
  path: string;
  name: string;
}

export const getPathName = (
  form: string,
  patientId: string,
  distressId: string,
  lastVisitedSection: string,
  showNewRego: boolean,
): NextForm => {
  let nextForm = { name: '', path: '' };

  const newRegoLink = `/patient/${patientId}/registration/${lastVisitedSection ? lastVisitedSection : 'infonotice'}`;
  const oldRegoLink = `/registration/${patientId}/${lastVisitedSection ? lastVisitedSection : 'basic'}`;

  switch (form) {
    case 'covid':
      nextForm = {
        name: 'covidScreening',
        path: `/patient/${patientId}/covid/information`,
      };
      break;
    case 'registration':
      nextForm = {
        name: 'registration',

        path: showNewRego ? newRegoLink : oldRegoLink,
      };
      break;
    case 'healthAssessment':
      nextForm = {
        name: 'healthAssessment',
        path: `/patient/${patientId}/health/info`,
      };
      break;
    case 'distress':
      nextForm = {
        name: 'distressThermometer',
        path: `/patient/${patientId}/distress/${distressId}/information`,
      };
      break;
    case 'theranostics':
      nextForm = {
        name: 'theranostics',
        path: '/server/forms/theranostics',
      };
      break;
    default:
      nextForm = {
        name: 'home',
        path: `/patient/${patientId}/home`,
      };
  }
  return nextForm;
};

export const calculateNextForm = (
  currentForm: string,
  goToReg: boolean,
  goToCovid: boolean,
  goToHa: boolean,
  goToTha: boolean,
  goToDt: boolean,
): string => {
  const possibleForms = [
    {
      name: 'covid',
      goTo: goToCovid,
      theranostics: false,
    },
    {
      name: 'registration',
      goTo: goToReg,
      theranostics: true,
    },
    {
      name: 'healthAssessment',
      goTo: goToHa,
      theranostics: true,
    },
    {
      name: 'distress',
      goTo: goToDt,
      theranostics: false,
    },
    {
      name: 'theranostics',
      goTo: goToTha,
      theranostics: true,
    },
  ];

  const filteredForms = possibleForms.filter((form) => form.name !== currentForm && form.goTo);
  const thaNextForm = filteredForms.find((form) => form.theranostics);
  // If there is no next form return home (and ensure that THA patients do not go to covid screening)
  if (goToTha && thaNextForm) {
    return thaNextForm.name;
  } else if (!goToTha && filteredForms[0]) {
    return filteredForms[0].name;
  } else {
    return 'home';
  }
};

export interface NextFormData {
  profile: {
    toForm: string;
    showDistressThermometer: boolean;
    patientFeatures: {
      covidEnabled: boolean;
    };
  };
  patient: {
    lastVisitedSection: string;
    regFormStatus: FormStatus;
    userProfile: {
      systemState: string;
    };
  };
  covidScreening?: {
    status?: FormStatus;
  };
  healthAssessment: {
    status: string;
  };
  distressThermometerInitial: {
    status: string;
    id: string;
  };
  distressThermometerEot: {
    status: string;
    id: string;
  };
  theranostics: {
    status: string;
    id: string;
  };
}

export const getNextForm = (
  data: NextFormData,
  patientId: string,
  currentForm: string,
  showNewRego = false,
): NextForm => {
  if (data) {
    const covidEnabled = data?.profile?.patientFeatures?.covidEnabled || false;
    const lastVisitedSection = data?.patient?.lastVisitedSection;
    const goToReg = !REVIEWED_OR_SUBMITTED.includes(data?.patient?.regFormStatus);
    const goToCovid = !(
      REVIEWED_OR_SUBMITTED.includes(data?.covidScreening?.status || FormStatus.CREATED) || !covidEnabled
    );
    const goToHa = data?.healthAssessment?.status !== FormStatus.REG_SUBMITTED;
    const goToTha =
      data?.patient?.userProfile?.systemState === 'THA' && data?.theranostics?.status !== FormStatus.REG_SUBMITTED;
    const dtVersion = toFormToVersion(data?.profile?.toForm);
    const dtForm =
      dtVersion === DistressThermometerVersion.INITIAL
        ? data?.distressThermometerInitial
        : data?.distressThermometerEot;

    const goToDt = dtForm?.status !== FormStatus.REG_SUBMITTED && data?.profile?.showDistressThermometer;
    const distressId = dtForm?.id;

    const nextFormName = calculateNextForm(currentForm, goToReg, goToCovid, goToHa, goToTha, goToDt);
    return getPathName(nextFormName, patientId, distressId, lastVisitedSection, showNewRego);
  } else {
    // If browser refresh is hit and context is lost, redirect to home
    return {
      name: 'home',
      path: `/patient/${patientId}/home`,
    };
  }
};
