import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/system';

interface OptionType {
  label: string;
  value: string;
}

interface Props {
  options: OptionType[];
  placeholder: string;
  selectedOptionsUpdated(options: any): void;
  resetSearchResults(): void;
  defaultSelectedOptions?: string[] | number[];
  allSelected?: boolean;
  allSelectedLabel?: string;
  selectAllEnabled?: boolean;
  selectAllLabel?: string;
}

const StyledLabel = styled(InputLabel)`
  &.MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.palette.primary.dark};
  },
`;

const StyledInput = styled(Input)`
  &.MuiInput-underline:after {
    border-bottom: 2px solid ${(props) => props.theme.palette.primary.dark};
  }
`;

const FieldWrapper = styled('div')`
  display: inline-flex;
  flex-wrap: wrap;
`;

const ChipsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const StyledChip = styled(Chip)`
  && {
    margin: 2px;
  }
`;

const StyledFormControl = styled(FormControl)`
  && {
    min-width: 200px;
    max-width: 400px;
  }
`;

const StyledSelect = styled(Select)`
  && {
    margin: 16px 8px 8px 0px;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
&& {
  &.Mui-checked {
    color: ${(props) => (props.checked ? props.theme.palette.primary.dark : 'white')};
  }
}}
`;

const StyledClose = styled('div')`
  cursor: pointer;
`;

const arrayMatch = (a: Array<any>, b: Array<any>) => {
  [...a] = a.sort();
  [...b] = b.sort();
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
};

const SearchMultiSelectField = ({
  options,
  placeholder,
  selectedOptionsUpdated,
  resetSearchResults,
  defaultSelectedOptions,
  allSelected,
  allSelectedLabel,
  selectAllEnabled,
  selectAllLabel,
}: Props): JSX.Element | null => {
  const allOptions: string[] = [];

  const [selectedItems, setSelectedItems] = React.useState<any>(defaultSelectedOptions || []);
  const [selectAllStatus, setSelectAllStatus] = React.useState<boolean>(false);
  const [isSelectOpen, setIsSelectOpen] = React.useState<boolean>(false);

  useEffect(() => {
    setSelectedItems(defaultSelectedOptions);
    if (defaultSelectedOptions && defaultSelectedOptions.length && arrayMatch(allOptions, defaultSelectedOptions)) {
      setSelectAllStatus(true);
    }
  }, [defaultSelectedOptions, allOptions]);

  if (!options?.length) return null;
  options?.forEach((option: OptionType) => {
    allOptions.push(option.value);
  });

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = (event.target.value as string[]) || '';
    if (value[0] === 'SelectAll' || value.indexOf('SelectAll') > -1) {
      handleSelectAll(allOptions);
      return;
    }
    setSelectedItems(value);
    selectedOptionsUpdated(value);
    resetSearchResults();
    if (arrayMatch(value, allOptions)) {
      setSelectAllStatus(true);
    } else {
      setSelectAllStatus(false);
    }
  };

  const handleSelectAll = (value: any) => {
    setSelectAllStatus(!selectAllStatus);
    setSelectedItems(!selectAllStatus ? value : []);
    selectedOptionsUpdated(!selectAllStatus ? value : []);
    resetSearchResults();
  };

  const handleRender = (selected: any) => {
    return (
      <ChipsWrapper>
        {allSelected === true && allSelectedLabel && selected.length > 1 ? (
          <StyledChip key={allSelectedLabel} label={allSelectedLabel} />
        ) : (
          selected.map((value: string) => {
            // Find the label that matches the value
            const label = options.find((option: OptionType) => option.value === value)?.label;
            return <StyledChip key={value} label={label} />;
          })
        )}
      </ChipsWrapper>
    );
  };

  return (
    <FieldWrapper>
      <StyledFormControl>
        <StyledLabel id={`search-multi-select-${placeholder}`}>{placeholder}</StyledLabel>
        <StyledSelect
          multiple
          data-test-id={`search-filter-${placeholder}`}
          value={selectedItems}
          onChange={handleChange}
          input={<StyledInput />}
          placeholder={placeholder}
          renderValue={handleRender}
          open={isSelectOpen}
          onOpen={(): void => setIsSelectOpen(true)}
          onClose={(): void => setIsSelectOpen(false)}>
          <Box display="flex" justifyContent="space-between" p={1} mx={1}>
            <span style={{ color: 'grey' }}>
              <em>{placeholder}</em>
            </span>
            <StyledClose
              data-test-id={`close-${placeholder}`}
              onClick={(e) => {
                e.stopPropagation();
                setIsSelectOpen(false);
              }}>
              <CloseIcon color="primary" />
            </StyledClose>
          </Box>

          {selectAllEnabled && (
            <MenuItem
              key="SelectAll"
              value="SelectAll"
              onChange={() => {
                handleSelectAll(allOptions);
              }}>
              <StyledCheckbox checked={selectAllStatus} />
              <ListItemText primary={selectAllLabel ? selectAllLabel : 'Select all'} />
            </MenuItem>
          )}
          {options?.map((option: OptionType) => (
            <MenuItem data-test-id={`${placeholder}-${option.label}`} key={option.label} value={option.value}>
              <StyledCheckbox checked={selectedItems.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </FieldWrapper>
  );
};

export default SearchMultiSelectField;
