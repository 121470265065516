import React from 'react';
import { styled } from '@mui/system';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

const StyledGeneralError = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const StyledErrorText = styled('span')`
  margin-top: 20px;
  font-size: 1.125rem;
  font-weight: 700;
  color: ${(props) => props.theme.palette.secondary.dark};
  text-align: center;
  padding: 0 15px;
`;

const StyledErrorSecondaryText = styled('span')`
  margin-top: 6px;
  font-size: 1.125rem;
  font-weight: 700;
  color: ${(props) => props.theme.palette.secondary.dark};
  text-align: center;
  margin-bottom: 28px;
  padding: 0 15px;
`;

interface Props {
  primaryText?: string;
  secondaryText?: string;
}

const GeneralError = ({ primaryText, secondaryText }: Props): JSX.Element => {
  return (
    <StyledGeneralError>
      <ErrorOutlineIcon />
      <StyledErrorText>{primaryText ? primaryText : 'Something went wrong.'}</StyledErrorText>
      <StyledErrorSecondaryText>{secondaryText ? secondaryText : 'Please refresh the page.'}</StyledErrorSecondaryText>
    </StyledGeneralError>
  );
};

export default GeneralError;
