// import React from 'react'; //, useState
import { FormBuilderValidatorType, FormBuilderComponentConfig } from './FormBuilderInterfaces';
import * as Yup from 'yup';
// import { FormikErrors, FormikProps, FormikTouched, useFormikContext } from 'formik';
import { fieldMeetsConditions } from './common';
import { EMAIL_REQUIRED, VALUE_REQUIRED } from 'op-pages/Shared/constants';
import { FormikValues } from 'formik';
import { AnySchema } from 'yup';

type NumberYupType = Yup.NumberSchema<number | null | undefined, object>;

function getYupType(
  visibleConditions: Record<string, FormBuilderValidatorType> | boolean,
  validator: [string, boolean | Record<string, FormBuilderValidatorType>],
  component: FormBuilderComponentConfig, // ,ComponentInterface
  objectValueDataType?: string,
): AnySchema {
  switch (objectValueDataType ?? component.field.dataType) {
    case 'date':
      return Yup.date().nullable().typeError('You must enter a valid date');
    case 'number':
      return Yup.number().nullable().typeError('You must enter a number');
    case 'mixed':
      return Yup.mixed().nullable();
    // Not used, may never be, going in the Typescript too hard basket for now
    // case 'array':
    //   return Yup.array().nullable();
    case 'boolean':
      return Yup.bool().nullable();
    case 'object':
      // TODO Validating values stored inside object seems feasible, but is not working at this stage
      return Yup.object()
        .shape({
          value: getValidation(getYupType(visibleConditions, validator, component, 'string'), validator, component),
        })
        .nullable();
    default:
      return Yup.string().nullable();
  }
}

function addRequiredValidation(yupType: AnySchema, component: FormBuilderComponentConfig) {
  return yupType.test('Required', VALUE_REQUIRED, function (value) {
    if (fieldMeetsConditions(this.parent, component.visible)) {
      if (fieldMeetsConditions(this.parent, component.validators['required'])) {
        const passesTest = value !== undefined && value !== null && value !== false && value !== 'false';
        return passesTest;
      }
    }
    return true;
  });
}

function addConditionalRangeValidation(yupType: AnySchema, component: FormBuilderComponentConfig, fieldName: string) {
  const value = Object.entries(component.visible).filter(([key]) => {
    return key === fieldName;
  })[0][1];
  //@ts-ignore
  const message = `Value must be between ${component.validators['range'][0]} and ${component.validators['range'][1]}`;
  return yupType.when(fieldName, {
    is: value,
    then: () =>
      Yup.number()
        .typeError('You must enter a number')
        // @ts-ignore
        .min(component.validators['range'][0], message)
        // @ts-ignore
        .max(component.validators['range'][1], message),
    otherwise: () => yupType.notRequired(),
  });
}

function getValidation(
  yupType: AnySchema,
  validator: [string, boolean | Record<string, FormBuilderValidatorType>],
  component: FormBuilderComponentConfig,
) {
  const conditionalVisibility = Object.keys(component.visible ?? {});
  // end early for object type, another call for the object's value will be triggered
  if (component.field.dataType === 'object') return yupType;

  let message = 'Unknown validation error';
  switch (validator[0]) {
    case 'required':
      yupType = addRequiredValidation(yupType, component);
      return yupType;
    case 'range':
      if (validator[1]) {
        if (conditionalVisibility.length > 0) {
          conditionalVisibility.forEach((fieldName) => {
            yupType = addConditionalRangeValidation(yupType, component, fieldName);
          });
        } else {
          //@ts-ignore
          message = `Value must be between ${component.validators['range'][0]} and ${component.validators['range'][1]}`;
          return (
            (yupType as NumberYupType)
              .typeError('You must enter a number')
              //@ts-ignore
              .min(component.validators['range'][0], message)
              //@ts-ignore
              .max(component.validators['range'][1], message)
          );
        }
        return yupType;
      } else {
        //@ts-ignore
        message = `Value must be between ${validator[1][0]} and ${validator[1][1]}`;
        return (
          Yup.number()
            .typeError('You must enter a number')
            //@ts-ignore
            .min(validator[1][0] as number, message)
            //@ts-ignore
            .max(validator[1][1] as number, message)
        );
      }
    case 'max':
      message = `Value must be less than or equal to ${validator[1]}`;
      if (typeof validator[1] === 'string') return Yup.number().max(validator[1] as number, message);
      // @ts-ignore
      else return Yup.number().max(validator[1][0] as number, message);

    case 'min':
      message = `Value must be less than or equal to ${validator[1]}`;
      if (typeof validator[1] === 'string') return Yup.number().min(validator[1] as number, message);
      // @ts-ignore
      else return Yup.number().max(validator[1][0] as number, message);
    case 'email':
      return Yup.string().email(EMAIL_REQUIRED);
    case 'phone':
      return Yup.string().phone();
    default:
      return yupType;
  }
}

function generateFieldValidationSchema(
  schema: Record<string, AnySchema>,
  components: FormBuilderComponentConfig[],
  fieldValues: FormikValues,
): Record<string, AnySchema> {
  components.forEach((component) => {
    if (component.validators) {
      let yupConditions: any = undefined; // type here is a bit tricky, and not that critical so leaving it
      Object.entries(component.validators).forEach((validator) => {
        // continually adds to one existing yup type (if it exists) in order to chain mulitple yup validators into a single field..
        yupConditions = getValidation(
          yupConditions ?? getYupType(component.visible, validator, component),
          validator,
          component,
        );
      });
      schema[component.field.name] = yupConditions;
    }

    if (component.subComponents) {
      return generateFieldValidationSchema(schema, component.subComponents, fieldValues);
    }
  });
  return schema;
}

export function generateValidationSchema(components: FormBuilderComponentConfig[], values: FormikValues) {
  let schema = {};
  schema = generateFieldValidationSchema(schema, components, values);

  return Yup.object().shape(schema);
}
