// eslint-disable-next-line no-use-before-define
import { ContentTile, MainLayout } from 'op-pages/PX/Components';
import { DeviceUtilities } from 'op-pages/PX/utils';
import { GCButton } from 'shared-components/components/FormFields';

import { faq } from 'shared-components/images/';
import { FAQ_CONSTANTS } from './faqConstants';

import { useHistory } from 'react-router-dom';
import './FAQHome.scss';
import { Grid } from '@mui/material';

const FAQHome = (): JSX.Element => {
  const backgroundImageStyle = {
    backgroundImage: `linear-gradient(rgba(46, 46, 46, 0.5), rgba(46, 46, 46, 0.5)), url(${faq})`,
  };

  const history = useHistory<History>();
  const handleBackButtonPressed = (): void => history.replace('/px/information');

  return (
    <MainLayout clickLogoFn={handleBackButtonPressed}>
      <div className="px-faq-home-container">
        <div className="px-faq-banner-container" style={backgroundImageStyle}>
          <h1 className="px-faq-heading">Frequently Asked Questions</h1>
        </div>
        <div className="px-faq-home-outer-container" data-test-id="px-faq-home-container">
          <Grid container className="px-faq-home-inner-container">
            {Object.values(FAQ_CONSTANTS).map(
              (faq): JSX.Element => (
                <Grid item xs={12} md={6} lg={3} key={faq.title} style={{ padding: '8px' }}>
                  <ContentTile
                    title={faq.title}
                    url={`/px/information/faq/${faq.name}`}
                    image={faq.image}
                    testId={`${faq.name}-faq-content-tile`}
                    titleClass="faq-content-tile"
                  />
                </Grid>
              ),
            )}
          </Grid>
        </div>
        {DeviceUtilities.isDesktopDevice() && (
          <div className="px-desktop-back-button">
            <GCButton title="Back" id="opx-faq-home-back" onClick={(): void => history.push('/px/information')} />
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default FAQHome;
