// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'gc-ui';
import { Table, TableHead, TableBody, TableRow, styled, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { PatientBasic } from 'op-interfaces';
import './ModalSearchResults.scss';
import { fontWeight } from '@mui/system';

interface Props {
  isOpen: boolean;
  data?: { ukDedupSearch: PatientBasic[] };
  redirect?: string;
  dismissFunction: () => void;
  patient: PatientBasic;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class ModalSearchResults extends Component<Props> {
  public render(): JSX.Element {
    const { isOpen, dismissFunction, patient, data, redirect } = this.props;
    let searchResults: JSX.Element[] = [];

    if (data && data.ukDedupSearch && redirect) {
      searchResults = data.ukDedupSearch
        .filter((searchPatient: PatientBasic): boolean => {
          return searchPatient.id !== patient.id;
        })
        .map((currPatient: PatientBasic): JSX.Element => {
          const redirectTo = redirect.replace('temppatientid', currPatient.id);
          return (
            <StyledTableRow>
              <TableCell className={!currPatient.ida ? 'grey-text' : undefined}>
                {currPatient.ida || 'Not yet in Mosaiq'}
              </TableCell>
              <TableCell>{currPatient.firstName}</TableCell>
              <TableCell>{currPatient.lastName}</TableCell>
              <TableCell>{currPatient.dob}</TableCell>
              <TableCell>
                {[
                  currPatient.residentialAddressLine1,
                  currPatient.residentialAddressCity,
                  currPatient.residentialAddressPostCode,
                ]
                  .filter((item): boolean => !!item)
                  .join(', ')}
              </TableCell>
              <TableCell>
                <a href={redirectTo}>View patient</a>
              </TableCell>
            </StyledTableRow>
          );
        });
    }
    return (
      <Fragment>
        <Modal open={isOpen} onClose={dismissFunction.bind(this)} className="modal-gc" maxWidth="xl">
          <ModalHeader toggle={dismissFunction.bind(this)}>
            <Typography variant="h5" fontWeight={600}>
              Potential duplicate patients found
            </Typography>
          </ModalHeader>
          <ModalBody>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>GC Number</StyledTableCell>
                  <StyledTableCell>First name</StyledTableCell>
                  <StyledTableCell>Last name</StyledTableCell>
                  <StyledTableCell>Date of birth</StyledTableCell>
                  <StyledTableCell>Address</StyledTableCell>
                  <StyledTableCell>Link</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>{searchResults}</TableBody>
            </Table>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default ModalSearchResults;
