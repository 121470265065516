import React, { useContext, useState } from 'react';
import { styled } from '@mui/system';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { ROPatientContext } from '../context';
import { GET_ALERTS } from 'op-components/PatientCard/PatientCardQueries';

import { TabSelectHeader } from 'shared-components/components/Grid';
import { useTheme } from '@mui/material';

const ClinicalInfoWrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled('div')`
  overflow: auto;
  flex-grow: 1;
  min-height: 0;
  font-size: 16px;
`;

const DxAllergyAlertItem = styled('div')`
  margin-bottom: 16px;
  line-height: 24px;
`;

const BubbleTextContainer = styled('div')`
  display: inline-block;
  padding: 8px 16px;
  background-color: ${(props) => props.theme.palette.secondary.light};
  color: black;
  font-weight: bold;
  font-size: 16px;
  border-radius: 16px;
  margin-bottom: 8px;
`;

const ClinicalInformation = (): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);
  const theme = useTheme();
  const { patientDetails, setPatientAlerts } = useContext(ROPatientContext);
  const match = useRouteMatch<any>();
  const { id: patientId } = match.params;
  const { data: alertsData } = useQuery(GET_ALERTS, {
    variables: { patientId },
    onCompleted: (data) => setPatientAlerts(data?.alerts),
  });

  const diagnosis = patientDetails?.carepathPatientDiagnosis;
  const allergies = patientDetails?.allergy.filter((allergyInfo: any) => allergyInfo.status === 'Active');
  const alerts = alertsData?.alerts;

  const filteredDiagnosis = diagnosis?.filter((dx: any) => dx.isMostRecent && dx.medId !== null);
  const isNoKnownAllergy = allergies?.some(
    (allergy: any): boolean =>
      allergy.name && ['no known drug allergies', 'no known allergies'].includes(allergy.name.toLowerCase()),
  );

  const DiagnosisTab = () =>
    filteredDiagnosis?.length ? (
      filteredDiagnosis.map((item: any, index: number) => {
        return (
          <DxAllergyAlertItem key={`diagnosis-${index}`}>
            <BubbleTextContainer>
              {index + 1}. {item.diagnosisCode.diagnosisCode} {item.isPrimaryDiagnosis ? 'Primary' : 'Metastasis'}
            </BubbleTextContainer>
            <div>{`${item.diagnosisCode.tumourStream.name} - ${item.diagnosisCode.diagnosisDescription}`}</div>
          </DxAllergyAlertItem>
        );
      })
    ) : (
      <p>No diagnoses available</p>
    );

  const AllergyTab = () => {
    if (allergies && allergies.length === 0) return <p>No allergies available </p>;
    return allergies.map((allergy: any, index: number) => (
      <DxAllergyAlertItem key={`allergy-${index}`}>
        <BubbleTextContainer>
          {index + 1}. {allergy.name}
        </BubbleTextContainer>
        <div>
          Severity: <strong>{allergy.severity}</strong>
        </div>
        <div>
          Notes: <strong>{allergy.reaction}</strong>
        </div>
      </DxAllergyAlertItem>
    ));
  };

  const AlertsTab = () =>
    alerts?.length > 0 ? (
      alerts.map((alert: any, index: number) => (
        <DxAllergyAlertItem key={`alert-${index}`}>
          <BubbleTextContainer>
            {index + 1}. {alert.name}
          </BubbleTextContainer>
          <div>
            Status: <b>{alert.status}</b> | Assigned: <b>{alert.assigned && moment.utc(alert.assigned).format('L')}</b>
          </div>
          <div>
            Notes: <b>{alert.comment}</b>
          </div>
        </DxAllergyAlertItem>
      ))
    ) : (
      <p>No alerts available</p>
    );

  const tabOptions = [
    {
      text: 'Diagnosis',
      notification: {
        color: theme.palette.secondary.dark,
        text: filteredDiagnosis?.length || 0,
      },
      component: <DiagnosisTab />,
    },
    {
      text: 'Allergies',
      notification: {
        color: isNoKnownAllergy
          ? theme.palette.secondary.dark
          : !isNoKnownAllergy && allergies?.length > 0
          ? theme.palette.error.main
          : theme.palette.warning.dark,
        text: allergies?.length && isNoKnownAllergy ? 'N' : allergies?.length > 0 ? allergies.length : '?',
      },
      component: <AllergyTab />,
    },
    {
      text: 'Alerts',
      notification: {
        color: alerts?.length > 0 ? theme.palette.error.main : theme.palette.warning.dark,
        text: alerts?.length || '?',
      },
      component: <AlertsTab />,
    },
  ];

  if (!patientDetails) return <div>Loading...</div>;

  return (
    <ClinicalInfoWrapper>
      <TabSelectHeader
        title={'Clinical Information'}
        options={tabOptions}
        onClickHandler={() => {}}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      <ContentContainer>{tabOptions[activeIndex].component}</ContentContainer>
    </ClinicalInfoWrapper>
  );
};

export default ClinicalInformation;
