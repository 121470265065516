// eslint-disable-next-line no-use-before-define
import React, { useRef } from 'react'; //, useState, { useRef } useCallback, useEffect,
import { Formik, Form as FormikForm, FormikProps, FormikHelpers, FormikValues } from 'formik'; //
import { FlatFormConfigInterface } from './FormBuilderInterfaces';
import { preprocessConfig } from './PreprocessConfig';
import { fetchForm } from '../Outcomes/Backend';
import { generateValidationSchema } from './FBValidation';
import FBComponents from './FBComponents';
import FormBuilderFooter from './FormBuilderFooter';
import DebugPanel from './DebugPanel';
import { Grid } from '@mui/material';
import { sleepAsync } from './common';
import _ from 'lodash';

interface FormBuilderProps {
  formInstance?: FlatFormConfigInterface;
  formName?: string; //TODO EVE-10650. To remove, temporarily used as backdoor for FormBuilderPage
  onBack?: () => void;
  onSubmit?: () => void;
  autosaveMethod?: (values: any) => void;
  debug?: boolean;
  displayFooter?: boolean;
  children?: JSX.Element;
}

const FormBuilderForm = (props: FormBuilderProps): JSX.Element => {
  const { onBack = () => {}, autosaveMethod, children, onSubmit, formInstance, formName } = props;
  const { components, options, values } = formInstance ? preprocessConfig(formInstance) : fetchForm(formName || ''); //TODO EVE-10650 remove backend workaround
  const valuesSubmittedRef = useRef(values);

  const handleSubmit = async (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>, retries = 0) => {
    const retryLimit = 5;
    formikHelpers.setSubmitting(true);
    if (retries >= retryLimit) {
      formikHelpers.setSubmitting(false);
    } else {
      // wait for submittedValues to match form values (delay for autoSave)
      if (_.isEqual(values, valuesSubmittedRef.current)) {
        onSubmit ? onSubmit() : sleepAsync(500); //sleep is for tests
        formikHelpers.setSubmitting(false);
      } else {
        // wait for backend to catch up to front end (via autosave)
        await sleepAsync(500);
        handleSubmit(values, formikHelpers, ++retries);
      }
    }
  };

  return (
    <Formik
      style={{ overflow: 'auto', height: '100%' }}
      initialValues={values}
      validateOnMount={true}
      validationSchema={generateValidationSchema(components, values)}
      validateOnBlur={true}
      onSubmit={handleSubmit}>
      {(formikProps: FormikProps<any>) => {
        const { isSubmitting, handleSubmit, errors, submitCount, touched, values } = formikProps;
        // TODO EVE-10694 remove inline styling
        return (
          <FormikForm style={{ overflow: 'auto', height: '100%' }} onSubmit={handleSubmit}>
            <Grid container style={{ overflow: 'auto', height: '100%' }} direction="column">
              <Grid
                item
                style={{
                  flex: '1',
                  overflow: 'auto',
                  padding: '16px 16px 0 16px',
                  overflowX: 'hidden', //TODO EVE-10694. Bad, width >100% when components are rendered not sure why
                }}>
                <FBComponents
                  autosaveMethod={autosaveMethod}
                  formikProps={formikProps}
                  components={components}
                  options={options}
                />
                <DebugPanel values={values} debug={props.debug} touched={touched} errors={errors} />
                {children}
              </Grid>
              {props.displayFooter && (
                <FormBuilderFooter
                  onNext={handleSubmit}
                  nextText={'Submit'}
                  onBack={onBack}
                  onReset={() => {
                    // the below ensures formik values are retained after reset on Formbuilder page (reset is touched/submitted only)
                    valuesSubmittedRef.current = values;
                    formikProps.resetForm();
                    formikProps.setValues(valuesSubmittedRef.current);
                  }}
                  nextDisabled={isSubmitting || (Object.entries(errors).length > 0 && submitCount > 0)}
                />
              )}
            </Grid>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
export default FormBuilderForm;
