// eslint-disable-next-line no-use-before-define
import React from 'react';
import { styled } from '@mui/system';
import MuiPopper, { PopperPlacementType } from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { getRemSize } from 'shared-components/StyledComponents/functions';

export interface PopoverProps {
  id: string;
  title: string;
  open: boolean;
  placement?: PopperPlacementType;
  anchorEl: any;
  handleClickAway: () => void;
  fadeTimeout?: number;
  triangleLeftPos?: string;
  triangleBottomPos?: string;
  trianglePosition?: string;
  triangleRightPos?: string;
  triangleTopPos?: string;
  children: React.ReactNode;
}

interface StyledProps {
  $triangleLeftPos: string;
  $triangleRightPos: string;
  $triangleTopPos: string;
  $triangleBottomPos: string;
  $trianglePosition: string;
}

const Wrapper = styled('div')<StyledProps>`
  position: relative;
  padding: 16px;
  background-color: white;
  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${getRemSize(14)};
  border-radius: 4px;
  width: max-content;
  line-height: 20px;
  top: ${({ $triangleTopPos }: StyledProps): string => $triangleTopPos};
  right: ${({ $triangleRightPos }: StyledProps): string => $triangleRightPos};
  left: ${({ $triangleLeftPos }: StyledProps): string => $triangleLeftPos};
  bottom: ${({ $triangleBottomPos }: StyledProps): string => $triangleBottomPos};
  max-width: 340px;
  box-shadow: 0px 2px 8px ${(props: any) => props.theme.palette.secondary.dark};
  ::before,
  ::after {
    top: -15px;
    left: ${({ $trianglePosition }: StyledProps): string => $trianglePosition};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 16px 16px 16px;
    border-color: transparent transparent white transparent;
    content: '';
    position: absolute;
    display: block;
    filter: drop-shadow(1px 0px 0px lightgray);
  }
  ::before {
    border-color: transparent transparent lightgray transparent;
    top: -16px;
  }
`;

const Header = styled('div')`
  font-weight: bold;
  margin-bottom: 8px;
`;

const Popover = ({
  id,
  open,
  title,
  placement,
  anchorEl,
  handleClickAway,
  fadeTimeout,
  triangleLeftPos,
  triangleRightPos,
  triangleTopPos,
  triangleBottomPos,
  trianglePosition,
  children,
}: PopoverProps): JSX.Element => {
  const styledProps = {
    $triangleLeftPos: triangleLeftPos || '',
    $triangleBottomPos: triangleBottomPos || '',
    $trianglePosition: trianglePosition || '16px',
    $triangleRightPos: triangleRightPos || '20px',
    $triangleTopPos: triangleTopPos || '20px',
  };
  return (
    <MuiPopper id={id} role="dialog" open={open} placement={placement} anchorEl={anchorEl} transition>
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Fade {...TransitionProps} timeout={fadeTimeout}>
            <Wrapper {...styledProps}>
              <Header>{title}</Header>
              {children}
            </Wrapper>
          </Fade>
        </ClickAwayListener>
      )}
    </MuiPopper>
  );
};

export default Popover;
