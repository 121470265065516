import React from 'react';
import { styled } from '@mui/system';
import { InfoCardRowItem } from 'op-interfaces';
import Grid from '@mui/material/Grid';
import { getRemSize } from 'shared-components/StyledComponents/functions';

const Wrapper = styled('div')`
  margin: 26px 0;
  width: 100%;
`;

const Header = styled('div')`
  height: 48px;
  background: ${(props) => props.theme.palette.secondary.light};
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 12px;
  border: 1px solid ${(props) => props.theme.palette.secondary.main};
`;

const SubTitle = styled('span')`
  margin-left: 6px;
  font-size: ${getRemSize(14)};
`;

const Highlight = styled('span')`
  font-weight: bold;
  font-size: ${getRemSize(14)};
`;

const PageHeading = styled('div')`
  font-weight: bold;
  margin: 8px 0 8px 16px;
  font-size: ${getRemSize(16)};
`;

const Row = styled('div')`
  border: 1px solid ${(props) => props.theme.palette.secondary.main};
  border-top: none;
  padding-top: 16px;
  padding-bottom: 8px;
`;

const DetailContainer = styled('div')`
  margin: 16px;
`;

const TitleContainer = styled('div')`
  padding-bottom: 8px;
  font-size: ${getRemSize(14)};
`;

interface Props {
  title: string;
  subTitle: string;
  rowDetails: InfoCardRowItem[][];
  itemSize?: any;
}

const renderDetails = (detail: InfoCardRowItem) => {
  if (typeof detail?.value === 'string') {
    return <Highlight data-test-id={`${detail?.title}-response`}>{detail?.value}</Highlight>;
  }

  return detail?.value?.map((item) => {
    return (
      <>
        <Highlight data-test-id={`${detail.title}-response`}>{item}</Highlight> <br />
      </>
    );
  });
};
const InfoCard = ({ title, subTitle, rowDetails, itemSize = 4 }: Props): JSX.Element => {
  return (
    <Wrapper data-test-id={`${title}-table`}>
      <Header>
        <Highlight data-test-id={title}>{title}</Highlight>
        <SubTitle>{subTitle}</SubTitle>
      </Header>
      {rowDetails
        .filter((item) => item.length > 0)
        .map((row: InfoCardRowItem[], idx: number): JSX.Element => {
          return (
            <Row key={`row-${idx}`}>
              {row.length > 0 && row[0].hasOwnProperty('page') && <PageHeading>{row[0].page}</PageHeading>}
              <Grid container>
                {row.map((detail: InfoCardRowItem, idx: number): JSX.Element => {
                  return (
                    <Grid item xs={12} sm={itemSize} key={`detail-${idx}`}>
                      <DetailContainer>
                        {detail.title && (
                          <TitleContainer data-test-id={`${detail.title}`}>{detail.title}</TitleContainer>
                        )}
                        {renderDetails(detail)}
                      </DetailContainer>
                    </Grid>
                  );
                })}
              </Grid>
            </Row>
          );
        })}
    </Wrapper>
  );
};

export default InfoCard;
