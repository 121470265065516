import React from 'react';
import { StyledTile } from '../Dashboard';
import FormControl from '@mui/material/FormControl';
import { Grid, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useQuery } from '@apollo/client';
import { DIAGNOSIS_TRENDS, PRESCRIPTION_BY_TUMOUR_STREAM, CAREPLAN_USAGE, REFERRING_MD_TRENDS } from './queries';
import { LoadingSpinner } from 'shared-components/components';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import ROSearchFilter from '../CarePlanDashboard/ROFilterMenu';

import DataListTable from './DataListTable';
import MultiSelectWithSearch, { MultiSelectOptions } from '../CarePlanDashboard/MultiSelectWithSearch';

interface CareplanUsageType {
  id: string;
  totalCount: number;
  date: string;
  tumourStream: string;
  clinic: string;
  careplanTemplate: string;
}
interface PrescriptionsByTumourType {
  id: string;
  totalCount: number;
  date: string;
  tumourStream: string;
  clinic: string;
}
interface DiagnosisTrendsType {
  id: string;
  totalCount: number;
  date: string;
  tumourStream: string;
  clinic: string;
  diagnosis: string;
}

interface ReferringMdTrendsType {
  id: string;
  totalCount: number;
  date: string;
  tumourStream: string;
  clinic: string;
  referringMd: string;
}

interface SortableItemCountType {
  count: number;
  label: string;
}

const DATE_RANGE_OPTIONS = [
  { label: 'Clear', value: '' },
  { label: 'Last 3 months', value: 'l3m' },
  { label: 'Year to date', value: 'ytd' },
  { label: 'Last Year', value: 'ly' },
];
const CLINICS = [
  { label: 'Sacramento', value: 'Sacramento' },
  { label: 'Roseville', value: 'Roseville' },
  { label: 'Cameron Park', value: 'Cameron Park' },
  { label: 'Auburn', value: 'Auburn' },
];
const Reports = () => {
  const [selectedDateFilter, setSelectedDateFilter] = React.useState('');
  const [clinicFilters, setClinicFilter] = React.useState<MultiSelectOptions[] | undefined>(undefined);

  const [careplanListData, setCareplanListData] = React.useState<SortableItemCountType[]>([]);
  const [prescriptionListData, setPrescriptionListData] = React.useState<SortableItemCountType[]>([]);
  const [diagnosisListData, setDiagnosisListData] = React.useState<SortableItemCountType[]>([]);
  const [referringMdListData, setReferringMdListData] = React.useState<SortableItemCountType[]>([]);
  const { data: dataDxTrend, loading: loadingDxTrend } = useQuery(DIAGNOSIS_TRENDS, {
    fetchPolicy: 'network-only',
    variables: {
      clinics: clinicFilters?.map((item: any) => item.value).join(',') || '',
      dateRange: selectedDateFilter,
    },
  });

  const { data: dataPrescription, loading: loadingPrescription } = useQuery(PRESCRIPTION_BY_TUMOUR_STREAM, {
    fetchPolicy: 'network-only',
    variables: {
      clinics: clinicFilters?.map((item: MultiSelectOptions) => item.value).join(',') || '',
      dateRange: selectedDateFilter,
    },
  });

  const { data: dataReferringMd, loading: loadingReferringMd } = useQuery(REFERRING_MD_TRENDS, {
    fetchPolicy: 'network-only',
    variables: {
      clinics: clinicFilters?.map((item: MultiSelectOptions) => item.value).join(',') || '',
      dateRange: selectedDateFilter,
    },
  });

  const { data: dataCareplan, loading: loadingCareplan } = useQuery(CAREPLAN_USAGE, {
    fetchPolicy: 'network-only',
    variables: {
      clinics: clinicFilters?.map((item: MultiSelectOptions) => item.value).join(',') || '',
      dateRange: selectedDateFilter,
    },
  });

  const sortByCount = (data: SortableItemCountType[]) => {
    return [...data].sort((a: any, b: any) => b.count - a.count);
  };

  React.useEffect(() => {
    if (dataCareplan && dataCareplan.getCareplanUsage) {
      setCareplanListData(
        sortByCount(
          dataCareplan.getCareplanUsage.map((item: CareplanUsageType) => {
            return {
              count: item.totalCount,
              label: item.careplanTemplate,
            };
          }),
        ),
      );
    }
  }, [dataCareplan]);
  React.useEffect(() => {
    if (dataPrescription && dataPrescription.getPrescriptionsByTumourStream) {
      setPrescriptionListData(
        sortByCount(
          dataPrescription.getPrescriptionsByTumourStream.map((item: PrescriptionsByTumourType) => {
            return {
              count: item.totalCount,
              label: item.tumourStream,
            };
          }),
        ),
      );
    }
  }, [dataPrescription]);
  React.useEffect(() => {
    if (dataDxTrend && dataDxTrend.getDiagnosisTrends) {
      setDiagnosisListData(
        sortByCount(
          dataDxTrend.getDiagnosisTrends.map((item: DiagnosisTrendsType) => {
            return {
              count: item.totalCount,
              label: item.diagnosis,
            };
          }),
        ),
      );
    }
  }, [dataDxTrend]);

  React.useEffect(() => {
    if (dataReferringMd && dataReferringMd.getReferringMdTrends) {
      setReferringMdListData(
        sortByCount(
          dataReferringMd.getReferringMdTrends.map((item: ReferringMdTrendsType) => {
            return {
              count: item.totalCount,
              label: item.referringMd,
            };
          }),
        ),
      );
    }
  }, [dataReferringMd]);

  const handleSelectedDateFilterChange = (event: SelectChangeEvent) => {
    setSelectedDateFilter(event.target.value as string);
  };
  const handleSelectedROFilterChange = (clinicOptions: MultiSelectOptions[]) => {
    setClinicFilter(clinicOptions);
  };

  return (
    <div className="RO-dashboard-wrapper">
      <StyledTile className="careplan-dashboard-wrapper">
        <div className="filter-wrapper" id="careplan-viewer-title">
          <div className="table-title-style">My Clinical Data</div>
        </div>

        <div className="filter-wrapper">
          <div className="table-filters" id="careplan-viewer-filters">
            <ROSearchFilter showAlert={selectedDateFilter !== ''} title="Date filter" id="radiationOncologistFilter">
              <FormControl sx={{ m: 1, minWidth: 150 }} variant="standard">
                <Select
                  labelId="simple-label"
                  id="demo-simple-select-helper"
                  value={selectedDateFilter}
                  label="Date filter"
                  placeholder="Date filter"
                  onChange={handleSelectedDateFilterChange}
                  onKeyDown={(ev: any) => {
                    if (ev.key === 'Enter') {
                      handleSelectedDateFilterChange(ev);
                    }
                  }}>
                  {DATE_RANGE_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ROSearchFilter>
            <ROSearchFilter
              showAlert={clinicFilters ? clinicFilters?.length > 0 : false}
              title="Clinics"
              id="radiationOncologistFilter"
              startIcon={<LocationCityIcon />}>
              <MultiSelectWithSearch
                options={[...CLINICS]}
                id={'multi-select-search-filter-by-practitioner'}
                value={clinicFilters}
                label={'Clinics'}
                placeholder={'Clinics'}
                onChange={handleSelectedROFilterChange}
                disableCloseOnSelect
              />
            </ROSearchFilter>
          </div>
        </div>
        <div className="table-wrapper">
          <Grid container style={{ marginTop: '2px' }}>
            <Grid item xs={3} style={{ paddingRight: '8px' }}>
              <DataListTable dataSetLabel="Referring MD" tableName="Referrals" listData={referringMdListData} />
            </Grid>
            <Grid item xs={3} style={{ paddingRight: '16px', paddingLeft: '8px' }}>
              <DataListTable dataSetLabel="Diagnosis" tableName="Diagnosis trends" listData={diagnosisListData} />
            </Grid>
            <Grid item xs={3} style={{ paddingRight: '16px' }}>
              <DataListTable
                listData={prescriptionListData}
                tableName="Prescriptions by tumour stream"
                dataSetLabel="Tumour stream"
              />
            </Grid>
            <Grid item xs={3} style={{ paddingRight: '8px' }}>
              <DataListTable
                dataSetLabel="Careplan template"
                tableName="Careplan template usage"
                listData={careplanListData}
              />
            </Grid>
          </Grid>
        </div>
      </StyledTile>
    </div>
  );
};

export default Reports;
