import { gql } from '@apollo/client';

export const SEARCH_FILTERS_REF_DATA_QUERY = gql`
  query SearchFilters {
    searchFiltersRefData: listData(category: "searchFilters", removeOutdated: true) {
      id
      name
      appKey
    }
  }
`;

export const PATIENT_SEARCH = gql`
  query PatientSearch($searchTerm: String!, $selectedFilter: [String]!, $locationFilter: [Int]!, $tagFilter: [Int]!) {
    searchPatients(
      searchString: $searchTerm
      selectedFilter: $selectedFilter
      locationFilter: $locationFilter
      tagFilter: $tagFilter
    ) {
      id
      firstName
      lastName
      ida
      dob
      primaryPhone
      primaryPhoneFormatted
      email
      pxOptedIn
      pxOptOutComment
      overallStatus
      horizonCenterId
      address {
        id
        line1
        line2
        city
        state
        country
        postcode
      }
      regFormStatus
      primaryCenterAlias
      emrInstance
      userProfile {
        id
        registrationAccessType
        showDistressThermometer
        showRegistration
        showHealthAssessment
        patientFeatures {
          distressThermometerEotEnabled
          distressThermometerEnabled
          managementPlansEnabled
          covidEnabled
        }
      }
      tags {
        id
        tag {
          id
          name
        }
      }
    }
    user {
      id
    }
  }
`;

export const GET_PATIENT_PIN = gql`
  mutation getPin($id: ID!, $toForm: String) {
    getPin(id: $id, toForm: $toForm) {
      pin
    }
  }
`;

export const SEND_PX_EMAIL_INVITATION = gql`
  mutation SendPXEmailInvitation($recipientEmail: String!) {
    sendPxEmailInvitation(recipientEmail: $recipientEmail) {
      success
      inviteLink
    }
  }
`;

export const CREATE_SUBMISSION = gql`
  mutation CreateSubmission($patientID: ID, $includePdf: Boolean, $updateFormStatus: Boolean, $fromHomeRego: Boolean) {
    createSubmission(
      patientId: $patientID
      includePdf: $includePdf
      updateFormStatus: $updateFormStatus
      fromHomeRego: $fromHomeRego
    ) {
      submission {
        id
        pdf
      }
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query UserDetails($roles: [String]!, $hasOther: Boolean!, $filterOption: LocationFilterOption!) {
    user {
      id
      username
      features {
        distressThermometer
      }
      primaryRole
      isPractitioner
    }
    allowedLocations(roles: $roles, hasOther: $hasOther, filterOption: $filterOption) {
      id
      alias
    }
  }
`;

export const CREATE_PATIENT = gql`
  mutation CreatePatient {
    createPatient {
      patient {
        id
      }
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      user {
        id
      }
      errors
    }
  }
`;

export const GET_PATIENTS_BY_EMAIL = gql`
  query GetPatientsByEmail($email: String) {
    patients: getPatientsByEmail(email: $email) {
      id
    }
  }
`;
