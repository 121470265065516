import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material//VisibilityOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material//SaveAltOutlined';
import PrintOutlinedIcon from '@mui/icons-material//PrintOutlined';
import { styled } from '@mui/system';
import { CareplanCycle } from '../interfaces';
import { UserContext } from 'op-contexts/index';
import moment from 'moment';
import PDFViewModal from './PDFViewModal';

interface CycleMenuProps {
  cycleInfo: CareplanCycle;
  handleViewPdf: () => void;
  handleSavePdf: () => void;
  handlePrintPdf: () => void;
}

const MenuContent = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 0.7rem;
  font-size: 16px;
`;
const MenuMainContent = styled('div')`
  display: flex;
  column-gap: 0.7rem;
  align-items: center;
`;
const MenuSubContent = styled('div')`
  font-size: 13px;
  color: ${(props) => props.theme.palette.secondary.dark};
`;
const CycleMenu = (props: CycleMenuProps) => {
  const { state: userDetails } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button startIcon={<LibraryBooksOutlinedIcon />} aria-haspopup="true" onClick={handleClick}>
        PDF
      </Button>
      <Menu
        data-cy="cycle-menu"
        open={open}
        onClose={handleClose}
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <MenuItem
          data-cy="cycle-menu-view-pdf"
          onClick={() => {
            handleClose();
            setModalOpen(true);
          }}>
          <MenuContent>
            <VisibilityOutlinedIcon /> View PDF{' '}
          </MenuContent>
        </MenuItem>
        <MenuItem
          data-cy="cycle-menu-save-pdf"
          onClick={() => {
            handleClose();
            props.handleSavePdf();
          }}>
          <MenuContent>
            <div>
              <MenuMainContent>
                <SaveAltOutlinedIcon /> Save PDF
              </MenuMainContent>
              <MenuSubContent>
                {props.cycleInfo?.downloadedAt &&
                  `Last saved: ${moment(props.cycleInfo.downloadedAt).tz(userDetails.timezone).format('L hh:mmA')}`}
              </MenuSubContent>
            </div>
          </MenuContent>
        </MenuItem>
        <MenuItem
          data-cy="cycle-menu-print-pdf"
          onClick={() => {
            handleClose();
            props.handlePrintPdf();
          }}>
          <MenuContent>
            <div>
              <MenuMainContent>
                <PrintOutlinedIcon />
                Print PDF
              </MenuMainContent>
              <MenuSubContent>
                {props.cycleInfo?.printedAt &&
                  `Last printed: ${moment(props.cycleInfo.printedAt).tz(userDetails.timezone).format('L hh:mmA')}`}
              </MenuSubContent>
            </div>
          </MenuContent>
        </MenuItem>
      </Menu>
      <PDFViewModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        cycleId={props.cycleInfo?.cycleId}
      />
    </>
  );
};

export default CycleMenu;
