// eslint-disable-next-line no-use-before-define
import React from 'react';
import { IconButton } from '@mui/material';
import { StatusIndicator } from 'shared-components/images/ro_portal';
import { AddCircleOutline } from '@mui/icons-material';
import { OutcomeCard, OutcomeContentRow, OutcomeCardHeader, OutcomeCardBody } from './StyledComponents';
import { translateTimePoint, getStatusIndicatorFillColour } from './helper';

interface OutcomeTimePointCardProps {
  id: string | number;
  index: number;
  outcomeIndex: number;
  outcomeCardCount: number;
  diagnosisId: string;
  timePoint: number;
  lastModified: string;
  status: string;
  onClick: () => void;
}

const OutcomeTimePointCard = ({
  id,
  index,
  outcomeIndex,
  outcomeCardCount,
  diagnosisId,
  timePoint,
  lastModified,
  status,
  onClick,
}: OutcomeTimePointCardProps): JSX.Element => {
  if (id === 'other') {
    return (
      <OutcomeCard key={`${index}-${outcomeIndex}`} outcomecardcount={outcomeCardCount} onClick={onClick}>
        <OutcomeContentRow data-cy={`${diagnosisId}-other-time-point`}>
          <OutcomeCardHeader>Other time point</OutcomeCardHeader>
        </OutcomeContentRow>
        <OutcomeContentRow>
          <IconButton size="small" style={{ marginTop: '8px' }}>
            <AddCircleOutline color="primary" />
          </IconButton>
        </OutcomeContentRow>
      </OutcomeCard>
    );
  }

  return (
    <OutcomeCard
      key={`${index}-${outcomeIndex}`}
      outcomecardcount={outcomeCardCount}
      data-cy={`${diagnosisId}-${timePoint}-outcome-card`}
      onClick={onClick}>
      <OutcomeContentRow>
        <OutcomeCardHeader data-cy={`${diagnosisId}-${timePoint}-outcome-card-header-title`}>
          {translateTimePoint(timePoint)}
        </OutcomeCardHeader>
      </OutcomeContentRow>
      <OutcomeContentRow>
        <OutcomeCardBody data-cy={`${diagnosisId}-${timePoint}-outcome-card-body-last-modified`}>
          Last Modified: {lastModified}
        </OutcomeCardBody>
      </OutcomeContentRow>
      <OutcomeContentRow>
        <IconButton edge="start" size="small" style={{ paddingRight: '8px' }}>
          <StatusIndicator fill={getStatusIndicatorFillColour(status)} />
        </IconButton>
        <OutcomeCardBody data-cy={`${diagnosisId}-${timePoint}-outcome-card-body-status`}>{status}</OutcomeCardBody>
      </OutcomeContentRow>
    </OutcomeCard>
  );
};

export default OutcomeTimePointCard;
