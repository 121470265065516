// eslint-disable-next-line no-use-before-define
import React, { Fragment, useState, useContext, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useFormikContext, FormikProps } from 'formik';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import { DateValidationError } from '@mui/x-date-pickers';
import { LoadingSpinner } from 'shared-components/components';
import { Gender, Region } from 'shared-components/enums';
import { ROTextField, RODatePicker, ROAutocomplete, ROToggleButtons } from 'shared-components/components/FormFields';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { Logger } from 'shared-components/utils';
import { codeToIntakePageMapping, getOptionByValue } from '../DiagnosisPages/Utils';
import ROPatientCarePathSidePanel from '../SidePanel/SidePanel';
import {
  BOOLEAN_OPTIONS,
  BOOLEAN_UNKNOWN_OPTIONS,
  PATIENT_STATUS_OPTIONS,
  PRIORITY_OPTIONS,
  ListOptionType,
  RETREATMENT_OPTIONS,
  DELAY_CATEGORY_OPTIONS,
} from './Interface';
import ROPatientCarePlanPageFooter from '../Footer';
import { LOAD_TREATMENTINFO, UPDATE_TREATMENTINFO, LOAD_TREATMENT_LIST_DATA } from './Queries';
import { GET_DIAGNOSIS_FROM_CAREPLAN } from '../SidePanel/SidePanelQueries';
import ROPatientCarePlanContext from '../ROPatientCarePlanContext';
import { CAREPLAN_PAGES } from '../Constants';
import { validateField, isPageValid } from '../ValidationEngine';
import { CurrentAppConfig } from '../AppConfig';
import { CareplanBanners } from '../Banner/Banner';
import { GET_CAREPLAN } from '../Queries';
import CareplanContainer from '../Container';
import RCRModal from './RCRModal';
import { ROPatientContext } from '../../PatientSummary/context';
import { priorityTooltip, isRcrCodeInRange, tooltipIconWrapper } from '../helpers';
import { logMissingDiagnosisCode } from '../Common';
import './TreatmentInfoPage.scss';
import Box from '@mui/system/Box';
import { Dayjs } from 'dayjs';
import { SelectOptionType } from 'shared-components/components/FormFields';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const logger = new Logger('ROPatientDiagnosisSelectionPage');
interface State {
  chemoWhereOptions: ListOptionType[];
  intentOptions: ListOptionType[];
  chemoSequencingOptions: ListOptionType[];
  treatingDepartmentOptions: ListOptionType[];
  emdtOptions: ListOptionType[];
  brachyWhereOptions: ListOptionType[];
  brachySequencingOptions: ListOptionType[];
}

export const LIST_OPTIONS = {
  INTENT: 'intent',
  TREATING_DEPARTMENT: 'treatingDepartment',
  CHEMO_WHERE: 'chemoWhere',
  CHEMO_SEQUENCING: 'chemoSequencing',
  PREVIOUS_MDT: 'previousMdt',
  BRACHY_WHERE: 'brachyWhere',
  BRACHY_SEQUENCING: 'brachySequencing',
};

const StyledWarning = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 248px;
  margin-top: -10px;
  margin-bottom: 1rem;
  font-size: ${getRemSize(13)};
  color: ${(props) => props.theme.palette.warning.dark};

  span {
    margin-left: 5px;
  }
`;

const StyledQuestionCircleIcon = styled(HelpOutlineIcon)`
  cursor: pointer;
`;

const region = REACT_APP_REGION;

let pressedContinue = false;
const isFieldValid = (field: any, fieldName?: any) => {
  if (!pressedContinue) {
    return true;
  }

  // Enforce an error when linac required is YES, because we don't support that
  if (fieldName === 'ciedLinacRequired' && field === 'yes') {
    return false;
  }
  // Enforce Treatment Delay priority to be Unknown
  // if (fieldName === 'delayCategory' && field === 'Unknown') {
  //   return false;
  // }
  return validateField(field);
};

const filterListdata = (data: any, listCategory: string): any => {
  return [
    ...data.carepathDataReferenceList.filter((options: any): boolean => options.category.category === listCategory),
  ]
    .sort((a: any, b: any) => a.listWeight - b.listWeight)
    .map((option: any) => ({
      label: option.data.option === 'MRDRO' ? 'Murdoch' : option.data.option,
      value: option.data.option,
    }))
    .sort((a: any, b: any) => a.value.localeCompare(b.value));
};

export const previousURL = (
  diagnosis: any,
  careplan: any,
  patientId: string,
  careplanId: string,
  careplanState?: any,
): string => {
  /*
    1. Primary diagnosis page
    2. Secondary diagnosis page
    3. Staging page
    4. intake page
  */
  const primaryDiagnosis = diagnosis.isPrimaryDiagnosis === true ? diagnosis : diagnosis.relatedPrimaryDiagnosis;
  const metastasisDiagnosis = diagnosis.isPrimaryDiagnosis === false ? diagnosis : null;
  // should not be taken to intake page when using an existing primary diagnosis from secondary workflow.
  const hasIntakePage =
    primaryDiagnosis &&
    primaryDiagnosis.diagnosisCode &&
    primaryDiagnosis.diagnosisCode.diagnosisCode &&
    codeToIntakePageMapping(primaryDiagnosis.diagnosisCode.diagnosisCode, careplanState.intakePageMapping) !== '';

  // Intake is part of primary diagnosis, that diagnosis must be a draft
  if (hasIntakePage && careplan.isPrimDiagnosisOwner) {
    return `/radiation/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosis.id}/${CAREPLAN_PAGES.INTAKE}`;
  }
  // intake page is skipped when we choose an existing primary, that's why we don't check for the intake to not exist
  if (
    (primaryDiagnosis && careplan.isPrimDiagnosisOwner) ||
    (metastasisDiagnosis && careplan.isMetsDiagnosisOwner) ||
    diagnosis.relatedPrimaryDiagnosis?.draft === false
  ) {
    return `/radiation/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosis.id}/${CAREPLAN_PAGES.STAGING}`;
  }
  if (metastasisDiagnosis && !careplan.isMetsDiagnosisOwner) {
    return `/radiation/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosis.id}/metastasis`;
  }
  if (primaryDiagnosis && !careplan.isPrimDiagnosisOwner) {
    return `/radiation/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosis.id}/primary`;
  }

  logger.error('previousURL', [
    'Treatment Info Page: Unable to determine the previous URL this user should be directed to.',
    diagnosis,
  ]);
  return '';
};

const ROPatientTreatmentInfoPage = (): JSX.Element => {
  const { patientDetails } = useContext(ROPatientContext);

  const history = useHistory();
  const match: { params: { id: string; careplanId: string } } = useRouteMatch();
  const [state, setState] = useState<State>({
    chemoWhereOptions: [],
    intentOptions: [],
    chemoSequencingOptions: [],
    treatingDepartmentOptions: [],
    emdtOptions: [],
    brachyWhereOptions: [],
    brachySequencingOptions: [],
  });
  const [rcrModalStatus, setRCRModalStatus] = useState<boolean>(false);
  const { id: patientId, careplanId } = match.params;
  const [tumourStreamState, setTumourStreamState] = useState('');
  const {
    data: diagnosisData,
    error: diagLoadingError,
    loading: diagLoading,
  } = useQuery(GET_DIAGNOSIS_FROM_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { careplanId: careplanId },
    onCompleted: (data: any): void => {
      logMissingDiagnosisCode(logger, data.careplan, 'Treatment Info');
      setTumourStreamState(data.careplan.diagnosis.diagnosisCode.tumourStream.name);
    },
  });
  const { values, setFieldValue }: FormikProps<any> = useFormikContext();

  const { state: careplanState } = useContext(ROPatientCarePlanContext);

  const treatmentInfo = values?.treatmentInfo || {};

  // Jank text fields, need to refactor this form at some point
  const [inPatientAt, setInPatientAt] = useState(treatmentInfo?.inPatient);
  const [otherCiedInstructions, setOtherCiedInstructions] = useState(treatmentInfo?.otherCiedInstructions);
  const [hormonesRegime, setHormonesRegime] = useState(treatmentInfo?.hormonesRegime);
  const [brachyDose, setBrachyDose] = useState(treatmentInfo?.brachyDose);
  const [startDateAdditionalInformation, setStartDateAdditionalInformation] = useState(
    treatmentInfo?.startDateAdditionalInformation,
  );
  const [previousTreatmentAdditionalInformation, setPreviousTreatmentAdditionalInformation] = useState(
    treatmentInfo?.previousTreatmentAdditionalInformation,
  );
  const [previousTreatmentAt, setPreviousTreatmentAt] = useState(treatmentInfo?.previousTreatmentAt);
  const [chemoRegime, setChemoRegime] = useState(treatmentInfo?.chemoRegime);
  const [customChemoWhere, setCustomChemoWhere] = useState(treatmentInfo?.customChemoWhere);
  const [updateTreatmentInfo] = useMutation(UPDATE_TREATMENTINFO, {
    refetchQueries: [
      { query: LOAD_TREATMENTINFO, variables: { id: careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
    ],
  });

  const [selectedDateError, setSelectedDateError] = useState<DateValidationError | null>(null);

  const patientIsInSA = patientDetails?.patientState === 'SA';
  const tumourStreamHasDefault = ['Benign', 'Gynae', 'H&N', 'Brain', 'Urogenital', 'Metastasis'].includes(
    tumourStreamState,
  );
  const tumourStreamIsHN = tumourStreamState === 'H&N';
  const ICDTAuthorized = CurrentAppConfig.TreatmentInfoPage.AuthorizedICDTreatingDepartments.includes(
    treatmentInfo.treatingDepartment,
  );

  const validationDependencyStructure = [
    {
      parent: null,
      expansionValue: null,
      children: CurrentAppConfig.TreatmentInfoPage.mandatoryFields,
    },
    {
      parent: null,
      expansionValue: null,
      expansionCondition: () => CurrentAppConfig.TreatmentInfoPage.MDT,
      children: ['previousMdt'],
    },
    {
      parent: null,
      expansionValue: null,
      expansionCondition: () => region !== Region.US && CurrentAppConfig.TreatmentInfoPage.DelayCategory,
      children: ['delayCategory'],
    },
    {
      parent: 'previousRadioTreatment',
      expansionValue: 'yes',
      children: ['previousCompositePlanRequired'],
    },
    {
      parent: 'previousRadioTreatment',
      expansionValue: 'yes',
      expansionCondition: () => CurrentAppConfig.TreatmentInfoPage.PreviousTreatmentRetreatment,
      children: ['previousTreatmentRetreatment'],
    },
    {
      parent: 'previousTreatmentRetreatment',
      expansionValue: 'yes',
      expansionCondition: () => CurrentAppConfig.TreatmentInfoPage.PreviousTreatmentRetreatmentType,
      children: ['previousTreatmentRetreatmentType'],
    },
    {
      parent: 'cied',
      expansionValue: 'cied',
      expansionCondition: () => region === Region.UK,
      children: ['ciedPatientDependancy', 'ciedLinacRequired'],
    },
    {
      parent: 'cied',
      expansionValue: 'yes',
      expansionCondition: () => region === Region.AU,
      children: ['ciedProtocolRequired'],
    },
    // The child attribute of PREVENT_CONTINUE doesn't exist, therefore it will never meet validation requirements and always prevent the user from continuing
    {
      parent: 'cied',
      expansionValue: 'icd',
      expansionCondition: () => region === Region.UK && !ICDTAuthorized,
      children: ['PREVENT_CONTINUE'],
    },
    {
      parent: 'ciedPatientDependancy',
      expansionValue: null,
      expansionCondition: () => region === Region.UK,
      children: [],
    },
    {
      parent: 'ciedLinacRequired',
      expansionValue: 'yes',
      expansionCondition: () => region === Region.UK,
      children: ['PREVENT_CONTINUE'],
    },
    {
      parent: null,
      expansionValue: null,
      expansionCondition: () => patientIsInSA,
      children: ['cardioonc'],
    },
    {
      parent: null,
      expansionValue: null,
      expansionCondition: () => patientIsInSA && tumourStreamIsHN,
      children: ['endoscope'],
    },
  ];
  const { data: careplan } = useQuery(GET_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { id: careplanId },
  });

  const { loading: listRefLoading, data: listRefData } = useQuery(LOAD_TREATMENT_LIST_DATA, {
    variables: {
      listCategory: [
        LIST_OPTIONS.CHEMO_WHERE,
        LIST_OPTIONS.INTENT,
        LIST_OPTIONS.TREATING_DEPARTMENT,
        LIST_OPTIONS.CHEMO_SEQUENCING,
        LIST_OPTIONS.PREVIOUS_MDT,
        LIST_OPTIONS.BRACHY_WHERE,
        LIST_OPTIONS.BRACHY_SEQUENCING,
      ],
      patientId,
    },
    // skip: !groupNumber && groupNumber === '',
    onCompleted: (data: any): void => {
      const chemoSequencing = filterListdata(data, LIST_OPTIONS.CHEMO_SEQUENCING);
      const chemoWhere = filterListdata(data, LIST_OPTIONS.CHEMO_WHERE);
      const intent = filterListdata(data, LIST_OPTIONS.INTENT);
      const treatingDepartment = filterListdata(data, LIST_OPTIONS.TREATING_DEPARTMENT);
      const mdtOptions = filterListdata(data, LIST_OPTIONS.PREVIOUS_MDT);
      const brachyWhereOptions = filterListdata(data, LIST_OPTIONS.BRACHY_WHERE);
      const brachySequencingOptions = filterListdata(data, LIST_OPTIONS.BRACHY_SEQUENCING);
      const newState: State = { ...state };
      newState['chemoWhereOptions'] = chemoWhere;
      newState['chemoSequencingOptions'] = chemoSequencing;
      newState['intentOptions'] = intent;
      newState['treatingDepartmentOptions'] = treatingDepartment;
      newState['emdtOptions'] = mdtOptions;
      newState['brachyWhereOptions'] = brachyWhereOptions;
      newState['brachySequencingOptions'] = brachySequencingOptions;
      setState(newState);
    },
    fetchPolicy: 'cache-and-network',
  });
  const [, updateState] = useState();
  // @ts-ignore allow empty object
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect((): void => {
    pressedContinue = false;
  }, []);

  useEffect(() => {
    const errorElems = document.getElementsByClassName('icon-form-error');
    if (errorElems && errorElems.length > 0) {
      errorElems[0].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
      (errorElems[0] as HTMLElement).focus({ preventScroll: true });
    } else {
      pressedContinue = false;
    }
  });
  const [cardioOncState, setCardioOncState] = useState('');
  const [endoscopeState, setEndoscopeState] = useState('');

  const diagnosis = diagnosisData?.careplan?.diagnosis;
  const primaryDiagnosis = diagnosis?.isPrimaryDiagnosis === true ? diagnosis : diagnosis?.relatedPrimaryDiagnosis;
  const diagnosisCode = primaryDiagnosis?.diagnosisCode?.diagnosisCode;
  const formattedDxCode = Number(diagnosisCode?.substring(1));
  const formattedMorphologyCode = Number(primaryDiagnosis?.morphology?.code.substring(0, 4));
  const isValidCat1RCR = isRcrCodeInRange(formattedDxCode, formattedMorphologyCode);
  const getNewDelayCategory = () => {
    if (treatmentInfo.intent === 'Palliative' || !['C', 'D'].includes(diagnosisCode?.charAt(0))) {
      return 'Category 3';
    } else if (isValidCat1RCR) {
      return 'Category 1';
    } else if (formattedMorphologyCode) {
      return 'Category 2';
    }
    return '';
  };

  useEffect(() => {
    if (primaryDiagnosis) {
      const newDelayCategory = getNewDelayCategory();
      if (
        newDelayCategory !== treatmentInfo?.delayCategory &&
        (!treatmentInfo.isDelayCategoryManual || (newDelayCategory !== '' && newDelayCategory !== null))
      ) {
        updateHandler({ delayCategory: newDelayCategory, isDelayCategoryManual: false });
      }
    }
  }, [primaryDiagnosis, treatmentInfo.intent]);

  useEffect(() => {
    // Some absolute jank to get around the initial formik values being null
    if (chemoRegime === undefined) setChemoRegime(treatmentInfo?.chemoRegime);
    if (brachyDose === undefined) setBrachyDose(treatmentInfo?.brachyDose);
    if (customChemoWhere === undefined) setCustomChemoWhere(treatmentInfo?.customChemoWhere);
    if (startDateAdditionalInformation === undefined)
      setStartDateAdditionalInformation(treatmentInfo?.startDateAdditionalInformation);
    if (previousTreatmentAt === undefined) setPreviousTreatmentAt(treatmentInfo?.previousTreatmentAt);
    if (previousTreatmentAdditionalInformation === undefined)
      setPreviousTreatmentAdditionalInformation(treatmentInfo?.previousTreatmentAdditionalInformation);
    if (inPatientAt === undefined) setInPatientAt(treatmentInfo?.inPatientAt);
    if (otherCiedInstructions === undefined) setOtherCiedInstructions(treatmentInfo?.otherCiedInstructions);
    if (hormonesRegime === undefined) setHormonesRegime(treatmentInfo?.hormonesRegime);
  }, [treatmentInfo]);

  const cardioOncValue =
    treatmentInfo &&
    (!treatmentInfo?.cardioonc ? (patientIsInSA && tumourStreamHasDefault ? 'no' : '') : treatmentInfo?.cardioonc);
  const endoscopeValue = !treatmentInfo?.endoscope
    ? patientIsInSA && tumourStreamIsHN
      ? 'no'
      : ''
    : treatmentInfo?.endoscope;

  if (treatmentInfo && (cardioOncState !== cardioOncValue || endoscopeState !== endoscopeValue)) {
    setCardioOncState(cardioOncValue);
    setEndoscopeState(endoscopeValue);
    setFieldValue('treatmentInfo.cardioonc', cardioOncValue);
    setFieldValue('treatmentInfo.endoscope', endoscopeValue);
    updateTreatmentInfo({
      variables: { id: careplanId, cardioonc: cardioOncValue, endoscope: endoscopeValue },
    });
  }

  const updateHandler = (changedValues: { [key: string]: string | null | boolean }): void => {
    const fieldKeys = Object.keys(changedValues);

    fieldKeys.forEach((key) => setFieldValue(`treatmentInfo.${key}`, changedValues[key]));
    updateTreatmentInfo({
      variables: {
        id: careplanId,
        ...changedValues,
      },
    });
  };

  if (listRefLoading && !listRefData) return <LoadingSpinner />;

  return (
    <Fragment>
      <div className="main-container-parent-wrapper">
        <div className="main-container-wrapper">
          <CareplanBanners
            data={careplan}
            forceUpdate={() => {
              forceUpdate();
            }}
            refetchQueriesList={[
              { query: GET_DIAGNOSIS_FROM_CAREPLAN, variables: { careplanId } },
              { query: GET_CAREPLAN, variables: { id: careplanId } },
            ]}
          />
          <CareplanContainer>
            <div className="page-header">
              <h1>{'Treatment Information'}</h1>
            </div>
            <RCRModal modalStatus={rcrModalStatus} setModalStatus={setRCRModalStatus} />
            <Box component="form" id="treatmentInfoForm" noValidate>
              {CurrentAppConfig.TreatmentInfoPage.ReadyForCareDate && (
                <RODatePicker
                  id="readyForCareDate"
                  fieldlabel="Ready for Care Date"
                  value={treatmentInfo.readyForCareDate ? dayjs(treatmentInfo.readyForCareDate) : null}
                  error={!isFieldValid(treatmentInfo.readyForCareDate)}
                  required
                  onChange={(date: Dayjs | null, context: any) => {
                    if (context.validationError) {
                      setSelectedDateError(context.validationError);
                      return;
                    }
                    const dateString = date ? dayjs(date).format('YYYY-MM-DD') : '';
                    updateHandler({ readyForCareDate: dateString });
                  }}
                  onError={setSelectedDateError}
                />
              )}
              <ROAutocomplete
                id="intent"
                fieldlabel="Intent"
                options={state.intentOptions}
                value={getOptionByValue(
                  state.intentOptions.map((data) => ({ label: data.label, value: data.value })),
                  treatmentInfo.intent,
                )}
                inputProps={{
                  error: !isFieldValid(treatmentInfo.intent),
                  helperText: isFieldValid(treatmentInfo.intent) ? '' : 'This field is required',
                }}
                required
                onChange={(option: SelectOptionType | string) => {
                  const value = typeof option === 'string' ? option : option?.value;
                  updateHandler({ intent: value || '' });
                }}
              />
              <RODatePicker
                id="preferredTreatmentStartDate"
                disablePast
                value={
                  treatmentInfo.preferredTreatmentStartDate ? dayjs(treatmentInfo.preferredTreatmentStartDate) : null
                }
                onChange={(date: Dayjs | null, context: any) => {
                  if (context.validationError) {
                    setSelectedDateError(context.validationError);
                    return;
                  }
                  const dateString = date ? dayjs(date).format('YYYY-MM-DD') : '';
                  updateHandler({ preferredTreatmentStartDate: dateString });
                }}
                onError={setSelectedDateError}
                fieldlabel="Preferred Treatment Start Date"
              />
              {CurrentAppConfig.TreatmentInfoPage.AdditionalBookingInfo && (
                <ROTextField
                  id="startDateAdditionalInformation"
                  fieldlabel="Additional Booking Information"
                  value={startDateAdditionalInformation}
                  multiline
                  maxRows={4}
                  placeholder="e.g. start treatment two weeks after Easter holidays"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    setStartDateAdditionalInformation(event.target.value);
                  }}
                  onBlur={(): void => {
                    updateHandler({ startDateAdditionalInformation });
                  }}
                />
              )}
              <ROAutocomplete
                id="treatingDepartment"
                fieldlabel="Treating Department"
                options={state.treatingDepartmentOptions}
                value={getOptionByValue(
                  state.treatingDepartmentOptions.map((data) => ({ label: data.label, value: data.value })),
                  treatmentInfo.treatingDepartment,
                )}
                required
                onChange={(option: SelectOptionType | string) => {
                  const value = typeof option === 'string' ? option : option?.value;
                  updateHandler({
                    treatingDepartment: value || '',
                  });
                }}
                inputProps={{
                  error: !isFieldValid(treatmentInfo.treatingDepartment),
                  helperText: isFieldValid(treatmentInfo.treatingDepartment) ? '' : 'This field is required',
                }}
              />
              {CurrentAppConfig.TreatmentInfoPage.MDT && (
                <ROAutocomplete
                  id="previousMdt"
                  fieldlabel="Has patient been through a MDT?"
                  options={state.emdtOptions}
                  value={getOptionByValue(
                    state.emdtOptions.map((data) => ({ label: data.label, value: data.value })),
                    treatmentInfo.previousMdt,
                  )}
                  inputProps={{
                    error: !isFieldValid(treatmentInfo.previousMdt),
                    helperText: isFieldValid(treatmentInfo.treatingDepartment) ? '' : 'This field is required',
                  }}
                  required
                  onChange={(option: SelectOptionType | string) => {
                    const value = typeof option === 'string' ? option : option?.value;
                    updateHandler({ previousMdt: value || '' });
                  }}
                />
              )}
              <ROToggleButtons
                id="previousRadioTreatment"
                fieldlabel="Previous Radiotherapy Treatment"
                required
                options={CurrentAppConfig.QuestionOptions.previousRadioTreatment}
                value={treatmentInfo.previousRadioTreatment}
                error={!isFieldValid(treatmentInfo.previousRadioTreatment)}
                handleChange={(value: string): void => {
                  updateHandler({
                    previousRadioTreatment: value,
                    previousCompositePlanRequired: '',
                    previousTreatmentAt: '',
                    previousTreatmentAdditionalInformation: '',
                    previousTreatmentRetreatment: '',
                  });
                  setPreviousTreatmentAt('');
                  setPreviousTreatmentAdditionalInformation('');
                }}
              />
              {treatmentInfo.previousRadioTreatment === 'yes' && (
                <>
                  {CurrentAppConfig.TreatmentInfoPage.PreviousTreatmentRetreatmentType && (
                    <>
                      <ROToggleButtons
                        id="previousTreatmentRetreatment"
                        fieldlabel="Is this a retreatment?"
                        required
                        options={BOOLEAN_OPTIONS}
                        value={treatmentInfo.previousTreatmentRetreatment}
                        error={!isFieldValid(treatmentInfo.previousTreatmentRetreatment)}
                        handleChange={(value: string): void => {
                          updateHandler({
                            previousTreatmentRetreatment: value,
                            previousTreatmentRetreatmentType: '',
                          });
                        }}
                      />
                      {treatmentInfo.previousTreatmentRetreatment === 'yes' && (
                        <ROToggleButtons
                          id="previousTreatmentRetreatmentType"
                          fieldlabel="Retreatment Type"
                          required
                          options={RETREATMENT_OPTIONS}
                          value={treatmentInfo.previousTreatmentRetreatmentType}
                          error={!isFieldValid(treatmentInfo.previousTreatmentRetreatmentType)}
                          handleChange={(value: string): void => {
                            updateHandler({ previousTreatmentRetreatmentType: value });
                          }}
                        />
                      )}
                    </>
                  )}
                  <ROToggleButtons
                    id="previousCompositePlanRequired"
                    fieldlabel="Is a Composite Plan Required"
                    required
                    options={BOOLEAN_OPTIONS}
                    value={treatmentInfo.previousCompositePlanRequired}
                    error={!isFieldValid(treatmentInfo.previousCompositePlanRequired)}
                    handleChange={(value: string): void => {
                      updateHandler({ previousCompositePlanRequired: value });
                    }}
                  />
                  <ROTextField
                    id="previousTreatmentAt"
                    fieldlabel="Previous Treatment Centre & Date"
                    placeholder="Please specify centre & year if known"
                    value={previousTreatmentAt}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      setPreviousTreatmentAt(event.target.value);
                    }}
                    onBlur={(): void => {
                      updateHandler({ previousTreatmentAt });
                    }}
                  />
                  <ROTextField
                    id="previousTreatmentAdditionalInformation"
                    fieldlabel="Additional Information"
                    placeholder="e.g. composite dose information"
                    value={previousTreatmentAdditionalInformation}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      setPreviousTreatmentAdditionalInformation(event.target.value);
                    }}
                    onBlur={(): void => {
                      updateHandler({
                        previousTreatmentAdditionalInformation,
                      });
                    }}
                  />
                </>
              )}
              <ROToggleButtons
                id="patientStatus"
                fieldlabel="Patient Status"
                options={PATIENT_STATUS_OPTIONS}
                value={
                  treatmentInfo.patientStatus
                    ? treatmentInfo.patientStatus
                    : CurrentAppConfig.DefaultValue.patientStatus
                }
                handleChange={(value: string): void => {
                  updateHandler({ patientStatus: value, inPatientAt: '' });
                  setInPatientAt('');
                }}
              />
              {treatmentInfo.patientStatus === 'inPatient' && (
                <ROTextField
                  id="inPatientAt"
                  placeholder="In patient at.."
                  value={inPatientAt}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    setInPatientAt(event.target.value);
                  }}
                  onBlur={(): void => {
                    updateHandler({ inPatientAt });
                  }}
                />
              )}
              {CurrentAppConfig.TreatmentInfoPage.DelayCategory && (
                <ROToggleButtons
                  id="delayCategory"
                  fieldlabel="RCR Treatment Delay Priority"
                  required
                  error={!isFieldValid(treatmentInfo.delayCategory, 'delayCategory')}
                  options={DELAY_CATEGORY_OPTIONS}
                  value={treatmentInfo.delayCategory ? treatmentInfo.delayCategory : 'Not Applicable'}
                  handleChange={(value: string): void => {
                    updateHandler({ delayCategory: value, isDelayCategoryManual: true });
                  }}
                  tooltip={
                    <StyledQuestionCircleIcon
                      color="info"
                      fontSize="small"
                      onClick={() => {
                        setRCRModalStatus(true);
                      }}
                    />
                  }
                />
              )}
              <ROToggleButtons
                id="priority"
                fieldlabel={CurrentAppConfig.Tooltips.hasPriority ? 'Treatment Start Priority' : 'Priority'}
                options={PRIORITY_OPTIONS}
                value={treatmentInfo.priority ? treatmentInfo.priority : 'standard'}
                handleChange={(value: string): void => {
                  updateHandler({ priority: value });
                }}
                tooltip={CurrentAppConfig.Tooltips.hasPriority ? tooltipIconWrapper(priorityTooltip) : undefined}
              />
              {patientDetails && patientDetails.patientGender !== Gender.MALE && (
                <ROToggleButtons
                  id="pregnancy"
                  fieldlabel="Pregnancy"
                  options={BOOLEAN_UNKNOWN_OPTIONS}
                  value={treatmentInfo.pregnancy ? treatmentInfo.pregnancy : 'Unknown'}
                  error={!treatmentInfo.pregnancy}
                  handleChange={(value: string): void => {
                    updateHandler({ pregnancy: value });
                  }}
                />
              )}
              <ROToggleButtons
                id="cied"
                fieldlabel={CurrentAppConfig.QuestionLabel.CIED}
                options={CurrentAppConfig.QuestionOptions.CIED}
                value={treatmentInfo.cied}
                error={(treatmentInfo.cied === 'icd' && !ICDTAuthorized) || !isFieldValid(treatmentInfo.cied, 'CIED')}
                // This conditional error message is to provide feedback to non-gc ROs in the UK that explains why ICD
                // is not supported for the CIED field. Ideally this would become a tool tip in the future
                warning={treatmentInfo.cied === 'yes' && region !== Region.UK}
                helperText={
                  treatmentInfo.cied === 'yes' && region !== Region.UK
                    ? 'Warning: Patient has Cardiac Implantable Electronic Device (CIED)'
                    : treatmentInfo.cied === 'icd' && !ICDTAuthorized
                    ? 'The patient cannot be treated at GenesisCare, as per the ICD protocol.'
                    : undefined
                }
                handleChange={(value: string): void => {
                  const mutationVariables =
                    value !== 'yes'
                      ? CurrentAppConfig.ResetMutationVariableDefaults.CIED
                      : CurrentAppConfig.GetMutationVariableDefaults.CIED;
                  updateHandler({ cied: value, ...mutationVariables });
                  setOtherCiedInstructions('');
                }}
                required
              />
              {treatmentInfo.cied === 'yes' && region !== Region.UK && (
                <>
                  <ROToggleButtons
                    id="ciedProtocolRequired"
                    fieldlabel="CIED Protocol Required"
                    options={BOOLEAN_OPTIONS}
                    required
                    value={treatmentInfo.ciedProtocolRequired}
                    error={!isFieldValid(treatmentInfo.ciedProtocolRequired)}
                    handleChange={(value: string): void => {
                      const mutationVariables =
                        value !== 'yes'
                          ? CurrentAppConfig.ResetMutationVariableDefaults.CiedProtocolRequired
                          : CurrentAppConfig.GetMutationVariableDefaults.CiedProtocolRequired;
                      updateHandler({ ciedProtocolRequired: value, ...mutationVariables });
                    }}
                  />
                  {treatmentInfo.cied === 'yes' && treatmentInfo.ciedProtocolRequired === 'yes' && (
                    <>
                      <ROToggleButtons
                        id="preTreatmentCiedCheckRequired"
                        fieldlabel="Pre-treatment CIED Check Required"
                        options={BOOLEAN_OPTIONS}
                        value={treatmentInfo.preTreatmentCiedCheckRequired}
                        required
                        error={!isFieldValid(treatmentInfo.preTreatmentCiedCheckRequired)}
                        handleChange={(value: string): void => {
                          updateHandler({ preTreatmentCiedCheckRequired: value });
                        }}
                      />
                      {CurrentAppConfig.CardioReferralField && treatmentInfo.ciedProtocolRequired === 'yes' && (
                        <ROToggleButtons
                          id="referralToGcCardiologist"
                          fieldlabel="Referral to GC Cardiologist"
                          options={BOOLEAN_OPTIONS}
                          value={treatmentInfo.referralToGcCardiologist ? treatmentInfo.referralToGcCardiologist : 'no'}
                          error={!isFieldValid(treatmentInfo.referralToGcCardiologist)}
                          handleChange={(value: string): void => {
                            updateHandler({ referralToGcCardiologist: value });
                          }}
                        />
                      )}
                      {treatmentInfo.ciedProtocolRequired === 'yes' && (
                        <ROTextField
                          id="otherCiedInstructions"
                          fieldlabel="Other CIED Instructions"
                          value={otherCiedInstructions}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                            setOtherCiedInstructions(event.target.value);
                          }}
                          onBlur={(): void => {
                            updateHandler({ otherCiedInstructions });
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              {treatmentInfo.cied === 'cied' && region === Region.UK && (
                <>
                  <ROToggleButtons
                    id="ciedPatientDependancy"
                    fieldlabel="Is patient dependent on CIED?"
                    options={BOOLEAN_OPTIONS}
                    value={treatmentInfo.ciedPatientDependancy}
                    required
                    error={!isFieldValid(treatmentInfo.ciedPatientDependancy, 'ciedPatientDependency')}
                    handleChange={(value: string): void => {
                      updateHandler({ ciedPatientDependancy: value });
                    }}
                  />

                  <ROToggleButtons
                    id="ciedPatientLinac"
                    fieldlabel="Will patient be treated on MR Linac?"
                    options={BOOLEAN_OPTIONS}
                    value={treatmentInfo.ciedLinacRequired}
                    required
                    error={
                      !isFieldValid(treatmentInfo.ciedLinacRequired, 'ciedLinacRequired') ||
                      treatmentInfo.ciedLinacRequired === 'yes'
                    }
                    helperText={
                      treatmentInfo.ciedLinacRequired === 'yes'
                        ? 'The patient cannot be treated at GenesisCare, as per the CIED protocol.'
                        : treatmentInfo.ciedLinacRequired === 'no'
                        ? 'Please send Pacing Report to GenesisCare.'
                        : undefined
                    }
                    info={treatmentInfo.ciedLinacRequired === 'no'}
                    handleChange={(value: string): void => {
                      updateHandler({ ciedLinacRequired: value });
                    }}
                  />
                </>
              )}
              {patientIsInSA && (
                <ROToggleButtons
                  id="cardioonc"
                  fieldlabel={'CardioOnc Referral Required'}
                  error={!isFieldValid(cardioOncState)}
                  required
                  options={BOOLEAN_OPTIONS}
                  value={treatmentInfo.cardioonc ? treatmentInfo.cardioonc : tumourStreamHasDefault ? 'no' : ''}
                  handleChange={(value: string): void => {
                    updateHandler({ cardioonc: value });
                  }}
                />
              )}
              {patientIsInSA && tumourStreamIsHN && (
                <ROToggleButtons
                  id="endoscope"
                  fieldlabel={'Endoscope Required'}
                  error={!isFieldValid(endoscopeState)}
                  required
                  options={BOOLEAN_OPTIONS}
                  value={treatmentInfo.endoscope ? treatmentInfo.endoscope : 'no'}
                  handleChange={(value: string): void => {
                    updateHandler({ endoscope: value });
                  }}
                />
              )}
              <ROToggleButtons
                id="chemotherapy"
                fieldlabel={'Chemotherapy'}
                options={BOOLEAN_UNKNOWN_OPTIONS}
                value={treatmentInfo.chemotherapy ? treatmentInfo.chemotherapy : 'Unknown'}
                handleChange={(value: string): void => {
                  updateHandler({
                    chemotherapy: value,
                    chemoRegime: '',
                    chemoWhere: '',
                    customChemoWhere: '',
                    chemoSequencing: '',
                    chemoStartDate: '',
                    chemoEndDate: '',
                  });
                  setCustomChemoWhere('');
                }}
              />
              {treatmentInfo.chemotherapy === 'yes' && (
                <Fragment>
                  <ROTextField
                    id="chemoRegime"
                    fieldlabel="Chemo Regime"
                    value={chemoRegime}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      setChemoRegime(event.target.value);
                    }}
                    onBlur={(): void => {
                      updateHandler({ chemoRegime });
                    }}
                  />
                  <ROAutocomplete
                    id="chemoWhere"
                    fieldlabel="Chemo Where"
                    options={state.chemoWhereOptions}
                    value={getOptionByValue(
                      state.chemoWhereOptions.map((data) => ({ label: data.label, value: data.value })),
                      treatmentInfo.chemoWhere,
                    )}
                    onChange={(option: SelectOptionType | string) => {
                      const value = typeof option === 'string' ? option : option?.value;
                      updateHandler({
                        chemoWhere: value || '',
                        customChemoWhere: '',
                      });
                      setCustomChemoWhere('');
                    }}
                  />

                  {treatmentInfo.chemoWhere && treatmentInfo.chemoWhere.toLowerCase() === 'other' && (
                    <ROTextField
                      id="customChemoWhere"
                      fieldlabel="Specify Chemo Where"
                      value={customChemoWhere}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        setCustomChemoWhere(event.target.value);
                      }}
                      onBlur={(): void => {
                        updateHandler({ customChemoWhere });
                      }}
                    />
                  )}
                  <ROAutocomplete
                    id="chemoSequencing"
                    fieldlabel="Chemo Sequencing"
                    options={state.chemoSequencingOptions}
                    value={getOptionByValue(
                      state.chemoSequencingOptions.map((data) => ({ label: data.label, value: data.value })),
                      treatmentInfo.chemoSequencing,
                    )}
                    onChange={(option: SelectOptionType | string) => {
                      const value = typeof option === 'string' ? option : option?.value;
                      updateHandler({
                        chemoSequencing: value || '',
                      });
                    }}
                  />
                  <RODatePicker
                    id="chemoStartDate"
                    fieldlabel="Chemo Start Date"
                    value={treatmentInfo.chemoStartDate ? dayjs(treatmentInfo.chemoStartDate) : null}
                    maxDate={dayjs(treatmentInfo.chemoEndDate)}
                    onChange={(date: Dayjs | null, context: any) => {
                      if (context.validationError) {
                        setSelectedDateError(context.validationError);
                        return;
                      }
                      const dateString = date ? dayjs(date).format('YYYY-MM-DD') : '';
                      updateHandler({ chemoStartDate: dateString });
                    }}
                    onError={setSelectedDateError}
                  />
                  <RODatePicker
                    id="chemoEndDate"
                    fieldlabel="Chemo End Date"
                    value={treatmentInfo.chemoEndDate ? dayjs(treatmentInfo.chemoEndDate) : null}
                    minDate={dayjs(treatmentInfo.chemoStartDate)}
                    onChange={(date: Dayjs | null, context: any) => {
                      if (context.validationError) {
                        setSelectedDateError(context.validationError);
                        return;
                      }
                      const dateString = date ? dayjs(date).format('YYYY-MM-DD') : '';
                      updateHandler({ chemoEndDate: dateString });
                    }}
                    onError={setSelectedDateError}
                  />
                </Fragment>
              )}
              {CurrentAppConfig.TreatmentInfoPage.Hormones && (
                <ROToggleButtons
                  id="hormones"
                  fieldlabel="Hormones"
                  options={BOOLEAN_UNKNOWN_OPTIONS}
                  value={treatmentInfo.hormones || 'unknown'}
                  handleChange={(value: string): void => {
                    updateHandler({ hormones: value, hormonesRegime: '', hormonesStartDate: '', hormonesEndDate: '' });
                    setHormonesRegime('');
                  }}
                />
              )}
              {CurrentAppConfig.TreatmentInfoPage.Hormones && treatmentInfo.hormones === 'yes' && (
                <Fragment>
                  <ROTextField
                    id="hormonesRegime"
                    fieldlabel="Hormones Regime"
                    value={hormonesRegime}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      setHormonesRegime(event.target.value);
                    }}
                    onBlur={(): void => {
                      updateHandler({ hormonesRegime });
                    }}
                  />
                  <RODatePicker
                    id="hormonesStartDate"
                    fieldlabel="Hormones Start Date"
                    value={treatmentInfo.hormonesStartDate ? dayjs(treatmentInfo.hormonesStartDate) : null}
                    maxDate={dayjs(treatmentInfo.hormonesEndDate)}
                    onChange={(date: Dayjs | null, context: any) => {
                      if (context.validationError) {
                        setSelectedDateError(context.validationError);
                        return;
                      }
                      const dateString = date ? dayjs(date).format('YYYY-MM-DD') : '';
                      updateHandler({ hormonesStartDate: dateString });
                    }}
                    onError={setSelectedDateError}
                  />
                  <RODatePicker
                    id="hormonesEndDate"
                    fieldlabel="Hormones End Date"
                    value={treatmentInfo.hormonesEndDate ? dayjs(treatmentInfo.hormonesEndDate) : null}
                    minDate={dayjs(treatmentInfo.hormonesStartDate)}
                    onChange={(date: Dayjs | null, context: any) => {
                      if (context.validationError) {
                        setSelectedDateError(context.validationError);
                        return;
                      }
                      const dateString = date ? dayjs(date).format('YYYY-MM-DD') : '';
                      updateHandler({ hormonesEndDate: dateString });
                    }}
                    onError={setSelectedDateError}
                  />
                </Fragment>
              )}
              {CurrentAppConfig.TreatmentInfoPage.Brachytherapy && (
                <ROToggleButtons
                  id="brachytherapy"
                  fieldlabel="Brachytherapy"
                  options={BOOLEAN_UNKNOWN_OPTIONS}
                  value={treatmentInfo.brachytherapy ? treatmentInfo.brachytherapy : 'unknown'}
                  handleChange={(value: string): void => {
                    updateHandler({
                      brachytherapy: value,
                      brachyWhere: '',
                      brachySequencing: '',
                      brachyDose: '',
                      brachyStartDate: '',
                      brachyEndDate: '',
                    });
                    setBrachyDose('');
                  }}
                />
              )}
              {CurrentAppConfig.TreatmentInfoPage.Brachytherapy && treatmentInfo.brachytherapy === 'yes' && (
                <Fragment>
                  <ROAutocomplete
                    id="brachyWhere"
                    fieldlabel="Brachy Where"
                    options={state.brachyWhereOptions}
                    value={getOptionByValue(
                      state.brachyWhereOptions.map((data) => ({ label: data.label, value: data.value })),
                      treatmentInfo.brachyWhere,
                    )}
                    onChange={(option: SelectOptionType | string) => {
                      const value = typeof option === 'string' ? option : option?.value;
                      updateHandler({
                        brachyWhere: value || '',
                      });
                    }}
                  />
                  <ROAutocomplete
                    id="brachySequencing"
                    fieldlabel="Brachy Sequencing"
                    options={state.brachySequencingOptions}
                    value={getOptionByValue(
                      state.brachySequencingOptions.map(
                        (data): ListOptionType => ({
                          label: data.label,
                          value: data.value,
                        }),
                      ),
                      treatmentInfo.brachySequencing,
                    )}
                    onChange={(option: SelectOptionType | string) => {
                      const value = typeof option === 'string' ? option : option?.value;
                      updateHandler({
                        brachySequencing: value || '',
                      });
                    }}
                  />
                  <ROTextField
                    id="brachyDose"
                    fieldlabel="Brachy Dose"
                    value={brachyDose}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      setBrachyDose(event.target.value);
                    }}
                    onBlur={(): void => {
                      updateHandler({ brachyDose });
                    }}
                  />
                  <RODatePicker
                    id="brachyStartDate"
                    fieldlabel="Brachy Start Date"
                    value={treatmentInfo.brachyStartDate ? dayjs(treatmentInfo.brachyStartDate) : null}
                    maxDate={dayjs(treatmentInfo.brachyEndDate)}
                    onChange={(date: Dayjs | null, context: any) => {
                      if (context.validationError) {
                        setSelectedDateError(context.validationError);
                        return;
                      }
                      const dateString = date ? dayjs(date).format('YYYY-MM-DD') : '';
                      updateHandler({ brachyStartDate: dateString });
                    }}
                    onError={setSelectedDateError}
                  />
                  <RODatePicker
                    id="brachyEndDate"
                    fieldlabel="Brachy End Date"
                    value={treatmentInfo.brachyEndDate ? dayjs(treatmentInfo.brachyEndDate) : null}
                    minDate={dayjs(treatmentInfo.brachyStartDate)}
                    onChange={(date: Dayjs | null, context: any) => {
                      if (context.validationError) {
                        setSelectedDateError(context.validationError);
                        return;
                      }
                      const dateString = date ? dayjs(date).format('YYYY-MM-DD') : '';
                      updateHandler({ brachyEndDate: dateString });
                    }}
                    onError={setSelectedDateError}
                  />
                </Fragment>
              )}
            </Box>
          </CareplanContainer>
          <ROPatientCarePlanPageFooter
            onBack={(): void => {
              const n = previousURL(diagnosis, diagnosisData?.careplan, patientId, careplanId, careplanState);
              if (n === '') return;
              history.push(n);
              return;
            }}
            onNext={(): void => {
              pressedContinue = true;
              forceUpdate();
              if (!isPageValid(validationDependencyStructure, treatmentInfo)) return;

              // Ensure that user can't press Next when non-mandatory fields are filled and fail validation
              if (selectedDateError) return;

              history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/templateSelection`);
            }}
            nextDisabled={!careplanId}
            backDisabled={
              !diagnosisData ||
              (diagnosisData &&
                (!diagnosisData.careplan.diagnosis.isPrimaryDiagnosis ||
                  !diagnosisData.careplan.isPrimDiagnosisOwner) &&
                diagnosisData?.careplan?.careplanStatus !== 'DRAFT')
            }
            backButtonId={'treatment-info-back-button'}
            backButtonTooltip={
              region !== Region.UK &&
              !diagLoading &&
              !diagLoadingError &&
              !diagnosisData?.careplan.diagnosis.isPrimaryDiagnosis
                ? "If you would like to edit the submitted 'Metastasis' diagnosis, please update in Mosaiq (This feature is coming soon)"
                : ''
            }
          />
        </div>
        <ROPatientCarePathSidePanel />
      </div>
    </Fragment>
  );
};

export default ROPatientTreatmentInfoPage;
