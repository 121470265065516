import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { DATATYPE } from 'shared-components/components/UIFormComponents/SimpleTable';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { Stack } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { FindInPageOutlined as FindInPageOutlinedIcon } from '@mui/icons-material';
import { useTheme, styled } from '@mui/material';

interface StyledRowProps {
  active: boolean;
}

interface TableHeaderProps {
  title: string;
  key: string;
  sort?: boolean;
  type: string;
}

interface TableDataProps {
  title?: string;
  subtitle?: string;
  id?: string;
  key: string;
}

interface Props {
  tableHeaders: TableHeaderProps[];
  tableData?: TableDataProps[][];
  dateFormat?: string;
  onRowClick?: (item: TableDataProps[]) => void;
  defaultSelectedIndex?: number;
  testId?: string;
}

const TableContainer = styled('table')`
  height: 100%;
  display: block;
  border-collapse: separate;
  width: 100%;
`;

const TableHead = styled('thead')`
  display: block;
`;

const TableHeaderRow = styled('tr')`
  display: block;
  max-width: 100%;
`;

const TableHeader = styled('th')`
  width: 100%;
  white-space: nowrap;
  align-items: center;
  padding: 14px 14px 12px;
  color: ${(props) => props.theme.palette.text.primary};
  background-color: ${(props) => props.theme.palette.secondary.light};
  font-size: ${getRemSize(16)};
  &:last-of-type {
    text-align: right;
  }
`;

const TableBody = styled('tbody')`
  max-height: calc(100% - 50px);
  display: block;
  width: 100%;
  overflow: auto;
`;

const TableRow = styled('tr')`
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.main};
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    cursor: pointer;
  }
`;

const TableData = styled('td')<StyledRowProps>`
  width: 100%;
  padding: ${(props: StyledRowProps) => (props.active ? '6px 14px 7px 14px' : '8px 16px')};
  font-size: ${getRemSize(16)};
  vertical-align: middle;
  border-top: ${(props) => (props.active ? `2px solid ${props.theme.palette.primary.dark}` : '')};
  border-bottom: ${(props) =>
    props.active ? `2px solid ${props.theme.palette.primary.dark}` : `1px solid ${props.theme.palette.secondary.main}`};
  &:last-of-type {
    text-align: right;
    border-right: ${(props) => (props.active ? `2px solid ${props.theme.palette.primary.dark}` : '')};
    border-radius: ${(props: StyledRowProps) => (props.active ? '0 4px 4px 0' : '')};
  }
  &:first-of-type {
    border-left: ${(props) => (props.active ? `2px solid ${props.theme.palette.primary.dark}` : '')};
    border-radius: ${(props: StyledRowProps) => (props.active ? '4px 0 0 4px' : '')};
  }
`;

const SubTableData = styled('div')`
  padding-top: 4px;
  font-size: ${getRemSize(13)};
  color: ${(props) => props.theme.palette.secondary.dark};
`;

const NoDataContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const NoDataText = styled('span')`
  margin-top: 28px;
  font-size: ${getRemSize(18)};
  font-weight: 700;
  color: ${(props) => props.theme.palette.secondary.dark};
`;

const SORT_TYPES = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const NO_DATA = 'No results found';

const Table = (props: Props): JSX.Element => {
  const { tableHeaders, tableData, dateFormat = 'DD-MM-YYYY', onRowClick, defaultSelectedIndex, testId } = props;
  const [sortState, setSortState] = useState({ key: '', order: '', type: '' });
  const [activeItem, setActiveItem] = useState<any>({ id: null });
  const theme = useTheme();

  const sortedData =
    tableData &&
    [...tableData].sort((a: any, b: any): number => {
      if (sortState.type === DATATYPE.DATE) {
        const aDate = a.find((item: any) => item['key'] === sortState['key'])['title'];
        const bDate = b.find((item: any) => item['key'] === sortState['key'])['title'];
        const aDateFormatted = moment(aDate, dateFormat);
        const bDateFormatted = moment(bDate, dateFormat);
        return sortState['order'] === SORT_TYPES.ASC
          ? bDateFormatted.diff(aDateFormatted)
          : aDateFormatted.diff(bDateFormatted);
      }
      return 0;
    });

  const onSortToggle = (header: TableHeaderProps): void => {
    const sortOrder = sortState.order === SORT_TYPES.DESC ? SORT_TYPES.ASC : SORT_TYPES.DESC;
    setSortState({ key: header.key, order: sortOrder, type: header.type });
  };

  useEffect(() => {
    const sortedDataExists = sortedData && sortedData.length;
    if (
      sortedDataExists &&
      defaultSelectedIndex !== undefined &&
      defaultSelectedIndex >= 0 &&
      sortedData !== undefined &&
      sortedData[defaultSelectedIndex][0]
    ) {
      setActiveItem(sortedData && sortedData[defaultSelectedIndex][0]);

      if (onRowClick && sortedData && sortedData[defaultSelectedIndex]) {
        onRowClick(sortedData[defaultSelectedIndex]);
      }
    }

    const sortableItem = tableHeaders.filter((item) => item.sort)[0];
    if (sortableItem) {
      setSortState({ key: sortableItem.key, order: SORT_TYPES.DESC, type: sortableItem.type });
    }
  }, []);

  if (tableData && tableData.length && sortedData) {
    return (
      <TableContainer data-test-id={testId}>
        <TableHead>
          <TableHeaderRow>
            {tableHeaders.map((header) => (
              <TableHeader key={header.key}>
                <Stack direction="row" alignItems="center">
                  {header.hasOwnProperty('sort') && (
                    <Stack
                      onClick={() => {
                        onSortToggle(header);
                      }}
                      data-testid={`${header.title}-sort`}
                      sx={{ cursor: 'pointer' }}>
                      <KeyboardArrowUp
                        color={
                          sortState['key'] === header.key && sortState.order === SORT_TYPES.ASC
                            ? 'primary'
                            : 'secondary'
                        }
                        sx={{ marginBottom: '-10px' }}
                      />
                      <KeyboardArrowDown
                        color={
                          sortState['key'] === header.key && !(sortState.order === SORT_TYPES.ASC)
                            ? 'primary'
                            : 'secondary'
                        }
                      />
                    </Stack>
                  )}
                  {header.title}
                </Stack>
              </TableHeader>
            ))}
          </TableHeaderRow>
        </TableHead>
        <TableBody data-test-id={`${testId}-body`}>
          {sortedData.map((item, index) => {
            const styledProps = {
              active: item[0].id === activeItem.id,
            };
            return (
              <TableRow
                data-test-id={`${testId}-row-${index}`}
                key={`${item[0].title}-${index}`}
                onClick={() => {
                  setActiveItem(item[0]);
                  if (onRowClick) {
                    onRowClick(item);
                  }
                }}>
                {item.map((data, x) => {
                  return (
                    <TableData key={x} {...styledProps}>
                      {data.title}
                      {data.subtitle && <SubTableData>{data.subtitle}</SubTableData>}
                    </TableData>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>
    );
  }
  return (
    <NoDataContainer>
      <FindInPageOutlinedIcon fontSize="large" htmlColor={theme.palette.grey[600]} />
      <NoDataText>{NO_DATA}</NoDataText>
    </NoDataContainer>
  );
};

export default Table;
