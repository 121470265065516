// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'gc-ui';
import { GCButton } from 'shared-components/components/FormFields';
import { ButtonType } from 'shared-components/enums';

import './ModalInfo.scss';

interface Props {
  title: string;
  text: string;
  isOpen: boolean;
  extraClass?: string;
  isButton?: boolean;
  buttonName?: string | undefined;
  buttonFunction?: (() => void) | undefined;
  dismissFunction: () => void;
}

class ModalInfo extends Component<Props> {
  public render(): JSX.Element {
    const { title, isOpen, text, dismissFunction, extraClass, isButton, buttonName, buttonFunction } = this.props;
    const showButton = isButton && buttonName && buttonFunction;
    return (
      <Fragment>
        <Modal
          id="modal-info"
          open={isOpen}
          onClose={dismissFunction}
          className={`modal-gc ${extraClass}`}
          maxWidth="sm">
          <ModalHeader id="modal-info-header" toggle={dismissFunction}>
            {title}
          </ModalHeader>
          <ModalBody>
            <span id="modal-info-body">{text}</span>
            {showButton && this.renderButton(buttonName, buttonFunction)}
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
  private renderButton = (buttonName: string | undefined, buttonFunction: (() => void) | undefined): JSX.Element => {
    return (
      <div>
        <GCButton title={String(buttonName)} onClick={buttonFunction} name="come-back-later" type={ButtonType.GREEN} />
      </div>
    );
  };
}

export default ModalInfo;
