// eslint-disable-next-line no-use-before-define
import React from 'react';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material';

interface Props {
  borderColor?: string;
  textColor?: string;
  backgroundColor?: string;
  maxWidth?: string;
  minWidth?: string;
  height?: string;
  borderRadius?: string;
  buttonText: React.ReactChild;
  fontWeight?: string;
  margin?: string;
  padding?: string;
  onClick?: (e: any) => void;
  disabled?: boolean;
  fontSize?: string;
  className?: string;
  width?: string;
  id?: string;
  testId?: string;
}

interface StyledProps {
  $borderColor: string;
  $textColor: string;
  $backgroundColor: string;
  $borderRadius: string;
  $height: string;
  $minWidth: string;
  $maxWidth: string;
  $padding: string;
  $fontWeight: string;
  $margin: string;
  $fontSize: string;
  $width: string;
}

const Button = styled('button')<StyledProps>`
  padding: ${({ $padding }: StyledProps): string => $padding};
  min-width: ${({ $minWidth }: StyledProps): string => $minWidth};
  max-width: ${({ $maxWidth }: StyledProps): string => $maxWidth};
  height: ${({ $height }: StyledProps): string => $height};
  border-radius: ${({ $borderRadius }: StyledProps): string => $borderRadius};
  color: ${({ $textColor }: StyledProps): string => $textColor};
  border: 2px solid ${({ $borderColor }: StyledProps): string => $borderColor};
  font-weight: ${({ $fontWeight }: StyledProps): string => $fontWeight};
  margin: ${({ $margin }: StyledProps): string => $margin};
  background-color: ${({ $backgroundColor }: StyledProps): string => $backgroundColor};
  font-size: ${({ $fontSize }: StyledProps): string => $fontSize};
  padding: ${({ $padding }: StyledProps): string => $padding};
`;

const GCButton = ({
  borderColor,
  textColor,
  fontWeight,
  backgroundColor,
  buttonText,
  minWidth,
  maxWidth,
  height,
  margin,
  padding,
  borderRadius,
  onClick,
  disabled,
  fontSize,
  className,
  width,
  id,
  testId = `${buttonText}-button`,
}: Props): JSX.Element => {
  const theme = useTheme();
  const styledProps = {
    $borderColor: borderColor || theme.palette.primary.main,
    $textColor: textColor || theme.palette.text.primary,
    $backgroundColor: backgroundColor || 'white',
    $height: height || '40px',
    $maxWidth: maxWidth || '240px',
    $minWidth: minWidth || '140px',
    $borderRadius: borderRadius || '100px',
    $fontWeight: fontWeight || '400',
    $margin: margin || 'auto',
    $fontSize: fontSize || '16px',
    $padding: padding || '0',
    $width: width || 'auto',
  };
  return (
    <Button
      id={id}
      type="button"
      data-test-id={testId}
      onClick={onClick}
      disabled={disabled || false}
      className={className}
      {...styledProps}>
      {buttonText}
    </Button>
  );
};

export default GCButton;
