// eslint-disable-next-line no-use-before-define
import React from 'react';

import { GCLogo, Horizon } from 'shared-components/images';

import './ErrorPage.scss';

const ErrorPage = (): JSX.Element => {
  return (
    <div id="error-page">
      <div id="error-container">
        <div>
          <Horizon className="horizon-logo" />
          <div id="error-title">{'You do not have permission to view this page'}</div>
          <div id="error-detail">
            <span>
              If you believe you are seeing this screen in error, please contact GenesisCare Innovation Support by email
              at:
            </span>
            &nbsp;
            <a href="mailto:InnovationSupport@genesiscare.com">InnovationSupport@genesiscare.com</a>
          </div>
        </div>
        <div id="error-logo">
          <GCLogo />
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
