// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'gc-ui';
import classnames from 'classnames';

import { DateTimeConverter } from 'shared-components/utils';

import './ModalOptIn.scss';

import { PatientOptIn } from 'op-interfaces';
import { GraphUpdate, ListData } from 'shared-components/interfaces';
import {
  ErrorInfo,
  FreeTextField,
  GCButton,
  SectionField,
  SegmentedInput,
} from 'shared-components/components/FormFields';
import { Logger } from 'shared-components/utils';
import { ButtonType } from 'shared-components/enums';
import { validateRegistration, ValidationKeys } from 'op-utils';
import { GET_PATIENTS_BY_EMAIL } from '../../OP/PatientSearch/PatientSearchQueries';
import { HEADER_TITLE, DESCRIPTION, SEGMENT_INPUT, FIELD_INFO, OPT_OUT_PLACEHOLDER } from './constants';
import Typography from '@mui/material/Typography';
import { theme } from 'theme';
import Grid from '@mui/material/Grid';
import Stack from '@mui/system/Stack';

const logger = new Logger('ModalOptIn');

const VALIDATION_MESSAGE: { [key: string]: { [key: string]: string } } = {
  primaryPhone: {
    INVALID_DATA: 'Please enter a valid mobile number.',
    MISSING_DATA: 'Please enter your mobile phone number.',
  },
  email: {
    INVALID_DATA: 'Please enter a valid email address.',
    MISSING_DATA: 'Please enter your email address.',
    DUPLICATE_EMAIL: 'Email address already in use, please use another email address',
  },
  pxOptOutComment: {
    MISSING_DATA: 'Please enter a reason for opting out.',
  },
};

interface ValidationObject {
  primaryPhone?: string[];
  email?: string[];
  pxOptOutComment?: string[];
}

export interface PatientSearchQueryItemAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
}

interface Props extends WithApolloClient<{}> {
  patient: PatientOptIn;
  isOpen: boolean;
  onClose: () => void;
  dismissFunction: () => void;
  saveFunction: (graphData: GraphUpdate[], optedIn: boolean, shouldSubmit: boolean) => void;
  patientIda: string;
  dob: string;
  address: PatientSearchQueryItemAddress;
  setMutationLoading: () => void;
  dismissLock: () => void;
}

interface State {
  pxSignedUp: string;
  validationObject: ValidationObject;
}

class ModalOptIn extends Component<Props, State> {
  private email = '';
  private primaryPhone = '';
  private pxOptOutComment = '';

  public constructor(props: Props) {
    super(props);

    // Default the variables
    const {
      patient: { email, primaryPhone, pxOptOutComment, pxOptedIn },
    } = props;

    this.email = email;
    this.primaryPhone = primaryPhone;
    this.pxOptOutComment = pxOptOutComment;

    let defaultSignedUp = '';
    if (pxOptedIn === true) {
      defaultSignedUp = SEGMENT_INPUT.OPT_IN;
    } else if (pxOptedIn === false) {
      defaultSignedUp = SEGMENT_INPUT.OPT_OUT;
    }

    this.state = {
      pxSignedUp: defaultSignedUp,
      validationObject: {},
    };
  }

  public render(): JSX.Element {
    logger.debug('render', 'State: ', JSON.stringify(this.state));
    const { isOpen, dismissFunction, patient, onClose, patientIda, dob, address } = this.props;
    const { pxSignedUp } = this.state;
    return (
      <div id="patient-portal-access">
        <Modal
          open={isOpen}
          onClose={onClose}
          className="modal-gc patient-portal-access"
          maxWidth="sm"
          PaperProps={{ style: { borderRadius: '16px' } }}>
          <ModalHeader toggle={dismissFunction}>{''}</ModalHeader>
          <ModalBody>
            <Typography variant="h5" className="header-title" sx={{ fontWeight: '600', textAlign: 'center' }}>
              {HEADER_TITLE}
            </Typography>
            <div className="description" style={{ textAlign: 'center', padding: '16px 0px' }}>
              <Typography variant="body1">{DESCRIPTION.LINE_1}</Typography>
              <Typography variant="body1">{DESCRIPTION.LINE_2}</Typography>
            </div>
            <form
              className="form-container"
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ width: '70%', paddingBottom: '16px' }}>
                <SegmentedInput
                  options={[
                    { id: SEGMENT_INPUT.OPT_IN, name: 'Sign up' },
                    { id: SEGMENT_INPUT.OPT_OUT, name: 'Opt out' },
                  ]}
                  fieldName={FIELD_INFO.OPT_IN.NAME}
                  defaultSelected={pxSignedUp}
                  itemSelected={(selectedItem): void => {
                    this.setState({
                      pxSignedUp: (selectedItem as ListData).id,
                      validationObject: {},
                    });
                  }}
                />
              </div>
              {this.renderConditionalForm()}
              {this.renderConditionalButtons()}
            </form>
            <div id="patient-portal-name">
              <Typography
                variant="h5"
                textAlign="center"
                sx={{
                  backgroundColor: theme.palette.grey[200],
                  padding: '8px 0px',
                }}>{`${patient.firstName} ${patient.lastName}`}</Typography>
              {this.renderPatientDetails('EMR', patient.emrInstance)}
              {this.renderPatientDetails('Patient ID', patientIda)}
              {this.renderPatientDetails('Date of birth', DateTimeConverter.getFormattedDateAsDDMonthYYYY(dob))}
              {this.renderPatientDetails('Address', this.createAddress(address))}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }

  private renderConditionalForm = (): JSX.Element => {
    const { pxSignedUp, validationObject } = this.state;
    const { patient } = this.props;

    if (pxSignedUp === SEGMENT_INPUT.OPT_IN) {
      // Convert the error messages
      let primaryPhoneErrors: string[] | undefined = undefined;
      let emailErrors: string[] | undefined = undefined;
      if (validationObject) {
        if (validationObject.primaryPhone && validationObject.primaryPhone.length > 0) {
          primaryPhoneErrors = validationObject.primaryPhone.map((primaryPhoneError: string) => {
            return VALIDATION_MESSAGE.primaryPhone[primaryPhoneError];
          });
        }

        if (validationObject.email && validationObject.email.length > 0) {
          emailErrors = validationObject.email.map((emailError: string) => {
            return VALIDATION_MESSAGE.email[emailError];
          });
        }
      }

      return (
        <div className="sign-up-form">
          <SectionField
            isValid={validationObject && validationObject.primaryPhone ? false : true}
            htmlFor={FIELD_INFO.PRIMARY_PHONE.NAME}
            title={FIELD_INFO.PRIMARY_PHONE.TITLE}>
            <FreeTextField
              inputName={FIELD_INFO.PRIMARY_PHONE.NAME}
              maxLength={25}
              placeholder={FIELD_INFO.PRIMARY_PHONE.PLACEHOLDER}
              defaultValue={patient.primaryPhone}
              onBlur={(e): void => {
                this.primaryPhone = e.target.value;
              }}
              errors={primaryPhoneErrors}
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.email ? false : true}
            htmlFor={FIELD_INFO.EMAIL.NAME}
            title={FIELD_INFO.EMAIL.TITLE}>
            <FreeTextField
              inputName={FIELD_INFO.EMAIL.NAME}
              maxLength={40}
              placeholder={FIELD_INFO.EMAIL.PLACEHOLDER}
              defaultValue={patient.email}
              onBlur={(e): void => {
                this.email = e.target.value;
              }}
              errors={emailErrors}
            />
          </SectionField>
        </div>
      );
    } else if (pxSignedUp === SEGMENT_INPUT.OPT_OUT) {
      let pxOptOutCommentErrors: string[] | undefined = undefined;
      if (validationObject && validationObject.pxOptOutComment && validationObject.pxOptOutComment.length > 0) {
        pxOptOutCommentErrors = validationObject.pxOptOutComment.map((commentError: string) => {
          return VALIDATION_MESSAGE.pxOptOutComment[commentError];
        });
      }
      return (
        <div className="opt-out-reason">
          <SectionField
            isValid={validationObject && validationObject.pxOptOutComment ? false : true}
            htmlFor={FIELD_INFO.OPT_OUT_REASON.NAME}
            title={FIELD_INFO.OPT_OUT_REASON.TITLE}>
            <textarea
              className={classnames({
                'error-state': validationObject && validationObject.pxOptOutComment,
              })}
              placeholder={OPT_OUT_PLACEHOLDER || ''}
              defaultValue={patient.pxOptOutComment}
              name="pxOptOutComment"
              maxLength={500}
              onBlur={(e): void => {
                this.pxOptOutComment = e.target.value;
              }}
            />
            <ErrorInfo errors={pxOptOutCommentErrors} />
          </SectionField>
        </div>
      );
    }

    return <Fragment />;
  };

  private renderConditionalButtons = (): JSX.Element => {
    const { pxSignedUp } = this.state;
    const { dismissFunction, setMutationLoading } = this.props;

    const buttonText = pxSignedUp === SEGMENT_INPUT.OPT_IN ? 'Send invitation link' : 'Save';

    // Create the button area
    if (pxSignedUp === SEGMENT_INPUT.OPT_IN || pxSignedUp === SEGMENT_INPUT.OPT_OUT) {
      return (
        <Stack direction="row" gap="8px" className="modal-opt-in-buttons">
          <GCButton
            title={'Cancel'}
            onClick={(e): void => {
              e.preventDefault();
              dismissFunction();
            }}
            type={ButtonType.WHITE}
          />
          <GCButton
            title={buttonText}
            onClick={(e): void => {
              setMutationLoading();
              e.preventDefault();
              this.saveValues();
            }}
            type={ButtonType.GREEN}
          />
        </Stack>
      );
    }

    return <Fragment />;
  };

  private saveValues = async (): Promise<void> => {
    const { pxSignedUp } = this.state;
    const {
      saveFunction,
      patient: { id, firstName, lastName },
      dismissLock,
    } = this.props;

    // Default values
    let pxOptedIn = false;
    let graphData = [{ key: 'pxOptOutComment', value: this.pxOptOutComment, type: 'String' }];
    const shouldSubmit = this.checkUpdatedValue();

    if (pxSignedUp === SEGMENT_INPUT.OPT_IN) {
      pxOptedIn = true;

      const validation = validateRegistration(
        { id, firstName, lastName, email: this.email, primaryPhone: this.primaryPhone },
        ValidationKeys.ContactPXSignUp,
      );

      // There is a validation error so do not continue after setting the state
      if (validation && Object.keys(validation).length > 0) {
        logger.debug('saveValues', 'Validation values: ', JSON.stringify(validation));
        this.setState({
          validationObject: { ...validation },
        });
        return;
      } else {
        const duplicateEmailCheck = await this.props.client?.query({
          query: GET_PATIENTS_BY_EMAIL,
          fetchPolicy: 'no-cache',
          variables: { email: this.email },
        });
        const patients = duplicateEmailCheck?.data.patients;
        const includesCurrentPatient = patients.filter((patient: { id: string }) => patient.id === id).length;

        if ((patients.length === 1 && !includesCurrentPatient) || patients.length > 1) {
          this.setState({
            validationObject: { email: ['DUPLICATE_EMAIL'] },
          });
          return;
        }
      }
    }

    if (pxOptedIn) {
      graphData = [
        { key: 'email', value: this.email, type: 'String' },
        { key: 'primaryPhone', value: this.primaryPhone, type: 'String' },
      ];
    } else {
      // Check to make sure that the px opt out comment has been filled out
      if (this.pxOptOutComment.trim().length === 0) {
        this.setState({
          validationObject: { pxOptOutComment: ['MISSING_DATA'] },
        });
        return dismissLock();
      }
    }

    saveFunction(graphData, pxOptedIn, shouldSubmit);
  };

  private checkUpdatedValue = (): boolean => {
    const {
      patient: { primaryPhone, email },
    } = this.props;
    const primary = primaryPhone.split(' ').join('');
    const inputPhone = this.primaryPhone.split(' ').join('');
    if (primary === inputPhone && email === this.email) {
      return false;
    }
    return true;
  };

  private renderPatientDetails = (
    headingText: string,
    detailText?: string | JSX.Element,
    fallbackText?: string,
  ): JSX.Element => {
    let optionalColouring = false;
    let displayText: string | JSX.Element | JSX.Element[] | undefined = undefined;

    displayText = this.renderContentMessage(detailText, fallbackText);
    optionalColouring = displayText === fallbackText || displayText === 'Not provided';

    return (
      <Grid container className="patient-data-row">
        <Grid item className="patient-data-title" xs={6}>
          <Typography variant="body1" fontSize="16px" textAlign="right" fontWeight={600} paddingRight="16px">
            {headingText}:
          </Typography>
        </Grid>
        <Grid item className={classnames('patient-data-content', { optional: optionalColouring })} xs={6}>
          <Typography variant="body1" fontSize="16px">
            {displayText}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  /**
   * Helper method to generate the detail text or the provided fall-back text if data is missing.
   */
  private renderContentMessage = (
    content?: string | JSX.Element,
    fallbackText?: string,
  ): JSX.Element | JSX.Element[] | string => {
    // If null or empty then return missing text
    if (!content) {
      if (fallbackText) {
        return fallbackText || '';
      } else {
        return 'Not provided';
      }
    }
    return content;
  };

  private createAddress = (address?: PatientSearchQueryItemAddress): JSX.Element => {
    if (address) {
      const { line1, line2, city, state, country, postcode } = address;

      if (line1 || city || state || country || postcode) {
        return (
          <Fragment>
            <div>
              {line1}&#44;
              <br />
              {this.renderLine2(line2)}
              {city}&#44;
              <br />
              {state} {country} {postcode}
            </div>
          </Fragment>
        );
      }
    }

    return <>Not provided</>;
  };

  private renderLine2 = (line2?: string): JSX.Element => {
    if (line2) {
      return (
        <Fragment>
          {line2}&#44;
          <br />
        </Fragment>
      );
    }

    return <Fragment />;
  };
}

const apolloModal = withApollo<Props>(ModalOptIn);
export default apolloModal;
