// eslint-disable-next-line no-use-before-define
import React from 'react';
import { FormikProps } from 'formik';
import FormBuilderComponent from './FBFormRow';
import { FormBuilderComponentConfig, OptionInterface } from './FormBuilderInterfaces';
import AutoSave from 'shared-components/components/FormikComponents/AutoSave';

function handleFieldUpdate(formikProps: FormikProps<any>, component: FormBuilderComponentConfig, value: any) {
  if (value && Object.keys(value).includes('value')) {
    const parsedNumber = parseFloat(value['value']);
    value['value'] = isNaN(parsedNumber) ? value['value'] : parsedNumber;
  }

  formikProps.setFieldValue(component.field.name, value);
}
interface FBComponentsProps {
  components: FormBuilderComponentConfig[];
  options: Record<string, OptionInterface[]>;
  autosaveMethod?: (values: any) => void;
  formikProps: FormikProps<any>;
}

export default function FBComponents(props: FBComponentsProps): JSX.Element {
  const { autosaveMethod, formikProps, components, options } = props;
  return (
    <>
      {components.map((component) => {
        return (
          <FormBuilderComponent
            key={`top_${component.label}`}
            formikProps={formikProps}
            component={component}
            options={options}
            handleUpdate={handleFieldUpdate}
          />
        );
      })}
      {autosaveMethod && <AutoSave saveMethod={autosaveMethod} />}
    </>
  );
}
