// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { RouteComponentProps, Redirect, withRouter, Link } from 'react-router-dom';

import { gql } from '@apollo/client';
import 'url-search-params-polyfill';
import * as Sentry from '@sentry/browser';

import './Login.scss';

import { ButtonType, LoginPageMode } from 'shared-components/enums';
import { Logger } from 'shared-components/utils';

import {
  Form,
  FormContent,
  FormSection,
  SectionField,
  FreeTextField,
  GCButton,
} from 'shared-components/components/FormComponents';
import LoginFormViewModel from './LoginFormViewModel';
import { NEXT_SEARCH_PARAM } from 'shared-components/models/ApolloHandlerModel';
import { ErrorInfo } from 'shared-components/components/FormFields';
import axios from 'axios';

import logo from 'shared-components/images/gclogo.png';
import Cookies from 'js-cookie';

const logger = new Logger('Login');

export const DEFAULT_DASHBOARD = '/px/appointments';
const PAGE_TITLE = 'Welcome!';
const PAGE_TITLE_RESET = 'Welcome back!';
const USERNAME_TITLE = 'Email Address';
const PASSWORD_TITLE = 'Password';
const LOGIN_ERROR = 'Email or password was incorrect!';
const LOGIN_RATE_LIMIT_MESSAGE = 'Maximum login attempts reached, you can try again in <n_minutes>.';
const LOGIN_RATE_LIMIT_MINUTES = 5;
const LOGIN_TITLE = 'Log In';

const API_ENDPOINT_LOGIN_PATH = '/server/patient/auth/login';
const API_ENDPOINT_TRUSTED_AGENT_PATH = '/server/patient/auth/trusted_agent';

/**
 * Interfaces
 */

export interface GraphUser {
  id: string;
  username: string;
  lastThreeMobile: string;
}

interface RedirectionParams {
  pathname: string;
  state?: {
    next?: string | null;
    mfaToken?: string;
    user: GraphUser;
    resetPassword?: boolean;
  };
}

interface SubmitButtonLockedState {
  submitButtonLocked?: boolean;
  minutesUntilSubmitButtonUnlocked?: number;
  loginError?: boolean;
  loginResponse?: LoginResults;
}

//interface Props extends RouteComponentProps<{},StaticContext, Location|any> {}

interface State {
  bypassMFA: boolean;
  passwordExpired: boolean;
  forgotPasswordRedirect: boolean;
  loading: boolean;
  loginError: boolean;
  loginResponse: LoginResults;
  submitButtonLocked: boolean;
  minutesUntilSubmitButtonUnlocked: number;
}

interface LoginResults {
  user?: {
    id: string;
    username: string;
    lastThreeMobile: string;
    userProfile: {
      usernamedEntered: string;
      forcedReset: boolean;
    };
    mfaDisabled?: boolean;
  };
  error?: string;
  details?: string;
}

export const LOGOUT = gql`
  mutation Logout {
    logout {
      errors
    }
  }
`;

class Login extends Component<Props, State> {
  public loginFormViewModel: LoginFormViewModel;
  private loggedOut = false;
  private pageMode: LoginPageMode = LoginPageMode.DEFAULT;

  public constructor(props: Props) {
    super(props);
    this.loginFormViewModel = new LoginFormViewModel();
    if (this.props.location.state && this.props.location.state.forgotPassword) {
      this.pageMode = LoginPageMode.RESET_PASSWORD;
    }

    this.state = {
      bypassMFA: false,
      passwordExpired: false,
      forgotPasswordRedirect: false,
      loading: false,
      loginError: false,
      loginResponse: {},
      submitButtonLocked: false,
      minutesUntilSubmitButtonUnlocked: 0,
    };
  }

  public componentDidMount(): void {
    this.updateSubmitButtonLockingFromSessionStorage();
    this.resetCacheOnLogout();
    if (!this.loggedOut) {
      logger.debug('componentDidMount', 'Calling logout');
      if (this.props.client !== undefined) {
        this.props.client.mutate({
          mutation: LOGOUT,
        });
      }
      logger.debug('componentDidMount', 'Called logout');
      this.loggedOut = true;
    }
  }

  private updateSubmitButtonLockingFromSessionStorage() {
    // loads the submit button locking values from session storage if they exist.
    const sessionStorageSubmitButtonLocked = sessionStorage.getItem('submitButtonLocked');
    const sessionStorageMinutesUntilSubmitButtonUnlocked = sessionStorage.getItem('minutesUntilSubmitButtonUnlocked');

    const stateUpdateValue: SubmitButtonLockedState = {};
    if (sessionStorageSubmitButtonLocked !== null) {
      // load submit button locked
      stateUpdateValue.submitButtonLocked = Boolean(sessionStorageSubmitButtonLocked === 'true');
      if (sessionStorageMinutesUntilSubmitButtonUnlocked !== null) {
        // load minutes until submit button is unlocked
        stateUpdateValue.minutesUntilSubmitButtonUnlocked = Number(sessionStorageMinutesUntilSubmitButtonUnlocked);
        // if this value is greater than 0 then we need to set the error state too!
        if (stateUpdateValue.minutesUntilSubmitButtonUnlocked > 0) {
          stateUpdateValue.loginError = true;
          stateUpdateValue.loginResponse = {
            error: 'E9010',
            details: 'rate limiting has been applied',
          };
        }
      }
      this.setState(stateUpdateValue as State, () => {
        // if the button is curerntly locked then we want to kick off the timer.
        if (this.state.submitButtonLocked) {
          this.waitForLockSubmitButtonUpdate();
        }
      });
    }
  }

  /**
   * Function that clear's the apollo cache when the login component is mounted.
   */
  private resetCacheOnLogout = (): void => {
    sessionStorage.removeItem('userId');
    const client = this.props.client;
    if (client !== undefined) {
      client.clearStore().then((): void => {
        client.writeQuery({
          query: gql`
            query {
              contentShown
            }
          `,
          data: {
            contentShown: false,
          },
        });
      });
    }
  };

  /**
   * Render error message for login
   * @returns {JSX.Element} Login error element
   */
  private renderError(): JSX.Element {
    let errorMessage: string;
    if (this.state.loginResponse.error === 'E9010' && this.state.minutesUntilSubmitButtonUnlocked > 0) {
      errorMessage = LOGIN_RATE_LIMIT_MESSAGE.replace(
        '<n_minutes>',
        this.state.minutesUntilSubmitButtonUnlocked > 1
          ? `${String(this.state.minutesUntilSubmitButtonUnlocked)} minutes`
          : `${String(this.state.minutesUntilSubmitButtonUnlocked)} minute`,
      );
    } else {
      errorMessage = LOGIN_ERROR; // Default error message
    }
    const resetPasswordLink = (
      <Link to="/px/forgotPassword" data-testid="forgot-password-link">
        Reset password
      </Link>
    );

    return <ErrorInfo errors={[errorMessage]} link={resetPasswordLink} />;
  }

  private lockSubmitButton = (): void => {
    // this will lock the submit button and start the first interval to decrement the minute counter
    const epochEndTime = Date.now() + 5 * 60 * 1000;
    this.setState({ submitButtonLocked: true, minutesUntilSubmitButtonUnlocked: LOGIN_RATE_LIMIT_MINUTES });
    sessionStorage.setItem('submitButtonLocked', String(this.state.submitButtonLocked));
    sessionStorage.setItem('minutesUntilSubmitButtonUnlocked', String(this.state.minutesUntilSubmitButtonUnlocked));
    // Set an epoch end time in session storage so we can check if the time has elapsed on return visits to the page
    sessionStorage.setItem('epochEndTime', String(epochEndTime));
    this.waitForLockSubmitButtonUpdate();
  };

  private waitForLockSubmitButtonUpdate = () => {
    // this is called each time we want to wait for the next update of submit button
    // broken into afunction since we call it potentially from 3 different places.
    setTimeout(this.updateLockSubmitButton, 1000 * 60);
  };

  private updateLockSubmitButton = (): void => {
    // this gets ran each minute by setTimeout
    const currentMinutesLeft = this.state.minutesUntilSubmitButtonUnlocked - 1;
    const epochEndTime = Number(sessionStorage.getItem('epochEndTime'));
    const epochCurrentTime = Date.now();

    if (currentMinutesLeft <= 0 || epochCurrentTime >= epochEndTime) {
      // time has elapsed, we can clear the values and refresh page
      this.setState({
        submitButtonLocked: false,
        minutesUntilSubmitButtonUnlocked: 0,
        loginError: false,
        loginResponse: {},
      });
    } else {
      // we still have time left, so start the timer again.
      this.setState({ minutesUntilSubmitButtonUnlocked: currentMinutesLeft });
      this.waitForLockSubmitButtonUpdate();
    }
    // update the session storage vaiables with the current state to  handle page refresh
    sessionStorage.setItem('submitButtonLocked', String(this.state.submitButtonLocked));
    sessionStorage.setItem('minutesUntilSubmitButtonUnlocked', String(this.state.minutesUntilSubmitButtonUnlocked));
  };
  private getCSRFCookie(): string {
    const csrfToken = Cookies.get('csrftoken');
    if (csrfToken) {
      return csrfToken;
    }
    return 'invalid_token';
  }

  private submit = (): void => {
    // Get values directly from dom as firefox autocomplete does not update the react state
    const username_field = document.getElementsByName('username')[0] as HTMLInputElement;
    const password_field = document.getElementsByName('password')[0] as HTMLInputElement;

    this.setState({ loading: true });
    const loginData = {
      username: username_field.value,
      password: password_field.value,
    };

    axios
      .post(API_ENDPOINT_LOGIN_PATH, loginData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFTOKEN': this.getCSRFCookie(),
        },
      })
      .then((response): void => {
        // Redirection
        const results = response.data as LoginResults;
        // Get whether the agent is trusted or not to determine whether to bypass mfa
        axios
          .get(API_ENDPOINT_TRUSTED_AGENT_PATH, {
            headers: {
              'X-CSRFTOKEN': this.getCSRFCookie(),
            },
          })
          .then((trustedAgentResponse): void => {
            this.setState({
              loading: false,
              loginError: false,
              loginResponse: response.data,
              bypassMFA: trustedAgentResponse.data.trustedAgent,
              passwordExpired: trustedAgentResponse.data.passwordExpired,
            });
            this.loginSuccess(results.user, trustedAgentResponse.data.token);
          })
          .catch((error): void => {
            this.setState({ loading: false, loginError: true, loginResponse: error.response.data });
            // React dosen't seem to like rerendering on axios error so force it to re-render
            this.forceUpdate();
          });
      })
      .catch((error): void => {
        if (error.response.status === 403) {
          this.setState({
            loading: false,
            loginError: true,
            loginResponse: {
              error: 'E9010',
              details: 'rate limiting has been applied',
            },
          });
          this.lockSubmitButton();
        } else {
          this.setState({ loading: false, loginError: true, loginResponse: error.response.data });
        }
        // React dosen't seem to like rerendering on axios error so force it to re-render
        this.forceUpdate();
      });
  };
  private clearErrorWarning = (): void => {
    // Clear the error warning state when the user makes input changes
    // Leaving the error message
    this.setState({
      loginError: false,
    });
  };
  private loginSuccess = (user: LoginResults['user'], token: string): void => {
    // Make sure TS is happy and we have a user. Should not run into this unless backend does not match interface
    if (!user) {
      throw Error('User is not defined');
    }
    // If there data, need to check if there is also a redirection
    // Check if there is a redirection from the login
    const searchParams = this.props.location.search;

    {
      const scope = Sentry.getCurrentScope();
      scope.setTag('user_type', 'patient');
      scope.setUser({
        id: user.id,
        username: user.username,
      });
    }

    let redirection = DEFAULT_DASHBOARD;
    if (searchParams) {
      const urlSearchParams = new URLSearchParams(searchParams);
      const nextParams = urlSearchParams.get(NEXT_SEARCH_PARAM);
      redirection = nextParams ? nextParams : redirection;
    }

    // Check to see if the mfa is disabled
    // As long as the login has worked, proceed onwards to the multi factor page
    let redirectionParams: RedirectionParams = {
      pathname: '/px/mfa/',
      state: { next: redirection, mfaToken: token, user: user },
    };

    // Change the redirection to the next in the search params
    if ((user.mfaDisabled || this.state.bypassMFA) && redirection) {
      redirectionParams = { pathname: redirection };

      // Check to determine if the password has expired to redirect the patient to the change password screen
      if (this.state.passwordExpired) {
        redirectionParams = {
          pathname: '/px/changePassword/',
          state: { next: redirection, user, resetPassword: true },
        };
      }
    }

    this.props.history.push(redirectionParams);
  };

  private getPageTitle(): string {
    if (this.pageMode === LoginPageMode.RESET_PASSWORD) {
      return PAGE_TITLE_RESET;
    }
    return PAGE_TITLE;
  }

  private renderSubTitle(): JSX.Element {
    if (this.pageMode === LoginPageMode.RESET_PASSWORD) {
      return (
        <div className="welcome-subtitle">
          Password sucessfully updated
          <div />
          Enter your new password to Log in
        </div>
      );
    }
    return <Fragment />;
  }

  public render(): JSX.Element {
    if (this.state.forgotPasswordRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: '/px/forgotPassword',
            state: {
              username: this.loginFormViewModel.loginForm.formData.username,
            },
          }}
        />
      );
    }

    const { loading, loginError, loginResponse } = this.state;
    return (
      <Fragment>
        <div className="auth-container login-page">
          <div className="auth-container-inner login-page">
            <div className="flex-horizontal-center welcome-container">
              <div>
                <div className="flex-horizontal-center welcome-title">
                  <img src={logo} alt="GenesisCare Logo" />
                </div>
                <div className="welcome-title hide-mobile">{this.getPageTitle()}</div>
                {this.renderSubTitle()}
              </div>
            </div>
            <Form
              id="patient-login-form"
              formData={this.loginFormViewModel.loginForm}
              submit={(): void => this.submit()}>
              <FormContent>
                <FormSection>
                  <SectionField htmlFor="username" title={USERNAME_TITLE} invalidInput={loginError}>
                    <FreeTextField
                      fieldName="username"
                      modelValue={this.loginFormViewModel.loginForm.formData['username']}
                      validateField={this.loginFormViewModel.validateField}
                      invalidInput={loginError}
                      showErrorMessage={false}
                      placeholder=""
                      updateParent={this.clearErrorWarning}
                    />
                  </SectionField>
                  <SectionField htmlFor="password" title={PASSWORD_TITLE} invalidInput={loginError}>
                    <FreeTextField
                      fieldName="password"
                      modelValue={this.loginFormViewModel.loginForm.formData['password']}
                      validateField={this.loginFormViewModel.validateField}
                      invalidInput={loginError}
                      showErrorMessage={false}
                      placeholder=""
                      secure={true}
                      updateParent={this.clearErrorWarning}
                    />
                  </SectionField>
                  {/* If there is an error take the auth error and render it */}
                  {loginResponse.error && this.renderError()}
                  <div className="flex-horizontal-center">
                    <GCButton
                      onClick={(e): void => {
                        if (e) {
                          e.preventDefault();
                        }
                        this.submit();
                      }}
                      name="login"
                      type={this.state.submitButtonLocked ? ButtonType.DISABLED : ButtonType.GREEN}
                      inputType="submit"
                      disabled={this.state.submitButtonLocked}
                      loading={loading}
                      title={LOGIN_TITLE}
                    />
                  </div>
                  {!loginResponse.error && (
                    <div className="flex-horizontal-center">
                      <div
                        className="link"
                        onClick={(): void => {
                          this.setState({
                            forgotPasswordRedirect: true,
                          });
                        }}>
                        Reset Password
                      </div>
                    </div>
                  )}
                </FormSection>
              </FormContent>
            </Form>
            <div id="trouble-container">
              <div>Having trouble logging in? Contact support</div>
              <a href="mailto: patientportal@genesiscare.com">patientportal@genesiscare.com</a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

interface Props extends RouteComponentProps<{}, any, Location | any>, WithApolloClient<{}> {}

// Wrap with apollo for cache access
// @ts-ignore
const apolloLogin = withApollo(Login);
// Export component with router
//@ts-ignore
export default withRouter(apolloLogin);
