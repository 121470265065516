import React, { Fragment, useState, useContext, useMemo } from 'react';

import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { getNextForm } from '../../../pages/OP/PatientNavigation/helpers';
import { FormContext } from 'op-pages/OP/PatientNavigation/context';

import DTContext from '../../../pages/OP/DistressThermometer/DTContext';

import './DTNavigator.scss';

import { StepperLink } from 'shared-components/interfaces';
import { NavigatorDirection, DistressThermometerVersion, FormStatus } from 'op-enums';
import { ModalSaveExit, ModalSubmit } from 'op-components';
import { ButtonType } from 'shared-components/enums';

import { GCButton } from 'shared-components/components/FormFields';

interface Props extends RouteComponentProps<{ patientId: string }> {
  links: StepperLink[];
  loading: boolean;
  submitCalled: boolean;
  submitFunction: () => void;
  distressVersion: string | null;
  showNewRego?: boolean;
}

const DTNavigator = (props: Props): JSX.Element => {
  const [submitModalIsOpen, setSubmitModalIsOpen] = useState<boolean>(false);
  const [saveExitModalOpen, setSaveExitModalOpen] = useState<boolean>(false);
  const { selectedRating } = useContext(DTContext);
  const { formStatusDetails, setFormStatusDetails } = useContext(FormContext);

  const {
    history,
    links,
    loading,
    submitCalled,
    submitFunction,
    location: { pathname },
    match: {
      params: { patientId },
    },
    distressVersion,
  } = props;

  const pathSegments = pathname.split('/');
  const currentPage = pathSegments[pathSegments.length - 1];
  const linksLength = useMemo<number>(() => links.length, [links]);

  let pageNumber: number | undefined = undefined;

  for (let i = 0; i < linksLength; i++) {
    const link = links[i];

    const linkHref = link.href.replace(/\//, '');

    if (linkHref === currentPage) {
      pageNumber = i;
      break;
    }
  }

  const firstPage = pageNumber === 0;
  const lastPage = pageNumber === linksLength - 1;

  const saveAndExitButtonTitle = 'Save and exit';
  const leftButtonTitle = 'Back';
  const rightButtonTitle = !lastPage ? 'Continue' : !submitCalled ? 'Submit' : 'Submitted';

  // Set up the submit button to display submitting for usability.
  let submitButtonText = 'Submit';
  if (loading) {
    submitButtonText = 'Submitting...';
  }

  if (submitCalled && !loading) {
    // Only redirect to PX portal if completing initial DT as part of rego pack
    const isDtInitial = distressVersion === DistressThermometerVersion.INITIAL;
    if (!isDtInitial) {
      return (
        <Redirect
          to={{
            pathname: `/patient/${patientId}/home`,
            state: { pxRedirect: isDtInitial },
          }}
        />
      );
    }
    const nextForm = getNextForm(formStatusDetails, patientId, 'distress', props?.showNewRego);
    const distressType = isDtInitial ? 'distressThermometerInitial' : 'distressThermometerEot';
    setFormStatusDetails({
      ...formStatusDetails,
      [distressType]: { status: FormStatus.REG_SUBMITTED },
    });
    return (
      <Redirect
        to={{
          pathname: nextForm?.path,
          state: { pxRedirect: isDtInitial },
        }}
      />
    );
  }

  const buttonHandler = (currentPageIndex: number, direction: NavigatorDirection, lastPage: boolean): void => {
    // On the last page, so call the submission instead
    if (lastPage && direction === NavigatorDirection.FORWARD) {
      setSubmitModalIsOpen(true);
    }
    // Need to traverse based on direction
    else {
      let navigateTo = '#';
      let nextIndex = currentPageIndex;

      switch (direction) {
        case NavigatorDirection.FORWARD:
          nextIndex += 1;
          break;
        case NavigatorDirection.BACK:
          nextIndex -= 1;
          break;
        default:
          break;
      }

      if (nextIndex >= 0 && nextIndex <= linksLength - 1) {
        navigateTo = links[nextIndex].href.replace(/\//, '');
        history.push(navigateTo);
      }
    }
  };

  const dismissModal = (): void => {
    setSubmitModalIsOpen(false);
  };

  const handleSaveAndExit = (): void => {
    setSaveExitModalOpen(true);
  };

  const dismissSNEModal = () => {
    setSaveExitModalOpen(false);
  };

  const exitForm = () => {
    history.push({ pathname: `/patient/${patientId}/home`, state: { pxRedirect: true } });
  };

  return (
    <Fragment>
      <ModalSaveExit
        isOpen={saveExitModalOpen}
        dismissFunction={dismissSNEModal}
        exitForm={exitForm}
        exitText={'Exit form'}
      />
      <ModalSubmit
        isOpen={submitModalIsOpen}
        dismissFunction={dismissModal}
        submitText={submitButtonText}
        submitForm={submitFunction}
        isPSO={false}
      />
      <div id="dt-nav-container">
        <div id="dt-nav-left" className="dt-nav-button">
          <GCButton title={saveAndExitButtonTitle} type={ButtonType.WHITE} onClick={(): void => handleSaveAndExit()} />
        </div>
        <div id="dt-nav-right" className="dt-nav-button justify-content-around">
          {!firstPage && (
            <GCButton
              title={leftButtonTitle}
              type={ButtonType.WHITE}
              onClick={(): void => {
                if (pageNumber !== undefined) {
                  buttonHandler(pageNumber, NavigatorDirection.BACK, lastPage);
                }
              }}
            />
          )}
          <GCButton
            disabled={currentPage === 'rating' && selectedRating < 0}
            title={rightButtonTitle}
            type={currentPage === 'rating' && selectedRating < 0 ? ButtonType.DISABLED : ButtonType.GREEN}
            onClick={(): void => {
              if (pageNumber !== undefined) {
                buttonHandler(pageNumber, NavigatorDirection.FORWARD, lastPage);
              }
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

const routedComponent = withRouter(DTNavigator);
export default routedComponent;
