import React from 'react';
import { ROAutocomplete, ROTextField, ROToggleButtons } from 'shared-components/components/FormFields';
import { BolusVersionProps } from '.';
import { DuplicateSimulationaBolusToPrescriptionInterface } from '../Interface';
import { useMutation } from '@apollo/client';
import { DUPLICATE_SIMULATION_BOLUS_TO_PRESCRIPTION } from '../Queries';
import { SectionTitle } from 'op-pages/RO/FormBuilder/Forms/FormElements';
import { SelectOptionType } from 'shared-components/components/FormFields';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';

export const validationV1 = [
  {
    parent: 'bolus',
    expansionValue: 'true',
    children: ['bolusLocation', 'bolusThickness', 'bolusFrequency'],
  },
];

const PrescriptionBolusFormV1 = ({
  options,

  site,
  updatePrescription,
  updateData,
  getBolusValue,
  cpotReadOnly,
  state,
  setState,
  isFieldValid,
}: BolusVersionProps) => {
  const [duplicateBolus] = useMutation(DUPLICATE_SIMULATION_BOLUS_TO_PRESCRIPTION, {
    awaitRefetchQueries: true,
    onCompleted: (data: DuplicateSimulationaBolusToPrescriptionInterface): void => {
      const newData = data.duplicateSimulationBolusToPrescription;
      // TODO: The reason location & thickness is converted from a field to just a value is because
      // the prescription page has a mixture of fields and values, rather than standardised data structures
      // In a future clean up card, the .value extractions should be removed in favour of just the field type
      setState({
        ...state,
        bolus: newData.bolus,
        bolusLocation: newData.bolusLocation?.value || '',
        bolusThickness: newData.bolusThickness?.value || '',
        bolusThicknessCustom: newData.bolusThicknessCustom?.value || '',
      });
    },
  });

  const copyBolus = (): void => {
    duplicateBolus({
      variables: { id: site.id },
    });
  };

  return (
    <>
      <SectionTitle>BOLUS DETAILS</SectionTitle>

      <ROToggleButtons
        id="treatmentBolus"
        fieldlabel={'Bolus'}
        options={options.bolus}
        value={getBolusValue()}
        disabled={cpotReadOnly}
        handleChange={(value): void => {
          const bolusBool = value === 'yes';
          setState({
            ...state,
            bolus: { value: bolusBool.toString(), isShown: true },
            bolusLocationType: '',
            bolusLocation: '',
            bolusThickness: '',
            bolusThicknessCustom: '',
            bolusFrequency: '',
            is3dBolus: '',
          });
          updatePrescription({
            variables: {
              siteId: site.id,
              bolus: bolusBool,
              bolusLocationType: '',
              bolusLocation: '',
              bolusThickness: '',
              bolusThicknessCustom: '',
              bolusFrequency: '',
              is3dBolus: '',
            },
          });
        }}>
        {!cpotReadOnly && (
          <div
            className="bolus-button"
            onClick={(): void => {
              if (!cpotReadOnly) copyBolus();
            }}>
            <ContentCopyIcon color="primary" />
            <div className="btn-label" id="copyBolus">
              Copy bolus from simulation
            </div>
          </div>
        )}
      </ROToggleButtons>
      {state?.bolus?.value === 'true' && (
        <>
          <ROTextField
            id="treatmentBolusLocation"
            fieldlabel="Bolus Location"
            value={state.bolusLocation}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              setState({ ...state, bolusLocation: event.target.value });
            }}
            onBlur={() => updateData()}
            error={!isFieldValid(state.bolusLocation)}
            disabled={cpotReadOnly}
            required
          />
          <ROAutocomplete
            id="treatmentBolusThickness"
            fieldlabel="Bolus Thickness"
            options={options.bolusThickness}
            value={{ value: state.bolusThickness, label: state.bolusThickness }}
            required
            inputProps={{
              error: !isFieldValid(state.bolusThickness),
            }}
            onChange={(option: SelectOptionType | string): void => {
              const value = typeof option === 'string' ? option : option?.value;
              setState({ ...state, bolusThickness: value, bolusThicknessCustom: '' });
              updatePrescription({
                variables: { siteId: site.id, bolusThickness: value, bolusThicknessCustom: '' },
              });
            }}
            disabled={cpotReadOnly}
          />
          {state?.bolusThickness === 'Custom' && (
            <ROTextField
              id="treatmentBolusThicknessCustom"
              value={state.bolusThicknessCustom}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setState({ ...state, bolusThicknessCustom: event.target.value });
              }}
              onBlur={() => updateData()}
              disabled={cpotReadOnly}
            />
          )}
          <ROAutocomplete
            id="bolusFrequency"
            fieldlabel="Bolus Frequency"
            options={options.bolusFrequency}
            value={{ value: state.bolusFrequency, label: state.bolusFrequency }}
            required
            inputProps={{
              error: !isFieldValid(state.bolusFrequency),
            }}
            onChange={(option: SelectOptionType | string): void => {
              const value = typeof option === 'string' ? option : option?.value;
              setState({ ...state, bolusFrequency: value });
              updatePrescription({ variables: { siteId: site.id, bolusFrequency: value } });
            }}
            disabled={cpotReadOnly}
          />
        </>
      )}
    </>
  );
};

export default PrescriptionBolusFormV1;
