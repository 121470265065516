// eslint-disable-next-line no-use-before-define
import React, { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import moment from 'moment';
import { LoadingSpinner, Card } from 'shared-components/components';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { DocumentType } from './Summary/interfaces';
import { useLazyQuery } from '@apollo/client';
import { GET_PATIENT_DOCUMENT } from './PatientProfileQueries';
import useInterval from 'shared-components/utils/useInterval';
import { useErrorModalContext } from 'op-contexts';
import { Stack, useTheme } from '@mui/material';
import { FindInPageOutlined as FindInPageOutlinedIcon } from '@mui/icons-material';

interface ROPatientDocProps {
  document: DocumentType | null;
}

const StyledCol = styled(Card)`
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
`;

const NoDocumentContainer = styled('div')`
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoDocumentMessage = styled('div')`
  margin-top: 28px;
  font-size: ${getRemSize(18)};
  font-weight: 700;
  color: ${(props) => props.theme.palette.secondary.dark};
`;

const ContainerTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const Title = styled('h2')`
  font-size: 18px;
  margin: 0;
`;

const Status = styled('span')`
  font-size: 18px;
  color: grey;
`;

const Date = styled('span')`
  font-size: ${getRemSize(16)};
`;

interface NoDocumentsProps {
  message: string;
  className?: string;
}

export const NoDocuments = ({ message, className }: NoDocumentsProps): JSX.Element => {
  const theme = useTheme();
  return (
    <NoDocumentContainer className={className || ''}>
      <FindInPageOutlinedIcon fontSize="large" htmlColor={theme.palette.grey[600]} />
      <NoDocumentMessage>{message}</NoDocumentMessage>
    </NoDocumentContainer>
  );
};

const ROPatientDocs = (props: ROPatientDocProps): JSX.Element => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [document, setDocument] = useState<DocumentType | null>(null);
  const [fetchAttempts, setFetchAttempts] = useState<number>(0);
  const [fetchDelay, setFetchDelay] = useState<number>(1000);
  const maxFetchAttempts = 100;
  const { setError } = useErrorModalContext();
  const [getDocument, { data, error }] = useLazyQuery(GET_PATIENT_DOCUMENT);
  useEffect(() => {
    if (error) return setError();
  }, [error]);

  const fetchDocument = () => {
    if (data && data.document?.docUrl) {
      fetch(data.document?.docUrl).then((res) => {
        if (res.ok) {
          if (document?.hash !== data.document?.hash) {
            setDocument(data.document);
          }
          setLoading(false);
          setFetchDelay(1000);
          setFetchAttempts(0);
        }
        if (fetchAttempts === maxFetchAttempts - 1) setFetchError(true);
        if (fetchAttempts > 0 && fetchDelay === 0) setFetchDelay(1000);
      });
      setFetchAttempts(fetchAttempts + 1);
    }
  };

  useEffect(() => {
    setFetchAttempts(0);

    if (props.document && props.document.id) {
      getDocument({
        variables: {
          documentId: props.document.id,
        },
      });
      setLoading(true);
      setFetchError(false);
    } else {
      setDocument(null);
      setLoading(false);
    }
  }, [props.document]);

  // Fetch the document before useInterval starts the timer
  useEffect(() => {
    fetchDocument();
  }, [data]);

  useInterval(fetchDocument, fetchAttempts < maxFetchAttempts && loading ? fetchDelay : null);

  return (
    <Stack sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      <StyledCol $noPadding>
        {!loading && !props.document && (
          <NoDocumentContainer>
            <FindInPageOutlinedIcon fontSize="large" htmlColor={theme.palette.grey[600]} />
            <NoDocumentMessage>No document to show</NoDocumentMessage>
          </NoDocumentContainer>
        )}
        {loading && !fetchError && (
          <LoadingSpinner
            loadingText={'Loading Document Viewer'}
            subtitle={'This may take longer for larger documents'}
            relativeSpinner={true}
          />
        )}
        {!loading && !fetchError && document && (
          <Fragment>
            <ContainerTitle>
              <Title data-test-id="document-preview-header">
                {props.document?.description !== '' ? props.document?.description : 'No title available'}
                <Status> ({props.document?.status})</Status>
              </Title>
              <Date>{moment(props.document?.dateOfDocument).utc(false).format('D MMMM YYYY h:mm:ss A')}</Date>
            </ContainerTitle>
            <iframe
              title="patientPDFView"
              src={document.docUrl.concat('#view=fitH')} // Add view parameter so PDF defaults to fit to width
              width="100%"
              style={{ height: 'calc(100% - 60px)' }}
              onLoad={(): void => {
                setLoading(false);
              }}
              onError={(): void => {
                setFetchError(true);
              }}>
              This browser does not support PDFs.
            </iframe>
          </Fragment>
        )}
      </StyledCol>
    </Stack>
  );
};

export default ROPatientDocs;
