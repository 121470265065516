import { gql } from '@apollo/client';

const TREATMENT_SITE_FRAGMENT = gql`
  fragment TreatmentSite on RoPortalTreatmentSiteType {
    id
    laterality
    phase
    technique
    modality
    dose
    fractions
    doseTo
    customDoseTo
    doseFrequency
    customDoseFrequency
    imagingFrequency {
      value
      isShown
    }
    imagingTechnique
    additionalPrescriptionInfo
    bolusLocationType
    bolusLocation
    bolusThickness
    bolusThicknessCustom
    bolus {
      value
      isShown
    }
    is3dBolus {
      value
      isShown
    }
    microdosimetryTld
    treatmentBreak {
      value
      isShown
    }
    treatmentBreakDuration
    treatmentBreakFraction
    treatmentSite
    bolusFrequency
    location
    treatmentOption
    cpotFraction
    changeReason
    changeReasonOther
    originalDose
    originalFractions
    ctv
    ptv
    basicDoseCalculation
    userAcknowledgedExceeded5gFraction
  }
`;

export const LOAD_PRESCRIPTION_SITE = gql`
  query siteListByCareplan($careplanId: ID!) {
    siteListByCareplan(careplanId: $careplanId) {
      id
      ...TreatmentSite
    }
  }

  ${TREATMENT_SITE_FRAGMENT}
`;

export const LOAD_PRESCRIPTION = gql`
  query siteListByCareplan($careplanId: ID!) {
    siteListByCareplan(careplanId: $careplanId) {
      id
      ...TreatmentSite
    }
  }

  ${TREATMENT_SITE_FRAGMENT}
`;

export const RESET_CONDITIONAL_FIELDS = gql`
  mutation resetConditionalFields($siteId: ID, $siteGroupId: ID, $fieldNames: [String]!) {
    resetConditionalFields(siteId: $siteId, siteGroupId: $siteGroupId, fieldNames: $fieldNames) {
      success
    }
  }
`;

export const RESET_CONDITIONAL_FIELDS_TO_LAST_PRESCRIPTION = gql`
  mutation resetConditionalFieldsToLastPrescription($siteId: ID, $siteGroupId: ID, $fieldNames: [String]!) {
    resetConditionalFieldsToLastPrescription(siteId: $siteId, siteGroupId: $siteGroupId, fieldNames: $fieldNames) {
      success
    }
  }
`;

export const RESET_PRESCRIPTION = gql`
  mutation resetPrescription($siteId: ID!) {
    resetPrescription(siteId: $siteId) {
      site {
        id
        ...TreatmentSite
      }
    }
  }

  ${TREATMENT_SITE_FRAGMENT}
`;

export const RESET_TO_LAST_PRESCRIPTION = gql`
  mutation resetToLastPrescription($siteId: ID!) {
    resetToLastPrescription(siteId: $siteId) {
      site {
        id
      }
    }
  }
`;

export const LOAD_SITE_TEMPLATE_VARIATION_VALUES = gql`
  query loadSiteTemplateValuesVariation($siteId: ID!) {
    loadSiteTemplateValuesVariation(siteId: $siteId) {
      fieldName
      value
    }
  }
`;

export const UPDATE_PRESCRIPTION = gql`
  mutation updatePrescription(
    $siteId: ID
    $phase: String
    $technique: String
    $modality: String
    $dose: [Float]
    $doseLow: String
    $doseHigh: String
    $doseIntermediate: [String]
    $fractions: Int
    $doseTo: String
    $customDoseTo: String
    $doseFrequency: String
    $customDoseFrequency: String
    $imagingFrequency: String
    $imagingTechnique: String
    $additionalPrescriptionInfo: String
    $bolus: Boolean
    $bolusLocationType: String
    $bolusLocation: String
    $bolusThickness: String
    $bolusThicknessCustom: String
    $bolusFrequency: String
    $is3dBolus: String
    $microdosimetryTld: String
    $treatmentBreak: Boolean
    $treatmentBreakDuration: String
    $treatmentBreakFraction: String
    $treatmentOption: String
    $ctv: [String]
    $ptv: [String]
    $changeReason: String
    $changeReasonOther: String
    $cpotFraction: Int
    $basicDoseCalculation: String
    $userAcknowledgedExceeded5gFraction: String
  ) {
    updatePrescription(
      siteId: $siteId
      phase: $phase
      technique: $technique
      modality: $modality
      dose: $dose
      doseLow: $doseLow
      doseHigh: $doseHigh
      doseIntermediate: $doseIntermediate
      fractions: $fractions
      doseTo: $doseTo
      customDoseTo: $customDoseTo
      doseFrequency: $doseFrequency
      customDoseFrequency: $customDoseFrequency
      imagingFrequency: $imagingFrequency
      imagingTechnique: $imagingTechnique
      additionalPrescriptionInfo: $additionalPrescriptionInfo
      bolusLocationType: $bolusLocationType
      bolusLocation: $bolusLocation
      bolusThickness: $bolusThickness
      bolusThicknessCustom: $bolusThicknessCustom
      bolus: $bolus
      bolusFrequency: $bolusFrequency
      is3dBolus: $is3dBolus
      microdosimetryTld: $microdosimetryTld
      treatmentBreak: $treatmentBreak
      treatmentBreakDuration: $treatmentBreakDuration
      treatmentBreakFraction: $treatmentBreakFraction
      treatmentOption: $treatmentOption
      ctv: $ctv
      ptv: $ptv
      changeReason: $changeReason
      changeReasonOther: $changeReasonOther
      cpotFraction: $cpotFraction
      basicDoseCalculation: $basicDoseCalculation
      userAcknowledgedExceeded5gFraction: $userAcknowledgedExceeded5gFraction
    ) {
      site {
        id
        phase
        technique
        modality
        fractions
        dose
        doseTo
        customDoseTo
        doseFrequency
        customDoseFrequency
        imagingFrequency
        imagingTechnique
        additionalPrescriptionInfo
        bolusLocationType
        bolusLocation
        bolusThickness
        bolusThicknessCustom
        bolus
        bolusFrequency
        is3dBolus
        microdosimetryTld
        treatmentBreak
        treatmentBreakDuration
        treatmentBreakFraction
        treatmentOption
        ctv
        ptv
        changeReason
        changeReasonOther
        cpotFraction
        originalFractions
        originalDose
        basicDoseCalculation
        userAcknowledgedExceeded5gFraction
      }
    }
  }
`;

export const DUPLICATE_SIMULATION_BOLUS_TO_PRESCRIPTION = gql`
  mutation duplicateSimulationBolusToPrescription($id: ID!) {
    duplicateSimulationBolusToPrescription(id: $id) {
      bolus {
        value
        isShown
      }
      bolusLocation {
        value
        isShown
      }
      bolusThickness {
        value
        isShown
      }
      bolusThicknessCustom {
        value
        isShown
      }
    }
  }
`;
