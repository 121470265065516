// eslint-disable-next-line no-use-before-define
import React from 'react';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { Modal as MuiModal, ModalHeader, ModalFooter } from 'gc-ui';
import GCButton from 'shared-components/components/UIFormComponents/GCButton';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { Typography, useTheme } from '@mui/material';

interface ButtonsContainerProps {
  centeredButton?: boolean;
}

const isMobileDevice = (): boolean => window.innerWidth < 768;

const Header = styled(ModalHeader)`
  font-size: ${getRemSize(23)};
  text-align: center;
  padding: 40px 40px 16px;
  border-bottom: none;
`;

const ModalBody = styled('div')`
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
`;

const ContentWrapper = styled('div')`
  font-size: ${getRemSize(16)};
  display: block;
  margin: 16px 0;
  padding: 0 40px;
`;

export const MainSection = styled('section')`
  font-size: 1.125rem;
  margin-bottom: 10px;
  line-height: 28px;
`;

export const SubSection = styled('section')`
  line-height: 24px;
  margin-bottom: 16px;
`;

const ButtonsContainer = styled('section')<ButtonsContainerProps>`
  display: flex;
  width: 100%;
  margin-bottom: 0;
  padding: 16px 8px 16px;
  border-top: 2px solid ${(props) => props.theme.palette.primary.main};
  justify-content: ${(props: ButtonsContainerProps): string => `${props?.centeredButton ? 'center' : 'flex-end'}`};
`;

interface Props {
  isOpen: boolean;
  title: string;
  size?: 'sm' | 'lg' | 'xl';
  children?: React.ReactNode;
  leftButtonText?: string;
  rightButtonText?: string;
  dismissFunction?: () => void;
  leftButtonHandleClick?: (...args: any[]) => void;
  rightButtonHandleClick?: (...args: any[]) => void;
  leftButtonLink?: string;
  rightButtonLink?: any;
  leftButtonWidth?: string;
  rightButtonWidth?: string;
  rightButtonBackground?: string;
  rightButtonBorder?: string;
  centeredButton?: boolean;
  rightButtonDisabled?: boolean;
  btnContainerClasses?: string;
  headerClasses?: string;
}
const Modal = ({
  isOpen,
  size,
  title,
  children,
  leftButtonText,
  rightButtonText,
  dismissFunction,
  leftButtonHandleClick,
  rightButtonHandleClick,
  leftButtonLink,
  rightButtonLink,
  leftButtonWidth,
  rightButtonBackground,
  rightButtonBorder,
  rightButtonWidth,
  centeredButton,
  rightButtonDisabled,
  btnContainerClasses,
  headerClasses,
}: Props): JSX.Element => {
  const showModalFooter = leftButtonText || rightButtonText;
  const theme = useTheme();

  return (
    <MuiModal
      open={isOpen}
      onClose={dismissFunction ? dismissFunction : undefined}
      maxWidth={size}
      PaperProps={{ style: { maxWidth: `${size === 'sm' ? '360px' : '600px'}` } }}>
      <Header
        toggle={dismissFunction ? dismissFunction : undefined}
        className={headerClasses ? `${headerClasses} modal-title` : 'modal-title'}>
        <Typography variant="h5" fontWeight={600}>
          {title}
        </Typography>
      </Header>
      <ModalBody className="modal-body">
        <ContentWrapper>{children}</ContentWrapper>
      </ModalBody>
      {showModalFooter && (
        <ModalFooter>
          <ButtonsContainer centeredButton={centeredButton} className={btnContainerClasses}>
            {leftButtonText && (
              <Link to={leftButtonLink ? leftButtonLink : ''}>
                <GCButton
                  buttonText={leftButtonText}
                  fontWeight={'700'}
                  minWidth={isMobileDevice() ? '' : leftButtonWidth ? leftButtonWidth : '170px'}
                  margin={'0 16px 0 0'}
                  onClick={leftButtonHandleClick}
                />
              </Link>
            )}
            {rightButtonText && (
              <Link to={rightButtonLink ? rightButtonLink : ''}>
                <GCButton
                  buttonText={rightButtonText}
                  backgroundColor={rightButtonBackground ? rightButtonBackground : theme.palette.primary.dark}
                  borderColor={rightButtonBorder ? rightButtonBorder : theme.palette.primary.dark}
                  textColor={theme.palette.primary.contrastText}
                  fontWeight={'700'}
                  minWidth={isMobileDevice() ? '' : rightButtonWidth ? rightButtonWidth : '170px'}
                  margin={'0 16px 0 0'}
                  onClick={rightButtonHandleClick}
                  disabled={rightButtonDisabled}
                />
              </Link>
            )}
          </ButtonsContainer>
        </ModalFooter>
      )}
    </MuiModal>
  );
};

export default Modal;
