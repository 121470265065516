import { FieldProps, getIn } from 'formik';
import React, { useMemo, useState } from 'react';
import Select, { components, MenuPlacement } from 'react-select';
import { styled } from '@mui/system';
import HelperMessage from '../UIFormComponents/HelperMessage';
import { ROHelperText } from '../FormFields';
import { useTheme } from '@mui/material';

interface Option {
  label: string;
  value: string;
}
interface SelectFieldInterface extends FieldProps {
  options: Option[];
  placeholder: string;
  disabled?: boolean;
  menuPlacement?: string;
  updateMutation: (value: string | null) => void;
}

const selectStyle = (isFieldInvalid: boolean) => {
  const theme = useTheme();
  return {
    dropdownIndicator: () => ({ color: theme.palette.primary.main, marginRight: '8px' }),
    indicatorSeparator: () => ({ display: 'none' }),
    control: (base: any, state: any) => ({
      ...base,
      border: isFieldInvalid
        ? `1px solid ${theme.palette.error.main}`
        : state.isFocused
        ? `1px solid ${theme.palette.primary.main}`
        : `1px solid ${theme.palette.secondary.main}`,
      boxShadow: 'none',
      color: theme.palette.text.primary,
      height: '40px',
      '&:hover': {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    }),
    input: (base: any) => ({
      ...base,
      color: theme.palette.text.primary,
    }),
    valueContainer: (base: any) => ({
      ...base,
      lineHeight: '32px',
      fontSize: '14px',
      padding: '0px 8px',
    }),
    option: (styles: any, state: { isDisabled: boolean; isFocused: boolean; isSelected: boolean }) => {
      return {
        ...styles,
        color: theme.palette.text.primary,
        fontSize: '14px',
        backgroundColor: state.isSelected
          ? theme.palette.primary.light
          : state.isFocused
          ? theme.palette.secondary.light
          : undefined,
        cursor: 'pointer',
        ':active': {
          ...styles[':active'],
          backgroundColor: theme.palette.primary.light,
        },
      };
    },
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  };
};

interface CustomFooterProps {
  isselected: string;
}

const ratherNotSayOption = {
  label: 'Rather not say',
  value: 'Rather not say',
};

const CustomFooter = styled('div')<CustomFooterProps>`
  line-height: 32px;
  font-size: 1rem;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  font-size: 14px;
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;
  border-top: 1px solid ${(props) => props.theme.palette.secondary.main};
  text-decoration: underline;
  background-color: ${(props) => (props.isselected === 'true' ? props.theme.palette.primary.light : 'white')};
`;

const SelectField = (props: SelectFieldInterface): JSX.Element => {
  const { options, field, form, updateMutation, disabled = false, menuPlacement = 'auto', ...otherProps } = props;

  const theme = useTheme();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { name, value, onBlur } = field;
  const { errors, touched, submitCount, setFieldValue, setFieldTouched } = form;
  const fieldError = getIn(errors, name);
  const fieldTouched = getIn(touched, name);
  const isFieldInvalid = (submitCount > 0 || fieldTouched) && fieldError;
  const inputId = `${name}-select-input`;

  const selectedValue = useMemo(() => options?.find((option: Option) => option.value === value), [value, options]);

  const updateField = (value: any) => {
    updateMutation(value ? value.value : '');
    setFieldValue(name, value.value, true);
    setMenuIsOpen(false);
  };

  const Menu = useMemo(
    () =>
      ({ children, ...props }: any) =>
        (
          <components.Menu {...props}>
            {children}
            <CustomFooter
              onClick={() => {
                updateField(ratherNotSayOption);
                // This is here to force the select component to blur after choosing the footer option.
                // Without this clicking the select again won't open the menu, and the no-screen
                // keyboard for ipad will be left open
                // @ts-expect-error blur doesn't exist on type Element
                document?.querySelector(`#${inputId}`)?.blur();
              }}
              id={`${name}-footer-option`}
              isselected={(value === ratherNotSayOption?.value).toString()}>
              {ratherNotSayOption.label}
            </CustomFooter>
          </components.Menu>
        ),
    [options],
  );

  const handleBlur = (ev: any) => {
    // On ipad the onBlur runs before the custom footer onClick is called, making that option unselectable
    // adding this timeout to the blur prevents that
    setTimeout(() => {
      setMenuIsOpen(false);
      setFieldTouched(name, true);
      onBlur(ev);
    }, 150);
  };

  return (
    <div
      data-testid={`select-${name}`}
      onClick={() => {
        if (!menuIsOpen) setMenuIsOpen(true);
      }}
      onBlur={handleBlur}>
      <Select
        {...otherProps}
        inputId={inputId}
        styles={selectStyle(isFieldInvalid)}
        options={options?.filter((option: Option) => option?.label !== ratherNotSayOption?.label)}
        name={name}
        value={selectedValue}
        onFocus={() => setMenuIsOpen(true)}
        menuShouldScrollIntoView
        menuPortalTarget={document.body}
        onBlur={handleBlur}
        onChange={(value: any) => updateField(value)}
        classNamePrefix={name}
        isDisabled={disabled}
        components={{ Menu }}
        menuIsOpen={menuIsOpen}
        menuPlacement={menuPlacement as MenuPlacement}
      />
      {isFieldInvalid && <ROHelperText id={name} error={true} helperText={fieldError} />}
    </div>
  );
};

export default SelectField;
