import './CarePlanDashboard.scss';

// eslint-disable-next-line no-use-before-define
import React from 'react';

import { redirectCareplanPath } from 'op-pages/RO/Careplan/Common';

import { StyledTile } from '../Dashboard';
import moment from 'moment';
import { SORT_TYPES, DATATYPE } from 'shared-components/components/Table/Table';
import ROCarePlanTable, {
  DropDownObject,
  SearchInput,
  NameComponent,
  CareplanNameComponent,
  StatusComponent,
  PriorityComponent,
  LastModifiedComponent,
  DepartmentComponent,
  TABLE_KEYS,
  TABLE_LABELS,
} from './ROCarePlanTable';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { Careplan } from 'op-pages/RO/Careplan/Interface';
import { useTheme } from '@mui/material';

interface Props {
  currentPage: number;
  careplans: any[];
  totalPages: number;
  searchTerm: string;
  onPageChange: any;
  onSearchStringChange: any;
  onPlanStatusChange: any;
  onPriorityChange: any;
  onOrderingChange: any;
  statusDropDownData: DropDownObject[];
  selectedStatus: string[];
  orderingDirection: string;
  orderType: string;
  onRowClick: (_careplanUrl: string) => void;
  careplanDropDownData: DropDownObject[];
  onCareplanFilterChange: any;
  careplanFilterSelected: string[];
}

const ROCarePlanDashboard = (props: Props) => {
  const {
    currentPage,
    totalPages,
    careplans,
    statusDropDownData,
    searchTerm,
    selectedStatus,
    careplanDropDownData,
    careplanFilterSelected,
    onRowClick,
    onOrderingChange,
    onSearchStringChange,
    onPageChange,
    onCareplanFilterChange,
    onPlanStatusChange,
  } = props;
  const theme = useTheme();

  const searchValue = searchTerm || '';

  const careplansWithIdentifier = careplans.map((item: any) => {
    return { ...item, patientName: item.patient.fullName };
  });

  const headers = [
    {
      title: TABLE_LABELS.PATIENT_NAME,
      key: TABLE_KEYS.PATIENT_NAME,
      sort: true,
      type: DATATYPE.STRING,
      width: '20%',
    },
    {
      title: TABLE_LABELS.DEPARTMENT,
      key: TABLE_KEYS.DEPARTMENT,
      type: DATATYPE.STRING,
      width: '10%',
    },
    {
      title: TABLE_LABELS.CAREPLAN,
      key: TABLE_KEYS.CAREPLAN,
      type: DATATYPE.STRING,
      width: '30%',
      filterOptions: careplanDropDownData,
      onFilterChange: onCareplanFilterChange,
      dropdownTitle: TABLE_LABELS.CAREPLAN,
      badgeNumber: careplanFilterSelected.length !== 0 ? careplanFilterSelected.length : undefined,
    },
    {
      title: TABLE_LABELS.STATUS,
      key: TABLE_KEYS.STATUS,
      type: DATATYPE.STRING,
      width: '15%',
      filterOptions: statusDropDownData,
      onFilterChange: onPlanStatusChange,
      dropdownTitle: `${TABLE_LABELS.STATUS}${selectedStatus.length === statusDropDownData.length ? ' (All)' : ''}`,
      badgeNumber: selectedStatus.length !== 0 ? selectedStatus.length : undefined,
    },
    {
      title: TABLE_LABELS.PRIORITY,
      key: TABLE_KEYS.PRIORITY,
      type: DATATYPE.STRING,
      width: '15%',
    },
    {
      title: TABLE_LABELS.LAST_MODIFIED,
      key: TABLE_KEYS.LAST_MODIFIED,
      sort: true,
      type: DATATYPE.DATE,
      width: '20%',
    },
  ];
  const tableData = careplansWithIdentifier.map((careplan: Careplan) => ({
    tableRows: [
      {
        key: TABLE_KEYS.PATIENT_NAME,
        width: '20%',
        sortableField: careplan.patient.fullName,
        component: (
          <NameComponent
            careplan={careplan}
            showLink={CurrentAppConfig.RadiationDashboard.careplanDashboard.isPatientSummaryLinkEnabled}
            onOpenCareplan={onRowClick}
          />
        ),
      },
      {
        key: TABLE_KEYS.DEPARTMENT,
        width: '10%',
        component: <DepartmentComponent careplan={careplan} />,
      },
      {
        key: TABLE_KEYS.CAREPLAN,
        width: '30%',
        component: <CareplanNameComponent careplan={careplan} />,
        careplan: careplan,
      },
      {
        key: TABLE_KEYS.STATUS,
        width: '15%',
        component: <StatusComponent careplan={careplan} />,
      },
      {
        key: TABLE_KEYS.PRIORITY,
        width: '15%',
        component: <PriorityComponent careplan={careplan} />,
      },
      {
        key: TABLE_KEYS.LAST_MODIFIED,
        width: '20%',
        sortableField: moment(careplan.updatedAt),
        component: <LastModifiedComponent careplan={careplan} />,
      },
    ],
    onRowClick: () => {
      if (careplan.latestPageUrl) {
        onRowClick(redirectCareplanPath(careplan, careplan.patient.id));
      }
    },
    rowHoverColor: theme.palette.primary.light,
  }));

  const tableProps = {
    tableHeaders: headers,
    tableData: tableData,
    defaultSortState: { key: TABLE_KEYS.LAST_MODIFIED, order: SORT_TYPES.ASC, type: DATATYPE.DATE },
  };

  return (
    <div className="RO-dashboard-wrapper">
      <StyledTile className="careplan-dashboard-wrapper">
        <div className="filter-wrapper">
          <div className="table-title-style">
            <span className="table-title">Careplan Dashboard</span>
          </div>
          <div className="table-filters">
            <SearchInput
              inputValue={searchValue}
              id="patient-gc-search"
              placeholder="Search patient name or patient ID…"
              onSubmitSearch={(searchString: string) => onSearchStringChange(searchString)}></SearchInput>
          </div>
        </div>
        <div className="table-wrapper">
          <ROCarePlanTable
            orderingDirection={props.orderingDirection}
            orderType={props.orderType}
            carePlans={careplans}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            onRowClick={onRowClick}
            onOrderChange={(order: string) => onOrderingChange(order)}
            tableProps={tableProps}
          />
        </div>
      </StyledTile>
    </div>
  );
};

export default ROCarePlanDashboard;
