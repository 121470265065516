import React, { Fragment, useContext, useEffect, useReducer, useState } from 'react';
import { useRouteMatch, useHistory, Prompt } from 'react-router-dom';

import { ROPatientCarePlanRoute } from '../Interface';
import ROPatientCarePlanPageFooter from '../Footer';
import { useMutation, useQuery } from '@apollo/client';
import { SUBMIT_PRESCRIPTION, TRIGGER_CPOT, UPDATE_SIM_SUBMISSION } from '../Queries';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'gc-ui';
import { ButtonRO } from 'shared-components/components/FormFields';
import { ButtonMode, ButtonType } from 'shared-components/enums';
import { CareplanBanners, changesFromLastEdited, hasSubmitted } from '../Banner/Banner';
import { GET_CAREPLAN, USER_DATA } from '../Queries';
import './SubmissionPage.scss';
import { WarningInfo } from 'shared-components/components/FormFields';
import { CurrentAppConfig } from '../AppConfig';
import { LoadingSpinner } from 'shared-components/components';
import CareplanContainer from '../Container';
import { useErrorModalContext, UserContext } from 'op-contexts';
import ModalCpotCancel from '../ModalCpotCancel/ModalCpotCancel';
import ModalCpotStart from '../ModalCpotStart/ModalCpotStart';
import { ModalSimulationSubmission, ROSidePanel } from 'op-components';
import { Region } from 'shared-components/enums';
import { usePreventUnload } from 'shared-components/utils/CustomHooks';
import { CAREPLAN_PAGES } from '../Constants';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { LARGE_DISPLAY_SIZE } from 'shared-components/enums';
import { GET_PEER_REVIEW, GET_DIAGNOSIS_FROM_CAREPLAN } from '../SidePanel/SidePanelQueries';
import { Logger } from 'shared-components/utils';
import { logMissingDiagnosisCode } from '../Common';
import { isDemo } from 'op-utils';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;

const logger = new Logger('SubmissionPage.tsx');

const ROPatientTreatmentSubmissionPage = (): JSX.Element => {
  const windowSize = useWindowSize();
  const isSmallDevice = windowSize.width < LARGE_DISPLAY_SIZE;
  const { setError } = useErrorModalContext();
  const history = useHistory();
  const [state, setState] = useReducer((state: any, changeState: any): any => ({ ...state, ...changeState }), {
    planAimModal: false,
    submitting: false,
    submissionType: CurrentAppConfig.SubmissionPage.PlanAimSubmissionType,
    loading: true,
    error: false,
  });
  const userDetails = useContext(UserContext);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showStartModal, setShowStartModal] = useState(false);
  const [showSimSubmissionModal, setShowSimSubmissionModal] = useState(false);
  const [modalEnabled, setModalEnabled] = useState(CurrentAppConfig.DisplayModal.submission);
  const showUKSubmissionModal = CurrentAppConfig.DisplayModal.submission && region === Region.UK;
  const showSpecialPhysicsConsultPage = CurrentAppConfig.SpecialPhysicsConsultVisible;

  const togglePlanAim = () => {
    setState({ planAimModal: !state.planAimModal });
  };

  const match = useRouteMatch<ROPatientCarePlanRoute>();
  const { id: patientId, careplanId } = match.params;

  const [triggerCpot] = useMutation(TRIGGER_CPOT, {
    awaitRefetchQueries: true,
    variables: {
      id: careplanId,
      cpotTriggered: true,
    },
    refetchQueries: [{ query: GET_CAREPLAN, variables: { id: careplanId } }],
  });
  const [cancelCpot] = useMutation(TRIGGER_CPOT, {
    awaitRefetchQueries: true,
    variables: {
      id: careplanId,
      careplanStatus: 'Prescription',
      cpotTriggered: false,
    },
    refetchQueries: [{ query: GET_CAREPLAN, variables: { id: careplanId } }],
  });

  const [submitPrescription] = useMutation(SUBMIT_PRESCRIPTION, {
    refetchQueries: [
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: GET_PEER_REVIEW, variables: { careplanId } },
    ],
  });

  const [updateSimSubmission] = useMutation(UPDATE_SIM_SUBMISSION);

  const handleSubmission = (submissionType: string) => {
    setState({ submitting: true, loading: true });
    submitPrescription({ variables: { id: careplanId, status: submissionType } }).then(() => {
      setState({ submitting: false, planAimModal: false, loading: false });
    });
    if (isDemo) {
      const medical_link = `/server/demo/document/medical/${patientId}/${careplanId}/Medical-Necessity`;
      const special_treatment_link = `/server/demo/document/medical/${patientId}/${careplanId}/LMN%20Special%20Treatment`;

      window.open(medical_link, '_blank');
      window.open(special_treatment_link, '_blank');
    }
  };

  useQuery(GET_DIAGNOSIS_FROM_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { careplanId: careplanId },
    onCompleted: (data: any): void => {
      logMissingDiagnosisCode(logger, data.careplan, 'Submission');
    },
  });

  const { data, error } = useQuery(GET_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { id: careplanId },
  });
  const { data: userData, error: userDataError } = useQuery(USER_DATA);

  useEffect(() => {
    if (error || userDataError) return setError();
  }, [error, userDataError]);

  useEffect(() => {
    setModalEnabled(data?.careplan?.simulationModification);
  }, [data]);

  const careplanStatus = data?.careplan?.careplanStatus;
  const unloadPrevented =
    ['DRAFT', 'CPOT'].includes(careplanStatus) ||
    Boolean(hasSubmitted(data?.careplan, userDetails.state.timezone) && changesFromLastEdited(data?.careplan));

  usePreventUnload(unloadPrevented);
  if (!userData) return <></>;
  const { user } = userData;

  // simulationModification == false or null do not trigger modal
  const currentSimulationChanges = data?.careplan?.simulationModification ? true : false;
  const cpotTriggered = careplanStatus === 'CPOT';
  const disableAllNav = data?.careplan?.changeType === 'CPOT' && data?.careplan?.careplanStatus === 'PRESCRIPTION';
  const resetHidden =
    !['PRESCRIPTION', 'CPOT'].includes(careplanStatus) ||
    data?.careplan?.diagnosisModification ||
    data?.careplan?.prescriptionModification ||
    data?.careplan?.simulationModification ||
    region === 'UK';

  const ukCiedDisabled =
    region === Region.UK &&
    data &&
    data.careplan.cied === 'yes' &&
    (data.careplan.ciedPatientDependancy === 'yes' || data.careplan.ciedLinacRequired === 'yes');
  const planAimSubmission: boolean =
    data &&
    data.careplan &&
    ['PRESCRIPTION', 'PLAN_AIM', 'PRESCRIPTION_DIRECTIVE'].includes(data.careplan.careplanStatus);
  const resetText = () => {
    if (careplanStatus === 'PRESCRIPTION' && !(region === 'UK')) return 'Change Prescription on Treatment';
    if (cpotTriggered) return 'Cancel';
    return '';
  };

  const footerNextDisabled =
    careplanStatus === 'PRESCRIPTION' ||
    ukCiedDisabled ||
    disableAllNav ||
    cpotTriggered ||
    (planAimSubmission && !changesFromLastEdited(data?.careplan)) ||
    user.isShowcaseUser ||
    state.loading;

  const footerAdditionRightDisabled =
    (careplanStatus === 'PRESCRIPTION' && !changesFromLastEdited(data?.careplan)) ||
    user.isShowcaseUser ||
    user.isRegistrar ||
    state.loading ||
    disableAllNav;

  const nextText = CurrentAppConfig.SubmissionPage.PlanAimSubmissionType; // e.g. Plan Aim
  const nextTextId = nextText.toLowerCase().replace(' ', '-'); // e.g. plan-aim
  const footerOptions = {
    nextText: nextText,
    nextTextId: nextTextId,
    nextHidden: false,
    // TODO: revert hideLeftButton
    hideLeftButton: careplanStatus !== 'PRESCRIPTION',
    //hideLeftButton: true,
    onReset: () => {
      if (!cpotTriggered) {
        setShowStartModal(true);
      } else {
        setShowCancelModal(true);
      }
    },
    resetHidden: resetHidden,
    resetDisabled: state.loading || disableAllNav || user.isRegistrar,
    resetText: resetText(),
    additionalLeftText: 'CPOT',
    nextDisabled: footerNextDisabled,
    backDisabled: state.loading || disableAllNav,
    nextLoading: state.submitting && state.submissionType === CurrentAppConfig.SubmissionPage.PlanAimSubmissionType,
    additionRightText: 'Prescription',
    additionRightTextId: 'prescription',
    additionRightDisabled: footerAdditionRightDisabled,
    onNext: (): void => {
      setState({ planAimModal: modalEnabled, submissionType: CurrentAppConfig.SubmissionPage.PlanAimSubmissionType });
      if (!currentSimulationChanges || !modalEnabled)
        handleSubmission(CurrentAppConfig.SubmissionPage.PlanAimSubmissionType);
      if (currentSimulationChanges) {
        setShowSimSubmissionModal(true);
      }
    },
    additionRightLoading: state.submitting && state.submissionType === 'Prescription',
  };
  if (!modalEnabled) {
    //@ts-ignore
    footerOptions['onAdditionRight'] = () => {
      setState({ submissionType: 'Prescription' });
      if (currentSimulationChanges) {
        setShowSimSubmissionModal(true);
      } else {
        handleSubmission('Prescription');
      }
    };
  }
  if (!ukCiedDisabled && modalEnabled) {
    //@ts-ignore
    footerOptions['onAdditionRight'] = () => {
      setState({ planAimModal: modalEnabled, submissionType: 'Prescription' });
      setShowSimSubmissionModal(true);
    };
  }
  const submitText = region !== Region.UK ? 'Submit' : `Submit ${state.submissionType}`;
  const isUKplanAim = state.submissionType === CurrentAppConfig.SubmissionPage.PlanAimSubmissionType && modalEnabled;
  const additionalURLparam =
    hasSubmitted(data?.careplan, userDetails.state.timezone) && !changesFromLastEdited(data?.careplan)
      ? '?submission=True'
      : '';
  const zoomLevel = isSmallDevice ? '100' : '130'; // large desktop set to 130%, otherwise 100%
  const renderModal = () => (
    <Modal open={state.planAimModal} onClose={togglePlanAim} className="modal-gc-ro">
      <ModalHeader toggle={togglePlanAim}>{`Submit ${state.submissionType} to MOSAIQ`}</ModalHeader>
      <ModalBody>
        {isUKplanAim && (
          <div className="modal-submit-ro">
            <p>
              This will submit a request for CT Planning (Simulation) with an intended prescription for Radiotherapy.
            </p>
            <WarningInfo
              warnings={[
                'Please remember to submit the final prescription at a later date (as part of the target volume definition session and/or when further clinical information is obtained).',
              ]}
              black={true}
            />
          </div>
        )}
        {!isUKplanAim && (
          <div className="modal-submit-ro">
            <ul>
              <li>Please ensure details are correct before proceeding</li>
              <li>Patient information will be updated in both systems.</li>
            </ul>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonRO
          disabled={state.submitting}
          title={state.submitting ? 'Submission in progress' : submitText}
          loading={state.submitting}
          type={ButtonType.GREEN}
          mode={ButtonMode.NORMAL}
          onClick={() => handleSubmission(state.submissionType)}
        />
      </ModalFooter>
    </Modal>
  );

  return (
    <Fragment>
      <Prompt
        when={unloadPrevented}
        message={(location) => {
          return location.pathname.includes(showSpecialPhysicsConsultPage ? '/specialPhysicsConsult' : '/voluming')
            ? true
            : 'This careplan has NOT been submitted. Are you sure you want to leave page without submitting?';
        }}
      />

      {showUKSubmissionModal && renderModal()}

      <div className="main-container-parent-wrapper">
        <div className="main-container-wrapper">
          <CareplanBanners data={data} refetchQueriesList={[{ query: GET_CAREPLAN, variables: { id: careplanId } }]} />
          <CareplanContainer className={'pdf-submission-container'}>
            <div className="page-header">
              <h1>{'Review & Submit'}</h1>
            </div>
            {state.loading && (
              <LoadingSpinner
                loadingText={'Loading Submission'}
                subtitle={'Please wait while we set things up for you'}
              />
            )}
            {state.error && <div>An error occurred while generating the PDF, please contact support</div>}
            <iframe
              title="simpage"
              key={zoomLevel} // need key on iframe to force rerender for responsive zoom level
              src={`/ro_portal/careplan/${careplanId}/prescription.pdf${additionalURLparam}&type=${careplanStatus}#zoom=${zoomLevel}`}
              width="100%"
              height="100%"
              onLoad={(): void => {
                setState({ loading: false });
              }}
              onError={(): void => {
                setState({ error: true });
              }}>
              This browser does not support PDFs.
            </iframe>
          </CareplanContainer>
          <ROPatientCarePlanPageFooter
            onBack={(): void => {
              history.push(
                `/radiation/patient/${patientId}/careplan/${careplanId}/${
                  showSpecialPhysicsConsultPage ? CAREPLAN_PAGES.SPECIAL_PHYSICS_CONSULT : CAREPLAN_PAGES.VOLUMING
                }/last`,
              );
            }}
            {...footerOptions}
          />
          <ModalSimulationSubmission
            isOpen={showSimSubmissionModal}
            submitFunction={(value) =>
              updateSimSubmission({ variables: { id: careplanId, submitSimulation: value === 'yes' } })
            }
            handleSubmission={handleSubmission}
            status={state.submissionType}
            dismissFunction={() => setShowSimSubmissionModal(false)}
          />
          <ModalCpotCancel
            isOpen={showCancelModal}
            submitFunction={() => cancelCpot()}
            dismissFunction={() => setShowCancelModal(false)}
          />
          <ModalCpotStart
            isOpen={showStartModal}
            submitFunctionOne={() => {
              triggerCpot();
              history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/simulation`);
            }}
            submitFunctionTwo={() => {
              triggerCpot();
              history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/prescription`);
            }}
            dismissFunction={() => setShowStartModal(false)}
          />
        </div>
        <ROSidePanel />
      </div>
    </Fragment>
  );
};

export default ROPatientTreatmentSubmissionPage;
