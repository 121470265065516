import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'gc-ui';
import { DoseInfo } from './DoseEditModal';
import { useMutation } from '@apollo/client';
import { DELETE_DRUG_ORDER } from '../Queries';
import { useRouteMatch } from 'react-router-dom';

interface DeleteDrugOrderModalProps {
  open: boolean;
  onClose: () => void;
  doseInfo?: DoseInfo;
  day?: number;
}

const DeleteDrugOrderModal = (props: DeleteDrugOrderModalProps): JSX.Element => {
  const [busy, setBusy] = useState(false);
  const match = useRouteMatch<{ careplanId: string }>();
  const { careplanId } = match.params;
  const { open, onClose, doseInfo, day } = props;
  const handleClose = () => {
    onClose();
  };
  const [deleteDrugOrder] = useMutation(DELETE_DRUG_ORDER, { refetchQueries: ['moCareplanData', 'latestCycles'] });
  const handleSubmit = () => {
    setBusy(true);
    deleteDrugOrder({
      variables: {
        careplanId,
        drugOrderId: doseInfo?.doseDay.dose.drugOrder.id,
        day: day ?? 0,
        cycle: doseInfo?.cycle,
      },
    })
      .then(() => {
        onClose();
      })
      .finally(() => {
        setBusy(false);
      });
  };
  return (
    <Modal open={open} width="medium" onClose={handleClose}>
      <ModalHeader>{`Delete ${doseInfo?.drugOrder.drugName}`}</ModalHeader>
      <ModalBody>Please confirm that you would like to delete this drug.</ModalBody>
      <ModalFooter>
        <Button mode={'outlined'} color={'primary'} size={'small'} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          data-cy="drug-order-delete-button-ok"
          mode={'contained'}
          color={'error'}
          size={'small'}
          onClick={handleSubmit}
          busy={busy}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteDrugOrderModal;
