// eslint-disable-next-line no-use-before-define
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { ROPatientCarePlanRoute } from '../Interface';
import { BaseAutocomplete, BaseTextField } from 'shared-components/components/FormFields';
import ROPatientCarePlanPageFooter from '../Footer';
import { Link, LinkOff, DeleteOutline } from '@mui/icons-material';
import './SiteSetupPage.scss';
import { CAREPLAN_PAGES } from '../Constants';
import { useMutation, useQuery } from '@apollo/client';
import { getOptionByValue } from '../DiagnosisPages/Utils';
import {
  DELETE_SITE,
  GET_CAREPLAN_SITE_GROUPS,
  DELETE_SITE_GROUP,
  LINK_SITE,
  UNLINK_SITE,
  CLEAN_SITE_GROUPS,
  UPDATE_SITE,
  GET_CAREPLAN,
  UPDATE_GROUP_LATERALITY,
  UPDATE_TREATMENT_SITE,
  SITE_NAME_VARIATION,
} from '../Queries';
import { LIST_TREATMENT_SITES_ORDERBY_USAGE } from '../TemplatePage/TemplatePageQueries';
import { LOAD_SITEGROUP_INFO } from '../SimulationPage/Queries';
import { LOAD_CAREPLAN_LIST_DATA } from '../ListData/ListQueries';
import { filterListdata, DEFAULT_OPTION } from '../ListData/ListData';
import { validateField } from '../ValidationEngine';
import { getSiteRepresentation, isSpecifySite, templatesWithValues } from '../Common';
import { CareplanBanners } from '../Banner/Banner';
import ROPatientCarePathSidePanel from 'op-pages/RO/Careplan/SidePanel/SidePanel';
import { LoadingSpinner } from 'shared-components/components';
import CareplanContainer from '../Container';
import { useErrorModalContext } from 'op-contexts';
import { GET_PEER_REVIEW, GET_DIAGNOSIS_FROM_CAREPLAN } from '../SidePanel/SidePanelQueries';
import { logMissingDiagnosisCode } from '../Common';
import { Logger } from 'shared-components/utils';
import Container from '@mui/material/Container';
import { useTheme, Stack, Grid, Typography } from '@mui/material';
import BaseSelect, { SelectOptionType } from 'shared-components/components/FormFields/ROSelect/BaseSelect';

const DEFAULT_LATERALITY_OPTION = 'Please Choose';
const LIST_OPTIONS = {
  LATERALITY: 'lateralitySite',
};
let pressedContinue = false;
const isFieldValid = (field: any) => {
  return pressedContinue ? validateField(field) : true;
};

const sortOptionType = (a: SelectOptionType, b: SelectOptionType) => {
  if (a.value < b.value) return -1;
  if (a.value > b.value) return 1;
  return 0;
};

const logger = new Logger('SiteSetupPage.tsx');

const ROPatientCarePathSiteSetupPage = (): JSX.Element => {
  const match = useRouteMatch<ROPatientCarePlanRoute>();
  const theme = useTheme();
  const { careplanId, id: patientId } = match.params;
  const [lateralitySiteOptions, setLateralitySiteOptions] = useState<SelectOptionType[]>(DEFAULT_OPTION);
  const [locationList, setLocationList] = useState<any[]>([]);
  const [lateralityState, setLateralityState] = useState<any>([]);
  const [hiddenSite, setHiddenSite] = useState<any>([]);
  const [hiddenGroups, setHiddenGroups] = useState<any>([]);
  const [activeLateralitySite, setActiveLateralitySite] = useState({});
  const [, updateState] = useState();
  // @ts-ignore allow empty object
  const forceUpdate = useCallback(() => updateState({}), []);
  const history = useHistory();
  const { setError } = useErrorModalContext();
  const { loading, error, data } = useQuery(GET_CAREPLAN_SITE_GROUPS, {
    fetchPolicy: 'network-only',
    variables: { careplanId },
  });

  useQuery(LOAD_CAREPLAN_LIST_DATA, {
    fetchPolicy: 'cache-first',
    variables: {
      listCategory: [LIST_OPTIONS.LATERALITY],
      patientId,
    },
    onCompleted: (data: any): void => {
      setLateralitySiteOptions(filterListdata(data, LIST_OPTIONS.LATERALITY));
    },
  });

  const { data: careplan, error: careplanError } = useQuery(GET_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { id: careplanId },
  });

  useQuery(GET_DIAGNOSIS_FROM_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { careplanId: careplanId },
    onCompleted: (data: any): void => {
      logMissingDiagnosisCode(logger, data.careplan, 'Site Setup');
    },
  });

  const { data: siteNameVariation } = useQuery(SITE_NAME_VARIATION, {
    fetchPolicy: 'network-only',
    variables: { careplanId },
    skip: loading,
  });

  const hasVariation = siteNameVariation?.siteNameVariation?.variation;

  useEffect(() => {
    if (error || careplanError) return setError();
  }, [error, careplanError]);

  const [deleteSite] = useMutation(DELETE_SITE, {
    refetchQueries: [
      { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: GET_PEER_REVIEW, variables: { careplanId } },
      { query: SITE_NAME_VARIATION, variables: { careplanId } },
    ],
  });
  const [deleteGroup] = useMutation(DELETE_SITE_GROUP, {
    refetchQueries: [
      { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: GET_PEER_REVIEW, variables: { careplanId } },
      { query: SITE_NAME_VARIATION, variables: { careplanId } },
    ],
  });

  const [linkSite] = useMutation(LINK_SITE, {
    refetchQueries: [
      { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: GET_PEER_REVIEW, variables: { careplanId } },
    ],
  });

  const [unlinkSite] = useMutation(UNLINK_SITE, {
    refetchQueries: [
      { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: GET_PEER_REVIEW, variables: { careplanId } },
    ],
  });

  const [updateSite] = useMutation(UPDATE_SITE, {
    refetchQueries: [
      { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: GET_PEER_REVIEW, variables: { careplanId } },
    ],
  });

  const [cleanSiteGroups] = useMutation(CLEAN_SITE_GROUPS, {
    refetchQueries: [
      { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
      { query: LOAD_SITEGROUP_INFO, variables: { careplanId } },
      { query: GET_PEER_REVIEW, variables: { careplanId } },
    ],
  });

  const [updateGroupLaterality] = useMutation(UPDATE_GROUP_LATERALITY, {
    refetchQueries: [
      { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
    ],
  });

  const [updateTreatmentSite] = useMutation(UPDATE_TREATMENT_SITE, {
    refetchQueries: [
      { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: SITE_NAME_VARIATION, variables: { careplanId } },
      { query: GET_PEER_REVIEW, variables: { careplanId } },
    ],
  });

  const { data: treatmentSitesData } = useQuery(LIST_TREATMENT_SITES_ORDERBY_USAGE);

  useEffect(() => {
    pressedContinue = false;
  }, []);

  useEffect(() => {
    const errorElems = document.getElementsByClassName('icon-form-error');
    if (errorElems && errorElems.length > 0) {
      errorElems[0].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
      (errorElems[0] as HTMLElement).focus({ preventScroll: true });
    } else {
      pressedContinue = false;
    }
  });

  if (loading)
    return (
      <LoadingSpinner loadingText={'Loading Site Setup'} subtitle={'Please wait while we set things up for you'} />
    );

  const { siteGroupsByCareplan } = data;
  if (!siteGroupsByCareplan) return <div />;
  // group the site groups by the careplan template id
  const templates = siteGroupsByCareplan;

  const lateralityIndex = (siteId: string) => {
    return lateralityState.findIndex((item: any) => item.id === siteId);
  };

  const treatmentSiteList =
    treatmentSitesData?.mostUsedSiteReferenceList?.map((site: any): SelectOptionType => {
      return { label: site.treatmentSite, value: site.treatmentSite };
    }) || [];

  const setLaterality = (siteId: string, option: string, siteUpdate = true): void => {
    const newLateralityState = lateralityState;
    const removeValueIndex = lateralityIndex(siteId);
    if (removeValueIndex !== -1) {
      newLateralityState.splice(removeValueIndex, 1);
    }
    newLateralityState.push({ id: siteId, value: option });
    setLateralityState(newLateralityState);
    if (siteUpdate) {
      updateSite({ variables: { siteId, laterality: option } });
    }
  };

  const lateralityValue = (siteId: string, laterality: any): string => {
    if (lateralityIndex(siteId) !== -1) {
      return lateralityState[lateralityIndex(siteId)].value;
    }
    if (laterality && laterality.length !== 0) {
      const value = JSON.parse(laterality[0].value);
      if (value) {
        return value;
      }
    }
    return DEFAULT_LATERALITY_OPTION;
  };

  const setHiddenSitesAndGroups = (siteGroup: any, siteId: any): void => {
    const newHiddenSites = hiddenSite;
    newHiddenSites.push(siteId);
    setHiddenSite(newHiddenSites);
    if (siteGroup.siteSet && siteGroup.siteSet.length === 1) {
      const newHiddenGroups = hiddenGroups;
      newHiddenGroups.push(siteGroup.id);
      setHiddenGroups(newHiddenGroups);
    }
    if (siteGroup.siteSet && siteGroup.siteSet.length > 1) {
      const currentSites = siteGroup.siteSet.map((item: any) => newHiddenSites.includes(item.id));
      if (!currentSites.includes(false)) {
        const newHiddenGroups = hiddenGroups;
        newHiddenGroups.push(siteGroup.id);
        setHiddenGroups(newHiddenGroups);
      }
    }
  };
  const getLocationOption = (site: any): string => {
    const location = site.sitevaluesSet.filter((values: any): any => values.field.name === 'Location');
    return location[0] ? JSON.parse(location[0].value) : '';
  };

  const setlocationFields = (id: string, newValue: string): void => {
    const Idx = locationList.findIndex((obj: any) => obj.id === id);
    const newValues = [...locationList];
    Idx >= 0 ? (newValues[Idx].value = newValue) : newValues.push({ id, value: newValue });
    setLocationList(newValues);
  };

  const siteLocationLookup = (idToMatch: string, defaultValue: any) => {
    const matchedElement = locationList.find((x: any) => x.id === idToMatch);
    return matchedElement ? matchedElement.value : defaultValue;
  };
  const checkLocationOption = (site: any): boolean => {
    const db_location = getLocationOption(site);
    const state_location = siteLocationLookup(site.id, getLocationOption(site));
    if (db_location !== state_location) {
      return false;
    }
    return Boolean(db_location);
  };
  const deleteSiteWrapper = (siteGroup: any, siteId: number): void => {
    if (siteGroup.siteSet.length in [0, 1]) {
      deleteGroup({ variables: { id: siteGroup.id } });
    } else {
      const newHiddenSites = hiddenSite;
      newHiddenSites.push(siteId);
      setHiddenSite(newHiddenSites);
      deleteSite({ variables: { id: siteId } });
    }
  };

  const isSpecifyComplete = !templates
    .map((template: any) =>
      template.siteSet.map((siteSet: any) =>
        isSpecifySite(siteSet.treatmentSite.treatmentSite) ? checkLocationOption(siteSet) : true,
      ),
    )
    .reduce((acc: any, val: any) => acc.concat(val), [])
    .includes(false);

  const isLateralityComplete = !templates
    .map((template: any) =>
      template.siteSet.map((siteSet: any) => {
        const laterality = siteSet.sitevaluesSet.filter((values: any) => values.field.name === 'Laterality');
        if (!laterality) return false;
        if (laterality.length) return Boolean(laterality[0].value);
        return false;
      }),
    )
    .reduce((acc: any, val: any) => acc.concat(val), [])
    .includes(false);

  const isSetupValid = () => {
    return pressedContinue && isSpecifyComplete && isLateralityComplete;
  };

  const unlinkSiteWrapper = (careplanId: any, siteGroupId: any, siteId: number): void => {
    const cpId: number = parseInt(careplanId);
    const sgId: number = parseInt(siteGroupId);
    unlinkSite({ variables: { siteId, careplanId: cpId, originalSiteGroupId: sgId } });
  };

  const linkSiteWrapper = (siteGroupLinkId: any, siteId: number): void => {
    linkSite({ variables: { siteGroupId: siteGroupLinkId, siteId } });
  };

  const showGroup = (siteGroups: any): boolean => {
    return !hiddenGroups.includes(siteGroups.id);
  };

  const getTemplateGroupRepresentation = (template: any) => {
    const values = template.siteSet
      .map((site: any, count: number) => {
        if (count < 3) {
          return getSiteRepresentation(site);
        }
        return undefined;
      })
      .filter(Boolean);

    const siteString = template.siteSet.length - values.length === 1 ? 'site' : 'sites';

    return template.siteSet.length - values.length !== 0
      ? values.join(', ') + ` + ${template.siteSet.length - values.length} ${siteString}`
      : values.join(', ');
  };

  const moveGroupPresenceCheck = (tIndex: number): boolean => {
    const tValues = templatesWithValues(templates);
    for (let i = 0; i < tValues.length; i++) {
      if (tIndex !== i) {
        return true;
      }
    }
    return false;
  };

  return (
    <Fragment>
      <div className="main-container-parent-wrapper">
        <div className="main-container-wrapper">
          {loading && <div className="main-container">Loading </div>}
          <CareplanBanners
            data={careplan}
            variation={hasVariation}
            refetchQueriesList={[
              { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
              { query: GET_CAREPLAN, variables: { id: careplanId } },
              { query: SITE_NAME_VARIATION, variables: { careplanId } },
            ]}
          />
          <CareplanContainer className={'carepath-site-setup-page'}>
            <div className="page-header">
              <h1 data-testid="site-setup-header">{'Site Setup'}</h1>
              <h2>
                Treatment groups share the same simulation and voluming details. Prescription details are associated
                with individual treatment sites.
              </h2>
            </div>
            <Container className="site-setup-page-sites-container">
              <Grid container sx={{ background: theme.palette.secondary.light }}>
                <Grid sx={{ padding: '12px' }} xs={3} item key="treatmentSite">
                  <Typography sx={{ fontSize: '16px' }} variant="body1">
                    Treatment Site*
                  </Typography>
                </Grid>
                <Grid sx={{ padding: '12px' }} xs={3} item key="laterality">
                  <Typography sx={{ fontSize: '16px' }} variant="body1">
                    Laterality*
                  </Typography>
                </Grid>
                <Grid sx={{ padding: '12px' }} xs={6} item key="action">
                  <Typography sx={{ fontSize: '16px' }} variant="body1">
                    Action
                  </Typography>
                </Grid>
              </Grid>
              {templatesWithValues(templates).map((template: any, tIndex: number): JSX.Element => {
                return (
                  <Fragment key={`${tIndex}`}>
                    {showGroup(template) && (
                      <Grid container className="site-setup-page-template-header" key={`row-${tIndex}`}>
                        <Grid item md={12} key={`col-${tIndex}`}>
                          <div>
                            <span style={{ paddingLeft: '8px' }} className={'site-setup-template-group-header'}>
                              {`Treatment Group ${tIndex + 1}`}:
                            </span>
                            <span>{getTemplateGroupRepresentation(template)}</span>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                    {template.siteSet.map((site: any, sIndex: number): JSX.Element => {
                      const laterality = site.sitevaluesSet.filter(
                        (sitevalue: any): any => sitevalue.field.name === 'Laterality',
                      );
                      const isLateralitySet = lateralityValue(site.id, laterality) !== DEFAULT_LATERALITY_OPTION;
                      const validateLaterality = !isFieldValid(isLateralitySet);
                      const locationInvalid = !isFieldValid(siteLocationLookup(site.id, getLocationOption(site)));
                      if (hiddenSite.indexOf(site.id) !== -1) {
                        return <Fragment key={`frag-${sIndex}`} />;
                      }
                      return (
                        <Grid
                          container
                          key={`site-${sIndex}`}
                          alignItems="center"
                          sx={{ border: `1px solid ${theme.palette.secondary.main}`, borderTop: 'none' }}>
                          <Grid sx={{ padding: '12px' }} item xs={3} key={`site-col-loca-${sIndex}`}>
                            <BaseAutocomplete
                              id="treatmentSites"
                              options={[...treatmentSiteList].sort(sortOptionType)}
                              value={getOptionByValue(treatmentSiteList, site.treatmentSite.treatmentSite)}
                              onChange={(option: SelectOptionType | string): void => {
                                const value = typeof option === 'string' ? option : option?.value;
                                updateTreatmentSite({
                                  variables: {
                                    siteId: site.id,
                                    treatmentSite: value || '',
                                  },
                                });
                              }}
                              disableClearable
                              inputProps={{
                                error: !isFieldValid(site.treatmentSite.treatmentSite),
                                helperText: !isFieldValid(site.treatmentSite.treatmentSite)
                                  ? 'Please select a treatment site'
                                  : '',
                              }}
                            />
                            {isSpecifySite(site.treatmentSite.treatmentSite) && (
                              <div className={`ro-site-setup-location ${locationInvalid ? 'invalid' : 'valid'}`}>
                                <BaseTextField
                                  id="location"
                                  fullWidth
                                  placeholder={'Please specify location'}
                                  value={siteLocationLookup(site.id, getLocationOption(site))}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                    setlocationFields(site.id, event.target.value);
                                  }}
                                  onBlur={() => {
                                    updateSite({
                                      variables: {
                                        siteId: site.id,
                                        location: siteLocationLookup(site.id, site.location),
                                      },
                                    });
                                  }}
                                  error={locationInvalid}
                                  required
                                  sx={{
                                    paddingBottom: '20px', // Jank to make row wide enough
                                  }}
                                />
                              </div>
                            )}
                          </Grid>
                          <Grid sx={{ padding: '12px' }} item xs={3}>
                            <div
                              key={`site-col-lat-${sIndex}`}
                              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <BaseSelect
                                data-testid={`laterality-${tIndex}-${sIndex}`}
                                error={validateLaterality}
                                helperText={'Please choose a laterality'}
                                id={`laterality-${tIndex}-${sIndex}`}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  if (lateralityValue(site.id, laterality) !== event.target.value) {
                                    setActiveLateralitySite((lateralitySite) => {
                                      return { ...lateralitySite, [template.id]: site.id };
                                    });
                                  }
                                  setLaterality(site.id, event.target.value, true);
                                }}
                                value={lateralityValue(site.id, laterality)}
                                placeholder="Please Choose"
                                variant="standard"
                                sx={{
                                  fontSize: '14px',
                                  color: validateLaterality ? theme.palette.error.main : 'initial',
                                  borderColor: theme.palette.primary.main,
                                  fontWeight: validateLaterality ? 'bold' : 'initial',
                                  width: '180px',
                                  paddingLeft: '0',
                                }}
                                options={lateralitySiteOptions}
                                inputProps={{ fontSize: '14px' }}></BaseSelect>
                            </div>
                            {template.siteSet.length > 1 &&
                              activeLateralitySite.hasOwnProperty(template.id) &&
                              // @ts-ignore
                              activeLateralitySite[template.id] === site.id && (
                                <Typography
                                  variant="body1"
                                  sx={{
                                    cursor: 'pointer',
                                    marginTop: '4px',
                                    ':hover': {
                                      textDecoration: 'underline',
                                    },
                                  }}
                                  onClick={() => {
                                    const latValue = lateralityValue(site.id, laterality);
                                    updateGroupLaterality({
                                      variables: {
                                        siteGroupId: template.id,
                                        siteId: site.id,
                                        laterality: latValue,
                                      },
                                    });
                                    template.siteSet.forEach((site: any) => {
                                      setLaterality(site.id, latValue, false);
                                    });
                                    setActiveLateralitySite({});
                                  }}>
                                  Apply to all
                                </Typography>
                              )}
                          </Grid>

                          <Grid sx={{ padding: '12px' }} item xs={6} key={`site-col-setup-${sIndex}`}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                              {moveGroupPresenceCheck(tIndex) && (
                                <Stack direction="column" alignItems="center">
                                  {templatesWithValues(templates).map(
                                    (siteGroupLink: any, sGLIndex: number): JSX.Element => {
                                      return tIndex !== sGLIndex ? (
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          sx={{ cursor: 'pointer' }}
                                          gap={1}
                                          key={sGLIndex + 'move_text'}
                                          onClick={(): void => {
                                            linkSiteWrapper(siteGroupLink.id, site.id);
                                          }}>
                                          <Link color="primary" sx={{ ':hover': { fillOpacity: 0.75 } }} />
                                          <Typography variant="h6">Move to</Typography>
                                          <Typography variant="body1">{`Treatment group ${sGLIndex + 1}`}</Typography>
                                        </Stack>
                                      ) : (
                                        <Fragment key={sGLIndex + 'move_text'} />
                                      );
                                    },
                                  )}
                                </Stack>
                              )}

                              {template.siteSet.length > 1 && (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                  style={{ cursor: 'pointer' }}
                                  onClick={(): void => {
                                    unlinkSiteWrapper(careplanId, template.id, site.id);
                                  }}>
                                  <LinkOff color="primary" sx={{ ':hover': { fillOpacity: 0.75 } }} />
                                  <Typography variant="h6">Detach from group</Typography>
                                </Stack>
                              )}
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ cursor: 'pointer' }}
                                gap={1}
                                onClick={(): void => {
                                  setHiddenSitesAndGroups(template, site.id);
                                  deleteSiteWrapper(template, site.id);
                                }}>
                                <DeleteOutline color="error" sx={{ ':hover': { fillOpacity: 0.75 } }} />
                                <Typography variant="h6">Delete</Typography>
                              </Stack>
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Fragment>
                );
              })}
            </Container>
          </CareplanContainer>
          <ROPatientCarePlanPageFooter
            onBack={(): void => {
              cleanSiteGroups({ variables: { careplanId } });
              history.push(
                `/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.TEMPLATE_SELECTION}`,
              );
            }}
            onNext={(): void => {
              pressedContinue = true;
              forceUpdate();
              if (!isSetupValid()) return;
              cleanSiteGroups({ variables: { careplanId } });
              history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SIMULATION}`);
            }}
            nextDisabled={templates && templates.length === 0}
            // nextDisabled={this.isEmptyLaterality()}
          />
        </div>
        <ROPatientCarePathSidePanel />
      </div>
    </Fragment>
  );
};

export default ROPatientCarePathSiteSetupPage;
