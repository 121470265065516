import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import moment from 'moment';
import { BaseSelectCheckbox } from '../FormFields';
import { legacyOnChange } from '../FormFields/ROSelectCheckbox/BaseSelectCheckbox';
import { Stack, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const ResponsiveContainer = styled('div')`
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
`;

const TableContainer = styled('table')`
  display: table;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  float: left;
  border-collapse: separate;
  margin: 10px 0px;
`;

const Header = styled('thead')`
  width: auto;
  font-weight: 500;
  margin: 25px 5px;

  th {
    top: 0;
    background: ${(props) => props.theme.palette.secondary.light};
    font-weight: 500;
    position: sticky;
  }
`;

const HeaderRow = styled('tr')`
  width: auto;
`;

const HeaderCol = styled('th')`
  width: auto;
  vertical-align: middle;
  border-collapse: collapse;
  padding: 8px;
  text-align: left;
`;

const DataBody = styled('tbody')`
  width: auto;
`;

const DataCol = styled('td')`
  width: auto;
  padding: 8px;
  height: 44px;
`;

interface DataRowProps {
  highlight: boolean;
}

const DataRow = styled('tr')<DataRowProps>`
  background: ${(props: DataRowProps) =>
    props.highlight ? (props: any) => props.theme.palette.primary.light : 'none'};
  & td:first-child {
    text-align: left;
    border-radius: ${(props: DataRowProps) => (props.highlight ? '5px  0  0 5px' : 'none')};
    border-left: ${(props: DataRowProps) =>
      props.highlight ? `2px solid ${(props: any) => props.theme.palette.primary.main}` : 'none'};
    padding: 8px;
  }
  & td:last-child {
    border-radius: ${(props: DataRowProps) => (props.highlight ? '0px  5px  5px  0px' : 'none')};
    border-right: ${(props: DataRowProps) =>
      props.highlight ? `2px solid ${(props: any) => props.theme.palette.primary.main}` : 'none'};
    padding: 8px;
    text-align: right;
  }
  & td {
    border-top: ${(props: DataRowProps) =>
      props.highlight ? `2px solid ${(props: any) => props.theme.palette.primary.main}` : 'none'};
    border-bottom: ${(props: DataRowProps) =>
      props.highlight ? `2px solid ${(props: any) => props.theme.palette.primary.main}` : 'none'};
    padding: 8px;
    text-align: left;
  }
  &:hover {
    background: ${(props) => props.theme.palette.primary.light};
  }
  box-shadow: 0px 1px 0px ${(props) => props.theme.palette.secondary.main};
  width: auto;
`;

interface OrderLabelContainerProps {
  isLastColumn: boolean;
}

interface StyledSortedProps {
  order: string;
}

const OrderLabelContainer = styled('div')<OrderLabelContainerProps>`
  display: flex;
  justify-content: ${(props: OrderLabelContainerProps) => (props.isLastColumn ? 'flex-end' : 'flex-start')};
  align-items: center;
`;

interface DataProps {
  header: string;
  key: string;
  sort: boolean;
  type: string;
  alignment?: string;
}

interface Props {
  header?: string;
  data: any;
  dataProps: DataProps[];
  isScrollable?: boolean;
  onRowClick?: (rowId: string) => void;
  selectedRow: number;
  updateDocumentTypeOptions?: any;
  docTypeOptions?: any;
}

interface SortStateType {
  key: string;
  order: string;
  type: string;
}

interface OrderLabelProps {
  sortState: SortStateType;
  header: DataProps;
  label: string;
  onSortCallback: (order: string) => void;
  isLastColumn: boolean;
  updateDocumentTypeOptions?: any;
  docTypeOptions?: any;
}

const ORDERING = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const DATATYPE = {
  DATE: 'DATE',
  STRING: 'STRING',
  NUMBER: 'NUMBER',
};

const OrderLabel = (props: OrderLabelProps) => {
  const { ASC, DESC } = ORDERING;
  const isAscending = props.sortState.order === ORDERING.ASC;
  const activeSort = props.sortState['key'] === props.header.key;
  return (
    <OrderLabelContainer isLastColumn={props.isLastColumn}>
      <Stack
        onClick={() => {
          props.onSortCallback(isAscending ? DESC : ASC);
        }}
        sx={{ cursor: 'pointer' }}>
        <KeyboardArrowUp color={activeSort && isAscending ? 'primary' : 'secondary'} sx={{ marginBottom: '-10px' }} />
        <KeyboardArrowDown color={activeSort && !isAscending ? 'primary' : 'secondary'} />
      </Stack>
      <Typography>{props.label}</Typography>
    </OrderLabelContainer>
  );
};

const DropdownOrderLabel = (props: OrderLabelProps) => {
  const checkedOptionCount = props.docTypeOptions.filter((item: any) => item.checked).length;
  const allSelected = checkedOptionCount === props.docTypeOptions.length;
  const placeholderText = allSelected ? 'Type (All)' : 'Type';
  const badgeNumber = String(checkedOptionCount);

  const options = props.docTypeOptions.map((docType: any) => {
    return {
      value: docType.name,
      id: docType.id,
      checked: docType.checked || false,
    };
  });

  return (
    <OrderLabelContainer isLastColumn={props.isLastColumn}>
      <BaseSelectCheckbox
        id="documents"
        options={options}
        placeholder={placeholderText}
        showSelectAll
        badgeContent={allSelected ? undefined : badgeNumber}
        onChange={(event) => legacyOnChange(event, options, props.updateDocumentTypeOptions)}
        sx={{ '.MuiOutlinedInput-notchedOutline': { border: 'none', outline: 'none' } }}
      />
    </OrderLabelContainer>
  );
};

const SimpleTable = (props: Props): JSX.Element => {
  const [sortState, setSortState] = useState({ key: '', order: '', type: '' });
  const [highlightRow, setHighlightRow] = useState(props.selectedRow);
  let renderData = props.data;
  const sortKeys = props.dataProps.filter((item) => item['sort']).map((item) => item['key']);
  const dataKeys = props.dataProps.map((item) => item['key']);

  if (sortKeys && sortState.key) {
    renderData = [...props.data].sort((a: any, b: any): any => {
      if (sortState.type === DATATYPE.DATE) {
        const atime = moment(a[sortState.key], 'L').toDate().getTime();
        const btime = moment(b[sortState.key], 'L').toDate().getTime();
        return sortState.order === ORDERING.ASC ? atime - btime : btime - atime;
      } else {
        const order = sortState.order === ORDERING.ASC ? 1 : -1;
        if (a[sortState.key] > b[sortState.key]) return order;
        if (a[sortState.key] < b[sortState.key]) return -1 * order;
      }
      return 0;
    });
  }

  useEffect(() => {
    setHighlightRow(props.selectedRow);
  }, [props.selectedRow]);

  const attributes =
    props.header === 'Documents' ? props.dataProps.filter((item) => item.key !== 'description') : props.dataProps;

  return (
    <ResponsiveContainer style={{ minHeight: '100%' }}>
      <TableContainer data-test-id={`${props.header}-table`}>
        <Header>
          <HeaderRow>
            {props.header === 'Documents' ? (
              <HeaderCol key="head-99">
                <DropdownOrderLabel
                  sortState={sortState}
                  isLastColumn={false}
                  header={{ header: 'Type', key: 'description', sort: false, type: DATATYPE.STRING }}
                  label={'Key'}
                  onSortCallback={(ordering: string): void => {
                    setSortState({ key: 'description', order: ordering, type: DATATYPE.STRING });
                  }}
                  updateDocumentTypeOptions={props.updateDocumentTypeOptions}
                  docTypeOptions={props.docTypeOptions}
                />
              </HeaderCol>
            ) : (
              <></>
            )}
            {attributes.map(
              (item: DataProps, index: number): JSX.Element => (
                <HeaderCol key={`head-${index}`}>
                  {sortKeys && sortKeys.includes(item['key']) ? (
                    <OrderLabel
                      sortState={sortState}
                      isLastColumn={index === attributes.length - 1}
                      header={item}
                      label={item['header']}
                      onSortCallback={(ordering: string): void => {
                        setSortState({ key: item['key'], order: ordering, type: item['type'] });
                      }}
                    />
                  ) : (
                    item['header']
                  )}
                </HeaderCol>
              ),
            )}
          </HeaderRow>
        </Header>
        {renderData ? (
          <DataBody className="document-list-table">
            {renderData.map((row: any, index: number) => (
              <DataRow
                data-test-id={`docs-table-row-${row['description']}`}
                key={`row-${index}`}
                highlight={highlightRow === row.id}
                onClick={(): void => {
                  if (highlightRow !== row.id) {
                    setHighlightRow(row.id);
                    props.onRowClick && props.onRowClick(row.id);
                  }
                }}>
                {dataKeys.map((colname, index) => (
                  <DataCol key={`col-${index}`}>{row[colname]}</DataCol>
                ))}
              </DataRow>
            ))}
          </DataBody>
        ) : (
          <></>
        )}
      </TableContainer>
    </ResponsiveContainer>
  );
};

export default SimpleTable;
