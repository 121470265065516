/* eslint-disable no-console */
import { styled } from '@mui/system';
import React, { ReactElement } from 'react';
import { wktToGeoJSON } from '@terraformer/wkt';
import { HighlightFeature, ClinexData } from './types';
import { multiPolygon, polygon } from '@turf/helpers';
import { Tooltip } from '@mui/material';
import { ArrowRight as ArrowRightIcon, PlagiarismOutlined } from '@mui/icons-material';

const ClinexDocumentIcon = styled(PlagiarismOutlined)`
  position: relative;
  top: 10px;
  left: 6px;
  color: ${(props) => props.theme.palette.primary.main};
  &:hover {
    cursor: pointer;
  }
`;

const ClinexActiveIcon = styled(ArrowRightIcon)`
  position: relative;
  left: 2px;
  color: ${(props) => props.theme.palette.primary.main};
  top: 6px;
  font-size: 32px;
`;

const PleaseReviewText = styled('div')`
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-top: 8px;
  color: ${(props) => props.theme.palette.text.primary};
`;

export const getHighlightFeatures = (highlightWKTs: string[]): HighlightFeature[] => {
  const features = [];
  for (const wkt of highlightWKTs) {
    if (wkt !== 'GEOMETRYCOLLECTION EMPTY') {
      const primitive = wktToGeoJSON(wkt);
      if (primitive.type === 'Polygon') {
        features.push(polygon(primitive.coordinates));
      } else if (primitive.type === 'MultiPolygon') {
        features.push(multiPolygon(primitive.coordinates));
      }
    }
  }
  return features;
};

export const stagingClinexMappings = {
  ajcc_disease: {
    field: 'disease',
    value: 'diseaseId',
  },
  histology_code: {
    field: 'morphology',
    value: 'displayName',
  },
  t_stage_pathological: {
    field: 'stageTCodePathological',
    value: 'subCode',
  },
  m_stage_pathological: {
    field: 'stageMCodePathological',
    value: 'subCode',
  },
  n_stage_pathological: {
    field: 'stageNCodePathological',
    value: 'subCode',
  },
  t_stage_clinical: {
    field: 'stageTCodeClinical',
    value: 'subCode',
  },
  m_stage_clinical: {
    field: 'stageMCodeClinical',
    value: 'subCode',
  },
  n_stage_clinical: {
    field: 'stageNCodeClinical',
    value: 'subCode',
  },
  laterality: {
    field: 'laterality',
  },
  diagnosis_date: {
    field: 'diagnosisDate',
  },
};

export const breastIntakeClinexMappings = {
  grade: {
    field: 'grade',
  },
  er: {
    field: 'er',
  },
  pgr: {
    field: 'pgr',
  },
  her2: {
    field: 'her2',
  },
  mastectomy: {
    field: 'mastectomy',
  },
  reconstruction: {
    field: 'reconstruction',
  },
  reconstructionTimeline: {
    field: 'reconstructionTimeline',
  },
  surgery: {
    field: 'surgery',
  },
  lymphovascular_invasion: {
    field: 'lymphovascularInvasion',
  },
  lymphovascular_invasion_extent: {
    field: 'lymphovascularInvasionExtent',
  },
};

export const lungIntakeClinexMappings = {
  egfr: {
    field: 'egfr',
  },
  alk: {
    field: 'alk',
  },
  pdl1: {
    field: 'pdl1',
  },
};

export const urogenitalClinexMappings = {
  gleason_score_highest: {
    field: 'gleason',
    firstField: 'gleasonOne',
    secondField: 'gleasonTwo',
  },
  total_psa: {
    field: 'psa',
  },
  total_psa_date: {
    field: 'psaDate',
  },
  prostatectomy: {
    field: 'prostatectomy',
  },
};

const clinexFieldsMapping = {
  ...stagingClinexMappings,
  ...breastIntakeClinexMappings,
  ...lungIntakeClinexMappings,
  ...urogenitalClinexMappings,
};

export const ClinexTooltip = ({
  clinexData,
  clinexField,
  formValues,
  children,
}: {
  clinexData: ClinexData;
  clinexField: string;
  formValues: any;
  children: ReactElement;
}): JSX.Element => {
  const [show, setShow] = React.useState(false);

  if (
    clinexData &&
    isFieldValueClinex(clinexData, clinexField, formValues) &&
    clinexData[clinexField]?.['description']
  ) {
    return (
      <Tooltip
        arrow
        open={show}
        title={clinexData[clinexField]['description']}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        onClick={() => setShow(false)}>
        {children}
      </Tooltip>
    );
  }
  return children;
};

export const getClinexTooltipText = (clinexData: ClinexData, clinexField: string, formValues: any): string => {
  if (
    clinexData &&
    isFieldValueClinex(clinexData, clinexField, formValues) &&
    clinexData[clinexField]?.['description']
  ) {
    return clinexData[clinexField]['description'];
  }
  return '';
};

export const isFieldValueClinex = (clinexData: ClinexData, clinexField: string, formValues: any): boolean => {
  const fieldValuesObject = clinexFieldsMapping[clinexField as keyof typeof clinexFieldsMapping];

  let formValue;

  const { field } = fieldValuesObject;

  if (field === 'gleason') {
    formValue = [formValues['gleasonOne'], formValues['gleasonTwo']];
  } else if (fieldValuesObject.hasOwnProperty('value')) {
    //@ts-ignore
    const { value } = fieldValuesObject;
    formValue = formValues[field]?.[value] || formValues[field]?.value;
  } else {
    formValue = typeof formValues[field] === 'string' ? formValues[field] : formValues[field]?.value;
  }
  const clinexValue = clinexData?.[clinexField]?.['value'];

  return formValue && formValue.toString().includes(clinexValue?.toString());
};

export const navigateToClinexHighlight = (field: string, context: any): void => {
  const { setPageIndex, setIndexLock, pages, setHighlights, setFieldSelected, clinexData } = context;
  const documentId = Object.keys(clinexData[field]['highlight_geoms']);
  if (documentId.length > 0) {
    const allPages = pages.filter((item: any) => documentId.includes(item.document_id));
    setFieldSelected(field);

    const geomsTest: any = {};
    for (const key of Object.keys(clinexData[field]['highlight_geoms'])) {
      const value = clinexData[field]['highlight_geoms'][key];
      const itemArr: any = [];
      value.forEach((document: any) => {
        itemArr.push([document]);
      });
      geomsTest[key] = itemArr;
    }

    setHighlights(geomsTest);

    allPages.forEach((page: any) => {
      if (page.document_id in geomsTest) {
        const features = getHighlightFeatures(geomsTest[page.document_id][page.page_index]);
        if (features.length > 0) {
          setPageIndex(pages.indexOf(page));
          setIndexLock(true);
          setFieldSelected(field);
        }
      }
    });
  }
};

export const isFieldLowCertainty = (clinexData: ClinexData, clinexField: string, formValues: any): boolean => {
  if (clinexData?.[clinexField]?.certainty !== 'low') {
    return false;
  }
  return isFieldValueClinex(clinexData, clinexField, formValues) && clinexData[clinexField].certainty === 'low';
};

export const selectFirstHighlight = (clinexMappings: any, context: any) => {
  const { clinexData } = context;
  const orderedFieldKeys = Object.keys(clinexMappings);
  let firstClinexSelected = false;

  return (
    clinexData &&
    orderedFieldKeys.forEach((field: string) => {
      const fieldHasHighlight = clinexData?.[field]?.['highlight_geoms'];
      if (fieldHasHighlight && !firstClinexSelected) {
        navigateToClinexHighlight(field, context);
        firstClinexSelected = true;
      }
    })
  );
};

export const LOW_CERTAINTY = 'Lower certainty';

export const PLEASE_REVIEW_CLINEX = (
  <PleaseReviewText data-testid="subtitle">
    Please review prepopulated values found by Clinex. You can view the source document by clicking on{' '}
    <PlagiarismOutlined color="primary" />
  </PleaseReviewText>
);
