import React from 'react';
import { styled } from '@mui/system';
import { Stack } from '@mui/material';
import { UploadOutlined as UploadOutlinedIcon } from '@mui/icons-material';

interface DocumentUploadButtonProps {
  onClick: () => void;
  $maxWidth?: string;
}

interface StyledProps {
  $maxWidth?: string;
}

const UploadDocumentBlock = styled(Stack)<StyledProps>`
  display: flex;
  flex-direction: row;
  height: 3em;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: ${({ $maxWidth }: StyledProps): string => $maxWidth || 'none'};
  max-width: ${({ $maxWidth }: StyledProps): string => $maxWidth || 'none'};
  margin-right: 5px;
  border-radius: 18px;
  background-color: white;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`;

const UploadIcon = styled(UploadOutlinedIcon)`
  width: 24px;
  height: 24px;
  margin-right: -5px;
`;

const DocumentUploadButton = (props: DocumentUploadButtonProps): JSX.Element => {
  return (
    <UploadDocumentBlock {...props} id="upload-document-block" data-testid="document-upload-button">
      <UploadIcon color="primary" />
      <div style={{ marginRight: '5px', fontWeight: 'bold' }}>Upload document</div>
    </UploadDocumentBlock>
  );
};

export default DocumentUploadButton;
