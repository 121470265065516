import { gql } from '@apollo/client';

export const GET_CAREPLANS_QUERY = gql`
  query careplansQuery(
    $page: Int
    $search: String
    $status: [String]
    $priority: String
    $ordering: String
    $careplanChanged: [String]
    $onlyForRequestingPractitioner: Boolean
    $submittedBy: [String]
    $onOrBeforeDate: String
    $onOrAfterDate: String
  ) {
    careplans(
      page: $page
      search: $search
      status: $status
      priority: $priority
      ordering: $ordering
      careplanChanged: $careplanChanged
      onlyForRequestingPractitioner: $onlyForRequestingPractitioner
      submittedBy: $submittedBy
      onOrBeforeDate: $onOrBeforeDate
      onOrAfterDate: $onOrAfterDate
    ) {
      id
      careplanStatus
      diagnosisCode
      diagnosis {
        id
        diagnosisCode {
          id
          diagnosisCode
          diagnosisDescription
        }
      }
      priority
      updatedAt
      submittedAt
      createdBy
      careplanName
      treatingDepartment
      latestPageUrl
      simulationModification
      prescriptionModification
      diagnosisModification
      documentSet {
        id
        docUrl
      }
      patient {
        id
        fullName
        ida
        photoUrl
      }
      sitegroupSet {
        orderRanking
        id
        siteSet {
          id
          orderRanking
          treatmentSite {
            id
            treatmentSite
          }
          sitevaluesSet {
            id
            field {
              id
              name
            }
            value
          }
        }
      }
    }
    careplansCount(
      search: $search
      status: $status
      priority: $priority
      ordering: $ordering
      onlyForRequestingPractitioner: $onlyForRequestingPractitioner
      submittedBy: $submittedBy
      onOrBeforeDate: $onOrBeforeDate
      onOrAfterDate: $onOrAfterDate
    )
  }
`;

export const GET_CAREPATH_FILTERS_QUERY = gql`
  query carepathFilters {
    carepathFilters {
      practitioners {
        name
        id
      }
    }
  }
`;
