import moment from 'moment';
import { UserContext } from 'op-contexts/index';
import { NewSupportingDrugOrder } from 'op-pages/MO/interfaces';
import { isNewSupportingDrugApplicable } from 'op-pages/MO/utils';
import React, { useContext } from 'react';
import { SuccessWhiteBorderIcon, InfoCircleBlueWhiteBorderIcon, Warning } from 'shared-components/images';
import { styled } from '@mui/system';
import { CareplanCycle } from '../interfaces';
import useCyclePdf from './useCyclePdf';
import { useTheme } from '@mui/material';

export enum BannerType {
  WARNING = 0,
  APPROVED = 1,
  UNAPPROVED = 3,
  EMPTY = 2,
}
interface BannerProps {
  children?: React.ReactNode;
  type: BannerType;
  color?: string;
  $borderColor?: string;
}
const BannerLayout = styled('div')<BannerProps>`
  display: flex;
  padding: 0.8rem;
  justify-content: start;
  column-gap: 0.7rem;
  align-items: center;
  background-color: ${(props) => props.color};
  border-left: 3px solid;
  border-color: ${(props: BannerProps) => props.$borderColor};
`;

const Banner = (props: BannerProps): JSX.Element => {
  const icon = (type: BannerType) => {
    switch (type) {
      case BannerType.APPROVED:
        return <SuccessWhiteBorderIcon width="20px" height="20px" />;
      case BannerType.WARNING:
        return <InfoCircleBlueWhiteBorderIcon width="20px" height="20px" />;
      case BannerType.UNAPPROVED:
        return <Warning width="20px" height="20px" />;
      default:
        return <></>;
    }
  };

  return (
    <BannerLayout color={props.color} {...props}>
      {icon(props.type)}
      {props.children}
    </BannerLayout>
  );
};

interface CycleNotPrintedBannerProps {
  approvedDate?: string;
  savePdfCallback: () => void;
  printPdfCallback: () => void;
  showSavePdfLink: boolean;
  showPrintPdfLink: boolean;
}
const PDFLinksLayout = styled('div')`
  display: flex;
  margin-left: auto;
  column-gap: 0.7rem;
  padding-right: 30px;
`;

const PDFLink = styled('div')`
  text-decoration: underline;
  cursor: pointer;
`;
export const CycleNotPrintedBanner = ({
  approvedDate,
  savePdfCallback,
  printPdfCallback,
  showSavePdfLink,
  showPrintPdfLink,
}: CycleNotPrintedBannerProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Banner
      type={BannerType.WARNING}
      color={theme.palette.info.light}
      $borderColor={theme.palette.info.main}
      data-cy={'banner-cycle-not-printed'}>
      <div>This cycle was approved on {approvedDate}. Please save and print the PDF.</div>
      <PDFLinksLayout>
        {showSavePdfLink && (
          <PDFLink data-cy="banner-cycle-save-pdf" onClick={savePdfCallback}>
            Save PDF
          </PDFLink>
        )}
        {showPrintPdfLink && (
          <PDFLink data-cy="banner-cycle-print-pdf" onClick={printPdfCallback}>
            Print PDF
          </PDFLink>
        )}
      </PDFLinksLayout>
    </Banner>
  );
};

export const CycleApprovedBanner = ({ approvedDate }: { approvedDate: string }): JSX.Element => {
  const theme = useTheme();
  return (
    <Banner
      type={BannerType.APPROVED}
      color={theme.palette.secondary.light}
      $borderColor={theme.palette.primary.main}
      data-cy={'banner-cycle-approved'}>
      This cycle was approved on {approvedDate}.
    </Banner>
  );
};

export const CycleUnApprovedBanner = () => {
  const theme = useTheme();
  return (
    <Banner
      type={BannerType.UNAPPROVED}
      color={theme.palette.warning.light}
      $borderColor={theme.palette.warning.main}
      data-cy={'banner-cycle-unapproved'}>
      This cycle has been amended and needs to be approved.
    </Banner>
  );
};

const EmptyBanner = styled(Banner)`
  min-height: 46px;
  border-left: none;
`;
interface CycleBannerProps {
  cycleInfo: CareplanCycle;
  supportingDrugOrder: NewSupportingDrugOrder | null;
}
export const CycleBanner = ({ cycleInfo, supportingDrugOrder }: CycleBannerProps): JSX.Element => {
  const { state: userDetails } = useContext(UserContext);
  const [savePdfCallback, printPdfCallback] = useCyclePdf();
  const isSupportingDrug = isNewSupportingDrugApplicable(cycleInfo?.cycleId, supportingDrugOrder);
  if (!cycleInfo || (cycleInfo.approvedAt === null && cycleInfo.version === 0) || !userDetails) {
    return <EmptyBanner type={BannerType.EMPTY}></EmptyBanner>;
  }
  if (isSupportingDrug || (cycleInfo && cycleInfo.approvedAt === null && cycleInfo.version !== 0)) {
    return <CycleUnApprovedBanner />;
  }
  if (cycleInfo && cycleInfo.approvedAt && cycleInfo?.downloadedAt && cycleInfo.printedAt) {
    return (
      <CycleApprovedBanner approvedDate={moment(cycleInfo.approvedAt).tz(userDetails.timezone).format('L hh:mmA')} />
    );
  }
  if (cycleInfo && cycleInfo.approvedAt) {
    return (
      <CycleNotPrintedBanner
        savePdfCallback={() => savePdfCallback(cycleInfo.cycleId)}
        printPdfCallback={() => printPdfCallback(cycleInfo.cycleId)}
        approvedDate={moment(cycleInfo.approvedAt).tz(userDetails.timezone).format('L hh:mmA')}
        showPrintPdfLink={!cycleInfo?.printedAt}
        showSavePdfLink={!cycleInfo?.downloadedAt}
      />
    );
  }
  return <EmptyBanner type={BannerType.EMPTY}></EmptyBanner>;
};
export default Banner;
