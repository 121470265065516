// eslint-disable-next-line no-use-before-define
import React, { useContext } from 'react';
import classNames from 'classnames';

import { UserContext } from 'op-contexts';

import './ROSideNav.scss';

interface Props {
  children: React.ReactNode;
}

const ROSideNav = ({ children }: Props): JSX.Element => {
  const { state } = useContext(UserContext);

  return (
    <div
      className={classNames('ro-side-nav', {
        active: state.navShow,
      })}>
      <div id="profile-summary-side" className="ro-side-nav-wrapper">
        {children}
      </div>
    </div>
  );
};

export default ROSideNav;
