import { Typography, Stack } from '@mui/material';
import React from 'react';

export interface Props {
  id: string;
  fieldlabel?: string;
  required?: boolean;
  children?: React.ReactNode;
  alignItems?: string;
  tooltip?: JSX.Element;
}

/*
  Adds a field label, validation, helper text logic and other nice things to the
  BaseTextField to make this suitable for use in forms. Generally use this TextField
  unless you don't need a field label.
*/
const FormRow = ({ id, fieldlabel, required, children, alignItems = 'baseline', tooltip }: Props) => {
  const label = required ? fieldlabel + '*' : fieldlabel;
  return (
    <Stack direction="row" alignItems={alignItems} sx={{ marginBottom: '1rem' }}>
      <Stack
        gap="6px"
        direction="row"
        alignItems="center"
        sx={{ width: '248px', minWidth: '248px', marginBottom: 'auto', marginTop: '6px' }}>
        <Typography data-testid={`label-${id}`} variant="h6">
          {label}
        </Typography>
        {tooltip ?? undefined}
      </Stack>
      {children}
    </Stack>
  );
};

export default FormRow;
