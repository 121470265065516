// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment, FormEvent, useEffect } from 'react';
import { gql } from '@apollo/client';
import { styled } from '@mui/system';

import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { Query, Mutation } from '@apollo/client/react/components';
import 'url-search-params-polyfill';
import * as Sentry from '@sentry/browser';

import './UKPatientSearch.scss';

import { PatientOptIn } from 'op-interfaces';
import { ReactListData } from 'shared-components/interfaces';
import { ButtonType } from 'shared-components/enums';
import { DateTimeConverter, Logger } from 'shared-components/utils';

import { PageContainer } from 'op-components';
import { LoadingSpinner } from 'shared-components/components';
import { GCButton, DropDownField } from 'shared-components/components/FormFields';

import { BulletPoint, Success } from 'shared-components/images';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { useErrorModalContext } from 'op-contexts';
import { Typography, Stack } from '@mui/material';

const logger = new Logger('PatientSearch');

const BASE_SEARCH_URL = '/search';
const SEARCH_NAME = 'q';
const FILTER = 'filter';
const FILTER_PLACEHOLDER = 'Select filter';
const EMPTY_PATIENT_OPT_IN = {
  id: '',
  firstName: '',
  lastName: '',
  pxOptedIn: null,
  primaryPhone: '',
  email: '',
  pxOptOutComment: '',
  emrInstance: '',
};
const EMPTY_PATIENT_ADDRESS = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  country: '',
  postcode: '',
};
const UK_PATIENT_SEARCH = gql`
  query ukPatientSearch($searchTerm: String, $submissionStatus: String, $primaryCenterId: Int, $page: Int) {
    searchPatients: ukPatientSearch(
      search: $searchTerm
      threshold: 0.5
      submissionStatus: $submissionStatus
      primaryCenterId: $primaryCenterId
      page: $page
    ) {
      id
      firstName
      lastName
      ida
      dob
      primaryPhone
      primaryPhoneFormatted
      email
      pxOptedIn
      regFormStatus
      pxOptOutComment
      address {
        id
        line1
        line2
        city
        state
        country
        postcode
      }
    }
  }
`;
export const SEARCH_FILTERS_REF_DATA_QUERY = gql`
  query ukSearchFilters {
    searchFiltersRefData: listData(category: "ukSearchFilters") {
      id
      name
      appKey
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query UserDetails {
    user {
      id
      username
      features {
        distressThermometer
      }
    }
  }
`;

export const CREATE_PATIENT = gql`
  mutation CreatePatient {
    createPatient {
      patient {
        id
      }
    }
  }
`;

export interface PatientSearchQueryItemAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
}

export interface PatientSearchQueryItem {
  id: string;
  firstName: string;
  lastName: string;
  ida: string;
  dob: string;
  primaryPhone: string;
  primaryPhoneFormatted: string;
  email: string;
  address: PatientSearchQueryItemAddress;
  pxOptedIn: boolean | null;
  pxOptOutComment: string;
  regFormStatus: string;
  hasDraftCareplan?: boolean;
}

export interface PatientSearchQueryResultData {
  searchPatients: PatientSearchQueryItem[];
  user?: {
    features: {
      DT: boolean;
    };
  };
}

export interface PatientSearchQueryResultHandler {
  loading: boolean;
  error: string[];
  data: PatientSearchQueryResultData;
}

interface SearchFiltersQueryResultData {
  searchFiltersRefData: ReactListData[];
}

interface State {
  modalIsOpen: boolean;
  modalHeader?: string;
  selectedPIN: string;
  selectedName: string;
  buttonLoadingId: string;
  selectedPatientOptIn: PatientOptIn;
  optInModalOpen: boolean;
  optInModalInDOM: boolean;
  optInModalLoadingId: string;
  selectedPatientId: string;
  selectedPatientIda: string;
  selectedDob: string;
  selectedAddress: PatientSearchQueryItemAddress;
  lockErrorModalOpen: boolean;
  lockErrorText: string;
  distressThermometerEnabled: boolean;
}

interface UserDetailsResponse {
  data: {
    user: {
      id: string;
      username: string;
      features: {
        distressThermometer: boolean;
      };
    };
  };
}

interface Props extends RouteComponentProps, WithApolloClient<{}> {}

const StyledSearchInput = styled('input')`
  max-width: 872px;
  flex: 1;
  font-size: 16px;
  line-height: get-line-height(16, 24);
  padding: 12px 24px 12px 48px;
  background-image: url('../../../../../shared_components/images/Search.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: 12px 12px;
  background-color: ${(props) => props.theme.palette.secondary.light};
  border: 2px solid ${(props) => props.theme.palette.secondary.dark};
  margin-right: 16px;

  &:focus {
    background-image: url('../../../../../shared_components/images/Search_Green.svg');
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: 12px 12px;
    outline: none;
  }
`;

class UKPatientSearch extends Component<Props, State> {
  private searchTerm?: string = undefined;
  private liveSearchInput: HTMLInputElement | null = null;
  private submissionStatus = '';
  private triggerSubmit: HTMLFormElement | null = null;

  public constructor(props: Props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalHeader: undefined,
      selectedName: '',
      selectedPIN: '',
      buttonLoadingId: '',
      optInModalLoadingId: '',
      optInModalOpen: false,
      optInModalInDOM: false,
      selectedPatientId: '',
      selectedPatientIda: '',
      selectedDob: '',
      selectedAddress: { ...EMPTY_PATIENT_ADDRESS },
      lockErrorModalOpen: false,
      lockErrorText: '',
      selectedPatientOptIn: { ...EMPTY_PATIENT_OPT_IN },
      distressThermometerEnabled: false,
    };
  }

  public componentDidMount(): void {
    const { client } = this.props;
    if (!client) return;
    client.query({ query: GET_USER_DETAILS }).then((results: UserDetailsResponse): any => {
      {
        const scope = Sentry.getCurrentScope();
        scope.setTag('user_type', 'pso');
        scope.setUser({
          id: results.data.user.id,
          username: results.data.user.username,
        });
      }

      this.setState({ distressThermometerEnabled: results.data.user.features.distressThermometer });
    });

    // If we've landed on search page. Reset any cache for viewed pages.
    client.writeQuery({
      query: gql`
        query {
          registrationPagesViewed
        }
      `,
      data: {
        registrationPagesViewed: [],
      },
    });
  }

  /**
   * Extract what the search term param is
   */
  public extractSearchTerm = (): void => {
    const searchParams = this.props.location.search;

    if (searchParams) {
      const urlSearchParams = new URLSearchParams(searchParams);
      const query = urlSearchParams.get(SEARCH_NAME);
      if (query) {
        this.searchTerm = query;
      }
    }
  };

  public extractFilter = (): void => {
    const searchParams = this.props.location.search;

    if (searchParams) {
      const urlSearchParams = new URLSearchParams(searchParams);
      const query = urlSearchParams.get(FILTER);
      if (query) {
        this.submissionStatus = query;
      }
    }
  };

  public render(): JSX.Element {
    logger.debug('render', 'State: ', JSON.stringify(this.state));

    this.extractSearchTerm();
    this.extractFilter();

    return (
      <Query<SearchFiltersQueryResultData> query={SEARCH_FILTERS_REF_DATA_QUERY}>
        {({ loading, error, data }): JSX.Element => {
          const { setError } = useErrorModalContext();

          useEffect(() => {
            if (error) return setError();
          }, [error]);

          if (loading) return <LoadingSpinner />;

          return (
            <Fragment>
              <PageContainer>
                <div id="patient-search">
                  <div id="patient-search-title">{'Patient Search'}</div>
                  <form
                    action="/search"
                    id="patient-search-form"
                    method="GET"
                    onSubmit={this.submitHandler}
                    ref={(ref): void => {
                      this.triggerSubmit = ref;
                    }}>
                    <div className="patient-search-input-container">
                      <StyledSearchInput
                        type="text"
                        id="patient-search-input"
                        name={SEARCH_NAME}
                        placeholder={'Search patient name or enter GC number'}
                        defaultValue={this.searchTerm}
                        ref={(ref): void => {
                          this.liveSearchInput = ref;
                        }}
                      />
                      <GCButton title={'Search'} inputType="submit" type={ButtonType.GREEN} />
                      {this.makePatientButton()}
                    </div>
                    <div id="patient-search-filter">
                      <span>{'Registration form status:'}</span>
                      <DropDownField
                        inputName={FILTER}
                        placeholder={FILTER_PLACEHOLDER}
                        defaultValue={this.submissionStatus}
                        options={(data && data.searchFiltersRefData) || []}
                        onChange={(e): void => {
                          this.submissionStatus = e.target.value;
                          if (this.liveSearchInput && this.liveSearchInput.value !== '') {
                            this.triggerSubmit && this.triggerSubmit.submit();
                          }
                        }}
                      />
                      <a
                        href={
                          this.searchTerm ? `${BASE_SEARCH_URL}?${SEARCH_NAME}=${this.searchTerm}` : BASE_SEARCH_URL
                        }
                        onClick={(): void => {
                          this.triggerSubmit && this.triggerSubmit.submit();
                        }}>
                        {'Clear filter'}
                      </a>
                    </div>
                  </form>
                  <br />
                </div>
                {this.searchTerm && this.fetchSearchResults()}
              </PageContainer>
            </Fragment>
          );
        }}
      </Query>
    );
  }

  private makePatientButton = (): JSX.Element => {
    return (
      <Mutation<{ createPatient: { patient: { id: string } } }> mutation={CREATE_PATIENT}>
        {(createPatient: any, { loading, data }): JSX.Element => {
          if (!loading && data) {
            const { id } = data.createPatient.patient;
            return <Redirect to={`registration/${id}/basic`} push />;
          }
          const text = loading ? 'Loading' : 'Register new patient';
          return (
            <div style={{ marginLeft: '8px' }}>
              <GCButton title={text} onClick={createPatient} loading={loading} type={ButtonType.WHITE} />
            </div>
          );
        }}
      </Mutation>
    );
  };

  private fetchSearchResults = (): JSX.Element => {
    return (
      <Query<PatientSearchQueryResultData>
        query={UK_PATIENT_SEARCH}
        variables={{ searchTerm: this.searchTerm, submissionStatus: this.submissionStatus }}>
        {({ loading, error, data }): JSX.Element => {
          const queryData = data;
          const queryError = error;
          if (loading) {
            return <LoadingSpinner />;
          }

          if (queryError || !queryData) {
            return this.renderNoSearchResults();
          }

          return (
            <div id="patient-search-results">
              {this.renderContents(queryData)}
              {this.searchTerm !== undefined &&
                this.renderSearchTips(queryData && queryData.searchPatients && queryData.searchPatients.length > 0)}
            </div>
          );
        }}
      </Query>
    );
  };

  private renderContents = (data: PatientSearchQueryResultData): JSX.Element | JSX.Element[] | undefined => {
    if (this.searchTerm) {
      if (data && data.searchPatients && data.searchPatients.length > 0) {
        return this.renderSearchResults(data);
      } else {
        return this.renderNoSearchResults();
      }
    }

    return;
  };

  private renderSearchResults = (data: PatientSearchQueryResultData): JSX.Element[] | undefined => {
    if (data && data.searchPatients && data.searchPatients.length > 0) {
      const searchResults = data.searchPatients.map((patient: PatientSearchQueryItem): JSX.Element => {
        return (
          <div key={patient.ida} id={`patient_${patient.ida}`} className="patient-search-result">
            <div className="patient-search-name">
              {patient.firstName} {patient.lastName}
            </div>
            <Stack className="patient-search-details">
              <div className="patient-search-all">
                {this.renderContentDetails('Patient ID', patient.ida)}
                {this.renderContentDetails(
                  'Date of birth',
                  DateTimeConverter.getFormattedDateAsDDMonthYYYY(patient.dob),
                )}
                {this.renderContentDetails('Phone', patient.primaryPhone)}
              </div>
              <div className="patient-search-address">
                {this.renderContentDetails('Address', this.createAddress(patient.address))}
              </div>
              <div className="patient-registration-link">
                <a href={`/registration/${patient.id}/basic`}>Registration form</a>
                {this.renderFormStatus(patient.regFormStatus)}
              </div>
            </Stack>
          </div>
        );
      });

      return searchResults;
    }

    return;
  };

  private renderNoSearchResults() {
    return (
      <Typography
        style={{
          margin: '48px 0px 48px 8px',
          fontWeight: 'bold',
        }}
        variant="h5"
        id="patient-search-no-results">
        Sorry we couldnt find any results matching &nbsp;&quot;{this.searchTerm}&quot;
      </Typography>
    );
  }

  private renderSearchTips = (resultsFound: boolean): JSX.Element => {
    let message = 'Cant find what youre looking for? Try some of these search tips';
    if (!resultsFound) {
      message = 'Search tips';
    }

    return (
      <div id="patient-search-tips">
        <div id="patient-search-tips-heading">{message}</div>
        <div id="patient-search-tips-points">
          <ul>
            <li>
              <BulletPoint />
              Check your spelling and try again
            </li>
            <li>
              <BulletPoint />
              Just created a new patient in the EMR? Please wait one minute and then refresh the page
            </li>
            <li>
              <BulletPoint />
              Search using the patients' first name and last name or patient IDA
            </li>
            <li>
              <BulletPoint />
              Try to clear the status filter
            </li>
          </ul>
        </div>
      </div>
    );
  };

  private renderContentDetails = (title: string, value?: string | JSX.Element): JSX.Element => {
    let displayedValue = value;
    if (displayedValue === undefined || displayedValue === null || displayedValue === '') {
      displayedValue = 'Not provided';
    }

    return (
      <Stack direction="row">
        <div className="patient-search-title-column">{title}&#58;</div>
        <div className="patient-search-value-column">{displayedValue}</div>
      </Stack>
    );
  };

  private renderFormStatus = (regFormStatus: string): JSX.Element => {
    let icon = <div />; // FIXME: Temporary placeholder
    let message = '';

    if (regFormStatus === 'filterSubmittedToMosaiq') {
      icon = <Success className="reviewed icon" />;
      message = 'Submitted to Mosaiq';
    } else if (regFormStatus === 'filterReviewRequired') {
      icon = <ErrorOutlineIcon color="warning" className="icon" />;
      message = 'Review required';
    }

    return (
      <div className="patient-search-form-status-container">
        {icon}
        <span className="patient-search-form-status">{message}</span>
      </div>
    );
  };

  private createAddress = (address?: PatientSearchQueryItemAddress): JSX.Element | undefined => {
    if (address) {
      const { line1, line2, city, state, country, postcode } = address;

      if (line1 || city || state || country || postcode) {
        return (
          <Fragment>
            <div>
              {line1}&#44;
              <br />
              {this.renderLine2(line2)}
              {city}&#44;
              <br />
              {state} {country} {postcode}
            </div>
          </Fragment>
        );
      }
    }

    return undefined;
  };

  private renderLine2 = (line2?: string): JSX.Element => {
    if (line2) {
      return (
        <Fragment>
          {line2}&#44;
          <br />
        </Fragment>
      );
    }

    return <Fragment />;
  };

  private submitHandler = (event: FormEvent<HTMLFormElement>): void => {
    if (this.liveSearchInput && this.liveSearchInput.value === '') {
      event.preventDefault();
    }
  };
}

//@ts-ignore
const apolloComponent = withApollo(UKPatientSearch);
//@ts-ignore
export default withRouter(apolloComponent);
