// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'gc-ui';
import './ModalSubmitValidationError.scss';

import { Region } from 'shared-components/enums';
import { ButtonType } from 'shared-components/enums';
import { GCButton, ErrorInfo } from 'shared-components/components/FormFields';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

interface Props {
  isOpen: boolean;
  dismissFunction: () => void;
  validationErrors: any;
}

const keyNames: any = {
  firstName: 'First name',
  middleName: 'Middle name',
  lastName: 'Last name',
  namePrefix: 'Name prefix',
  dobRaw: 'Date of birth',
  gender: 'Gender',
  primaryCenter: 'Preferred centre',
  primaryPhone: 'Home or mobile phone number',
  secondaryPhone: 'Home or mobile phone number',
  email: 'Email',
  residentialAddressLine1: 'Address line 1',
  residentialAddressCity: 'Town/City',
  residentialAddressState: 'County',
  residentialAddressCountry: 'Country',
  residentialAddressPostcode: 'Post code',
  generalPractitioner: 'General practitioner',
  referringSurgeon: 'Referring surgeon',
  oncologist: 'Responsible clinician',
  'emergencyContact.firstName': 'Emergency contact first name',
  'emergencyContact.lastName': 'Emergency contact last name',
  'emergencyContact.relationship': 'Emergency contact relationship',
  'emergencyContact.mobilePhoneNumber': 'Emergency contact home or mobile phone number',
  'emergencyContact.homePhoneNumber': 'Emergency contact home or mobile phone number',
  'emergencyContact.authorisedForEnquiries': 'Emergency contact authorised for enquiries',
  'emergencyContact.supportPerson': 'Emergency contact identified as support',
  'emergencyContact.email': 'Emergency contact email',
  'nextOfKinContact.firstName': 'Next of kin first name',
  'nextOfKinContact.lastName': 'Next of kin last name',
  'nextOfKinContact.mobilePhoneNumber': 'Next of kin mobile phone',
  'nextOfKinContact.HomePhoneNumber': 'Next of kin home phone',
  'nextOfKinContact.email': 'Next of kin email',
  idb: 'NHS ID number',
  nhsOptions: 'Reason NHS ID not provided',
  payor: 'Insurance company',
  registrationReason: 'Registration reason',
  registrationReasonText: 'Registration reason',
  apptClash: 'Selected appointment no longer available',
  appointmentMissing: 'Please select appointment',
  maritalStatus: 'Marital status',
  countryOfBirth: 'Country of birth',
  heritage: 'Aboriginal or Torres Strait Islander',
  languageAtHome: 'Language spoken at home',
  attachments: 'Document type',
  attachmentAcknowledged: 'Document type acknowledgement',
};

class ModalSubmitValidationError extends Component<Props> {
  public render(): JSX.Element {
    const { dismissFunction, isOpen, validationErrors } = this.props;

    const region = REACT_APP_REGION;
    const isAU = region === Region.AU;

    // This removes duplicate validation errors for person and emergency phone numbers
    if (validationErrors && validationErrors.primaryPhone && validationErrors.secondaryPhone) {
      delete validationErrors.secondaryPhone;
    }
    if (
      validationErrors &&
      validationErrors['emergencyContact.homePhoneNumber'] &&
      validationErrors['emergencyContact.mobilePhoneNumber']
    ) {
      delete validationErrors['emergencyContact.mobilePhoneNumber'];
    }

    return (
      <div id="submit-validation-error-modal">
        <Modal open={isOpen} onClose={dismissFunction} className="modal-gc submit-validation-error-modal">
          <ModalHeader id="submit-validation-error-header" toggle={dismissFunction}>
            {'Missing mandatory fields'}
          </ModalHeader>
          <ModalBody>
            <div className="modal-submit-validation-error-body" data-testid="modal-submit-validation-error-body">
              {!isAU && (
                <>
                  {validationErrors &&
                    Object.keys(validationErrors).length > 5 &&
                    'The form contains multiple errors. Please fix and try again'}
                  {validationErrors &&
                    Object.keys(validationErrors).length < 6 &&
                    'Please complete the below required fields for submission and ensure that there are no errors in all fields.'}

                  {validationErrors &&
                    Object.keys(validationErrors).length < 6 &&
                    Object.keys(validationErrors).map((key: string): JSX.Element | undefined => {
                      if (keyNames[key]) return this.renderError(keyNames[key]);
                    })}
                </>
              )}
              {isAU && validationErrors && (
                <>
                  {
                    <>
                      Please answer all mandatory questions before submitting. Mandatory questions are marked with an{' '}
                      <strong>asterisk*</strong>. Use the left menu or Review and Submit page to return to a section.
                    </>
                  }
                  {Object.keys(validationErrors).length < 10 &&
                    Object.keys(validationErrors).map((key: string): JSX.Element | undefined => {
                      if (keyNames[key]) return this.renderError(keyNames[key]);
                    })}
                </>
              )}
            </div>
            <GCButton
              name="returnToForm"
              title="Return to form"
              onClick={(e): void => {
                e.preventDefault();
                dismissFunction();
              }}
              type={ButtonType.GREEN}
              data-testid="return-to-form"
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }

  private renderError = (fieldDisplayName: string): JSX.Element => {
    return <ErrorInfo errors={[fieldDisplayName]} key={fieldDisplayName} />;
  };
}

export default ModalSubmitValidationError;
