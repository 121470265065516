import { BottomSection, HeaderSection } from 'op-pages/PX/Components';
import { ReactNode, memo } from 'react';
import { Stack } from '@mui/material';

interface MainLayoutIProps {
  children: ReactNode;
  clickLogoFn?: () => void;
}

const MainLayout = (props: MainLayoutIProps): JSX.Element => {
  const { children, clickLogoFn } = props;
  const componentId = 'main-layout';

  return (
    <Stack id={componentId} style={{ padding: '0', width: '100%', display: 'flex', height: '100%' }}>
      <HeaderSection clickLogoFn={clickLogoFn} />
      <div id={`${componentId}-content`} style={{ display: 'flex', overflow: 'auto' }}>
        {children}
      </div>
      <BottomSection />
    </Stack>
  );
};

export default memo(MainLayout);
