// eslint-disable-next-line no-use-before-define
import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { ComposedModal } from 'gc-ui';
import BolusForm, { getBolusValidationStructure } from './BolusForm';
import { ROTextField, ROAutocomplete, ROToggleButtons } from 'shared-components/components/FormFields';
import ROPatientCarePlanPageFooter from '../Footer';
import {
  BLOOD_TEST_LAST_3_MONTHS,
  BOOLEAN_OPTIONS,
  CONTRAST_TYPES,
  EGFR_GT_30,
  fieldValues,
  IMAGING_REQUIREMENT_OPTIONS,
} from './Interface';
import { getOptionByValue } from '../DiagnosisPages/Utils';
import { ROPatientCarePlanRoute, ImagingType } from '../Interface';
import { CAREPLAN_PAGES } from '../Constants';
import {
  CREATE_IMAGING,
  RESET_SIMULATION,
  LOAD_SITEGROUP_INFO,
  UPDATE_SIMULATION,
  DELETE_IMAGING,
  UPDATE_IMAGING,
  GET_DEFAULT_VALUES,
  USER_QUERY,
} from './Queries';

import { RESET_CONDITIONAL_FIELDS } from '../PrescriptionPage/Queries';
import { GET_DIAGNOSIS_FROM_CAREPLAN } from '../SidePanel/SidePanelQueries';
import { LOAD_CAREPLAN_LIST_DATA } from '../ListData/ListQueries';
import { filterListdata } from '../ListData/ListData';
import ROPatientCarePathSidePanel from '../SidePanel/SidePanel';
import {
  setYesNo,
  isFieldVisible,
  ScrollToTop,
  CAREPLAN_STATUS,
  ManualSiteAccordion,
  mapConditionalFields,
  CONDITIONAL_FIELD_MAP,
  getTreatmentSiteHeader,
  getManualSiteTumourStreamMap,
} from '../Common';
import { SUBMIT_PRESCRIPTION, GET_CAREPLAN, GET_CAREPLAN_STATUS, TRIGGER_CPOT } from '../Queries';
import { isPageValid, validateField } from '../ValidationEngine';
import { CareplanBanners } from '../Banner/Banner';

import './Simulation.scss';
import { Logger } from 'shared-components/utils';
import { LoadingSpinner } from 'shared-components/components';
import CareplanContainer from '../Container';
import { useErrorModalContext } from 'op-contexts';
import ModalCpotCancel from '../ModalCpotCancel/ModalCpotCancel';
import { Region } from 'shared-components/enums';
import { CurrentAppConfig } from '../AppConfig';
import { SectionTitle } from 'op-pages/RO/FormBuilder/Forms/FormElements';
import { logMissingDiagnosisCode } from '../Common';
import { BREADTH_HOLD_OPTIONS } from '../TreatmentInfoPage/Interface';
import { Typography, Divider, Box, IconButton, Button, useTheme } from '@mui/material';
import { SelectOptionType } from 'shared-components/components/FormFields';
import { RemoveCircleOutline, AddCircleOutline } from '@mui/icons-material';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const logger = new Logger('ROPatientSimulationPage');
const region = REACT_APP_REGION;

const LIST_OPTIONS = {
  PATIENT_POSITION: 'patientPosition',
  EXTENT_OF_CT: 'extentOfCT',
  BLADDER: 'bladder',
  SCAN_EXAMINATION: 'scanType',
  BODY_REGION: 'imagingArea',
  STOMACH: 'stomach',
  BOLUS_THICKNESS: 'bolusThickness',
  BOLUS_FREQUENCY: 'bolusFrequency',
  IS_3D_BOLUS: 'is3dBolus',
  BOLUS_LOCATION_TYPE: 'bolusLocationType',
};
const DEFAULT_OPTION: SelectOptionType[] = [
  {
    label: '',
    value: '',
  },
];
const showChildField = ['Custom setup', 'Custom', 'As per template'];

const readOnlyChildField = ['As per template'];

const DEFAULT_FIELDS = {
  POSITION_SETUP: 'Position_Other',
  EXTENT_DEFINED: 'Extent_Defined',
};

let pressedContinue = false;

const isFieldValid = (field: any): boolean => {
  return pressedContinue ? validateField(field) : true;
};

const DEFAULT_IMAGING: ImagingType = {
  imagingId: '',
  imagingRequirement: '',
  imagingScanExamination: '',
  imagingBodyRegion: '',
  imagingContrastRequired: '',
  imagingRadiologyClinic: '',
  imagingAnatomicalMatchPriority: '',
  imagingInstructions: '',
  showImageOptions: false,
};

const IMAGING_NAMES = {
  RADIOLOGY_CLINIC: 'imagingRadiologyClinic',
  INSTRUCTIONS: 'imagingInstructions',
  ANATOMICA_MATCH: 'imagingAnatomicalMatchPriority',
};

const SIM_NAMES = {
  POSITION_SETUP: 'treatmentPositionSetup',
  EXTENT_DEFINED: 'treatmentExtentDefined',
  EXTRA_CT_MARKERS: 'treatmentExtraCTMarkers',
  ADDITIONAL_SIM_INFO: 'treatmentAdditionalSimInfo',
  BOLUS: 'treatmentBolus',
  BOLUS_LOCATION: 'treatmentBolusLocation',
  BOLUS_LOCATION_TYPE: 'treatmentBolusLocationType',
  BOLUS_THICKNESS: 'treatmentBolusThickness',
  BOLUS_THICKNESS_CUSTOM: 'treatmentBolusThicknessCustom',
  BOLUS_FREQUENCY: 'treatmentBolusFrequency',
  IS_3D_BOLUS: 'treatmentIs3dBolus',
  TONGUE_POSITION: 'treatmentTonguePosition',
  CT_SIMULATION: 'treatmentCTSimulation',
  CONTRAST_REQUIRED: 'treatmentContrastRequired',
  CONTRAST_TYPE: 'treatmentContrastType',
  BLOOD_TEST_QUESTION: 'treatmentBloodTestQuestion',
  EGFR_QUESTION: 'treatmentEgfrQuestion',
};

const ROPatientTreatmentSimulationPage = (): JSX.Element => {
  const match = useRouteMatch<ROPatientCarePlanRoute>();
  const { id: patientId, careplanId, siteGroupIdx: siteGroupLocation } = match.params;
  const siteGroupIdx = siteGroupLocation ? parseInt(siteGroupLocation) : 0;
  const theme = useTheme();

  const [showModal, setShowModal] = useState(false);
  const { data: careplanStatus } = useQuery(GET_CAREPLAN_STATUS, {
    variables: { id: careplanId },
  });
  const cpotTriggered = careplanStatus?.careplan?.careplanStatus === 'CPOT';

  const history = useHistory();
  const { setError } = useErrorModalContext();

  const [cancelCpot] = useMutation(TRIGGER_CPOT, {
    awaitRefetchQueries: true,
    variables: {
      id: careplanId,
      careplanStatus: 'Prescription',
      cpotTriggered: false,
    },
    refetchQueries: [
      { query: LOAD_SITEGROUP_INFO, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
    ],
    onCompleted: () => {
      history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SUBMISSION}`);
    },
  });

  const [submitPrescription] = useMutation(SUBMIT_PRESCRIPTION, {
    refetchQueries: [{ query: GET_CAREPLAN, variables: { id: careplanId } }],
  });
  const [patientPositionOptions, setPositionOptions] = useState<SelectOptionType[]>(DEFAULT_OPTION);
  const [extentCTOptions, setExtentCTOptions] = useState<SelectOptionType[]>(DEFAULT_OPTION);
  const [bladderOptions, setBladderOptions] = useState<SelectOptionType[]>(DEFAULT_OPTION);
  const [scanExaminationOptions, setScanExaminationOptions] = useState<SelectOptionType[]>(DEFAULT_OPTION);
  const [bodyRegionOptions, setBodyRegionOptions] = useState<SelectOptionType[]>(DEFAULT_OPTION);
  const [stomachOptions, setStomachOptions] = useState<SelectOptionType[]>(DEFAULT_OPTION);
  const [positionSetupShow, setPositionSetupShow] = useState<boolean>(false);
  const [positionSetupReadOnly, setPositionSetupReadOnly] = useState<boolean>(false);
  const [positionSetupValues, setPositionSetupValues] = useState<any[]>([]);
  const [extentOfCtValues, setExtentOfCtValues] = useState<any[]>([]);
  const [extentCtSetupShow, setExtentCtSetupShow] = useState<boolean>(false);
  const [extentCtSetupReadOnly, setExtentCtSetupReadOnly] = useState<boolean>(false);
  const [imagingRadiologyClinicValues, setImagingRadiologyClinic] = useState<any[]>([]);
  const [imagingAnatomicalMatchPriorityValues, setImagingAnatomicalMatchPriority] = useState<any[]>([]);
  const [imagingInstructionsValues, setImagingInstructions] = useState<any[]>([]);
  const [extraCtMarkersValues, setExtraCtMarkersValues] = useState<any[]>([]);
  const [additionalSimInfoValues, setAdditionalSimInfoValues] = useState<any[]>([]);
  const [bolusLocationValues, setBolusLocationValues] = useState<any[]>([]);
  const [bolusThicknessCustomValues, setBolusThicknessCustomValues] = useState<any[]>([]);
  const [tonguePositionValues, setTonguePositionValues] = useState<any[]>([]);
  const [requestSimModalOpen, setRequestSimModalOpen] = useState(false);
  const [submittingPrescription, setSubmittingPrescription] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [conditionalsOpen, setConditionalsOpen] = useState(false);
  const [, updateState] = useState();
  // @ts-ignore allow empty object
  const forceUpdate = useCallback(() => updateState({}), []);
  const { data: diagnosisData, error: diagnosisDataError } = useQuery(GET_DIAGNOSIS_FROM_CAREPLAN, {
    variables: { careplanId: careplanId },
    onCompleted: (data: any): void => {
      logMissingDiagnosisCode(logger, data.careplan, 'Simulation');
    },
  });

  const [bolusOptions, setBolusOptions] = useState({
    bolus: DEFAULT_OPTION,
    bolusThickness: DEFAULT_OPTION,
    bolusFrequency: DEFAULT_OPTION,
    is3dBolus: DEFAULT_OPTION,
    bolusLocationType: DEFAULT_OPTION,
  });

  const diagnosisTumourStream = diagnosisData?.careplan?.diagnosis?.diagnosisCode?.tumourStream?.name ?? null;

  useEffect((): void => {
    pressedContinue = false;
  }, []);

  const preparePageTransition = () => {
    // Reset pressed continue because this component can be re-used on the next page
    pressedContinue = false;
    ScrollToTop(document);
  };

  useEffect((): void => {
    const errorElems = document.getElementsByClassName('icon-form-error');
    if (!errorElems || !errorElems.length) return;
    errorElems[0].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
    (errorElems[0] as HTMLElement).focus({ preventScroll: true });
  });

  const setDefaultShowValue = (siteGroup: any): void => {
    if (siteGroup) {
      if (siteGroup.patientPosition) {
        if (readOnlyChildField.includes(siteGroup.patientPosition.value)) {
          setPositionSetupReadOnly(true);
        } else {
          setPositionSetupReadOnly(false);
        }
        showChildField.includes(siteGroup.patientPosition.value)
          ? setPositionSetupShow(true)
          : setPositionSetupShow(false);
      }
      if (siteGroup.extentOfCt) {
        if (readOnlyChildField.includes(siteGroup.extentOfCt.value)) {
          setExtentCtSetupReadOnly(true);
        } else {
          setExtentCtSetupReadOnly(false);
        }
        showChildField.includes(siteGroup.extentOfCt.value) ? setExtentCtSetupShow(true) : setExtentCtSetupShow(false);
      }
    }
  };

  const setImagingData = (imaging: any): ImagingType => {
    return {
      imagingId: imaging.id,
      imagingRequirement: imaging.imagingRequirement,
      imagingScanExamination: imaging.scanExamination,
      imagingBodyRegion: imaging.bodyRegion,
      imagingContrastRequired: imaging.contrastRequired,
      imagingRadiologyClinic: imaging.radiologyClinic,
      imagingAnatomicalMatchPriority: imaging.anatomicalMatchPriority,
      imagingInstructions: imaging.instructions,
      showImageOptions: imaging.imagingRequirement ? true : false,
    };
  };

  const setImagingList = (siteGroup: any): ImagingType[] => {
    if ('imagingList' in siteGroup && siteGroup.imagingList) {
      const imagingList = siteGroup.imagingList;
      if (imagingList.length !== 0) {
        const orderImaging = [...imagingList].sort((a: any, b: any) => a.id - b.id);
        return orderImaging.map((imaging: any): ImagingType => {
          return setImagingData(imaging);
        });
      }
    }
    return [DEFAULT_IMAGING];
  };

  const listValuesLookup = (listValues: any, idToMatch: string, defaultValue: any) => {
    let value = '';
    if (typeof defaultValue == 'string') value = defaultValue;
    else value = defaultValue ? defaultValue.value : '';
    if (listValues.length > 0) {
      const matchedElement = listValues.find((x: any) => x.id === idToMatch);
      return matchedElement ? matchedElement.value : value;
    }
    return value;
  };

  const setTextFields = (name: string, id: string, values: any, newValue: string, reset = false): void => {
    const idx = values.filter(Boolean).findIndex((obj: any) => obj.id === id);
    let newValues: any[] = [];
    if (idx === -1) {
      newValues.push({ id, value: newValue });
    } else {
      if (reset) {
        delete values[idx];
        newValues = values.filter(Boolean);
      } else {
        newValues[idx] ? (newValues[idx].value = newValue) : newValues.push({ id, value: newValue });
      }
    }
    switch (name) {
      case IMAGING_NAMES.RADIOLOGY_CLINIC:
        setImagingRadiologyClinic(newValues);
        break;
      case IMAGING_NAMES.INSTRUCTIONS:
        setImagingInstructions(newValues);
        break;
      case IMAGING_NAMES.ANATOMICA_MATCH:
        setImagingAnatomicalMatchPriority(newValues);
        break;
      case SIM_NAMES.POSITION_SETUP:
        setPositionSetupValues(newValues);
        break;
      case SIM_NAMES.EXTENT_DEFINED:
        setExtentOfCtValues(newValues);
        break;
      case SIM_NAMES.EXTRA_CT_MARKERS:
        setExtraCtMarkersValues(newValues);
        break;
      case SIM_NAMES.ADDITIONAL_SIM_INFO:
        setAdditionalSimInfoValues(newValues);
        break;
      case SIM_NAMES.BOLUS_LOCATION:
        setBolusLocationValues(newValues);
        break;
      case SIM_NAMES.BOLUS_THICKNESS_CUSTOM:
        setBolusThicknessCustomValues(newValues);
        break;
      case SIM_NAMES.TONGUE_POSITION:
        setTonguePositionValues(newValues);
        break;
      default:
        break;
    }
  };

  const { loading: careplanDataLoading } = useQuery(LOAD_CAREPLAN_LIST_DATA, {
    fetchPolicy: 'cache-first',
    variables: {
      listCategory: [
        LIST_OPTIONS.PATIENT_POSITION,
        LIST_OPTIONS.BLADDER,
        LIST_OPTIONS.EXTENT_OF_CT,
        LIST_OPTIONS.STOMACH,
        LIST_OPTIONS.BOLUS_THICKNESS,
        LIST_OPTIONS.BOLUS_FREQUENCY,
        LIST_OPTIONS.IS_3D_BOLUS,
        LIST_OPTIONS.BOLUS_LOCATION_TYPE,
        LIST_OPTIONS.SCAN_EXAMINATION,
        LIST_OPTIONS.BODY_REGION,
      ],
      patientId,
    },
    onCompleted: (data: any): void => {
      setPositionOptions(filterListdata(data, LIST_OPTIONS.PATIENT_POSITION));
      setExtentCTOptions(filterListdata(data, LIST_OPTIONS.EXTENT_OF_CT));
      setBladderOptions(filterListdata(data, LIST_OPTIONS.BLADDER));
      setStomachOptions(filterListdata(data, LIST_OPTIONS.STOMACH));
      setScanExaminationOptions(filterListdata(data, LIST_OPTIONS.SCAN_EXAMINATION));
      setBodyRegionOptions(filterListdata(data, LIST_OPTIONS.BODY_REGION));

      setBolusOptions({
        bolus: BOOLEAN_OPTIONS,
        bolusThickness: filterListdata(data, LIST_OPTIONS.BOLUS_THICKNESS),
        bolusFrequency: filterListdata(data, LIST_OPTIONS.BOLUS_FREQUENCY),
        is3dBolus: filterListdata(data, LIST_OPTIONS.IS_3D_BOLUS),
        bolusLocationType: filterListdata(data, LIST_OPTIONS.BOLUS_LOCATION_TYPE),
      });
    },
  });

  const {
    loading,
    error: siteGroupError,
    data: siteGroupData,
  } = useQuery(LOAD_SITEGROUP_INFO, {
    variables: { careplanId },
    fetchPolicy: 'network-only',
  });

  const { data: careplan, error: careplanError } = useQuery(GET_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { id: careplanId },
  });

  const siteGroupRefetchQueries = [
    { query: LOAD_SITEGROUP_INFO, variables: { careplanId } },
    { query: GET_CAREPLAN, variables: { id: careplanId } },
  ];

  const [resetConditionalFields] = useMutation(RESET_CONDITIONAL_FIELDS, {
    awaitRefetchQueries: true,
    refetchQueries: siteGroupRefetchQueries,
  });
  const [resetSiteGroup] = useMutation(RESET_SIMULATION, {
    refetchQueries: () => siteGroupRefetchQueries,
    awaitRefetchQueries: true,
    onCompleted: (data: any): void => {
      if (data?.resetSimulation) {
        const siteGroup = data.resetSimulation.siteGroup;
        setTextFields(
          SIM_NAMES.EXTENT_DEFINED,
          siteGroup.id,
          extentOfCtValues,
          siteGroup.extentDefined ? siteGroup.extentDefined.value : '',
        );
        setTextFields(
          SIM_NAMES.POSITION_SETUP,
          siteGroup.id,
          positionSetupValues,
          siteGroup.positionSetup ? siteGroup.positionSetup.value : '',
        );
        setTextFields(
          SIM_NAMES.BOLUS_LOCATION,
          siteGroup.id,
          bolusLocationValues,
          siteGroup.bolusLocation ? siteGroup.bolusLocation.value : '',
        );
        setDefaultShowValue(siteGroup);
      }
    },
  });

  const [updateSimulation, { loading: updateSimulationLoading }] = useMutation(UPDATE_SIMULATION, {
    refetchQueries: [{ query: GET_CAREPLAN, variables: { id: careplanId } }],
  });

  const [createNewImaging] = useMutation(CREATE_IMAGING, {
    awaitRefetchQueries: true,
    refetchQueries: siteGroupRefetchQueries,
  });

  const [updateImaging] = useMutation(UPDATE_IMAGING, {
    awaitRefetchQueries: true,
    refetchQueries: siteGroupRefetchQueries,
  });

  const [deleteImaging] = useMutation(DELETE_IMAGING, {
    awaitRefetchQueries: true,
    refetchQueries: siteGroupRefetchQueries,
  });

  const onBack = (): void => {
    const isFirstSiteGroup = siteGroupIdx === 0;
    preparePageTransition();
    if (isFirstSiteGroup) {
      history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SITESETUP}`);
    } else {
      const newSiteGroupIdx = siteGroupIdx - 1;
      newSiteGroupIdx === 0
        ? history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SIMULATION}`)
        : history.push(
            `/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SIMULATION}/${newSiteGroupIdx}`,
          );
      setDefaultShowValue(siteGroupData.simulationSiteGroups[siteGroupIdx - 1]);
    }
  };

  const { data: userData } = useQuery(USER_QUERY);
  const isShowcaseUser = userData?.user?.isShowcaseUser;
  const displaySimButton =
    CurrentAppConfig.DisplayButton.displaySimButton &&
    !cpotTriggered &&
    careplan &&
    ![CAREPLAN_STATUS.planAim, CAREPLAN_STATUS.prescriptionDirective, CAREPLAN_STATUS.prescription].includes(
      careplan.careplan.careplanStatus,
    );

  const simulationSiteGroups = siteGroupData ? siteGroupData.simulationSiteGroups : [];
  const siteGroupLength = simulationSiteGroups ? simulationSiteGroups.length - 1 : 0;
  const isLastSiteGroup = siteGroupIdx === siteGroupLength;
  const careplanOnSim = careplan && careplan.careplan.careplanStatus === CAREPLAN_STATUS.simulation;
  const disableSimButton = isShowcaseUser || !isLastSiteGroup;

  const footerOptions =
    !cpotTriggered && displaySimButton
      ? {
          additionLeftText:
            careplan.careplan.careplanStatus === CAREPLAN_STATUS.draft
              ? 'Request simulation only & exit'
              : 'Re-request simulation only & exit',
          onAdditionLeft: (): void => {
            pressedContinue = true;
            forceUpdate();
            isPageValid(validationDependencyStructure, siteGroup) && setRequestSimModalOpen(true);
          },
          additionLeftDisabled: disableSimButton,
        }
      : {};
  const inValidSiteGroupIndex = () => {
    window.location.replace(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SIMULATION}`);
    return siteGroupData.simulationSiteGroups[0];
  };

  const hasActiveSiteGroup = (group: any): any => {
    if (group) {
      if (siteGroupData.simulationSiteGroups) {
        if (siteGroupData.simulationSiteGroups[siteGroupIdx]) {
          return siteGroupData.simulationSiteGroups[siteGroupIdx];
        } else {
          if (String(siteGroupLocation) !== 'last' && siteGroupData.simulationSiteGroups[0]) {
            inValidSiteGroupIndex();
          }
        }
      }
    }
    return [];
  };

  const siteGroup = hasActiveSiteGroup(siteGroupData);
  const isManualSite = !siteGroup.templateId;

  useEffect((): void => {
    setDefaultShowValue(siteGroup);
  });

  const { data: setDefaultsValues, error: defaultsValuesError } = useQuery(GET_DEFAULT_VALUES, {
    skip: !siteGroup || !siteGroup.id,
    variables: {
      siteGroupId: siteGroup.id,
      fieldNames: [DEFAULT_FIELDS.POSITION_SETUP, DEFAULT_FIELDS.EXTENT_DEFINED],
    },
  });

  useEffect(() => {
    if (siteGroupError || careplanError || defaultsValuesError || diagnosisDataError) return setError();
  }, [siteGroupError, careplanError, defaultsValuesError, diagnosisDataError]);

  const imagingList: ImagingType[] = setImagingList(siteGroup);

  const getDefaultValues = (fieldName: string): any => {
    if (setDefaultsValues) {
      const defaultValue = setDefaultsValues.defaultSiteGroupValues.find((x: any) => x.fieldName === fieldName);
      return defaultValue ? defaultValue.value : '';
    }
    return '';
  };

  const {
    sites,
    id: fieldsId,
    ctSimulation,
    contrastRequired,
    contrastType,
    bloodTestLast3Months,
    egfrGt30ml,
    roRequired,
    patientPosition,
    positionSetup,
    extentOfCt,
    extentDefined,
    mask,
    stomach,
    abdomenCompression,
    bladder,
    bowelPrep,
    goldSeeds,
    vaginalTampon,
    spacerGel,
    tonguePosition,
    mouthpiece,
    gating,
    fourDct,
    dibh,
    breathHold,
    extraCtMarkers,
    additionalSimInfo,
  } = siteGroup;
  const baseValidationDependencyStructure = [
    {
      // dibh is optional on care plan templates. Therefore we only validate when it exists
      parent: 'dibh',
      expansionCondition: (parentValue: any) => {
        if (parentValue === null) return false;
        if (isManualSite && !(hiddenConditionals.includes('dibh') && conditionalsOpen)) return false;
        return Boolean(parentValue?.isShown);
      },
      children: ['dibh'],
    },
    {
      parent: 'breathHold',
      expansionCondition: (parentValue: any) => {
        if (parentValue === null) return false;
        if (isManualSite && !(hiddenConditionals.includes('breathHold') && conditionalsOpen)) return false;
        return Boolean(parentValue?.isShown);
      },
      children: ['breathHold'],
    },
    {
      parent: 'ctSimulation',
      expansionValue: 'true',
      expansionCondition: () => contrastRequired?.isShown,
      children: ['contrastRequired'],
    },
    {
      parent: 'contrastRequired',
      expansionValue: 'true',
      expansionCondition: () =>
        contrastRequired?.isShown && CurrentAppConfig.SimulationPage.ContrastRequiredChildFields === true,
      children: ['contrastType'],
    },
    {
      parent: 'contrastType',
      expansionValue: fieldValues.iv,
      children: ['bloodTestLast3Months'],
    },
    {
      parent: 'bloodTestLast3Months',
      expansionValue: fieldValues.yes,
      children: ['egfrGt30ml'],
    },
  ];

  // Need to use a ref here because the while loop that uses it will not pick up the state change
  const simUpdateInProgressRef = useRef(false);
  useEffect(() => {
    simUpdateInProgressRef.current = updateSimulationLoading;
  }, [updateSimulationLoading]);

  const onNext = async () => {
    while (simUpdateInProgressRef.current) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    pressedContinue = true;
    forceUpdate();
    const canContinue = isPageValid(validationDependencyStructure, siteGroup);
    if (!canContinue) {
      return;
    }

    preparePageTransition();

    if (isLastSiteGroup) {
      history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.PRESCRIPTION}`);
    } else {
      const newSiteGroupIdx = siteGroupIdx + 1;
      history.push(
        `/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SIMULATION}/${newSiteGroupIdx}`,
      );
      setDefaultShowValue(siteGroupData.simulationSiteGroups[newSiteGroupIdx]);
    }
  };

  const clearImaging = (siteGroup: any): void => {
    if (siteGroup) {
      if (siteGroup.imagingList.length !== 0) {
        siteGroup.imagingList.map((imaging: any) => {
          return deleteImaging({ variables: { imagingId: imaging.id } });
        });
      }
    }
  };

  const siteNames = sites ? sites.map((site: any): string => getTreatmentSiteHeader(site)).join(', ') : '';

  const handleManualSiteAccordionOpen = () => {
    setConditionalsOpen(true);
  };

  const handleManualSiteAccordionClose = () => {
    resetConditionalFields({
      variables: {
        siteGroupId: parseInt(fieldsId),
        fieldNames: mapConditionalFields(CONDITIONAL_FIELD_MAP, hiddenConditionals),
      },
    });
    setConditionalsOpen(false);
  };
  const manualSiteConditionalFields = getManualSiteTumourStreamMap(careplan?.careplan.featureVersion)[
    diagnosisTumourStream
  ];
  const bolusValidationStructure = getBolusValidationStructure(careplan?.careplan.featureVersion);
  const validationDependencyStructure = [...baseValidationDependencyStructure, ...bolusValidationStructure];

  const conditionalFieldsJSX = {
    mask: (
      <ROToggleButtons
        id="treatmentMask"
        fieldlabel="Mask"
        options={BOOLEAN_OPTIONS}
        value={setYesNo(mask ? mask.value : '')}
        handleChange={(value): void => {
          const maskBool = value === 'yes' ? true : false;
          updateSimulation({ variables: { siteGroupId: fieldsId, mask: maskBool } });
        }}
      />
    ),
    stomach: (
      <ROAutocomplete
        id="treatmentStomach"
        fieldlabel="Stomach"
        options={stomachOptions}
        value={getOptionByValue(
          stomachOptions.map((data) => ({ label: data.label, value: data.value })),
          stomach ? stomach.value : '',
        )}
        onChange={(option: SelectOptionType | string): void => {
          const value = typeof option === 'string' ? option : option?.value;
          updateSimulation({
            variables: {
              siteGroupId: fieldsId,
              stomach: value || '',
            },
          });
        }}
      />
    ),
    abdomenCompression: (
      <ROToggleButtons
        id="treatmentAbdomenCompression"
        fieldlabel="Abdomen Compression"
        options={BOOLEAN_OPTIONS}
        value={setYesNo(abdomenCompression ? abdomenCompression.value : '')}
        handleChange={(value): void => {
          const abdomenCompressionBool = value === 'yes';
          updateSimulation({
            variables: { siteGroupId: fieldsId, abdomenCompression: abdomenCompressionBool },
          });
        }}
      />
    ),
    bladder: (
      <ROAutocomplete
        id="treatmentBladder"
        fieldlabel="Bladder"
        options={bladderOptions}
        value={getOptionByValue(
          bladderOptions.map((data) => ({ label: data.label, value: data.value })),
          bladder ? bladder.value : '',
        )}
        onChange={(option: SelectOptionType | string): void => {
          const value = typeof option === 'string' ? option : option?.value;
          updateSimulation({
            variables: {
              siteGroupId: fieldsId,
              bladder: value || '',
            },
          });
        }}
      />
    ),
    bowelPrep: (
      <ROToggleButtons
        id="treatmentBowelPrep"
        fieldlabel={'Bowel Prep'}
        options={BOOLEAN_OPTIONS}
        value={setYesNo(bowelPrep ? bowelPrep.value : '')}
        handleChange={(value): void => {
          const bowelPrepBool = value === 'yes' ? true : false;
          updateSimulation({
            variables: { siteGroupId: fieldsId, bowelPrep: bowelPrepBool },
          });
        }}
      />
    ),
    goldSeeds: (
      <ROToggleButtons
        id="treatmentGoldSeeds"
        fieldlabel={CurrentAppConfig.QuestionLabel.GoldSeeds}
        options={BOOLEAN_OPTIONS}
        value={setYesNo(goldSeeds ? goldSeeds.value : '')}
        handleChange={(value): void => {
          const goldSeedsBool = value === 'yes' ? true : false;
          updateSimulation({ variables: { siteGroupId: fieldsId, goldSeeds: goldSeedsBool } });
        }}
      />
    ),
    vaginalTampon: (
      <ROToggleButtons
        id="treatmentVaginalTampon"
        fieldlabel="Vaginal Tampon"
        options={BOOLEAN_OPTIONS}
        value={setYesNo(vaginalTampon ? vaginalTampon.value : '')}
        handleChange={(value): void => {
          const vaginalTamponBool = value === 'yes' ? true : false;
          updateSimulation({ variables: { siteGroupId: fieldsId, vaginalTampon: vaginalTamponBool } });
        }}
      />
    ),
    spacerGel: (
      <ROToggleButtons
        id="treatmentSpacerGel"
        fieldlabel="Spacer Gel"
        options={BOOLEAN_OPTIONS}
        value={setYesNo(spacerGel ? spacerGel.value : '')}
        handleChange={(value): void => {
          const spacerGelBool = value === 'yes' ? true : false;
          updateSimulation({ variables: { siteGroupId: fieldsId, spacerGel: spacerGelBool } });
        }}
        warning={CurrentAppConfig.SimulationPage.SpacerGelWarning && spacerGel?.value === 'true'}
        helperText="MR Planning scan required"
      />
    ),
    tonguePosition: (
      <ROTextField
        id={SIM_NAMES.TONGUE_POSITION}
        fieldlabel={'Tongue Position'}
        value={listValuesLookup(tonguePositionValues, fieldsId, tonguePosition)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          setTextFields(SIM_NAMES.TONGUE_POSITION, fieldsId, tonguePositionValues, event.target.value);
        }}
        onBlur={(): void => {
          const valueSet = tonguePositionValues.find((x: any) => x.id === fieldsId);
          if (valueSet) {
            const value = valueSet ? valueSet.value : '';
            updateSimulation({ variables: { siteGroupId: fieldsId, tonguePosition: value } });
          }
        }}
      />
    ),
    mouthpiece: (
      <ROToggleButtons
        id="treatmentMouthpiece"
        fieldlabel={'Mouthpiece'}
        options={BOOLEAN_OPTIONS}
        value={setYesNo(mouthpiece ? mouthpiece.value : '')}
        handleChange={(value): void => {
          const mouthpieceBool = value === 'yes' ? true : false;
          updateSimulation({ variables: { siteGroupId: fieldsId, mouthpiece: mouthpieceBool } });
        }}
      />
    ),
    gating: (
      <ROToggleButtons
        id="treatmentGating"
        fieldlabel={'Gating'}
        options={BOOLEAN_OPTIONS}
        value={setYesNo(gating ? gating.value : '')}
        handleChange={(value): void => {
          const gatingBool = value === 'yes' ? true : false;
          updateSimulation({ variables: { siteGroupId: fieldsId, gating: gatingBool } });
        }}
      />
    ),
    fourDct: (
      <ROToggleButtons
        id="treatment4DCT"
        fieldlabel={'4DCT'}
        options={BOOLEAN_OPTIONS}
        value={setYesNo(fourDct ? fourDct.value : '')}
        handleChange={(value): void => {
          const fourDctBool = value === 'yes' ? true : false;
          updateSimulation({ variables: { siteGroupId: fieldsId, fourDct: fourDctBool } });
        }}
      />
    ),

    dibh: (
      <ROToggleButtons
        id="treatmentDIBH"
        fieldlabel={'DIBH'}
        options={BOOLEAN_OPTIONS}
        value={setYesNo(dibh ? dibh.value : '')}
        handleChange={(value): void => {
          const dibhBool = value === 'yes' ? true : false;
          updateSimulation({ variables: { siteGroupId: fieldsId, dibh: dibhBool } });
        }}
        required
        error={!isFieldValid(dibh)}
      />
    ),
    breathHold: (
      <ROToggleButtons
        id="treatmentBreathHold"
        fieldlabel={'Breath Hold'}
        options={BREADTH_HOLD_OPTIONS}
        value={breathHold ? breathHold.value : ''}
        handleChange={(value): void => {
          updateSimulation({ variables: { siteGroupId: fieldsId, breathHold: value } });
        }}
        required={!isManualSite}
        error={!isFieldValid(breathHold)}
      />
    ),
    bolus: (
      <BolusForm
        siteGroup={siteGroup}
        listValuesLookup={listValuesLookup}
        version={careplan?.careplan?.featureVersion}
        options={bolusOptions}
        updateSimulation={updateSimulation}
        fieldsId={fieldsId}
        SIM_NAMES={SIM_NAMES}
        bolusLocationValues={bolusLocationValues}
        setTextFields={setTextFields}
        isFieldValid={isFieldValid}
        bolusThicknessCustomValues={bolusThicknessCustomValues}></BolusForm>
    ),
  };

  const allConditionals = Object.keys(conditionalFieldsJSX);
  const hiddenConditionals: string[] = [];
  const visibleConditionals: string[] = [];

  Object.keys(conditionalFieldsJSX).forEach((fieldName) => {
    if (manualSiteConditionalFields) {
      manualSiteConditionalFields.includes(fieldName)
        ? visibleConditionals.push(fieldName)
        : hiddenConditionals.push(fieldName);
    }
  });

  const hasHiddenConditionalsWithValues =
    hiddenConditionals.filter((fieldName) => {
      if (siteGroup[fieldName]) return siteGroup[fieldName].value;
      return false;
    }).length > 0;

  const renderConditionalFields = (fieldNameArr: string[]) => {
    return fieldNameArr.map((fieldName: string): JSX.Element | null => {
      const isVisible = isFieldVisible(siteGroup[fieldName]);
      if (isVisible) {
        return (
          <Fragment key={fieldName}>{conditionalFieldsJSX[fieldName as keyof typeof conditionalFieldsJSX]}</Fragment>
        );
      } else {
        return null;
      }
    });
  };

  if (loading || careplanDataLoading)
    return (
      <LoadingSpinner loadingText={'Loading Simulation'} subtitle={'Please wait while we set things up for you'} />
    );

  return (
    <Fragment>
      <div className="main-container-parent-wrapper">
        <div className="main-container-wrapper">
          <CareplanBanners
            data={careplan}
            refetchQueriesList={[
              { query: LOAD_SITEGROUP_INFO, variables: { careplanId } },
              { query: GET_CAREPLAN, variables: { id: careplanId } },
            ]}
          />
          <CareplanContainer>
            <div className="page-header">
              <h1 data-test-id="simulation-header">
                {'Simulation'}: {siteNames}
              </h1>
            </div>
            <Box component="form" id="simulationForm" noValidate>
              <ROToggleButtons
                id="treatmentCtSimulation"
                fieldlabel={
                  region && [String(Region.AU), String(Region.US)].includes(region)
                    ? 'CT Simulation'
                    : 'CT Planning scan required'
                }
                options={BOOLEAN_OPTIONS}
                value={setYesNo(ctSimulation ? ctSimulation.value : '')}
                handleChange={(value: string): void => {
                  const ctSimulationBool = value === 'yes';
                  let updateArgs: any = { ctSimulation: ctSimulationBool };
                  // Set the Contrast required to null if the CT sim is false
                  if (!ctSimulationBool) {
                    updateArgs = {
                      ...updateArgs,
                      extentOfCt: '',
                      extentDefined: '',
                      contrastRequired: null,
                      contrastType: '',
                      bloodTestLast3Months: '',
                      egfrGt30ml: '',
                    };
                  }

                  updateSimulation({ variables: { siteGroupId: fieldsId, ...updateArgs } });
                }}
              />
              {contrastRequired?.isShown && ctSimulation?.value !== 'false' && (
                <ROToggleButtons
                  id={SIM_NAMES.CONTRAST_REQUIRED}
                  fieldlabel="Contrast Required"
                  options={BOOLEAN_OPTIONS}
                  value={setYesNo(contrastRequired.value)}
                  warning={isFieldValid(contrastRequired) && contrastRequired.value === 'true'}
                  helperText={isFieldValid(contrastRequired) ? 'Please check for contrast allergy' : undefined}
                  handleChange={(value: string): void => {
                    if (value !== fieldValues.yes) {
                      updateSimulation({
                        variables: {
                          siteGroupId: fieldsId,
                          contrastRequired: false,
                          contrastType: '',
                          bloodTestLast3Months: '',
                          egfrGt30ml: '',
                        },
                      });
                    } else {
                      updateSimulation({
                        variables: { siteGroupId: fieldsId, contrastRequired: true },
                      });
                    }
                  }}
                  required
                  error={!isFieldValid(contrastRequired)}
                />
              )}
              {CurrentAppConfig.SimulationPage.ContrastRequiredChildFields &&
                setYesNo(contrastRequired?.value) === fieldValues.yes && (
                  <ROToggleButtons
                    id="treatmentContrastType"
                    fieldlabel="Type of Contrast"
                    options={CONTRAST_TYPES}
                    value={contrastType?.value}
                    handleChange={(value: string): void => {
                      if (value !== fieldValues.iv) {
                        updateSimulation({
                          variables: {
                            siteGroupId: fieldsId,
                            contrastType: value,
                            bloodTestLast3Months: '',
                            egfrGt30ml: '',
                          },
                        });
                      } else {
                        updateSimulation({
                          variables: { siteGroupId: fieldsId, contrastType: value },
                        });
                      }
                    }}
                    required
                    error={!isFieldValid(contrastType)}
                  />
                )}
              {contrastType?.value === fieldValues.iv && (
                <ROToggleButtons
                  id="treatmentBloodTestQuestion"
                  fieldlabel={'Blood Test Within Last 3 Months'}
                  options={BLOOD_TEST_LAST_3_MONTHS}
                  value={bloodTestLast3Months?.value}
                  handleChange={(value: string): void => {
                    updateSimulation({
                      variables: {
                        siteGroupId: fieldsId,
                        bloodTestLast3Months: value,
                        ...(value === fieldValues.bookingRequired && { egfrGt30ml: '' }), // Reset egfrGt30ml field
                      },
                    });
                  }}
                  required
                  error={!isFieldValid(bloodTestLast3Months)}
                />
              )}
              {bloodTestLast3Months?.value === fieldValues.yes && (
                <ROToggleButtons
                  id="treatmentEgfrQuestion"
                  fieldlabel={'Is eGFR > 30ml/min?'}
                  options={EGFR_GT_30}
                  value={egfrGt30ml?.value}
                  warning={isFieldValid(egfrGt30ml) && egfrGt30ml?.value === fieldValues.no}
                  helperText={
                    isFieldValid(egfrGt30ml)
                      ? "High risk of contrast induced acute kidney injury in patients with eGFR <30mL/min. By selecting 'No' you are consenting for IV contrast to proceed, otherwise select 'No' to Contrast Required."
                      : undefined
                  }
                  handleChange={(value: string): void => {
                    updateSimulation({
                      variables: { siteGroupId: fieldsId, egfrGt30ml: value },
                    });
                  }}
                  required
                  error={!isFieldValid(egfrGt30ml)}
                />
              )}
              <ROToggleButtons
                id="treatmentRORequired"
                fieldlabel={CurrentAppConfig.QuestionLabel.RoRequired}
                options={BOOLEAN_OPTIONS}
                value={setYesNo(roRequired ? roRequired.value : CurrentAppConfig.DefaultValue.treatmentRORequired)}
                handleChange={(value: string): void => {
                  const roRequiredBool = value === 'yes' ? true : false;
                  updateSimulation({ variables: { siteGroupId: fieldsId, roRequired: roRequiredBool } });
                }}
                required
                error={!isFieldValid(roRequired)}
              />
              <ROAutocomplete
                id="treatmentPatientPosition"
                fieldlabel="Patient Position"
                options={patientPositionOptions}
                value={getOptionByValue(
                  bodyRegionOptions.map((data) => ({ label: data.label, value: data.value })),
                  patientPosition ? patientPosition.value : '',
                )}
                onChange={(option: SelectOptionType | string): void => {
                  const value = typeof option === 'string' ? option : option?.value;
                  const posSetup = readOnlyChildField.includes(value)
                    ? getDefaultValues(DEFAULT_FIELDS.POSITION_SETUP)
                    : '';
                  setTextFields(SIM_NAMES.POSITION_SETUP, fieldsId, positionSetupValues, posSetup);
                  updateSimulation({
                    variables: { siteGroupId: fieldsId, patientPosition: value, positionSetup: posSetup },
                  });
                }}
              />
              {positionSetupShow && (
                <ROTextField
                  id={SIM_NAMES.POSITION_SETUP}
                  fieldlabel={'Position Setup'}
                  value={listValuesLookup(positionSetupValues, fieldsId, positionSetup)}
                  disabled={positionSetupReadOnly}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    setTextFields(SIM_NAMES.POSITION_SETUP, fieldsId, positionSetupValues, event.target.value);
                  }}
                  onBlur={(): void => {
                    const valueSet = positionSetupValues.find((x: any) => x.id === fieldsId);
                    if (valueSet) {
                      updateSimulation({ variables: { siteGroupId: fieldsId, positionSetup: valueSet.value } });
                    }
                  }}
                  multiline
                  maxRows={4}
                />
              )}
              {ctSimulation && ctSimulation.value !== 'false' && (
                <ROAutocomplete
                  id="treatmentExtentOfCt"
                  fieldlabel="Extent of CT"
                  options={extentCTOptions}
                  value={getOptionByValue(
                    extentCTOptions.map((data) => ({ label: data.label, value: data.value })),
                    extentOfCt ? extentOfCt.value : '',
                  )}
                  onChange={(option: SelectOptionType | string): void => {
                    const value = typeof option === 'string' ? option : option?.value;
                    const exDefined = readOnlyChildField.includes(value)
                      ? getDefaultValues(DEFAULT_FIELDS.EXTENT_DEFINED)
                      : '';
                    setTextFields(SIM_NAMES.EXTENT_DEFINED, fieldsId, extentOfCtValues, exDefined);
                    updateSimulation({
                      variables: { siteGroupId: fieldsId, extentOfCt: value, extentDefined: exDefined },
                    });
                  }}
                />
              )}
              {ctSimulation?.value !== 'false' && extentCtSetupShow && (
                <ROTextField
                  id={SIM_NAMES.EXTENT_DEFINED}
                  fieldlabel={'Extent Defined'}
                  value={listValuesLookup(extentOfCtValues, fieldsId, extentDefined ? extentDefined.value : '')}
                  disabled={extentCtSetupReadOnly}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    setTextFields(SIM_NAMES.EXTENT_DEFINED, fieldsId, extentOfCtValues, event.target.value);
                  }}
                  onBlur={(): void => {
                    const valueSet = extentOfCtValues.find((x: any) => x.id === fieldsId);
                    if (valueSet) {
                      updateSimulation({ variables: { siteGroupId: fieldsId, extentDefined: valueSet.value } });
                    }
                  }}
                  multiline
                  maxRows={4}
                  required
                />
              )}
              {isManualSite ? renderConditionalFields(visibleConditionals) : renderConditionalFields(allConditionals)}
              <div className="simulation-hr"></div>
              <ROTextField
                id={SIM_NAMES.EXTRA_CT_MARKERS}
                fieldlabel={'Additional Markers'}
                value={listValuesLookup(extraCtMarkersValues, fieldsId, extraCtMarkers)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                  setTextFields(SIM_NAMES.EXTRA_CT_MARKERS, fieldsId, extraCtMarkersValues, event.target.value);
                }}
                onBlur={(): void => {
                  const valueSet = extraCtMarkersValues.find((x: any) => x.id === fieldsId);
                  if (valueSet) {
                    updateSimulation({ variables: { siteGroupId: fieldsId, extraCtMarkers: valueSet.value } });
                  }
                }}
              />
              <ROTextField
                id={SIM_NAMES.ADDITIONAL_SIM_INFO}
                fieldlabel="Additional Sim Info"
                value={listValuesLookup(
                  additionalSimInfoValues,
                  fieldsId,
                  additionalSimInfo ? additionalSimInfo.value : '',
                )}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                  setTextFields(SIM_NAMES.ADDITIONAL_SIM_INFO, fieldsId, additionalSimInfoValues, event.target.value);
                }}
                onBlur={(): void => {
                  const valueSet = additionalSimInfoValues.find((x: any) => x.id === fieldsId);
                  if (valueSet) {
                    updateSimulation({ variables: { siteGroupId: fieldsId, additionalSimInfo: valueSet.value } });
                  }
                }}
                multiline
                maxRows={4}
              />
              {isManualSite && hiddenConditionals.length ? (
                <ManualSiteAccordion
                  title={{ open: 'Clear & close conditional fields', closed: 'See more conditional fields' }}
                  onOpen={handleManualSiteAccordionOpen}
                  onClose={handleManualSiteAccordionClose}
                  open={hasHiddenConditionalsWithValues}>
                  {renderConditionalFields(hiddenConditionals)}
                </ManualSiteAccordion>
              ) : null}
            </Box>
            {region !== Region.UK && <Divider />}

            {region === Region.UK && <SectionTitle>ADDITIONAL IMAGING TO AID PLANNING</SectionTitle>}
            <div className="imaging-list-container">
              {imagingList.map(
                (
                  {
                    imagingId,
                    imagingRequirement,
                    showImageOptions,
                    imagingInstructions,
                    imagingScanExamination,
                    imagingBodyRegion,
                    imagingContrastRequired,
                    imagingRadiologyClinic,
                    imagingAnatomicalMatchPriority,
                  },
                  imagingIdx: number,
                ): JSX.Element => (
                  <div
                    className="imaging-container"
                    key={`imaging_${siteGroupIdx}_${imagingIdx}`}
                    id={imagingIdx === imagingList.length - 1 ? 'lastImaging' : ''}>
                    <div className="added-imaging-sequence-header">
                      <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '18px', lineHeight: '24px' }}>
                        <span>
                          {'Imaging #'}
                          {imagingIdx + 1}
                        </span>
                      </Typography>
                      {imagingId && (
                        <IconButton
                          sx={{
                            marginLeft: '10px',
                          }}
                          onClick={() => {
                            deleteImaging({ variables: { imagingId: imagingId } });
                          }}>
                          <RemoveCircleOutline />
                        </IconButton>
                      )}
                    </div>
                    {region === Region.UK ? (
                      <ROToggleButtons
                        id="imagingRequirement"
                        fieldlabel="Retrieve Imaging"
                        options={BOOLEAN_OPTIONS}
                        value={setYesNo(imagingRequirement ? 'true' : 'false')}
                        handleChange={(value): void => {
                          const retrieveValue = value === 'yes' ? 'retrieveImaging' : '';
                          if (imagingId) {
                            updateImaging({ variables: { imagingId: imagingId, imagingRequirement: retrieveValue } });
                          } else {
                            createNewImaging({
                              variables: { siteGroupId: parseInt(fieldsId), imagingRequirement: retrieveValue },
                            });
                          }
                        }}
                      />
                    ) : (
                      <ROToggleButtons
                        id="imagingRequirement"
                        fieldlabel="Imaging Requirement"
                        options={IMAGING_REQUIREMENT_OPTIONS}
                        value={imagingRequirement ? imagingRequirement.toString() : ''}
                        handleChange={(value: string): void => {
                          if (imagingId) {
                            updateImaging({ variables: { imagingId: imagingId, imagingRequirement: value } });
                          } else {
                            createNewImaging({
                              variables: { siteGroupId: parseInt(fieldsId), imagingRequirement: value },
                            });
                          }
                        }}
                      />
                    )}
                    {showImageOptions && (
                      <Fragment>
                        <ROAutocomplete
                          id={`imagingScanExamination-${imagingIdx}`}
                          fieldlabel="Scan Examination"
                          options={scanExaminationOptions}
                          value={getOptionByValue(
                            scanExaminationOptions.map((data) => ({ label: data.label, value: data.value })),
                            imagingScanExamination ? imagingScanExamination : '',
                          )}
                          onChange={(option: SelectOptionType | string): void => {
                            const value = typeof option === 'string' ? option : option?.value;
                            updateImaging({
                              variables: {
                                imagingId: imagingId,
                                scanExamination: value || '',
                              },
                            });
                          }}
                        />
                        <ROAutocomplete
                          id={`imagingBodyRegion-${imagingIdx}`}
                          fieldlabel="Body Region"
                          options={bodyRegionOptions}
                          value={getOptionByValue(
                            bodyRegionOptions.map((data) => ({ label: data.label, value: data.value })),
                            imagingBodyRegion ? imagingBodyRegion : '',
                          )}
                          onChange={(option: SelectOptionType | string): void => {
                            const value = typeof option === 'string' ? option : option?.value;
                            updateImaging({
                              variables: {
                                imagingId: imagingId,
                                bodyRegion: value || '',
                              },
                            });
                          }}
                        />
                        {imagingRequirement === 'bookImaging' && (
                          <ROToggleButtons
                            id={`imagingContrastRequired-${imagingIdx}`}
                            fieldlabel="Contrast Required"
                            options={BOOLEAN_OPTIONS}
                            value={setYesNo(String(imagingContrastRequired))}
                            handleChange={(value): void => {
                              const contrastBool = value === 'yes' ? true : false;
                              updateImaging({ variables: { imagingId: imagingId, contrastRequired: contrastBool } });
                            }}
                          />
                        )}
                        <ROTextField
                          id={`${IMAGING_NAMES.RADIOLOGY_CLINIC}-${imagingIdx}`}
                          fieldlabel={region === Region.UK ? 'Hospital/Radiology provider' : 'Radiology Clinic'}
                          value={listValuesLookup(imagingRadiologyClinicValues, imagingId, imagingRadiologyClinic)}
                          placeholder={
                            region === Region.UK
                              ? 'Please enter Hospital/Radiology provider'
                              : 'Please enter Radiology Clinic...'
                          }
                          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                            setTextFields(
                              IMAGING_NAMES.RADIOLOGY_CLINIC,
                              imagingId,
                              imagingRadiologyClinicValues,
                              event.target.value,
                            );
                          }}
                          onBlur={(): void => {
                            const valueSet = imagingRadiologyClinicValues.find((x: any) => x.id === imagingId);
                            if (valueSet) {
                              updateImaging({ variables: { imagingId: imagingId, radiologyClinic: valueSet.value } });
                            }
                          }}
                        />
                        <ROTextField
                          id={`${IMAGING_NAMES.ANATOMICA_MATCH}-${imagingIdx}`}
                          fieldlabel={'Anatomical Match Priority'}
                          value={listValuesLookup(
                            imagingAnatomicalMatchPriorityValues,
                            imagingId,
                            imagingAnatomicalMatchPriority,
                          )}
                          placeholder={'Please enter matching criteria...'}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                            setTextFields(
                              IMAGING_NAMES.ANATOMICA_MATCH,
                              imagingId,
                              imagingAnatomicalMatchPriorityValues,
                              event.target.value,
                            );
                          }}
                          onBlur={(): void => {
                            const valueSet = imagingAnatomicalMatchPriorityValues.find((x: any) => x.id === imagingId);
                            if (valueSet) {
                              updateImaging({
                                variables: { imagingId: imagingId, anatomicalMatchPriority: valueSet.value },
                              });
                            }
                          }}
                        />

                        <ROTextField
                          id={`${IMAGING_NAMES.INSTRUCTIONS}-${imagingIdx}`}
                          fieldlabel={'Instructions'}
                          value={listValuesLookup(imagingInstructionsValues, imagingId, imagingInstructions)}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                            setTextFields(
                              IMAGING_NAMES.INSTRUCTIONS,
                              imagingId,
                              imagingInstructionsValues,
                              event.target.value,
                            );
                          }}
                          onBlur={(): void => {
                            const valueSet = imagingInstructionsValues.find((x: any) => x.id === imagingId);
                            if (valueSet) {
                              updateImaging({ variables: { imagingId, instructions: valueSet.value } });
                            }
                          }}
                          multiline
                          maxRows={4}
                        />
                      </Fragment>
                    )}
                    <div className="simulation-hr" style={{ marginBottom: '3rem', marginTop: '3rem' }} />
                  </div>
                ),
              )}
            </div>
            <Button
              variant="outlined"
              startIcon={<AddCircleOutline color="primary" />}
              onClick={(): void => {
                createNewImaging({ variables: { siteGroupId: parseInt(fieldsId) } });
              }}
              style={{
                border: `2px solid ${theme.palette.primary.main}`,
                borderRadius: '20px',
                borderWidth: '2px',
                fontWeight: 'bold',
              }}
              sx={{
                backgroundColor: theme.palette.primary.contrastText,
              }}>
              Add imaging request
            </Button>
          </CareplanContainer>
          <ComposedModal
            isOpen={requestSimModalOpen}
            dismissFunction={() => setRequestSimModalOpen(!requestSimModalOpen)}
            headerText={`${careplanOnSim ? 'Re-request' : 'Request'} Simulation & Exit`}
            secondaryRightButton={{
              buttonText: 'Cancel',
              onClick: () => setRequestSimModalOpen(!requestSimModalOpen),
            }}
            primaryRightButton={{
              buttonText: careplanOnSim ? 'Re-request simulation' : 'Request simulation',
              disabled: submitLoading,
              onClick: () => {
                setSubmittingPrescription(!submittingPrescription);
                setSubmitLoading(true);
                submitPrescription({ variables: { id: careplanId, status: 'Simulation' } }).then(
                  (): void => {
                    setSubmitLoading(false);
                    history.push(`/radiation/patient/${patientId}/summary`);
                  },
                  (error: any): void => {
                    logger.error(error);
                  },
                );
              },
            }}
            width={'536px'}>
            <Typography variant="body1">
              Please ensure simulation details are correct for ALL treatment groups before proceeding. You will be
              redirected back to the patient summary page after submission.
            </Typography>
          </ComposedModal>
          <ROPatientCarePlanPageFooter
            onReset={(): void => {
              pressedContinue = false;
              if (cpotTriggered) {
                setShowModal(true);
              } else {
                clearImaging(siteGroup);
                resetSiteGroup({ variables: { careplanId, siteGroupId: parseInt(fieldsId) } });
              }
            }}
            resetText={cpotTriggered ? 'Cancel Change' : 'Reset to templated values'}
            {...footerOptions}
            onBack={() => {
              onBack();
            }}
            onNext={() => {
              onNext();
            }}
            nextDisabled={loading}
            backDisabled={loading || (cpotTriggered && siteGroupIdx === 0)}
            additionalLeftTooltip={
              disableSimButton &&
              displaySimButton &&
              'Request simulation is available on the last treatment group. Please ensure details are correct before proceeding.'
            }
          />
        </div>
        <ROPatientCarePathSidePanel />
        <ModalCpotCancel
          isOpen={showModal}
          submitFunction={() => cancelCpot()}
          dismissFunction={() => setShowModal(false)}
        />
      </div>
    </Fragment>
  );
};

export default ROPatientTreatmentSimulationPage;
