// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import { Redirect } from 'react-router-dom';
import { styled } from '@mui/system';

import { ROSideNav } from 'op-components';
import { useErrorModalContext } from 'op-contexts';
import { RoleType } from 'op-enums';
import { GET_USER_PROFILE } from 'op-graphql/queries';
import { generateGreetingText } from 'op-utils/helpers';

import { Card, LoadingSpinner } from 'shared-components/components';
import ROProfilePhoto from 'shared-components/components/ROProfilePhoto/ROProfilePhoto';

import './Dashboard.scss';
import DashboardPanel from './DashboardPanel';

export const StyledTile = styled(Card)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  margin-left: 5px;
`;

const SideUserDetailWrapper = styled('div')`
  width: 100%;
  padding: 16px;
  background-color: white;
`;

const StyleDivWrapper = styled('div')`
  padding-top: 16px;
`;

const MODashboard = (): JSX.Element => {
  const { setError } = useErrorModalContext();

  const { data: userProfile, loading: userLoading, error: userError } = useQuery(GET_USER_PROFILE);

  const isMo = userProfile.user.isMo;

  useEffect(() => {
    if (userError || !userProfile.user.hasStaffId) return setError('ACCOUNT');
  }, [userError, userProfile, userProfile.user.hasStaffId, setError]);

  const hasCareplanViewerRole = userProfile.user.primaryRole === RoleType.CAREPLANVIEWER;

  if (!isMo && !hasCareplanViewerRole) {
    return <Redirect to="/error" />;
  }

  if (userLoading) return <LoadingSpinner />;

  const userName = `Dr. ${userProfile.user.name}`;
  const greeting = generateGreetingText();

  return (
    <div key="RO-dashboard" className="RO-home-wrapper">
      <ROSideNav>
        <SideUserDetailWrapper>
          <div className="side-date-wrapper">{moment().format('ddd, DD MMMM YYYY')}</div>
          <div className="side-user-photo">
            <ROProfilePhoto />
          </div>
          <div className="side-greeting-wrapper">{greeting}</div>
          <div className="side-username-wrapper">{userName}</div>
          <hr />
        </SideUserDetailWrapper>
        <StyleDivWrapper />
      </ROSideNav>
      <DashboardPanel />
    </div>
  );
};

export default MODashboard;
