import './DocumentViewer.scss';

import { Document, HighlightFeature, HighlightWKTs, Page } from './types';
import { multiPolygon, polygon } from '@turf/helpers';
import React, { useEffect, useState, useContext } from 'react';
import { Pagination } from '@mui/material';

import $ from 'jquery';
import PageImageViewer from './PageImageViewer';
import { wktToGeoJSON } from '@terraformer/wkt';
import { ClinexContext } from './context';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { GenericModal } from 'op-components';
import CircularProgress from '@mui/material/CircularProgress';

const getPages = (documents: Document[]) => {
  const pages = [];
  for (const document of Object.values(documents)) {
    for (const i in document.page_image_urls) {
      pages.push({
        page_index: parseInt(i),
        document_id: document.document_id,
        report_types: document.report_types,
        max_report_date: document.max_report_date,
        url: document.page_image_urls[i],
        width: document.page_dimensions[i][0],
        img: null,
      });
    }
  }
  return pages;
};

const getHighlightFeatures = (highlightWKTs: string[]): HighlightFeature[] => {
  const features = [];
  for (const wkt of highlightWKTs) {
    if (wkt !== 'GEOMETRYCOLLECTION EMPTY') {
      const primitive = wktToGeoJSON(wkt);
      if (primitive.type === 'Polygon') {
        features.push(polygon(primitive.coordinates));
      } else if (primitive.type === 'MultiPolygon') {
        features.push(multiPolygon(primitive.coordinates));
      }
    }
  }
  return features;
};

interface DocumentViewerProps {
  documents: Document[];
  highlights: HighlightWKTs;
  loading: boolean;
}

const DocumentViewer = ({ documents, highlights, loading }: DocumentViewerProps): JSX.Element | null => {
  const {
    pageIndex,
    setPageIndex,
    indexLock,
    setIndexLock,
    pages,
    setPages,
    setFieldSelected,
    clinexModalOpen,
    setClinexModalOpen,
  } = useContext(ClinexContext);
  const [highlightFeatures, setHighlightFeatures] = useState<HighlightFeature[]>([]);
  const [width, setWidth] = useState(1200);
  const windowSize = useWindowSize();
  const isSmallDevice = windowSize.width < 1900;

  const setWidthOnResize = () => {
    const containerWidth = $('.page-view .page-image-viewer').width();
    if (containerWidth !== undefined) {
      setWidth(containerWidth);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setWidthOnResize();
    }, 1000);

    $(window).on('resize', setWidthOnResize);
    return () => {
      $(window).off('resize', setWidthOnResize);
    };
  });

  useEffect(() => {
    // Jump to first page and preload images if documents changed
    const pages = getPages(documents);
    setPages(pages);
    setPageIndex(0);
    setIndexLock(false);
    pages.forEach((page: Page) => {
      page.img = new Image();
      page.img.src = page.url;
    });
    $(window).trigger('resize');
  }, [documents]);

  useEffect(() => {
    setIndexLock(false);
  }, [highlights]);

  useEffect(() => {
    if (pageIndex >= pages.length) {
      return;
    }

    const documentId = pages[pageIndex].document_id;
    let features: HighlightFeature[] = [];
    if (documentId in highlights) {
      features = getHighlightFeatures(highlights[documentId][pages[pageIndex].page_index]);
    }
    setHighlightFeatures(features);

    // Jump to first page with highlighting
    if (!indexLock && features.length === 0) {
      for (const p in pages) {
        const page = pages[p];
        if (page.document_id in highlights) {
          features = getHighlightFeatures(highlights[page.document_id][page.page_index]);
          if (features.length > 0) {
            setPageIndex(parseInt(p));
            break;
          }
        }
      }
    }
  }, [pages, pageIndex, indexLock, highlights]);

  if (loading || pageIndex >= pages.length) {
    return (
      <div className="card card-default">
        <div className="card-body extracted-labels" style={{ textAlign: 'center' }}>
          <CircularProgress color="primary" />
        </div>
      </div>
    );
  }

  const handlePagination = (event: React.ChangeEvent<unknown>, pageIndex: number) => {
    setPageIndex(pageIndex - 1);
    setIndexLock(true);
  };

  const ImageViewer = (
    <div className="card card-default page-view">
      <div className="page-image-viewer">
        <PageImageViewer
          img={pages[pageIndex].img}
          features={highlightFeatures}
          width={width}
          imgWidth={pages[pageIndex].width}
          loading={loading}
        />
      </div>
    </div>
  );

  const pagination = (
    <Pagination
      sx={{ alignSelf: 'center', margin: '8px 0' }}
      count={pages.length}
      color="primary"
      page={pageIndex + 1}
      onChange={handlePagination}
    />
  );

  if (!isSmallDevice) {
    return (
      <div className="desktop-view-container">
        {pagination}
        <div className="card-default page-view desktop-view">
          <div className="page-image-viewer">
            <PageImageViewer
              img={pages[pageIndex].img}
              features={highlightFeatures}
              width={width}
              imgWidth={pages[pageIndex].width}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <GenericModal
      headerElement={pagination}
      bodyElements={ImageViewer}
      isOpen={clinexModalOpen}
      dismissFunction={() => {
        setClinexModalOpen(false);
        setFieldSelected(null);
      }}
    />
  );
};

export default DocumentViewer;
