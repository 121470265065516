import { Stack, Typography, useTheme } from '@mui/material';
import { Check } from '@mui/icons-material';
import { RoCarePlanPages } from 'shared-components/enums';
import { CAREPLAN_PAGES } from '../Constants';
import { CurrentAppConfig } from '../AppConfig';
import ROCareplanContext from '../CareplanContext';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

export interface Step {
  idx: number;
  text: string;
  page: RoCarePlanPages;
}

interface Props {
  patientId: string;
  careplanId: string;
  diagnosisId?: string;
  steps: Step[];
  isExistingDiagnosis: boolean;
  previousUrl: string;
  disabled: boolean;
  statusText: string;
}
const isCpot = (status: string) => status === 'cpot';
const showSpecialPhysicsConsultPage = CurrentAppConfig.SpecialPhysicsConsultVisible;

export const pageMap: { [key: string]: any } = {
  [RoCarePlanPages.DX_LANDING_PG]: CAREPLAN_PAGES.CREATE,
  [RoCarePlanPages.DX_NEW_DIAGNOSIS_PG]: CAREPLAN_PAGES.DIAGNOSIS_A,
  [RoCarePlanPages.DX_SELECT_DIAGNOSIS_PG]: CAREPLAN_PAGES.DIAGNOSIS_B,
  [RoCarePlanPages.DX_STAGING_PG]: CAREPLAN_PAGES.STAGING,
  [RoCarePlanPages.DX_INTAKE_PG]: CAREPLAN_PAGES.INTAKE,
  [RoCarePlanPages.CP_TREATMENT_INFO_PG]: CAREPLAN_PAGES.TREATMENT_INFO,
  [RoCarePlanPages.CP_CAREPLAN_SELECTION_PG]: CAREPLAN_PAGES.TEMPLATE_SELECTION,
  [RoCarePlanPages.CP_SITESETUP_PG]: CAREPLAN_PAGES.SITESETUP,
  [RoCarePlanPages.TM_SIMULATION_PG]: CAREPLAN_PAGES.SIMULATION,
  [RoCarePlanPages.TM_PRESCRIPTION_PG]: CAREPLAN_PAGES.PRESCRIPTION,
  [RoCarePlanPages.TM_VOLUMING_PG]: CAREPLAN_PAGES.VOLUMING,
  [RoCarePlanPages.TM_SUBMISSION_PG]: CAREPLAN_PAGES.SUBMISSION,
};

const mapPathToStep = (path: string): string => {
  switch (path) {
    case CAREPLAN_PAGES.CREATE:
      return RoCarePlanPages.DX_LANDING_PG;
    case CAREPLAN_PAGES.DIAGNOSIS_A:
      return RoCarePlanPages.DX_NEW_DIAGNOSIS_PG;
    case CAREPLAN_PAGES.DIAGNOSIS_B:
      return RoCarePlanPages.DX_SELECT_DIAGNOSIS_PG;
    case CAREPLAN_PAGES.STAGING:
      return RoCarePlanPages.DX_STAGING_PG;
    case CAREPLAN_PAGES.INTAKE:
      return RoCarePlanPages.DX_INTAKE_PG;
    case CAREPLAN_PAGES.TREATMENT_INFO:
      return RoCarePlanPages.CP_TREATMENT_INFO_PG;
    case CAREPLAN_PAGES.TEMPLATE_SELECTION:
      return RoCarePlanPages.CP_CAREPLAN_SELECTION_PG;
    case CAREPLAN_PAGES.SITESETUP:
      return RoCarePlanPages.CP_SITESETUP_PG;
    case CAREPLAN_PAGES.SIMULATION:
      return RoCarePlanPages.TM_SIMULATION_PG;
    case CAREPLAN_PAGES.VOLUMING:
      return RoCarePlanPages.TM_VOLUMING_PG;
    case CAREPLAN_PAGES.PRESCRIPTION:
      return RoCarePlanPages.TM_PRESCRIPTION_PG;
    case CAREPLAN_PAGES.SPECIAL_PHYSICS_CONSULT:
      return RoCarePlanPages.TM_SPECIAL_PHYSICS_CONSULT;
    case CAREPLAN_PAGES.SUBMISSION:
      return showSpecialPhysicsConsultPage ? RoCarePlanPages.TM_SUBMISSION_PG_US : RoCarePlanPages.TM_SUBMISSION_PG;
    default:
      return RoCarePlanPages.DX_LANDING_PG;
  }
};

const ROStepperSteps = ({
  steps,
  previousUrl,
  disabled,
  isExistingDiagnosis,
  patientId,
  careplanId,
  diagnosisId,
  statusText,
}: Props): JSX.Element => {
  const previousUrlIsIntake = previousUrl.endsWith(CAREPLAN_PAGES.INTAKE);
  const previousUrlIsStaging = previousUrl.endsWith(CAREPLAN_PAGES.STAGING);
  const context = useContext(ROCareplanContext);
  const theme = useTheme();
  const history = useHistory();

  const generatePageLink = (urlSuffix: string) => {
    const url = `/radiation/patient/${patientId}/careplan/${careplanId}/`;

    if (urlSuffix === CAREPLAN_PAGES.CREATE) {
      return `${url}diagnosis/${diagnosisId}/${CAREPLAN_PAGES.DIAGNOSIS_A}/${CAREPLAN_PAGES.DIAGNOSIS_B}`;
    }
    if ([CAREPLAN_PAGES.STAGING, CAREPLAN_PAGES.INTAKE].includes(urlSuffix)) {
      return `${url}diagnosis/${diagnosisId}/${urlSuffix}`;
    }
    return url + urlSuffix;
  };

  return (
    <>
      {steps.map((step: Step, index: number): JSX.Element => {
        const pathMatch = Object.values(CAREPLAN_PAGES)
          .map((page) => (window.location.pathname.includes(page) ? page : null))
          .filter(Boolean);

        const stepId = mapPathToStep(pathMatch[0] || '1');
        const stepIndex = Number(isNaN(Number(stepId)) ? stepId.substring(0, stepId.length - 1) : stepId);

        const isCurrent = stepIndex === step.idx;
        const isComplete = stepIndex ? stepIndex > step.idx : false;

        const isDisabled = () => {
          // Determine if we should disable the intake or staging page, using the same logic as back navigation on the
          // treatment info page
          const disableForIntake = step.page === RoCarePlanPages.DX_INTAKE_PG && !previousUrlIsIntake;
          const disableForStaging =
            step.page === RoCarePlanPages.DX_STAGING_PG && !(previousUrlIsIntake || previousUrlIsStaging);
          const disableForDiagnosis = isExistingDiagnosis && step.idx <= Number(RoCarePlanPages.DX_INTAKE_PG);
          const disableForCpot = isCpot(statusText) && step.idx < Number(RoCarePlanPages.TM_SIMULATION_PG);
          return (
            stepIndex < step.idx ||
            disableForCpot ||
            disableForDiagnosis ||
            disableForIntake ||
            disableForStaging ||
            disabled
          );
        };
        const disableStep = isDisabled();

        return (
          <div
            style={{
              background: isCurrent ? 'white' : 'transparent',
              borderLeft: `5px solid ${isCurrent ? theme.palette.primary.main : 'transparent'}`,
              cursor: disableStep ? 'not-allowed' : 'pointer',
            }}
            data-testid={`nav-step-${step.page}${disableStep ? '-disabled' : ''}`}
            key={step.idx}
            onClick={(): void => {
              if (disableStep || isCurrent) return;
              const pageMapModified: { [key: string]: any } = {
                ...pageMap,
                ...(showSpecialPhysicsConsultPage
                  ? {
                      [RoCarePlanPages.TM_SPECIAL_PHYSICS_CONSULT]: CAREPLAN_PAGES.SPECIAL_PHYSICS_CONSULT,
                      [RoCarePlanPages.TM_SUBMISSION_PG_US]: CAREPLAN_PAGES.SUBMISSION,
                    }
                  : {}),
              };
              if (step.page in pageMapModified) {
                const pageLink = generatePageLink({ ...pageMapModified }[step.page]);
                context.setPage(step.page);
                history.push(pageLink);
              }
            }}>
            <Stack direction="row" alignItems="center" gap="8px" sx={{ padding: '8px' }}>
              {/* This absolute treat is here to add the vertical line to connect the steps */}
              <Stack
                sx={{
                  borderLeft: `1px solid ${index === 0 ? 'transparent' : theme.palette.secondary.main}`,
                  marginLeft: '12px',
                  marginRight: '-32px',
                  paddingLeft: '12px',
                  marginTop: '-16px',
                  height: '40px',
                }}></Stack>
              {isComplete ? (
                <Check
                  color="primary"
                  data-testid={`nav-step-${step.page}-complete`}
                  sx={{
                    backgroundColor: theme.palette.primary.contrastText,
                    backgroundSize: '24px',
                    border: `2px solid ${theme.palette.primary.main}`,
                    borderRadius: '16px',
                    color: theme.palette.primary.main,
                  }}
                />
              ) : (
                <div
                  style={{
                    background: isCurrent ? theme.palette.primary.main : theme.palette.secondary.light,
                    color: isCurrent ? theme.palette.primary.contrastText : theme.palette.secondary.dark,
                    width: '24px',
                    height: '24px',
                    border: `1px solid ${theme.palette.secondary.main}`,
                    fontSize: '15px',
                    fontWeight: 'bold',
                    lineHeight: '22px',
                    borderRadius: '100%',
                    textAlign: 'center',
                  }}>
                  {stepIndex <= step.idx ? step.idx : null}
                </div>
              )}
              <Typography
                variant="body2"
                data-testid={`nav-step-text-${step.page}${isCurrent ? '-current' : ''}`}
                style={{
                  color: isCurrent ? theme.palette.primary.main : theme.palette.text.primary,
                  fontWeight: '600',
                }}>
                {step.text}
              </Typography>
            </Stack>
          </div>
        );
      })}
    </>
  );
};

export default ROStepperSteps;
