// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'gc-ui';
import { ButtonType } from 'shared-components/enums';
import { GCButton } from 'shared-components/components/FormFields';

import './ModalSaveExit.scss';
import Typography from '@mui/material/Typography';

interface Props {
  isOpen: boolean;
  dismissFunction: () => void;
  exitForm: () => void;
  exitText: string;
}

class ModalSaveExit extends Component<Props> {
  public render(): JSX.Element {
    const { isOpen, dismissFunction, exitForm, exitText } = this.props;
    return (
      <Modal
        open={isOpen}
        onClose={dismissFunction}
        className="modal-gc save-exit-modal"
        PaperProps={{ style: { borderRadius: '24px' } }}>
        <ModalHeader id="save-exit-header" toggle={dismissFunction}>
          <Typography variant="h5">Are you sure you want to exit the form without submitting?</Typography>
        </ModalHeader>
        <ModalBody className="modal-content" sx={{ width: '100% !important' }}>
          <Typography variant="body1" lineHeight="1.4rem" fontSize="16px" className="modal-save-exit-body">
            All changes have been successfully saved and you will be redirected to the Main Menu
          </Typography>
          <div
            className="modal-save-exit-buttons"
            style={{ display: 'flex', flexDirection: 'row', paddingTop: '24px', gap: '8px', justifyContent: 'center' }}>
            <GCButton
              name="cancel"
              title="Cancel"
              onClick={(e): void => {
                e.preventDefault();
                dismissFunction();
              }}
              type={ButtonType.WHITE}
            />
            <GCButton
              name="exitForm"
              title={exitText}
              onClick={(e): void => {
                e.preventDefault();
                exitForm();
              }}
              type={ButtonType.GREEN}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default ModalSaveExit;
