import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { useFormikContext, FormikProps } from 'formik';

import { ReviewBox } from 'shared-components/components';
import { getRemSize } from 'shared-components/StyledComponents/functions';

import { ActiveQcl } from '../interfaces';
import { sharedContent } from '../constants';

interface Props {
  activeQcls: ActiveQcl[];
  customQclRules: (fieldName: string, values: object) => boolean | void;
  formContent: object;
  isLocked: boolean;
}

const QclName = styled('div')`
  font-size: ${getRemSize(13)};
  line-height: 15px;
  margin-bottom: 20px;
  margin-left: 27px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const { title, noQclsGenerated, qclGenerationText } = sharedContent.reviewAndSubmit;

const ReviewQcl = ({ activeQcls, formContent, customQclRules, isLocked }: Props): JSX.Element => {
  const { values, setFieldValue }: FormikProps<any> = useFormikContext();
  const excludedQcls = activeQcls.map((qcl) => qcl.name);

  const mapQclGeneration = (): void => {
    const generatedQcls: string[] = [];
    const possibleQcls = Object.values(formContent)
      .filter((item) => item.hasOwnProperty('integrationQCL'))
      .reduce((arr, item) => {
        Object.entries(item.integrationQCL).forEach((entry: object) => {
          //@ts-ignore
          const fieldName = entry[0];
          //@ts-ignore
          const qcls: string[] = entry[1].includes('|') ? entry[1].split('|') : [entry[1]];
          qcls.forEach((value) => arr.push({ fieldName, value }));
        });
        return arr;
      }, []);

    possibleQcls.forEach((item: any) => {
      if (
        customQclRules(item.fieldName, values) &&
        !generatedQcls.includes(item.value) &&
        !excludedQcls.includes(item.value)
      ) {
        generatedQcls.push(item.value);
      }
    });

    setFieldValue('generatedQCLs', generatedQcls);
  };

  useEffect(() => {
    mapQclGeneration();
  }, []);

  const subtitle = !values.isAmendment ? qclGenerationText(false) : qclGenerationText(true);
  // HTML from form context
  return (
    <ReviewBox title={title} subtitle={subtitle} isLocked={isLocked}>
      {values.hasOwnProperty('generatedQCLs') && values.generatedQCLs.length ? (
        values.generatedQCLs.map((item: string) => (
          <QclName key={item} aria-label={`review-${item}`} dangerouslySetInnerHTML={{ __html: item }} />
        ))
      ) : (
        <QclName key={noQclsGenerated}>{noQclsGenerated}</QclName>
      )}
    </ReviewBox>
  );
};

export default ReviewQcl;
