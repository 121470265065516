import * as Yup from 'yup';

export const intakeValidation = Yup.object({
  intake: Yup.object()
    .shape({
      breast: Yup.object()
        .shape({
          grade: Yup.string().required(),
          er: Yup.string().required(),
          pgr: Yup.string().required(),
          her2: Yup.string().required(),
        })
        .nullable()
        .default(null),
      lung: Yup.object()
        .shape({
          ecogPerformanceStatus: Yup.string().required(),
          weightLoss: Yup.string().required(),
          smokingHistory: Yup.string().required(),
          egfr: Yup.string().required(),
          alk: Yup.string().required(),
          pdl1: Yup.string().required(),
        })
        .nullable()
        .default(null),
      lymphoma: Yup.object()
        .shape({
          stage: Yup.string().required(),
          aOrBSymptoms: Yup.string().required(),
          extranodal: Yup.string().required(),
          bulkDisease: Yup.string().required(),
          ecogPerformanceStatus: Yup.string().required(),
        })
        .nullable()
        .default(null),
      headAndNeck: Yup.object()
        .shape({
          p16: Yup.string().required(),
          smokingHistory: Yup.string().required(),
        })
        .nullable()
        .default(null),
    })
    .nullable()
    .default(null),
});
