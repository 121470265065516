import React from 'react';

export interface UnselectedCheckboxProps {
  id?: string;
  className?: string;
}

const UnselectedCheckbox = ({ id, className }: UnselectedCheckboxProps) => (
  <svg
    className={className}
    id={id}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect id="inner-rect" x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" />
    <rect id="outer-rect" x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#CFCDC9" />
  </svg>
);

export default UnselectedCheckbox;
