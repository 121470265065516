import React from 'react';
import { useMutation } from '@apollo/client';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'gc-ui';
import { DISCARD_OUTCOME, GET_OUTCOMES_BY_DIAGNOSIS } from './Queries';
import { OutcomeInterface } from './Interfaces';
import { WarningOutlined as WarningOutlinedIcon } from '@mui/icons-material';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  outcome: OutcomeInterface;
  handleBack: () => void;
  patientId: string;
}

const DiscardModal = ({ open, setOpen, outcome, handleBack, patientId }: Props): JSX.Element => {
  const [saveOutcomeData] = useMutation(DISCARD_OUTCOME, {
    onCompleted: () => {
      setOpen(false);
      handleBack();
    },
    refetchQueries: [{ query: GET_OUTCOMES_BY_DIAGNOSIS, variables: { patientId: patientId } }],
  });

  const handleClose = () => {
    setOpen(false);
  };

  const discard = () => {
    saveOutcomeData({
      variables: {
        outcomeId: outcome.id,
      },
    });
  };

  return (
    <Modal width="small" open={open} onClose={handleClose}>
      <ModalHeader>
        Discard Outcome <WarningOutlinedIcon color="warning" />
      </ModalHeader>
      <ModalBody>
        You are about to discard the following outcome: {outcome?.diagnosisName}
        <br />
        {outcome?.timePoint} years follow up
      </ModalBody>
      <ModalFooter>
        <Button mode="outlined" size="auto" onClick={handleClose} data-cy={'dosecancel-discard-outcome'}>
          Back
        </Button>

        <Button color="error" onClick={discard} data-cy={'confirm-discard-outcome'}>
          Discard Outcome
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default DiscardModal;
