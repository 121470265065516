// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

interface ListDataType {
  count: number;
  label: string;
}

interface Props {
  listData: ListDataType[];
}

const ChartTumour = (props: Props) => {
  const [barData, setBarData] = useState([]);

  useEffect(() => {
    if (props?.listData) {
      const data: any = [['Diagnosis', 'Count']];
      let otherCount = 0;
      props.listData.forEach((element, index) => {
        if (index < 5) {
          data.push([element.label, element.count]);
        } else {
          otherCount += element.count;
        }
      });

      data.push(['Other', otherCount]);

      setBarData(data);
    }
  }, [props.listData]);

  const options = {
    legend: { position: 'bottom', textStyle: { fontSize: 10 } },
    pieSliceText: 'label',
    chartArea: {
      top: -50,
      height: '65%',
      width: '84%',
    },
    enableInteractivity: true,
    animation: {
      duration: 1000,
      easing: 'out',
    },
    colors: [
      '#081E43',
      '#AAA1FD',
      '#3EC490',
      '#FFBD5B',
      '#FB6A76',
      '#65ABE8',
      '#394B68',
      '#226BC0',
      '#007840',
      '#989898',
    ],
  };

  return (
    <div style={{ margin: '0 auto', overflow: 'hidden' }}>
      {barData && (
        <>
          <h2 style={{ textAlign: 'center', paddingTop: '20px' }}>Prescription Overview</h2>
          <Chart chartType="PieChart" data={barData} options={options} height="600px" />
        </>
      )}
    </div>
  );
};

export default ChartTumour;
