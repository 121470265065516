import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import StyledSwitch from 'shared-components/components/UIFormComponents/Switch';
import { styled } from '@mui/system';
import useInterval from 'shared-components/utils/useInterval';
import ROPatientProfilePopup from './ROPatientProfilePopup';
import { DOCTOR_APPOINTMENT_QUERY } from './RODailyAppointmentQueries';
import { DateTimeWidget } from './DateTimeWidget';
import { StyledTile } from '../Dashboard';
import { useErrorModalContext, UserContext } from 'op-contexts';
import { CurrentAppConfig } from '../../Careplan/AppConfig';
import dayjs from 'dayjs';
import { Tooltip, Stack, IconButton } from '@mui/material';
import { BaseDatePicker } from 'shared-components/components/FormFields';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';

interface StyledTableRowProps {
  readonly $highlight: boolean;
  readonly $highlightTop?: boolean;
  readonly $highlightBottom?: boolean;
  readonly ref: any;
}

export const StyledRow = styled(Stack)`
  align-items: center;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;

export const StyledTimeAppCol = styled(Stack)`
  display: flex;
  align-items: center;
`;

export const StyledDateInfo = styled('div')`
  font-weight: 700;
  font-size: 23px;
  padding-right: 16px;
`;

export const StyledTotalAppointments = styled('div')`
  font-weight: 400;
  background: $[theme.palette.secondary.light};
  border-radius: 128px;
  padding: 2px 16px;
  width: 185px;
  height: 24px;
  line-height: 24px;
  align: center;
`;

export const StyledShowCancelledAppointments = styled('div')`
  font-weight: 500;
  font-size: 16px;
  padding: 0px 16px;
  height: 24px;
  line-height: 24px;
  align: center;
`;

export const StyledTable = styled('table')`
  border-collapse: separate;
  width: 100%;
  & td {
    padding: 7px 12px;
    border: 1px solid lightgray;
    text-align: left;
  }

  & thead {
    & th {
      position: sticky;
      top: 0;
      text-align: left;
      padding: 8px 8px;
      z-index: 1000;
      border: 1px solid lightgray;
      background-color: ${(props) => props.theme.palette.secondary.light};
    }

    & th:first-child {
      text-align: right;
    }
  }
`;

export const StyledTableBody = styled('tbody')`
  color: black;
`;

export const HighlightArrow = styled('div')`
  float: left;
  border-right: solid 7px transparent;
  border-left: solid 7px transparent;
  border-top: solid 7px ${(props) => props.theme.palette.warning.main};
  transform: rotate(-90deg);
  width: 12px;
  height: 12px;
  z-index: 0;
`;

export const StyledTableRow = styled('tr')<StyledTableRowProps>`
  & td:first-child {
    border-left: ${(props) =>
      props.$highlight ? `2px solid ${props.theme.palette.warning.main}` : '1px solid lightgray'};
    text-align: right;
  }
  & td:last-child {
    border-right: ${(props) =>
      props.$highlight ? `2px solid ${props.theme.palette.warning.main}` : '1px solid lightgray'};
  }
  & td {
    border-top: ${(props) =>
      props.$highlight && props.$highlightTop
        ? `2px solid ${props.theme.palette.warning.main}`
        : '1px solid lightgray'};
    border-bottom: ${(props) =>
      props.$highlight && props.$highlightBottom
        ? `2px solid ${props.theme.palette.warning.main}`
        : '1px solid lightgray'};
    cursor: default;
    background: white;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  top: 12px !important;
  font-family: Arial, sans-serif;
  pointer-events: none;

  .arrow {
    border: none !important;
    z-index: 1181;
    overflow: hidden;
    padding: 0;
    height: 32px;
    width: 32px;
    top: -32px;
    transform: none !important;

    &::before {
      height: 16px;
      position: absolute;
      width: 16px;
      background-color: white;
      transform: rotate(45deg);
      box-shadow: 0px 4px 8px rgba(113, 110, 106, 0.5);
      left: 16px;
      bottom: -8px;
    }
  }

  .tooltip-inner {
    background: transparent;
  }
`;

export const StyledPatientName = styled(Link)`
  color: ${(props) => props.theme.palette.info.main} !important;
  text-decoration: underline !important;
`;

export const StyledTableWrapper = styled('div')`
  height: calc(100vh - 280px) !important;
  display: block;
  overflow-y: auto;
  scroll-padding: 32px 0px;
  width: 100%;
`;

interface OverlapStyleProps {
  readonly $overlap: boolean;
}

export const OverlappedStyle = styled('td')<OverlapStyleProps>`
  background: ${(props) => (props.$overlap ? props.theme.palette.warning.light : 'white')} !important;
`;

export const TodayButton = styled('div')`
  width: 55px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  margin-right: 16px;
`;

const filterCancelledAppointments = (appointments: any, showCancelledAppointments: boolean): any =>
  appointments.filter(
    (appointment: any) =>
      appointment != null &&
      (CurrentAppConfig.RadiationDashboard.isCancelledAppointmentsToggleEnabled && !showCancelledAppointments
        ? appointment.startTime && appointment.statusAbbreviation !== 'X'
        : appointment.startTime),
  );

const prepareDailyScheduleData = (
  startDate: any,
  data: any,
  practitionerTimezone: string,
  showCancelledAppointments: boolean,
): any => {
  // Prepare schedule from 6am to 6pm in 15min interval
  const startTime = moment(startDate).tz(practitionerTimezone).startOf('date').add(6, 'hours').subtract(15, 'minutes');
  const defaultDailySchedule = [...new Array(48)].map(() => {
    return {
      startTime: startTime.add(15, 'minutes').clone(),
      duration: 15,
      id: '',
      patient: '',
      activity: '',
      description: '',
      status: '',
      comment: '',
      department: '',
      location: '',
      overlap: false,
      showTime: true,
    };
  });

  if (!data || data.doctorAppointmentsByDate.length === 0) return defaultDailySchedule;

  const dailySchedule = defaultDailySchedule;
  const filteredAppointments = filterCancelledAppointments(data.doctorAppointmentsByDate, showCancelledAppointments);
  const primedDoctorAppointments = filteredAppointments.map((appointment: any) => {
    return {
      id: appointment.id,
      startTime: moment(appointment.startTime).tz(practitionerTimezone),
      duration: appointment.duration ? appointment.duration / 60 : 0, // seconds to minutes
      patient: appointment.patient,
      activity: appointment.activity,
      description: appointment.description,
      status: appointment.statusAbbreviation,
      comment: appointment.comment,
      department: appointment.department,
      location: appointment.location,
      overlap: false,
      showTime: true,
    };
  });

  primedDoctorAppointments.forEach((appointment: any) => {
    //find the index in the dailySchedule and replace/insert this appointment
    const index = dailySchedule.findIndex((existingAppointment: any): any => {
      return (
        moment(appointment.startTime).tz(practitionerTimezone) <=
        moment(existingAppointment.startTime).tz(practitionerTimezone)
      );
    });

    if (index === -1) {
      dailySchedule.push(appointment);
      return;
    }
    // if they are same and no appointment exist then replace dummy
    if (appointment.startTime.isSame(dailySchedule[index].startTime)) {
      if (!dailySchedule[index].id) {
        dailySchedule.splice(index, 1, appointment);
      } else {
        appointment.overlap = true;
        dailySchedule[index].overlap = true;
        dailySchedule[index].showTime = false;
        dailySchedule.splice(index, 0, appointment);
      }
    } else {
      if (index !== 0) {
        // appointment doesn't start at the 15' boundary
        dailySchedule[index - 1].duration = moment
          .duration(appointment.startTime.diff(dailySchedule[index - 1].startTime))
          .asMinutes();
      }
      // insert new record
      dailySchedule.splice(index, 0, appointment);
    }
  });
  // update overlapping appointment
  const maxIndex = dailySchedule.length;
  dailySchedule.forEach((appointment: any, index: number) => {
    let innerIndex = index + 1;
    const totalAppointment = appointment.startTime.clone().add(appointment.duration, 'minutes');
    while (innerIndex < maxIndex && totalAppointment > dailySchedule[innerIndex].startTime) {
      if (dailySchedule[innerIndex].id) appointment.overlap = true;
      dailySchedule[innerIndex].overlap = true;
      innerIndex += 1;
    }
  });
  return dailySchedule;
};

const renderTime = (schedule: any, highlight: boolean): JSX.Element => {
  const time = schedule['startTime'];
  const formatString = time.minutes() === 0 ? 'hA' : 'h:mm';

  return (
    <OverlappedStyle data-testid="appointment-time" $overlap={schedule['overlap']}>
      <Stack direction="row">
        {highlight && <HighlightArrow />}
        {schedule['showTime'] && time.format(formatString)}
      </Stack>
    </OverlappedStyle>
  );
};

const renderDuration = (schedule: any): JSX.Element => {
  return (
    <OverlappedStyle $overlap={schedule['overlap']}>
      {schedule['id'] ? `${schedule['duration']}'` : '-'}
    </OverlappedStyle>
  );
};

const renderPatient = (schedule: any): JSX.Element => {
  const id = schedule['patient'] ? schedule['patient']['id'] : 0;
  if (!id) return <td>-</td>;
  return (
    <td>
      <Tooltip
        title={
          <React.Fragment>
            <StyledTooltip id="patient-profile" title="">
              <ROPatientProfilePopup {...schedule['patient']} />
            </StyledTooltip>
          </React.Fragment>
        }
        placement="bottom-start">
        <StyledPatientName to={`/radiation/patient/${id}/summary`}>{schedule['patient']['fullName']}</StyledPatientName>
      </Tooltip>
    </td>
  );
};

const ScheduleTableBody = (props: any): JSX.Element => {
  const [currentTime, setCurrentTime] = useState(moment());
  const scheduleList = props.schedule;
  const myRef = useRef<HTMLDivElement>(null);
  const firstAppointment = scheduleList.find((schedule: any) => schedule.id);
  const currentlyRunningAppointments = scheduleList.filter(
    (schedule: any): any =>
      schedule.startTime <= currentTime && schedule.startTime.clone().add(schedule.duration, 'minutes') > currentTime,
  );
  // If there are no currently running appointments not even the placeholders then need
  // to find the closest/last finished
  if (
    currentlyRunningAppointments &&
    currentlyRunningAppointments.filter((schedule: any): boolean => !(schedule.overlap && !schedule.id)).length === 0
  ) {
    const foundIndex = scheduleList.findIndex((schedule: any): boolean => schedule === currentlyRunningAppointments[0]);
    if (foundIndex !== -1 && foundIndex !== 0) {
      currentlyRunningAppointments.push(scheduleList[foundIndex - 1]);
    }
  }

  const updatedAppointmentList = scheduleList
    .map((schedule: any) => {
      // is the schedule overlapping with currently running appointment
      const overlapList = currentlyRunningAppointments.filter(
        (appointment: any) =>
          schedule === appointment ||
          (schedule.startTime >= appointment.startTime &&
            schedule.startTime < appointment.startTime.clone().add(appointment.duration, 'minutes')),
      );
      return { ...schedule, currentAppointmentOverlap: overlapList && overlapList.length !== 0 };
    })
    .filter((schedule: any) => !(schedule.overlap && !schedule.id)); // Remove overlapping placeholder

  useInterval(() => {
    setCurrentTime(moment());
  }, 10000);
  useEffect(() => {
    if (myRef && myRef.current) {
      myRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [myRef.current]);
  return (
    <StyledTableBody data-testid="daily-appointments-table">
      {updatedAppointmentList.map((schedule: any, index: number): JSX.Element => {
        const prevSchedule = index !== 0 ? updatedAppointmentList[index - 1] : undefined;
        const nextSchedule =
          index !== updatedAppointmentList.length - 1 ? updatedAppointmentList[index + 1] : undefined;
        const currentEndSchedule = schedule.startTime.clone().add(schedule.duration, 'minutes');
        const timeHighlight = currentTime >= schedule.startTime && currentTime < currentEndSchedule && schedule.id;
        const rowHighlight = schedule.currentAppointmentOverlap;
        // If next appointment is overlapping then don't draw bottom line
        // If previous appointment is overlapping then don't draw top line
        const rowBottomHighlight =
          schedule.currentAppointmentOverlap && !(nextSchedule && nextSchedule.currentAppointmentOverlap);
        const rowTopHighlight =
          schedule.currentAppointmentOverlap &&
          !(typeof prevSchedule !== 'undefined' && prevSchedule.currentAppointmentOverlap);
        return (
          <StyledTableRow
            key={`appointment-row-${index}`}
            ref={
              (firstAppointment && firstAppointment.id === schedule.id) || (!firstAppointment && index === 0)
                ? myRef
                : (null as any)
            }
            $highlight={rowHighlight}
            $highlightBottom={rowBottomHighlight}
            $highlightTop={rowTopHighlight}>
            {renderTime(schedule, timeHighlight)}
            {renderDuration(schedule)}
            {renderPatient(schedule)}
            <td>{schedule['description'] ? schedule['description'] : '-'} </td>
            <td>{schedule['status'] && schedule['status'] !== 'None' ? schedule['status'] : '-'}</td>
            <td>{schedule['comment'] ? schedule['comment'] : '-'}</td>
            <td>{schedule['department'] ? schedule['department']['alias'] : '-'}</td>
            <td>{schedule['location'] ? schedule['location']['name'] : '-'}</td>
          </StyledTableRow>
        );
      })}
    </StyledTableBody>
  );
};

const RODailyAppointment = (): JSX.Element => {
  const { setError } = useErrorModalContext();
  const userContext = useContext(UserContext);
  const practitionerTimezone = userContext.state.timezone;
  const [showCancelledAppointments, setShowCancelledAppointments] = useState(false);
  const [totalAppointmentCount, setTotalAppointmentCount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(moment());

  useEffect(() => {
    setSelectedDate(moment());
  }, [practitionerTimezone]);

  const { data, error, refetch } = useQuery(DOCTOR_APPOINTMENT_QUERY, {
    variables: {
      startDate: moment(selectedDate).tz(practitionerTimezone).startOf('day').toDate(),
      endDate: moment(selectedDate).tz(practitionerTimezone).endOf('day').toDate(),
    },
    skip: !selectedDate,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) return setError();
  }, [error]);

  useEffect(() => {
    if (data && data.doctorAppointmentsByDate && data.doctorAppointmentsByDate.length > 0) {
      const filteredAppointments = filterCancelledAppointments(
        data.doctorAppointmentsByDate,
        showCancelledAppointments,
      );
      setTotalAppointmentCount(filteredAppointments.length);
    }
  }, [data, showCancelledAppointments]);

  const header = [
    { name: 'Time', variable: 'startTime', width: '5%' },
    { name: 'Dur', variable: 'duration', width: '5%' },
    { name: 'Patient', variable: 'patient', width: '15%' },
    { name: 'Activity', variable: 'activity', width: '15%' },
    { name: 'Status', variable: 'status', width: '5%' },
    { name: 'Comment', variable: 'comment', width: '30%' },
    { name: 'Department', variable: 'department', width: '10%' },
    { name: 'Location', variable: 'location', width: '15%' },
  ];

  const schedule = prepareDailyScheduleData(
    selectedDate.toDate(),
    data,
    practitionerTimezone,
    showCancelledAppointments,
  );

  useInterval(() => {
    refetch();
  }, 60000);

  const handleShowCancelledAppointments = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setShowCancelledAppointments(event.target.checked);
  };

  return (
    <StyledTile>
      <Stack sx={{ display: 'flex', width: '100%' }}>
        <Stack sx={{ fisplay: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <StyledDateInfo>
            <DateTimeWidget date={selectedDate} practitionerTimezone={practitionerTimezone} />
          </StyledDateInfo>
          <StyledTotalAppointments data-testid="total-daily-appointments">
            Total appointments: {totalAppointmentCount}
          </StyledTotalAppointments>
          <Stack direction="row" alignItems="center" sx={{ marginLeft: 'auto', marginBottom: '6px' }} gap="2px">
            <TodayButton
              onClick={() => {
                setSelectedDate(moment());
              }}>
              Today
            </TodayButton>
            <IconButton
              onClick={() => setSelectedDate(selectedDate ? moment(selectedDate).subtract(1, 'days') : moment())}>
              <ArrowBackIosNew color="primary" />
            </IconButton>
            <BaseDatePicker
              id="dailyAppointmentDatePicker"
              value={dayjs(selectedDate.toString())}
              minDate={dayjs().subtract(5, 'year')}
              maxDate={dayjs().add(5, 'year')}
              onChange={(date, context) => {
                if (context.validationError || !date) return;
                setSelectedDate(moment(date && date.toString()));
              }}
            />
            <IconButton onClick={() => setSelectedDate(selectedDate ? moment(selectedDate).add(1, 'days') : moment())}>
              <ArrowForwardIos color="primary" />
            </IconButton>
          </Stack>
        </Stack>
        {CurrentAppConfig.RadiationDashboard.isCancelledAppointmentsToggleEnabled && (
          <StyledRow>
            <StyledShowCancelledAppointments>Show cancelled appointments</StyledShowCancelledAppointments>
            <StyledSwitch checked={showCancelledAppointments} handleChange={handleShowCancelledAppointments} />
          </StyledRow>
        )}
        <Stack style={{ width: '100%' }}>
          <StyledTableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  {header.map((head, index) => (
                    <th key={`header-${index}`} style={{ width: head['width'] }}>
                      {head['name']}
                    </th>
                  ))}
                </tr>
              </thead>
              <ScheduleTableBody schedule={schedule} />
            </StyledTable>
          </StyledTableWrapper>
        </Stack>
      </Stack>
    </StyledTile>
  );
};

export default RODailyAppointment;
