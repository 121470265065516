import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'gc-ui';
import { Form, Formik, Field, FormikProps } from 'formik';
import { DrugOrderInterface } from 'op-pages/MO/interfaces';
import {
  FormContainer,
  NumberInput,
  SelectInput,
  NumberWithCheckboxInput,
  TextInput,
  SelectOption,
} from './ModalComponents';
import { DoseDay, PatientObservation } from '../interfaces';
import { Grid } from '@mui/material';
import { ADMIN_INSTRUCTIONS, DRUG_CATEGORY, DRUG_ORDER_TYPE, PATIENT_INSTRUCTIONS } from '../../Constants';
import { useMutation } from '@apollo/client';
import useOncologyListData from '../../useOncologyListData';
import { LIST_OPTIONS } from '../../Constants';
import { UPDATE_DOSE_ADJUSTMENT } from '../Queries';
import moment from 'moment';
import { useRouteMatch } from 'react-router';

export interface DoseInfo {
  drugOrder: DrugOrderInterface & { drugName: string };
  doseDay: DoseDay;
  cycle: any;
}

interface PropsType {
  onSubmit?: () => void;
  onClose: () => void;
  open: boolean;
  doseInfo?: DoseInfo;
  observationResults: PatientObservation | null;
  cycleEndDate: moment.Moment | null;
  careplanEndDate: moment.Moment | null;
  daysPerCycle: number;
  numberOfCycles: number;
}

interface DoseFields {
  ogDoseBasis: number;
  ogDose: number;
  calculatedDose: number;
  dosePercentage: number;
  prescribedDose: string;
  applyTo: string;
  reason: string;
  instructions: string;
}

enum ApplyToValues {
  REST_OF_CAREPLAN = 'REST_OF_CAREPLAN',
  THIS_CYCLE = 'THIS_CYCLE',
  SELECTED_DAY = 'SELECTED_DAY',
}

const applyToOptions: SelectOption[] = [
  { label: 'Rest of careplan', value: ApplyToValues.REST_OF_CAREPLAN },
  { label: 'This cycle', value: ApplyToValues.THIS_CYCLE },
  { label: 'Selected day', value: ApplyToValues.SELECTED_DAY },
];

const roundToTwo = (num: number) => {
  return Math.round(num * 100 + Number.EPSILON) / 100;
};

const DoseEditModal = ({
  onClose,
  open,
  doseInfo,
  observationResults,
  cycleEndDate,
  careplanEndDate,
  daysPerCycle,
  numberOfCycles,
}: PropsType): JSX.Element => {
  const [busy, setBusy] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [onHold, setOnHold] = useState(false);
  const [dosePercentageChanged, setDosePercentageChanged] = useState(false);
  const [prescribedDoseChanged, setPrescribedDoseChanged] = useState(false);
  const [reasonChanged, setReasonChanged] = useState(false);
  const [applyToChanged, setApplyToChanged] = useState(false);
  const [instructionsChanged, setInstructionsChanged] = useState(false);
  const formRef = useRef<FormikProps<DoseFields>>(null);
  const dose = doseInfo?.doseDay.dose;
  const initialDosePercentage = Math.round(dose?.doseAdjustment?.dosePercentage ?? 100);
  const [actualDosePercentage, setActualDosePercentage] = useState(initialDosePercentage);
  const [updateDoseAdjustment] = useMutation(UPDATE_DOSE_ADJUSTMENT, {
    refetchQueries: ['moCareplanData', 'latestCycles'],
  });
  const match = useRouteMatch<any>();
  const { id: patientId } = match.params;

  const referenceData = useOncologyListData([LIST_OPTIONS.DOSE_CHANGE_REASON], patientId);
  const doseChangeReason = useMemo(() => {
    if (!referenceData || !referenceData.moDoseChangeReason) return [];
    return [...referenceData.moDoseChangeReason]
      .sort((a: any, b: any) => a.listWeight - b.listWeight)
      .map((moDoseChangeReason) => {
        return { label: moDoseChangeReason.option, value: moDoseChangeReason.option };
      });
  }, [referenceData]);

  useEffect(() => {
    if (open) {
      setBusy(false);
      setDosePercentageChanged(false);
      setPrescribedDoseChanged(false);
      setReasonChanged(false);
      setApplyToChanged(false);
      setValidateOnChange(false);
      setInstructionsChanged(false);
      setActualDosePercentage(initialDosePercentage);
    }
  }, [open, initialDosePercentage]);

  useEffect(() => {
    if (formRef.current === null) return;

    if (onHold) {
      formRef.current?.setFieldValue('dosePercentage', 0);
    } else {
      if (dose?.doseAdjustment?.dosePercentage === 0) {
        formRef.current.setFieldValue(
          'dosePercentage',
          Math.round(dose?.previousDoseAdjustment?.dosePercentage || 100),
        );
      } else {
        formRef.current.setFieldValue('dosePercentage', Math.round(dose?.doseAdjustment?.dosePercentage || 100));
      }
    }
  }, [onHold]);

  useEffect(() => {
    if (dose?.doseAdjustment?.dosePercentage === 0) {
      setOnHold(true);
    } else {
      setOnHold(false);
    }
  }, [doseInfo]);

  if (doseInfo === undefined || dose === undefined) {
    return <></>;
  }

  const adjustPrescribedDose = (doseValue: number, percentage: number): string => {
    // this func is mirrored in the backend
    const prescribedDose = (percentage / 100) * doseValue;
    const drugOrder = doseInfo.drugOrder;
    const precision = drugOrder.prescribedDosePrecision;
    if (drugOrder.roundToNearest) {
      const res = drugOrder.roundToNearest * Math.round(prescribedDose / drugOrder.roundToNearest);
      return res.toFixed(precision);
    } else {
      return prescribedDose.toFixed(precision);
    }
  };

  const originalDrugOrderinstructions =
    doseInfo.drugOrder?.type === DRUG_ORDER_TYPE.IN_HOUSE_TREATMENT
      ? doseInfo.drugOrder?.administrationInstructions
      : doseInfo.drugOrder?.patientInstructions;

  const drugOrderInstruction = dose.doseAdjustment
    ? dose.doseAdjustment.instructions
    : dose.drugOrder.instructions || originalDrugOrderinstructions;

  const initialPrescribedDose = adjustPrescribedDose(dose.doseValue, dose.doseAdjustment?.dosePercentage ?? 100);

  const initialValues: DoseFields = {
    ogDoseBasis: 0,
    ogDose: dose.doseValue || 0,
    calculatedDose: 0,
    dosePercentage: initialDosePercentage,
    prescribedDose: dose.prescribedDose || initialPrescribedDose,
    applyTo: dose.doseAdjustment?.applyTo || ApplyToValues.REST_OF_CAREPLAN,
    reason: dose.doseAdjustment?.reasonForChange || '',
    instructions: drugOrderInstruction,
  };

  const handleSubmit = () => {
    if (formRef.current?.values === undefined) {
      return;
    }
    formRef.current.validateForm().then(() => {
      if (formRef.current?.isValid) {
        const values = formRef.current.values;
        // recalculate percentage because rounding might have thrown it off
        const unroundedDosePercentage = (parseFloat(values.prescribedDose) / values.ogDose) * 100;
        updateDoseAdjustment({
          variables: {
            drugOrderId: dose.drugOrder.id,
            prescribedDoseValue: values.prescribedDose.toString(),
            dosePercentage: unroundedDosePercentage,
            reasonForChange: values.reason,
            applyTo: values.applyTo,
            day: doseInfo.doseDay.day,
            cycle: doseInfo.cycle,
            instructions: values.instructions,
          },
        }).then(() => {
          onClose();
        });
      } else {
        setValidateOnChange(true);
      }
    });
  };

  const handleDosePercentageChange = (dosePercentage: number, ogDose: number, setFieldValue: any) => {
    const adjustedDose = adjustPrescribedDose(ogDose, dosePercentage);
    setFieldValue('prescribedDose', adjustedDose);
    const actualPercentage = Math.round((parseFloat(adjustedDose) / ogDose) * 100);
    setActualDosePercentage(actualPercentage);
    setDosePercentageChanged(actualPercentage !== initialValues.dosePercentage);
    setPrescribedDoseChanged(false);
  };

  const handleClose = () => {
    setValidateOnChange(false);
    onClose();
  };

  const drugOrder = doseInfo.drugOrder;
  const day = doseInfo.doseDay.day;
  const cycle = doseInfo.cycle;
  const date = doseInfo.doseDay.date;
  const instructionsLabel =
    doseInfo.drugOrder?.type === DRUG_ORDER_TYPE.IN_HOUSE_TREATMENT ? ADMIN_INSTRUCTIONS : PATIENT_INSTRUCTIONS;
  const instructionMessage =
    doseInfo.drugOrder?.type === DRUG_ORDER_TYPE.IN_HOUSE_TREATMENT
      ? 'Please review admin instructions before updating'
      : 'Please review patient instructions before updating';

  let calcFormula = (): JSX.Element | null => null;
  if (observationResults !== null) {
    const { bsa, weight, creatinineClearance: cc } = observationResults;
    const calculatedByValue = { BSA: bsa, Weight: weight, 'Creatinine Clearance': cc }[drugOrder.doseCalculatedBy];
    calcFormula = () => {
      switch (drugOrder.doseCalculatedBy) {
        case 'BSA':
        case 'Weight':
          return (
            <>
              {calculatedByValue}
              <b>({drugOrder.doseCalculatedBy})</b> × {drugOrder.doseBasis}
              <b>(Dose basis)</b>
            </>
          );
        case 'Creatinine Clearance':
          return (
            <>
              ({cc}
              <b>(Creatinine clearance)</b> + 25) × {drugOrder.doseBasis}
              <b>(Dose basis)</b>
            </>
          );
        default:
          return null;
      }
    };
  }

  const appliesTo = (applyTo: string) => {
    if (!cycleEndDate || !careplanEndDate) {
      return <></>;
    }
    const endDate = applyTo === ApplyToValues.THIS_CYCLE ? cycleEndDate : careplanEndDate;
    const endCycle = applyTo === ApplyToValues.THIS_CYCLE ? doseInfo.cycle + 1 : numberOfCycles;
    return (
      <>
        Change will apply {applyTo === ApplyToValues.SELECTED_DAY ? 'to' : 'from'}
        {` ${moment(doseInfo.doseDay.date).format('L')}, (C${doseInfo.cycle + 1}D${doseInfo.doseDay.day + 1})`}
        {applyTo !== ApplyToValues.SELECTED_DAY &&
          ` to ${moment(endDate.toDate()).format('L')}, (C${endCycle}D${daysPerCycle})`}
      </>
    );
  };

  const validateReason = (value: string): string | undefined => {
    if (drugOrder.drugCategory !== 'Supporting' && formRef.current?.values.dosePercentage !== 100 && value === '') {
      setValidateOnChange(true);
      return 'This field is required';
    }
  };

  const validateInstructions = (value: string): string | undefined => {
    if (value === '' && !onHold) {
      setValidateOnChange(true);
      return 'This field is required';
    }
  };

  const updateEnabled =
    !busy &&
    ((initialValues.reason !== '' && (reasonChanged || applyToChanged)) ||
      dosePercentageChanged ||
      prescribedDoseChanged ||
      instructionsChanged);

  if (!cycleEndDate || !careplanEndDate) {
    return <></>;
  }

  return (
    <Modal width="large" open={open} onClose={handleClose}>
      <ModalHeader toggle={handleClose}>Update {drugOrder.drugName}</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          innerRef={formRef}
          validateOnChange={validateOnChange}
          validateOnBlur={false}>
          {({ values, initialValues, errors, handleChange, setFieldValue }: FormikProps<DoseFields>): JSX.Element => (
            <Form>
              <FormContainer>
                <Grid item container alignItems="center">
                  <Grid item xs={12}>
                    <b>{`Selected date: ${moment(date).format('L')}, (C${cycle + 1}D${day + 1})`}</b>
                  </Grid>
                  <Grid item xs={12}>
                    Current date: {moment().format('L')}
                  </Grid>
                </Grid>
                {drugOrder.doseIsCalculated ? (
                  <>
                    <Field
                      component={NumberInput}
                      label="Original dose basis"
                      name="ogDoseBasis"
                      units={drugOrder.doseUnit}
                      disabled
                      value={drugOrder.doseBasis}
                    />
                    <Field
                      component={NumberInput}
                      label="Calculated dose"
                      name="calculatedDose"
                      units={drugOrder.doseCalculatedUnits}
                      disabled
                      value={roundToTwo(dose?.doseValue)}
                      info={<span data-cy="calculated-dose-info">{calcFormula()}</span>}
                    />
                  </>
                ) : (
                  <Field
                    component={NumberInput}
                    label="Original dose"
                    name="ogDose"
                    units={drugOrder.doseUnit}
                    disabled
                    value={roundToTwo(dose?.doseValue)}
                  />
                )}
                <Field
                  gridItemProps={
                    drugOrder.drugCategory === 'Supporting' && {
                      number: { style: { display: 'none' } },
                      unit: { style: { display: 'none' } },
                      checkbox: { xs: 12 },
                    }
                  }
                  component={NumberWithCheckboxInput}
                  label={drugOrder.drugCategory === 'Supporting' ? 'Withhold' : 'Actual dose %'}
                  checkboxLabel="Withhold this drug"
                  name="dosePercentage"
                  units="%"
                  min={0}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if ('+-.eE'.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const dosePercentage = parseFloat(e.currentTarget.value);
                    handleChange(e.currentTarget.name)(e);
                    handleDosePercentageChange(dosePercentage, values.ogDose, setFieldValue);
                  }}
                  onCheckboxChange={(_event: any, checked: boolean) => {
                    setOnHold(checked);
                    if (checked) {
                      setFieldValue('instructions', '');
                      const percentage = checked ? 0 : 100;
                      handleDosePercentageChange(percentage, values.ogDose, setFieldValue);
                      setFieldValue('prescribedDose', 0);
                    } else if (initialValues.dosePercentage !== 0) {
                      // unholding after withhholding in the same modal instance
                      setFieldValue('instructions', initialValues.instructions);
                      handleDosePercentageChange(initialValues.dosePercentage, values.ogDose, setFieldValue);
                    } else if (dose.doseAdjustment && dose.previousDoseAdjustment) {
                      // unwithholding a drug that was previously withheld AFTER an adjustment was saved
                      setFieldValue('instructions', dose.previousDoseAdjustment.instructions);
                      handleDosePercentageChange(
                        Math.round(dose.previousDoseAdjustment.dosePercentage),
                        values.ogDose,
                        setFieldValue,
                      );
                    } else {
                      // unwithholding a drug that didn't have any adjustments done prior to withholding
                      setFieldValue('instructions', dose.doseAdjustment.instructions || originalDrugOrderinstructions);
                      handleDosePercentageChange(100, values.ogDose, setFieldValue);
                    }
                  }}
                  checkboxValue={onHold}
                  disabled={onHold}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    if (event.currentTarget.value === '0') {
                      setOnHold(true);
                    }
                  }}
                  warning={
                    actualDosePercentage !== values.dosePercentage &&
                    dosePercentageChanged && (
                      <span data-cy="dose-percentage-warning">
                        The actual dose % will be set as <b>{actualDosePercentage}%</b> due to rounding
                      </span>
                    )
                  }
                />
                <Field
                  component={NumberInput}
                  label="Prescribed dose"
                  name="prescribedDose"
                  units={drugOrder.doseIsCalculated ? drugOrder.doseCalculatedUnits : drugOrder.doseUnit}
                  disabled={onHold}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if ('+-eE'.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // exec the Formik change handler
                    const prescribedDose = parseFloat(e.currentTarget.value);
                    handleChange(e.currentTarget.name)(e);
                    const dosePercentage = Math.round((prescribedDose / values.ogDose) * 100);
                    setActualDosePercentage(dosePercentage);
                    setFieldValue('dosePercentage', dosePercentage);
                    setPrescribedDoseChanged(prescribedDose !== parseFloat(initialValues.prescribedDose));
                    setDosePercentageChanged(false);
                  }}
                  info={
                    drugOrder.drugCategory === DRUG_CATEGORY.TREATMENT ? (
                      <span data-cy="prescribed-dose-info">
                        {!actualDosePercentage || `${actualDosePercentage}% of `}
                        {drugOrder.doseIsCalculated ? (
                          <>
                            {roundToTwo(dose.doseValue)}
                            <b>(Calculated dose)</b>
                          </>
                        ) : (
                          <>
                            {drugOrder.minimumDose}
                            <b>(Original dose)</b>
                          </>
                        )}
                        {!!drugOrder.roundToNearest &&
                          !prescribedDoseChanged &&
                          `: rounded to ${drugOrder.roundToNearest}`}
                      </span>
                    ) : null
                  }
                />
                <Field
                  component={SelectInput}
                  label="Apply to"
                  name="applyTo"
                  options={applyToOptions}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setApplyToChanged(e.target.value !== initialValues.applyTo);
                    handleChange(e.target.name)(e);
                  }}
                  info={<span data-cy="apply-to-info">{appliesTo(values.applyTo)}</span>}
                />
                <Field
                  component={SelectInput}
                  label={`Reason${drugOrder.drugCategory !== 'Supporting' ? '*' : ''}`}
                  name="reason"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setReasonChanged(e.target.value !== initialValues.reason);
                    handleChange(e.target.name)(e);
                  }}
                  options={doseChangeReason}
                  validate={validateReason}
                />
                {drugOrder.solution !== '0' && (
                  <Field
                    component={SelectInput}
                    label={'Diluent'}
                    name="solution"
                    disabled
                    value={drugOrder.solution}
                    options={[{ label: drugOrder.solution, value: drugOrder.solution }]}
                  />
                )}
                <Field
                  component={TextInput}
                  label={instructionsLabel + (values.dosePercentage !== 0 ? '*' : '')}
                  name="instructions"
                  multiline
                  rows={3}
                  value={values.instructions}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setInstructionsChanged(e.target.value !== initialValues.instructions);
                    handleChange(e.target.name)(e);
                  }}
                  warning={
                    values.prescribedDose !== initialValues.prescribedDose &&
                    values.dosePercentage !== 0 &&
                    doseInfo.drugOrder?.type === DRUG_ORDER_TYPE.TAKE_AT_HOME &&
                    !errors.instructions && <span data-cy="instructions-info">{instructionMessage}</span>
                  }
                  disabled={values.dosePercentage === 0}
                  validate={validateInstructions}
                />
              </FormContainer>
            </Form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button mode="outlined" size="auto" onClick={onClose} data-cy={'dose-edit-close-button'}>
          Cancel
        </Button>
        <Button
          mode="contained"
          size="small"
          onClick={formRef.current?.submitForm}
          disabled={!updateEnabled}
          data-test-id={'dose-edit-update-button'}>
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DoseEditModal;
