// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { gql } from '@apollo/client';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';

import { Logger } from 'shared-components/utils';

const logger = new Logger('ExtendLock');

export const EXTEND_LOCK_MUTATION = gql`
  mutation ExtendLock($accessPatientId: ID!) {
    extendLock(accessPatientId: $accessPatientId) {
      success
      patient {
        lock {
          lockedBy
          readOnly
          lockedByName
        }
      }
    }
  }
`;

interface Props extends WithApolloClient<{}> {
  accessPatientId: string;
}

/**
 * Provide heartbeat mutations to the server to let it know the user is still requiring lock on the patient record.
 */
class ExtendLock extends Component<Props> {
  // @ts-ignore
  private interval: NodeJS.Timeout; // Ignoring Typescript error as the componentDidMount() will initialise it and fire it at the same time

  public componentDidMount() {
    logger.debug('componentDidMount', 'Called with props', JSON.stringify(this.props.accessPatientId));
    const { accessPatientId, client } = this.props;

    // Delay a touch for performance
    setTimeout((): void => {
      //@ts-ignore
      client.mutate({
        mutation: EXTEND_LOCK_MUTATION,
        variables: {
          accessPatientId,
        },
      });
    }, 500);

    this.interval = setInterval((): void => {
      //@ts-ignore
      client.mutate({
        mutation: EXTEND_LOCK_MUTATION,
        variables: {
          accessPatientId,
        },
      });
    }, 5000); // Fire every 5 seconds
  }

  public componentWillUnmount() {
    logger.debug('componentWillUnmount', 'Called');
    clearInterval(this.interval);
  }

  public render(): JSX.Element {
    return <Fragment />;
  }
}

// @ts-ignore
const apolloComponent = withApollo<Props>(ExtendLock);
export default apolloComponent;
