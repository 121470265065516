import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { PageTitle } from 'op-components';

import { useParams } from 'react-router-dom';
import {
  HAAccordion,
  AccordionCheckboxItem,
  DateFieldSubform,
  TextFieldSubform,
  AccordionBooleanData,
  AccordionStringData,
  AccordionNumberData,
} from 'op-pages/OP/HealthAssessment/HASharedComponents';
import { UPDATE_HA_ILLNESS } from './HAMedicalHistoryIllnessUSQueries';
import { ListData as BaseListData } from 'shared-components/interfaces';
import {
  ChildField,
  Section,
  CARDIAC,
  CONSTITUTIONAL,
  PAST_MEDICAL_HISTORY,
  PULMONARY,
  NEUROLOGICAL,
  GASTROINTESTINAL,
  GENITOURINARY,
  PSYCHIATRIC,
  EYES_EARS_NOSE_THROAT,
  SKIN,
  HEMATOLOGIC,
  SCREENING,
  BONESJOINTS,
} from './constants';
import {
  CardiacObject,
  PastMedicalHistoryObject,
  ConstitutionalObject,
  EyesEarsNoseThroatObject,
  GastrointestinalObject,
  GenitourinaryObject,
  NeurologicalObject,
  PsychiatricObject,
  PulmonaryObject,
  SkinObject,
  HematologicObject,
  ScreeningObject,
  BonesJointsObject,
} from './interfaces';
import { HA_ILLNESS_QUERY } from '../../HASharedComponents/queries';
import { styled } from '@mui/system';
import { HAIllnessQueryResponse } from 'op-pages/OP/HealthAssessment/HASharedComponents/types/HAIllnessUSTypes';

interface ListData extends BaseListData {
  key: string;
}

const DateHelpText = styled('div')`
{
  width: 322px;
  height: 28px;
  font-family: 'GenesisCare Sans';
  align-items: center;
  color: ${(props) => props.theme.palette.text.primary};
  flex: none;
  margin-top: 8px;
`;

const HAMedicalHistoryIllnessPageUS = (): JSX.Element => {
  const { patientId } = useParams<{ patientId: string }>();

  const [updateHaIllness, { loading }] = useMutation(UPDATE_HA_ILLNESS);

  const { data: illnessData } = useQuery<HAIllnessQueryResponse>(HA_ILLNESS_QUERY, {
    variables: { patientId },
  });

  if (!illnessData) return <></>;

  const healthAssessment = illnessData.healthAssessment;
  const haId = healthAssessment?.id;

  // JSON data
  const pastMedicalHistory: PastMedicalHistoryObject = JSON.parse(healthAssessment?.pastMedicalHistory || '{}');
  const cardiac: CardiacObject = JSON.parse(healthAssessment?.cardiac || '{}');
  const constitutional: ConstitutionalObject = JSON.parse(healthAssessment?.constitutional || '{}');
  const eyesEarsNoseThroat: EyesEarsNoseThroatObject = JSON.parse(healthAssessment?.eyesEarsNoseThroat || '{}');
  const gastrointestinal: GastrointestinalObject = JSON.parse(healthAssessment?.gastrointestinal || '{}');
  const genitourinary: GenitourinaryObject = JSON.parse(healthAssessment?.genitourinary || '{}');
  const neurological: NeurologicalObject = JSON.parse(healthAssessment?.neurological || '{}');
  const psychiatric: PsychiatricObject = JSON.parse(healthAssessment?.psychiatric || '{}');
  const pulmonary: PulmonaryObject = JSON.parse(healthAssessment?.pulmonary || '{}');
  const bonesJoints: BonesJointsObject = JSON.parse(healthAssessment?.bonesJoints || '{}');
  const skin: SkinObject = JSON.parse(healthAssessment?.skin || '{}');
  const hematologic: HematologicObject = JSON.parse(healthAssessment?.hematologic || '{}');
  const screening: ScreeningObject = JSON.parse(healthAssessment?.screening || '{}');

  const updateField = (field: string, value: string) => {
    updateHaIllness({ variables: { id: haId, patientId, [field]: value } });
  };

  const updateJsonField = (
    section: string,
    data: Record<string, AccordionBooleanData | AccordionStringData | AccordionNumberData>,
    option: ListData,
    value: boolean | string,
  ) => {
    // This is a json field, so we have some special json
    data[option.key] = { id: option.id, value, name: option.name };
    updateField(section, JSON.stringify(data));
  };

  const renderDateSubForm = (field: ChildField): JSX.Element => {
    const dayField = `${field.key}RawDay`;
    const monthField = `${field.key}RawMonth`;
    const yearField = `${field.key}RawYear`;
    const subFormProps = {
      dayField,
      monthField,
      yearField,
      dayValue: healthAssessment[dayField as keyof typeof healthAssessment],
      monthValue: parseInt(healthAssessment[monthField as keyof typeof healthAssessment]),
      yearValue: healthAssessment[yearField as keyof typeof healthAssessment],
      label: field.label,
    };
    return (
      <>
        <DateFieldSubform
          onChange={(dateField: string, value: string | number) => updateField(dateField, value.toString())}
          {...subFormProps}
        />
        <DateHelpText>e.g. Jan 31 2007</DateHelpText>
      </>
    );
  };

  const renderChildField = (data: any, section: string, childField: ChildField): JSX.Element => {
    if (childField.inputType === 'date') return renderDateSubForm(childField);
    return (
      <TextFieldSubform
        value={data?.[childField.key]?.value}
        inputName={childField.key}
        onChange={(value: string) =>
          updateJsonField(section, data, { id: '-1', key: childField.key, name: childField.label }, value)
        }
        {...childField}
      />
    );
  };

  const renderAccordion = (
    data: Record<string, AccordionBooleanData | AccordionStringData | AccordionNumberData>,
    section: Section,
    startOpen = false,
  ): JSX.Element => {
    //@ts-ignore
    const options = (illnessData[`${section.key}RefData`] as ListData[]) || [];
    return (
      <HAAccordion
        title={section.title}
        subtitle={section.subtitle}
        badgeValue={getBadgeCount(data)}
        startOpen={startOpen}>
        {options?.map((option: ListData) => {
          const childField = section?.childFields?.find(
            (childField: ChildField) => option.key === childField.parentKey,
          );
          return (
            <AccordionCheckboxItem
              key={option.key}
              label={option.name}
              value={Boolean(data[option.key]?.value)} // This should always be a boolean
              disabled={loading}
              onClick={(value: boolean) => {
                updateJsonField(section.key, data, option, value);
              }}>
              {(childField && renderChildField(data, section.key, childField)) || undefined}
            </AccordionCheckboxItem>
          );
        })}
      </HAAccordion>
    );
  };

  const getBadgeCount = (data: any): number =>
    Object.keys(data).filter((key: string) => data[key]?.value === true).length;
  return (
    <div id="ha-fields" className="ha-medical-history-illness">
      <PageTitle title={'Medical history: 2'} idPrefix="ha" />
      {renderAccordion(screening, SCREENING, true)}

      {renderAccordion(pastMedicalHistory, PAST_MEDICAL_HISTORY)}

      <div className="py-3" style={{ fontWeight: 700, fontSize: '17px', lineHeight: '28px' }}>
        Do you have history of any of the following?
      </div>

      {renderAccordion(cardiac, CARDIAC)}
      {renderAccordion(constitutional, CONSTITUTIONAL)}
      {renderAccordion(eyesEarsNoseThroat, EYES_EARS_NOSE_THROAT)}
      {renderAccordion(gastrointestinal, GASTROINTESTINAL)}
      {renderAccordion(genitourinary, GENITOURINARY)}
      {renderAccordion(neurological, NEUROLOGICAL)}
      {renderAccordion(psychiatric, PSYCHIATRIC)}
      {renderAccordion(pulmonary, PULMONARY)}
      {renderAccordion(bonesJoints, BONESJOINTS)}
      {renderAccordion(hematologic, HEMATOLOGIC)}
      {renderAccordion(skin, SKIN)}
    </div>
  );
};

export default HAMedicalHistoryIllnessPageUS;
