import React from 'react';
import { SearchFilter, SearchMultiSelectField } from 'op-components';
import { GET_USER_DETAILS } from 'op-components';
import { useQuery } from '@apollo/client';
import { SMS_LOGS_SMS_TYPES } from './SmsLogsQueries';
import './smsLogs.scss';

interface ISmsLogsSearchFilter {
  selectedDepartments: string[] | number[];
  setSelectedDepartments(options: any): void;
  selectedStatus: string[] | number[];
  setSelectedStatus(options: any): void;
  selectedSmsType: string[] | number[];
  setSelectedSmsType(options: any): void;
}

interface RawDepartmentData {
  alias: string;
}

interface FormattedDepartmentData {
  label: string;
  value: string;
}

const formatDepartmentOptions = (rawData: RawDepartmentData[]): FormattedDepartmentData[] =>
  rawData?.map((reg: RawDepartmentData) => ({
    label: reg.alias,
    value: reg.alias,
  }));
const formatStringListOptions = (rawData: string[]): FormattedDepartmentData[] =>
  rawData?.map((rawValue: string) => ({ label: rawValue, value: rawValue }));

const filterSelectedOptions = (options: any[]): any[] => {
  // There is some absolute jank causing empty items to be chosen when clicking in certain parts of
  // the search filter, so this helps to fix that
  return options.filter((option: any) => {
    if (option === '' || option === undefined || option === null) return false;
    return true;
  });
};

const SmsLogsSearchFilter = (props: ISmsLogsSearchFilter): JSX.Element => {
  const {
    selectedDepartments,
    selectedSmsType,
    selectedStatus,
    setSelectedDepartments,
    setSelectedSmsType,
    setSelectedStatus,
  } = props;
  const showAlert = Boolean(selectedDepartments.length || selectedSmsType.length || selectedStatus.length);

  const { data: userDetailsData } = useQuery(GET_USER_DETAILS, {
    variables: { roles: ['PSO'], hasOther: false, filterOption: 'ADDRESS_STATE' },
  });
  const { data: smsTypesData } = useQuery(SMS_LOGS_SMS_TYPES);

  const departmentOptions = formatDepartmentOptions(userDetailsData?.allowedLocations);
  const smsTypeOptions = formatStringListOptions([...new Set(smsTypesData?.currentSmsTypes)] as string[]);
  const statusOptions = formatStringListOptions(['Sent', 'Failed']);

  return (
    <div className="search-filter-wrapper">
      <SearchFilter showAlert={showAlert}>
        <div className="search-menu-item">
          <SearchMultiSelectField
            selectAllEnabled={true}
            selectAllLabel="Select all"
            selectedOptionsUpdated={(options) => setSelectedDepartments(filterSelectedOptions(options))}
            resetSearchResults={() => null}
            defaultSelectedOptions={selectedDepartments}
            options={departmentOptions}
            placeholder="Departments"
            allSelected={selectedDepartments?.length === departmentOptions?.length}
            allSelectedLabel="All departments"
          />
        </div>
        <div className="search-menu-item">
          <SearchMultiSelectField
            selectAllEnabled={true}
            selectAllLabel="Select all"
            selectedOptionsUpdated={(options) => setSelectedSmsType(filterSelectedOptions(options))}
            resetSearchResults={() => null}
            defaultSelectedOptions={selectedSmsType}
            options={smsTypeOptions}
            placeholder="SMS Types"
            allSelected={selectedSmsType?.length === smsTypeOptions?.length}
            allSelectedLabel="All sms types"
          />
        </div>
        <div className="search-menu-item">
          <SearchMultiSelectField
            selectAllEnabled={true}
            selectAllLabel="Select all"
            selectedOptionsUpdated={(options) => setSelectedStatus(filterSelectedOptions(options))}
            resetSearchResults={() => null}
            defaultSelectedOptions={selectedStatus}
            options={statusOptions}
            placeholder="Statuses"
            allSelected={selectedStatus?.length === statusOptions?.length}
            allSelectedLabel="All statuses"
          />
        </div>
      </SearchFilter>
      <div
        data-test-id="clear-filter"
        className="clear-filter"
        onClick={(): void => {
          if (selectedDepartments?.length) setSelectedDepartments([]);
          if (selectedSmsType?.length) setSelectedSmsType([]);
          if (selectedStatus?.length) setSelectedStatus([]);
        }}>
        Clear filters
      </div>
    </div>
  );
};

export default SmsLogsSearchFilter;
