// eslint-disable-next-line no-use-before-define
import React from 'react';
import { styled } from '@mui/system';
import { ComposedModal } from 'gc-ui';
import { SummaryViewInfoImage } from 'shared-components/images/ro_portal';

const StyledModalBody = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 48px;
  margin-right: 48px;
  gap: 5px;
`;

const StyledSummaryViewInfoImage = styled(SummaryViewInfoImage)`
  align-self: center;
  margin-top: 20px;
  margin-bottom: 24px;
  padding-botton: 24px;
`;

const StyledHeading = styled('h1')`
  font-style: normal;
  weigth: 500;
  font-size: 24px;
  line-height: 28px;
`;

interface props {
  isOpen: boolean;
  onCloseModal: (event: unknown) => void;
}

const SummaryPageViewInfoModal = (props: props): JSX.Element => {
  const { isOpen, onCloseModal } = props;
  const ModalBody = () => (
    <StyledModalBody>
      <StyledSummaryViewInfoImage />
      <StyledHeading>Switch patient summary views anytime</StyledHeading>
      <p>You can go back to the previous view or enable the new view using the settings menu at the top right.</p>
    </StyledModalBody>
  );

  return (
    <ComposedModal
      headerText=""
      isOpen={isOpen}
      dismissFunction={onCloseModal}
      width={'711px'}
      backdrop={'static'}
      primaryRightButton={{
        buttonText: 'Got it',
        onClick: onCloseModal,
        className: 'got-it-button',
      }}>
      <ModalBody />
    </ComposedModal>
  );
};

export default SummaryPageViewInfoModal;
