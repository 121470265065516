import React from 'react';
import BaseDatePicker, { Props as BaseDatePickerProps } from './BaseDatePicker';
import FormRow from '../FormRow/FormRow';
import { Stack } from '@mui/material';

interface Props extends BaseDatePickerProps {
  required?: boolean;
  fieldlabel?: string;
  children?: React.ReactNode;
}

const RODatePicker = (props: Props) => {
  return (
    <FormRow id={props.id} required={props.required} fieldlabel={props.fieldlabel}>
      <Stack sx={{ width: '250px', ...props?.sx }}>
        <BaseDatePicker {...props} />
      </Stack>
      {props.children}
    </FormRow>
  );
};

export default RODatePicker;
