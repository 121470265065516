import React from 'react';
import { SubSection } from 'shared-components/components/UIFormComponents/Modal';

interface Props {
  text: string;
  emailLink: string;
}

const ModalContentSubmissionError = ({ text, emailLink }: Props): JSX.Element => (
  <SubSection>
    {text}
    <a href={`mailto:${emailLink}`}>{emailLink}</a>
  </SubSection>
);
export default ModalContentSubmissionError;
