export const typography = {
  family: {
    primary: '"GenesisCareSans", sans-serif',
    code: '"GenesisCareSans", sans-serif',
  },
  weight: {
    regular: '400',
    medium: '500',
    bold: '700',
  },
  size: {
    displayHeader1: '4rem',
    displayHeader2: '3rem',
    displayHeader3: '2rem',
    header1: '1.5rem',
    header2: '1.438rem',
    header3: '1.125rem',
    subtitle1: '1rem',
    subtitle2: '0.875rem',
    subtitle3: '0.875rem',
    body: '1rem',
    caption: '0.8125rem',
    link: '1rem',
    button: '0.875rem',
    helperText1: '0.8125rem',
    helperText2: '0.75rem',
  },
};
