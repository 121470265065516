import { Fragment, useState, useContext, useEffect, useCallback, useLayoutEffect, useRef } from 'react';
import { GCButton, ROTextField, ROAutocomplete, BaseAutocomplete } from 'shared-components/components/FormFields';
import ROPatientCarePlanPageFooter from '../Footer';
import { VerticalListState, CarePlanTemplateState, VerticalListType, SigmaResponseData } from './TemplatePageInterface';

import { getOptionByValue } from '../DiagnosisPages/Utils';
import { Logger, logPage } from 'shared-components/utils';
import classNames from 'classnames';
import {
  CareplanListFilter,
  SiteGroupType,
  DEFAULT_SITEGROUP,
  DEFAULT_SITE,
  CarePlanActionType,
  ROPatientCarePlanRoute,
} from '../Interface';
import ROPatientCarePathSidePanel from '../SidePanel/SidePanel';
import { CAREPLAN_PAGES } from '../Constants';
import ROPatientCarePlanContext from '../ROPatientCarePlanContext';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { gql } from '@apollo/client';
import {
  CREATE_SITEGROUP_FROM_TEMPLATE,
  LIST_SITE_FILTERS,
  LIST_DOSE_FILTERS,
  LIST_TECHNIQUE_FILTERS,
  LIST_CAREPLAN_FILTERS,
  LIST_TUMOUR_STREAMS,
  LIST_TREATMENT_SITES_ORDERBY_USAGE,
  GET_TUMOUR_STREAM_FROM_CAREPLAN,
} from './TemplatePageQueries';
import { GET_PEER_REVIEW } from '../SidePanel/SidePanelQueries';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { GET_CAREPLAN_SITE_GROUPS, GET_CAREPLAN, FREQUENTLY_USED_TEMPLATES, SIGMA_DATA } from '../Queries';
import './TemplatePage.scss';
import { alphabeticalOrder, CleanSpecifyFromSiteName, isSpecifySite } from '../Common';
import { validateField, isPageValid } from '../ValidationEngine';
import { CareplanBanners } from '../Banner/Banner';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { toast, Slide } from 'react-toastify';
import { LoadingSpinner } from 'shared-components/components';
import CareplanContainer from '../Container';
import { Features, useErrorModalContext, UserContext } from 'op-contexts';
import Sigma from './SuggestedTemplates/Sigma';
import FrequentlyUsed from './SuggestedTemplates/FrequentlyUsed';
import { LOG_SIGMA_MUTATION } from '../DiagnosisPages/Queries';
import { ButtonType } from 'shared-components/enums';
import { SEARCH_DROPDOWN_PLACEHOLDER } from 'shared-components/components/FormFields/common';
import { logMissingDiagnosisCode } from '../Common';
import { Modal, ModalBody, ModalFooter } from 'gc-ui';
import { Box, useTheme, InputAdornment } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import { BaseTextField, SelectOptionType } from 'shared-components/components/FormFields';
import TemplateSelectionTable from './TemplateSelectionTable';

const MAX_TREATMENT_SITE_OPTIONS_LENGTH = 8;
const UROGENITAL_TS = 'urogenital';
const BREAST_TS = 'breast';

let pressedContinue = false;
const isFieldValid = (field: any) => {
  return pressedContinue ? validateField(field) : true;
};
const logger = new Logger('ROPatientTemplateSelectionPage');
const getNewSiteGroupTemplate = (treatmentSite: string, laterality: string): SiteGroupType => ({
  ...DEFAULT_SITEGROUP,
  sites: [
    {
      ...DEFAULT_SITE,
      treatmentSite,
      laterality,
    },
  ],
});

const sortOptionType = (a: SelectOptionType, b: SelectOptionType) => {
  if (a.value < b.value) return -1;
  if (a.value > b.value) return 1;
  return 0;
};
export const getVerticalListState = (verticalList: VerticalListType[]): VerticalListState[] =>
  verticalList.map(
    (option: VerticalListType): VerticalListState => ({
      ...option,
      selected: '',
      show: option.id === 'site',
    }),
  );

const ROPatientCarePathTemplatePage = (): JSX.Element => {
  const windowSize = useWindowSize();
  const isSmallDevice = windowSize.width <= 1440;
  const client = useApolloClient();
  const match = useRouteMatch<ROPatientCarePlanRoute>();
  const { careplanId, id: patientId } = match.params;
  const pageLoadTime = useRef(new Date().getTime());
  const { state: userState, hasFeature, isFeatureLoading } = useContext(UserContext);
  const [sigmaActive, setSigmaActive] = useState(false);
  const theme = useTheme();
  const [createSiteGroup] = useMutation(CREATE_SITEGROUP_FROM_TEMPLATE, {
    refetchQueries: [
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
      { query: GET_PEER_REVIEW, variables: { careplanId } },
    ],
  });
  const [logEvent] = useMutation(LOG_SIGMA_MUTATION);
  const [tumourStream, setTumourStream] = useState<string>('');
  const [selectedSiteFilter, setSelectedSiteFilter] = useState<string>('');
  const [selectedDoseFilter, setSelectedDoseFilter] = useState<string>('');
  const [selectedTechniqueFilter, setSelectedTechniqueFilter] = useState<string>('');
  const [siteFilters, setSiteFilters] = useState<string[]>([]);
  const [doseFilters, setDoseFilters] = useState<string[]>([]);
  const [techniqueFilters, setTechniqueFilters] = useState<string[]>([]);
  const [careplans, setCareplans] = useState<CareplanListFilter[]>([]);
  const [pageState, setState] = useState<CarePlanTemplateState>({
    siteGroupTemplatesState: [],
    modalShow: false,
    treatmentSites: '',
    laterality: '',
    location: '',
    validated: false,
    tumourStreamList: [],
    treatmentSiteList: [],
    selectedCareplanList: [],
    frequentlyUsedTemplates: [],
    sigmaResponse: undefined,
  });
  const { dispatch } = useContext(ROPatientCarePlanContext);
  const history = useHistory();
  const { setError } = useErrorModalContext();
  const { data: currentCareplanGroupList, error: currentCareplanGroupListError } = useQuery(GET_CAREPLAN_SITE_GROUPS, {
    variables: { careplanId },
  });

  // May be better to pass this in, many of the other calls have to wait for the tumourSteam that this returns
  const {
    loading: tumourStreamLoading,
    data: tumourData,
    error: tumourDataError,
  } = useQuery(GET_TUMOUR_STREAM_FROM_CAREPLAN, {
    variables: { careplanId },
    onCompleted: (data) => {
      logMissingDiagnosisCode(logger, data.careplan, 'Template Information');
    },
  });
  const { data: tumourStreamData, error: tumourStreamDataError } = useQuery(LIST_TUMOUR_STREAMS);
  const { data: treatmentSitesData, error: treatmentSitesDataError } = useQuery(LIST_TREATMENT_SITES_ORDERBY_USAGE);
  const { data: siteFiltersData, error: siteFiltersDataError } = useQuery(LIST_SITE_FILTERS, {
    variables: { tumourStream, patientId },
    skip: !tumourStream,
  });

  const { error: frequentTemplatesDataError } = useQuery(FREQUENTLY_USED_TEMPLATES, {
    variables: { patientId, tumourStream },
    skip: !tumourStream,
    fetchPolicy: 'network-only',
    onCompleted: (data): void =>
      setState((prevState) => {
        return { ...prevState, frequentlyUsedTemplates: data?.frequentlyUsedTemplates };
      }),
  });

  interface SigmaVariables {
    careplanId: string;
  }

  const {
    loading: sigmaLoading,
    data: sigmaData,
    error: sigmaDataError,
  } = useQuery<SigmaResponseData, SigmaVariables>(SIGMA_DATA, {
    variables: { careplanId },
    skip: !sigmaActive,
    fetchPolicy: 'network-only',
    onError: (error): void => {
      logger.info(error?.message);
    },
  });

  const [searchTerm, setSearchTerm] = useState('');
  const firstUpdate = useRef(true);
  const { data: careplan, error: careplanError } = useQuery(GET_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { id: careplanId },
  });

  const { data: doseFiltersData, error: doseFiltersDataError } = useQuery(LIST_DOSE_FILTERS, {
    variables: { tumourStream, siteFilter: selectedSiteFilter, patientId },
    skip: !tumourStream || !selectedSiteFilter,
  });
  const { data: techniqueFiltersData, error: techniqueFiltersDataError } = useQuery(LIST_TECHNIQUE_FILTERS, {
    variables: {
      patientId,
      tumourStream,
      siteFilter: selectedSiteFilter,
      doseFilter: selectedDoseFilter,
    },
    skip: !tumourStream || !selectedSiteFilter,
  });

  const { data: careplanFiltersData, error: careplanFiltersDataError } = useQuery(LIST_CAREPLAN_FILTERS, {
    variables: {
      patientId,
      tumourStream,
      siteFilter: selectedSiteFilter,
      doseFilter: selectedDoseFilter ? selectedDoseFilter : null,
      techniqueFilter: selectedTechniqueFilter ? selectedTechniqueFilter : null,
      search: searchTerm,
    },
    skip: !tumourStream || !selectedSiteFilter,
    onCompleted: (data): void => {
      setCareplans(data?.careplanTemplateList);
    },
  });

  useEffect(() => {
    if (
      currentCareplanGroupListError ||
      tumourDataError ||
      tumourStreamDataError ||
      treatmentSitesDataError ||
      frequentTemplatesDataError ||
      siteFiltersDataError ||
      careplanError ||
      doseFiltersDataError ||
      techniqueFiltersDataError ||
      careplanFiltersDataError ||
      sigmaDataError
    )
      return setError();
  }, [
    currentCareplanGroupListError,
    tumourDataError,
    tumourStreamDataError,
    treatmentSitesDataError,
    frequentTemplatesDataError,
    siteFiltersDataError,
    careplanError,
    doseFiltersDataError,
    techniqueFiltersDataError,
    careplanFiltersDataError,
    sigmaDataError,
  ]);

  const [, updateState] = useState();
  //@ts-ignore
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    let tumourOptionType = [];
    let treatmentSiteList = [];
    let selectedCareplanList = [];

    if (tumourStreamData) {
      tumourOptionType = tumourStreamData.tumourStreamList.map((stream: string): SelectOptionType => {
        return { label: stream, value: stream };
      });
    }
    if (treatmentSitesData) {
      treatmentSiteList = treatmentSitesData.mostUsedSiteReferenceList.map((site: any): SelectOptionType => {
        return { label: site.treatmentSite, value: site.treatmentSite };
      });
    }
    if (currentCareplanGroupList) {
      selectedCareplanList = currentCareplanGroupList.siteGroupsByCareplan.map((siteGroup: any) => {
        return {
          id: siteGroup.id,
          template: siteGroup,
          name: siteGroup.name,
          ccTemplate: siteGroup.template?.template,
        };
      });
    }
    setState({
      ...pageState,
      tumourStreamList: tumourOptionType,
      treatmentSiteList,
      selectedCareplanList,
    });
  }, [tumourStreamData, treatmentSitesData, currentCareplanGroupList]);

  useEffect(() => {
    if (careplanFiltersData) {
      setCareplans(careplanFiltersData.careplanTemplateList);
    }
  }, [careplanFiltersData]);

  useEffect(() => {
    if (siteFiltersData) {
      setSiteFilters(siteFiltersData.siteFilterList);
    }
  }, [siteFiltersData]);

  useEffect(() => {
    if (doseFiltersData) {
      setDoseFilters(doseFiltersData.doseFilterList);
    }
  }, [doseFiltersData]);

  useEffect(() => {
    if (techniqueFiltersData) {
      setTechniqueFilters(techniqueFiltersData.techniqueFilterList);
    }
  }, [techniqueFiltersData]);

  useEffect(() => {
    if (tumourData && !tumourStream) {
      setTumourStream(tumourData.careplan?.diagnosis?.diagnosisCode?.tumourStream?.name || '');
    }
  }, [tumourData]);

  useLayoutEffect(() => {
    if (searchTerm !== '') {
      client
        .query({
          query: gql`
            query careplanTemplateList($tumourStream: String!, $search: String, $patientId: ID!) {
              careplanTemplateList(tumourStream: $tumourStream, search: $search, patientId: $patientId) {
                id
                name
                rules {
                  id
                  field
                  defaultValue
                }
              }
            }
          `,
          variables: {
            tumourStream,
            search: searchTerm,
            patientId: patientId,
          },
        })
        .then((careplanData: any) => {
          const careplans: any = careplanData.data.careplanTemplateList;
          if (careplans) {
            setSelectedSiteFilter('');
            setDoseFilters([]);
            setTechniqueFilters([]);
            setSelectedSiteFilter('');
            setCareplans(careplans);
          }
        });
    } else if (searchTerm === '' && !firstUpdate.current) {
      setState((prevState) => {
        return { ...prevState, careplans: [] };
      });
    } else firstUpdate.current = false;
  }, [searchTerm]);

  useEffect(() => {
    pressedContinue = false;
  }, []);
  const sigmaTumourStreams = [BREAST_TS, UROGENITAL_TS];

  useEffect(() => {
    setSigmaActive(
      sigmaTumourStreams.includes(tumourStream.toLowerCase()) &&
        Boolean(hasFeature(Features.SIGMA)) &&
        tumourData?.careplan?.diagnosis.isPrimaryDiagnosis,
    );
  }, [userState.features, tumourStream, tumourData]);

  // On page refresh user features take a moment to load, causing flicker of frequently used templates
  if (isFeatureLoading() || (sigmaActive && sigmaLoading) || tumourStreamLoading)
    return (
      <LoadingSpinner
        loadingText={'Loading Careplan Selection'}
        subtitle={'Please wait while we set things up for you'}
      />
    );

  const selectCareplan = (
    treatmentSite: string,
    careplanName: string,
    templateId: number,
    treatmentSiteSpecify: string,
    selectionMethod?: string,
  ): void => {
    const nextSiteGroupTemplatesState = getNewSiteGroupTemplate(treatmentSite, '');
    const elem = document.querySelector(`[data-testid="${careplanName}"]`) as any;
    const isManualSelection = selectionMethod === 'manual';
    const title = isManualSelection ? 'Manual site' : 'Careplan template';

    const manualSiteName = treatmentSiteSpecify
      ? `${CleanSpecifyFromSiteName(`${treatmentSite} (${treatmentSiteSpecify})`)}`
      : treatmentSite;

    const NotificationMessage = () => (
      <div>
        <div style={{ maxWidth: '270px', wordWrap: 'normal', overflow: 'hidden' }} className="title">
          {title} added
        </div>
        <div style={{ maxWidth: '270px', wordWrap: 'normal', overflow: 'hidden' }}>
          {isManualSelection ? manualSiteName : careplanName}
        </div>
      </div>
    );

    toast.dark(<NotificationMessage />, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      transition: Slide,
      progress: undefined,
    });

    if (elem && isManualSelection) {
      if (elem.hasAttribute('disabled')) return;
      elem.setAttribute('disabled', '');
      elem.classList.add('active');

      setTimeout(() => {
        setSelectedSiteFilter('');
        setSelectedDoseFilter('');
        setSelectedTechniqueFilter('');
        setCareplans([]);
        setState({
          ...pageState,
          siteGroupTemplatesState: [...pageState.siteGroupTemplatesState, nextSiteGroupTemplatesState],
        });
      }, 0);
    }

    setState({
      ...pageState,
      siteGroupTemplatesState: [...pageState.siteGroupTemplatesState, nextSiteGroupTemplatesState],
    });
    dispatch({
      type: CarePlanActionType.SET_CAREPLAN_SITES,
      payload: { template: { name: careplanName, groups: [nextSiteGroupTemplatesState] } },
    });
    createSiteGroup({
      variables: {
        careplanId,
        templateId: templateId,
        templateName: careplanName,
        treatmentSite: treatmentSite,
        selectionMethod,
        treatmentSiteSpecify,
      },
    });
  };
  const hideModal = (): void => {
    pressedContinue = false;
    setState({ ...pageState, modalShow: false });
  };
  const validationDependencyStructure = [
    {
      parent: null,
      expansionValue: null,
      children: ['treatmentSites'],
    },
    {
      parent: 'isSpecify',
      expansionValue: true,
      children: ['location'],
    },
  ];
  const isManualSiteValid = () => {
    // Create an isSpecify flag so we can use it in the validation structure
    const stateWithSpecify = { ...pageState, isSpecify: isSpecifySite(pageState.treatmentSites) };
    return isPageValid(validationDependencyStructure, stateWithSpecify);
  };

  const getSigmaStatus = (): string => {
    // Breast only for MVP. When extended to other streams may need more logic around tumour endpoint availablity
    const diagnosisCode = tumourData?.careplan?.diagnosis?.diagnosisCode;
    const tumourStream = diagnosisCode?.tumourStream?.name.toLowerCase();

    if (diagnosisCode && !sigmaTumourStreams.includes(tumourStream)) {
      return 'not_supported'; // No sigma endpoint available
    } else if (tumourStream === UROGENITAL_TS && diagnosisCode.diagnosisCode !== 'C61') {
      // If the tumour stream is Urogenital and the diagnosis code is not C61, then sigma is not support
      return 'not_supported';
    } else {
      if (sigmaActive) {
        switch (sigmaData?.sigmaData?.status) {
          case '200':
            return 'ok';
          case '422':
            return 'validation_error';
          default:
            return 'unexpected_error';
        }
      }
      return 'inactive'; // tumour stream available for Sigma Suggestions, Sigma not active for user
    }
  };

  const usageMetricsData = {
    event_name: 'careplans_selected',
    practitioner_id: parseInt(tumourData.careplan.practitioner.id),
    patient_id: parseInt(patientId),
    diagnosis_id: parseInt(tumourData.careplan.diagnosis.id),
    careplan_id: parseInt(tumourData.careplan.id),
    sigma_response_uuid: sigmaData?.sigmaData?.responseUUID,
    sigma_status: getSigmaStatus(),
    selected_templates: pageState.selectedCareplanList.map((selectedCareplan) => {
      return {
        id: selectedCareplan.ccTemplate?.id ?? selectedCareplan.template.id, //manual careplans don't have a carepath template
        name: selectedCareplan.template.name,
        selectionMethod: selectedCareplan.template.selectionMethod,
      };
    }),
    recommended_templates: sigmaData?.sigmaData?.templates?.map((template) => {
      return {
        id: template.id,
        name: template.name,
        score: template.score,
      };
    }),
  };

  const endAdornment = () => {
    if (searchTerm) {
      return (
        <InputAdornment position="end">
          <Clear sx={{ cursor: 'pointer' }} onClick={() => setSearchTerm('')} color="primary" />
        </InputAdornment>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <div className="main-container-parent-wrapper">
        <div className="main-container-wrapper">
          <CareplanBanners data={careplan} />
          <CareplanContainer className={'template-selection-page carepath-template-page'}>
            <div className="page-header">
              <h1 data-test-id="template-select-header">{'Careplan Selection'}</h1>
              <h2>{'Please select careplan templates/sites.'}</h2>
            </div>
            {sigmaActive && sigmaData?.sigmaData?.templates?.length ? (
              <Sigma
                templates={sigmaData?.sigmaData?.templates}
                selectCareplan={selectCareplan}
                tumourStream={tumourStream}
              />
            ) : (
              <FrequentlyUsed
                templates={pageState.frequentlyUsedTemplates}
                selectCareplan={selectCareplan}
                tumourStream={tumourStream}
              />
            )}

            <div className="freq-search-separator"></div>
            <div className="cp-select-ddsearch-wrapper">
              <div className="cp-select-ddsearch">
                <div className="cp-select-ddsearch">
                  <span className="cp-select-span">Tumour Stream</span>
                  <div style={{ width: '400px' }}>
                    <BaseAutocomplete
                      id="tumourStream"
                      value={getOptionByValue(pageState.tumourStreamList, tumourStream)}
                      disableClearable
                      onChange={(option: SelectOptionType | string): void => {
                        const value = typeof option === 'string' ? option : option?.value;
                        if (tumourStream !== value) {
                          setSearchTerm('');
                          setSelectedSiteFilter('');
                          setSelectedDoseFilter('');
                          setSelectedTechniqueFilter('');
                          setDoseFilters([]);
                          setTechniqueFilters([]);
                          setSiteFilters([]);
                          setCareplans([]);
                          setTumourStream(value);
                        }
                      }}
                      options={pageState.tumourStreamList}
                    />
                  </div>
                </div>
                <div className="add-cp-site-manually">
                  <span
                    onClick={(): void => {
                      setState({ ...pageState, modalShow: true, location: '', treatmentSites: '' });
                    }}>
                    {isSmallDevice ? (
                      'Add site manually'
                    ) : (
                      <Fragment>
                        Can't find template? <br /> Add site manually
                      </Fragment>
                    )}
                  </span>
                </div>
                <div className="cp-search-input-wrapper">
                  <BaseTextField
                    name="cpTemplateSearchInput"
                    id="cpTemplateSearchInput"
                    type="search"
                    placeholder="Search careplan templates…"
                    fullWidth
                    value={searchTerm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchTerm(event.target.value);
                    }}
                    InputProps={{
                      startAdornment: <Search color="primary" />,
                      endAdornment: endAdornment(),
                    }}
                    sx={{
                      marginLeft: '16px',
                    }}
                  />
                </div>
              </div>
            </div>
            <TemplateSelectionTable
              siteFilter={selectedSiteFilter}
              doseFilter={selectedDoseFilter}
              techniqueFilter={selectedTechniqueFilter}
              setSiteFilter={setSelectedSiteFilter}
              setDoseFilter={setSelectedDoseFilter}
              setTechniqueFilter={setSelectedTechniqueFilter}
              treatingDepartment={careplan?.careplan?.treatingDepartment}
              siteFilterOptions={alphabeticalOrder(siteFilters, null)}
              doseFilterOptions={alphabeticalOrder(doseFilters, null)}
              techniqueFilterOptions={alphabeticalOrder(techniqueFilters, null)}
              setCareplan={(id, name) => selectCareplan(tumourStream, name, id, '', 'filter')}
              careplanOptions={alphabeticalOrder(careplans, 'name')}
            />
          </CareplanContainer>
          <Modal open={pageState.modalShow} id="treatment-sites-modal" PaperProps={{ style: { minWidth: '800px' } }}>
            <ModalBody>
              <button
                className="btn-close"
                onClick={(): void => {
                  hideModal();
                }}>
                <i className="icon icon-green-close" />
              </button>
              <div className="page-header">
                <h4 className="title">{'Add Treatment Site Manually'}</h4>
              </div>
              <div className="page-sub-header">
                <h4 className="subtitle">{'Most Common Sites'}</h4>
              </div>
              <div className="treatment-sites-freq-list">
                {pageState.treatmentSiteList.slice(0, MAX_TREATMENT_SITE_OPTIONS_LENGTH).map(
                  (option: SelectOptionType): JSX.Element => (
                    <div className="treatment-sites-freq-item" key={`treatment-sites-${option.value}`}>
                      <button
                        className={classNames('treatment-sites-freq-item-button', {
                          active: pageState.treatmentSites === option.value,
                        })}
                        value={option.value}
                        onClick={(): void => {
                          setState({ ...pageState, treatmentSites: option.value, location: '' });
                        }}>
                        {option.label}
                      </button>
                    </div>
                  ),
                )}
              </div>
              <div className="page-sub-header">
                <h4 className="subtitle">{'Search & Select'}</h4>
              </div>
              <Box component="form" id="addSiteManuallyForm">
                <ROAutocomplete
                  id="treatmentSites"
                  fieldlabel="Treatment Sites"
                  placeholder={SEARCH_DROPDOWN_PLACEHOLDER}
                  options={pageState.treatmentSiteList.sort(sortOptionType)}
                  value={getOptionByValue(pageState.treatmentSiteList, pageState.treatmentSites)}
                  onChange={(option: SelectOptionType | string): void => {
                    const value = typeof option === 'string' ? option : option?.value;
                    setState({
                      ...pageState,
                      treatmentSites: value || '',
                      location: '',
                    });
                  }}
                  inputProps={{
                    error: !isFieldValid(pageState.treatmentSites),
                    helperText: !isFieldValid(pageState.treatmentSites) ? 'This field is required' : undefined,
                  }}
                  required
                  disableClearable
                />
                {isSpecifySite(pageState.treatmentSites) && (
                  <ROTextField
                    id="location"
                    fieldlabel="Location"
                    value={pageState.location}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      setState({ ...pageState, location: event.target.value });
                    }}
                    error={!isFieldValid(pageState.location)}
                    helperText={!isFieldValid(pageState.location) ? 'This field is required' : undefined}
                    required
                  />
                )}
              </Box>
            </ModalBody>
            <ModalFooter>
              <GCButton
                id="button-manually-cancel"
                title={'Cancel'}
                onClick={(): void => {
                  hideModal();
                }}
                type={ButtonType.WHITE}
              />

              <GCButton
                id="button-manually-confirm"
                title={'Add'}
                onClick={(): void => {
                  pressedContinue = true;
                  forceUpdate();
                  if (!isManualSiteValid()) return;
                  selectCareplan(pageState.treatmentSites, '', -1, pageState.location, 'manual');

                  hideModal();
                }}
                type={ButtonType.GREEN}
              />
            </ModalFooter>
          </Modal>
          <ROPatientCarePlanPageFooter
            onBack={(): void => {
              history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.TREATMENT_INFO}`);
            }}
            onNext={(): void => {
              logPage(pageLoadTime.current, usageMetricsData, logEvent);
              history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SITESETUP}`);
            }}
            nextDisabled={
              !(
                currentCareplanGroupList &&
                currentCareplanGroupList.siteGroupsByCareplan &&
                currentCareplanGroupList.siteGroupsByCareplan.length !== 0
              )
            }
          />
        </div>
        <ROPatientCarePathSidePanel />
      </div>
    </Fragment>
  );
};

export default ROPatientCarePathTemplatePage;
