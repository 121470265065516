// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import 'url-search-params-polyfill';
import * as Sentry from '@sentry/browser';
import axios from 'axios';

import { ButtonType } from 'shared-components/enums';
import { SectionField, FreeTextField, GCButton, ErrorInfo } from 'shared-components/components/FormFields';

import logo from 'shared-components/images/gclogo.png';

const REG_LOGIN_TITLE = 'Registration';
const REG_LOGIN_BUTTON = 'Log in';
const USERNAME_LABEL = 'Last name';
const USERNAME_PLACEHOLDER = 'Enter last name';
const PASSWORD_LABEL = 'PIN';
const PASSWORD_PLACEHOLDER = 'Enter 4 digit PIN (e.g. 1234)';
const LOGIN_ERROR_MESSAGE = 'Please enter a valid last name and/or PIN';

/**
 * Interfaces
 */

interface State {
  loading: boolean;
  loginError: boolean;
}

class RegistrationLogin extends Component<RouteComponentProps, State> {
  private usernameRef: HTMLInputElement | null = null;
  private passwordRef: HTMLInputElement | null = null;

  public constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      loading: false,
      loginError: false,
    };
  }

  public render(): JSX.Element {
    const { loading, loginError } = this.state;
    return (
      <Fragment>
        <div className="auth-container">
          <div className="auth-container-inner">
            <div className="auth-header-container">
              <img src={logo} alt="GenesisCare Logo" />
              <div className="auth-header-title">{REG_LOGIN_TITLE}</div>
            </div>
            <form id="patient-login-form" className="auth-form" onSubmit={(): void => this.login()}>
              <SectionField htmlFor="username" title={USERNAME_LABEL} isValid={!loginError}>
                <FreeTextField
                  inputName="username"
                  placeholder={USERNAME_PLACEHOLDER}
                  elementRef={(ref: HTMLInputElement | null): void => {
                    this.usernameRef = ref;
                  }}
                  displayInputError={loginError}
                />
              </SectionField>
              <SectionField htmlFor="password" title={PASSWORD_LABEL} isValid={!loginError}>
                <FreeTextField
                  inputName="password"
                  inputType="number"
                  placeholder={PASSWORD_PLACEHOLDER}
                  elementRef={(ref: HTMLInputElement | null): void => {
                    this.passwordRef = ref;
                  }}
                  displayInputError={loginError}
                />
              </SectionField>
              {loginError && <ErrorInfo errors={loginError ? [LOGIN_ERROR_MESSAGE] : undefined} />}
              <div className="auth-submit-button">
                <GCButton
                  onClick={(e): void => {
                    if (e) {
                      e.preventDefault();
                    }
                    this.login();
                  }}
                  name="login"
                  type={ButtonType.GREEN}
                  inputType="submit"
                  loading={loading}
                  title={REG_LOGIN_BUTTON}
                />
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }

  private login = (): void => {
    const { history } = this.props;

    if (this.usernameRef && this.passwordRef) {
      this.setState({ loading: true, loginError: false });
      const loginData = {
        lastname: this.usernameRef.value,
        pin: this.passwordRef.value,
      };

      axios
        .post('/server/auth/pinlogin', loginData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response): void => {
          let version;
          const { id, username } = response.data;
          sessionStorage.removeItem('userId');

          this.setState({ loading: false, loginError: false });
          this.onLoginSuccess(id, username);
          // TODO: Once HA live instage the navigation. Add navigateto back to props.
          history.push({ pathname: `/patient/${id}/home`, state: { version } });
        })
        .catch((_): void => {
          this.setState({ loading: false, loginError: true });
        });
    }
  };

  private onLoginSuccess = (patientId: string, username: string): void => {
    {
      const scope = Sentry.getCurrentScope();
      scope.setTag('user_type', 'patient');
      scope.setUser({
        id: patientId,
        username: username,
      });
    }
  };
}

// Wrap component with router
export default withRouter(RegistrationLogin);
