import React from 'react';
import { HelperMessage } from 'shared-components/components';
import { MainSection } from 'shared-components/components/UIFormComponents/Modal';
import { sharedContent } from '../constants';

const { mainText, helperText } = sharedContent.reviewAndSubmit.proceedToTriage;

const ModalContentProceedToTriage = (): JSX.Element => {
  return (
    <>
      <MainSection>{mainText}</MainSection>
      <HelperMessage fieldName={'proceed-to-triage'} fieldText={helperText} helperType="info" fontSize={13} />
    </>
  );
};

export default ModalContentProceedToTriage;
