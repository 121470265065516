import { FilteredListOption } from 'shared-components/components/FormFields/ROFilteredDropdown/FilteredListDropdown';
import { IntakeData, DEFAULT_INTAKE_DATA } from './Intake/Interface';
import { SelectOptionType } from 'shared-components/components/FormFields';

export interface TnmStageType {
  id: number;
  mainCode: string;
  subCode: string;
  description: string;
}

export interface DiseaseType {
  diseaseId: string;
  title: string;
  chapter: string;
}

export interface DiagnosisCodeType {
  id: string;
  diagnosisCode: string;
  diagnosisDescription: string;
  stageNumber: string;
  certainty?: string;
  tumourStream: {
    name: string;
  };
}
export interface MorphologyType {
  displayName: string;
}

export interface DiagnosisType {
  id: string;
  stageType: string;
  stageTCodePathological: TnmStageType | null;
  stageMCodePathological: TnmStageType | null;
  stageNCodePathological: TnmStageType | null;
  stageTCodeClinical: TnmStageType | null;
  stageMCodeClinical: TnmStageType | null;
  stageNCodeClinical: TnmStageType | null;
  morphology: MorphologyType | null;
  otherInfo: string;
  laterality: string;
  diagnosisDate: string;
  diagnosisCode: DiagnosisCodeType | null;
  isPrimaryDiagnosis: boolean | null;
  relatedPrimaryDiagnosis: DiagnosisType | null;
  draft: boolean;
  medId?: string;
  disease?: DiseaseType;
  hasTreatmentStarted: boolean;
  metastasisLaterality: string;
  metastasisDiagnosisDate: string;
  ajccMajorVersion: '';
}

// For use on Staging Page
export interface ConvertedDiagnosisType {
  id?: string;
  stageType?: string;
  stageTCodePathological?: FilteredListOption | undefined;
  stageMCodePathological?: FilteredListOption | undefined;
  stageNCodePathological?: FilteredListOption | undefined;
  stageTCodeClinical?: FilteredListOption | undefined;
  stageMCodeClinical?: FilteredListOption | undefined;
  stageNCodeClinical?: FilteredListOption | undefined;
  morphology?: SelectOptionType | undefined;
  otherInfo?: string;
  laterality?: SelectOptionType | undefined;
  diagnosisDate?: string;
  diagnosisCode?: DiagnosisCodeType | null;
  isPrimaryDiagnosis?: boolean | null;
  relatedPrimaryDiagnosis?: DiagnosisType | null;
  draft?: boolean;
  medId?: string;
  disease?: FilteredListOption | undefined;
  hasTreatmentStarted?: boolean;
}

export interface DiagnosisTypeWithoutNulls {
  id: string;
  stageType: string;
  stageTCodePathological: TnmStageType;
  stageMCodePathological: TnmStageType;
  stageNCodePathological: TnmStageType;
  stageTCodeClinical: TnmStageType;
  stageMCodeClinical: TnmStageType;
  stageNCodeClinical: TnmStageType;
  morphology: MorphologyType;
  otherInfo: string;
  laterality: string;
  diagnosisDate: string;
  diagnosisCode: DiagnosisCodeType;
  relatedPrimaryDiagnosis: DiagnosisType | null;
  isPrimaryDiagnosis: boolean;
}

export interface DiagnosisInputType {
  stageType: string;
  stageTCodePathologicalId: number | null;
  stageNCodePathologicalId: number | null;
  stageMCodePathologicalId: number | null;
  stageTCodeClinicalId: number | null;
  stageNCodeClinicalId: number | null;
  stageMCodeClinicalId: number | null;
  morphology: string;
  laterality: string;
  otherInfo: string;
  diagnosisDate: string;
  isPrimaryDiagnosis: boolean | null;
  diagnosisCodeId: number | null;
  relatedPrimaryDiagnosisId: number | null;
}

export const DEFAULT_DIAGNOSIS_TYPE: DiagnosisType = {
  id: '',
  stageType: '',
  stageTCodePathological: null,
  stageMCodePathological: null,
  stageNCodePathological: null,
  stageTCodeClinical: null,
  stageMCodeClinical: null,
  stageNCodeClinical: null,
  disease: undefined,
  morphology: null,
  otherInfo: '',
  laterality: '',
  diagnosisDate: '',
  diagnosisCode: null,
  isPrimaryDiagnosis: null,
  relatedPrimaryDiagnosis: null,
  draft: true,
  metastasisLaterality: '',
  metastasisDiagnosisDate: '',
  hasTreatmentStarted: false,
  ajccMajorVersion: '',
};

export interface DiagnosisCategoryList {
  tumourStream: string;
  diagnosisList: DiagnosisCodeType[];
}

export const DEFAULT_DIAGNOSIS_CODE_TYPE: DiagnosisCodeType = {
  id: '',
  diagnosisCode: '',
  diagnosisDescription: '',
  stageNumber: '',
  tumourStream: {
    name: '',
  },
};

export const DEFAULT_TNM_STAGE_TYPE: TnmStageType = {
  id: 0,
  mainCode: '',
  description: '',
  subCode: '',
};

export interface DiagnosisRouterParams {
  id: string;
  diagnosisId: string;
  careplanId: string;
  page: string;
  operation: string;
  oncologyType: string;
}

/**
 *  Reducer interfaces for Diagnosis
 */
export enum DiagnosisActionType {
  SET_DIAGNOSIS,
  SET_INTAKE,
  SET_NEW_PRIMARY,
  SET_NEW_METASTASIS,
  CREATE_DIAGNOSIS,
  COMPLETED_CREATING_DIAGNOSIS,
  CREATE_DIAGNOSIS_ONLY,
  REPLACE_CURRENT_DIAGNOSIS,
  RESET,
}

export interface DiagnosisState {
  diagnosis: DiagnosisType;
  intake: IntakeData;
  newPrimary: boolean;
  newSecondary: boolean;
  creatingDiagnosis: boolean;
  createDiagnosisOnly: boolean;
}
export interface DiagnosisAction {
  type: DiagnosisActionType;
  payload: any;
}

export interface Disease {
  diseaseId: string;
  title: string;
  chapter: string;
}
export interface DiseaseData {
  diseases?: Disease[];
}

export const DEFAULT_DIAGNOSIS_STATE: DiagnosisState = {
  diagnosis: DEFAULT_DIAGNOSIS_TYPE,
  intake: DEFAULT_INTAKE_DATA,
  newPrimary: false,
  newSecondary: false,
  creatingDiagnosis: false,
  createDiagnosisOnly: false,
};

export interface StagingFieldsAJCC {
  disease?: FilteredListOption;
  morphology?: SelectOptionType | null;
  stageTCodeClinical?: FilteredListOption;
  stageTCodePathological?: FilteredListOption;
  stageNCodeClinical?: FilteredListOption;
  stageNCodePathological?: FilteredListOption;
  stageMCodeClinical?: FilteredListOption;
  stageMCodePathological?: FilteredListOption;
  laterality?: SelectOptionType | null;
  diagnosisDate: string | undefined;
  otherInfo: string | undefined;
  metastasisLaterality?: SelectOptionType | null;
  metastasisDiagnosisDate: string | undefined;
}
export interface DiseaseGroupVariables {
  ajccMajorVersion: number;
  relatedIcdCode?: string;
}

export interface DiagnosisVariables {
  diagnosisId: string;
}

export interface DiagnosisData {
  diagnosis: DiagnosisType;
}
