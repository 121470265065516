import React from 'react';
import { Card } from 'shared-components/components';
import MOCareplanTableApollo from './MOCareplanTableApollo';
import { useTheme } from '@mui/material';

const DashboardPanel = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Card style={{ margin: '32px', height: 'calc(100% - 60px)', width: 'calc(100% - 303px)' }}>
      <h1 style={{ fontSize: '24px', paddingBottom: '8px' }}>Treatment</h1>
      <p style={{ fontSize: '14px', color: theme.palette.secondary.dark }}>In the next 7 days</p>
      <div style={{ overflow: 'auto', height: 'calc(100% - 60px)' }}>
        <MOCareplanTableApollo />
      </div>
    </Card>
  );
};

export default DashboardPanel;
