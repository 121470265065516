import { useTheme } from '@mui/material';
import { styled } from '@mui/system';

export const SEARCH_DROPDOWN_PLACEHOLDER = 'Start typing or choose from dropdown...';

// TODO: fix this up and use grid when all components are cleaned up
export const ChildSectionWrapper = styled('div')`
  border-left: 1px solid ${(props) => props.theme.palette.secondary.main};

  & h6 {
    margin-left: 16px;
  }
  .gc-form-label {
    margin-right: -17px;
    margin-left: 16px;
  }
`;

export const SectionTitleGrey = styled('div')`
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => props.theme.palette.secondary.dark};
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 21px;
`;

export const getBorderColor = (error?: boolean, warning?: boolean, info?: boolean): string => {
  const theme = useTheme();
  return error
    ? theme.palette.error.main
    : warning
    ? theme.palette.warning.main
    : info
    ? theme.palette.info.main
    : theme.palette.secondary.main;
};
