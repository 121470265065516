import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { styled } from '@mui/system';
import moment from 'moment';
import { useRouteMatch } from 'react-router';
import { LoadingSpinner } from 'shared-components/components';
import { usePatientNotes, PatientNote } from 'op-components/RO/Notes/Notes';
import NotesFilter from 'op-components/RO/Notes/NotesFilter';
import { GET_PRACTITIONER } from 'op-graphql/queries';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { formatNoteCreationDate } from 'op-utils/helpers';
import { sanitizeNote } from './sanitizeNote';

const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 670px;
`;

const NotesTitle = styled('h2')`
  font-size: 23px;
  color: ${(props) => props.theme.palette.primary.dark};
  margin-bottom: 18px;
`;

const FilterContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 25%;
`;

const FilterTitle = styled('div')`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-right: 10px;
`;

const AllNotesContainer = styled('div')`
  border: 1px solid ${(props) => props.theme.palette.secondary.main};
  border-radius: 4px;
  flex-grow: 1;
  overflow: hidden;
  margin-top: 12px;
  display: flex;
`;

const NotesListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 25%;
  border-right: 1px solid ${(props) => props.theme.palette.secondary.main};
  max-height: 100%;
  overflow-y: auto;
`;

const SingleNoteItem = styled('div')<any>`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  background-color: ${(props) => (props.active ? props.theme.palette.primary.light : null)};
  :hover {
    cursor: pointer;
  }
`;

const NoteItemType = styled('div')`
  font-size: 16px;
  line-height: 24px;
`;

const NoteItemDate = styled('div')`
  font-size: 13px;
  line-height: 15px;
`;

const NotesPreviewContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 75%;
  overflow-y: auto;
`;

const SingleNotePreview = styled('div')`
  padding-top: 16px;
  margin: 0 10px;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.main};
`;

const NameTimeContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
`;

const NameQualificationContainer = styled('div')`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 8px;
`;

const StyledNoteType = styled('div')`
  display: inline-block;
  background: $[theme.palette.secondary.light};
  border-radius: 16px;
  padding: 0 8px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
`;

const NoteText = styled('div')<{ $format: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  white-space: ${({ $format }: { $format: boolean }) => ($format ? 'normal' : 'pre-wrap')};
  word-break: break-word;
  font,
  *,
  & {
    font-family: Arial, sans-serif !important;
    font-size: 1rem !important;
    color: black !important;
  }
`;

const NoNotes = styled('div')`
  padding: 16px;
`;
export const formatNoteDateTime = (note: PatientNote, timezone: string, format: string): string =>
  formatNoteCreationDate(note, timezone, format);

const DisplayNotes = (): JSX.Element => {
  const match = useRouteMatch<any>();
  const { id: patientId } = match.params;
  const [selectedNoteIndex, setSelectedNoteIndex] = useState<number | undefined>();
  const { notes, filteredNotes, filterNoteTypes, setFilterNoteTypes, isLoading, hasErrors } =
    usePatientNotes(patientId);

  const { data: pracData } = useQuery(GET_PRACTITIONER, {
    variables: { patientId },
    skip: !patientId,
  });
  const practitionerTimezone = useMemo(
    () => pracData?.practitioner?.timezone || CurrentAppConfig.DefaultTimezone,
    [pracData?.practitioner?.timezone],
  );

  useEffect(() => {
    const selectedNote = document.getElementById(`note-preview-${selectedNoteIndex}`);
    if (selectedNote) {
      selectedNote.scrollIntoView({ behavior: 'smooth' });
      (selectedNote as HTMLElement).focus({ preventScroll: true });
    }
  }, [selectedNoteIndex]);

  if (isLoading && !notes) return <LoadingSpinner loadingText={'Loading notes'} />;
  if (hasErrors) return <div>Error</div>;
  const NoNotesFound = () => <NoNotes>No notes found.</NoNotes>;
  return (
    <Container>
      <NotesTitle>Notes</NotesTitle>
      <FilterContainer>
        <FilterTitle>Type:</FilterTitle>
        <NotesFilter noteTypes={filterNoteTypes} setFilterNoteTypes={setFilterNoteTypes} />
      </FilterContainer>
      <AllNotesContainer>
        <NotesListContainer>
          {filteredNotes.length ? (
            filteredNotes.map((note: PatientNote, index: number) => (
              <SingleNoteItem
                key={note.id}
                data-test-id={`display-note-${index}`}
                active={index === selectedNoteIndex}
                role="presentation"
                onClick={() => setSelectedNoteIndex(index)}>
                <NoteItemType>{note.messageType}</NoteItemType>
                <NoteItemDate>{formatNoteDateTime(note, practitionerTimezone, 'L')}</NoteItemDate>
              </SingleNoteItem>
            ))
          ) : (
            <NoNotesFound />
          )}
        </NotesListContainer>
        <NotesPreviewContainer>
          {filteredNotes.length ? (
            filteredNotes.map((note: PatientNote, index: number) => (
              <SingleNotePreview key={note.id} id={`note-preview-${index}`}>
                <NameTimeContainer>
                  <NameQualificationContainer>
                    {note.createdBy?.name} &nbsp;
                    {note.practitionerQualification && `(${note.practitionerQualification})`}
                  </NameQualificationContainer>
                  <div>{formatNoteDateTime(note, practitionerTimezone, 'L hh:mmA')}</div>
                </NameTimeContainer>
                {note.messageTypeAlias && <StyledNoteType>{note.messageTypeAlias}</StyledNoteType>}
                <NoteText
                  $format={note.formattedMessage.startsWith('<!DOCTYPE html')}
                  dangerouslySetInnerHTML={sanitizeNote(note.formattedMessage)}
                />
              </SingleNotePreview>
            ))
          ) : (
            <NoNotesFound />
          )}
        </NotesPreviewContainer>
      </AllNotesContainer>
    </Container>
  );
};

export default DisplayNotes;
