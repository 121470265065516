// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Button } from 'gc-ui';
import { Grid } from '@mui/material';
import './FormBuilder.scss';

interface Props {
  onReset?: () => void;
  onBack?: () => void;
  onNext?: () => void;
  onAdditionLeft?: () => void;
  onAdditionLeftMouseEnter?: () => void;
  onAdditionLeftMouseLeave?: () => void;
  showAdditionLeftTooltip?: boolean;
  onAdditionRight?: () => void;
  resetText?: string;
  backText?: string;
  backButtonId?: string;
  nextText?: string;
  nextTextId?: string;
  additionLeftText?: string;
  additionLeftTextId?: string;
  additionRightText?: string;
  additionRightTextId?: string;
  nextDisabled?: boolean;
  backDisabled?: boolean;
  resetDisabled?: boolean;
  nextHidden?: boolean;
  resetHidden?: boolean;
  additionRightDisabled?: boolean;
  additionLeftDisabled?: boolean;
  nextLoading?: boolean;
  additionRightLoading?: boolean;
  backHidden?: boolean;
}

function FormBuilderFooter(props: Props) {
  const {
    onReset,
    onBack,
    onNext,
    onAdditionLeft,
    onAdditionRight,
    resetText,
    backText,
    backButtonId,
    backHidden,
    nextText,
    nextTextId,
    additionLeftText,
    additionLeftTextId,
    additionRightText,
    additionRightTextId,
    nextDisabled,
    backDisabled,
    resetDisabled,
    nextHidden,
    resetHidden,
    additionRightDisabled,
    additionLeftDisabled,
    nextLoading,
    additionRightLoading,
  } = props;
  return (
    <>
      <Grid container className="footer">
        <Grid item>
          {!resetHidden && !!onReset && (
            <Button id={'gc-reset-button'} onClick={onReset} mode="outlined" disabled={resetDisabled}>
              {resetText || 'Reset page'}
            </Button>
          )}
        </Grid>
        <Grid item>
          {onAdditionLeft && (
            <Button
              disabled={additionLeftDisabled}
              id={additionLeftTextId || 'additional-left-text'}
              onClick={onAdditionLeft}
              mode="outlined">
              {additionLeftText || ''}
            </Button>
          )}
        </Grid>
        <Grid item>
          {!backHidden && (
            <Button
              id={backButtonId || 'footer-button-back'}
              onClick={onBack}
              mode={'outlined'}
              disabled={backDisabled}>
              {backText || 'Back'}
            </Button>
          )}
        </Grid>
        <Grid item>
          {!nextHidden && (
            <Button
              id={nextTextId || 'footer-button-continue'}
              busy={nextLoading}
              onClick={onNext}
              disabled={nextDisabled}>
              {nextText || 'Continue'}
            </Button>
          )}
        </Grid>
        <Grid item>
          {!!onAdditionRight && (
            <Button
              id={additionRightTextId}
              busy={additionRightLoading}
              onClick={onAdditionRight}
              disabled={additionRightDisabled}>
              {additionRightText || ''}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default FormBuilderFooter;
