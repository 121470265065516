import React from 'react';
import { MainSection } from 'shared-components/components/UIFormComponents/Modal';
import { sharedContent } from '../constants';

const { mainText1, mainText2 } = sharedContent.reviewAndSubmit.missingQcl;

interface Props {
  sectionName: string;
}
// HTML set from NurseAssessment constants file
const ModalContentMissingQcl = ({ sectionName }: Props): JSX.Element => (
  <MainSection>{<div dangerouslySetInnerHTML={{ __html: `${mainText1} ${sectionName} ${mainText2}` }} />}</MainSection>
);

export default ModalContentMissingQcl;
