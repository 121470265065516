// eslint-disable-next-line no-use-before-define
import { ModalSaveExit, ModalSubmit, PatientFormFooter } from 'op-components';
import { NavigatorDirection } from 'op-enums';
import React, { Component, Fragment } from 'react';

import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { StepperLink } from 'shared-components/interfaces';
import { FormContext } from '../../../pages/OP/PatientNavigation/context';
import { getNextForm } from '../../../pages/OP/PatientNavigation/helpers';
import { isUs } from 'op-utils/helpers';
import './HANavigator.scss';
import { RegistrationContextType } from 'op-contexts/RegistrationContext/RegistrationContext';
import { navigateToExternalURL } from 'shared-components/utils';

interface Props extends RouteComponentProps<{ patientId?: string }> {
  links: StepperLink[];
  redirectToRegistraton?: boolean;
  loading: boolean;
  submitCalled: boolean;
  submitFunction: () => void;
  isPso: boolean;
  generateURL?: () => void;
  isInClinic?: boolean;
  registrationContext: RegistrationContextType;
  showNewRego: boolean;
}

interface State {
  submitModalIsOpen: boolean;
  saveExitModalOpen: boolean;
}

class HANavigator extends Component<Props, State> {
  public static contextType = FormContext;

  public constructor(props: Props) {
    super(props);

    this.state = {
      submitModalIsOpen: false,
      saveExitModalOpen: false,
    };
  }

  public render(): JSX.Element {
    const {
      links,
      redirectToRegistraton = false,
      loading,
      submitCalled,
      submitFunction,
      location: { pathname },
      match: {
        params: { patientId },
      },
      isPso,
      isInClinic,
      generateURL,
      registrationContext,
    } = this.props;

    const pathSegments = pathname.split('/');
    const currentPage = pathSegments[pathSegments.length - 1];

    let pageNumber: number | undefined = undefined;

    for (let i = 0; i < links.length; i++) {
      const link = links[i];

      const linkHref = link.href.replace(/\//, '');

      if (linkHref === currentPage) {
        pageNumber = i;
        break;
      }
    }
    const firstPage = pageNumber === 0;
    const lastPage = pageNumber === links.length - 1;

    // Set up the submit button to display submitting for usability.
    const submitButtonText = loading ? 'Submitting...' : 'Submit';

    const nextForm = getNextForm(
      //@ts-ignore
      this.context.formStatusDetails,
      patientId!,
      'healthAssessment',
      this.props.showNewRego,
    );

    if (submitCalled && !loading) {
      if (isUs()) {
        if (redirectToRegistraton) {
          if (isInClinic) {
            return <Redirect to={{ pathname: `/patient/${patientId}/home` }} />;
          }
          return <Redirect to={{ pathname: '/patient/registrationComplete' }} />;
        }
        return <Redirect to={{ pathname: `/patient/${patientId}/registration/basic` }} />;
      }

      if (nextForm.name === 'theranostics') {
        navigateToExternalURL(nextForm.path);
        return <></>;
      }

      return <Redirect to={{ pathname: nextForm.path, state: { pxRedirect: true } }} />;
    }

    return (
      <Fragment>
        <ModalSaveExit
          isOpen={this.state.saveExitModalOpen}
          dismissFunction={this.dismissSNEModal}
          exitForm={this.exitForm}
          exitText={'Exit form'}
        />
        <ModalSubmit
          isOpen={this.state.submitModalIsOpen}
          dismissFunction={this.dismissSubmitModal}
          submitText={submitButtonText}
          submitForm={submitFunction}
          isUs={isUs()}
          continueNextForm={nextForm['name'] !== 'home'}
          isPSO={false}
        />

        <PatientFormFooter
          navComponentId="ha-nav"
          isPso={isPso}
          isFirstPage={firstPage}
          isLastPage={lastPage}
          submitCalled={submitCalled}
          showPrintPdfButton={isPso ?? false}
          showContinueButton={isPso ? false : true}
          handleClickOnBack={() => this.handleBackButton(lastPage, pageNumber, isPso)}
          handleSaveAndExit={() => this.setState({ saveExitModalOpen: true })}
          handleClickOnContinue={() => this.handleContinueButton(lastPage, pageNumber, isPso, generateURL)}
          summaryVisited={registrationContext?.haSummaryVisited}
        />
      </Fragment>
    );
  }

  private buttonHandler = (currentPageIndex: number, direction: NavigatorDirection, lastPage: boolean): void => {
    const { history, links } = this.props;

    // On the last page, so call the submission instead
    if (lastPage && direction === NavigatorDirection.FORWARD) {
      this.setState({ submitModalIsOpen: true });
    } else if (this.props.registrationContext.haSummaryVisited && direction === NavigatorDirection.FORWARD) {
      // 'Continue' has been replaced with 'Return to submit'
      const patientId = this.props.match.params.patientId;
      const newPath = `/patient/${patientId}/health/summary`;
      history.push(newPath);
    } else {
      // Need to traverse based on direction
      let navigateTo = '#';
      let nextIndex = currentPageIndex;

      switch (direction) {
        case NavigatorDirection.FORWARD:
          nextIndex += 1;
          break;
        case NavigatorDirection.BACK:
          nextIndex -= 1;
          break;
        default:
          break;
      }

      if (nextIndex >= 0 && nextIndex <= links.length - 1) {
        navigateTo = links[nextIndex].href.replace(/\//, '');
        history.push(navigateTo);
      }
    }
  };

  private dismissSubmitModal = (): void => {
    this.setState({ submitModalIsOpen: false });
  };

  private dismissSNEModal = (): void => {
    this.setState({ saveExitModalOpen: false });
  };

  private exitForm = (): void => {
    const {
      history,
      isPso,
      match: {
        params: { patientId },
      },
    } = this.props;

    if (isPso) {
      history.push('/search');
    } else {
      history.push({ pathname: `/patient/${patientId}/home`, state: { pxRedirect: true } });
    }
  };

  private handleBackButton = (isLastPage: boolean, pageIndex?: number, isPso?: boolean) => {
    if (isPso) {
      window.history.back();
    }
    if (pageIndex !== undefined) {
      this.buttonHandler(pageIndex, NavigatorDirection.BACK, isLastPage);
    }
  };

  private handleContinueButton = (isLastPage: boolean, pageIndex?: number, isPso?: boolean, generateURL?: any) => {
    if (isPso) {
      if (generateURL) {
        generateURL();
      }
    } else {
      if (pageIndex !== undefined) {
        this.buttonHandler(pageIndex, NavigatorDirection.FORWARD, isLastPage);
      }
    }
  };
}

const routedComponent = withRouter(HANavigator);
export default routedComponent;
