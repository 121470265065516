// eslint-disable-next-line no-use-before-define
import { FormikProps } from 'formik';
import FBFormControl from './Components/FBFormControl';
import { FormBuilderComponentConfig, FormBuilderComponentProps } from './FormBuilderInterfaces';
import { fieldMeetsConditions } from './common';
import {
  FormRow,
  FormControlLabel,
  InputContainer,
  ChildField,
  SectionTitle,
} from 'op-pages/RO/FormBuilder/Forms/FormElements';

function parseFormikError(formikProps: FormikProps<any>, fieldName: string): string | undefined {
  const error = formikProps.errors[fieldName];
  if (error && (formikProps.touched[fieldName] || formikProps.submitCount > 0)) {
    return String(error);
  }
  return undefined;
}

function clearSubComponentValues(component: FormBuilderComponentConfig, formikProps: FormikProps<any>): void {
  if (formikProps.values && formikProps.values[component.field?.name] != null) {
    formikProps.setFieldValue(component.field.name, null, false);
  }
  component.subComponents?.forEach((subComponent) => {
    clearSubComponentValues(subComponent, formikProps);
  });
}

const SubComponents = (props: FormBuilderComponentProps) => {
  const { parentIsVisible, component, formikProps } = props;
  const isVisible = parentIsVisible && fieldMeetsConditions(formikProps.values, component.visible);

  return component.subComponents?.map((subComponent) => {
    return (
      <FormBuilderComponent
        key={`sub_${subComponent.label}`}
        parentIsVisible={isVisible}
        {...props} // must come before the below component prop, so that 'subComponent' will replace the ...props parent 'component'
        component={subComponent}
      />
    );
  });
};

const FormBuilderComponent = (props: FormBuilderComponentProps): JSX.Element | null => {
  const { component, parent, formikProps } = props;

  if (!fieldMeetsConditions(formikProps.values, component.visible)) {
    clearSubComponentValues(component, formikProps);
    return null;
  }

  if (component.type === 'Container') {
    return (
      <fieldset>
        <SectionTitle>{component.label} </SectionTitle>
        {SubComponents({ ...props })}
      </fieldset>
    );
  }

  return (
    <>
      <FormRow data-cy={`form-row-${component.field.name}`}>
        {component.field.formControl !== 'CheckboxTile' && (
          <FormControlLabel
            required={
              component.validators && fieldMeetsConditions(formikProps.values, component.validators['required'])
            }
            tooltip={component.tooltip}>
            {component.label}
          </FormControlLabel>
        )}
        <InputContainer error={parseFormikError(formikProps, component.field.name)}>
          <FBFormControl {...props} />
        </InputContainer>
      </FormRow>

      <ChildField
        id={`${component.type}-${parent?.label}-child-section`}
        key={`${component.type}-${parent?.label}-child-section`}>
        {SubComponents({ ...props })}
      </ChildField>
    </>
  );
};

export default FormBuilderComponent;
