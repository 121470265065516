// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'gc-ui';
import { ToggleButtons } from 'shared-components/components/FormFields';
import { BOOLEAN_OPTIONS } from './constants';
import './ModalSubmitSimulation.scss';
import { Stack } from '@mui/material';

interface SimulationSubmissionProps {
  isOpen: boolean;
  status: string;
  dismissFunction: () => void;
  submitFunction: (value: string) => void;
  handleSubmission: (value: string) => void;
}
export interface ButtonInterface {
  text: string;
  value: string;
}

const ModalSimulationSubmission = (props: SimulationSubmissionProps): JSX.Element => {
  const { isOpen, dismissFunction, submitFunction, handleSubmission, status } = props;
  const [currentValue, setCurrentValue] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const checkSimSubmission = () => {
    if (currentValue === '') {
      setIsInvalid(true);
      return;
    }
    handleSubmission(status);
    dismissFunction();
  };
  const clearImmediateValuesCloseModal = () => {
    setIsInvalid(false);
    setCurrentValue('');
    dismissFunction();
  };

  const BodyElements = (): JSX.Element => {
    return (
      <Stack gap={2}>
        A change to the simulation or treatment sites has occured. Please confirm if a new simulation is required*
        <ToggleButtons
          id={'modal-simulation-submission'}
          options={BOOLEAN_OPTIONS}
          fullWidth
          value={currentValue}
          handleChange={(value: string): void => {
            setIsInvalid(false);
            setCurrentValue(value);
            submitFunction(value);
          }}
          error={isInvalid}
        />
      </Stack>
    );
  };

  return (
    <Modal open={isOpen} PaperProps={{ style: { maxWidth: '500px' } }} onClose={clearImmediateValuesCloseModal}>
      <ModalHeader>Would you like to request a new simulation?</ModalHeader>
      <ModalBody>
        <BodyElements />
      </ModalBody>
      <ModalFooter>
        <Button mode="outlined" size="auto" onClick={clearImmediateValuesCloseModal}>
          Cancel
        </Button>
        <Button data-testid="submit-button" color="primary" size="auto" onClick={checkSimSubmission}>
          Submit {status}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalSimulationSubmission;
