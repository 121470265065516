// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { useMutation, useQuery } from '@apollo/client';
import { Route, Switch, useRouteMatch, withRouter, useHistory, useParams, Link, generatePath } from 'react-router-dom';
import { OncologyStepper, usRegoSteps, MiddleContainerCol, RightContainerCol } from 'shared-components/components';
import { registrationPath } from './Helper';
import {
  Basic,
  ContactDetails,
  Referrer,
  EmergencyContact,
  Feedback,
  Address,
  ReviewAndSubmit,
  Insurance,
  Demographics,
  SocialGeographicHistory,
} from '.';
import { styled } from '@mui/system';
import { SideNavContainer, HeaderBar, ModalSaveExit, WelcomeBackModal } from 'op-components';
import { UPDATE_LAST_VISITED_SECTION, LAST_VISITED_QUERY } from './RegistrationFormQueries';
import { supportedSize } from 'shared-components/StyledComponents/breakpoints';
import { StyledTitle, StyledArrow } from '../../../components/SideNav/common';
import PatientEMR from 'op-components/SideNav/PatientEMR';
import _ from 'lodash';

const StyledContainer = styled('div')`
  height: calc(100vh - 54px);
`;

const StyledRow = styled('div')`
  height: 100%;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;
`;

const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;

  div:nth-child(1) {
    flex-shrink: 0;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px 0 16px;
  text-decoration: none;
  height: 45px;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const StyledDivider = styled('div')`
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.main};
  margin-left: 16px;
  margin-right: 16px;
  padding-top: 16px;
`;

const StyledStepperHeading = styled('div')`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  margin: 16px 0 0 16px;
  color: ${(props) => props.theme.palette.secondary.dark};
`;

const StyledSideNavContainer = styled((props: any) => <SideNavContainer {..._.omit(props, ['$isvisible'])} />)`
  @media all and (max-width: ${supportedSize.transition}px) {
    display: ${(props) => (props?.$isvisible ? 'auto' : 'none')};
  }
`;

const getCurrentPage = (): string => {
  const url = window.location.href;
  const currentPage = url.split('/').pop() || '';
  return currentPage;
};

const RegistrationUS = (): JSX.Element => {
  const { path } = useRouteMatch();
  const { patientId: id } = useParams<{ patientId: string }>();
  const history = useHistory();

  const [saveExitModalOpen, setSaveExitModalOpen] = useState(false);
  const [welcomeBackModalOpen, setWelcomeBackModalOpen] = useState(false);
  const [isPso, setIsPso] = useState(false);

  const [updateLastVisited] = useMutation(UPDATE_LAST_VISITED_SECTION);
  const { data, error } = useQuery(LAST_VISITED_QUERY, {
    variables: { id },
    onCompleted: (data) => {
      const { patient, user } = data;
      setIsPso(user?.isPso);
      const currentPage = getCurrentPage();
      if (
        user?.isPso === false &&
        currentPage !== 'basic' &&
        patient?.lastVisitedSection === getCurrentPage() &&
        patient?.regFormStatus
      ) {
        setWelcomeBackModalOpen(true);
      }
    },
  });

  if (error || !data) return <></>;

  const BackToPTSummary = (): JSX.Element => {
    const url = generatePath(`/navigator/patient/${id}/summary`);
    return (
      <>
        <StyledLink to={url} data-testid="back-to-summary-button">
          <StyledArrow />
          <StyledTitle>Back to patient summary</StyledTitle>
        </StyledLink>
        <StyledDivider />
      </>
    );
  };

  const returnToStart = (): void => {
    updateLastVisited({ variables: { id, lastVisitedSection: '' } });
    history.push({ pathname: registrationPath(id, 'basic'), state: { pxRedirect: true } });
  };

  const closeWelcomeModal = () => {
    updateLastVisited({ variables: { id, lastVisitedSection: '' } });
    setWelcomeBackModalOpen(false);
  };

  const exitForm = (): void => {
    updateLastVisited({ variables: { id, lastVisitedSection: getCurrentPage() } });
    history.push({ pathname: `/patient/${id}/home`, state: { pxRedirect: true } });
  };
  const showSideNav = !window.location.href.includes('summary');

  return (
    <>
      <WelcomeBackModal
        returnToStart={() => returnToStart()}
        dismissFunction={() => closeWelcomeModal()}
        updateFirstReturn={() => {}}
        isOpen={welcomeBackModalOpen}
      />
      <ModalSaveExit
        isOpen={saveExitModalOpen}
        dismissFunction={() => setSaveExitModalOpen(false)}
        exitForm={exitForm}
        exitText={'Exit form'}
      />
      <StyledWrapper>
        <HeaderBar defaultHeader={!isPso} />
        <StyledContainer>
          <StyledRow>
            <StyledSideNavContainer $isvisible={showSideNav}>
              {isPso && <PatientEMR patientId={id} />}
              <OncologyStepper steps={usRegoSteps} showTopDivider>
                {isPso && <BackToPTSummary />}
                <StyledStepperHeading>REGISTRATION FORM</StyledStepperHeading>
              </OncologyStepper>
            </StyledSideNavContainer>
            <MiddleContainerCol>
              <Switch>
                <Route path={`${path}/basic`}>
                  <Basic exitForm={setSaveExitModalOpen} />
                </Route>
                <Route path={`${path}/contact`}>
                  <ContactDetails exitForm={setSaveExitModalOpen} />
                </Route>
                <Route path={`${path}/address`}>
                  <Address exitForm={setSaveExitModalOpen} />
                </Route>
                <Route path={`${path}/emergencyContact`}>
                  <EmergencyContact exitForm={setSaveExitModalOpen} />
                </Route>
                <Route path={`${path}/referrers`}>
                  <Referrer exitForm={setSaveExitModalOpen} />
                </Route>
                <Route path={`${path}/insurance`}>
                  <Insurance exitForm={setSaveExitModalOpen} />
                </Route>
                <Route path={`${path}/demographics`}>
                  <Demographics exitForm={setSaveExitModalOpen} />
                </Route>
                <Route path={`${path}/feedback`}>
                  <Feedback exitForm={setSaveExitModalOpen} />
                </Route>
                <Route path={`${path}/socialHistory`}>
                  <SocialGeographicHistory exitForm={setSaveExitModalOpen} />
                </Route>
                <Route path={`${path}/summary`}>
                  <ReviewAndSubmit exitForm={setSaveExitModalOpen} />
                </Route>
              </Switch>
            </MiddleContainerCol>
            <RightContainerCol />
          </StyledRow>
        </StyledContainer>
      </StyledWrapper>
    </>
  );
};

const apolloComponent = withApollo(withRouter(RegistrationUS));
export default apolloComponent;
