// eslint-disable-next-line no-use-before-define
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'gc-ui';
import { useState } from 'react';
import SelectButton from 'shared-components/components/UIFormComponents/SelectButton';
import { Divider, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material';

const OptionsContainer = styled('section')`
  display: flex;
`;

const SelectButtonContainer = styled('section')`
  margin-right: 24px;
`;

const TextContainer = styled('div')`
  margin-bottom: 16px;
`;
interface Props {
  isOpen: boolean;
  dismissFunction: () => void;
  submitForm: (value: string) => void;
  cancelButtonText: string;
  submitButtonText: string;
  title: string;
  options: string[];
  aboveOptionsText?: string;
}

const ModalSelect = ({
  dismissFunction,
  isOpen,
  submitForm,
  cancelButtonText,
  submitButtonText,
  title,
  aboveOptionsText,
  options,
}: Props): JSX.Element => {
  const [selectedButton, setSelectedButton] = useState('');
  const componentClass = 'modal-select';
  const theme = useTheme();

  return (
    <Modal
      id={componentClass}
      open={isOpen}
      onClose={dismissFunction}
      maxWidth="xs"
      PaperProps={{ style: { minWidth: '300px' } }}>
      <ModalHeader>
        <Typography variant="h5" fontWeight={600}>
          {title}
        </Typography>
      </ModalHeader>
      <ModalBody
        sx={{ padding: '20px 24px !important', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {aboveOptionsText && <TextContainer>{aboveOptionsText}</TextContainer>}
        <OptionsContainer>
          {options.map((item: string) => {
            return (
              <SelectButtonContainer key={item}>
                <SelectButton
                  buttonText={item}
                  active={item === selectedButton}
                  onClick={() => setSelectedButton(item)}
                  activeColor={theme.palette.primary.main}
                  width="152px"
                />
              </SelectButtonContainer>
            );
          })}
        </OptionsContainer>
      </ModalBody>
      <Divider />
      <ModalFooter className={`${componentClass}-footer`} sx={{ padding: '16px' }}>
        <Button
          id={`${componentClass}-close-btn`}
          mode="outlined"
          size="auto"
          onClick={dismissFunction}
          data-test-id={`${cancelButtonText}-button`}>
          {cancelButtonText}
        </Button>
        <Button
          id={`${componentClass}-submit-btn`}
          size="small"
          onClick={() => submitForm(selectedButton)}
          data-test-id={`${submitButtonText}-button`}>
          {submitButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalSelect;
