import React, { useState } from 'react';
import { LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
import ApptTable from './ApptTable';
import LocationTable from './LocationTable';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import GCButton from 'shared-components/components/FormComponents/GCButton/GCButton';
import { ButtonType } from 'shared-components/enums';
import { Modal, ModalBody, ModalHeader } from 'gc-ui';

interface FileUploadProps {
  file: any;
  mapType: string;
  showInstructions: boolean;
}

const MAPPING_FILE_UPLOAD_PAYLOAD = gql`
  query MappingFileUploadPayLoad($fileName: String!) {
    mappingFileUploadPayload(fileName: $fileName) {
      awsPayload
      awsKey
      url
    }
  }
`;

const CREATE_APPOINTMENT_UPDATE = gql`
  mutation CreateAppointmentUpdate($key: String!) {
    createAppointmentUpdate(key: $key) {
      mappingUpdate {
        id
      }
      results {
        activityCode
        system
        mappedValue
        errorFields
      }
    }
  }
`;

const SAVE_APPOINTMENT_MAPPINGS = gql`
  mutation SaveAppointmentMappings($key: String!) {
    saveAppointmentMappings(key: $key) {
      ok
    }
  }
`;

const CREATE_LOCATION_UPDATE = gql`
  mutation CreateLocationUpdate($key: String!) {
    createLocationUpdate(key: $key) {
      mappingUpdate {
        id
      }
      results {
        locationId
        system
        locationName
        locationAddr1
        locationAddr2
        locationCity
        locationPostcode
        locationState
        locationCountry
        locationPhone
        errorFields
      }
    }
  }
`;

const SAVE_LOCATION_MAPPINGS = gql`
  mutation SaveLocationMappings($key: String!) {
    saveLocationMappings(key: $key) {
      ok
    }
  }
`;

const APPLY_APPOINTMENT_MAPPINGS = gql`
  mutation ApplyAppointmentMappings($key: String!) {
    applyAppointmentMappings(key: $key) {
      ok
    }
  }
`;

const UploadBarContainer = styled('div')`
  margin-top: 30px;
`;

const PageContainer = styled('div')`
  margin: 10px auto;
  width: 90%;
  text-align: center;
  vertical-align: middle;
  height: 100%;
`;

const InputContainer = styled('div')`
  text-align-last: center;
`;

const InstructionContainer = styled('div')`
  margin: 30px auto;
  display: flex;
  width: 40%;
  flex-direction: column;
  text-align: left;
`;

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  vertical-align: middle;
  margin: 30px auto;
  width: 800px;
`;

const SaveButtonContainer = styled('div')`
  margin-right: 20px;
`;

const Footer = styled('div')`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.palette.secondary.main};
  background-color: white;
  height: 8%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: red;
`;

const ErrorMessage = styled('h1')`
  color: red;
  margin-top: 30px;
`;

const HideButton = styled('button')`
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: ${(props) => props.theme.palette.info.main};
  text-decoration: underline;
  cursor: pointer;
`;

const Buttons = {
  appointments: {
    title: 'Update Appointments',
  },
  locations: {
    title: 'Update Locations',
  },
};

const mapTypes = {
  appointment: 'appointment',
  location: 'location',
};

const MappingUpdate = (): JSX.Element => {
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [file, setFile] = useState();
  const [showInstructions, setShowInstructions] = useState(true);
  const [updateAppt, setUpdateAppt] = useState(false);
  const [updateLocation, setUpdateLocation] = useState(false);
  const [mapType, setMapType] = useState('');

  return (
    <PageContainer>
      <div>Would you like to update mappings for Appointments or Locations?</div>
      <ButtonContainer>
        <GCButton
          title={Buttons.appointments.title}
          type={updateAppt ? ButtonType.GREEN : ButtonType.DISABLED}
          onClick={() => {
            setUpdateAppt(true);
            setUpdateLocation(false);
            setMapType(mapTypes.appointment);
          }}
          disabled={updateAppt}
        />
        <GCButton
          title={Buttons.locations.title}
          type={updateLocation ? ButtonType.GREEN : ButtonType.DISABLED}
          onClick={() => {
            setUpdateAppt(false);
            setUpdateLocation(true);
            setMapType(mapTypes.location);
          }}
          disabled={updateLocation}
        />
      </ButtonContainer>
      {(updateAppt || updateLocation) && (
        <>
          <InstructionContainer>
            <h1>
              Instructions on how to submit file (
              <HideButton onClick={() => setShowInstructions(!showInstructions)}>
                {showInstructions ? 'hide' : 'show'}
              </HideButton>
              )
            </h1>
            {showInstructions && (
              <>
                <p>
                  1. Compile all work sheets into one sheet (i.e combine all the states). Remember to keep appointments
                  and locations separate
                </p>
                <p>2. Remove the headers from the file so it only contains the data that needs to be mapped</p>
                <p>3. Upload the file. Make sure file is of type .xlsx, .xlsm, .xls or .xml </p>
                <p>
                  4. Errors will be highlighted in red. Review the spreadsheet if there are any errors and upload again
                </p>
              </>
            )}
          </InstructionContainer>
          <InputContainer>
            <h1
              onClick={(): void => {
                fileInputRef!.current!.click();
              }}>
              Click or drag over this to attach files
            </h1>
            <input
              id="fileinput"
              type="file"
              ref={fileInputRef}
              // ref={this.fileInputRef}
              onClick={(e: any): void => {
                e.target.value = null;
              }}
              onChange={(e: any): void => {
                setFile(e.target.files[0]);
              }}
            />
          </InputContainer>
        </>
      )}
      {file !== undefined && <FileUpload file={file!} mapType={mapType} showInstructions={showInstructions} />}
    </PageContainer>
  );
};

interface PayloadVars {
  fileName: string;
}
interface PayloadData {
  mappingFileUploadPayload: {
    url: string;
    awsKey: string;
    awsPayload: any;
    fields: any;
  };
}

const FileUpload = (props: FileUploadProps) => {
  const { file, mapType, showInstructions } = props;
  const { loading, error, data } = useQuery<PayloadData, PayloadVars>(MAPPING_FILE_UPLOAD_PAYLOAD, {
    variables: { fileName: file.name },
  });

  const [createMappingUpdate] = useMutation(
    mapType === mapTypes.appointment ? CREATE_APPOINTMENT_UPDATE : CREATE_LOCATION_UPDATE,
  );
  const [saveMappingUpdates] = useMutation(
    mapType === mapTypes.appointment ? SAVE_APPOINTMENT_MAPPINGS : SAVE_LOCATION_MAPPINGS,
  );
  const [applyMappingUpdates] = useMutation(APPLY_APPOINTMENT_MAPPINGS);
  const [progress, setProgress] = useState(0);
  const [hasBegunUpload, setHasBegunUpload] = useState(false);
  const [mapData, setMapData] = useState(null);
  const [readyToSave, setReadyToSave] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [badUpload, setBadUpload] = useState(false);

  if (error) {
    return <div>error</div>;
  }
  if (loading) {
    return <div>loading</div>;
  }

  const setSaveFalse = (bool: boolean) => {
    setReadyToSave(bool);
  };

  if (data && data.mappingFileUploadPayload) {
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    const uploadUrl = data.mappingFileUploadPayload.url;
    const payloadData = data.mappingFileUploadPayload.awsPayload;

    formData.append('Content-Type', file.type);
    const parsedPayload = JSON.parse(payloadData);
    for (const [key, value] of Object.entries(parsedPayload.fields)) {
      formData.append(key, String(value));
    }
    formData.append('file', file);

    xhr.upload.addEventListener('progress', (e): void => {
      const progress = (e.loaded / e.total) * 100;
      setProgress(progress);
    });

    xhr.addEventListener('error', (): void => {
      alert('There was an error');
    });

    xhr.addEventListener('load', async (): Promise<void> => {
      const statusCode = xhr.status;
      if ([200, 201, 204].includes(statusCode)) {
        const updateData = mapType === mapTypes.appointment ? 'createAppointmentUpdate' : 'createLocationUpdate';
        const mappingData = await createMappingUpdate({ variables: { key: data.mappingFileUploadPayload.awsKey } });
        setMapData(mappingData.data[updateData].results);
        !mappingData.data[updateData].results && setBadUpload(true);
        // Then set some UI state with the info & stop loading.
      }
    });

    if (!hasBegunUpload) {
      xhr.open('POST', uploadUrl);
      xhr.send(formData);
      setHasBegunUpload(true);
    }

    return (
      <>
        <UploadBarContainer>
          {progress !== 100 && <div>Uploading {Math.round(progress)}%</div>}
          {progress === 100 && <div>Uploaded</div>}
          <LinearProgress id="bar-adjustments" color="primary" variant="determinate" value={progress} />
        </UploadBarContainer>
        {mapData && mapType === mapTypes.appointment && (
          <ApptTable apptData={mapData} setSaveFalse={setSaveFalse} showInstructions={showInstructions} />
        )}
        {mapData && mapType === mapTypes.location && (
          <LocationTable locationData={mapData} setSaveFalse={setSaveFalse} showInstructions={showInstructions} />
        )}
        {badUpload && (
          <ErrorMessage>
            Error occured. Make sure you are uploading an Excel file and are logged in as an admin
          </ErrorMessage>
        )}
        <Footer>
          <SaveButtonContainer>
            <GCButton
              title={'Save Mappings'}
              type={!readyToSave && !badUpload ? ButtonType.GREEN : ButtonType.DISABLED}
              disabled={readyToSave || badUpload}
              onClick={() => {
                setModalOpen(true);
                saveMappingUpdates({ variables: { key: data.mappingFileUploadPayload.awsKey } });
              }}
            />
          </SaveButtonContainer>
        </Footer>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          className="modal-gc pending-submit-modal"
          size="medium"
          centered={true}>
          <ModalHeader toggle={() => setModalOpen(false)}>Nearly there..</ModalHeader>
          <ModalBody>
            <div style={{ textAlign: 'center' }}>
              <GCButton
                title={'Apply Mappings'}
                type={ButtonType.GREEN}
                onClick={() => {
                  applyMappingUpdates({ variables: { key: data.mappingFileUploadPayload.awsKey } });
                  setModalOpen(false);
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
  return <></>;
};

export default MappingUpdate;
