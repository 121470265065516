import React from 'react';
import GCButton from 'shared-components/components/UIFormComponents/GCButton';
import './ModalCreateNewPatient.scss';
import { isUs } from 'op-utils';
import { CircularProgress, Typography, useTheme } from '@mui/material';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'gc-ui';

interface ModalCreateNewPatientIProps {
  isOpen: boolean;
  handleClose: () => void;
  headerText: string;
  cancelButtonText: string;
  continueButtonText: string;
  isLoading: boolean;
  submissionProcessingTime: number;
  rightButtonHandleClick: () => void;
}

const ModalCreateNewPatient = (props: ModalCreateNewPatientIProps) => {
  const {
    isOpen,
    handleClose,
    headerText,
    submissionProcessingTime,
    isLoading,
    cancelButtonText,
    continueButtonText,
    rightButtonHandleClick,
  } = props;
  const theme = useTheme();
  return (
    <Modal onClose={handleClose} open={isOpen} maxWidth="sm">
      <ModalHeader toggle={handleClose}>
        <Typography variant="h5" fontWeight={600}>
          {headerText}
        </Typography>
      </ModalHeader>
      <ModalBody sx={{ lineHeight: '1.4rem', padding: '8px 24px !important' }}>
        {isUs() ? (
          <p>Proceeding will create a new patient record. Please ensure all patient details are correct.</p>
        ) : (
          <p>
            Proceeding will create a new patient record in both Horizon and Mosaiq. Please ensure all patient details
            are correct.
          </p>
        )}

        <p>
          It may take up to {submissionProcessingTime} min{submissionProcessingTime > 1 && 's'}, please do{' '}
          <b>
            <u>NOT</u>
          </b>{' '}
          close the browser while loading.
        </p>
        <br />
      </ModalBody>
      <ModalFooter
        style={{
          borderTop: `1px solid ${theme.palette.secondary.main}`,
          padding: '24px',
        }}>
        <GCButton
          buttonText={isLoading ? <CircularProgress size="1rem" color="primary" /> : cancelButtonText}
          backgroundColor="white"
          borderColor={theme.palette.primary.dark}
          textColor={theme.palette.text.primary}
          fontWeight={'700'}
          fontSize={'14px'}
          minWidth={'91px'}
          width={'91px'}
          height={'40px'}
          margin={'0 16px 0 0'}
          onClick={handleClose}
          disabled={isLoading}
        />
        <GCButton
          buttonText={isLoading ? <CircularProgress size="1rem" color="warning" /> : continueButtonText}
          backgroundColor={theme.palette.primary.dark}
          borderColor={theme.palette.primary.dark}
          textColor={theme.palette.primary.contrastText}
          fontWeight={'700'}
          fontSize={'14px'}
          maxWidth={'140px'}
          height={'40px'}
          margin={'0 16px 0 0'}
          onClick={rightButtonHandleClick}
          disabled={isLoading}
        />
      </ModalFooter>
    </Modal>
  );
};

ModalCreateNewPatient.defaultProps = {
  submissionProcessingTime: 1,
  headerText: 'Create New Patient',
  cancelButtonText: 'Cancel',
  continueButtonText: 'Create patient',
};

export default ModalCreateNewPatient;
