import React, { useContext, useEffect, useState } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import moment from 'moment';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Box,
  Typography,
  Divider,
  useTheme,
  Stack,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { RegistrationHomeClinicApollo, PatientIdentifierBar, ModalSelect } from 'op-components';
import { NavigationContext } from 'op-contexts';
import { FormStatus, RoleType, FormTypeId } from 'op-enums';
import { getFormStatus } from 'op-utils';
import { navigateToExternalURL } from 'shared-components/utils';
import { ActionButton, DeleteButton, AmendButton } from 'shared-components/components/UIFormComponents';
import { MandatoryPatient, LocationListData, FeatureOption } from 'op-interfaces';
import { ProfileNavigation, FormInfo, ThaInfo, pCCCInfo, PreCTTriageInfo } from './PatientFormSummaryApollo';
import {
  CREATE_PRE_CT_TRIAGE,
  CREATE_PRE_CT_CHART_CHECK,
  DELETE_PRE_CT_CHART_CHECK,
  DELETE_PRE_CT_TRIAGE,
  QUERY_NURSE,
  CREATE_AMENDED_PRE_CT_CHART_CHECK,
  CREATE_AMENDED_PRE_CT_TRIAGE,
  DUPLICATE_PRE_CT_CHART_CHECK,
  DUPLICATE_PRE_CT_TRIAGE,
} from './PatientFormSummaryQueries';
import './PatientFormSummary.scss';
import { styled } from '@mui/system';
import DuplicateButton from 'shared-components/components/UIFormComponents/DuplicateButton';
import { routes as chartCheckRoutes } from 'op-pages/OP/NurseAssessment/PreCtChartCheck/routes';
import { routes as triageRoutes } from 'op-pages/OP/NurseAssessment/PreCtTriage/routes';
import { AssessmentContext } from 'op-pages/OP/PatientFormSummary/context';
import { isUs } from 'op-utils';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'gc-ui';

export interface RegFormInfo extends MandatoryPatient {
  regFormStatus: string;
  updatedAt: string;
  updatedBy: string;
}

interface Props {
  primaryRole: string;
  patient: RegFormInfo;
  haInfo?: FormInfo;
  dtInfo?: FormInfo;
  dtEotInfo?: FormInfo;
  covidInfo?: FormInfo;
  thaInfo?: ThaInfo;
  pCCCInfo?: pCCCInfo[];
  preCtTriageInfo?: PreCTTriageInfo[];
  profile?: ProfileNavigation;
  locationsPsoSystem?: LocationListData;
  diffMqData?: string;
  featureOptions?: FeatureOption[];
}

interface NurseAssessmentData {
  id: string;
  title: string;
  url: string;
  displayTo: string[];
}

const NOT_STARTED = 'Not started';

const TableContainer = styled('div')`
  max-height: 65%;
  overflow-y: auto;
`;

const NurseDeleteFormText = styled('div')`
  margin-bottom: 40px;
  line-height: 1.6rem;
`;

const { SUPERUSER, PSO, NURSE } = RoleType;
const {
  REGISTRATION_FORM,
  HEALTH_ASSESSMENT,
  DISTRESS_THERMOMETER,
  DISTRESS_THERMOMETER_EOT,
  COVID_SCREENING,
  THERANOSTICS,
} = FormTypeId;

const formType = {
  registration: 'registration',
  healthAssessment: 'ha',
  distressThermometer: 'dt',
  distressThermometerEot: 'dt-eot',
  covid: 'covid',
  theranostics: 'tha',
  initialTriage: 'PreCtTriageType',
  initialChartCheck: 'PreCtChartCheckType',
};

export const staticRowForm = [DISTRESS_THERMOMETER, DISTRESS_THERMOMETER_EOT];

const ResizedDeleteButton = styled(DeleteButton)`
  svg {
    width: 18px;
    height: 18px;
  }
`;

const formatTime = (utcTime: string): string => {
  return moment
    .utc(utcTime)
    .local()
    .format(`${isUs() ? 'MM/DD/YYYY' : 'DD/MM/YYYY'} hh:mm A`);
};

const checkUnclickable = (isSubmitted: boolean): boolean | undefined => {
  return isSubmitted ? true : undefined;
};

const duplicateChartCheckTooltipMessage =
  'This will create a new Chart Check assessment with pre-filled answers copied from this assessment. If the patient has submitted a new digital health assessment, this will be visible in the read-only tables.';
const duplicateTriageTooltipMessage =
  'This will create a new Triage assessment with pre-filled answers copied from this assessment. If the patient has submitted a new digital health assessment, this will be visible in the read-only tables.';

export const getFormData = (props: any): any => {
  const { patient, haInfo, dtInfo, dtEotInfo, profile, covidInfo, thaInfo, primaryRole, featureOptions } = props;
  const isPso = primaryRole === PSO;
  const regFormConflictOrBasicPath =
    Object.keys(JSON.parse(`${patient.diffMqData}`)).length === 0 ? (isPso ? 'summary' : 'basic') : 'conflicts';

  const showNewRego = featureOptions?.find(
    (featureOption: FeatureOption) => featureOption.name === 'NewAusRego' && featureOption.active,
  );
  const regFormLink =
    isUs() || showNewRego
      ? `/patient/${patient.id}/registration/${regFormConflictOrBasicPath}`
      : `/registration/${patient.id}/${regFormConflictOrBasicPath}`;
  const haFormLink = `/patient/${patient.id}/health/review`;
  const covidFormLink = `/patient/${patient.id}/covid/review`;

  const regUpdatedAt = patient.updatedAt ? formatTime(patient.updatedAt) : '-';

  const haUpdatedAt = haInfo && haInfo.updatedAt ? formatTime(haInfo.updatedAt) : '-';

  // DT can be null
  const dtUpdatedAt = dtInfo && dtInfo.updatedAt ? formatTime(dtInfo.updatedAt) : '-';
  const dtUpdatedBy = dtInfo ? dtInfo.updatedBy : '-';
  const dtEotUpdatedAt = dtEotInfo && dtEotInfo.updatedAt ? formatTime(dtEotInfo.updatedAt) : '-';
  const dtEotUpdatedBy = dtEotInfo ? dtEotInfo.updatedBy : '-';

  const covidUpdatedAt = covidInfo && covidInfo.updatedAt ? formatTime(covidInfo.updatedAt) : '-';

  const thaUpdatedAt = thaInfo && thaInfo.updatedAt ? formatTime(thaInfo.updatedAt) : '-';
  const thaUpdatedBy = thaInfo && thaInfo.status ? `${thaInfo.patient.firstName} ${thaInfo.patient.lastName}` : '-';

  const regForm = {
    title: 'Registration Form',
    id: REGISTRATION_FORM,
    formLink: regFormLink,
    formStatus: 'registration',
    updatedBy: patient.updatedBy,
    updatedAt: regUpdatedAt,
    isHidden: false,
    displayTo: [PSO, SUPERUSER],
  };
  const haForm = {
    title: 'Health Assessment',
    id: HEALTH_ASSESSMENT,
    formLink: haFormLink,
    formStatus: 'ha',
    updatedBy: haInfo && haInfo.updatedBy,
    updatedAt: haUpdatedAt,
    isHidden: !profile || !profile.patientFeatures.healthAssessmentEnabled,
    displayTo: [PSO, SUPERUSER],
  };

  return isUs()
    ? [regForm, haForm]
    : [
        regForm,
        haForm,
        {
          title: 'Covid-19 Screening',
          id: COVID_SCREENING,
          formLink: covidFormLink,
          formStatus: 'covid',
          updatedBy: covidInfo && covidInfo.updatedBy,
          updatedAt: covidUpdatedAt,
          // Hide the covid form if the feature is disabled and the form has not been submitted
          isHidden: !profile?.patientFeatures?.covidEnabled && ['', NOT_STARTED].includes(covidInfo?.status),
          displayTo: [PSO, SUPERUSER],
        },
        {
          title: 'Distress Thermometer',
          id: DISTRESS_THERMOMETER,
          formLink: '',
          formStatus: 'dt',
          updatedBy: dtUpdatedBy,
          updatedAt: dtUpdatedAt,
          isHidden: !profile || !profile.patientFeatures.distressThermometerEnabled,
          displayTo: [PSO, SUPERUSER],
        },
        {
          title: 'Distress Thermometer EOT',
          id: DISTRESS_THERMOMETER_EOT,
          formLink: '',
          formStatus: 'dt-eot',
          updatedBy: dtEotUpdatedBy,
          updatedAt: dtEotUpdatedAt,
          isHidden: !profile || !profile.patientFeatures.distressThermometerEotEnabled,
          displayTo: [PSO, SUPERUSER],
        },
        {
          title: 'Theranostics Assessment',
          id: THERANOSTICS,
          formLink: `/server/forms/pso-theranostics/${patient.id}`,
          formStatus: 'tha',
          updatedBy: thaUpdatedBy,
          updatedAt: thaUpdatedAt,
          isHidden: patient.userProfile?.systemState !== 'THA',
          displayTo: [PSO, SUPERUSER],
        },
      ];
};

export const renderFormStatus = (form: string, attributes: any, nurseStatus?: string, returnString?: boolean): any => {
  let regFormStatus = attributes.patient.regFormStatus;
  switch (form) {
    case formType.registration:
      regFormStatus = attributes.patient.regFormStatus;
      break;
    case formType.healthAssessment:
      attributes.haInfo && attributes.haInfo.status ? (regFormStatus = attributes.haInfo.status) : (regFormStatus = '');
      break;
    case formType.distressThermometer:
      attributes.dtInfo && attributes.dtInfo.status ? (regFormStatus = attributes.dtInfo.status) : (regFormStatus = '');
      break;
    case formType.distressThermometerEot:
      attributes.dtEotInfo && attributes.dtEotInfo.status
        ? (regFormStatus = attributes.dtEotInfo.status)
        : (regFormStatus = '');
      break;
    case formType.covid:
      attributes.covidInfo && attributes.covidInfo.status
        ? (regFormStatus = attributes.covidInfo.status)
        : (regFormStatus = '');
      break;
    case formType.theranostics:
      attributes.thaInfo && attributes.thaInfo.status
        ? (regFormStatus = attributes.thaInfo.status)
        : (regFormStatus = '');
      break;
    case formType.initialTriage:
      nurseStatus ? (regFormStatus = nurseStatus) : (regFormStatus = '');
      break;
    case formType.initialChartCheck:
      nurseStatus ? (regFormStatus = nurseStatus) : (regFormStatus = '');
      break;
    case 'na':
      regFormStatus = FormStatus.REG_SUBMITTED;
      break;
    default:
      break;
  }

  if (regFormStatus === '') {
    return <>-</>;
  }
  const message = getFormStatus(regFormStatus, PSO);

  if (returnString) return message;
  return (
    <>
      {regFormStatus === '' ? (
        <>-</>
      ) : (
        <div className="status-container">
          <span
            className={classNames('form-status', {
              'needs-review': regFormStatus === FormStatus.REG_REVIEW_REQUIRED,
              'submitted-to-mq': regFormStatus === FormStatus.REG_SUBMITTED,
              'in-progress':
                regFormStatus === FormStatus.IN_PROGRESS || regFormStatus === FormStatus.IN_PROGRESS_AMENDED,
              'no-status': regFormStatus === '',
            })}>
            {message}
          </span>
        </div>
      )}
    </>
  );
};

const PatientFormSummary = (props: Props): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const { setTriageIdentifier, setChartCheckIdentifier } = useContext(AssessmentContext);
  const { primaryRole, patient, pCCCInfo, preCtTriageInfo } = props;
  const [createPreCtTriage] = useMutation(CREATE_PRE_CT_TRIAGE);
  const [createPreCtChartCheck] = useMutation(CREATE_PRE_CT_CHART_CHECK);
  const [deletePreCtChartCheck] = useMutation(DELETE_PRE_CT_CHART_CHECK, {
    refetchQueries: [{ query: QUERY_NURSE, variables: { id: patient.id } }],
  });
  const [deletePreCtTriage] = useMutation(DELETE_PRE_CT_TRIAGE, {
    refetchQueries: [{ query: QUERY_NURSE, variables: { id: patient.id } }],
  });
  const [createAmendedPreCtTriage] = useMutation(CREATE_AMENDED_PRE_CT_TRIAGE, {
    refetchQueries: [{ query: QUERY_NURSE, variables: { id: patient.id } }],
  });
  const [createAmendedPreCtChartCheck] = useMutation(CREATE_AMENDED_PRE_CT_CHART_CHECK, {
    refetchQueries: [{ query: QUERY_NURSE, variables: { id: patient.id } }],
  });
  const [duplicatePreCtTriage] = useMutation(DUPLICATE_PRE_CT_TRIAGE, {
    refetchQueries: [{ query: QUERY_NURSE, variables: { id: patient.id } }],
  });
  const [duplicatePreCtChartCheck] = useMutation(DUPLICATE_PRE_CT_CHART_CHECK, {
    refetchQueries: [{ query: QUERY_NURSE, variables: { id: patient.id } }],
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteNAModal, setDeleteNAModal] = useState(false);
  const [deleteFormData, setDeleteFormData] = useState({});
  const theme = useTheme();

  const nurseFormData = {
    preCtTriageInfo,
    pCCCInfo,
  };

  const preCtChartCheckLink = '/patient/:patientId/nurse/prectchartcheck/:formId';
  const preCtTriageLink = '/patient/:patientId/nurse/precttriage/:formId';

  // When PSO clicks on a form for review, the "entrypoint" into the patient registration forms will be set to the
  // URL which corresponds to the "Patient Summary" dashboard for the given patient
  const navigationContext = useContext(NavigationContext);

  useEffect(() => {
    navigationContext.setRegEntryPath(location.pathname);
  }, [location.pathname]);

  const renderFormTable = (): JSX.Element => {
    const formData = getFormData(props);

    const nurseAssessmentData = [
      {
        id: 'pCCCInfo',
        title: 'Initial Chart Check',
        url: `${preCtChartCheckLink}/basic-information`,
        reviewUrl: `${preCtChartCheckLink}/review-and-submit`,
        displayTo: [NURSE, SUPERUSER],
      },
      {
        id: 'preCtTriageInfo',
        title: 'Initial Triage',
        url: `${preCtTriageLink}/malnutrition-risk`,
        reviewUrl: `${preCtTriageLink}/review-and-submit`,
        displayTo: [NURSE, SUPERUSER],
      },
    ];

    const createCopy = async (item: any, type: string) => {
      const amendQueries = {
        'Initial Chart Check': createAmendedPreCtChartCheck,
        'Initial Triage': createAmendedPreCtTriage,
      };
      const duplicateQueries = {
        'Initial Chart Check': duplicatePreCtChartCheck,
        'Initial Triage': duplicatePreCtTriage,
      };

      const referenceData = {
        //@ts-ignore
        query: type === 'amendment' ? amendQueries[item.name] : duplicateQueries[item.name],
        redirectPath:
          item.name === 'Initial Chart Check'
            ? chartCheckRoutes.basicInformation.path
            : triageRoutes.malnutritionRisk.path,
        contextSetter: item.name === 'Initial Chart Check' ? setChartCheckIdentifier : setTriageIdentifier,
      };

      await referenceData.query({ variables: { id: item.id } }).then(({ data }: any) => {
        let formIdentifier;
        if (type === 'amendment')
          formIdentifier =
            item.name === 'Initial Chart Check'
              ? data.createAmendedPreCtChartCheck.preCtChartCheck.id
              : data.createAmendedPreCtTriage.preCtTriage.id;

        if (type === 'duplicate')
          formIdentifier =
            item.name === 'Initial Chart Check'
              ? data.duplicatePreCtChartCheck.preCtChartCheck.id
              : data.duplicatePreCtTriage.preCtTriage.id;

        type === 'duplicate' && referenceData.contextSetter(formIdentifier);

        return history.push(
          generatePath(referenceData.redirectPath, { patientId: patient.id, formId: formIdentifier }),
        );
      });
    };

    const mapNurseData = () => {
      const results: JSX.Element[] = [];

      const labelFromType = (value: string) => (value === 'Initial Chart Check' ? 'chartcheck' : 'triage');
      const isNurse = primaryRole === NURSE;
      const hasSubmittedAssessments = (value: any) =>
        value.filter((item: any) => item.status === FormStatus.REG_SUBMITTED).length;
      const triageTooltip =
        isNurse && nurseFormData.preCtTriageInfo?.length && hasSubmittedAssessments(nurseFormData.preCtTriageInfo)
          ? duplicateTriageTooltipMessage
          : '';
      const chartCheckTooltip =
        isNurse && nurseFormData.pCCCInfo?.length && hasSubmittedAssessments(nurseFormData.pCCCInfo)
          ? duplicateChartCheckTooltipMessage
          : '';

      for (const [key, values] of Object.entries(nurseFormData)) {
        values!.forEach((item: any, idx: number) => {
          const isSubmitted = item.status === FormStatus.REG_SUBMITTED;
          const formStatus =
            item.status === FormStatus.IN_PROGRESS && item.isAmendment === true
              ? `${item.status}Amendment`
              : item.status;
          const matchedData = nurseAssessmentData.filter((triage: NurseAssessmentData) => triage.id === key);

          if (matchedData.length === 1 && matchedData[0].displayTo.includes(primaryRole)) {
            results.push(
              <TableRow
                data-test-id={`${matchedData[0].title}-${idx}`}
                key={item.updatedAt}
                sx={{ '&:hover': { backgroundColor: theme.palette.primary.light, cursor: 'pointer' } }}
                onClick={() => {
                  sessionStorage.setItem('referringPage', `/navigator/patient/${patient.id}/summary`);
                  history.push(
                    generatePath(item.isLocked ? matchedData[0].reviewUrl : matchedData[0].url, {
                      patientId: patient.id,
                      formId: item.id,
                    }),
                  );
                }}>
                <TableCell>{matchedData[0].title}</TableCell>
                <TableCell>{renderFormStatus(item.__typename, props, formStatus)}</TableCell>
                <TableCell>{item.updatedBy}</TableCell>
                <TableCell>{formatTime(item.updatedAt)}</TableCell>
                <TableCell>
                  <div className="space-between">
                    {isSubmitted && (
                      <>
                        <AmendButton
                          unclickable={checkUnclickable(!isSubmitted)}
                          fieldId="nurse-amend-form"
                          label="Amend assessment"
                          onClick={(e) => {
                            e.stopPropagation();
                            isSubmitted && createCopy({ name: matchedData[0].title, id: item.id }, 'amendment');
                          }}
                        />
                        <Tooltip
                          title={
                            labelFromType(matchedData[0].title) === 'chartcheck' ? chartCheckTooltip : triageTooltip
                          }
                          arrow>
                          <Box>
                            <DuplicateButton
                              unclickable={checkUnclickable(!isSubmitted)}
                              fieldId={`nurse-duplicate-form-${labelFromType(matchedData[0].title)}`}
                              label="Duplicate assessment"
                              onClick={(e) => {
                                e.stopPropagation();
                                isSubmitted && createCopy({ name: matchedData[0].title, id: item.id }, 'duplicate');
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </>
                    )}
                    {!isSubmitted && (
                      <>
                        <ResizedDeleteButton
                          unclickable={checkUnclickable(isSubmitted)}
                          fieldId="nurse-delete-form"
                          label={'discard'}
                          buttonText={'Discard'}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!isSubmitted) {
                              setDeleteFormData({
                                id: item.id,
                                title: matchedData[0].title,
                                isAmendment: item.isAmendment,
                              });
                              setDeleteNAModal(true);
                            }
                          }}
                        />
                      </>
                    )}
                  </div>
                </TableCell>
              </TableRow>,
            );
          }
        });
      }
      const sortedResults = [...results].sort((a, b) => moment(b.key).unix() - moment(a.key).unix());

      return sortedResults;
    };

    return (
      <>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
            '& td': {
              padding: '17px',
              verticalAlign: 'middle',
            },
            '& th': {
              padding: '17px',
              verticalAlign: 'middle',
              fontWeight: 'bold',
            },
          }}
          id="patient-forms-table">
          <TableHead sx={{ backgroundColor: theme.palette.secondary.light, fontWeight: 'bold', maxHeight: '90px' }}>
            <TableRow>
              <TableCell>Forms</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last Modified By</TableCell>
              <TableCell>Last Modified</TableCell>
              {primaryRole === NURSE && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {formData
              .filter((row: any): boolean => !row.isHidden && row.displayTo.includes(primaryRole))
              .map((row: any): JSX.Element => {
                return (
                  <TableRow
                    data-test-id={row.id}
                    className={`${staticRowForm.includes(row.id) && 'static-row'}`}
                    key={row.id}
                    sx={{ '&:hover': { backgroundColor: theme.palette.primary.light, cursor: 'pointer' } }}
                    onClick={(): any =>
                      row.id === THERANOSTICS
                        ? navigateToExternalURL(row.formLink)
                        : staticRowForm.includes(row.id)
                        ? null
                        : history.push(row.formLink)
                    }>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{renderFormStatus(row.formStatus, props)}</TableCell>
                    <TableCell>{row.updatedBy}</TableCell>
                    <TableCell>{row.updatedAt}</TableCell>
                  </TableRow>
                );
              })}
            {primaryRole === NURSE && mapNurseData()}
          </TableBody>
        </Table>
      </>
    );
  };

  const onFormSelectionSubmit = async (item: string) => {
    if (item === 'Initial Chart Check') {
      await createPreCtChartCheck({ variables: { patientId: patient.id } }).then(({ data }: any) =>
        history.push(
          generatePath(`${preCtChartCheckLink}/basic-information`, {
            patientId: patient.id,
            formId: data.createPreCtChartCheck.preCtChartCheck.id,
          }),
        ),
      );
    }
    if (item === 'Initial Triage') {
      await createPreCtTriage({ variables: { patientId: patient.id } }).then(({ data }: any) =>
        history.push(
          generatePath(`${preCtTriageLink}/malnutrition-risk`, {
            patientId: patient.id,
            formId: data.createPreCtTriage.preCtTriage.id,
          }),
        ),
      );
    }
  };

  const nurseAssessmentOptions = ['Initial Chart Check', 'Initial Triage'];

  return (
    <>
      <ModalSelect
        isOpen={modalOpen}
        dismissFunction={() => setModalOpen(false)}
        submitForm={(value: string) => {
          onFormSelectionSubmit(value);
          setModalOpen(false);
        }}
        title="Create New Assessment"
        cancelButtonText="Cancel"
        submitButtonText="Create"
        options={nurseAssessmentOptions}
        aboveOptionsText="Select an option to create a new assessment"
      />
      {deletePreCtModal(
        deletePreCtChartCheck,
        deletePreCtTriage,
        deleteNAModal,
        setDeleteNAModal,
        deleteFormData,
        history,
        patient.id,
      )}
      <PatientIdentifierBar patient={patient} />
      <div id="summary-header">
        <div id="summary-header-title">{'Patient Summary'}</div>
      </div>
      <div className="summary-elements">
        <div className="left-container">
          <div className="newspaper-container">
            <div className="home-clinic-container">
              <RegistrationHomeClinicApollo
                disabled={primaryRole === NURSE ? true : false}
                patient={patient}
                horizonCenterId={patient.horizonCenterId as string}
              />
            </div>
          </div>
        </div>
        <div className="right-container">
          <h2>{primaryRole === PSO ? 'Registration Forms' : 'Forms'}</h2>
          <TableContainer>{renderFormTable()}</TableContainer>
          {primaryRole === NURSE && (
            <Stack sx={{ width: '100%', justifyContent: 'flex-end', marginTop: '16px' }} direction="row">
              <ActionButton
                label="Create new assessment"
                type="add"
                fieldId="nurse-assessment-modal"
                onClick={() => setModalOpen(true)}
              />
            </Stack>
          )}
        </div>
      </div>
    </>
  );
};

export const deletePreCtModal = (
  deletePreCtChartCheck: any,
  deletePreCtTriage: any,
  isOpen: any,
  setIsOpen: any,
  deleteFormData: any,
  patientId: any,
  history: any,
  redirect = false,
) => {
  const componentClass = 'discard-icc-modal';
  const deleteNurseAssessmentFormRecord = (formTitle: string | undefined, formId: string | undefined) => {
    if (formTitle === 'Initial Chart Check') deletePreCtChartCheck({ variables: { id: formId } });
    if (formTitle === 'Initial Triage') deletePreCtTriage({ variables: { id: formId } });

    if (redirect) return history.push(`/patient/${patientId}/summary`);
  };

  const amendmentText = (): JSX.Element => (
    <NurseDeleteFormText>
      <div>You'll lose all answers collected by this proposed amendment.</div>
      <br />
      <div>Are you sure you want to discard this amendment?</div>
    </NurseDeleteFormText>
  );

  const nonAmendmentText = (): JSX.Element => (
    <NurseDeleteFormText>
      <div>You'll lose all answers collected by this assessment. We can't recover them once you discard it.</div>
      <br />
      <div>Are you sure you want to discard this assessment?</div>
    </NurseDeleteFormText>
  );

  const modalBody = deleteFormData.isAmendment ? amendmentText() : nonAmendmentText();
  const cancelButtonText = 'Cancel';
  const submitButtonText = `Discard ${deleteFormData.isAmendment ? 'amendment' : 'assessment'}`;

  const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsOpen(false);
  };

  return (
    <Modal id={componentClass} open={isOpen} onClose={handleCloseModal}>
      <ModalHeader>
        <Typography variant="h5" fontWeight={600}>{`Discard ${deleteFormData?.title}`}</Typography>
      </ModalHeader>
      <ModalBody sx={{ padding: '0px 24px !important' }}>{modalBody}</ModalBody>
      <Divider />
      <ModalFooter className={`${componentClass}-footer`} sx={{ padding: '16px !important' }}>
        <Button
          id={`${componentClass}-close-btn`}
          mode="outlined"
          size="auto"
          onClick={handleCloseModal}
          data-test-id={`${cancelButtonText}-button`}>
          {cancelButtonText}
        </Button>
        <Button
          id={`${componentClass}-submit-btn`}
          size="small"
          color="error"
          data-test-id={`${submitButtonText}-button`}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            deleteNurseAssessmentFormRecord(deleteFormData?.title, deleteFormData?.id);
            setIsOpen(false);
          }}>
          {submitButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PatientFormSummary;
