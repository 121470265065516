// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import {
  GreetingsSection,
  HeaderBar,
  PageContainer,
  PatientSearch,
  QuickAccessSectionHeader,
  RecentPatientSection,
  ReviewFormsHeader,
  SideNavContainer,
} from 'op-components';
import { HAS_LATEST_NOTIFICATION, UPDATE_USER_NOTIFICATION } from 'op-components/HeaderBar/HeaderBarQueries';
import { RoleType } from 'op-enums';
import { LOG_EVENT } from 'op-graphql';
import { GET_RECENT_PATIENTS, GET_USER, UX_FEATURE_OPTION_QUERY } from 'op-graphql/queries';
import { UNSEEN_POPUP_NOTIFICATIONS, UPDATE_USER_POPUP_NOTIFICATION } from 'op-graphql/queries/Notification';
import { PatientTooltip } from 'op-interfaces';
import { UserLanguageQueryData } from 'op-interfaces/graph';
import { isUs } from 'op-utils/helpers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { OnboardingPopover } from 'shared-components/components';
import { AddOutlineCircle } from 'shared-components/images';
import { ELASTIC_SEARCH_EVENTS, logPage } from 'shared-components/utils';
import './PSODashboard.scss';
import ReviewFormsApollo from './ReviewFormsApollo';
import SmsLogs from './SmsLogs/SmsLogs';
import { Box, Button, useTheme } from '@mui/material';

const { PSO, SUPERUSER } = RoleType;

interface DashboardContentIProps extends RouteComponentProps {
  primaryRole?: string;
  path?: any;
}

interface RecentPatientsQueryResultData {
  getRecentPatients: PatientTooltip[];
}

const DashboardContent = (props: DashboardContentIProps): any => {
  const { primaryRole, path, ...otherProps } = props;
  const [recentPatients, setRecentPatients] = useState<PatientTooltip[]>([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const startTime = useRef<number>(new Date().getTime());
  const createPatientBtnRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const [logEvent] = useMutation(LOG_EVENT);

  // User, UX feature toggle and recent patient queries
  const { data: userData } = useQuery<UserLanguageQueryData>(GET_USER);
  const { data: patientData, loading: patientLoading } = useQuery<RecentPatientsQueryResultData>(GET_RECENT_PATIENTS, {
    fetchPolicy: 'network-only',
  });
  const { data: psoSideNavFeatureOption } = useQuery(UX_FEATURE_OPTION_QUERY, {
    variables: { featureOptionName: 'PSOSideNav' },
  });
  const { data: createPatientFeatureOption } = useQuery(UX_FEATURE_OPTION_QUERY, {
    variables: { featureOptionName: 'CreatePatient' },
  });
  const { data: showRecentPatientsFeatureOption } = useQuery(UX_FEATURE_OPTION_QUERY, {
    variables: { featureOptionName: 'ShowRecentPatients' },
  });
  const { data: buzzSmsLogFeatureOption } = useQuery(UX_FEATURE_OPTION_QUERY, {
    variables: { featureOptionName: 'Buzz SMS Log Widget' },
  });
  const { data: notificationData } = useQuery(UNSEEN_POPUP_NOTIFICATIONS);
  const [updateLatestNotification] = useMutation(UPDATE_USER_NOTIFICATION, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: HAS_LATEST_NOTIFICATION }],
  });
  const [updateLatestPopupNotification] = useMutation(UPDATE_USER_POPUP_NOTIFICATION, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: UNSEEN_POPUP_NOTIFICATIONS }],
  });

  const handleVisitLink = () => {
    updateLatestPopupNotification({ variables: { id: createPatientNotificationId } });
    updateLatestNotification();
    const win = window.open(
      'https://genesiscare.sharepoint.com/sites/Oncologyportal/SitePages/Create-Patient-Record-in-Horizon.aspx',
      '_blank',
    );
    win?.focus();
  };

  useEffect(() => {
    if (patientData && patientData.getRecentPatients) {
      setRecentPatients(patientData.getRecentPatients);
    }
  }, [patientData]);
  useEffect(() => {
    createPatientBtnRef.current && setAnchorEl(createPatientBtnRef.current);
  }, [createPatientBtnRef.current]);

  const user = useMemo(() => userData?.user, [userData]);
  const showSideNav = useMemo(() => psoSideNavFeatureOption?.uxFeatureOption?.active, [psoSideNavFeatureOption]);
  const showBuzzSmsDashboard = useMemo<boolean>(
    () => (buzzSmsLogFeatureOption?.uxFeatureOption?.active && userData?.user?.isPso) ?? false,
    [buzzSmsLogFeatureOption, userData],
  );
  const enableCreatePatient = useMemo(
    () => createPatientFeatureOption?.uxFeatureOption?.active,
    [createPatientFeatureOption],
  );
  const showRecentPatients = useMemo(
    () => showRecentPatientsFeatureOption?.uxFeatureOption?.active,
    [showRecentPatientsFeatureOption],
  );

  const createPatientNotificationId = notificationData?.unseenPopups?.find(
    (unseenPopup: { description: string }) => unseenPopup.description === 'Create Patient',
  )?.id;
  const showNotificationUpdate = !!createPatientNotificationId;

  useEffect(() => {
    setPopoverOpen(!!(showNotificationUpdate && showSideNav && user?.isPso));
  }, [showNotificationUpdate, showSideNav, user]);

  const closePopup = () => {
    updateLatestPopupNotification({ variables: { id: createPatientNotificationId } });
    setPopoverOpen(false);
  };

  const handleCreateNewPatient = () => {
    if (showNotificationUpdate) closePopup();

    // Create a session storage item to hold a random create patient id
    const createPatientKey = String(Math.floor(Math.random() * 10000000));
    sessionStorage.setItem('createPatientKey', createPatientKey);

    const pageData = { eventName: ELASTIC_SEARCH_EVENTS.START, state: user?.state || '', createPatientKey };
    logPage(startTime?.current, pageData, logEvent);
    otherProps.history.push('/patient/create');
  };

  return (
    <PageContainer>
      <div id="pso-dashboard-wrapper">
        {showSideNav && (user?.isPso || user?.isSuperuser) && (
          <SideNavContainer>
            <GreetingsSection username={user?.name} />
            <QuickAccessSectionHeader />
            <OnboardingPopover
              handleClose={() => closePopup()}
              handleVisitLink={handleVisitLink}
              popoverOpen={popoverOpen}
              target={anchorEl}
              feature="Create Patient">
              <Box ref={createPatientBtnRef} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  id="create-patient-button"
                  disabled={!enableCreatePatient}
                  startIcon={<AddOutlineCircle />}
                  onClick={handleCreateNewPatient}
                  variant="outlined"
                  sx={{
                    border: `2px solid ${theme.palette.primary.main} !important`,
                    borderRadius: '20px',
                    margin: '16px',
                    fontWeight: 'bold',
                    background: theme.palette.primary.contrastText,
                  }}>
                  Create new patient
                </Button>
              </Box>
            </OnboardingPopover>
            {!isUs() && showRecentPatients && !patientLoading && userData && userData.user && (
              <RecentPatientSection data={recentPatients} hasPractitionerId={userData.user.isPractitioner} />
            )}
          </SideNavContainer>
        )}

        <div className="dashboard-content-container">
          {primaryRole && [PSO, SUPERUSER].includes(primaryRole) && (
            <div className="dashboard-content-header-container">
              <ReviewFormsHeader pageShown={path} showSmsLogHeader={showBuzzSmsDashboard} />
            </div>
          )}
          <Switch>
            <Route path={'/search'} component={PatientSearch} />
            <Route path={'/review-forms'} component={ReviewFormsApollo} />
            {showBuzzSmsDashboard && <Route path={'/sms-logs'} component={SmsLogs} />}
          </Switch>
        </div>
      </div>
    </PageContainer>
  );
};

const PSODashboard = (props: RouteComponentProps): JSX.Element => {
  const {
    match: { path },
  } = props;

  return (
    <HeaderBar>
      <DashboardContent {...props} path={path} />
    </HeaderBar>
  );
};

export default withRouter(PSODashboard);
