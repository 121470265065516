// eslint-disable-next-line no-use-before-define
import React, { ReactNode, useState } from 'react';
import { styled } from '@mui/system';
import TooltipModal from '../Components/TooltipModal';
import { TooltipInterface } from '../FormBuilderInterfaces';
import { useTheme } from '@mui/material';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';

export const FormContainer = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface InputContainerProps {
  children: ReactNode;
  error?: string | JSX.Element;
  warning?: string | JSX.Element;
  info?: string | JSX.Element;
}

export const StyledInputContainer = styled('div')<any>`
  flex: 1;
`;

export const InputContainer = ({ children, error, warning, info }: InputContainerProps): JSX.Element => (
  <StyledInputContainer>
    {children}
    {info && <InfoMsg>{info}</InfoMsg>}
    {warning && typeof warning !== 'boolean' && <WarningMsg>{warning}</WarningMsg>}
    {error && typeof error !== 'boolean' && <ErrorMsg>{error}</ErrorMsg>}
  </StyledInputContainer>
);

export const FormRow = styled('div')<any>`
  display: flex;
  margin-bottom: 16px;
`;

export const StyledLabel = styled('label')`
  line-height: 40px;
  width: 256px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ChildField = styled('fieldset')<any>`
  border-left: 1px solid ${(props) => props.theme.palette.secondary.main};
  padding-left: 16px;
  label {
    width: calc(256px - 16px);
  }
`;

export const SectionTitle = styled('div')<any>`
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => props.theme.palette.secondary.dark};
  font-weight: bold;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

const StyledMessage = styled('div')<any>`
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-top: 8px;
  color: ${(props) => props.color};
`;

interface toolTipProps {
  tooltip?: TooltipInterface;
}

export function FormRowToolTip(props: toolTipProps): JSX.Element {
  const { tooltip } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <HelpOutlineIcon
        fontSize="small"
        sx={{ cursor: 'pointer' }}
        color="info"
        className="tooltip-icon"
        onClick={() => setOpen(true)}
      />
      {open && <TooltipModal open={open} tooltip={tooltip} setOpen={setOpen} />}
    </>
  );
}

export const FormControlLabel = ({
  children,
  required,
  tooltip,
}: {
  children: ReactNode;
  required?: boolean;
  tooltip?: TooltipInterface;
}): JSX.Element => {
  const theme = useTheme();
  return (
    <StyledLabel>
      {children}
      {required && <span style={{ color: theme.palette.error.main }}> * </span>}
      {tooltip && <FormRowToolTip tooltip={tooltip} />}
    </StyledLabel>
  );
};

const Msg = ({ children, color = '' }: { children: ReactNode; color?: string; iconColor?: string }) => (
  <StyledMessage color={color}>
    <span className="msg">{children}</span>
  </StyledMessage>
);

export const ErrorMsg = ({ children }: { children: ReactNode }): JSX.Element => {
  const theme = useTheme();
  return <Msg color={theme.palette.error.main}>{children}</Msg>;
};

export const WarningMsg = ({ children }: { children: ReactNode }): JSX.Element => {
  const theme = useTheme();
  return <Msg iconColor={theme.palette.warning.main}>{children}</Msg>;
};

export const InfoMsg = ({ children }: { children: ReactNode }): JSX.Element => {
  const theme = useTheme();
  return <Msg iconColor={theme.palette.info.main}>{children}</Msg>;
};
