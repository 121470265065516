import { Table, TableBody, TableHead, TableCell, TableRow, styled, tableCellClasses, Typography } from '@mui/material';
interface Location {
  locationId: string;
  system: string;
  locationName: string;
  locationAddr1: string;
  locationAddr2: string;
  locationCity: string;
  locationPostcode: string;
  locationState: string;
  locationCountry: string;
  locationPhone: string;
  errorFields: string[];
}

interface Props {
  locationData: null | Location[];
  setSaveFalse: (bool: boolean) => void;
  showInstructions: boolean;
}

const StyledCell = styled(TableCell)`
  color: red;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableContainer = styled('div')<{ $showInstructions?: boolean }>`
  ${({ $showInstructions }) =>
    $showInstructions
      ? `
    overflow: auto;
    max-height: calc(100% - 670px);
  `
      : `
  overflow: auto;
  max-height: calc(100% - 510px);
  `}
`;

const validSystems = ['ROA', 'CWA', 'ARC', 'GCCQ', 'CCQ', 'ROV'];

const LocationTable = ({ locationData, setSaveFalse, showInstructions }: Props): JSX.Element => {
  return (
    <>
      <Typography variant="h6" sx={{ marginTop: '50px', marginBottom: '20px' }}>
        Location Mappings
      </Typography>
      <StyledTableContainer $showInstructions={showInstructions}>
        <Table data-testid="practitioner-search-results-table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Location Id</StyledTableCell>
              <StyledTableCell>System</StyledTableCell>
              <StyledTableCell>Location Name</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locationData &&
              locationData.map((data: Location) => {
                if (!validSystems.includes(data.system)) setSaveFalse(true);
                return (
                  <StyledTableRow>
                    {renderCell('locationId', data.locationId, data.errorFields)}
                    {renderCell('system', data.system, data.errorFields)}
                    {renderCell('locationName', data.locationName, data.errorFields)}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

const renderCell = (key: string, value: string, errorFields: string[]): JSX.Element => {
  if (errorFields.includes(key)) return <StyledCell>{value}</StyledCell>;
  return <TableCell>{value}</TableCell>;
};

export default LocationTable;
