import { ErrorOutline as ErrorOutlineIcon, HighlightOff as HighlightOffIcon } from '@mui/icons-material';
import { Stack, Typography, useTheme } from '@mui/material';
import { getBorderColor } from '../common';

interface Props {
  id?: string;
  helperText?: string;
  error?: boolean;
  warning?: boolean;
  info?: boolean;
}

const ROHelperText = ({ id, error, warning, info, helperText }: Props): JSX.Element => {
  const borderColor = getBorderColor(error, warning, info);
  const Icon = error ? HighlightOffIcon : warning || info ? ErrorOutlineIcon : null;
  const theme = useTheme();

  // Color should follow border color, unless there no error state in which case we want a darler
  // text color
  const color = borderColor === theme.palette.secondary.main ? theme.palette.secondary.dark : borderColor;
  const displayText = () => {
    if (error && !helperText) return 'This field is required';
    if (helperText) return helperText;
    return null;
  };

  return displayText() ? (
    <Stack component={'span'} direction="row" gap={0.5} color={color} alignItems="center">
      {Icon && <Icon sx={{ width: '16px', height: '16px' }} />}
      <Typography variant="caption" color={color} data-testid={`helper-text-${id}`}>
        {displayText()}
      </Typography>
    </Stack>
  ) : (
    <></>
  );
};

export default ROHelperText;
