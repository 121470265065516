import React, { useEffect, useState } from 'react';
import { GCButton } from 'shared-components/components/FormFields';
import { ButtonType, GCButtonSize } from 'shared-components/enums';
import { styled } from '@mui/system';
import './PatientFormFooter.scss';
import { DeviceUtilities } from 'shared-components/utils';
import { Stack } from '@mui/material';

const FooterContainer = styled('div')`
  z-index: 1000;
  position: fixed;
  bottom: 0;
  padding: 12px 24px;
  height: 76px;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.palette.secondary.main};
  background-color: white;
  box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

interface Props {
  handleClickOnBack?: any;
  handleClickOnContinue?: any;
  handleSaveAndExit?: any;
  handleResolveConflict?: any;
  navComponentId: string;
  isRecordLocked?: boolean;
  hasConflicts?: boolean;
  resolveConflictsIncomplete?: boolean;
  isPso: boolean | null;
  isInRegistrationForm?: boolean;
  isFirstPage?: boolean;
  isLastPage?: boolean;
  submitCalled?: boolean;
  showPrintPdfButton?: boolean;
  showContinueButton?: boolean;
  continueButtonType?: ButtonType;
  summaryVisited?: boolean;
}

const PatientFormFooter = ({
  handleClickOnBack,
  handleClickOnContinue,
  handleSaveAndExit,
  handleResolveConflict,
  navComponentId,
  isRecordLocked = false,
  hasConflicts = false,
  resolveConflictsIncomplete = false,
  isPso = null,
  isInRegistrationForm = false,
  isFirstPage,
  isLastPage,
  submitCalled = false,
  showPrintPdfButton = false,
  showContinueButton = true,
  continueButtonType = ButtonType.GREEN,
  summaryVisited = false,
}: Props): JSX.Element => {
  const componentId = 'patient-form-footer';
  const isMobile = DeviceUtilities.isMobileDevice();

  // isPso comes into component initially as null. Prevent flashing buttons by introducing loading flag
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    if (isPso !== null) {
      setLoadingPage(false);
    }
  }, [isPso]);

  let backButtonTitle = 'Back';
  const saveAndExitButtonTitle = 'Save and exit';
  const resolveConflictsButtonTitle = 'Resolve conflicts';
  const exitButtonTitle = 'Exit';
  const pdfButtonTitle = 'Print PDF';
  let continueButtonTitle = '';
  if (isLastPage) {
    continueButtonTitle = submitCalled ? 'Submitted' : 'Submit';
  } else {
    continueButtonTitle = summaryVisited ? 'Return to submit' : 'Continue';
  }

  if (isPso && !isInRegistrationForm) {
    backButtonTitle = 'Exit';
  }

  return (
    <FooterContainer id={componentId}>
      <div id={`${navComponentId}-left`}>
        {!loadingPage && isPso && !hasConflicts && !isFirstPage && (
          <GCButton
            title={backButtonTitle}
            type={ButtonType.WHITE}
            name={`${navComponentId}-back`}
            onClick={(): void => handleClickOnBack()}
            buttonSize={isMobile ? GCButtonSize.SMALL : undefined}
          />
        )}

        {!isMobile && !loadingPage && (!isPso || (isPso && isInRegistrationForm)) && (
          <GCButton
            title={isRecordLocked ? exitButtonTitle : saveAndExitButtonTitle}
            type={ButtonType.WHITE}
            name={`${navComponentId}-save-and-exit`}
            onClick={(): void => handleSaveAndExit()}
            buttonSize={isMobile ? GCButtonSize.SMALL : undefined}
          />
        )}
      </div>
      <Stack id={`${navComponentId}-right`} direction="row" gap="16px">
        {isInRegistrationForm && hasConflicts && (
          <GCButton
            title={resolveConflictsButtonTitle}
            onClick={(): void => handleResolveConflict()}
            name={`${navComponentId}-resolve-conflicts`}
            disabled={resolveConflictsIncomplete}
            type={resolveConflictsIncomplete ? ButtonType.DISABLED : ButtonType.GREEN}
          />
        )}

        {!loadingPage && showPrintPdfButton && !isInRegistrationForm && isPso && (
          <GCButton
            title={pdfButtonTitle}
            type={continueButtonType}
            name={`${navComponentId}-pdf`}
            onClick={(): void => handleClickOnContinue()}
          />
        )}

        {!loadingPage && !hasConflicts && !isPso && !isFirstPage && (
          <GCButton
            title={backButtonTitle}
            type={ButtonType.WHITE}
            name={`${navComponentId}-back`}
            onClick={(): void => handleClickOnBack()}
            buttonSize={isMobile ? GCButtonSize.SMALL : undefined}
          />
        )}

        {!hasConflicts && showContinueButton && (
          <GCButton
            id={`${navComponentId}-submit`}
            title={continueButtonTitle}
            type={isRecordLocked ? ButtonType.DISABLED : ButtonType.GREEN}
            disabled={isRecordLocked}
            name={`${navComponentId}-${isLastPage ? 'submit' : 'continue'}`}
            onClick={(): void => handleClickOnContinue()}
            buttonSize={isMobile ? GCButtonSize.SMALL : undefined}
          />
        )}
      </Stack>
    </FooterContainer>
  );
};

export default PatientFormFooter;
