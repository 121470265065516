// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'gc-ui';
import { TooltipInterface } from '../FormBuilderInterfaces';
interface PropsType {
  open: boolean;
  setOpen: (value: boolean) => void;
  tooltip?: TooltipInterface;
  modalWidth?: 'small' | 'medium' | 'large';
}

export default function TooltipModal(props: PropsType): JSX.Element {
  const { open, setOpen, tooltip, modalWidth = 'small' } = props;

  return (
    <Modal width={modalWidth} open={open} onClose={() => setOpen(false)}>
      <ModalHeader>{tooltip?.header}</ModalHeader>
      <ModalBody>
        {/* HTML from Intake helpers file */}
        <span dangerouslySetInnerHTML={{ __html: tooltip?.body || '' }}></span>
      </ModalBody>
      <ModalFooter>
        <Button mode="contained" size="small" onClick={() => setOpen(false)} data-cy={'form-builder-tooltip-modal'}>
          Back
        </Button>
      </ModalFooter>
    </Modal>
  );
}
