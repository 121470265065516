// eslint-disable-next-line no-use-before-define
import { FormBuilderComponentProps } from '../../FormBuilderInterfaces';
import { fieldMeetsConditions } from '../../common';
import { BaseDatePicker } from 'shared-components/components/FormFields';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';

/**
 * `FBDatePicker` is a React functional component used to render a date picker input field
 * within a FormBuilder component. It allows users to select a date from a calendar interface
 * created by Material UI.
 *
 * @param {FormBuilderComponentProps} props - The properties passed to the component.
 * @returns {JSX.Element} - A JSX element representing the date picker input field.
 */
export default function FBDatePicker(props: FormBuilderComponentProps): JSX.Element {
  const { component, formikProps, handleUpdate } = props;
  const value = formikProps.values[component.field.name];

  return (
    <BaseDatePicker
      id={component.field.name}
      key={component.field.name}
      value={value ? dayjs(value.toString()) : undefined}
      disabled={fieldMeetsConditions(formikProps.values, component.disabled, false)}
      onChange={(date: Dayjs | null, context: any) => {
        if (context.validationError) return;

        const dateString = date ? date.format('YYYY-MM-DD').toString() : '';
        handleUpdate(formikProps, component, dateString);
      }}
    />
  );
}
