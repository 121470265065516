// eslint-disable-next-line no-use-before-define
import React, { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/system';
import { useMutation, useQuery } from '@apollo/client';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { ToggleButtons } from 'shared-components/components/FormFields';
import { UserContext } from 'op-contexts';
import { UPDATE_USER_PREFERENCE_MUTATION, USER_PREFERENCE_QUERY } from 'op-pages/RO/Dashboard/Onboarding/queries';
import { Region } from 'shared-components/enums';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;
interface MenuLinkItem {
  title: string;
  url: string;
  icon?: JSX.Element;
  isROPortal: boolean;
}

const StyledMenuLinkItem = styled('li')`
  border-bottom: solid 1px ${(props) => props.theme.palette.secondary.main};
`;

const StyledMenuLink = styled('div')`
  border: none;
  background: transparent;
  padding: 15px 18px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 0;
  width: 100%;
  font-weight: bold;

  svg {
    margin-right: 10px;
  }

  span {
    font-size: ${getRemSize(14)};
  }
`;

const StyledMenuLinkIcon = styled('div')`
  svg {
    width: 20px;
    height: 20px;
  }

  path {
    fill: ${(props) => props.theme.palette.primary.main}};
  }
`;

const ToggleButtonsWrapper = styled('div')`
  padding: 0 16px 13px;
`;

interface Props {
  menuLink: MenuLinkItem;
  index: number;
  defaultButtonState: string;
}

const NEW_PATIENT_SUMMARY_OPTIONS = [
  { label: 'Enable', value: 'enable' },
  { label: 'Disable', value: 'disable' },
];

const VIEW_MAPPINGS = {
  Unset: '',
  Simple: 'enable',
  Default: 'disable',
};

const PATIENT_SUMMARY = 'PatientSummary';

export const NewPatientSummary = (props: Props): JSX.Element => {
  const { menuLink, index, defaultButtonState } = props;
  const [buttonValue, setButtonValue] = useState(defaultButtonState);
  const userContext = useContext(UserContext);
  const [updateUserPreference] = useMutation(UPDATE_USER_PREFERENCE_MUTATION);
  const { refetch: refetchPreferences } = useQuery(USER_PREFERENCE_QUERY, {
    variables: { featureName: PATIENT_SUMMARY },
  });

  const { setFeatures, getEMRFeature, getUserFeature } = userContext;

  const userSelectedViewObject = getUserFeature(PATIENT_SUMMARY);
  const userSelectedView = userSelectedViewObject ? userSelectedViewObject.featureOption?.name : null;

  const defaultFeatureObject = getEMRFeature(PATIENT_SUMMARY);
  const defaultView = defaultFeatureObject ? defaultFeatureObject.default.name : null;

  const view = userSelectedView || defaultView || '';

  useEffect(() => {
    //@ts-ignore
    setButtonValue(VIEW_MAPPINGS[view]);
  }, [view]);

  if (!view || region !== Region.AU) {
    return <></>;
  }
  const featureId = defaultFeatureObject?.feature.id;

  const onTryNewViewClick = () => {
    const simpleView = defaultFeatureObject?.options?.find((f: any): boolean => f.name === 'Simple');
    updateUserPreference({ variables: { featureId: featureId, featureOptionId: simpleView?.id } }).then(() =>
      refetchPreferences().then(({ data }: any) => setFeatures(data)),
    );
  };

  const onDefaultViewClick = () => {
    const defaultView = defaultFeatureObject?.options?.find((f: any): boolean => f.name === 'Default');
    updateUserPreference({ variables: { featureId: featureId, featureOptionId: defaultView?.id } }).then(() =>
      refetchPreferences().then(({ data }: any) => setFeatures(data)),
    );
  };

  return (
    <StyledMenuLinkItem key={menuLink.title.replace(/\s+/g, '-').toLowerCase()}>
      <StyledMenuLink
        key={index}
        className="nav-drop-down-link"
        data-test-id={`menu-title-${menuLink.title.replace(/\s+/g, '-').toLowerCase()}`}
        tabIndex={0}
        onClick={() => {}}
        onKeyPress={() => {}}>
        <StyledMenuLinkIcon key={index}>{menuLink.icon}</StyledMenuLinkIcon>
        <span className="nav-drop-down-link-text">{menuLink.title}</span>
      </StyledMenuLink>
      <ToggleButtonsWrapper>
        <ToggleButtons
          id={'new-patient-summary-toggle'}
          options={NEW_PATIENT_SUMMARY_OPTIONS}
          value={buttonValue}
          handleChange={(value: string): void => {
            setButtonValue(value);
            if (value === VIEW_MAPPINGS['Simple']) {
              onTryNewViewClick();
            } else {
              onDefaultViewClick();
            }
          }}
        />
      </ToggleButtonsWrapper>
    </StyledMenuLinkItem>
  );
};
