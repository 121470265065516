import BaseTextField, { Props as BaseTextFieldProps } from './BaseTextField';
import FormRow, { Props as FormRowProps } from '../FormRow/FormRow';
import React from 'react';

export interface Props extends BaseTextFieldProps, FormRowProps {
  id: string;
  fieldlabel?: string;
  alwaysShowHelper?: boolean; // Forces helper text to show (when it is not for info/warning/error state)
}

/*
  Adds a field label, validation, helper text logic and other nice things to the
  BaseTextField to make this suitable for use in forms. Generally use this TextField
  unless you don't need a field label.
*/
const ROTextField = (props: Props): JSX.Element => {
  const { fullWidth, error, warning, info, fieldlabel, id, required, tooltip } = props;
  const color = error ? 'error' : warning ? 'warning' : info ? 'info' : 'primary';

  return (
    <FormRow {...{ fieldlabel, id, required, tooltip }}>
      <BaseTextField
        {...props}
        fullWidth={fullWidth === undefined ? true : fullWidth}
        color={color}
        sx={{
          maxWidth: '400px',
          minWidth: '250px',
          ...props?.sx,
        }}
      />
    </FormRow>
  );
};

export default ROTextField;
