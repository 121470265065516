import { EditOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';

interface Props {
  title: string;
  subTitle: string;
  icon: JSX.Element;
  children?: (JSX.Element | string)[] | string | JSX.Element;
  onEdit?: () => void;
}

const Card = styled('div')`
  margin-top: 8px;
  border: 1px solid ${(props) => props.theme.palette.secondary.main};
  border-top: 7px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 5px;
  width: 100%;
  padding: 4px;
`;

const CardHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const StyledIcon = styled('div')`
  color: ${(props) => props.theme.palette.secondary.dark};
`;

const EditButton = styled(IconButton)`
  && {
    margin-left: auto;
  }
`;

const Icon = ({ icon }: any) => <StyledIcon>{icon}</StyledIcon>;

const Title = styled('div')`
  font-weight: 700;
`;

const SubTitle = styled('div')`
  font-weight: 700;
  font-size: 0.8125rem;
  color: ${(props) => props.theme.palette.secondary.dark};
  padding: 0px 4px 8px 4px;
  opacity: 0.75;
`;

const Body = styled('div')`
  padding: 4px;
`;

const SideBarCard = ({ title, subTitle, icon, children, onEdit }: Props): JSX.Element => (
  <Card>
    <CardHeader>
      <Icon icon={icon} />
      <div>
        <Title>{title}</Title>
      </div>
      {onEdit && (
        <EditButton data-test-id={`edit-${title}`} size="small" onClick={onEdit}>
          <Icon icon={<EditOutlined />} />
        </EditButton>
      )}
    </CardHeader>
    <SubTitle>{subTitle}</SubTitle>
    <Body>{children}</Body>
  </Card>
);

export default SideBarCard;
