import { useMutation, useQuery } from '@apollo/client';
import { Field, Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';

import { PageTitle } from 'op-components';

import { CheckboxField } from 'shared-components/components/UIFormComponents';
import { InputField } from 'shared-components/components/FormikComponents';
import { UPDATE_HA_CONSENT } from './HAPatientConsentQueries';
import { HA_CONSENT_QUERY } from '../../HASharedComponents/queries/HAPatientConsentQueries';

const StyledTypography = styled('div')`
  font-family: 'GenesisCare Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledLabel = styled('div')`
  margin-bottom: 4px;
  font-size: 1rem;
  line-height: 1.55556;
  font-weight: bold;
  width: 100%;
`;
export const patientConsentQuestions = {
  acknowledgement:
    'As the patient, you acknowledge, that with the completion of this form, it constitutes your complete clinical history summary.',
  patientSignature:
    'I understand that by typing my name and clicking on "Continue" I am electronically signing this document.',
};

export interface HAConsentItem {
  id: string;
  patientAcknowledgement: boolean;
  patientDigitalSignature: string;
}

const HAPatientConsent = (): JSX.Element => {
  const { patientId } = useParams<{ patientId: string }>();
  const { data, error, loading } = useQuery(HA_CONSENT_QUERY, {
    variables: { patientId: patientId },
  });

  const [UpdateHealthAssessmentConsent] = useMutation<HAConsentItem>(UPDATE_HA_CONSENT);

  if (loading || error) return <></>;

  const updateField = (field: string, value: string) => {
    UpdateHealthAssessmentConsent({ variables: { patientId, [field]: value } });
  };

  return (
    <>
      <Formik enableReinitialize={true} initialValues={data.healthAssessment} validateOnBlur={true} onSubmit={() => {}}>
        {() => {
          return (
            <>
              <PageTitle title={'Patient consent'} idPrefix="ha" />
              <div id="ha-fields" className="ha-pain-assessment">
                <StyledTypography>{patientConsentQuestions.acknowledgement}</StyledTypography>
                <Field
                  name="patientAcknowledgement"
                  component={CheckboxField}
                  width="366px"
                  alternateStyle
                  handleMutation={(value: string) => {
                    updateField('patientAcknowledgement', value);
                  }}
                  label="I acknowledge"
                />

                <br />
                <br />
                <StyledTypography>{patientConsentQuestions.patientSignature}</StyledTypography>

                <StyledLabel> Patient Name</StyledLabel>
                <Field
                  name="patientDigitalSignature"
                  component={InputField}
                  label="Patient Name"
                  width="366px"
                  alternateStyle
                  handleMutation={updateField}
                  placeHolder="Type your name"
                />
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default HAPatientConsent;
