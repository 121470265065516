// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { RouteComponentProps, Redirect } from 'react-router';

import { gql } from '@apollo/client';

export const CREATE_PATIENT = gql`
  mutation CreatePatient {
    createPatient {
      patient {
        id
      }
    }
  }
`;

class HomePage extends Component<RouteComponentProps> {
  private createPatientButton(): JSX.Element {
    return (
      <Mutation<{ createPatient: { patient: { id: string } } }> mutation={CREATE_PATIENT}>
        {(createPatient, { loading, data }): JSX.Element => {
          if (!loading && data) {
            const { id } = data.createPatient.patient;
            return <Redirect to={`registration/${id}/basic`} push />;
          }
          const text = loading ? 'Loading' : 'Make Patient';
          return (
            <Fragment>
              <div
                className="m-5 create-patient"
                onClick={() => {
                  createPatient();
                }}>
                {text}
              </div>
              <a className="m-5 link" href="/sso/login/azuread-oauth2?next=/home">
                Log In (SSO)
              </a>
            </Fragment>
          );
        }}
      </Mutation>
    );
  }

  public render(): JSX.Element {
    return <div>{this.createPatientButton()}</div>;
  }
}

export default HomePage;
