import { useMutation } from '@apollo/client';
import moment from 'moment';
import { LOG_EVENT } from 'op-graphql';
import { NewPatient } from 'op-interfaces/PatientInterfaces';
import { isUs } from 'op-utils';
import { genderTitle } from 'op-utils/PatientHelper';
import React, { useRef } from 'react';
import GCButton from 'shared-components/components/UIFormComponents/GCButton';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import { Region } from 'shared-components/enums';
import { WarningTriangle } from 'shared-components/images';
import { calculateMomentAge, logPage } from 'shared-components/utils';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'gc-ui';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useTheme, TableContainer, Typography, TableHead, TableBody, TableRow, Table, styled } from '@mui/material';
import { RadioButtonChecked as RadioButtonCheckedIcon } from '@mui/icons-material';

const tableHeader = {
  // TEMP
  DEFAULT: {
    headers: ['Potential duplicate patient(s)', 'Date of Birth', 'Address'],
  },
  [Region.UK]: {
    headers: ['Potential duplicate patient(s)', 'Date of Birth', 'Address'],
  },
};
const StyledTableCell = styled(TableCell)((props) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: props.theme.palette.grey[200],
    color: props.theme.palette.common.black,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface ModalDuplicationResultIProps {
  newPatientData: NewPatient;
  duplicateData: any[];
  isOpen: boolean;
  handleClose: () => void;
  rightButtonText: string;
  rightButtonHandleClick: () => void;
}

const ModalDuplicationResult = (props: ModalDuplicationResultIProps): JSX.Element => {
  const { newPatientData, duplicateData, isOpen, handleClose, rightButtonText, rightButtonHandleClick } = props;
  const [logEvent] = useMutation(LOG_EVENT);
  const startTime = useRef<number>(new Date().getTime());
  const theme = useTheme();

  const redirectToPatientSummary = (patientId: string | number) => {
    const pageData = {
      eventName: 'create_patient_duplicate_selected',
      patientId,
    };
    logPage(startTime?.current, pageData, logEvent);
    const win = window.open(`/navigator/patient/${patientId}/summary`, '_blank');
    win?.focus();
  };

  const fullName = (firstName: string, lastName: string) => `${firstName} ${lastName}`;

  let formattedAddress = newPatientData.address.line2 ? `${newPatientData.address.line2}, ` : '';
  formattedAddress += newPatientData.address.line1 ? `${newPatientData.address.line1}, ` : '';
  formattedAddress += newPatientData.address.city ? `${newPatientData.address.city}, ` : '';
  formattedAddress += newPatientData.address.state ? `${newPatientData.address.state} ` : '';
  formattedAddress += newPatientData.address.postcode ? `${newPatientData.address.postcode} ` : '';
  formattedAddress += newPatientData.address.country ? `${newPatientData.address.country} ` : '';

  return (
    <Modal onClose={handleClose} open={isOpen} maxWidth="lg" sx={{ padding: '20px' }}>
      <ModalHeader toggle={handleClose}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Check potiential duplicate patient record{' '}
          <span className="icon">
            <WarningTriangle title="Warning_Triangle.svg" />
          </span>
        </Typography>
      </ModalHeader>
      <ModalBody>
        <div>
          <Typography variant="body1" sx={{ lineHeight: '1.6rem', fontSize: '16px', paddingBottom: '8px' }}>
            The patient you are about to create may already exist in the system. If a match is listed in the table
            below, open the patient record by clicking on the table row. If no match is found, click continue to create
            a new patient record.
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
            Create new patient
          </Typography>
          <div>
            <Table
              style={{
                border: `1px solid ${theme.palette.primary.main}`,
              }}>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: '300px', borderBottom: `1px solid ${theme.palette.primary.main}` }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                      <RadioButtonCheckedIcon color="primary" sx={{ height: '100%' }} />
                      <div>
                        {fullName(newPatientData.firstName, newPatientData.lastName)}{' '}
                        {genderTitle(newPatientData.gender)}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell style={{ width: '188px', borderBottom: `1px solid ${theme.palette.primary.main}` }}>
                    {moment(newPatientData.dateOfBirth).format(isUs() ? 'MMM DD, YYYY' : 'DD MMM YYYY')} (age{' '}
                    {calculateMomentAge(moment(newPatientData.dateOfBirth))})
                  </TableCell>
                  <TableCell sx={{ borderBottom: `1px solid ${theme.palette.primary.main}` }}>
                    {formattedAddress}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>

        <TableContainer sx={{ paddingTop: '8px' }}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeader.DEFAULT.headers.map((header: string, i: number) => (
                  <StyledTableCell key={i}>{header}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {duplicateData.map((patient: any, i: number) => {
                return (
                  <StyledTableRow key={i} onClick={() => redirectToPatientSummary(patient.id)}>
                    <TableCell style={{ width: '300px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
                        <PhotoWidget url={patient.photoUrl} enableOverlay={true} size={'sm'} />
                        <div>
                          <div>{fullName(patient.firstName, patient.lastName)}</div>
                          <div>Patient ID: {patient.id}</div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ width: '188px' }}>
                      {isUs() ? moment(patient.dob).format('MMM DD, YYYY') : moment(patient.dob).format('DD MMM YYYY')}{' '}
                      (age {calculateMomentAge(moment(patient.dob))})
                    </TableCell>
                    <TableCell>{patient.address?.formattedAddress}</TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalBody>
      <ModalFooter
        style={{
          borderTop: `1px solid ${theme.palette.secondary.main}`,
          padding: '24px',
        }}>
        <GCButton
          buttonText={rightButtonText}
          backgroundColor={theme.palette.primary.dark}
          borderColor={theme.palette.primary.dark}
          textColor={theme.palette.primary.contrastText}
          fontWeight={'700'}
          fontSize={'14px'}
          minWidth={'91px'}
          width={'91px'}
          height={'40px'}
          margin={'0 16px 0 0'}
          onClick={rightButtonHandleClick}
        />
      </ModalFooter>
    </Modal>
  );
};

ModalDuplicationResult.defaultProps = {
  rightButtonText: 'Continue',
};

export default ModalDuplicationResult;
