import React from 'react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Button from '@mui/material/Button';

interface Props {
  content: any[];
}

const arrayToCsvString = (dataArray: any[]) => {
  const escapeCsvValue = (value: any) => {
    // If the value contains a comma, surround it with double quotes and escape any existing double quotes
    if (typeof value === 'string') {
      if (value.includes(',')) {
        return `"${value.replace(/"/g, '""')}"`;
      }
      return value;
    }
    return value;
  };
  // Extract headers from the first object in the array
  const headers = Object.keys(dataArray[0]);

  // Create CSV header row
  const csvHeader = headers.join(',');

  // Create CSV rows
  const csvRows = dataArray.map((obj) => {
    return headers
      .map((header) => {
        return escapeCsvValue(obj[header]);
      })
      .join(',');
  });

  // Combine header and rows
  const csvContent = [csvHeader, ...csvRows].join('\n');

  return csvContent;
};

const CsvDownloadButton = (props: Props) => {
  const downloadCsv = () => {
    // CSV content

    // 'Name,Email\nJohn Doe,johndoe@example.com\nJane Smith,janesmith@example.com';
    const csvContent = arrayToCsvString(props.content);
    // Create a Blob object
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.csv');

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button
      variant="outlined"
      onClick={downloadCsv}
      startIcon={<FileUploadOutlinedIcon />}
      sx={{
        borderRadius: '5px',
        backgroundColor: '#081e43',
        textTransform: 'none',
        color: 'white',
        border: 'none',
        '&:hover': { backgroundColor: '#05152E', border: 'none' },
      }}>
      Export CSV
    </Button>
  );
};

export default CsvDownloadButton;
