import { DEFAULT_TNM_STAGE_TYPE, MorphologyType, DiagnosisRouterParams } from '../Interface';
// eslint-disable-next-line no-use-before-define
import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { ROAlert } from 'shared-components/components';
import { ROAutocomplete, ROTextField, ROToggleButtons } from 'shared-components/components/FormFields';
import { RODatePicker } from 'shared-components/components/FormFields';
import { codeToIntakePageMapping } from '../Utils';
import {
  LOAD_STAGE_MORPHOLOGY_LIST,
  GET_DIAGNOSIS,
  UPDATE_DIAGNOSIS,
  LOAD_STAGE_TNM_LIST,
  LOG_CLINEX_MUTATION,
} from '../Queries';
import { getOptionByValue } from '../Utils';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import ROPatientCarePlanPageFooter from '../../Footer';
import { TnmStageType } from '../Interface';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { logPage } from 'shared-components/utils';
import { useWindowSize, deepCopyFunction } from 'shared-components/utils/CustomHooks';
import ROPatientCarePlanContext from '../../ROPatientCarePlanContext';
import { CAREPLAN_PAGES } from '../../Constants';
import { validateField, isPageValid, setReactAppValidation } from '../../ValidationEngine';
import { LOAD_CAREPLAN_LIST_DATA } from '../../ListData/ListQueries';
import { filterListdata } from '../../ListData/ListData';
import { CurrentAppConfig } from '../../AppConfig';
import { LoadingSpinner } from 'shared-components/components';
import CareplanContainer from '../../Container';
import { useErrorModalContext } from 'op-contexts';
import { ClinexContext } from '../Clinex/context';
import {
  isFieldLowCertainty,
  LOW_CERTAINTY,
  ClinexTooltip,
  stagingClinexMappings,
  selectFirstHighlight,
  PLEASE_REVIEW_CLINEX,
} from '../Clinex/helpers';
import { CareplanBanners } from '../../Banner/Banner';
import { GET_CAREPLAN } from '../../Queries';
import { convertMorphologyValuesToOptions, getMorphologyAndTNMString } from './Helper';
import lodash from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectOptionType } from 'shared-components/components/FormFields';

const DEFAULT_T_STAGE = 'T';
const DEFAULT_N_STAGE = 'N';
const DEFAULT_M_STAGE = 'M';

interface State {
  tStagePathologicalList: SelectOptionType[];
  nStagePathologicalList: SelectOptionType[];
  mStagePathologicalList: SelectOptionType[];
  tStageClinicalList: SelectOptionType[];
  nStageClinicalList: SelectOptionType[];
  mStageClinicalList: SelectOptionType[];
  tnmStagingOption: TnmStageType[];
  diagnosis: {
    stageType: string;
    stageTCodePathological: TnmStageType | null;
    stageNCodePathological: TnmStageType | null;
    stageMCodePathological: TnmStageType | null;
    stageTCodeClinical: TnmStageType | null;
    stageNCodeClinical: TnmStageType | null;
    stageMCodeClinical: TnmStageType | null;
  };
}

const STAGE_TYPE_OPTIONS = [
  { value: 'clinical', label: 'Clinical' },
  { value: 'pathological', label: 'Pathological' },
  { value: 'both', label: 'Clinical & Pathological' },
];

const tnmOptionList = (optionList: TnmStageType[]): SelectOptionType[] => {
  return optionList.map(
    (option: any): SelectOptionType => ({
      value: option.subCode,
      label: `${option.subCode} - ${option.description}`,
    }),
  );
};

const setTnmStagingOptions = (optionsState: State, options: TnmStageType[], stage?: string): State => {
  const clinicalOpions = options.filter(
    (option: TnmStageType): boolean => option.mainCode === 'c' + stage || option.mainCode === stage,
  );
  const pathologicalOpions = options.filter(
    (option: TnmStageType): boolean => option.mainCode === 'p' + stage || option.mainCode === stage,
  );
  switch (stage) {
    case DEFAULT_T_STAGE:
      optionsState['tStagePathologicalList'] = tnmOptionList(pathologicalOpions);
      optionsState['tStageClinicalList'] = tnmOptionList(clinicalOpions);
      break;
    case DEFAULT_N_STAGE:
      optionsState['nStagePathologicalList'] = tnmOptionList(pathologicalOpions);
      optionsState['nStageClinicalList'] = tnmOptionList(clinicalOpions);
      break;
    case DEFAULT_M_STAGE:
      optionsState['mStagePathologicalList'] = tnmOptionList(pathologicalOpions);
      optionsState['mStageClinicalList'] = tnmOptionList(clinicalOpions);
      break;
    case 'global':
      optionsState['tnmStagingOption'] = options;
      break;
    default:
      return optionsState;
  }
  return optionsState;
};

const getStageOptionType = (tnmStagingOption: any): SelectOptionType | undefined => {
  if (tnmStagingOption?.subCode) {
    return {
      label: tnmStagingOption.subCode + ' - ' + tnmStagingOption.description,
      value: tnmStagingOption.subCode,
    };
  }
  return undefined;
};

const ROPatientDiagnosisStagingPageLegacy = (props: any): JSX.Element => {
  const { setError } = useErrorModalContext();
  const { onDataRefresh } = props;
  const match = useRouteMatch<DiagnosisRouterParams>();
  const [dateErrorState, setDateErrorState] = useState(false);
  const { diagnosisId, id: patientId, careplanId, oncologyType } = match.params;
  const startTime = useRef<number>(new Date().getTime());
  const [logEvent] = useMutation(LOG_CLINEX_MUTATION);
  const { state: careplanState } = useContext(ROPatientCarePlanContext);
  const clinexContexts = useContext(ClinexContext);
  const { clinexData, uuid } = clinexContexts;
  const [accordianExpanded, setAccordianExpanded] = useState(false);
  const [pressedContinue, setPressedContinue] = useState(false);
  const theme = useTheme();

  const history = useHistory();

  const [, updateState] = useState({});
  const forceUpdate = useCallback((): void => updateState({}), []);

  const { loading, error, data } = useQuery(GET_DIAGNOSIS, {
    variables: { diagnosisId },
  });

  useEffect(() => {
    if (error) return setError();
  }, [error]);

  const { data: careplanData } = useQuery(GET_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { id: careplanId },
  });
  const [setDiagnosis] = useMutation(UPDATE_DIAGNOSIS, {
    refetchQueries: [
      { query: GET_DIAGNOSIS, variables: { diagnosisId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      onDataRefresh();
    },
  });

  const [state, setState] = useState<State>({
    tStagePathologicalList: [],
    nStagePathologicalList: [],
    mStagePathologicalList: [],
    tStageClinicalList: [],
    nStageClinicalList: [],
    mStageClinicalList: [],
    tnmStagingOption: [],
    diagnosis: {
      stageType: '',
      stageTCodePathological: null,
      stageNCodePathological: null,
      stageMCodePathological: null,
      stageTCodeClinical: null,
      stageNCodeClinical: null,
      stageMCodeClinical: null,
    },
  });
  const windowSize = useWindowSize();
  const isSmallDevice = windowSize.width < 1900;
  const [tOtherInfo, setTOtherInfo] = useState<string | null>(null);
  const [morphologyOptions, setMorphologyOptions] = useState<SelectOptionType[]>([]);
  const [lateralityOptions, setLateralityOptions] = useState<SelectOptionType[]>([]);
  const [validated] = useState(false);

  useEffect(() => {
    if (clinexData && !isSmallDevice) {
      selectFirstHighlight(stagingClinexMappings, clinexContexts);
    }
  }, [clinexData]);

  useQuery(LOAD_CAREPLAN_LIST_DATA, {
    variables: { listCategory: 'lateralityDiagnosis', patientId },
    onCompleted: (data: any): void => {
      setLateralityOptions(filterListdata(data, 'lateralityDiagnosis'));
    },
  });
  const isPrimaryDiagnosis = Boolean(data.diagnosis.isPrimaryDiagnosis);
  const primaryDiagnosis = isPrimaryDiagnosis ? data?.diagnosis : data?.diagnosis.relatedPrimaryDiagnosis;
  const metastasisDiagnosis = data?.diagnosis.isPrimaryDiagnosis ? null : data?.diagnosis;
  const tumourStream = data?.diagnosis?.diagnosisCode ? data?.diagnosis?.diagnosisCode?.tumourStream.name : '';
  const groupNumber = primaryDiagnosis.diagnosisCode ? primaryDiagnosis.diagnosisCode.stageNumber : '';
  const hasGroupNumber = typeof groupNumber !== 'undefined' && groupNumber !== null && groupNumber !== '';
  const tnmGroupNumber = hasGroupNumber ? groupNumber.toString() : null;

  const [getTNMOptionsQuery, { loading: loadingTNM, error: errorTNM, data: tnmStagingList }] = useLazyQuery(
    LOAD_STAGE_TNM_LIST,
    {
      variables: { groupNumber: tnmGroupNumber },
      onCompleted: (data: any): void => {
        if (data && data.tnmLookupGroupList) {
          const tStage = data.tnmLookupGroupList.filter((stage: TnmStageType): boolean =>
            stage.mainCode.includes(DEFAULT_T_STAGE),
          );
          const nStage = data.tnmLookupGroupList.filter((stage: TnmStageType): boolean =>
            stage.mainCode.includes(DEFAULT_N_STAGE),
          );
          const mStage = data.tnmLookupGroupList.filter((stage: TnmStageType): boolean =>
            stage.mainCode.includes(DEFAULT_M_STAGE),
          );
          // stop loading on state change
          let newState: State = { ...state };
          newState = setTnmStagingOptions(newState, tnmStagingList.tnmLookupGroupList, 'global');
          newState = setTnmStagingOptions(newState, tStage, DEFAULT_T_STAGE);
          newState = setTnmStagingOptions(newState, nStage, DEFAULT_N_STAGE);
          newState = setTnmStagingOptions(newState, mStage, DEFAULT_M_STAGE);
          setState(newState);
        }
      },
    },
  );
  const [getMorphOptionsQuery, { loading: loadingMorph, error: errorMorph, refetch: refetchMorphologyValues }] =
    useLazyQuery(LOAD_STAGE_MORPHOLOGY_LIST, {
      variables: { tumourStream },
      onCompleted: (data: { morphologyLookupList: MorphologyType[] }): void => {
        if (data?.morphologyLookupList) {
          setMorphologyOptions(convertMorphologyValuesToOptions(data.morphologyLookupList));
        }
      },
    });

  useEffect(() => {
    if (hasGroupNumber && tnmGroupNumber) {
      getTNMOptionsQuery();
    }
    if (tumourStream && tumourStream !== '') {
      getMorphOptionsQuery();
    }
  }, [tnmGroupNumber, tumourStream]);

  useEffect(() => {
    refetchMorphologyValues();
  }, [tumourStream]);

  useEffect(() => {
    if (errorTNM || errorMorph) return setError();
  }, [errorTNM, errorMorph]);

  if (tumourStream === '') return <h1>Error primary diagnosis not selected</h1>;

  if (!data || loading || loadingTNM || loadingMorph)
    return <LoadingSpinner loadingText={'Loading Staging'} subtitle={'Please wait while we set things up for you'} />;

  const diagnosis = data.diagnosis;
  const originalDiagnosis = { ...diagnosis };
  const isExistingPrimary = metastasisDiagnosis?.relatedPrimaryDiagnosis?.draft === false;

  const otherInfo = diagnosis.isPrimaryDiagnosis ? diagnosis.otherInfo : diagnosis.relatedPrimaryDiagnosis.otherInfo;
  const setDiagnosisWrapper = (diag: any): void => {
    setDiagnosis({
      variables: {
        id: diag.id,
        stageType: diag.stageType,
        stageTCodePathological: diag.stageTCodePathological?.id || -1,
        stageNCodePathological: diag.stageNCodePathological?.id || -1,
        stageMCodePathological: diag.stageMCodePathological?.id || -1,
        stageTCodeClinical: diag.stageTCodeClinical?.id || -1,
        stageNCodeClinical: diag.stageNCodeClinical?.id || -1,
        stageMCodeClinical: diag.stageMCodeClinical?.id || -1,
        morphology: diag.morphology && diag.morphology.displayName,
        laterality: diag.laterality,
        otherInfo: diag.otherInfo,
        diagnosisDate: diag.diagnosisDate,
      },
    });
  };
  const handleDispatch = (primaryDiagnosis: any, metastasisDiagnosis: any): void => {
    const copyOfPrimary = deepCopyFunction(primaryDiagnosis);
    const copyOfOriginal = deepCopyFunction(originalDiagnosis);

    const reduceDiagnosis = (diagnosisToReduce: any) => {
      Object.keys(diagnosisToReduce).map((field: any): any => {
        if (diagnosisToReduce[field] && typeof diagnosisToReduce[field] === 'object') {
          Reflect.deleteProperty(diagnosisToReduce, 'id');
          Reflect.deleteProperty(diagnosisToReduce, '__typename');
          diagnosisToReduce[field] = [...Object.values(diagnosisToReduce[field])].sort();
        }
        return diagnosisToReduce[field];
      });
      return diagnosisToReduce;
    };

    const reducedPrimary = reduceDiagnosis(copyOfPrimary);
    const reducedOriginal = reduceDiagnosis(copyOfOriginal);

    const isDiagnosisSame =
      JSON.stringify([...Object.values(reducedPrimary)].sort()) ===
      JSON.stringify([...Object.values(reducedOriginal)].sort());

    if (diagnosis.isPrimaryDiagnosis && !isDiagnosisSame) {
      setDiagnosisWrapper(primaryDiagnosis);
    }
    if (!diagnosis.isPrimaryDiagnosis) {
      setDiagnosisWrapper(primaryDiagnosis);
      setDiagnosisWrapper(metastasisDiagnosis);
    }
  };

  const primaryStagingConfig =
    oncologyType === 'radiation'
      ? CurrentAppConfig.QuestionValidationChildren.Staging.Primary.RO
      : CurrentAppConfig.QuestionValidationChildren.Staging.Primary.MO;
  const metastasisStagingConfig =
    oncologyType === 'radiation'
      ? CurrentAppConfig.QuestionValidationChildren.Staging.Metastasis.RO
      : CurrentAppConfig.QuestionValidationChildren.Staging.Metastasis.MO;
  // draft is used to treat this data as immutable, as draft becomes false after submission. Therefore we should only consider draft candidates
  const primaryValidationDependencyStructure = [
    {
      parent: null,
      expansionValue: true,
      children: primaryStagingConfig,
    },
  ];
  const metastasisValidationDependencyStructure = [
    {
      parent: null,
      expansionValue: true,
      children: metastasisStagingConfig,
    },
  ];

  const isStagingValid = (): boolean => {
    if (otherInfo === 'BORIS_BYPASS_VALIDATION') {
      setReactAppValidation('Y');
    }
    if (otherInfo === 'BORIS_ENABLE_VALIDATION') {
      setReactAppValidation('N');
    }
    const isPrimaryValid = isExistingPrimary
      ? true
      : primaryDiagnosis
      ? isPageValid(primaryValidationDependencyStructure, primaryDiagnosis)
      : true;
    const isMetaValid = metastasisDiagnosis
      ? isPageValid(metastasisValidationDependencyStructure, metastasisDiagnosis)
      : true;

    return isPrimaryValid && isMetaValid;
  };

  const getFieldData = () => {
    let primary_dx = diagnosis;
    let metsData = {};
    const isPrimary = diagnosis.isPrimaryDiagnosis;

    if (!isPrimary) {
      primary_dx = diagnosis.relatedPrimaryDiagnosis;
      metsData = {
        metastasisLaterality: diagnosis.laterality,
        metastasisDiagnosisDate: diagnosis.diagnosisDate,
        metastasisDiagnosis: diagnosis.diagnosisCode?.diagnosisCode,
      };
    }

    return {
      morphology: primary_dx.morphology?.displayName,
      stageType: primary_dx.stageType,
      stageTCodePathologicalSubcode: primary_dx.stageTCodePathological?.subCode,
      stageTCodePathologicalDescription: primary_dx.stageTCodePathological?.description,
      stageNCodePathologicalSubcode: primary_dx.stageNCodePathological?.subCode,
      stageNCodePathologicalDescription: primary_dx.stageNCodePathological?.description,
      stageMCodePathologicalSubcode: primary_dx.stageMCodePathological?.subCode,
      stageMCodePathologicalDescription: primary_dx.stageMCodePathological?.description,
      stageTCodeClinicalSubcode: primary_dx.stageTCodeClinical?.subCode,
      stageTCodeClinicalDescription: primary_dx.stageTCodeClinical?.description,
      stageNCodeClinicalSubcode: primary_dx.stageNCodeClinical?.subCode,
      stageNCodeClinicalDescription: primary_dx.stageNCodeClinical?.description,
      stageMCodeClinicalSubcode: primary_dx.stageMCodeClinical?.subCode,
      stageMCodeClinicalDescription: primary_dx.stageMCodeClinical?.description,
      primaryLaterality: primary_dx.laterality,
      primaryDiagnosisDate: primary_dx.diagnosisDate,
      otherInfo: primary_dx.otherInfo,
      ...metsData,
    };
  };

  const pageData = {
    diagnosisId: diagnosisId,
    fields: getFieldData(),
    isPrimaryDiagnosis: diagnosis.isPrimaryDiagnosis,
    relatedPrimaryDiagnosis: diagnosis.isPrimaryDiagnosis
      ? ''
      : diagnosis.relatedPrimaryDiagnosis?.diagnosisCode?.diagnosisCode,
    tumourStream: diagnosis.diagnosisCode?.tumourStream.name,
    patientId: patientId,
    page: CAREPLAN_PAGES.STAGING,
    ajcc_major_version: 7,
    careplanId: careplanId,
    clinexResponseUuid: uuid,
  };

  const isFieldValid = (field: any): boolean => {
    return pressedContinue ? validateField(field) : true;
  };
  const clearUnusedStageValues = () => {
    const { stageType } = primaryDiagnosis;
    const primaryDiagnosisClone = lodash.cloneDeep(primaryDiagnosis);
    if (stageType === 'pathological') {
      primaryDiagnosisClone.stageTCodeClinical = null;
      primaryDiagnosisClone.stageNCodeClinical = null;
      primaryDiagnosisClone.stageMCodeClinical = null;
    }
    if (stageType === 'clinical') {
      primaryDiagnosisClone.stageTCodePathological = null;
      primaryDiagnosisClone.stageNCodePathological = null;
      primaryDiagnosisClone.stageMCodePathological = null;
    }

    handleDispatch(primaryDiagnosisClone, metastasisDiagnosis);
  };

  const hasIntakePage = Boolean(
    codeToIntakePageMapping(primaryDiagnosis?.diagnosisCode?.diagnosisCode, careplanState.intakePageMapping) &&
      careplanData?.careplan?.isPrimDiagnosisOwner,
  );

  const backUrl = primaryDiagnosis
    ? `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/primary/newPrimary`
    : `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/primary`;

  const nextURL = hasIntakePage
    ? `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/${CAREPLAN_PAGES.INTAKE}`
    : `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.TREATMENT_INFO}`;

  const footerProps = {
    backDisabled: diagnosis && diagnosis.hasTreatmentStarted,
    onBack: (): void => {
      // Remove last history
      history.push(backUrl);
    },
    onNext: (): void => {
      clearUnusedStageValues();
      setPressedContinue(true);
      // Required a separate check for date validation as it is not handled by the validation engine (only checks for a value not the format)
      forceUpdate();
      if (dateErrorState) return;
      if (!isStagingValid()) return;
      logPage(startTime?.current, pageData, logEvent);
      // redirect to next page
      history.push(nextURL);
    },
  };

  const stageType =
    state.diagnosis && state.diagnosis.stageType ? state.diagnosis.stageType : primaryDiagnosis.stageType;
  const diagTypeStyle = {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 500,
    marginBottom: '12px',
  };

  const isPrimaryDiagnosisDateRequired = primaryStagingConfig.includes('diagnosisDate');
  const isSecondaryDiagnosisDateRequired = metastasisStagingConfig.includes('diagnosisDate');
  const isPrimaryLateralityRequired = primaryStagingConfig.includes('laterality');
  const isSecondaryLateralityRequired = metastasisStagingConfig.includes('laterality');

  return (
    <>
      <div
        className={classNames('main-container-wrapper', {
          'clinex-wrapper': clinexData && !isSmallDevice,
        })}>
        <CareplanBanners data={careplanData} />
        <CareplanContainer>
          <div className="page-header">
            <h1>{'Staging'}</h1>
            {clinexData && PLEASE_REVIEW_CLINEX}
          </div>
          {typeof validated !== 'undefined' && validated === true && (
            <ROAlert title={'Please fix the errors.'}>{'Mandatory field missing'}</ROAlert>
          )}
          <Box component="form" id="stagingForm" noValidate>
            <>
              {primaryDiagnosis && diagnosis.relatedPrimaryDiagnosis?.draft !== false && (
                <>
                  <h2 style={diagTypeStyle}>Primary Diagnosis</h2>
                  <Accordion sx={{ marginBottom: '16px', maxWidth: '680px' }} expanded={accordianExpanded}>
                    <AccordionSummary
                      data-testid="staging-accordion"
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => setAccordianExpanded((expanded) => !expanded)}>
                      {accordianExpanded ? (
                        <Typography>Morphology & TNM Staging</Typography>
                      ) : (
                        <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '680px', alignItems: 'center' }}>
                          <Typography style={{ minWidth: '234px' }}>Morphology & TNM Staging</Typography>
                          <Typography>{getMorphologyAndTNMString(primaryDiagnosis)}</Typography>
                        </div>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <ClinexTooltip clinexData={clinexData} clinexField={'histology_code'} formValues={diagnosis}>
                        <ROAutocomplete
                          id="morphology"
                          fieldlabel="Morphology"
                          options={morphologyOptions}
                          value={getOptionByValue(morphologyOptions, primaryDiagnosis?.morphology?.displayName || '')}
                          onChange={(option: SelectOptionType | string): void => {
                            const value = typeof option === 'string' ? option : option?.value;
                            const primaryDiagnosisClone = lodash.cloneDeep(primaryDiagnosis);
                            primaryDiagnosisClone.morphology = {
                              displayName: value || '',
                            };
                            handleDispatch(primaryDiagnosisClone, metastasisDiagnosis);
                          }}
                          inputProps={{
                            helperText: LOW_CERTAINTY,
                            warning: isFieldLowCertainty(clinexData, 'histology_code', diagnosis),
                          }}
                          sx={{ maxWidth: '400px' }}
                        />
                      </ClinexTooltip>
                      <ROToggleButtons
                        id="stageType"
                        fieldlabel=""
                        options={STAGE_TYPE_OPTIONS}
                        value={stageType ? stageType : primaryDiagnosis.stageType}
                        handleChange={(value: string): void => {
                          setState({ ...state, diagnosis: { ...state.diagnosis, stageType: value } });
                          const primaryDiagnosisClone = lodash.cloneDeep(primaryDiagnosis);
                          if (primaryDiagnosis) {
                            if (value === 'clinical' || value === 'pathological') {
                              setState({
                                ...state,
                                diagnosis: {
                                  ...state.diagnosis,
                                  stageType: value,
                                },
                              });
                            }
                            primaryDiagnosisClone.stageType = value;
                            handleDispatch(primaryDiagnosisClone, metastasisDiagnosis);
                          }
                        }}
                      />
                      {stageType === 'clinical' || stageType === 'both' ? (
                        <>
                          <Typography variant="h6" color={theme.palette.grey[600]}>
                            CLINICAL
                          </Typography>
                          <ClinexTooltip
                            clinexData={clinexData}
                            clinexField={'n_stage_clinical'}
                            formValues={diagnosis}>
                            <ROAutocomplete
                              id="stageTCodeClinical"
                              fieldlabel="T Stage"
                              options={state.tStageClinicalList}
                              value={getStageOptionType(
                                state.diagnosis.stageTCodeClinical || primaryDiagnosis.stageTCodeClinical,
                              )}
                              onChange={(option: SelectOptionType | string): void => {
                                const value = typeof option === 'string' ? option : option?.value;
                                const primaryDiagnosisClone = lodash.cloneDeep(primaryDiagnosis);
                                const selectedStage: TnmStageType | undefined = value
                                  ? state.tnmStagingOption.find(
                                      (option: TnmStageType): boolean => option.subCode === value,
                                    )
                                  : DEFAULT_TNM_STAGE_TYPE;
                                if (selectedStage) {
                                  primaryDiagnosisClone.stageTCodeClinical = selectedStage;
                                  setState({
                                    ...state,
                                    diagnosis: { ...state.diagnosis, stageTCodeClinical: selectedStage },
                                  });
                                  handleDispatch(primaryDiagnosisClone, metastasisDiagnosis);
                                }
                              }}
                              inputProps={{
                                helperText: LOW_CERTAINTY,
                                warning: isFieldLowCertainty(clinexData, 't_stage_clinical', diagnosis),
                              }}
                            />
                          </ClinexTooltip>
                          <ClinexTooltip
                            clinexData={clinexData}
                            clinexField={'m_stage_pathological'}
                            formValues={diagnosis}>
                            <ROAutocomplete
                              id="stageNCodeClinical"
                              fieldlabel="N Stage"
                              options={state.nStageClinicalList}
                              value={getStageOptionType(
                                state.diagnosis.stageNCodeClinical || primaryDiagnosis.stageNCodeClinical,
                              )}
                              onChange={(option: SelectOptionType | string): void => {
                                const value = typeof option === 'string' ? option : option?.value;
                                const primaryDiagnosisClone = lodash.cloneDeep(primaryDiagnosis);
                                const selectedStage: TnmStageType | undefined = value
                                  ? state.tnmStagingOption.find(
                                      (option: TnmStageType): boolean => option.subCode === value,
                                    )
                                  : DEFAULT_TNM_STAGE_TYPE;
                                if (selectedStage) {
                                  primaryDiagnosisClone.stageNCodeClinical = selectedStage;
                                  setState({
                                    ...state,
                                    diagnosis: { ...state.diagnosis, stageNCodeClinical: selectedStage },
                                  });
                                  handleDispatch(primaryDiagnosisClone, metastasisDiagnosis);
                                }
                              }}
                              inputProps={{
                                helperText: LOW_CERTAINTY,
                                warning: isFieldLowCertainty(clinexData, 'n_stage_clinical', diagnosis),
                              }}
                            />
                          </ClinexTooltip>
                          <ClinexTooltip
                            clinexData={clinexData}
                            clinexField={'m_stage_clinical'}
                            formValues={diagnosis}>
                            <ROAutocomplete
                              id="stageMCodeClinical"
                              fieldlabel="M Stage"
                              options={state.mStageClinicalList}
                              value={getStageOptionType(
                                state.diagnosis.stageMCodeClinical || primaryDiagnosis.stageMCodeClinical,
                              )}
                              onChange={(option: SelectOptionType | string): void => {
                                const value = typeof option === 'string' ? option : option?.value;
                                const primaryDiagnosisClone = lodash.cloneDeep(primaryDiagnosis);
                                const selectedStage: TnmStageType | undefined = value
                                  ? state.tnmStagingOption.find(
                                      (option: TnmStageType): boolean => option.subCode === value,
                                    )
                                  : DEFAULT_TNM_STAGE_TYPE;
                                if (selectedStage) {
                                  primaryDiagnosisClone.stageMCodeClinical = selectedStage;
                                  setState({
                                    ...state,
                                    diagnosis: { ...state.diagnosis, stageMCodeClinical: selectedStage },
                                  });
                                  handleDispatch(primaryDiagnosisClone, metastasisDiagnosis);
                                }
                              }}
                              inputProps={{
                                helperText: LOW_CERTAINTY,
                                warning: isFieldLowCertainty(clinexData, 'm_stage_clinical', diagnosis),
                              }}
                            />
                          </ClinexTooltip>
                        </>
                      ) : null}
                      {stageType === 'pathological' || stageType === 'both' ? (
                        <>
                          <Typography variant="h6" color={theme.palette.grey[600]}>
                            PATHOLOGICAL
                          </Typography>
                          <ClinexTooltip
                            clinexData={clinexData}
                            clinexField={'t_stage_pathological'}
                            formValues={diagnosis}>
                            <ROAutocomplete
                              id="stageTCodePathological"
                              fieldlabel="T Stage"
                              options={state.tStagePathologicalList}
                              value={getStageOptionType(
                                state.diagnosis.stageTCodePathological || primaryDiagnosis.stageTCodePathological,
                              )}
                              onChange={(option: SelectOptionType | string): void => {
                                const value = typeof option === 'string' ? option : option?.value;
                                const primaryDiagnosisClone = lodash.cloneDeep(primaryDiagnosis);
                                const selectedStage: TnmStageType | undefined = value
                                  ? state.tnmStagingOption.find(
                                      (option: TnmStageType): boolean => option.subCode === value,
                                    )
                                  : DEFAULT_TNM_STAGE_TYPE;
                                if (selectedStage) {
                                  primaryDiagnosisClone.stageTCodePathological = selectedStage;
                                  setState({
                                    ...state,
                                    diagnosis: { ...state.diagnosis, stageTCodePathological: selectedStage },
                                  });
                                  handleDispatch(primaryDiagnosisClone, metastasisDiagnosis);
                                }
                              }}
                              inputProps={{
                                helperText: LOW_CERTAINTY,
                                warning: isFieldLowCertainty(clinexData, 't_stage_pathological', diagnosis),
                              }}
                            />
                          </ClinexTooltip>
                          <ClinexTooltip
                            clinexData={clinexData}
                            clinexField={'n_stage_pathological'}
                            formValues={diagnosis}>
                            <ROAutocomplete
                              id="stageNCodePathological"
                              fieldlabel="N Stage"
                              options={state.nStagePathologicalList}
                              value={getStageOptionType(
                                state.diagnosis.stageNCodePathological || primaryDiagnosis.stageNCodePathological,
                              )}
                              onChange={(option: SelectOptionType | string): void => {
                                const value = typeof option === 'string' ? option : option?.value;
                                const primaryDiagnosisClone = lodash.cloneDeep(primaryDiagnosis);
                                const selectedStage: TnmStageType | undefined = value
                                  ? state.tnmStagingOption.find(
                                      (option: TnmStageType): boolean => option.subCode === value,
                                    )
                                  : DEFAULT_TNM_STAGE_TYPE;
                                if (selectedStage) {
                                  primaryDiagnosisClone.stageNCodePathological = selectedStage;
                                  setState({
                                    ...state,
                                    diagnosis: { ...state.diagnosis, stageNCodePathological: selectedStage },
                                  });
                                  handleDispatch(primaryDiagnosisClone, metastasisDiagnosis);
                                }
                              }}
                              inputProps={{
                                helperText: LOW_CERTAINTY,
                                warning: isFieldLowCertainty(clinexData, 'n_stage_pathological', diagnosis),
                              }}
                            />
                          </ClinexTooltip>
                          <ClinexTooltip
                            clinexData={clinexData}
                            clinexField={'m_stage_pathological'}
                            formValues={diagnosis}>
                            <ROAutocomplete
                              id="stageMCodePathological"
                              fieldlabel="M Stage"
                              options={state.mStagePathologicalList}
                              value={getStageOptionType(
                                state.diagnosis.stageMCodePathological || primaryDiagnosis.stageMCodePathological,
                              )}
                              onChange={(option: SelectOptionType | string): void => {
                                const value = typeof option === 'string' ? option : option?.value;
                                const primaryDiagnosisClone = lodash.cloneDeep(primaryDiagnosis);
                                const selectedStage: TnmStageType | undefined = value
                                  ? state.tnmStagingOption.find(
                                      (option: TnmStageType): boolean => option.subCode === value,
                                    )
                                  : DEFAULT_TNM_STAGE_TYPE;
                                if (selectedStage) {
                                  primaryDiagnosisClone.stageMCodePathological = selectedStage;
                                  setState({
                                    ...state,
                                    diagnosis: { ...state.diagnosis, stageMCodePathological: selectedStage },
                                  });
                                  handleDispatch(primaryDiagnosisClone, metastasisDiagnosis);
                                }
                              }}
                              inputProps={{
                                helperText: LOW_CERTAINTY,
                                warning: isFieldLowCertainty(clinexData, 'm_stage_pathological', diagnosis),
                              }}
                            />
                          </ClinexTooltip>
                        </>
                      ) : null}
                    </AccordionDetails>
                  </Accordion>
                  <ClinexTooltip clinexData={clinexData} clinexField={'laterality'} formValues={diagnosis}>
                    <ROAutocomplete
                      id="laterality"
                      fieldlabel="Primary Laterality"
                      options={lateralityOptions}
                      value={primaryDiagnosis.laterality}
                      inputProps={{
                        error: !isFieldValid(primaryDiagnosis.laterality),
                        helperText: !isFieldValid(primaryDiagnosis.laterality)
                          ? 'This field is required'
                          : LOW_CERTAINTY,
                        warning: isFieldLowCertainty(clinexData, 'laterality', diagnosis),
                      }}
                      required={isPrimaryLateralityRequired}
                      onChange={(option: SelectOptionType | string): void => {
                        const value = typeof option === 'string' ? option : option?.value;
                        const primaryDiagnosisClone = lodash.cloneDeep(primaryDiagnosis);
                        primaryDiagnosisClone.laterality = value || '';
                        handleDispatch(primaryDiagnosisClone, metastasisDiagnosis);
                      }}
                      sx={{ maxWidth: '430px' }}
                    />
                  </ClinexTooltip>
                  <ClinexTooltip clinexData={clinexData} clinexField={'diagnosis_date'} formValues={diagnosis}>
                    <RODatePicker
                      id="diagnosisDate"
                      fieldlabel="Primary Diagnosis Date"
                      required
                      disableFuture
                      value={
                        primaryDiagnosis.diagnosisDate ? dayjs(primaryDiagnosis.diagnosisDate.toString()) : undefined
                      }
                      error={!isFieldValid(primaryDiagnosis.diagnosisDate)}
                      warning={isFieldLowCertainty(clinexData, 'diagnosis_date', diagnosis)}
                      helperText={
                        isFieldLowCertainty(clinexData, 'diagnosis_date', diagnosis) ? LOW_CERTAINTY : undefined
                      }
                      onChange={(date: Dayjs | null, context: any) => {
                        const primaryDiagnosisClone = lodash.cloneDeep(primaryDiagnosis);
                        if (context.validationError) {
                          setDateErrorState(true);
                          if (context.validationError === 'maxDate') {
                            primaryDiagnosisClone.diagnosisDate = '';
                            handleDispatch(primaryDiagnosisClone, metastasisDiagnosis);
                          }
                        } else {
                          setDateErrorState(false);
                          const dateString = date ? date.format('YYYY-MM-DD').toString() : '';
                          primaryDiagnosisClone.diagnosisDate = dateString;
                          handleDispatch(primaryDiagnosisClone, metastasisDiagnosis);
                        }
                      }}
                    />
                  </ClinexTooltip>
                  <ROTextField
                    id="otherInfo"
                    fieldlabel={'Other Information'}
                    value={tOtherInfo !== null ? tOtherInfo : otherInfo}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      setTOtherInfo(event.target.value);
                    }}
                    onBlur={(event: React.FocusEvent<HTMLInputElement>): void => {
                      const value = event.target.value;
                      if (value == null) return;
                      setTOtherInfo(value);
                      const primaryDiagnosisClone = lodash.cloneDeep(primaryDiagnosis);
                      primaryDiagnosisClone.otherInfo = value;
                      handleDispatch(primaryDiagnosisClone, metastasisDiagnosis);
                    }}
                    sx={{ maxWidth: '430px' }}
                  />
                </>
              )}

              {!diagnosis.isPrimaryDiagnosis && metastasisDiagnosis && (
                <>
                  <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />
                  <h2 style={diagTypeStyle}>{'Metastasis Diagnosis'}</h2>
                  <ROAutocomplete
                    id="metastasisLaterality"
                    fieldlabel={'Metastasis Laterality'}
                    options={lateralityOptions}
                    value={metastasisDiagnosis.laterality}
                    inputProps={{
                      error: isSecondaryLateralityRequired && !isFieldValid(metastasisDiagnosis.laterality),
                    }}
                    required={isSecondaryLateralityRequired}
                    onChange={(option: SelectOptionType | string): void => {
                      const value = typeof option === 'string' ? option : option?.value;
                      const metastasisDiagnosisClone = lodash.cloneDeep(metastasisDiagnosis);
                      metastasisDiagnosisClone.laterality = value || '';
                      handleDispatch(primaryDiagnosis, metastasisDiagnosisClone);
                    }}
                    sx={{ maxWidth: '430px' }}
                  />
                  <RODatePicker
                    id="metastasisDiagnosisDate"
                    fieldlabel="Metastasis Diagnosis Date"
                    required={isSecondaryDiagnosisDateRequired}
                    error={isSecondaryDiagnosisDateRequired && !isFieldValid(metastasisDiagnosis.diagnosisDate)}
                    value={metastasisDiagnosis.diagnosisDate ? dayjs(metastasisDiagnosis.diagnosisDate) : null}
                    disableFuture
                    onChange={(date: Dayjs | null, context: any) => {
                      const metastasisDiagnosisClone = lodash.cloneDeep(metastasisDiagnosis);
                      if (context.validationError) {
                        setDateErrorState(true);
                        if (context.validationError === 'maxDate') {
                          metastasisDiagnosisClone.diagnosisDate = '';
                          handleDispatch(primaryDiagnosis, metastasisDiagnosisClone);
                        }
                      } else {
                        setDateErrorState(false);
                        const dateString = date ? date.format('YYYY-MM-DD').toString() : '';
                        metastasisDiagnosisClone.diagnosisDate = dateString;
                        handleDispatch(primaryDiagnosis, metastasisDiagnosisClone);
                      }
                    }}
                  />
                </>
              )}
            </>
          </Box>
        </CareplanContainer>
        <ROPatientCarePlanPageFooter {...footerProps} />
      </div>
    </>
  );
};

export default ROPatientDiagnosisStagingPageLegacy;
