import _ from 'lodash';
import { FormBuilderValidatorType, FormBuilderValueType } from './FormBuilderInterfaces';

export function fieldMeetsConditions(
  fieldValues: Record<string, FormBuilderValueType>,
  conditionsToMeet?: Record<string, FormBuilderValidatorType | Record<string, FormBuilderValidatorType>> | boolean,
  // dataType: string; TODO EVE-10692 add datatype to make life easier below
  defaultValue = true,
): boolean {
  // exit early for basic true / false
  if (typeof conditionsToMeet == 'boolean') return conditionsToMeet;

  // if no conditions then generally want this to be true...? disabled/read only the only exceptions?
  if (!conditionsToMeet) return defaultValue;

  // IMPORTANT! Have used every (instead of some) which means all conditions must be met
  return Object.entries(conditionsToMeet).every(([key, value]) => {
    const objectValueEntries =
      fieldValues[key] &&
      typeof fieldValues[key] === 'object' &&
      Object.entries(fieldValues[key] || {}).find(([key]) => {
        return key === 'value';
      });

    const valueAsNumber = parseFloat(fieldValues[key] as string);
    const arrayOfConditions = Array.isArray(value) ? value : [value];

    // TODO EVE-10692 revisit at some point, necessary to deal with all the possible data types, pretty ugly
    let fieldValue = fieldValues[key];
    if (objectValueEntries) {
      fieldValue = objectValueEntries[1];
    } else {
      if (!isNaN(valueAsNumber)) {
        fieldValue = valueAsNumber;
      }
    }
    const meetsUndefinedCondition =
      !fieldValues.hasOwnProperty(key) && (!value || Object.values(arrayOfConditions).includes(undefined));
    const meetsDefinedCondition =
      fieldValues.hasOwnProperty(key) && Object.values(arrayOfConditions).includes(fieldValue);
    const meetsCondition = meetsDefinedCondition || meetsUndefinedCondition;

    return meetsCondition;
  });
}

export async function sleepAsync(ms: number) {
  return new Promise((r) => setTimeout(r, ms));
}
