// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import FilterList from '@mui/icons-material/FilterList';
import { styled } from '@mui/system';
import { Circle as CircleIcon, Close as CloseIcon } from '@mui/icons-material';

const StyledButton = styled(Button)`
  :focus {
    outline: none;
  }
  && {
    text-transform: none;
    color: ${(props) => props.theme.palette.text.primary};
    border-color: ${(props) => props.theme.palette.secondary.dark};
    min-width: 100px;
    border-radius: 2em;
    min-height: 2em;
    margin-right: 12px;
    font-size: 16px;
    border-width: 2px;
  }
`;

const StyledCircleIcon = styled(CircleIcon)`
  margin-top: -15px;
  margin-left: -30px;
  margin-right: 15px;
}
`;

const StyledClose = styled('div')`
  cursor: pointer;
`;

const ROStyledMenu = (props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
);

interface Props {
  children: any;
  showAlert: boolean;
  title?: string;
  id?: string;
  startIcon?: any;
}

const ROSearchFilter = (props: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const id = props?.id || 'ROFilterMenu';
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const greenCircle = <StyledCircleIcon color="primary" />;

  return (
    <div>
      <StyledButton
        id={`${id}-button`}
        className="search-filter-button"
        aria-controls="customized-menu"
        aria-haspopup="true"
        data-testid={`${id}-button`}
        startIcon={props.startIcon ? props.startIcon : <FilterList />}
        onClick={handleClick}>
        {props?.title || 'Filter'}
      </StyledButton>
      <ROStyledMenu
        id={`${id}-menu`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="search-filter-menu"
        data-testid={`${id}-menu`}>
        <Box display="flex" flexDirection="row-reverse" mx={1}>
          <StyledClose data-testid={`${id}-close`} onClick={handleClose}>
            <CloseIcon color="primary" />
          </StyledClose>
        </Box>
        {props.children}
      </ROStyledMenu>
      {props.showAlert && greenCircle}
    </div>
  );
};

export default ROSearchFilter;
