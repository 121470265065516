import React from 'react';

export interface SelectedCheckboxProps {
  id?: string;
  className?: string;
  fill?: string;
}

const SelectedCheckbox = ({ id, className, fill }: SelectedCheckboxProps) => (
  <svg
    className={className}
    id={id}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect id="inner-rect" x="0.5" y="0.5" width="23" height="23" rx="3.5" fill={fill || 'none'} />
    <path
      d="M19.9871 8.00027L18.1071 6.10693L9.32039 14.8936L5.88039 11.4669L3.98706 13.3469L9.32039 18.6669L19.9871 8.00027Z"
      fill="white"
    />
    <rect id="outer-rect" x="0.5" y="0.5" width="23" height="23" rx="3.5" fill={fill || 'none'} />
  </svg>
);

export default SelectedCheckbox;
