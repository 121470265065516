// eslint-disable-next-line no-use-before-define
import React, { Fragment, useState } from 'react';
import { MenuListComponentProps } from 'react-select/src/components/Menu';
import { FilterListItem } from './FilterListItem';
import { LoadingSpinner } from 'shared-components/components';
import { SelectOptionType } from '../ROSelect/BaseSelect';
import { useTheme, styled } from '@mui/material';
import { Help as HelpIcon, FilterListOutlined as FilterListOutlinedIcon } from '@mui/icons-material';

const ValuesList = styled('ul')`
  overflow: auto;
  width: 100%;
  padding: 4px 0;
  border-left-style: solid;
  border-width: thin;
  border-color: lightgrey;
`;

const FilterIcon = styled(FilterListOutlinedIcon)`
  height: 20px;
  padding: 2px;
  margin-right: 6px;
`;

const FiltersPanelContainer = styled('div')`
  padding: 2px;
  min-width: 32%;
  max-width: 32%;
  overflow: auto;
  border-width: thin;
  border-color: lightgrey;
`;

const StyledListItem = styled('li')<{ isSelected: boolean }>`
  padding: 6px 16px;
  line-height: 20px;
  background-color: white;
  cursor: pointer;
  ${(props) =>
    `
      background-color: ${props.isSelected ? props.theme.palette.primary.light : 'white'};
      color: ${props.theme.palette.text.primary};
      &:hover {
        background-color: ${props.isSelected ? props.theme.palette.primary.light : props.theme.palette.secondary.light};
      }
    `};
`;

const FilterList = styled('ul')`
  overflow: auto;
`;

const FilterBox = styled('div')`
  display: flex;
  padding: 8px;
  border-bottom-style: solid;
  border-width: thin;
  border-color: lightgrey;
`;

const FilterBoxLabel = styled('div')`
  color: ${(props) => props.theme.palette.secondary.dark};
  padding-top: 4px;
`;

const NoMatchBox = styled('div')`
  align-self: center;
  width: 100%;
`;

const NoMatchLabel = styled('div')`
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoMatchIcon = styled(HelpIcon)`
  display: block;
  margin: auto;
  padding-bottom: 8px;
`;
interface GroupedOptionType extends SelectOptionType {
  options?: SelectOptionType[];
}

type ExtraProps = {
  isLoading?: boolean;
};

export type customProps = MenuListComponentProps<GroupedOptionType> & ExtraProps;

export const FilteredList = (props: customProps): JSX.Element => {
  const { children, isLoading, selectProps, options: groupedOptionsList } = props;
  const inputValue = props.selectProps.inputValue ?? '';
  const [selectedFilter, setSelectedFilter] = useState(getSelectedFilter());
  const theme = useTheme();

  function getSelectedFilter() {
    if (groupedOptionsList.length > 0) {
      const selectedFilter = groupedOptionsList.find((groupedOptions) =>
        groupedOptions.options?.some((option) => JSON.stringify(option) === JSON.stringify(selectProps.value)),
      );
      return selectedFilter?.value ?? selectProps?.defaultSelectedFilter ?? groupedOptionsList[0].value;
    }
    return undefined;
  }
  if (isLoading) {
    return <LoadingSpinner loadingText={'Loading...'} relativeSpinner={true} isSmall={true} />;
  }

  return inputValue.length === 0 && groupedOptionsList.length > 0 ? (
    <>
      <FiltersPanelContainer data-testid="filters-panel-container">
        <FilterBox>
          <FilterIcon htmlColor={theme.palette.grey[600]} />
          <FilterBoxLabel>Filters</FilterBoxLabel>
        </FilterBox>
        <FilterList>
          {groupedOptionsList.map((groupedOptions, index) => {
            return (
              <Fragment key={index}>
                <FilterListItem
                  option={groupedOptions}
                  isSelected={selectedFilter === groupedOptions.value}
                  onClick={() => setSelectedFilter(groupedOptions.value)}
                />
              </Fragment>
            );
          })}
        </FilterList>
      </FiltersPanelContainer>
      <ValuesList ref={props.innerRef} data-testid="values-list-container">
        {groupedOptionsList.map((groupedOptions) => {
          if (JSON.stringify(groupedOptions.value) === JSON.stringify(selectedFilter)) {
            return groupedOptions.options?.map((option: GroupedOptionType) => {
              return (
                <Fragment key={option.value}>
                  <StyledListItem
                    value={option.value}
                    isSelected={JSON.stringify(selectProps.value) === JSON.stringify(option)}
                    onClick={() => props.selectOption(option)}>
                    {option.label}
                  </StyledListItem>
                </Fragment>
              );
            });
          }
          return null;
        })}
      </ValuesList>
    </>
  ) : groupedOptionsList.length > 0 ? (
    <>{children}</>
  ) : (
    <NoMatchBox data-testid="no-match-box">
      <NoMatchIcon htmlColor={theme.palette.grey[600]} />
      <NoMatchLabel>No matches found</NoMatchLabel>
    </NoMatchBox>
  );
};
