// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import { styled } from '@mui/system';
import { MoreInfoText, PageTitle } from 'op-components';
import {
  ADD_MEDICATION,
  DELETE_MEDICATION,
  HA_MEDICATION_QUERY,
  UPDATE_HA_MEDICATION,
  UPDATE_MEDICATION,
} from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import { HAMedicationData, MedicationItem } from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import { isUs } from 'op-utils';
import { MEDICATION_RULES, MEDICATION_RULES_US } from 'op-utils/HealthAssessmentValidation/HealthAssessmentValidation';
import GCButton from 'shared-components/components/UIFormComponents/GCButton';
import { formatMyPhoneNumber } from 'op-utils/PatientHelper';
import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { FreeTextField, SectionField, SegmentedInput } from 'shared-components/components/FormFields';
import { MinusNoFill } from 'shared-components/images';
import { SegmentedInputBoolean } from 'shared-components/utils';
import validate from 'validate.js';
import { MEDICATION_FIELDS } from '../constants';
import { Modal, ModalBody, ModalHeader } from 'gc-ui';
import { useTheme } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
const initialViewedFields = ['pharmacyName', 'pharmacyPhone', 'medicationBool'];
const medicationFields = ['medicationName', 'dosage', 'route', 'prescribingPhysician'];

interface Props {
  validateOnLoad: boolean;
}

const getValidationRules = (viewedFields: Set<string>) => {
  const validationObject = {};
  const VALIDATION_RULES = isUs() ? MEDICATION_RULES_US : MEDICATION_RULES;
  viewedFields.forEach((field: string) => {
    if (field.includes('medications')) {
      //@ts-ignore
      validationObject[field] = VALIDATION_RULES[field.split('.')[2]];
    } else {
      //@ts-ignore
      validationObject[field] = VALIDATION_RULES[field];
    }
  });
  return validationObject;
};

const initalValidationFields = (length: number, staticFields: string[], infinteFields: string[]): string[] => {
  const validationFields = staticFields;
  for (let i = 0; i < length; i++) {
    infinteFields.forEach((field: string) => validationFields.push(`medications.${i}.${field}`));
  }
  return validationFields;
};

/*
  Runs validation on the current data against the currently viewed fields
*/
const runValidation = (data: HAMedicationData, viewedFields: Set<string>) => {
  const validationRules = getValidationRules(viewedFields);
  const options = { fullMessages: false };
  return validate(data, validationRules, options);
};

const HAMedication = (props: Props): JSX.Element => {
  const { validateOnLoad } = props;
  const theme = useTheme();
  const [viewedFields, setViewedFields] = useState(new Set<string>());
  const [validationObject, setValidationObject] = useState(Object);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const { patientId } = useParams<{ patientId: string }>();

  const { data, refetch, error } = useQuery(HA_MEDICATION_QUERY, {
    variables: { patientId },
    onCompleted: (data: { healthAssessment: HAMedicationData }) => {
      if (!validateOnLoad) return; // Only run the below if returning to the page
      const length = data?.healthAssessment?.medications?.length || 0;
      const newViewedFields = new Set(initalValidationFields(length, initialViewedFields, medicationFields));
      setViewedFields(newViewedFields);
      setValidationObject(runValidation(data?.healthAssessment, newViewedFields));
    },
  });
  const [updateHaMedication] = useMutation(UPDATE_HA_MEDICATION, {
    onCompleted: (data: { updateHaMedication: { healthAssessment: HAMedicationData } }) => {
      setValidationObject(runValidation(data?.updateHaMedication?.healthAssessment, viewedFields));
    },
  });
  const [addMedication, { loading: addMedicationLoading }] = useMutation(ADD_MEDICATION, {
    refetchQueries: ['HealthAssessment'],
  });
  const [deleteMedication, { loading: deleteMedicationLoading }] = useMutation(DELETE_MEDICATION, {
    refetchQueries: ['HealthAssessment'],
  });
  const [updateMedication] = useMutation(UPDATE_MEDICATION, {
    refetchQueries: ['HealthAssessment'],
    onCompleted: (data: { updateMedication: { healthAssessment: HAMedicationData } }) => {
      setValidationObject(runValidation(data?.updateMedication?.healthAssessment, viewedFields));
    },
  });

  if (error || !data?.healthAssessment) return <></>;

  const addToViewedFields = (field: string) => {
    const newViewedFields = [...viewedFields];
    newViewedFields.push(field);
    setViewedFields(new Set(newViewedFields));
  };

  const healthAssessment = data.healthAssessment;
  const haId = healthAssessment.id;
  const showMedications = healthAssessment.medicationBool === true;
  const showPharmacyFields = showMedications && isUs();

  const updateField = (field: string, value: string | boolean) => {
    // Special logic for validating phone number
    const newValue =
      field === 'pharmacyPhone' ? formatMyPhoneNumber(value as string, import.meta.env.REACT_APP_REGION) : value;
    updateHaMedication({ variables: { haId, patientId, [field]: newValue } });
    addToViewedFields(field);
  };

  const updateMedicationField = (id: string, field: string, value: string | boolean, index: number) => {
    // Special logic for validating phone number
    updateMedication({ variables: { id, patientId, [field]: value } }).catch(() => {
      setIsErrorModalOpen(true);
    });
    addToViewedFields(`medications.${index}.${field}`);
  };

  const refetchMedication = () => {
    setIsErrorModalOpen(false);
    refetch();
  };

  const StyledModalBody = styled('div')`
    text-align: center;
    margin-bottom: -10px;
  `;

  const renderMedications = () => {
    const removable = healthAssessment.medications.length > 1;
    return healthAssessment.medications.map((medication: MedicationItem, index: number) => {
      const inputName = `medications-${index}`;
      return (
        <div key={inputName} className="infinite-dropdown-item">
          <SectionField htmlFor={inputName} title={`${MEDICATION_FIELDS.MEDICATION_NAME.TITLE} ${index + 1}`}>
            <FreeTextField
              inputKey={medication.medicationName}
              inputName={`${inputName}-medicationName`}
              placeholder={'Please describe'}
              inputType="text"
              defaultValue={medication.medicationName}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                updateMedicationField(medication?.id, 'medicationName', e.target.value, index);
              }}
            />
          </SectionField>
          <SectionField htmlFor={inputName} title={MEDICATION_FIELDS.DOSAGE.TITLE}>
            <FreeTextField
              inputKey={medication.dosage}
              inputName={`${inputName}-dosage`}
              placeholder={'Please describe'}
              inputType="text"
              defaultValue={medication.dosage}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                updateMedicationField(medication?.id, 'dosage', e.target.value, index);
              }}
            />
            <MoreInfoText moreInfoText={MEDICATION_FIELDS.DOSAGE.HINT}></MoreInfoText>
          </SectionField>
          {isUs() && (
            <SectionField htmlFor={inputName} title={MEDICATION_FIELDS.ROUTE.TITLE}>
              <FreeTextField
                inputKey={medication.route}
                inputName={`${inputName}-route`}
                placeholder={'Please describe'}
                inputType="text"
                defaultValue={medication.route}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateMedicationField(medication?.id, 'route', e.target.value, index);
                }}
              />
              <MoreInfoText moreInfoText={MEDICATION_FIELDS.ROUTE.HINT}></MoreInfoText>
            </SectionField>
          )}
          {isUs() && (
            <SectionField htmlFor={inputName} title={MEDICATION_FIELDS.PHYSICIAN.TITLE}>
              <FreeTextField
                inputKey={medication.prescribingPhysician}
                inputName={`${inputName}-prescribingPhysician`}
                placeholder={'Please describe'}
                inputType="text"
                defaultValue={medication.prescribingPhysician}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateMedicationField(medication?.id, 'prescribingPhysician', e.target.value, index);
                }}
              />
            </SectionField>
          )}
          {removable && (
            <div className="remove-item">
              <div
                onClick={(): void => {
                  if (!deleteMedicationLoading)
                    deleteMedication({ variables: { id: medication.id, patientId } }).catch(() => {
                      setIsErrorModalOpen(true);
                    });
                }}>
                <MinusNoFill className="icon" />
                {'Remove'}
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <PageTitle title={'Medications'} idPrefix="ha" />
      <div id="ha-fields" className="ha-medical-history-cancer">
        <SectionField htmlFor={MEDICATION_FIELDS.MEDICATION.NAME} title={'Are you currently on any medications?'}>
          <MoreInfoText moreInfoText={MEDICATION_FIELDS.MEDICATION.HINT} showInfo={healthAssessment.medicationBool}>
            <SegmentedInput
              fieldName={MEDICATION_FIELDS.MEDICATION.NAME}
              options={SegmentedInputBoolean}
              optionAreBoolean={true}
              defaultSelected={healthAssessment.medicationBool}
              itemSelected={(selectedItem): void => {
                const selectedValue = selectedItem as boolean;
                updateField('medicationBool', selectedValue);
                // Add a medication item if Yes selected
                if (selectedValue && selectedValue !== healthAssessment?.medicationBool) {
                  addMedication({ variables: { haId, patientId } });
                }
              }}
            />
          </MoreInfoText>
        </SectionField>
        {showMedications && renderMedications()}
        {showMedications && (
          <div
            className="additional-item-button"
            onClick={(): void => {
              if (!addMedicationLoading) addMedication({ variables: { haId, patientId } });
            }}>
            <AddIcon className="icon" color="primary" />
            {'Add another medication'}
          </div>
        )}
        {showPharmacyFields && (
          <SectionField htmlFor={MEDICATION_FIELDS.PHARMACY_NAME.NAME} title={MEDICATION_FIELDS.PHARMACY_NAME.TITLE}>
            <FreeTextField
              inputKey={MEDICATION_FIELDS.PHARMACY_NAME.NAME}
              inputName={MEDICATION_FIELDS.PHARMACY_NAME.NAME}
              placeholder=""
              inputType="text"
              defaultValue={healthAssessment.pharmacyName}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                updateField('pharmacyName', e.target.value);
              }}
            />
          </SectionField>
        )}
        {showPharmacyFields && (
          <SectionField htmlFor={MEDICATION_FIELDS.PHARMACY_PHONE.NAME} title={MEDICATION_FIELDS.PHARMACY_PHONE.TITLE}>
            <FreeTextField
              inputKey={MEDICATION_FIELDS.PHARMACY_PHONE.NAME}
              inputName={MEDICATION_FIELDS.PHARMACY_PHONE.NAME}
              placeholder=""
              inputType="text"
              defaultValue={healthAssessment.pharmacyPhone}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                updateField('pharmacyPhone', e.target.value);
              }}
              errors={validationObject?.pharmacyPhone}
            />
          </SectionField>
        )}
      </div>
      <Modal open={isErrorModalOpen} className="modal-gc pending-submit-modal" width="small">
        <ModalHeader>Error updating Medication</ModalHeader>
        <ModalBody>
          <StyledModalBody>
            <p>This medication was deleted, and can no longer be updated.</p>
            <p>Please click OK to refresh.</p>
          </StyledModalBody>
          <div className="modal-submit-buttons">
            <GCButton
              onClick={() => refetchMedication()}
              buttonText="Ok"
              backgroundColor={theme.palette.primary.main}
              textColor={theme.palette.primary.contrastText}
              fontWeight="bold"
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default HAMedication;
