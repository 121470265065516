import React, { Fragment, useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { ROPatientCarePlanRoute } from '../Interface';

import ROPatientCarePathSidePanel from '../SidePanel/SidePanel';
import { CAREPLAN_PAGES } from '../Constants';
import ROPatientCarePlanPageFooter from '../Footer';
import { GET_CAREPLAN_SITE_GROUPS, GET_QUESTION, UPDATE_SPECIAL_PHYSICS_CONSULT, GET_CAREPLAN } from '../Queries';
import { ScrollToTop, getTreatmentSiteHeader } from '../Common';
import { LoadingSpinner } from 'shared-components/components';
import CareplanContainer from '../Container';
import { CareplanBanners } from '../Banner/Banner';
import { useErrorModalContext } from 'op-contexts';
import { CheckboxTile } from 'gc-ui';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import findLast from 'lodash/findLast';
import { ROTextField } from 'shared-components/components/FormFields';
import './SpecialPhysicsConsult.scss';

const preparePageTransition = () => {
  ScrollToTop(document);
};

const ROPatientSpecialPhysicsConsultPage = (): JSX.Element => {
  const history = useHistory();
  const match = useRouteMatch<ROPatientCarePlanRoute>();
  const { id: patientId, careplanId, siteGroupIdx } = match.params;
  const { setError } = useErrorModalContext();

  // Questions to render
  const [questions, setQuestions] = useState([]);

  // For Formik initialValues
  const [initialValues, setInitialValues] = useState({});

  // Fix for flickering - have to keep track that all data has loaded
  const [loading, setLoading] = useState(true);

  const { data: careplanStatusData, error: careplanStatusDataError } = useQuery(GET_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { id: careplanId },
  });

  const [getCareplanSiteGroups, { data: careplanData, error: careplanDataError, loading: careplanDataLoading }] =
    useLazyQuery(GET_CAREPLAN_SITE_GROUPS, {
      variables: { careplanId },
      onCompleted: (data: any) => buildInitialValuesFromExistingData(data),
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    setLoading(true);
    getCareplanSiteGroups();
  }, [siteGroupIdx]);

  const buildInitialValuesFromFormBuilder = (data: any) => {
    const tempInitialValues = {};
    //@ts-ignore
    data.forEach((question: any) => (tempInitialValues[question.fieldName] = false));
    setInitialValues(tempInitialValues);
    setLoading(false);
  };

  const [getQuestions, { data: formQuestionsError }] = useLazyQuery(GET_QUESTION, {
    variables: { type: 'special_physics_consult' },
    onCompleted: (data: any) => {
      const questions = JSON.parse(data.questionByType.values);
      setQuestions(questions);
      buildInitialValuesFromFormBuilder(questions);
    },
    fetchPolicy: 'no-cache',
  });

  const [updateSpecialPhysicsConsult] = useMutation(UPDATE_SPECIAL_PHYSICS_CONSULT, {
    refetchQueries: [{ query: GET_CAREPLAN, variables: { id: careplanId } }],
  });

  const buildValuesWithQuestions = (values: any) => {
    if (siteGroup && siteGroup.id) {
      const questionsCopy = [...questions];
      questionsCopy.forEach((currentValue: any, index, array: any) => {
        const fieldName = currentValue?.fieldName;
        // Adds value to the parent questions
        if (fieldName) {
          array[index]['value'] = values[fieldName];
        }
        // Adds value to the children(subQuestions)
        const subQuestions = array[index]['subQuestions'] || [];
        subQuestions.forEach((currentValue: any, index: number, array: any) => {
          const subFieldName = currentValue?.fieldName;
          if (subFieldName) {
            array[index]['value'] = values?.[`${fieldName}@@@${subFieldName}`];
          }
        });
      });

      updateSpecialPhysicsConsult({
        variables: {
          siteGroupId: siteGroup?.id,
          value: JSON.stringify(questionsCopy),
        },
      });
    }
  };

  useEffect(() => {
    if (careplanData || formQuestionsError || careplanStatusDataError) return setError();
  }, [careplanDataError, formQuestionsError, careplanStatusDataError]);

  let currentSiteGroupId: number = siteGroupIdx ? parseInt(siteGroupIdx) : 0;
  if (careplanData && siteGroupIdx === 'last') {
    currentSiteGroupId = careplanData.siteGroupsByCareplan.length - 1;
  }
  const siteGroup = careplanData && careplanData.siteGroupsByCareplan[currentSiteGroupId];

  const [siteNames, setSiteNames] = useState();

  useEffect(() => {
    if (!isEmpty(siteGroup)) {
      const siteNames =
        siteGroup && siteGroup.siteSet
          ? siteGroup.siteSet
              .map((site: any): string => {
                const location = site.sitevaluesSet.reduce(
                  (loc: string, value: any): string =>
                    value.field.name === 'Location' ? JSON.parse(value.value) : loc,
                  '',
                );
                const laterality = site.sitevaluesSet.reduce(
                  (loc: string, value: any): string =>
                    value.field.name === 'Laterality' ? JSON.parse(value.value) : loc,
                  '',
                );
                return getTreatmentSiteHeader({
                  treatmentSite: site.treatmentSite.treatmentSite,
                  location,
                  laterality,
                });
              })
              .join(', ')
          : '';
      setSiteNames(siteNames);
    }
  }, [siteGroup]);

  const buildInitialValuesFromExistingData = (data: any) => {
    // Get Special Physics Site Group Values
    const siteGroupValuesSet = data.siteGroupsByCareplan[currentSiteGroupId].sitegroupvaluesSet;
    const siteGroupValue = findLast(siteGroupValuesSet, function (object) {
      return object.field.name === 'Special_Physics_Consult';
    });

    if (siteGroupValue) {
      try {
        const existingQuestions = JSON.parse(JSON.parse(siteGroupValue.value));
        // Second condition is for when the array is empty
        if (existingQuestions && !isEmpty(existingQuestions)) {
          const initialValues = {};
          existingQuestions.forEach((question: any) => {
            // For parent questions
            //@ts-ignore
            initialValues[question.fieldName] = question.value;

            const subQuestions = question.subQuestions;

            subQuestions?.forEach((subQuestion: any) => {
              // Using @@@ to separate parent field name and child field name
              //@ts-ignore
              initialValues[`${question.fieldName}@@@${subQuestion.fieldName}`] = subQuestion.value;
            });
          });

          setInitialValues(initialValues);
          // Use version of questions saved with the site group values
          setQuestions(existingQuestions);
          setLoading(false);
          return;
        }
      } catch (e) {
        // When JSON parse fails
        getQuestions();
        return;
      }
    }
    getQuestions();
  };

  const onBack = (): void => {
    preparePageTransition();
    const index = currentSiteGroupId - 1;
    if (index === -1) {
      const presIndex = careplanData?.siteGroupsByCareplan?.length - 1 || 0;
      history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.VOLUMING}/${presIndex}`);
    } else {
      history.push(
        `/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SPECIAL_PHYSICS_CONSULT}/${index}`,
      );
    }
  };

  const onNext = (): void => {
    preparePageTransition();
    const isLastsite = currentSiteGroupId === careplanData?.siteGroupsByCareplan?.length - 1;
    if (isLastsite) {
      history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SUBMISSION}`);
    } else {
      const newsiteIdx = currentSiteGroupId + 1;
      history.push(
        `/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SPECIAL_PHYSICS_CONSULT}/${newsiteIdx}`,
      );
    }
  };

  return (
    <Fragment>
      <div className="main-container-parent-wrapper">
        <div className="main-container-wrapper">
          <CareplanBanners data={careplanStatusData} />
          {
            <CareplanContainer>
              {loading ? (
                <LoadingSpinner
                  loadingText={'Loading Special Physics Consult'}
                  subtitle={'Please wait while we set things up for you'}
                />
              ) : (
                <>
                  <div className="page-header">
                    <h1>Special Physics Consult: {siteNames}</h1>
                    <h2>{'Please select the following applicable options.'}</h2>
                  </div>

                  {!isEmpty(initialValues) && (
                    <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
                      {({ values, setFieldValue }) => {
                        return questions.map((question: any, index: number) => {
                          const clearSubQuestionsField = () => {
                            question?.subQuestions?.map((subQuestion: any) =>
                              setFieldValue(`${question.fieldName}@@@${subQuestion.fieldName}`, ''),
                            );
                          };

                          // Doesn't update Formik's values - used to save to db with updated values
                          const copyAndUpdateValues = (fieldName: any, value: any) => {
                            const valuesCopy = { ...values };
                            //@ts-ignore
                            valuesCopy[fieldName] = value;
                            return valuesCopy;
                          };

                          const onChangeCheckbox = (value: boolean) => {
                            setFieldValue(question.fieldName, value);
                            buildValuesWithQuestions(copyAndUpdateValues(question.fieldName, value));
                            // Checkbox is unselected
                            if (!value) {
                              clearSubQuestionsField();
                            }
                          };

                          return (
                            <React.Fragment key={`question-${index}`}>
                              <div>
                                {/* Just pass the value and the setter */}
                                <CheckboxTile
                                  id={`checkbox-${index}`}
                                  data-cy={`special-physics-tile-${index}`}
                                  name={question.fieldName}
                                  //@ts-ignore
                                  checked={values[question.fieldName]}
                                  label={question.label}
                                  onChange={onChangeCheckbox}
                                />
                              </div>
                              <div className={'subquestions-container'}>
                                {
                                  //@ts-ignore
                                  values[question.fieldName] &&
                                    question.subQuestions?.map((subQuestion: any, textboxIndex: number) => {
                                      return (
                                        <div
                                          key={`question-${index}-subquestion-${textboxIndex}`}
                                          className={`subquestion-text-area-container${
                                            textboxIndex === question.subQuestions.length - 1 ? '-last' : ''
                                          }`}
                                          style={{
                                            paddingBottom:
                                              textboxIndex === question.subQuestions.length - 1
                                                ? '0px!important'
                                                : undefined,
                                          }}>
                                          <ROTextField
                                            className={'special-physics-consult-text-area'}
                                            data-test-id={`special-physics-tile-${index}-textbox-${textboxIndex}`}
                                            id={`${question.fieldName}-${subQuestion.fieldName}`}
                                            fieldlabel={subQuestion.label}
                                            // @ts-ignore
                                            value={values[`${question.fieldName}@@@${subQuestion.fieldName}`]}
                                            onBlur={(event: React.FocusEvent<HTMLInputElement>): void => {
                                              const value = event.target.value;
                                              const fieldName = `${question.fieldName}@@@${subQuestion.fieldName}`;
                                              setFieldValue(fieldName, value);
                                              buildValuesWithQuestions(copyAndUpdateValues(fieldName, value));
                                            }}
                                          />
                                        </div>
                                      );
                                    })
                                }
                              </div>
                            </React.Fragment>
                          );
                        });
                      }}
                    </Formik>
                  )}
                </>
              )}
            </CareplanContainer>
          }
          <ROPatientCarePlanPageFooter
            onBack={() => onBack()}
            onNext={() => onNext()}
            nextDisabled={careplanDataLoading}
            backDisabled={careplanDataLoading}
          />
        </div>
        <ROPatientCarePathSidePanel />
      </div>
    </Fragment>
  );
};

export default ROPatientSpecialPhysicsConsultPage;
