import { styled } from '@mui/system';
import { DiseaseType, MorphologyType, TnmStageType, DiagnosisType, ConvertedDiagnosisType } from '../Interface';
import { FilteredListOption } from 'shared-components/components/FormFields/ROFilteredDropdown/FilteredListDropdown';
import { useTheme } from '@mui/material';
import { SelectOptionType } from 'shared-components/components/FormFields';

// CONSTANTS
export const VALUE_REQUIRED = 'This field is required';
export const LATERALITY_PLACEHOLDER = 'Please choose';

// STYLED COMPONENTS
export const HeaderTitle = styled('div')`
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
`;

export const SubTitle = styled('div')`
  font-size: 14px;
  line-height: 20px;
`;

export const SectionTitle = styled('div')`
  font-size: 18px;
  line-height: 28px;
  margin-top: 32px;
`;

export const convertMorphologyValuesToOptions = (options: MorphologyType[]): SelectOptionType[] => {
  const selectOptions: SelectOptionType[] = options.map(
    (option: any): SelectOptionType => ({
      label: option.displayName,
      value: option.displayName,
    }),
  );
  return selectOptions;
};

export const generateSelectStyle = (error: boolean, warning?: boolean): any => {
  const theme = useTheme();
  return {
    dropdownIndicator: () => ({ color: theme.palette.primary.dark, marginRight: '8px' }),
    indicatorSeparator: () => ({ display: 'none' }),
    control: (base: any) => ({
      ...base,
      border: error
        ? `1px solid ${theme.palette.error.main}`
        : warning
        ? `1px solid ${theme.palette.warning.dark}`
        : `1px solid ${theme.palette.secondary.main}`,
      boxShadow: 'none',
      height: '40px',
      '&:hover': { border: `1px solid ${theme.palette.secondary.main}` },
    }),
    valueContainer: (base: any) => ({
      ...base,
      lineHeight: '32px',
      padding: '0px 8px',
    }),
  };
};

const convertTNM = (tnmStage: TnmStageType | null | undefined): FilteredListOption | undefined => {
  if (tnmStage && tnmStage?.mainCode && tnmStage?.subCode && tnmStage?.description) {
    return {
      value: `${tnmStage.mainCode[0]}${tnmStage.subCode}`,
      label: `${tnmStage.mainCode[0]}${tnmStage.subCode} - ${tnmStage?.description}`,
      category:
        (tnmStage.mainCode[0] === 'p' && 'Pathological') || (tnmStage.mainCode[0] === 'c' && 'Clinical') || undefined,
    };
  }
  return undefined;
};

export const getTNMSubCode = (tnmStage: FilteredListOption | undefined): string | undefined => {
  if (tnmStage && tnmStage?.value?.length > 1) {
    return tnmStage.value.substring(1);
  }
  return undefined;
};

export const getTNMDescription = (tnmStage: FilteredListOption | undefined): string | undefined => {
  if (tnmStage && tnmStage?.label) {
    return tnmStage.label.split('- ')[1];
  }
  return undefined;
};

export const convertDisease = (disease: DiseaseType | undefined): FilteredListOption | undefined => {
  if (disease && disease.diseaseId && disease.title) {
    return {
      value: disease.diseaseId,
      label: disease.title,
      category: disease.chapter,
    };
  }
  return undefined;
};

const convertToOptionType = (displayName: string | undefined): SelectOptionType | undefined => {
  if (displayName) {
    return {
      value: displayName,
      label: displayName,
    };
  }
  return undefined;
};

export const calculateStageType = (diagnosis: ConvertedDiagnosisType): string => {
  let stageType = '';

  if (diagnosis.stageTCodePathological || diagnosis.stageNCodePathological || diagnosis.stageMCodePathological)
    stageType = 'pathological';

  if (diagnosis.stageTCodeClinical || diagnosis.stageNCodeClinical || diagnosis.stageMCodeClinical)
    stageType = stageType ? 'both' : 'clinical';

  return stageType || 'pathological'; // If no TNM values are selected default to pathological
};

export const convertTypeForStagingPage = (
  diagnosis: DiagnosisType | null | undefined,
): ConvertedDiagnosisType | undefined => {
  if (!diagnosis) return undefined;
  return {
    ...diagnosis,
    disease: convertDisease(diagnosis?.disease),
    laterality: convertToOptionType(diagnosis?.laterality),
    morphology: convertToOptionType(diagnosis?.morphology?.displayName),
    stageTCodeClinical: convertTNM(diagnosis?.stageTCodeClinical),
    stageNCodeClinical: convertTNM(diagnosis?.stageNCodeClinical),
    stageMCodeClinical: convertTNM(diagnosis?.stageMCodeClinical),
    stageTCodePathological: convertTNM(diagnosis?.stageTCodePathological),
    stageNCodePathological: convertTNM(diagnosis?.stageNCodePathological),
    stageMCodePathological: convertTNM(diagnosis?.stageMCodePathological),
  };
};

export const fieldsToClearMap = {
  disease: [
    'morphology',
    'stageTCodeClinical',
    'stageTCodePathological',
    'stageNCodeClinical',
    'stageNCodePathological',
    'stageMCodeClinical',
    'stageMCodePathological',
  ],
  stageTCodeClinical: ['stageTCodePathological'],
  stageTCodePathological: ['stageTCodeClinical'],
  stageNCodeClinical: ['stageNCodePathological'],
  stageNCodePathological: ['stageNCodeClinical'],
  stageMCodeClinical: ['stageMCodePathological'],
  stageMCodePathological: ['stageMCodeClinical'],
};

export const getMorphologyAndTNMString = (diagnosis: DiagnosisType): string => {
  const morphology = diagnosis.morphology?.displayName.split(' ')[0] || '-';
  let tnmPathological = '';
  if (diagnosis.stageTCodePathological) tnmPathological += ' ' + diagnosis.stageTCodePathological.subCode;
  if (diagnosis.stageNCodePathological) tnmPathological += ' ' + diagnosis.stageNCodePathological.subCode;
  if (diagnosis.stageMCodePathological) tnmPathological += ' ' + diagnosis.stageMCodePathological.subCode;
  let tnmClinical = '';
  if (diagnosis.stageTCodeClinical) tnmClinical += ' ' + diagnosis.stageTCodeClinical.subCode;
  if (diagnosis.stageNCodeClinical) tnmClinical += ' ' + diagnosis.stageNCodeClinical.subCode;
  if (diagnosis.stageMCodeClinical) tnmClinical += ' ' + diagnosis.stageMCodeClinical.subCode;
  const tnmPathologicalString = tnmPathological ? `(P): ${tnmPathological}` : '';
  const tnmClinicalString = tnmClinical ? `(C): ${tnmClinical}` : '';
  if (morphology === '-' && !tnmPathological && !tnmClinical) return '';
  return `${morphology} | ${tnmPathologicalString} ${tnmClinicalString}`;
};
