// eslint-disable-next-line no-use-before-define
import { gql } from '@apollo/client';
import { Mutation, Query } from '@apollo/client/react/components';
import moment from 'moment-timezone';
import React, { Component, Fragment } from 'react';

import { Link, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import './ChangePassword.scss';

import { ButtonType } from 'shared-components/enums';
import { MutationType } from 'shared-components/interfaces';
import { Logger } from 'shared-components/utils';

import { DEFAULT_DASHBOARD } from 'op-px-pages/Login/Login';
import { LoadingSpinner } from 'shared-components/components';
import {
  Form,
  FormContent,
  FormSection,
  FreeTextField,
  GCButton,
  SectionField,
} from 'shared-components/components/FormComponents';
import { Checkbox } from 'shared-components/components/FormFields';

import classNames from 'classnames';
import AuthLayout from 'op-pages/PX/Components/Layouts/AuthLayout';
import ChangePasswordFormViewModel from './ChangePasswordFormViewModel';

const logger = new Logger('ChangePassword');

interface UserQuery {
  user: {
    email: string;
    userProfile: {
      usernameEntered: string;
      forcedReset: boolean;
      patient: {
        termsConditionsAcceptedDate: string;
      };
    };
  };
}

interface ChangePasswordMutationType extends MutationType {
  data?: {
    changePassword?: {
      user?: {
        username?: string;
      };
      errors?: [
        {
          error: string;
          details: string;
        },
      ];
    };
  };
}

interface Props extends RouteComponentProps<{}, any, Location | any> {
  user?: {
    username: string;
    initialPasswordAlreadySet: boolean;
  };
}

interface ChangePasswordFunction {
  variables: {
    currentPassword?: string;
    newPassword?: string;
    confirmedPassword?: string;
    acceptTermsConditions?: boolean;
    resetToken?: string;
  };
}

const PAGE_TITLE = 'Change password';
const FORM_TITLE = 'Set your password';
const FORM_DESCRIPTION = 'Set a secure password that is:';
const EMAIL_FORM_LABEL = 'Email';
const OLD_PASSWORD_FORM_LABEL = 'Old password';
const NEW_PASSWORD_FORM_LABEL = 'New password';
const CONFIRM_PASSWORD_FORM_LABEL = 'Confirm new password';
const SET_PASSWORD_BUTTON_TEXT = 'Set password';
const CHANGE_PASSWORD_BUTTON_TEXT = 'Change password';

const MIN_PASSWORD_LENGTH = 8;

const TNC_CHECKBOX = {
  NAME: 'tncCheckbox',
  LABEL: {
    PLAIN_TEXT: 'I agree to the',
    LINK_TEXT: 'Terms & Conditions',
  },
};

const TNC_HELPER_TEXT = 'You must agree to the Terms & Conditions to continue';

// Graph QL queries
export const USER_QUERY = gql`
  {
    user {
      id
      email
      userProfile {
        id
        usernameEntered
        forcedReset
        patient {
          id
          termsConditionsAcceptedDate
        }
      }
    }
  }
`;

//prettier-ignore
export const PASSWORD_MUTATION = gql`
  mutation ChangePassword($currentPassword: String, $newPassword: String!, $confirmedPassword: String!, $acceptTermsConditions: Boolean, $resetToken: String) {
    changePassword(
      currentPassword: $currentPassword,
      newPassword: $newPassword,
      confirmedPassword: $confirmedPassword,
      resetToken: $resetToken,
      acceptTermsConditions: $acceptTermsConditions
    ) {
      errors {
        error
        details
      }
    }
  }
`;

interface RawProps extends RouteComponentProps<{}, any, Location | any> {
  username: string;
  forcedReset: boolean;
  forgotPassword: boolean;
  termsConditionsAcceptedDate: string | null;
}

interface State {
  formError: FormError;
  // Checkbox component expects a controlled input
  tncCheckbox: boolean;
}

interface FormError {
  showNewPasswordError: boolean;
  oldPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
  tncCheckbox?: string;
  resetToken?: boolean;
}

class ChangePassword extends Component<Props> {
  public render(): JSX.Element {
    let resetPassword = false;
    const { location } = this.props;

    if (location && location.state && location.state.resetPassword) {
      resetPassword = location.state.resetPassword;
    }

    return (
      <Query<UserQuery> query={USER_QUERY} fetchPolicy="network-only">
        {({ loading, error, data }): JSX.Element => {
          if (loading) return <LoadingSpinner />;
          if (error || !data) return <div>Error!!!!</div>;

          const { email } = data.user;
          const { usernameEntered, forcedReset } = data.user.userProfile;
          const { termsConditionsAcceptedDate } = data.user.userProfile.patient;
          return (
            <ChangePasswordInner
              {...this.props}
              username={usernameEntered || email}
              forcedReset={forcedReset}
              forgotPassword={resetPassword}
              termsConditionsAcceptedDate={termsConditionsAcceptedDate}
            />
          );
        }}
      </Query>
    );
  }
}

class ChangePasswordInner extends Component<RawProps, State> {
  private firstLogin = false;
  private forgotPassword = false;
  private changePasswordFormViewModel: ChangePasswordFormViewModel;

  public constructor(props: RawProps) {
    super(props);
    this.changePasswordFormViewModel = new ChangePasswordFormViewModel();
    this.firstLogin = this.props.forcedReset;
    this.forgotPassword = this.props.forgotPassword;

    this.state = {
      formError: {
        showNewPasswordError: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      tncCheckbox: false,
    };
  }

  private updateErrorState = (showNewPasswordError: boolean): void => {
    this.setState({
      formError: {
        showNewPasswordError,
        oldPassword: this.changePasswordFormViewModel.errors.oldPassword,
        newPassword: this.changePasswordFormViewModel.errors.newPassword,
        confirmPassword: this.changePasswordFormViewModel.errors.confirmPassword,
        tncCheckbox: this.changePasswordFormViewModel.errors.tncCheckbox,
      },
    });
  };

  private getPageContainerStyles = (): string => {
    const baseStyle = 'auth-container';
    if (this.firstLogin || this.forgotPassword) {
      return baseStyle;
    }
    return baseStyle + ' manual-password-change';
  };

  private navigateBack = (): void => {
    this.props.history.replace(this.props.location.state.from);
  };

  private renderFormHeading = (): JSX.Element => {
    let _FORM_TITLE = FORM_TITLE;
    if (!this.firstLogin) {
      _FORM_TITLE = PAGE_TITLE;
    }

    return (
      <div
        className={classNames('title-container', {
          hidden: !this.firstLogin,
        })}>
        <div
          className={classNames('back-arrow', {
            hidden: this.firstLogin || this.forgotPassword,
          })}
          onClick={this.navigateBack}
        />
        <div
          className={classNames('title', {
            titleGreen: !this.firstLogin && !this.forgotPassword,
          })}>
          {_FORM_TITLE}
        </div>
      </div>
    );
  };

  private renderOldPasswordTextField = (errors: FormError): JSX.Element => {
    const invalidInput = !!errors.oldPassword;
    if (this.firstLogin || this.forgotPassword) {
      return <React.Fragment />;
    }
    return (
      <SectionField htmlFor="old-password" title={OLD_PASSWORD_FORM_LABEL} invalidInput={invalidInput}>
        <FreeTextField
          fieldName="old-password"
          validateField={this.changePasswordFormViewModel.validateField}
          secure={true}
          showErrorMessage={true}
          invalidInput={invalidInput}
          errorMessage={errors.oldPassword}
          updateParent={(): void => {
            this.updateErrorState(false);
          }}
        />
      </SectionField>
    );
  };

  private getSubmitButtonText = (): string => {
    if (this.firstLogin) {
      return SET_PASSWORD_BUTTON_TEXT;
    }
    return CHANGE_PASSWORD_BUTTON_TEXT;
  };

  private renderContent = (
    changePasswordFunction: (_: ChangePasswordFunction) => void,
    serverError?: FormError,
    username?: string,
  ): JSX.Element => {
    const { location } = this.props;

    let errors = this.state.formError;
    if (serverError) {
      if (serverError.resetToken) {
        return (
          <Redirect
            to={{
              pathname: '/forgotPassword',
              state: {
                expiredLink: true,
              },
            }}
          />
        );
      }
      errors = serverError;
    }

    let resetToken: string | undefined;
    if (location && location.state && location.state.resetToken) {
      resetToken = location.state.resetToken;
    }

    return (
      <AuthLayout
        showHeader={!this.firstLogin && typeof resetToken === 'undefined'}
        showNavigation={!this.firstLogin && typeof resetToken === 'undefined'}
        showBottomNav={!this.firstLogin && typeof resetToken === 'undefined'}
        clickLogoFn={(): void => this.props.history.replace(this.props.location.state.from)}
        titleOverride={!this.firstLogin && !this.forgotPassword ? 'Change password' : 'Set your password'}>
        <div className={this.getPageContainerStyles()}>
          <div className="auth-container-inner">
            <Form id="patient-change-password-form" formData={this.changePasswordFormViewModel.changePasswordFormModel}>
              <FormContent>
                {this.renderFormHeading()}
                <FormSection>
                  <div className="description">{FORM_DESCRIPTION}</div>
                  <ul className="password-list">
                    <li>8 or more characters in length</li>
                    <li>Unique to you and hard to guess</li>
                    <li>
                      <u>Not</u> a simple word or phrase
                    </li>
                  </ul>
                  <SectionField htmlFor="username" title={EMAIL_FORM_LABEL} invalidInput={false}>
                    <FreeTextField
                      fieldName="username"
                      modelValue={username ? username : 'ERROR: No username'}
                      disabled={true}
                    />
                  </SectionField>
                  {this.renderOldPasswordTextField(errors)}
                  <SectionField
                    htmlFor="new-password"
                    title={NEW_PASSWORD_FORM_LABEL}
                    invalidInput={!!errors.newPassword}>
                    <FreeTextField
                      fieldName="new-password"
                      validateField={this.changePasswordFormViewModel.validateField}
                      secure={true}
                      showErrorMessage={true}
                      invalidInput={!!errors.newPassword}
                      errorMessage={errors.newPassword}
                      updateParent={(): void => {
                        this.updateErrorState(false);
                      }}
                    />
                  </SectionField>
                  <SectionField
                    htmlFor="confirm-password"
                    title={CONFIRM_PASSWORD_FORM_LABEL}
                    invalidInput={!!errors.confirmPassword}>
                    <FreeTextField
                      fieldName="confirm-password"
                      validateField={this.changePasswordFormViewModel.validateField}
                      secure={true}
                      showErrorMessage={true}
                      invalidInput={!!errors.confirmPassword}
                      errorMessage={errors.confirmPassword}
                      updateParent={(): void => {
                        this.updateErrorState(false);
                      }}
                      validateOnKeyUp={false}
                    />
                  </SectionField>
                </FormSection>
                {this.renderTermsAndConditionsCheckbox(errors)}
                <FormSection>
                  <div className="flex-horizontal-center">
                    <GCButton
                      onClick={(): void => {
                        const model = this.changePasswordFormViewModel.changePasswordFormModel;
                        this.onSubmitHandler((): void => {
                          changePasswordFunction({
                            variables: {
                              currentPassword: model.formData.oldPassword,
                              newPassword: model.formData.newPassword,
                              confirmedPassword: model.formData.confirmPassword,
                              acceptTermsConditions: this.state.tncCheckbox,
                              resetToken: resetToken,
                            },
                          });
                        });
                      }}
                      type={ButtonType.GREEN}
                      title={this.getSubmitButtonText()}
                      name="submit-button"
                    />
                  </div>
                  {this.forgotPassword && (
                    <div className="flex-horizontal-center">
                      <Link className="link" to="/" replace>
                        Cancel
                      </Link>
                    </div>
                  )}
                  {!this.changePasswordFormViewModel.errors.tncCheckbox && this.userToAcceptTermsAndConditions() && (
                    <div className="flex-horizontal-center tnc-helper-text">*{TNC_HELPER_TEXT}</div>
                  )}
                </FormSection>
              </FormContent>
            </Form>
          </div>
        </div>
      </AuthLayout>
    );
  };

  private renderTermsAndConditionsCheckbox = (errors: FormError): JSX.Element => {
    // User has already accepted the terms and conditions today or in the past
    if (!this.userToAcceptTermsAndConditions()) {
      return <Fragment />;
    }

    // User has to accept T&C
    return (
      <FormSection>
        <Checkbox
          inputName={TNC_CHECKBOX.NAME}
          inputLabel={this.renderTncLabel()}
          isChecked={this.state.tncCheckbox}
          onChange={(isChecked: boolean): void => {
            this.changePasswordFormViewModel.errors.tncCheckbox = undefined;
            errors.tncCheckbox = undefined;

            this.setState({
              tncCheckbox: isChecked,
            });
          }}
          errors={errors.tncCheckbox ? [errors.tncCheckbox] : undefined}
        />
      </FormSection>
    );
  };

  /**
   * Returns a boolean indicating if the user has to accept the terms and conditions
   */
  private userToAcceptTermsAndConditions = (): boolean => {
    const { termsConditionsAcceptedDate } = this.props;
    const utcDate = moment.utc(`${termsConditionsAcceptedDate}`);
    const termsConditionsAcceptedDateLocalDate = utcDate.local();
    const tomorrow = moment().add(1, 'days');
    // User has already accepted the terms and conditions today or in the past
    if (termsConditionsAcceptedDate != null && termsConditionsAcceptedDateLocalDate.isBefore(tomorrow, 'day')) {
      return false;
    }
    return true;
  };

  private onSubmitHandler = (successHandler: () => void): void => {
    logger.debug('onSubmitHandler', 'Calling the on submit handler');
    const { tncCheckbox } = this.state;
    // Stores if the user has to accept the terms and conditions
    let acceptTermsConditions = true;
    if (this.userToAcceptTermsAndConditions()) {
      if (!tncCheckbox) {
        acceptTermsConditions = false;
      }
    }

    if (
      this.changePasswordFormViewModel.validatePasswordsOnFormSubmission(this.firstLogin || this.forgotPassword) &&
      acceptTermsConditions
    ) {
      successHandler();
    } else {
      const model = this.changePasswordFormViewModel.changePasswordFormModel.formData;
      let showNewPasswordError = false;

      if (model.oldPassword.length < 1) {
        this.changePasswordFormViewModel.errors.oldPassword = this.changePasswordFormViewModel.ERROR_PASSWORD_INVALID;
      }

      if (model.newPassword.length < MIN_PASSWORD_LENGTH) {
        this.changePasswordFormViewModel.errors.newPassword = this.changePasswordFormViewModel.ERROR_PASSWORD_TOO_SHORT;
      }

      if (model.confirmPassword.length < MIN_PASSWORD_LENGTH) {
        this.changePasswordFormViewModel.errors.confirmPassword =
          this.changePasswordFormViewModel.ERROR_PASSWORD_TOO_SHORT;
      }

      if (model.newPassword !== model.confirmPassword) {
        this.changePasswordFormViewModel.errors.confirmPassword =
          this.changePasswordFormViewModel.ERROR_PASSWORDS_ENTERED_DO_NOT_MATCH;
        showNewPasswordError = true;
      }

      if (!tncCheckbox) {
        this.changePasswordFormViewModel.errors.tncCheckbox = this.changePasswordFormViewModel.TNC_ERROR;
      }

      this.updateErrorState(showNewPasswordError);
    }
  };

  private mutationRender = (username?: string): JSX.Element => {
    return (
      <Mutation mutation={PASSWORD_MUTATION}>
        {(
          changePassword: (_: ChangePasswordFunction) => void,
          changePasswordResult: ChangePasswordMutationType,
        ): JSX.Element => {
          const { loading: cpLoading, errors: cpErrors, data: cpData } = changePasswordResult;
          if (cpLoading) return <LoadingSpinner />;

          // TODO: Add error page if graphql fails
          if (!cpData && cpErrors) return <div>Error with graphql</div>;

          let serverError = undefined;
          if (cpData && cpData.changePassword) {
            const changePassword = cpData.changePassword;
            if (changePassword.errors) {
              this.changePasswordFormViewModel.createErrorArray(changePassword.errors);
              const errorModel = this.changePasswordFormViewModel.errors;
              serverError = {
                showNewPasswordError: false,
                ...errorModel,
              };
            } else {
              return this.goToNext();
            }
          }

          return this.renderContent(changePassword, serverError, username);
        }}
      </Mutation>
    );
  };

  private goToNext = (): JSX.Element => {
    const location = this.props.location;
    const redirectionParams = {
      pathname: DEFAULT_DASHBOARD,
      state: {
        bannerVisible: true,
        bannerMessage: 'Your password has been successfully updated',
        forgotPassword: this.props.forgotPassword,
      },
    };
    if (location && location.state && location.state.next) {
      const nextAddress = location.state.next;

      if (nextAddress.indexOf('/server/') !== -1) {
        window.location.replace(nextAddress);
        return <div />;
      }

      redirectionParams.pathname = location.state.next;

      return <Redirect from="/" to={redirectionParams} />;
    }

    // Redirect the window location to appointments
    return <Redirect from="/" to={redirectionParams} />;
  };

  public render(): JSX.Element {
    return this.mutationRender(this.props.username);
  }

  private renderTncLabel = (): JSX.Element => {
    return (
      <Fragment>
        <p className="terms-conditions-text">
          {TNC_CHECKBOX.LABEL.PLAIN_TEXT}{' '}
          <Link to="termsAndConditions" target="_blank" className="terms-conditions-link">
            {TNC_CHECKBOX.LABEL.LINK_TEXT}
          </Link>
          {this.changePasswordFormViewModel.errors.tncCheckbox ? '' : '*'}
        </p>
      </Fragment>
    );
  };
}

const wrappedComponent = withRouter(ChangePassword);
export default wrappedComponent;
