// eslint-disable-next-line no-use-before-define
import React from 'react';
import { styled } from '@mui/system';
import { ComposedModal } from 'gc-ui';

interface RCRModalProps {
  modalStatus: boolean;
  setModalStatus: any;
}

const ModalBodyContainer = styled('div')`
   {
    p {
      margin: 8px 0;
    }
    h2 {
      color: ${(props) => props.theme.palette.primary.main};
      margin: 8px 0;
    }
    ul {
      margin-left: 0;
      list-style: none;
      li::before {
        margin-left: 0;
        content: '\\2022';
        width: 1em;
        font-size: 32px;
        font-weight: bold;
        vertical-align: middle;
        color: ${(props) => props.theme.palette.primary.main};
      }
      li {
        margin-bottom: 8px;
      }
    }
  }
`;

const RCRModal = (props: RCRModalProps): JSX.Element => {
  const { modalStatus, setModalStatus } = props;

  const ModalBody = () => (
    <ModalBodyContainer>
      <h2>Category 1: Radical intent with 5 fractions/week</h2>
      <p>
        Where possible, there is to be no compromise to treatment scheduling, as prolongation of treatment affects
        outcomes.
      </p>
      <p>
        Treatment duration must not be protracted, there is to be no increase in overall number of days to deliver the
        radiotherapy prescriptions such that the end date remains the same. Where this is not possible for significant
        patient and/or operational reasons, end date is to be within 2 days of the original prescription date.
      </p>
      <p>
        Category 1 patients includes those with disease histology of SCC or with a known or probable harmful effect of
        treatment interruption:
      </p>
      <p>
        <ul>
          <li>SCC H&N</li>
          <li>SCC Anus</li>
          <li>Oesophagus (SCC and Adenocarcinoma)</li>
          <li>Lung cancer (SCLC and NSCLC)</li>
          <li>Gynaecological SCC (cervix, vagina, vulva, etc.)</li>
          <li>Cutaneous SCC (excluding wide field skin protocols)</li>
          <li>Meduloblastoma/PNET</li>
          <li>Inflammatory breast cancer</li>
          <li>Other tumours with short doubling time</li>
        </ul>
      </p>
      <p>
        Missed fraction(s) for Category 1 patients are to be made up by accelerating the remaining schedule or treating
        BID on another day(s), or working on an additional day in lieu of a public holiday (e.g. Easter, Christmas)
      </p>
      <br />
      <h2>Category 2: Radical intent (other)</h2>
      <p>
        There are reports that prolongation may not be deleterious, but no safe minimum has been established. Minimal
        compromise to treatment scheduling and treatment duration recommended – in these instances, overall treatment
        timeframe recommended not to be prolonged by more than 2 days acknowledging there will be more variability in
        Category 2 than Category 1 patients. This includes:
      </p>
      <p>
        <ul>
          <li>All other radical intent tumours not listed in Category 1</li>
        </ul>
      </p>
      <br />
      <h2>Category 3: Palliative intent or benign treatment</h2>
      <p>
        Patients being treated with palliative intent or benign treatment. Overall time is less critical in achieving
        the desired outcome.
        <br />
        In these instances:
      </p>
      <p>
        <ul>
          <li>Prolonged interruptions {'>'} 7 days may require compensation</li>
        </ul>
      </p>
    </ModalBodyContainer>
  );

  return (
    <ComposedModal
      isOpen={modalStatus}
      dismissFunction={() => {
        setModalStatus(false);
      }}
      width={'800px'}
      headerText={'RCR Treatment Delay Categories of Priority'}
      primaryRightButton={{
        buttonText: 'Close',
        className: 'primary-button',
        onClick: () => setModalStatus(false),
      }}>
      <ModalBody />
    </ComposedModal>
  );
};

export default RCRModal;
