export const HEADING = 'Document Upload';
export const DRAG_AND_DROP = 'Drag and drop a file here or browse for a file to upload.';

export const MAX_FILE_SIZE = 104857600; //100 MB
export const DELETE_FILE = 'Delete';
export const DELETE_DOCUMENT = {
  HEADER: 'Delete document',
  DESCRIPTION: "Once deleted this document can't be recovered.",
  DISCARD: 'Delete',
};
export const UPLOAD_ERRORS = {
  'file-invalid-type': 'File type is not accepted',
  'file-too-large': 'File is too large (maximum supported file size is 100 MB)',
  other: 'Upload failed. Please delete and try again',
};
export const MODAL = {
  HEADER: 'Proceed with submission',
  DESCRIPTION:
    "Please ensure details are correct before proceeding. On submission, uploaded documents will be visible in the patient's chart in MQ and Horizon",
  SUBMIT_BUTTON: 'Submit',
};

export const CUT_OFF_POINT = 1753;
export const LEAVE_PAGE_WARNING =
  'Documents have NOT been submitted. Are you sure you want to leave page without submitting?';
export const ACKNOWLEDGMENT = {
  HEADING: 'Acknowledgment',
  SUB_HEADING: '(Please tick after uploading any additional attachments)',
  INPUT_LABEL: 'I have reviewed and acknowledge the above document labels are correct',
};

export const FILE_TYPES_MIME_LIST = [
  'image/jpg',
  'image/jpeg',
  'application/pdf',
  'image/bmp',
  'image/png',
  'image/gif',
];

export const FILE_TYPES_MIME_TO_FILE_TYPES = {
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'application/pdf': ['.pdf'],
  'image/bmp': ['.bmp'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
};
