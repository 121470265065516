// eslint-disable-next-line no-use-before-define
import React from 'react';
import { styled } from '@mui/system';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../Modal';
import Button from '../Button';
import { ComposedModalProps } from './types';
import { Divider, Typography } from '@mui/material';

const HeaderIconStyled = styled('span')`
  &&& {
    margin-left: 8px;
  }
`;

/**
 * @deprecated the ComposedModal is deprecated, use the more granular Modal components instead
 */
const ComposedModal = (props: ComposedModalProps): JSX.Element => {
  const {
    headerText,
    icon,
    isOpen,
    dismissFunction,
    leftButton,
    primaryRightButton,
    secondaryRightButton,
    width = '520px',
    id,
    children,
    hideFooterButtons = false,
  } = props;

  return (
    <Modal id={id} PaperProps={{ style: { minWidth: width } }} open={isOpen} onClose={dismissFunction}>
      <ModalHeader toggle={dismissFunction}>
        <Typography data-testid="composed-modal-header" variant="h5" fontWeight={600}>
          {headerText}
        </Typography>{' '}
        <HeaderIconStyled>{icon}</HeaderIconStyled>
      </ModalHeader>
      <ModalBody className="modal-body">{children}</ModalBody>
      {!hideFooterButtons && (
        <>
          <Divider />
          <ModalFooter>
            {leftButton && (
              <div style={{ flexGrow: 1 }}>
                <Button
                  mode="outlined"
                  color="primary"
                  size="auto"
                  onClick={leftButton.onClick}
                  data-test-id={leftButton.testId || `${leftButton.buttonText}-button`}>
                  {leftButton.buttonText}
                </Button>
              </div>
            )}
            {secondaryRightButton && (
              <Button
                mode="outlined"
                color="primary"
                size="auto"
                onClick={secondaryRightButton.onClick}
                data-test-id={secondaryRightButton.testId || `${secondaryRightButton.buttonText}-button`}>
                {secondaryRightButton.buttonText}
              </Button>
            )}
            {primaryRightButton && (
              <Button
                size="small"
                mode="contained"
                color="primary"
                onClick={primaryRightButton.onClick}
                data-test-id={primaryRightButton.testId || `${primaryRightButton.buttonText}-button`}>
                {primaryRightButton.buttonText}
              </Button>
            )}
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default ComposedModal;
