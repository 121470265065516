import MenuItem from '@mui/material/MenuItem';
import BaseTextField, { Props as BaseTextFieldProps } from '../ROTextField/BaseTextField';

export interface SelectOptionType {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface Props extends BaseTextFieldProps {
  options: SelectOptionType[];
}

const BaseSelect = ({ options, ...rest }: Props): JSX.Element => {
  return (
    <BaseTextField
      select
      SelectProps={{
        MenuProps: {
          sx: { height: '350px' },
        },
      }}
      {...rest}>
      {options.map(
        (option: SelectOptionType, index: number): JSX.Element => (
          <MenuItem
            data-testid={`option-${option.value}`}
            key={`option-${option.value}-${index}`}
            value={option.value}
            disabled={option.disabled}>
            {option.label}
          </MenuItem>
        ),
      )}
    </BaseTextField>
  );
};

export default BaseSelect;
