// eslint-disable-next-line no-use-before-define
import React from 'react';
import { ComposedModal } from 'gc-ui';

import './ModalLogout.scss';

interface Props {
  id: string;
  isOpen: boolean;
  dismissFunction: () => void;
  Logout: () => void;
}

const ModalLogout = (props: Props): JSX.Element => {
  const { isOpen, id, dismissFunction, Logout } = props;
  return (
    <ComposedModal
      id={id}
      isOpen={isOpen}
      width={'520px'}
      dismissFunction={dismissFunction}
      headerText={'Logout'}
      secondaryRightButton={{
        buttonText: 'Cancel',
        onClick: (e: any): void => {
          e.preventDefault();
          dismissFunction();
        },
      }}
      primaryRightButton={{
        buttonText: 'Log out',
        onClick: (e: any): void => {
          e.preventDefault();
          Logout();
          dismissFunction();
        },
      }}>
      <div>Are you sure you want to logout from Horizon?</div>
    </ComposedModal>
  );
};

export default ModalLogout;
