// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import validate from 'validate.js';

import { PatientPreferences } from 'op-interfaces';
import { SavingStatus } from 'shared-components/enums';

import { RegistrationContainer } from 'op-components';
import { SectionField, SegmentedInput } from 'shared-components/components/FormFields';
import { ListData } from 'shared-components/interfaces';

const FORM_HEADING = 'Preferences';
const YES_REFERENCE_LIST_DATA_ITEM_ID = '1';
const NO_REFERENCE_LIST_DATA_ITEM_ID = '2';

const FIELD_NAMES: { [key: string]: string } = {
  ACCEPTS_DATA_SHARE: 'acceptsDataShare',
};
const FIELD_HEADINGS = {
  ACCEPTS_DATA_SHARE: 'Accepts data share?',
};

const WARNING_MESSAGE = 'Please select a value';

// Interface for the validation object
interface ValidationObject {
  [key: string]: string[];
  acceptsDataShare: string[];
}

interface Props {
  preferences: PatientPreferences;
  genderRefData: ListData[];
  autosave: (patient: PatientPreferences, key: string, value: string | boolean, type: string) => Promise<void>;
  saveStatus: SavingStatus;
  validateOnLoad: boolean;
  isPso: boolean;
}

interface State {
  viewed: Set<string>;
}

class RegistrationPreferences extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      viewed: new Set(),
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.validateOnLoad && props.preferences.lock && !props.preferences.lock.readOnly) {
      // Placeholder till validation is implemented
    }

    return state;
  }

  public render(): JSX.Element {
    const { preferences, genderRefData, isPso, saveStatus } = this.props;
    return (
      <RegistrationContainer patient={preferences} genderRefData={genderRefData} isPso={isPso} saveStatus={saveStatus}>
        {this.renderContents()}
      </RegistrationContainer>
    );
  }

  private convertBooleanToNumberString = (booleanValue: boolean | null): string | undefined => {
    switch (booleanValue) {
      case true:
        return YES_REFERENCE_LIST_DATA_ITEM_ID;
      case false:
        return NO_REFERENCE_LIST_DATA_ITEM_ID;
      default:
        return undefined;
    }
  };

  private renderContents = (): JSX.Element => {
    const { preferences } = this.props;

    const segmentedInputOptions = [
      { id: YES_REFERENCE_LIST_DATA_ITEM_ID, name: 'Yes' },
      { id: NO_REFERENCE_LIST_DATA_ITEM_ID, name: 'No' },
    ];

    const validationObject = this.validateObject(preferences);

    return (
      <div className="form-page form-registration-information-notice">
        <form className="form-container">
          <div className="form-heading">{FORM_HEADING}</div>

          <SectionField
            isValid={validationObject && validationObject.contactAuthorisedForEnquiries ? false : true}
            htmlFor={FIELD_NAMES.ACCEPTS_DATA_SHARE}
            title={FIELD_HEADINGS.ACCEPTS_DATA_SHARE}>
            <SegmentedInput
              disabled={preferences.lock && preferences.lock.readOnly}
              fieldName={FIELD_NAMES.ACCEPTS_DATA_SHARE}
              options={segmentedInputOptions}
              defaultSelected={this.convertBooleanToNumberString(preferences.acceptsDataShare)}
              optionAreBoolean={true}
              itemSelected={(selectedItem): void => {
                const booleanValue = selectedItem as boolean;
                this.autosave(preferences, FIELD_NAMES.ACCEPTS_DATA_SHARE, booleanValue, 'Boolean');
              }}
              errors={
                validationObject && validationObject.contactAuthorisedForEnquiries
                  ? validationObject.contactAuthorisedForEnquiries
                  : undefined
              }
            />
          </SectionField>
        </form>
      </div>
    );
  };

  /**
   * Autosave functionality that will call the props auto save function.
   */
  private autosave = async (
    coverage: PatientPreferences,
    key: string,
    value: string | boolean,
    type = 'String',
  ): Promise<void> => {
    const { autosave } = this.props;
    autosave(coverage, key, value, type).then(() => {
      const viewed = this.state.viewed.add(key);
      this.setState({ viewed });
    });
  };

  /**
   * Validates the entire form.
   */
  private validateForm(): void {
    // Placeholder till validation is implemented
  }

  private validateObject = (patient: PatientPreferences): ValidationObject | undefined => {
    // Global rules for the validator, must match the patient interface keys
    const globalValidationRules: { [key: string]: object } = {
      acceptsDataShare: {
        presence: {
          allowEmpty: false,
          message: WARNING_MESSAGE,
        },
      },
    };

    // Ensure fields that been viewed only have validation run on them
    const specificValidationRules: { [key: string]: object } = {};
    for (const viewed of this.state.viewed.keys()) {
      specificValidationRules[viewed] = globalValidationRules[viewed];
    }
    // Disable pre-appending of argument name to error messages
    const disableFullMessages = { fullMessages: false };
    // Run validation on all the fields
    return validate(patient, specificValidationRules, disableFullMessages);
  };
}
//@ts-ignore
export default RegistrationPreferences;
