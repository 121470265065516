export interface TreatmentInfoType {
  intent: string;
  readyForCareDate: string;
  preferredTreatmentStartDate: string;
  startDateAdditionalInformation: string;
  treatingDepartment: string;
  pregnancy: string;
  delayCategory: string;
  previousRadioTreatment: string;
  previousCompositePlanRequired: string;
  previousTreatmentAt: string;
  previousTreatmentDate: string;
  patientStatus: string;
  inPatientAt: string;
  priority: string;
  cied: string;
  previousMdt: string;
  ciedPatientDependancy: string;
  ciedLinacRequired: string;
  ciedProtocolRequired: string;
  preTreatmentCiedCheckRequired: string;
  referralToGcCardiologist: string;
  previousTreatmentAdditionalInformation: string;
  previousTreatmentRetreatment: string;
  previousTreatmentRetreatmentType: string;
  otherCiedInstructions: string;
  cardioonc: string;
  endoscope: string;
  chemotherapy: string;
  chemoRegime: string;
  customChemoWhere: string;
  chemoWhere: string;
  chemoSequencing: string;
  chemoStartDate: string;
  chemoEndDate: string;
  hormonesRegime: string;
  hormones: string;
  hormonesStartDate: string;
  hormonesEndDate: string;
}

export const DEFAULT_TREATMENT_INFO: TreatmentInfoType = {
  intent: '',
  readyForCareDate: '',
  preferredTreatmentStartDate: '',
  startDateAdditionalInformation: '',
  treatingDepartment: '',
  pregnancy: '',
  delayCategory: '',
  previousRadioTreatment: '',
  previousCompositePlanRequired: '',
  previousTreatmentAt: '',
  previousTreatmentDate: '',
  patientStatus: '',
  inPatientAt: '',
  priority: '',
  cied: '',
  ciedPatientDependancy: '',
  ciedLinacRequired: '',
  ciedProtocolRequired: '',
  previousMdt: '',
  preTreatmentCiedCheckRequired: '',
  previousTreatmentAdditionalInformation: '',
  previousTreatmentRetreatment: '',
  previousTreatmentRetreatmentType: '',
  referralToGcCardiologist: '',
  otherCiedInstructions: '',
  cardioonc: '',
  endoscope: '',
  chemotherapy: '',
  chemoRegime: '',
  customChemoWhere: '',
  chemoWhere: '',
  chemoSequencing: '',
  chemoStartDate: '',
  chemoEndDate: '',
  hormonesRegime: '',
  hormones: '',
  hormonesStartDate: '',
  hormonesEndDate: '',
};

export interface ListOptionType {
  label: string;
  value: string;
}

export interface ListDataQueryType {
  carepathDataReferenceList: {
    data: {
      id: string;
      data: {
        option: string;
      };
      listWeight: string;
    };
  };
}

export const BOOLEAN_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export const BOOLEAN_UNKNOWN_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Unknown', value: 'Unknown' },
];

export const BOOLEAN_TBC_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'To be clarified', value: 'tbc' },
];

export const UK_CIED_OPTIONS = [
  { label: 'CIED', value: 'cied' },
  { label: 'ICD', value: 'icd' },
  { label: 'To be clarified', value: 'tbc' },
  { label: 'No', value: 'no' },
];

export const PATIENT_STATUS_OPTIONS = [
  { label: 'In Patient', value: 'inPatient' },
  { label: 'Out Patient', value: 'outPatient' },
  { label: 'Unknown', value: 'unknown' },
];

export const BREADTH_HOLD_OPTIONS = [
  { label: 'Free Breathing', value: 'Free Breathing' },
  { label: 'DIBH', value: 'DIBH' },
  { label: 'EEBH', value: 'EEBH' },
  { label: 'Determined at CT', value: 'Determined at CT' },
];

export const DELAY_CATEGORY_OPTIONS = [
  {
    label: 'Category 1',
    value: 'Category 1',
    tooltip: {
      heading: 'Priority 1',
      list: [
        { item: 'SCC HN' },
        { item: 'Lung cancer (small and non-small cell)' },
        { item: 'Gynaecological SCC (cervix, vagina, vulva, etc.)' },
        { item: 'Oesophagus (SCC+adeno)' },
        { item: 'Cutaneous SCC' },
        { item: 'SCC anus' },
        { item: 'Meduloblastoma/PNET and other tumours with short doubling time' },
      ],
    },
  },
  {
    label: 'Category 2',
    value: 'Category 2',
    tooltip: {
      heading: 'Priority 2',
      body: 'Patients with the following tumours should not have their radical radiotherapy prolonged:',
      list: [
        {
          item: 'Squamous cell carcinoma of the head and neck region (grade B recommendation based on level 2 evidence)',
        },
        { item: 'Non-small cell lung carcinoma (NSCLC) - (grade C recommendation)' },
        { item: 'Squamous cell carcinoma of the cervix (grade D recommendation)' },
        { item: 'Small cell lung carcinoma (chemoradiotherapy) - (grade D recommendation)' },
        { item: 'Squamous cell carcinoma of oesophagus (grade D recommendation)' },
        { item: 'Squamous cell carcinoma of skin, vagina or vulva (grade D recommendation)' },
        { item: 'Squamous cell carcinoma of the anus (grade C recommendation)' },
        { item: 'Adenocarcinoma of the oesophagus (grade D recommendation)' },
        {
          item: 'Medulloblastoma and primitive neuroectodermal tumours (PNET) - (grade B recommendation based on level 2 evidence)',
        },
        {
          item: 'Patients with tumours with a short mass-doubling time (grade D recommendation based on level 4 evidence)',
        },
      ],
    },
  },
  {
    label: 'Category 3',
    value: 'Category 3',
    tooltip: {
      heading: 'Priority 3',
      body: 'Patients with the following receiving brachytherapy plus external beam therapy should not have the combined overall treatment time prolonged:',
      list: [
        { item: 'Squamous cell carcinoma of the cervix (grade B recommendation)' },
        { item: 'Squamous cell carcinoma of the tongue (grade C recommendation)' },
        { item: 'Squamous cell carcinoma of the anus (grade D recommendation)' },
      ],
    },
  },
];

export const PRIORITY_OPTIONS = [
  { label: 'Emerg.', value: 'emerg' },
  { label: 'Urgent', value: 'urgent' },
  { label: 'Standard', value: 'standard' },
];

export const RETREATMENT_OPTIONS = [
  { label: 'Partial', value: 'partial' },
  { label: 'Complete', value: 'complete' },
];
