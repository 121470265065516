import React from 'react';
import { styled } from '@mui/system';
import { supportedSize } from 'shared-components/StyledComponents/breakpoints';

const StyledToolTip = styled('div')`
  margin-left: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledRow = styled('div')`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;

  .label-form-row {
    padding: 0 10px 0 0;
  }
  textarea {
    overflow: hidden;
  }

  select {
    border-radius: 5px;
    padding-left: 8px;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  textarea,
  select,
  input {
    :focus-visible {
      outline-color: ${(props) => props.theme.palette.primary.main};
    }
  }

  @media all and (max-width: ${supportedSize.transition}px) {
    display: inline-flex;
    flex-direction: column;
    align-content: flex-start;
    gap: 5px;
  }

  @media all and (max-width: ${supportedSize.tablet}px) {
    margin-top: 0.6rem;
    display: inline-flex;
    flex-direction: column;
    align-content: flex-start;
    gap: 2px;
  }
`;

const StyledLabel = styled('label')`
  display: flex;
  flex: 1;
  align-items: center;
  font-weight: bold;
  padding-left: 0px;
  padding-top: 6px;
  padding-right: 1em;
  min-width: 300px;

  align-self: baseline;
  line-height: 20px;
  margin-top: 6px;
`;

const StyledChild = styled('div')`
  display: flex;
  flex: 2;
  flex-direction: column;
`;

interface Props {
  fieldLabel?: string | null;
  fieldName?: string;
  className?: string;
  labelClass?: string;
  tooltipElement?: JSX.Element;
  children: React.ReactNode;
}

const FormRow = ({ fieldLabel, fieldName, className, labelClass, tooltipElement, children }: Props): JSX.Element => {
  return (
    <StyledRow className={className}>
      <StyledLabel data-testid={`label-${fieldName}`} htmlFor={fieldName} className={labelClass}>
        {fieldLabel}
        {tooltipElement && <StyledToolTip>{tooltipElement}</StyledToolTip>}
      </StyledLabel>
      <StyledChild data-testid={`form-row-field-${fieldName}`}>{children}</StyledChild>
    </StyledRow>
  );
};

export default FormRow;
