import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { useErrorModalContext } from 'op-contexts';
import { FormStatus, DistressThermometerVersion } from 'op-enums';
import { LoadingSpinner } from 'shared-components/components';

import FormContext from './contexts';
import { HEALTH_ASSESSMENT_QUERY, DT_RATING_QUERY } from './graphql/OtherAssessmentQueries';
import {
  PRE_CT_CHART_CHECK_QUERY,
  UPDATE_PRE_CT_CHART_CHECK,
  CREATE_INITIAL_TRIAGE_QCL_CALL,
  CREATE_ASSESSMENT_DEVICE,
  DELETE_ASSESSMENT_DEVICE,
  UPDATE_ASSESSMENT_DEVICE,
  CREATE_INFECTIOUS_DISEASE,
  DELETE_INFECTIOUS_DISEASE,
  UPDATE_INFECTIOUS_DISEASE,
  CREATE_MRO_INFECTION,
  DELETE_MRO_INFECTION,
  UPDATE_MRO_INFECTION,
  CREATE_ALLERGY,
  DELETE_ALLERGY,
  UPDATE_ALLERGY,
  CREATE_REACTION,
  DELETE_REACTION,
  UPDATE_REACTION,
} from './graphql/PreCtChartCheckQueries';
import PreCtChartCheck from './PreCtChartCheck';
import { QUERY_NURSE } from '../../PatientFormSummary/PatientFormSummaryQueries';
import { GET_ALLOWED_LOCATIONS } from '../shared/queries';

const PreCtChartCheckApollo = (): JSX.Element => {
  const { patientId, formId } = useParams<{ patientId: string; formId: string }>();
  const { setError } = useErrorModalContext();

  const {
    loading: distressThermometerLoading,
    data: distressThermometerData,
    error: distressThermometerError,
  } = useQuery(DT_RATING_QUERY, {
    variables: { patientId: patientId, version: DistressThermometerVersion.INITIAL, status: FormStatus.REG_SUBMITTED },
  });

  const {
    loading: healthAssessmentLoading,
    data: healthAssessmentData,
    error: healthAssessmentError,
  } = useQuery(HEALTH_ASSESSMENT_QUERY, {
    variables: { id: patientId, status: FormStatus.REG_SUBMITTED },
  });

  const {
    loading: patientDetailsLoading,
    data: patientDetailsData,
    error: patientDetailsError,
  } = useQuery(QUERY_NURSE, {
    variables: { id: patientId },
  });

  const {
    loading: preCtChartCheckLoading,
    data: preCtChartCheckData,
    error: preCtChartCheckError,
    refetch: refetchPreCtChartCheck,
  } = useQuery(PRE_CT_CHART_CHECK_QUERY, {
    variables: { id: formId },
  });

  const {
    loading: allowedLocationsLoading,
    data: allowedLocationsData,
    error: allowedLocationsError,
  } = useQuery(GET_ALLOWED_LOCATIONS, {
    variables: { roles: ['Nurse'], hasOther: false, filterOption: 'FHIR_SYSTEM' },
  });

  useEffect(() => {
    if (
      allowedLocationsError ||
      distressThermometerError ||
      healthAssessmentError ||
      patientDetailsError ||
      preCtChartCheckError
    )
      return setError();
  }, [
    allowedLocationsError,
    distressThermometerError,
    healthAssessmentError,
    patientDetailsError,
    preCtChartCheckError,
  ]);

  const [updatePreCtChartCheck] = useMutation(UPDATE_PRE_CT_CHART_CHECK);
  const [createInitialTriageQclCall] = useMutation(CREATE_INITIAL_TRIAGE_QCL_CALL);
  const [createAssessmentDevice] = useMutation(CREATE_ASSESSMENT_DEVICE);
  const [deleteAssessmentDevice] = useMutation(DELETE_ASSESSMENT_DEVICE);
  const [updateAssessmentDevice] = useMutation(UPDATE_ASSESSMENT_DEVICE);
  const [createInfectiousDisease] = useMutation(CREATE_INFECTIOUS_DISEASE);
  const [deleteInfectiousDisease] = useMutation(DELETE_INFECTIOUS_DISEASE);
  const [updateInfectiousDisease] = useMutation(UPDATE_INFECTIOUS_DISEASE);
  const [createMroInfection] = useMutation(CREATE_MRO_INFECTION);
  const [deleteMroInfection] = useMutation(DELETE_MRO_INFECTION);
  const [updateMroInfection] = useMutation(UPDATE_MRO_INFECTION);
  const [createAllergy] = useMutation(CREATE_ALLERGY);
  const [deleteAllergy] = useMutation(DELETE_ALLERGY);
  const [updateAllergy] = useMutation(UPDATE_ALLERGY);
  const [createReaction] = useMutation(CREATE_REACTION);
  const [deleteReaction] = useMutation(DELETE_REACTION);
  const [updateReaction] = useMutation(UPDATE_REACTION);

  const mutations = {
    updatePreCtChartCheck,
    refetchPreCtChartCheck,
    createInitialTriageQclCall,
    createAssessmentDevice,
    deleteAssessmentDevice,
    updateAssessmentDevice,
    createInfectiousDisease,
    deleteInfectiousDisease,
    updateInfectiousDisease,
    createMroInfection,
    deleteMroInfection,
    updateMroInfection,
    createAllergy,
    deleteAllergy,
    updateAllergy,
    createReaction,
    deleteReaction,
    updateReaction,
  };

  const isDataLoading =
    allowedLocationsLoading &&
    distressThermometerLoading &&
    healthAssessmentLoading &&
    patientDetailsLoading &&
    preCtChartCheckLoading;

  const isDataAvailable =
    allowedLocationsData &&
    distressThermometerData &&
    patientDetailsData &&
    healthAssessmentData &&
    preCtChartCheckData;

  return (
    <>
      {isDataLoading && <LoadingSpinner />}
      {isDataAvailable && (
        <FormContext.Provider value={{ mutations }}>
          <PreCtChartCheck
            allowedLocationsData={allowedLocationsData.allowedLocations}
            distressThermometerData={distressThermometerData.distressThermometer}
            healthAssessmentData={healthAssessmentData}
            patientDetailsData={patientDetailsData.patient}
            preCtChartCheckData={preCtChartCheckData.preCtChartCheck}
            loggedInUserData={patientDetailsData && patientDetailsData.user}
          />
        </FormContext.Provider>
      )}
    </>
  );
};

export default PreCtChartCheckApollo;
