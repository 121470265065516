import React, { useState, Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { styled } from '@mui/system';
import TooltipWarning from 'shared-components/components/TooltipWarning/TooltipWarning';

import { Logger } from 'shared-components/utils';
import { ModalOptIn } from 'op-components';
import { LocationListData } from 'op-interfaces/Locations';
import { Edit } from 'shared-components/images';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import { DropDownField } from 'shared-components/components/FormFields';
import { GraphUpdate } from 'shared-components/interfaces';

import PatientId from './PatientId';
import { SEND_PX_EMAIL_INVITATION } from '../OP/PatientSearch/PatientSearchQueries';
import {
  GET_LOCATION_PROFILE,
  UPDATE_CENTER_MUTATION,
  UPDATE_PATIENT_OPT_IN,
  UPDATE_PATIENT_TAGS,
  GET_TAG_OPTIONS,
} from './PatientCardQueries';
import { DetailedCardProps } from './types';
import { isUs } from 'op-utils';

interface StyledProps {
  $cursor: string;
}

const EditIcon = styled(Edit)<StyledProps>`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 24px;
  height: 24px;
  margin-left: 4px;
  cursor: ${({ $cursor }: StyledProps): string => $cursor};
`;

const PSODetailsWrapper = styled('div')`
  display: flex;
  flex-direction: ${isUs() ? 'row-reverse' : 'row'};
  min-width: 50%;
`;

const PatientPortalWrapper = styled('div')`
  margin-top: -6.5px;
  width: 30%;
  max-width: 254px;
`;

const PatientPortalHeader = styled('div')`
  display: flex;
  flex-direction: row;
`;

const StyledHeader = styled('div')`
  margin-top: 6.5px;
`;

const PatientPortalStatus = styled('div')`
  font-weight: bold;
`;

const DropdownWrapper = styled('div')`
  width: 35%;
`;

const DropdownHeader = styled('div')`
  font-size: 14px;
`;

const StyledDropDownField = styled(DropDownField)`
  width: 80%;
  margin-top: 5px;

  text-overflow: ellipsis;
  padding-right: 32px !important;

  color: ${(props) => props.theme.palette.text.primary} !important;
`;

const DEFAULT_LOCATION_PROFILE = [
  {
    id: '',
    alias: '',
  },
];

const DEFAULT_TAGS_PROFILE = [
  {
    id: '',
    name: '',
  },
];

const logger = new Logger('PSOPatientCard');

const PSOPatientCard = (props: DetailedCardProps): JSX.Element => {
  const { attributes, loading } = props;
  const [displayPxModal, setDisplayPxModal] = useState(false);
  const [isOptInMutationLoading, setIsOptInMutationLoading] = useState(false);
  const address = attributes.address?.formattedAddress ? attributes.address?.ukFormattedAddress : '-';

  const [handleSendPxEmailInvitation] = useMutation(SEND_PX_EMAIL_INVITATION);
  const [saveHorizonCenterId] = useMutation(UPDATE_CENTER_MUTATION);
  const [saveOptIn] = useMutation(UPDATE_PATIENT_OPT_IN);
  const [updatePatientTagItems] = useMutation(UPDATE_PATIENT_TAGS);

  const handleSaveOptIn = (graphData: GraphUpdate[], optedIn: boolean): void => {
    let primaryPhone = null;
    let email = null;
    let pxOptedOutComment = null;

    const baseVars = { id: attributes.patientId, pxOptedIn: optedIn };

    graphData.forEach((graphItem: GraphUpdate) => {
      if (graphItem.key === 'email') {
        email = graphItem.value as string;
      } else if (graphItem.key === 'primaryPhone') {
        primaryPhone = graphItem.value as string;
      } else if (graphItem.key === 'pxOptedOutComment') {
        pxOptedOutComment = graphItem.value as string;
      }
    });

    if (optedIn) {
      saveOptIn({ variables: { ...baseVars, email: email, primaryPhone: primaryPhone } })
        .then((result: any): void => {
          const savedEmail = result.data.updatePatient.patient.email;
          if (savedEmail) {
            handleSendPxEmailInvitation({ variables: { recipientEmail: savedEmail } });
          } else {
            //Error updating patient
            throw new Error(`Error updating Patient ID ${result.data.updatePatient.patient.id} email address`);
          }
        })
        .catch((err: any) => {
          logger.error(`Error sending invite: ${err}`);
        });
    } else {
      saveOptIn({ variables: { ...baseVars, pxOptedOutComment: pxOptedOutComment } });
    }
    setIsOptInMutationLoading(false);
    setDisplayPxModal(false);
  };

  const renderPXModal = (): JSX.Element => {
    return (
      <ModalOptIn
        //@ts-ignore
        patient={attributes.selectedPatientOptIn}
        isOpen={displayPxModal}
        dismissFunction={(): void => {
          if (!isOptInMutationLoading) {
            setDisplayPxModal(false);
          }
        }}
        saveFunction={(graphData: GraphUpdate[], optedIn: boolean): void => {
          handleSaveOptIn(graphData, optedIn);
        }}
        setMutationLoading={() => setIsOptInMutationLoading(true)}
        patientIda={attributes.ida}
        address={attributes.address}
        dob={attributes.dob}
      />
    );
  };

  const updatePatientTags = (newTagId: any): any => {
    const updatedTags = [];
    if (newTagId !== 'None') {
      updatedTags.push(newTagId);
    }
    updatePatientTagItems({ variables: { patientId: attributes.patientId, updatedTags: updatedTags } });
  };

  // Map the locations to match the listdata type expected by the dropdown component
  const convertLocationsToListData = (locations: LocationListData[]): any => {
    return locations.map(({ alias: name, ...rest }) => ({ name, ...rest }));
  };

  const { data: locationProfileData, loading: loadingLocations } = useQuery(GET_LOCATION_PROFILE, {
    variables: { patientId: attributes.patientId },
    skip: !attributes.patientId,
  });

  const { data: tagData, loading: loadingTagOptions } = useQuery(GET_TAG_OPTIONS);
  const styledProps = {
    $cursor: attributes.hasPatId1 ? 'pointer' : 'not-allowed',
  };
  return (
    <div className="patient-detailed-card-wrapper patient-card-wrapper">
      {!loading && (
        <Fragment>
          <div className="patient-profile-wrapper">
            <div className="photo-widget">
              <PhotoWidget url={attributes.photoUrl} enableOverlay={true} />
            </div>
            <div className="patient-id-wrapper">
              <PatientId attributes={attributes} />
            </div>
          </div>
          <div className="patient-address-wrapper">
            <div className="header-text">Address</div>
            <div className="body-text">{address}</div>
          </div>
          <div className="patient-dob-wrapper">
            <div className="header-text">Date of birth</div>
            <div className="body-text">{attributes.fullAge}</div>
            <div className="header-text">
              Mobile: <b>{attributes.mobile}</b>
            </div>
            <div className="header-text">
              Landline: <b>{attributes.landline}</b>
            </div>
          </div>
          <PSODetailsWrapper>
            {!isUs() && (
              <PatientPortalWrapper>
                {displayPxModal && renderPXModal()}
                <PatientPortalHeader>
                  <StyledHeader>Patient portal</StyledHeader>
                  <TooltipWarning
                    disabled={attributes.hasPatId1}
                    message="Syncing patient with Mosaiq, please try again soon...">
                    <EditIcon
                      data-test-id="patient-portal-sign-up"
                      id="patient-portal-sign-up"
                      onClick={() => attributes.hasPatId1 && setDisplayPxModal(true)}
                      {...styledProps}
                    />
                  </TooltipWarning>
                </PatientPortalHeader>
                <PatientPortalStatus data-test-id="patient-portal-status">
                  {attributes.pxOptedIn ? 'Signed up' : attributes.pxOptedIn == null ? 'Not signed up' : 'Opted out'}
                </PatientPortalStatus>
              </PatientPortalWrapper>
            )}
            {!loadingLocations && (
              <DropdownWrapper>
                <DropdownHeader>Primary department</DropdownHeader>
                <StyledDropDownField
                  disabled={loadingLocations}
                  inputKey={'pso-patient-primary-department'}
                  inputName="pso-patient-primary-department"
                  placeholder={'Please select'}
                  defaultValue={attributes.horizonCenterId?.toString()}
                  options={convertLocationsToListData(
                    (locationProfileData && locationProfileData.locationsPsoSystem) || DEFAULT_LOCATION_PROFILE,
                  )}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                    saveHorizonCenterId({ variables: { id: attributes.patientId, horizonCenterId: e.target.value } });
                  }}
                />
              </DropdownWrapper>
            )}
            {!isUs() && !loadingTagOptions && (
              <DropdownWrapper>
                <DropdownHeader>Tag</DropdownHeader>
                <StyledDropDownField
                  disabled={loadingTagOptions}
                  inputKey={'pso-patient-tag'}
                  inputName="pso-patient-tag"
                  placeholder={'None'}
                  enabledPlaceholder={true}
                  defaultValue={attributes.tags[0]?.tag.id}
                  options={(tagData && tagData.tagOptions) || DEFAULT_TAGS_PROFILE}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updatePatientTags(e.target.value)}
                />
              </DropdownWrapper>
            )}
          </PSODetailsWrapper>
        </Fragment>
      )}
    </div>
  );
};

export default PSOPatientCard;
