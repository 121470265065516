import React, { Fragment, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Table, TableBody, TableHead, TableRow, TableCell, styled, tableCellClasses, useTheme } from '@mui/material';
import { Link, withRouter } from 'react-router-dom';
import './ReviewFormsTable.scss';
import { ItemProps, FormsTableProps, ReviewRequiredForm, formTypes } from './ReviewFormsTypes';
import { CONSTANTS, TABLE_HEADERS, mapFormData, formatLocation, generateFormUrl } from './utils';
import { NavigationContext } from 'op-contexts';
import { DropDownField } from 'shared-components/components/FormFields';
import Pagination, { PageChangePayload } from './ReviewFormsPagination';
import { isUs } from 'op-utils/helpers';

const { BLANK, DEFAULT_PAGINATION_LIMIT, NO_PATIENTS_MESSAGE, REVIEW_FORMS, SELECT_ALL } = CONSTANTS;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.common.black,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ReviewFormsTable = (props: FormsTableProps): JSX.Element => {
  const { forms, allowedLocations, loading, location, totalCount, showNewRego } = props;
  const navigationContext = useContext(NavigationContext);
  const theme = useTheme();
  const [parsedForms, setParsedForms] = useState<ReviewRequiredForm[]>([]);
  const [storedLocationFilter, setStoredLocationFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState<string>();
  const [locationFilterId, setLocationFilterId] = useState<number>();
  const [storedLocationFilterId, setStoredLocationFilterId] = useState<number>();
  const [pagination, setPagination] = useState<PageChangePayload | null>({
    currentPage: 1,
    limit: DEFAULT_PAGINATION_LIMIT,
    startingRecord: 0,
    endingRecord: DEFAULT_PAGINATION_LIMIT * 1,
  });

  // Adds location code to all forms

  const parseForms = (): void => {
    if (forms.length) {
      const filteredForms = forms.filter((formData) => formData.formType === formTypes.registration);

      const formsToParse = isUs() ? filteredForms : forms;

      const newForms = formsToParse.map((form) => {
        const { horizonCenterId } = form;
        const locationCode = horizonCenterId ? formatLocation(horizonCenterId, allowedLocations) : '';

        return {
          ...form,
          locationCode,
        };
      });

      setParsedForms(newForms);
    } else {
      setParsedForms([]);
    }
  };

  // When PSO clicks on a form for review, the "entrypoint" into the patient registration forms will be set to the
  // URL which corresponds to the "Review Forms" dashboard
  useEffect(() => {
    navigationContext.setRegEntryPath(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (pagination) {
      const { startingRecord, limit } = pagination;

      props.getFormsInReview({
        start: startingRecord,
        limit,
      });
    }
  }, [pagination]);

  useEffect((): void => {
    const location = sessionStorage.getItem('location');
    const locationId = sessionStorage.getItem('locationId');

    if (location) {
      setStoredLocationFilter(location);
    }

    if (locationId) {
      setStoredLocationFilterId(parseInt(locationId));
    }
  }, []);

  useEffect(() => {
    parseForms();
    setLocationFilter(storedLocationFilter);
    setLocationFilterId(storedLocationFilterId);
  }, [storedLocationFilter, storedLocationFilterId]);

  useEffect(() => {
    parseForms();
  }, [forms]);

  // Filters patients by location when locationFilter is changed
  useEffect((): void => {
    setPagination({
      currentPage: 1,
      limit: pagination ? pagination.limit : DEFAULT_PAGINATION_LIMIT,
      startingRecord: 0,
      endingRecord: pagination ? pagination.limit * 1 : DEFAULT_PAGINATION_LIMIT,
    });

    if (locationFilter) {
      const getLocationVariable = (isSelectAll: boolean, isBlank: boolean) => {
        // If "Select All" is selected we set the location variable to null, which will return all patients
        // If 'Blank' is selected we set the location variable to a sentinel value of -1, which tells the backend to
        // return patients that do not have a Horizon centre ID defined
        if (isSelectAll) {
          return '';
        } else if (isBlank) {
          return '-1';
        }
        return locationFilterId?.toString();
      };

      props.getFormsInReview({
        start: locationFilter === SELECT_ALL ? pagination?.startingRecord : 0,
        limit: pagination?.limit,
        location: getLocationVariable(locationFilter === SELECT_ALL, locationFilter === BLANK),
      });
    }
  }, [locationFilter]);

  const handlePaginationChange = (payload: PageChangePayload) => {
    setPagination(payload);
  };

  const handleLocationFilterChange = (location: string, locationId: number): void => {
    sessionStorage.setItem('location', location);
    sessionStorage.setItem('locationId', locationId.toString());
    setLocationFilter(location);
    setLocationFilterId(locationId);
  };

  const renderClinicFilter = (): JSX.Element => {
    const locationListItems = allowedLocations.map((item: any): any => {
      return { id: item.id, name: item.alias, appKey: item.alias };
    });
    // Check if any patients don't have a location
    const blankLocationFound = forms.map((form: ReviewRequiredForm): boolean => form.horizonCenterId === null);
    // Add 'select all' to list of options
    locationListItems.unshift({ id: SELECT_ALL, name: SELECT_ALL, appKey: SELECT_ALL });
    // Add 'blank' to list of options if patient found without location
    blankLocationFound.includes(true) && locationListItems.push({ id: BLANK, name: BLANK, appKey: BLANK });

    return (
      <Fragment>
        {locationListItems.length > 0 && (
          <DropDownField
            inputName={'Location Filter'}
            placeholder={'Location Filter'}
            defaultValue={locationFilter}
            options={locationListItems}
            className="location-dropdown"
            controlled={true}
            onChange={(e): void => {
              // Get the updated location ID from the alias value - this will be used in the GraphQL query instead of
              // the alias
              const updatedLocationId = locationListItems.find((item) => item.name === e.target.value).id;

              // Sorts patients first when changing location filter and then sets location filter value
              // This is to avoid accidentally filtering out patients
              parseForms();

              handleLocationFilterChange(e.target.value, updatedLocationId);
            }}
          />
        )}
      </Fragment>
    );
  };

  const renderTableRow = (formRow: ReviewRequiredForm): JSX.Element[] => {
    return mapFormData({ ...formRow }).map((item: ItemProps, index): JSX.Element => {
      const formUrl = generateFormUrl(formRow.formType, formRow.patientId, showNewRego, formRow.hasDataConflicts);
      const patientUrl = `/navigator/patient/${formRow.patientId}/summary`;
      const url = index === 0 ? patientUrl : formUrl;
      return (
        <TableCell key={index}>
          <Link
            style={{ color: 'black', textDecoration: 'none' }}
            className={classnames('link', { [`${item.linkClass}`]: item.linkClass })}
            data-test-id={`row-${item.name}`}
            to={{ pathname: url, state: { from: location.pathname } }}
            onClick={(e): void => {
              e.stopPropagation();
              sessionStorage.setItem('referringPage', '/review-forms');
            }}>
            <span className={classnames('form', { [`${item.valueClass}`]: item.valueClass })}>{item.value}</span>
            {item.otherValue && (
              <span className={classnames({ [`${item.otherValueClass}`]: item.otherValueClass })}>
                {item.otherValue}
              </span>
            )}
          </Link>
        </TableCell>
      );
    });
  };

  const renderTableData = (): JSX.Element[] => {
    return parsedForms.map((form: ReviewRequiredForm, index): JSX.Element => {
      return <StyledTableRow key={index}>{renderTableRow(form)}</StyledTableRow>;
    });
  };

  return (
    <div style={{ borderTop: `6px solid ${theme.palette.primary.main}` }} className="review-forms-container">
      <div className="filter-title-container">
        <div className="review-forms-title">{REVIEW_FORMS}</div>
        {renderClinicFilter()}
      </div>
      <div className="review-forms-table-container">
        <Table className="review-forms-table">
          <TableHead>
            <TableRow>
              {TABLE_HEADERS.map(
                (header): JSX.Element => (
                  <StyledTableCell key={header}>{header}</StyledTableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <tbody>{renderTableData()}</tbody>
        </Table>
      </div>
      {!loading && parsedForms.length === 0 && <div className="no-patients">{NO_PATIENTS_MESSAGE}</div>}
      {parsedForms.length !== 0 && (
        <Pagination
          totalRecords={totalCount}
          page={pagination?.currentPage}
          onPageChange={handlePaginationChange}
          pageLimit={DEFAULT_PAGINATION_LIMIT}
          loading={loading}
        />
      )}
    </div>
  );
};

export default withRouter(ReviewFormsTable);
