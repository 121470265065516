import React from 'react';
import './QuickAccessSectionHeader.scss';

const QuickAccessSectionHeader = (): JSX.Element => {
  const componentId = 'quick-access-section-header';

  return <div id={componentId}>Quick Access</div>;
};

export default QuickAccessSectionHeader;
