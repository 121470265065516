// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect, useMemo } from 'react';
import InputMask from 'react-input-mask';
import { styled } from '@mui/system';
import { FieldProps, getIn } from 'formik';

import { HelperMessage } from 'shared-components/components';
import StyledFieldRow from './StyledFieldRow';

interface IStyledTextArea {
  invalid: boolean;
  warning?: boolean;
  $alternateStyle?: boolean;
  ref?: any;
}

const StyledInputMask = styled(InputMask)<IStyledTextArea>`
  border: 1px solid
    ${(props) =>
      props.warning
        ? props.theme.palette.warning.dark
        : props.invalid
        ? props.theme.palette.error.main
        : props.theme.palette.secondary.main} !important;
  border-radius: 4px;
  height: 42px;
  padding: 8px;
  width: 250px;
`;

interface Props extends FieldProps {
  updateMutation?: (value: string) => void;
  initialMask?: string;
  mask: string;
  defaultValue?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputType?: string;
  elementRef?: (ref: HTMLInputElement | null) => void;
  autoComplete?: string;
  dataTestId?: string;
  maskChar?: string;
  maxLength?: string;
}

const MaskField = ({
  field,
  form,
  updateMutation,
  initialMask,
  mask,
  defaultValue,
  inputType = 'text',
  elementRef,
  autoComplete = 'off',
  dataTestId,
  maskChar = '_',
  maxLength,
}: Props): JSX.Element => {
  const { name, value, onBlur, onChange } = field;
  const [lengthWarning, setLengthWarning] = useState({
    show: false,
    message: 'Character limit reached',
  });
  const { errors, touched, submitCount } = form;
  const fieldError = getIn(errors, name);
  const fieldTouched = getIn(touched, name);
  const isFieldInvalid = (submitCount > 0 || fieldTouched) && fieldError;

  const finalMask = useMemo<string>(() => {
    if (!value || value === mask.replace(/9/g, 'X') || !isNaN(Number(value.replace(/\s/g, '').replace(/X/g, '')))) {
      return mask;
    } else {
      return initialMask && (!!isFieldInvalid || !fieldTouched) ? initialMask : mask;
    }
  }, [fieldTouched, isFieldInvalid, value]);

  const handleBlur = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    onBlur(event);
    updateMutation && updateMutation(event.target.value);
  };

  useEffect(() => {
    if (maxLength && value.length === parseInt(maxLength)) {
      setLengthWarning({ ...lengthWarning, show: true });
    } else setLengthWarning({ ...lengthWarning, show: false });
  }, [value]);

  return (
    <>
      <StyledFieldRow>
        <StyledInputMask
          autoComplete={autoComplete}
          mask={finalMask}
          alwaysShowMask={true}
          invalid={isFieldInvalid}
          name={name}
          type={inputType}
          onBlur={(e: any): Promise<any> => handleBlur(e)}
          onChange={onChange}
          ref={elementRef as any}
          data-test-id={dataTestId}
          maskChar={maskChar}
          defaultValue={defaultValue}
          value={value}
        />
      </StyledFieldRow>
      {(isFieldInvalid || lengthWarning.show) && (
        <HelperMessage
          fieldName={name}
          fieldText={fieldError || lengthWarning.message}
          helperType={lengthWarning.show ? 'warning' : 'error'}
          fontSize={14}
        />
      )}
    </>
  );
};

export default MaskField;
