// eslint-disable-next-line no-use-before-define
import React, { Fragment, useState, useEffect, useMemo } from 'react';

import { useRouteMatch, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { ROAutocomplete, ROTextField } from 'shared-components/components/FormFields';
import ROPatientCarePlanPageFooter from '../Footer';
import OrgansAtRiskModule from './OrgansAtRisk/OrgansAtRisk';
import { ROPatientCarePlanRoute } from '../Interface';
import SiteTargetValues from './SiteTargetValues';
import {
  GET_CAREPLAN_SITE_GROUPS,
  UPDATE_SITE_GROUP_VALUE,
  SITE_GROUP_TEMPLATE_VALUE_VARIATION,
  SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION,
  GET_CAREPLAN,
  GET_CAREPLAN_STATUS,
  TRIGGER_CPOT,
} from '../Queries';
import { LIST_CAREPLAN_FILTERS, GET_TUMOUR_STREAM_FROM_CAREPLAN } from '../TemplatePage/TemplatePageQueries';
import { OAR_QUERY, ORGAN_AT_RISK_TEMPLATE_VALUE_VARIATION } from './OrgansAtRisk/Queries';
import { CareplanBanners } from '../Banner/Banner';
import { CAREPLAN_PAGES } from '../Constants';
import './VolumingPage.scss';
import { ADDITIONAL_OAR_INFO, SELECTED_TEMPLATE, OAR } from './Interface';
import ROPatientCarePathSidePanel from '../SidePanel/SidePanel';
import { RESET_VOLUMING_DATA, GET_VOLUMING_OAR_DATA, UPDATE_MANUAL_OARS } from './Queries';
import { removeUnicodeQuotes, getTreatmentSiteHeader } from '../Common';
import { LoadingSpinner } from 'shared-components/components';
import CareplanContainer from '../Container';
import { useErrorModalContext } from 'op-contexts';
import ModalCpotCancel from '../ModalCpotCancel/ModalCpotCancel';
import { CurrentAppConfig } from '../AppConfig';
import { Logger } from 'shared-components/utils';
import { logMissingDiagnosisCode } from '../Common';
import { useTheme, Divider, FormLabel, Box } from '@mui/material';
import { SelectOptionType } from 'shared-components/components/FormFields';

const logger = new Logger('VolumingPage.tsx');

interface careplanTemplateList {
  id: string;
  name: string;
  rule: {
    id: string;
    field: string;
    defaultValue: string;
  }[];
}

interface State {
  siteGroupId: number;
  volumingAdditionalOarInfo: string;
  volumingAdditionalOarInfoId: number;
  volumingSelectedTemplate: SelectOptionType | undefined;
  volumingSelectedTemplateId: number;
  volumingOarId: number;
}

const ROPatientVolumingPage = (): JSX.Element => {
  // App Config
  const showSpecialPhysicsConsultPage = CurrentAppConfig.SpecialPhysicsConsultVisible;
  const theme = useTheme();

  // Non-react hooks
  const { setError } = useErrorModalContext();

  const match = useRouteMatch<ROPatientCarePlanRoute>();
  const history = useHistory();
  const { id: patientId, careplanId, siteGroupIdx } = match.params;

  // React state variables
  const [state, setState] = useState<State>({
    siteGroupId: -1,
    volumingAdditionalOarInfo: '',
    volumingAdditionalOarInfoId: -1,
    volumingSelectedTemplate: undefined,
    volumingSelectedTemplateId: -1,
    volumingOarId: -1,
  });

  const [showModal, setShowModal] = useState(false);

  const {
    data: careplanData,
    loading: careplanLoading,
    error: careplanStatusError,
  } = useQuery(GET_CAREPLAN_STATUS, {
    variables: { id: careplanId },
  });
  const cpotTriggered = useMemo(() => careplanData?.careplan?.careplanStatus === 'CPOT', [careplanData]);

  const { data: tumourStreamData, error: tumourDataError } = useQuery(GET_TUMOUR_STREAM_FROM_CAREPLAN, {
    variables: { careplanId },
    onCompleted: (data: any): void => {
      logMissingDiagnosisCode(logger, data.careplan, 'Voluming');
    },
  });
  const tumourStream = useMemo(
    () => tumourStreamData?.careplan?.diagnosis?.diagnosisCode?.tumourStream?.name,
    [tumourStreamData],
  );

  const { data: careplanFiltersData, error: careplanFiltersDataError } = useQuery(LIST_CAREPLAN_FILTERS, {
    variables: { patientId, tumourStream: tumourStream, hasOar: true },
    skip: !tumourStream,
  });
  const templateList = useMemo(() => {
    return careplanFiltersData?.careplanTemplateList
      .filter((templateItem: careplanTemplateList) => !!templateItem)
      .map((templateItem: careplanTemplateList) => {
        return { label: templateItem?.name, value: templateItem?.id };
      });
  }, [careplanFiltersData]);

  const [forceRefresh, setForceRefresh] = useState(true);
  const [groupVariation, setGroupVariation] = useState<boolean>(false);
  const [siteVariation, setSiteVariation] = useState<boolean>(false);

  const [cancelCpot] = useMutation(TRIGGER_CPOT, {
    awaitRefetchQueries: true,
    variables: {
      id: careplanId,
      careplanStatus: 'Prescription',
      cpotTriggered: false,
    },
    refetchQueries: [
      { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
    ],
    onCompleted: () => {
      history.push(`/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.SUBMISSION}`);
    },
  });

  const [updateManualOars] = useMutation(UPDATE_MANUAL_OARS, {
    refetchQueries: [
      { query: ORGAN_AT_RISK_TEMPLATE_VALUE_VARIATION, variables: { siteGroupId: state.siteGroupId } },
      { query: OAR_QUERY, variables: { siteGroupId: state.siteGroupId } },
      { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
    ],
  });
  const [updateSiteGroupValue] = useMutation(UPDATE_SITE_GROUP_VALUE, {
    refetchQueries: [
      { query: SITE_GROUP_TEMPLATE_VALUE_VARIATION, variables: { siteGroupId: state.siteGroupId } },
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: ORGAN_AT_RISK_TEMPLATE_VALUE_VARIATION, variables: { siteGroupId: state.siteGroupId } },
      { query: OAR_QUERY, variables: { siteGroupId: state.siteGroupId } },
      {
        query: SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION,
        variables: { siteGroupId: state.siteGroupId, volumingPage: true },
      },
    ],
  });

  const { loading, data, error, refetch } = useQuery(GET_CAREPLAN_SITE_GROUPS, {
    variables: { careplanId },
    fetchPolicy: 'cache-and-network',
  });

  const [resetVolumingData] = useMutation(RESET_VOLUMING_DATA, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_CAREPLAN, variables: { id: careplanId } },
      { query: GET_VOLUMING_OAR_DATA, variables: { siteGroupId: state.siteGroupId } },
      { query: ORGAN_AT_RISK_TEMPLATE_VALUE_VARIATION, variables: { siteGroupId: state.siteGroupId } },
      { query: OAR_QUERY, variables: { siteGroupId: state.siteGroupId } },
      { query: SITE_GROUP_TEMPLATE_VALUE_VARIATION, variables: { siteGroupId: state.siteGroupId } },
      {
        query: SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION,
        variables: { siteGroupId: state.siteGroupId, volumingPage: true },
      },
      { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
    ],
    onCompleted: (): void => {
      refetch();
      setForceRefresh(!forceRefresh);
    },
  });

  const currentSiteGroupId: number = useMemo(() => {
    let siteGroupId = siteGroupIdx ? parseInt(siteGroupIdx) : 0;
    if (data && siteGroupIdx === 'last') {
      siteGroupId = data.siteGroupsByCareplan.length - 1;
    }

    return siteGroupId;
  }, [data, siteGroupIdx]);

  const { data: careplanStatusData, error: careplanStatusDataError } = useQuery(GET_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { id: careplanId },
  });
  const { data: siteGroupVariation, error: siteGroupVariationError } = useQuery(SITE_GROUP_TEMPLATE_VALUE_VARIATION, {
    fetchPolicy: 'network-only',
    variables: { siteGroupId: data?.siteGroupsByCareplan[currentSiteGroupId]?.id || 0 },
    skip: loading || !data || cpotTriggered,
  });
  // Check CTV and PTV
  const { data: siteVariationBool, error: siteVariationBoolError } = useQuery(SITE_TEMPLATE_VALUES_BY_GROUP_VARIATION, {
    fetchPolicy: 'network-only',
    variables: { siteGroupId: data?.siteGroupsByCareplan[currentSiteGroupId]?.id || 0, volumingPage: true },
    skip: loading || !data || cpotTriggered,
  });

  useEffect(() => {
    if (
      tumourDataError ||
      careplanFiltersDataError ||
      error ||
      careplanStatusDataError ||
      siteGroupVariationError ||
      siteVariationBoolError ||
      careplanStatusError
    )
      return setError();
  }, [
    tumourDataError,
    careplanFiltersDataError,
    error,
    careplanStatusDataError,
    siteGroupVariationError,
    siteVariationBoolError,
    careplanStatusError,
  ]);

  const siteGroupTemplate = useMemo(() => data?.siteGroupsByCareplan[currentSiteGroupId]?.template, [data]);
  const isManual = useMemo(() => !siteGroupTemplate || siteGroupTemplate?.id === -1, [siteGroupTemplate]);

  useEffect((): void => {
    refetch();
  }, []);

  useEffect((): void => {
    if (data) {
      const siteGroupId = data.siteGroupsByCareplan[currentSiteGroupId].id;
      const additionalOarInfo = data.siteGroupsByCareplan[currentSiteGroupId].sitegroupvaluesSet.filter(
        (sgValue: any): boolean => sgValue.field.name === ADDITIONAL_OAR_INFO,
      );
      const selectedTemplate = data.siteGroupsByCareplan[currentSiteGroupId].sitegroupvaluesSet.filter(
        (sgValue: any): boolean => sgValue.field.name === SELECTED_TEMPLATE,
      );
      const volumingSelectedTemplate = templateList?.find(
        (template: SelectOptionType) => String(template?.value) === String(selectedTemplate?.[0]?.value),
      );
      const oar = data.siteGroupsByCareplan[currentSiteGroupId].sitegroupvaluesSet.filter(
        (sgValue: any): boolean => sgValue.field.name === OAR,
      );
      setState({
        ...state,
        siteGroupId,
        volumingAdditionalOarInfo:
          additionalOarInfo && additionalOarInfo.length > 0 ? JSON.parse(additionalOarInfo[0].value) : '',
        volumingSelectedTemplate: volumingSelectedTemplate || undefined,
        volumingAdditionalOarInfoId: additionalOarInfo && additionalOarInfo.length > 0 ? additionalOarInfo[0].id : -1,
        volumingSelectedTemplateId: selectedTemplate && selectedTemplate.length > 0 ? selectedTemplate[0].id : -1,
        volumingOarId: oar && oar.length > 0 ? oar[0].id : -1,
      });
    }
  }, [data, currentSiteGroupId, templateList]);

  const siteGroup = useMemo(() => data && data.siteGroupsByCareplan[currentSiteGroupId], [data, currentSiteGroupId]);
  const siteNames = useMemo(
    () =>
      siteGroup && siteGroup.siteSet
        ? siteGroup.siteSet
            .map((site: any): string => {
              const location = site.sitevaluesSet.reduce(
                (loc: string, value: any): string => (value.field.name === 'Location' ? JSON.parse(value.value) : loc),
                '',
              );
              const laterality = site.sitevaluesSet.reduce(
                (loc: string, value: any): string =>
                  value.field.name === 'Laterality' ? JSON.parse(value.value) : loc,
                '',
              );
              return getTreatmentSiteHeader({ treatmentSite: site.treatmentSite.treatmentSite, location, laterality });
            })
            .join(', ')
        : '',
    [siteGroup],
  );

  useEffect(() => {
    if (siteGroupVariation && !cpotTriggered) {
      const variation = siteGroupVariation ? siteGroupVariation.siteGroupTemplateValueVariation.variation : false;
      if (!groupVariation === variation) setGroupVariation(variation);
    }

    if (siteVariationBool && !cpotTriggered) {
      const variation = siteVariationBool.siteTemplateValuesByGroupVariation?.variation
        ? siteVariationBool.siteTemplateValuesByGroupVariation?.variation
        : false;
      if (!siteVariation === variation) setSiteVariation(variation);
    }
  }, [
    siteGroupVariation,
    cpotTriggered,
    groupVariation,
    setGroupVariation,
    siteVariationBool,
    setSiteVariation,
    siteVariation,
  ]);

  if (loading || careplanLoading)
    return <LoadingSpinner loadingText={'Loading Voluming'} subtitle={'Please wait while we set things up for you'} />;

  return (
    <Fragment>
      <div className="main-container-parent-wrapper">
        <div className="main-container-wrapper">
          <CareplanBanners
            data={careplanStatusData}
            variation={groupVariation || siteVariation}
            forceUpdate={refetch}
            refetchQueriesList={[
              { query: GET_CAREPLAN, variables: { id: careplanId } },
              { query: GET_CAREPLAN_STATUS, variables: { id: careplanId } },
              { query: GET_CAREPLAN_SITE_GROUPS, variables: { careplanId } },
              { query: GET_TUMOUR_STREAM_FROM_CAREPLAN, variables: { careplanId } },
            ]}
          />
          <CareplanContainer className={'ro-voluming-page'}>
            <div className="page-header">
              <h1>Voluming: {siteNames}</h1>
            </div>
            <Box component="form" id="volumingForm" noValidate>
              <SiteTargetValues refresh={forceRefresh} cpotTriggered={cpotTriggered} />
              <Divider />
              <FormLabel>
                <div
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: theme.palette.text.primary,
                    padding: '10px 0',
                  }}>
                  Organs At Risk
                </div>
              </FormLabel>
            </Box>
            {isManual && siteGroup && (
              <ROAutocomplete
                id="volumingSelectedTemplate"
                fieldlabel="Select OARs from Template"
                options={templateList || []}
                value={state.volumingSelectedTemplate}
                disableClearable
                onChange={(option: SelectOptionType | string): void => {
                  const value = typeof option === 'string' ? { label: option, value: option } : option;
                  setState({ ...state, volumingSelectedTemplate: value });
                  updateSiteGroupValue({
                    variables: {
                      siteGroupId: siteGroup.id,
                      id: state.volumingSelectedTemplateId,
                      fieldName: SELECTED_TEMPLATE,
                      value: value?.value,
                    },
                  });
                  updateManualOars({
                    variables: {
                      siteGroupId: siteGroup.id,
                      templateId: value?.value,
                    },
                  });
                }}
              />
            )}
            {siteGroup && (
              <OrgansAtRiskModule siteGroupId={siteGroup.id} careplanId={careplanId} cpotTriggered={cpotTriggered} />
            )}
            <Divider />
            <Box component="form" id="volumingForm-2" noValidate sx={{ marginTop: '8px' }}>
              <ROTextField
                id="volumingAdditionalOarInfo"
                fieldlabel={'Additional OAR Information'}
                value={state.volumingAdditionalOarInfo}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                  setState({ ...state, volumingAdditionalOarInfo: removeUnicodeQuotes(event.target.value) });
                }}
                onBlur={(): void => {
                  updateSiteGroupValue({
                    variables: {
                      siteGroupId: siteGroup.id,
                      id: state.volumingAdditionalOarInfoId,
                      fieldName: ADDITIONAL_OAR_INFO,
                      value: JSON.stringify(state.volumingAdditionalOarInfo),
                    },
                  });
                }}
                multiline
                maxRows={4}
              />
            </Box>
          </CareplanContainer>
          <ROPatientCarePlanPageFooter
            onReset={(): void => {
              if (cpotTriggered) {
                setShowModal(true);
              } else {
                resetVolumingData({ variables: { siteGroupId: siteGroup.id } });
              }
            }}
            resetText={cpotTriggered ? 'Cancel' : 'Reset to templated values'}
            onBack={(): void => {
              const index = currentSiteGroupId - 1;
              if (index === -1) {
                const presIndex = data.siteGroupsByCareplan
                  ? data.siteGroupsByCareplan
                      .map((group: any): any => group.siteSet)
                      .reduce((acc: any, val: any) => acc.concat(val), []).length - 1
                  : 0;
                history.push(
                  `/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.PRESCRIPTION}/${presIndex}`,
                );
              } else {
                history.push(
                  `/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.VOLUMING}/${index}`,
                );
              }
            }}
            onNext={(): void => {
              const index = currentSiteGroupId + 1;
              if (data.siteGroupsByCareplan.length <= index) {
                history.push(
                  `/radiation/patient/${patientId}/careplan/${careplanId}/${
                    showSpecialPhysicsConsultPage ? CAREPLAN_PAGES.SPECIAL_PHYSICS_CONSULT : CAREPLAN_PAGES.SUBMISSION
                  }`,
                );
              } else {
                history.push(
                  `/radiation/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.VOLUMING}/${index}`,
                );
              }
            }}
            nextDisabled={loading}
            backDisabled={loading}
          />
        </div>
        <ROPatientCarePathSidePanel />
        <ModalCpotCancel
          isOpen={showModal}
          submitFunction={() => cancelCpot()}
          dismissFunction={() => setShowModal(false)}
        />
      </div>
    </Fragment>
  );
};

export default ROPatientVolumingPage;
