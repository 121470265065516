import './CarePlanDashboard.scss';

// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { Dayjs } from 'dayjs';
import { useErrorModalContext } from 'op-contexts';
import { StyledTile } from '../Dashboard';
import moment from 'moment';
import ROCarePlanTable, {
  DropDownObject,
  SearchInput,
  SubmittedAtComponent,
  SubmittedByComponent,
  DepartmentComponent,
} from './ROCarePlanTable';
import { SORT_TYPES, DATATYPE } from 'shared-components/components/Table/Table';
import { NameComponent, CareplanNameComponent, StatusComponent, TABLE_KEYS, TABLE_LABELS } from './ROCarePlanTable';
import { GET_CAREPATH_FILTERS_QUERY } from './graphql/queries';
import MultiSelectWithSearch from './MultiSelectWithSearch';
import DateRange, { DateRangeValue } from './DateRange';
import ROSearchFilter from './ROFilterMenu';
import { MultiSelectOptions } from './MultiSelectWithSearch';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { Careplan } from 'op-pages/RO/Careplan/Interface';
import { useTheme } from '@mui/material';

interface CareplanDocument {
  docUrl: string;
  id: string;
}
interface Props {
  currentPage: number;
  careplans: any[];
  totalPages: number;
  searchTerm: string;
  onPageChange: any;
  onSearchStringChange: any;
  onPlanStatusChange: any;
  onPriorityChange: any;
  onOrderingChange: any;
  statusDropDownData: DropDownObject[];
  selectedStatus: string[];
  orderingDirection: string;
  orderType: string;
  onRowClick: (_documentUrl: string) => void;
  careplanDropDownData: DropDownObject[];
  onCareplanFilterChange: any;
  careplanFilterSelected: string[];
  onSubmittedByChange: (practitioners: MultiSelectOptions[]) => void;
  onDateRangeChange: (startingDate?: Dayjs, endingDate?: Dayjs) => void;
  dateRange: DateRangeValue;
  submittedByFilters: MultiSelectOptions[];
}

const ROCarePlanViewerDashboard = (props: Props) => {
  const {
    currentPage,
    totalPages,
    careplans,
    statusDropDownData,
    searchTerm,
    selectedStatus,
    onRowClick,
    onOrderingChange,
    onSearchStringChange,
    onPageChange,
    onPlanStatusChange,
    onSubmittedByChange,
    onDateRangeChange,
    dateRange,
    submittedByFilters,
  } = props;

  const theme = useTheme();
  const { data: carepathFilterData, error: carepathFilterError } = useQuery(GET_CAREPATH_FILTERS_QUERY, {
    fetchPolicy: 'network-only',
  });
  const { setError } = useErrorModalContext();
  useEffect(() => {
    if (carepathFilterError) return setError();
  }, [carepathFilterError]);

  const searchValue = searchTerm || '';

  const careplansWithIdentifier = careplans.map((item: any) => {
    item.patientName = item.patient.fullName;
    return item;
  });

  const headers = [
    {
      title: TABLE_LABELS.PATIENT_NAME,
      key: TABLE_KEYS.PATIENT_NAME,
      sort: true,
      type: DATATYPE.STRING,
      width: '20%',
    },
    {
      title: TABLE_LABELS.DEPARTMENT,
      key: TABLE_KEYS.DEPARTMENT,
      type: DATATYPE.STRING,
      width: '10%',
    },
    {
      title: TABLE_LABELS.CAREPLAN,
      key: TABLE_KEYS.CAREPLAN,
      type: DATATYPE.STRING,
      width: '25%',
    },
    {
      title: TABLE_LABELS.STATUS,
      key: TABLE_KEYS.STATUS,
      type: DATATYPE.STRING,
      width: '15%',
      filterOptions: statusDropDownData,
      onFilterChange: onPlanStatusChange,
      dropdownTitle: `${TABLE_LABELS.STATUS}${selectedStatus.length === statusDropDownData.length ? ' (All)' : ''}`,
      badgeNumber: selectedStatus.length !== 0 ? selectedStatus.length : undefined,
    },
    {
      title: TABLE_LABELS.SUBMITTED_BY,
      key: TABLE_KEYS.SUBMITTED_BY,
      type: DATATYPE.STRING,
      width: '15%',
    },
    {
      title: TABLE_LABELS.LAST_SUBMITTED,
      key: TABLE_KEYS.LAST_SUBMITTED,
      sort: true,
      type: DATATYPE.DATE,
      width: '15%',
    },
  ];

  const tableData = careplansWithIdentifier.map((careplan: Careplan) => ({
    tableRows: [
      {
        key: TABLE_KEYS.PATIENT_NAME,
        width: '20%',
        sortableField: careplan.patient.fullName,
        component: (
          <NameComponent
            careplan={careplan}
            showLink={CurrentAppConfig.RadiationDashboard.careplanViewerDashboard.isPatientSummaryLinkEnabled}
          />
        ),
      },
      {
        key: TABLE_KEYS.DEPARTMENT,
        width: '10%',
        component: <DepartmentComponent careplan={careplan} />,
      },
      {
        key: TABLE_KEYS.CAREPLAN,
        width: '25%',
        component: <CareplanNameComponent careplan={careplan} />,
        careplan: careplan,
      },
      {
        key: TABLE_KEYS.STATUS,
        width: '15%',
        component: <StatusComponent careplan={careplan} />,
      },
      {
        key: TABLE_KEYS.SUBMITTED_BY,
        width: '15%',
        component: <SubmittedByComponent careplan={careplan} />,
      },
      {
        key: TABLE_KEYS.LAST_SUBMITTED,
        width: '15%',
        sortableField: moment(careplan.submittedAt),
        component: <SubmittedAtComponent careplan={careplan} />,
      },
    ],
    onRowClick: () => {
      careplan.documentSet?.forEach((document: CareplanDocument) => {
        onRowClick(document.docUrl);
      });
    },
    rowHoverColor: theme.palette.primary.light,
  }));

  const tableProps = {
    tableHeaders: headers,
    tableData: tableData,
    defaultSortState: { key: TABLE_KEYS.LAST_SUBMITTED, order: SORT_TYPES.ASC, type: DATATYPE.DATE },
  };

  return (
    <div className="RO-dashboard-wrapper">
      <StyledTile className="careplan-dashboard-wrapper">
        <div className="filter-wrapper" id="careplan-viewer-title">
          <div className="table-title-style">
            <span className="table-title">Careplan Dashboard</span>
          </div>
        </div>
        <div className="filter-wrapper">
          <div className="table-filters" id="careplan-viewer-filters">
            <SearchInput
              inputValue={searchValue}
              id="patient-gc-search"
              placeholder="Search patient name or patient ID…"
              onSubmitSearch={(searchString: string) => onSearchStringChange(searchString)}></SearchInput>
            <ROSearchFilter
              showAlert={submittedByFilters.length > 0}
              title="Radiation oncologist"
              id="radiationOncologistFilter">
              {carepathFilterData && (
                <MultiSelectWithSearch
                  options={carepathFilterData.carepathFilters.practitioners.map((practitioner: any) => {
                    return { label: practitioner.name, value: practitioner.id };
                  })}
                  id={'multi-select-search-filter-by-practitioner'}
                  value={submittedByFilters}
                  label={'Radiation Oncologist'}
                  placeholder={'Dr. John Doe'}
                  onChange={onSubmittedByChange}
                  disableCloseOnSelect
                />
              )}
            </ROSearchFilter>
            <ROSearchFilter
              showAlert={Boolean(dateRange?.startingDate || dateRange?.endingDate)}
              title="Submitted date"
              id="dateRangeFilter">
              <DateRange value={dateRange} onRangeChange={onDateRangeChange} />
            </ROSearchFilter>
          </div>
        </div>
        <div className="table-wrapper">
          <ROCarePlanTable
            orderingDirection={props.orderingDirection}
            orderType={props.orderType}
            carePlans={careplans}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            onRowClick={onRowClick}
            onOrderChange={(order: string) => onOrderingChange(order)}
            tableProps={tableProps}
          />
        </div>
      </StyledTile>
    </div>
  );
};

export default ROCarePlanViewerDashboard;
