// eslint-disable-next-line no-use-before-define
import React from 'react';
import { FormBuilderFields, FormBuilderComponentProps } from '../FormBuilderInterfaces';
import FBToggleButtons from './Fields/FBToggleButtons';
import FBTextArea from './Fields/FBTextArea';
import FBInput from './Fields/FBInput';
import FBDatePicker from './Fields/FBDatePicker';
import FBDropdownSearch from './Fields/FBDropdownSearch';
import FBCheckBoxTile from './Fields/FBCheckBoxTile';

/**
 * `FBFormControl` is a React functional component that serves as a dynamic form control
 * renderer for FormBuilder components. It takes a set of `props` containing information
 * about the component to render, including its type, properties, and data.
 *
 * @param {FormBuilderComponentProps} props - The properties passed to the component.
 * @returns {JSX.Element} - A JSX element representing the rendered form control.
 */
export default function FBFormControl(props: FormBuilderComponentProps): JSX.Element {
  const { component } = props;

  switch (component.field.formControl) {
    case FormBuilderFields.TextArea:
      return <FBTextArea {...props} />;

    case FormBuilderFields.Input:
      return <FBInput {...props} />;

    case FormBuilderFields.DatePicker:
      return <FBDatePicker {...props} />;

    // was using this up to now, but isn't our typical RO dropdown
    // case FormBuilderFields.Dropdown:
    //   return <FBDropdown {...props} />;

    // "works" but displays small options and with invisible text
    case FormBuilderFields.Dropdown: // TODO I guess should really become DropdownSearchField...
      return <FBDropdownSearch {...props} />;

    case FormBuilderFields.ToggleButtons:
      return <FBToggleButtons {...props} />;

    case FormBuilderFields.CheckboxTile:
      return <FBCheckBoxTile {...props} />;

    default:
      return (
        <div key={component.field.name} id={component.field.name}>
          {`Component not found - ${component.field.formControl} `}
        </div>
      );
  }
}
