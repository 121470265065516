import { gql } from '@apollo/client';

export const DIAGNOSIS_TRENDS = gql`
  query GetDiagnosisTrends($clinics: String, $dateRange: String) {
    getDiagnosisTrends(clinics: $clinics, dateRange: $dateRange) {
      id
      totalCount
      date
      tumourStream
      clinic
      diagnosis
    }
  }
`;
export const PRESCRIPTION_BY_TUMOUR_STREAM = gql`
  query GetPrescriptionsByTumourStream($clinics: String, $dateRange: String) {
    getPrescriptionsByTumourStream(clinics: $clinics, dateRange: $dateRange) {
      id
      totalCount
      date
      tumourStream
      clinic
    }
  }
`;
export const CAREPLAN_USAGE = gql`
  query GetCareplanUsage($clinics: String, $dateRange: String) {
    getCareplanUsage(clinics: $clinics, dateRange: $dateRange) {
      id
      totalCount
      date
      tumourStream
      clinic
      careplanTemplate
    }
  }
`;
export const REFERRING_MD_TRENDS = gql`
  query GetReferringMdTrends($clinics: String, $dateRange: String) {
    getReferringMdTrends(clinics: $clinics, dateRange: $dateRange) {
      id
      totalCount
      date
      tumourStream
      clinic
      referringMd
    }
  }
`;
