import React from 'react';
import { styled } from '@mui/system';
import { Edit as Amend } from 'shared-components/images';

interface Props {
  fieldId: string;
  label?: string;
  onClick: (e: any) => any;
  unclickable?: boolean;
}

interface IStyledIcon {
  label?: string;
  unclickable?: boolean;
}

const StyledButton = styled('button')<IStyledIcon>`
  border: none;
  background: none;
  align-items: center;
  display: flex;
  padding: 0;
  outline: none !important;
  border-radius: 4px;
  &:hover {
    cursor: ${(props: IStyledIcon): string => (!props.unclickable ? 'pointer' : 'not-allowed')};
    text-decoration: ${(props: IStyledIcon): string => (!props.unclickable ? 'underline' : 'none')};
  }
`;

const AmendIcon = styled(Amend)<IStyledIcon>`
  margin-right: ${(props: IStyledIcon): string => (props.label ? '8px' : '0px')};
  height: 18px;
  width: 18px;
  path {
    fill: ${(props): string => (!props.unclickable ? props.theme.palette.primary.main : 'black')};
    fill-opacity: ${(props: IStyledIcon): string => (!props.unclickable ? '1' : '0.54')};
  }
`;

const Label = styled('span')<IStyledIcon>`
  font-weight: bold;
  font-size: 0.875rem;
  color: ${(props: IStyledIcon): string =>
    !props.unclickable ? 'black' : `${(props: any) => props.theme.palette.secondary.dark}`};
`;

const AmendButton = ({ fieldId, label, onClick, unclickable }: Props): JSX.Element => {
  return (
    <StyledButton type="button" onClick={onClick} data-test-id={`${fieldId}-button-id`} unclickable={unclickable}>
      <AmendIcon label={label} unclickable={unclickable} />
      <Label unclickable={unclickable}>Amend</Label>
    </StyledButton>
  );
};

export default AmendButton;
