// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { useMutation, useApolloClient, gql } from '@apollo/client';
import { styled } from '@mui/system';
import { beach } from 'shared-components/images';
import { HeaderBar } from 'op-components';
import { LOGOUT_MUTATION } from 'op-graphql/Logout';
import { Logger } from 'shared-components/utils';

const logger = new Logger('RegistrationComplete');

const HeaderImage = styled('div')`
  width: 100%;
  height: 420px;
  background-image: url(${beach});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

const StyledSection = styled('section')`
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 76px - 32px);
`;

const StyledMain = styled('main')`
  margin: 48px 56px;
`;

const Paragraph = styled('p')`
  font-size: 18px;
  line-height: 28px;
  font-family: 'GenesisCare Sans', Arial, sans-serif;
`;

const Headline = styled('h1')`
  font-size: 29px;
  line-height: 36px;
  font-family: 'GenesisCare Sans', Arial, sans-serif;
  font-weight: 700;
  margin-bottom: 16px;
`;

const Bulletpoints = styled('ul')`
  list-style: disc;
  font-size: 18px;
  line-height: 28px;
  font-family: 'GenesisCare Sans', Arial, sans-serif;
`;

const StyledFooter = styled('footer')`
  height: 76px;
  background-color: white;
  border-top: 1px solid ${(props) => props.theme.palette.secondary.main};
`;

const RegistrationComplete = (): JSX.Element => {
  const client = useApolloClient();
  const [logoutMutation] = useMutation(LOGOUT_MUTATION);

  useEffect(() => {
    client.clearStore().then((): void => {
      client.writeQuery({
        query: gql`
          query {
            contentShown
          }
        `,
        data: {
          contentShown: false,
        },
      });
    });
    logoutMutation({ variables: {} }).then(({ data }): void => {
      if (data.logout.errors !== null) {
        logger.error('logout', ['Unable to logout', data.logout.errors]);
        return;
      }
    });
  }, []);

  return (
    <>
      <HeaderBar defaultHeader={true} />
      <StyledSection>
        <HeaderImage />
        <StyledMain>
          <Headline data-testid="registration-header">Registration Complete!</Headline>
          <Paragraph>
            We have received your registration. If applicable, please bring a copy of the documents below with you to
            your appointment.
          </Paragraph>
          <Bulletpoints>
            <li>Advanced Directive</li>
            <li>Medical Durable Power of Attorney</li>
            <li>Living Will</li>
          </Bulletpoints>
          <Paragraph data-testid="registration-questions">
            If you have any questions before your appointment, please contact your local centre.{' '}
          </Paragraph>
        </StyledMain>
      </StyledSection>
      <StyledFooter />
    </>
  );
};

export default RegistrationComplete;
