import { styled } from '@mui/system';

export const CardTitle = styled('div')`
  display: flex;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.main};
`;

export const CardBody = styled('div')`
  height: 300px;
  overflow: auto;
`;

export const CardBodyGroup = styled('div')`
  margin: 8px 16px;
  font-size: 14px;
  line-height: 20px;
`;

export const CardBodyBold = styled('div')`
  font-weight: bold;
`;

export const BubbleTitle = styled('div')`
  background-color: $[theme.palette.secondary.light};
  display: inline-block;
  line-height: 20px;
  border-radius: 16px;
  padding: 0 8px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: bold;
`;

export const CardText = styled('div')`
  font-size: 13px;
  line-height: 15px;
`;
