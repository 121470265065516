// eslint-disable-next-line no-use-before-define
import React from 'react';
import { GroupType } from 'react-select';

import { styled } from '@mui/system';
import { FilteredListOption } from './FilteredListDropdown';
interface Props {
  option?: FilteredListOption | GroupType<FilteredListOption>;
  isSelected: boolean;
  onClick: (label: string) => void;
}

const StyledListItem = styled('li')<{ isSelected: boolean }>`
  cursor: default;
  line-height: 20px;
  background-color: white;
  border-left-style: solid;
  border-width: thick;
  ${(props) => `
    font-weight: ${props.isSelected ? 'bold' : 'normal'};
    padding: ${props.isSelected ? '6px 0px 6px 8px' : '6px 12px 6px 8px'} ;
    &:hover {
      background-color: ${props.theme.palette.secondary.light};
      border-left-color: ${props.isSelected ? props.theme.palette.primary.dark : props.theme.palette.secondary.light};
    }
    border-left-color: ${props.isSelected ? props.theme.palette.primary.dark : 'white'};
    background-color: ${props.isSelected ? props.theme.palette.secondary.light : 'white'};
  `};
`;

export const FilterListItem = (props: Props): JSX.Element => {
  const { option, onClick, isSelected } = props;

  return (
    <StyledListItem value={option?.value} isSelected={isSelected} onClick={() => onClick(option?.label)}>
      {option?.label}
    </StyledListItem>
  );
};
