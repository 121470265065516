// eslint-disable-next-line no-use-before-define
import React from 'react';
import { FormBuilderComponentProps } from '../../FormBuilderInterfaces';
import { ToggleButtons } from 'shared-components/components/FormFields';
import { fieldMeetsConditions } from '../../common';

export interface TooltipListType {
  item: string;
}

interface ButtonOptions {
  label: string;
  value: string;
  tooltip?: {
    heading?: string;
    body?: string;
    list?: TooltipListType[];
  };
}

export default function FBToggleButtons(props: FormBuilderComponentProps): JSX.Element {
  const { component, options, formikProps, handleUpdate } = props;
  return (
    <ToggleButtons
      key={component.field.name}
      id={component.field.name}
      // name={component.field.name}// doesn't have it
      data-testid={component.field.name}
      fullWidth
      value={formikProps.values[component.field.name]}
      options={
        (options[component.field.listDataCategory] as ButtonOptions[]) ?? [{ text: 'No Options', value: 'NoOptions' }]
      }
      handleChange={(value) => {
        handleUpdate(formikProps, component, value);
        // ensure validation immediately appears on interaction with fields that update using onChange event, (otherwise will validate on blur)
        formikProps.handleBlur(component.field.name);
      }}
      disabled={fieldMeetsConditions(formikProps.values, component.disabled, false)}
      error={Object.keys(formikProps.errors).length > 0 && formikProps.submitCount > 0}
    />
  );
}
