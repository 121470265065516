import React, { useState, Fragment, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router';
import OuterContainer from '../PatientSummary/OuterContainer';
import { styled } from '@mui/system';
import { LabPanelType } from './Interfaces';
import ResultsTable from './ResultsTable';
import DetailTable from './DetailTable';
import { Card } from 'shared-components/components';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import moment from 'moment';
import { GET_PATIENT_LAB_PANELS } from '../PatientSummary/PatientProfileQueries';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { useErrorModalContext } from 'op-contexts';
import { ROPatientContextProvider } from '../PatientSummary/context';
import AddNotes from '../Notes/AddNotes';
import { LARGE_DISPLAY_SIZE } from 'shared-components/enums';
import { Grid, Stack, useTheme } from '@mui/material';
import { FindInPageOutlined as FindInPageOutlinedIcon } from '@mui/icons-material';

const StyledPageHeader = styled('h1')`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 24px;
  color: ${(props) => props.theme.palette.text.primary};
`;

const ResultsContainer = styled(Card)`
  height: 100%;
`;

const ContainerTitle = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Title = styled('h2')`
  font-size: 18px;
  margin: 0 0 18px;
`;

const Status = styled('span')`
  font-size: 18px;
  color: grey;
`;

const Date = styled('span')`
  font-size: ${getRemSize(16)};
`;

const NoDataContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const NoDataText = styled('span')`
  margin-top: 28px;
  font-size: ${getRemSize(18)};
  font-weight: 700;
  color: ${(props) => props.theme.palette.secondary.dark};
`;

const AddNotesStyled = styled(AddNotes)`
  &&& {
    margin-right: 16px;
  }
`;

const NO_DATA = 'No results to show';

interface ROPatientLabsNavType {
  id: string;
}

const ROPatientLabs = (): JSX.Element => {
  const windowSize = useWindowSize();
  const [activeResult, setActiveResult] = useState<LabPanelType | undefined>();
  const [activeDocument, setActiveDocument] = useState<string | undefined>();
  const isSmallDevice = windowSize.width < LARGE_DISPLAY_SIZE;
  const match = useRouteMatch<ROPatientLabsNavType>();
  const { id } = match.params;
  const { setError } = useErrorModalContext();
  const { loading, error, data } = useQuery(GET_PATIENT_LAB_PANELS, {
    variables: { patientId: id },
  });
  const theme = useTheme();

  useEffect(() => {
    if (error) return setError();
  }, [error]);

  if (loading) return <>Loading...</>;

  return (
    <ROPatientContextProvider>
      <OuterContainer>
        <Grid container sx={{ width: '100%', paddingLeft: '16px' }}>
          <Grid xs={isSmallDevice ? 4 : 3}>
            <Stack sx={{ height: '100%' }}>
              <StyledPageHeader id="patient-summary-header">Labs</StyledPageHeader>
              <Title>Results</Title>
              <ResultsContainer>
                <ResultsTable
                  data={data.labPanels}
                  setActiveResult={setActiveResult}
                  setActiveDocument={setActiveDocument}
                />
              </ResultsContainer>
            </Stack>
          </Grid>
          <Grid xs={isSmallDevice ? 7 : 6} sx={{ paddingLeft: '16px' }}>
            <Card className="d-flex flex-column" $filled>
              {activeResult && activeResult.observations?.length ? (
                <Fragment>
                  <ContainerTitle data-test-id="labs-results-table-header">
                    <Title>
                      {activeResult.panelName !== '' ? activeResult.panelName : 'No title available'}
                      <Status> ({activeResult.status})</Status>
                    </Title>
                    <Date>{moment(activeResult.observationDate).utc(false).format('D MMMM YYYY h:mm:ss A')}</Date>
                  </ContainerTitle>
                  <DetailTable
                    testId={'labs-results-table'}
                    activeResult={activeResult}
                    activeDocument={activeDocument}
                    setActiveDocument={setActiveDocument}
                  />
                </Fragment>
              ) : (
                <NoDataContainer>
                  <FindInPageOutlinedIcon fontSize="large" htmlColor={theme.palette.grey[600]} />
                  <NoDataText>{NO_DATA}</NoDataText>
                </NoDataContainer>
              )}
            </Card>
          </Grid>
          <Grid xs={isSmallDevice ? 1 : 3} sx={{ width: '100%' }}>
            <AddNotesStyled className="add-notes-styled" />
          </Grid>
        </Grid>
      </OuterContainer>
    </ROPatientContextProvider>
  );
};

export default ROPatientLabs;
