import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CsvDownloadButton from './CsvDownLoad';
import ChartDx from './ChartDx';
import ChartTumour from './ChartTumour';
import ChartCareplan from './ChartCareplan';
import ChartReferrals from './ChartReferrals';
interface ListDataType {
  count: number;
  label: string;
}

interface Props {
  listData: ListDataType[];
  tableName: string;
  dataSetLabel: string;
}

const DataListTable = (props: Props) => {
  const { listData, tableName, dataSetLabel } = props;
  const rows = listData.map((row) => {
    return;
  });
  return (
    <TableContainer
      component={Paper}
      sx={{ minHeight: 650, maxWidth: 650, margin: '0 auto', paddingTop: '15px' }}
      style={{ marginBottom: '100px' }}>
      <Grid container item justifyContent="space-between" sx={{ padding: '5px 15px' }}>
        <Typography variant="h3" sx={{ fontSize: '1.3rem' }}>
          <div style={{ minWidth: '200px', marginBottom: '10px' }}>{tableName}</div>
        </Typography>
        <div style={{ width: '300px' }}>
          <CsvDownloadButton content={listData} />
        </div>
      </Grid>
      <div style={{ height: '658px', marginTop: '10px' }}>
        {listData?.length > 0 && tableName === 'Referrals' && <ChartReferrals listData={listData} />}
        {listData?.length > 0 && tableName === 'Diagnosis trends' && <ChartDx listData={listData} />}
        {listData?.length > 0 && tableName === 'Prescriptions by tumour stream' && <ChartTumour listData={listData} />}
        {listData?.length > 0 && tableName === 'Careplan template usage' && <ChartCareplan listData={listData} />}
      </div>
      <Table sx={{ minWidth: 300, minHeight: 1000 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                fontWeight: 550,
              }}>
              Rank
            </TableCell>
            <TableCell align="left" sx={{ maxWidth: '100px', fontWeight: 550 }}>
              Count
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontWeight: 550,
              }}>
              {dataSetLabel}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflow: 'auto' }}>
          {listData.map((row, index) => {
            if (index > 9) return null;
            return (
              <TableRow key={row.label} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left" component="th" scope="row" sx={{ maxWidth: '100px' }}>
                  #{index + 1}
                </TableCell>
                <TableCell align="left" component="th" scope="row" sx={{ maxWidth: '100px' }}>
                  {row.count}
                </TableCell>
                <TableCell align="left">{row.label}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataListTable;
