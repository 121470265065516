// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { styled } from '@mui/system';

import { RODoseSiteCarousel } from 'op-components';
import { useErrorModalContext } from 'op-contexts';
import { groupBy } from 'op-utils';
import { GeneralError, LoadingSpinner } from 'shared-components/components';
import { GET_DOSE_SITE_SUMMARIES } from './PatientProfileQueries';
import { Stack } from '@mui/material';

interface ROPatientDoseNavType {
  id: string;
}

const StyledBoxHeader = styled('h3')`
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 2em;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.primary};
  padding-left: 15px;
  padding-bottom: 8px;
`;

const StyledWrapper = styled('div')`
  box-shadow: 0px 2px 8px rgba(113, 110, 106, 0.2);
  border-radius: 4px;
  max-height: 350px;
  height: calc(100% - 40px) !important;
  overflow: auto;
`;

const ROPatientDoseSiteSummary = (): JSX.Element => {
  const { setError } = useErrorModalContext();
  const match = useRouteMatch<ROPatientDoseNavType>();
  const [index, setIndex] = useState(0);
  const { id: patientId } = match.params;
  const { data, loading, error } = useQuery(GET_DOSE_SITE_SUMMARIES, {
    variables: { patientId },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (error) {
      return setError('NON_BLOCKING');
    }
  }, [error]);

  useEffect(() => {
    setIndex(0);
  }, [data]);

  if (!data && loading) return <LoadingSpinner loadingText={'Loading dose site summaries'} relativeSpinner={true} />;

  let container = (
    <StyledWrapper>
      {error && (
        <GeneralError
          primaryText="Unable to load dose site summaries."
          secondaryText="Please contact GenesisCare Innovation support."
        />
      )}
    </StyledWrapper>
  );
  let tracker;

  if (data) {
    const mqDoses = data.doseSiteSummaries.filter((dss: any) => !dss.isAria);
    const ariaDoses = data.doseSiteSummaries.filter((dss: any) => dss.isAria);
    const groupedDose = groupBy(ariaDoses, 'categorySiteGroup');
    const doseSize = mqDoses.length + Object.keys(groupedDose).length;
    const handleSelect = (selectedIndex: number) => {
      setIndex(selectedIndex % doseSize);
    };

    tracker = doseSize ? `${index + 1}/${doseSize}` : '';

    container = (
      <RODoseSiteCarousel
        handleSelect={handleSelect}
        index={index}
        mqDoses={mqDoses}
        ariaDoses={ariaDoses}
        doseSize={doseSize}
      />
    );
  }

  return (
    <Stack sx={{ height: '50%' }}>
      {window.innerWidth >= 1440 && (
        <StyledBoxHeader data-test-id="dose-site-summary-title">Dose site summary {tracker}</StyledBoxHeader>
      )}
      {container}
    </Stack>
  );
};

export default ROPatientDoseSiteSummary;
