// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import classNames from 'classnames';

import './LoadingSpinner.scss';

interface Props {
  loadingText?: string | null;
  relativeSpinner?: boolean;
  visible?: boolean;
  container?: boolean;
  subtitle?: string | null;
  isSmall?: boolean;
}

class LoadingSpinner extends Component<Props> {
  public static defaultProps = {
    loadingText: 'Loading page',
    relativeSpinner: false,
    visible: true,
    container: true,
    isSmall: false,
  };

  public render(): JSX.Element {
    const { loadingText, relativeSpinner, visible, container, subtitle, isSmall } = this.props;

    return (
      <div
        className={classNames({
          'spinner-container': container,
          'entire-viewport': !relativeSpinner,
          'loading-off': !visible,
        })}>
        <div className="spinner-inner-container">
          <div
            className="spinner-border"
            style={{
              height: isSmall ? '2rem' : '4rem',
              width: isSmall ? '2rem' : '4rem',
              borderWidth: isSmall ? '2px' : '5px',
            }}
            role="status">
            <div className="sr-only" />
          </div>
          <div data-test-id="loading-spinner" className="text-display" style={{ fontWeight: isSmall ? 700 : 'bold' }}>
            {loadingText?.split('\n').map(
              (line: string, index: number): JSX.Element => (
                <p key={index}>{line}</p>
              ),
            )}
          </div>
          {subtitle && <div className="loader-subtitle">{subtitle}</div>}
        </div>
      </div>
    );
  }
}

export default LoadingSpinner;
