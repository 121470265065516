// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';
import { StepperLink } from 'shared-components/interfaces';
import { Logger } from 'shared-components/utils';
import { FormContext } from '../../../pages/OP/PatientNavigation/context';
import { RegistrationContext } from 'op-contexts';
import { FormStatus } from 'op-enums';

import HANavigator from './HANavigator';
import { FeatureOption } from 'op-interfaces/PatientInterfaces';

const logger = new Logger('HANavigatorApollo');

const HA_SUBMISSION_MUTATION = gql`
  mutation CreateHASubmission($haId: ID!) {
    createHASubmission(haId: $haId) {
      submissionCreated
      submission {
        id
        pdf
      }
    }
  }
`;

const HEALTH_ASSESSMENT_AND_USER_DETAILS = gql`
  query UserDetailsAndHealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
    }
    patient(id: $patientId) {
      id
      regFormStatus
      userProfile {
        id
        showRegistration
      }
    }
    user {
      id
      isPso
    }
    profile(id: $patientId) {
      id
      registrationAccessType
    }

    featureOptions {
      id
      name
      description
      active
    }
  }
`;

const GET_PDF_URL = gql`
  mutation GenerateHAPDF($haId: ID!) {
    generateHAPdf(haId: $haId) {
      pdfLink
    }
  }
`;

interface CreateSubmissionMutationResponse {
  createHASubmission: {
    submission: {
      id: string;
      pdf: string;
    };
  };
}

interface CreatePDFLinkMutationResponse {
  generateHAPdf: {
    pdfLink: string;
  };
}

interface UserDetailsQueryData {
  user: {
    id: string;
    isPso: boolean;
  };
  patient: {
    regFormStatus: string;
    userProfile: {
      showRegistration: boolean;
    };
  };
  healthAssessment: {
    id: string;
  };
  profile: {
    id: string;
    registrationAccessType: string;
  };

  featureOptions: FeatureOption[];
}

interface Props extends WithApolloClient<{}>, RouteComponentProps<{ patientId: string }> {
  links: StepperLink[];
  generateURL?: () => void;
}

interface State {
  loadingMutation: boolean;
  mutationCalled: boolean;
  haId: string;
}

class HANavigatorApollo extends Component<Props, State> {
  public static contextType = FormContext;

  public constructor(props: Props) {
    super(props);

    this.state = {
      loadingMutation: false,
      mutationCalled: false,
      haId: '',
    };
  }

  public render(): JSX.Element {
    const { links } = this.props;
    const { loadingMutation, mutationCalled } = this.state;
    return (
      <Query
        query={HEALTH_ASSESSMENT_AND_USER_DETAILS}
        variables={{ patientId: this.props.match.params.patientId }}
        fetchPolicy="network-only">
        {({ data }: { data: UserDetailsQueryData }): JSX.Element => {
          if (data && data.healthAssessment && this.state.haId !== data.healthAssessment.id)
            this.setState({ haId: data.healthAssessment.id });
          const regFormStatus = data?.patient?.regFormStatus;
          const isInClinic = data?.profile?.registrationAccessType === 'inClinic';
          const showRegistration = data?.patient?.userProfile?.showRegistration;

          const activeNewRegoFeature = data?.featureOptions.find(
            (featureOption: FeatureOption) => featureOption.name === 'NewAusRego' && featureOption.active,
          );
          const redirectToRegistraton =
            [FormStatus.REG_SUBMITTED as string, FormStatus.REG_REVIEW_REQUIRED as string].includes(regFormStatus) ||
            !showRegistration;
          return (
            <RegistrationContext.Consumer>
              {(registrationContext) => (
                <HANavigator
                  links={links}
                  redirectToRegistraton={redirectToRegistraton}
                  registrationContext={registrationContext}
                  loading={loadingMutation}
                  submitFunction={this.submitHA}
                  submitCalled={mutationCalled}
                  isInClinic={isInClinic}
                  isPso={data && data.user && data.user.isPso}
                  generateURL={this.generatePDFURL}
                  showNewRego={!!activeNewRegoFeature}
                />
              )}
            </RegistrationContext.Consumer>
          );
        }}
      </Query>
    );
  }

  private generatePDFURL = (): void => {
    const { client } = this.props;
    const { loadingMutation, mutationCalled, haId } = this.state;
    if (!loadingMutation && !mutationCalled) {
      this.setState({ loadingMutation: true }, (): void => {
        client
          ?.mutate({
            mutation: GET_PDF_URL,
            variables: {
              haId: haId,
            },
          })
          .then((response): void => {
            const url = `${response.data.generateHAPdf.pdfLink}`;
            const win = window.open(url, '_blank');
            if (win != null) {
              win.focus();
            }
            this.setState({ loadingMutation: false, mutationCalled: false });
          });
      });
    }
  };

  private submitHA = (): void => {
    const { client } = this.props;

    const { loadingMutation, mutationCalled, haId } = this.state;
    if (!loadingMutation && !mutationCalled) {
      this.setState({ loadingMutation: true }, (): void => {
        client
          ?.mutate({
            mutation: HA_SUBMISSION_MUTATION,
            variables: {
              haId: haId,
            },
          })
          .then((response): void => {
            if (response?.data?.createHASubmission?.submission) {
              logger.debug(
                'submitHA',
                'The pdf has been loaded in: ',
                `${window.location.origin}/server/media/${response.data.createHASubmission.submission.pdf}`,
              );
            }
            this.setState({ loadingMutation: false, mutationCalled: true });
            //@ts-ignore
            this.context.setFormStatusDetails({
              //@ts-ignore
              ...this.context.formStatusDetails,
              healthAssessment: { status: 'filterSubmittedToMosaiq' },
            });
          });
      });
    }
  };
}

const routeComponent = withRouter(withApollo<Props>(HANavigatorApollo));
export default routeComponent;
