import React, { useMemo } from 'react';
import { styled } from '@mui/system';

import { PatientTooltip } from 'op-interfaces';
import RecentPatientBlock from './RecentPatientBlock';

const StyledRecentPatientsContainer = styled('div')`
  padding-top: 8px;
  font-style: normal;
  font-weight: 400;
`;

const StyledSectionHeader = styled('div')`
  padding-left: 16px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.secondary.dark};
`;

const StyledContent = styled('div')`
  max-width: 240px;
`;

const StyledPlaceholder = styled('div')`
  padding: 16px;
`;

interface Props {
  data: PatientTooltip[];
  hasPractitionerId: boolean;
  limit: number;
  showTitle?: boolean;
}

const RecentPatientSection = ({ data, hasPractitionerId, limit, showTitle }: Props): JSX.Element => {
  const componentId = 'recent-patient-section';
  const placeholderMessage = hasPractitionerId
    ? 'No recent patients available because you have not visited any patient summaries.'
    : 'Your account setup appears to be incomplete or missing information. Please contact GenesisCare Innovation support.';
  const patients = useMemo(() => data.slice(0, limit), [data]);

  return (
    <StyledRecentPatientsContainer>
      {showTitle && <StyledSectionHeader>Last {limit} patients</StyledSectionHeader>}
      <StyledContent>
        {patients.length > 0 ? (
          patients.map((patient: PatientTooltip, i: number) => {
            return (
              <RecentPatientBlock
                key={`${componentId}-content-${i}`}
                parentComponentID={componentId}
                patient={patient}
              />
            );
          })
        ) : (
          <StyledPlaceholder>{placeholderMessage}</StyledPlaceholder>
        )}
      </StyledContent>
    </StyledRecentPatientsContainer>
  );
};

RecentPatientSection.defaultProps = {
  showTitle: true,
  limit: 5,
};

export default RecentPatientSection;
