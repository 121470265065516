// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import { MoreInfoText, PageTitle } from 'op-components';
import { AccordionCheckboxItem, HAMonthYear } from 'op-pages/OP/HealthAssessment/HASharedComponents';
import { HA_SOCIAL_US_QUERY, UPDATE_HA_SOCIAL_US } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import { HASocialUSItem, StreetDrugsObject } from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import React from 'react';

import { useParams } from 'react-router-dom';
import { DropDownField, FreeTextField, SectionField, SegmentedInput } from 'shared-components/components/FormFields';
import { ListData } from 'shared-components/interfaces';
import { SegmentedInputBoolean } from 'shared-components/utils';
import { styled } from '@mui/system';
import { SOCIAL_US_FIELDS } from '../constants';

const CheckboxContainer = styled('section')`
  background-color: $[theme.palette.secondary.light};
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const OtherDrugsHeading = styled('section')`
  color: grey;
  font-weight: bold;
  margin-bottom: 10px;
`;

const SmokingHistoryUom = styled('section')`
  display: inline;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
`;

const SubHeading = styled('section')`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const HASocialUS = (): JSX.Element => {
  const { patientId } = useParams<{ patientId: string }>();

  const { data, error } = useQuery(HA_SOCIAL_US_QUERY, {
    variables: { patientId },
  });

  const [updateHaSocialUS, { loading }] = useMutation(UPDATE_HA_SOCIAL_US);

  if (error || !data?.healthAssessment) return <></>;

  const healthAssessment: HASocialUSItem = data.healthAssessment;
  const smokingFrequencyRefData = data.smokingFrequencyRefData;
  const alcoholFrequencyRefData = data.alcoholFrequencyRefData;
  const streetDrugsRefData = data.streetDrugsRefData;
  const streetDrugsObject: Partial<StreetDrugsObject> = JSON.parse(data.healthAssessment.streetDrugsUse || '{}');
  const haId = healthAssessment.id;

  const sortedSmokingRefData = [...smokingFrequencyRefData].sort((a: ListData, b: ListData) =>
    a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }),
  );

  const updateField = (field: string, value: string | boolean) => {
    updateHaSocialUS({ variables: { haId, patientId, [field]: value } });
  };

  const updateStreetDrugs = (option: ListData, value: boolean | string) => {
    //@ts-ignore
    streetDrugsObject[option.name] = { id: option.id, value, name: option.name };

    if (option.name === 'OtherDrugs') {
      streetDrugsObject.OtherDrugsDescription = { id: '-1', name: 'OtherDrugsDescription', value: '' };
    }

    updateHaSocialUS({ variables: { haId, patientId, streetDrugsUse: JSON.stringify(streetDrugsObject) } });
  };

  const renderStreetDrugsCheckboxes = () =>
    [...streetDrugsRefData].map((option: ListData) => (
      <AccordionCheckboxItem
        key={option.name}
        disabled={loading}
        label={option.name}
        value={
          //@ts-ignore
          streetDrugsObject[option.name]?.value
        }
        onClick={(value: boolean) => updateStreetDrugs(option, value)}
      />
    ));

  return (
    <>
      <PageTitle title={'Social assessment'} idPrefix="ha" />
      <div id="ha-fields" className="ha-social-assessment-us">
        <SectionField htmlFor={SOCIAL_US_FIELDS.SMOKING_BOOL.NAME} title={SOCIAL_US_FIELDS.SMOKING_BOOL.TITLE}>
          <SegmentedInput
            fieldName={SOCIAL_US_FIELDS.SMOKING_BOOL.NAME}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={healthAssessment.smokingBool}
            itemSelected={(selectedItem): void => {
              const selectedValue = selectedItem as boolean;
              updateField('smokingBool', selectedValue);
            }}
          />
        </SectionField>

        {healthAssessment.smokingBool && (
          <>
            <SectionField
              htmlFor={SOCIAL_US_FIELDS.SMOKING_HISTORY.NAME}
              title={SOCIAL_US_FIELDS.SMOKING_HISTORY.TITLE}>
              <MoreInfoText moreInfoText={SOCIAL_US_FIELDS.SMOKING_HISTORY.MORE_INFO}>
                <FreeTextField
                  inputName={SOCIAL_US_FIELDS.SMOKING_HISTORY.NAME}
                  inputType="number"
                  defaultValue={healthAssessment.smokingHistory}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    updateField('smokingHistory', e.target.value);
                  }}
                  inputStyle={{ width: '80px' }}
                />
                <SmokingHistoryUom>years</SmokingHistoryUom>
              </MoreInfoText>
            </SectionField>

            <SectionField
              htmlFor={SOCIAL_US_FIELDS.SMOKING_FREQUENCY.NAME}
              title={SOCIAL_US_FIELDS.SMOKING_FREQUENCY.TITLE}>
              <DropDownField
                inputName={SOCIAL_US_FIELDS.SMOKING_FREQUENCY.NAME}
                placeholder={'Please select'}
                options={sortedSmokingRefData}
                defaultValue={healthAssessment.smokingFrequency}
                controlled={true}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                  updateField('smokingFrequency', e.target.value);
                }}
              />
            </SectionField>
            <SectionField htmlFor={SOCIAL_US_FIELDS.SMOKING_STOP.NAME} title={SOCIAL_US_FIELDS.SMOKING_STOP.TITLE}>
              <MoreInfoText moreInfoText={SOCIAL_US_FIELDS.SMOKING_STOP.MORE_INFO}>
                <HAMonthYear
                  monthValue={healthAssessment.smokingStopRawMonth}
                  monthField="smokingStopRawMonth"
                  yearValue={healthAssessment.smokingStopRawYear}
                  yearField="smokingStopRawYear"
                  onChange={updateField}
                />
              </MoreInfoText>
            </SectionField>
          </>
        )}

        <SectionField htmlFor={SOCIAL_US_FIELDS.TOBACCO_BOOL.NAME} title={SOCIAL_US_FIELDS.TOBACCO_BOOL.TITLE}>
          <SegmentedInput
            fieldName={SOCIAL_US_FIELDS.TOBACCO_BOOL.NAME}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={healthAssessment.tobaccoChew}
            itemSelected={(selectedItem): void => {
              const selectedValue = selectedItem as boolean;
              updateField('tobaccoChew', selectedValue);
            }}
          />
        </SectionField>

        {healthAssessment.tobaccoChew && (
          <>
            <SectionField
              htmlFor={SOCIAL_US_FIELDS.TOBACCO_HISTORY.NAME}
              title={SOCIAL_US_FIELDS.TOBACCO_HISTORY.TITLE}>
              <FreeTextField
                inputName={SOCIAL_US_FIELDS.TOBACCO_HISTORY.NAME}
                inputType="text"
                defaultValue={healthAssessment.tobaccoChewHistory}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateField('tobaccoChewHistory', e.target.value);
                }}
              />
            </SectionField>

            <SectionField htmlFor={SOCIAL_US_FIELDS.TOBACCO_STOP.NAME} title={SOCIAL_US_FIELDS.TOBACCO_STOP.TITLE}>
              <MoreInfoText moreInfoText={SOCIAL_US_FIELDS.TOBACCO_STOP.MORE_INFO}>
                <HAMonthYear
                  monthValue={healthAssessment.tobaccoStopRawMonth}
                  monthField="tobaccoStopRawMonth"
                  yearValue={healthAssessment.tobaccoStopRawYear}
                  yearField="tobaccoStopRawYear"
                  onChange={updateField}
                />
              </MoreInfoText>
            </SectionField>

            <SectionField
              htmlFor={SOCIAL_US_FIELDS.TOBACCO_CLASSES.NAME}
              title={SOCIAL_US_FIELDS.TOBACCO_CLASSES.TITLE}>
              <MoreInfoText moreInfoText={SOCIAL_US_FIELDS.TOBACCO_CLASSES.MORE_INFO}>
                <HAMonthYear
                  monthValue={healthAssessment.tobaccoStopClassesRawMonth}
                  monthField="tobaccoStopClassesRawMonth"
                  yearValue={healthAssessment.tobaccoStopClassesRawYear}
                  yearField="tobaccoStopClassesRawYear"
                  onChange={updateField}
                />
              </MoreInfoText>
            </SectionField>
          </>
        )}

        <SectionField htmlFor={SOCIAL_US_FIELDS.ALCOHOL_BOOL.NAME} title={SOCIAL_US_FIELDS.ALCOHOL_BOOL.TITLE}>
          <SegmentedInput
            fieldName={SOCIAL_US_FIELDS.ALCOHOL_BOOL.NAME}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={healthAssessment.alcoholBool}
            itemSelected={(selectedItem): void => {
              const selectedValue = selectedItem as boolean;
              updateField('alcoholBool', selectedValue);
            }}
          />
        </SectionField>

        {healthAssessment.alcoholBool && (
          <>
            <SectionField
              htmlFor={SOCIAL_US_FIELDS.ALCOHOL_FREQUENCY.NAME}
              title={SOCIAL_US_FIELDS.ALCOHOL_FREQUENCY.TITLE}>
              <DropDownField
                inputName={SOCIAL_US_FIELDS.ALCOHOL_FREQUENCY.NAME}
                placeholder={'Please select'}
                options={alcoholFrequencyRefData}
                defaultValue={healthAssessment.alcoholFrequency}
                controlled={true}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                  updateField('alcoholFrequency', e.target.value);
                }}
              />
            </SectionField>

            <SectionField htmlFor={SOCIAL_US_FIELDS.ALCOHOL_STOP.NAME} title={SOCIAL_US_FIELDS.ALCOHOL_STOP.TITLE}>
              <MoreInfoText moreInfoText={SOCIAL_US_FIELDS.ALCOHOL_STOP.MORE_INFO}>
                <HAMonthYear
                  monthValue={healthAssessment.alcoholStopRawMonth}
                  monthField="alcoholStopRawMonth"
                  yearValue={healthAssessment.alcoholStopRawYear}
                  yearField="alcoholStopRawYear"
                  onChange={updateField}
                />
              </MoreInfoText>
            </SectionField>
          </>
        )}

        <SectionField
          htmlFor={SOCIAL_US_FIELDS.STREET_DRUGS_BOOL.NAME}
          title={SOCIAL_US_FIELDS.STREET_DRUGS_BOOL.TITLE}>
          <SegmentedInput
            fieldName={SOCIAL_US_FIELDS.STREET_DRUGS_BOOL.NAME}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={healthAssessment.streetDrugs}
            itemSelected={(selectedItem): void => {
              const selectedValue = selectedItem as boolean;
              updateField('streetDrugs', selectedValue);
            }}
          />
        </SectionField>

        {healthAssessment.streetDrugs && (
          <>
            <SubHeading>Please describe which ones?</SubHeading>
            <CheckboxContainer>
              {renderStreetDrugsCheckboxes()}
              <AccordionCheckboxItem
                key="OtherDrugs"
                label="Other drugs"
                value={streetDrugsObject?.OtherDrugs?.value || false}
                onClick={(value: boolean) => updateStreetDrugs({ name: 'OtherDrugs', id: '-1' }, value)}>
                {streetDrugsObject?.OtherDrugs ? (
                  <>
                    <OtherDrugsHeading>Please describe other drugs</OtherDrugsHeading>
                    <FreeTextField
                      inputName="OtherDrugsDescription"
                      inputType="text"
                      defaultValue={streetDrugsObject?.OtherDrugsDescription?.value}
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        updateStreetDrugs({ name: 'OtherDrugsDescription', id: '-1' }, e.target.value);
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </AccordionCheckboxItem>
            </CheckboxContainer>
          </>
        )}

        <SectionField
          htmlFor={SOCIAL_US_FIELDS.MEDICAL_MARIJUANA_CARD_BOOL.NAME}
          title={SOCIAL_US_FIELDS.MEDICAL_MARIJUANA_CARD_BOOL.TITLE}>
          <SegmentedInput
            fieldName={SOCIAL_US_FIELDS.MEDICAL_MARIJUANA_CARD_BOOL.NAME}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={healthAssessment.medicalMarijuanaCard}
            itemSelected={(selectedItem): void => {
              const selectedValue = selectedItem as boolean;
              updateField('medicalMarijuanaCard', selectedValue);
            }}
          />
        </SectionField>

        <SectionField
          htmlFor={SOCIAL_US_FIELDS.SOCIAL_SUPPORT_SYSTEM_BOOL.NAME}
          title={SOCIAL_US_FIELDS.SOCIAL_SUPPORT_SYSTEM_BOOL.TITLE}>
          <SegmentedInput
            fieldName={SOCIAL_US_FIELDS.SOCIAL_SUPPORT_SYSTEM_BOOL.NAME}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={healthAssessment.socialSupportSystem}
            itemSelected={(selectedItem): void => {
              const selectedValue = selectedItem as boolean;
              updateField('socialSupportSystem', selectedValue);
            }}
          />
        </SectionField>

        {healthAssessment.socialSupportSystem && (
          <SectionField
            htmlFor={SOCIAL_US_FIELDS.SOCIAL_SUPPORT_SYSTEM.NAME}
            title={SOCIAL_US_FIELDS.SOCIAL_SUPPORT_SYSTEM.TITLE}>
            <FreeTextField
              inputName={SOCIAL_US_FIELDS.SOCIAL_SUPPORT_SYSTEM.NAME}
              inputType="text"
              defaultValue={healthAssessment.socialSupportPeople}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                updateField('socialSupportPeople', e.target.value);
              }}
            />
          </SectionField>
        )}

        <SectionField
          htmlFor={SOCIAL_US_FIELDS.STILL_WORKING_BOOL.NAME}
          title={SOCIAL_US_FIELDS.STILL_WORKING_BOOL.TITLE}>
          <SegmentedInput
            fieldName={SOCIAL_US_FIELDS.STILL_WORKING_BOOL.NAME}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={healthAssessment.stillWorking}
            itemSelected={(selectedItem): void => {
              const selectedValue = selectedItem as boolean;
              updateField('stillWorking', selectedValue);
            }}
          />
        </SectionField>

        {healthAssessment.stillWorking === false && (
          <SectionField htmlFor={SOCIAL_US_FIELDS.STILL_WORKING.NAME} title={SOCIAL_US_FIELDS.STILL_WORKING.TITLE}>
            <FreeTextField
              inputName={SOCIAL_US_FIELDS.STILL_WORKING.NAME}
              inputType="text"
              defaultValue={healthAssessment.stillWorkingReason}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                updateField('stillWorkingReason', e.target.value);
              }}
            />
          </SectionField>
        )}

        <SectionField
          htmlFor={SOCIAL_US_FIELDS.FINANCIAL_ASSISTANCE_BOOL.NAME}
          title={SOCIAL_US_FIELDS.FINANCIAL_ASSISTANCE_BOOL.TITLE}>
          <SegmentedInput
            fieldName={SOCIAL_US_FIELDS.FINANCIAL_ASSISTANCE_BOOL.NAME}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={healthAssessment.financialAssisstance}
            itemSelected={(selectedItem): void => {
              const selectedValue = selectedItem as boolean;
              updateField('financialAssisstance', selectedValue);
            }}
          />
        </SectionField>
        {healthAssessment.financialAssisstance && (
          <SectionField
            htmlFor={SOCIAL_US_FIELDS.FINANCIAL_ASSISTANCE_REASON.NAME}
            title={SOCIAL_US_FIELDS.FINANCIAL_ASSISTANCE_REASON.TITLE}>
            <FreeTextField
              inputName={SOCIAL_US_FIELDS.FINANCIAL_ASSISTANCE_REASON.NAME}
              inputType="text"
              defaultValue={healthAssessment.financialAssisstanceReason}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                updateField('financialAssisstanceReason', e.target.value);
              }}
            />
          </SectionField>
        )}

        <SectionField
          htmlFor={SOCIAL_US_FIELDS.DIFFICULTY_TRAVELLING_BOOL.NAME}
          title={SOCIAL_US_FIELDS.DIFFICULTY_TRAVELLING_BOOL.TITLE}>
          <SegmentedInput
            fieldName={SOCIAL_US_FIELDS.DIFFICULTY_TRAVELLING_BOOL.NAME}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={healthAssessment.difficultyTravellingBool}
            itemSelected={(selectedItem): void => {
              const selectedValue = selectedItem as boolean;
              updateField(SOCIAL_US_FIELDS.DIFFICULTY_TRAVELLING_BOOL.NAME, selectedValue);
            }}
          />
        </SectionField>

        {healthAssessment.difficultyTravellingBool && (
          <SectionField
            htmlFor={SOCIAL_US_FIELDS.DIFFICULTY_TRAVELLING.NAME}
            title={SOCIAL_US_FIELDS.DIFFICULTY_TRAVELLING.TITLE}>
            <FreeTextField
              inputName={SOCIAL_US_FIELDS.DIFFICULTY_TRAVELLING.NAME}
              inputType="text"
              defaultValue={healthAssessment.difficultyTravelling}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                updateField(SOCIAL_US_FIELDS.DIFFICULTY_TRAVELLING.NAME, e.target.value);
              }}
            />
          </SectionField>
        )}

        <SectionField
          htmlFor={SOCIAL_US_FIELDS.SERVED_IN_MILITARY_BOOL.NAME}
          title={SOCIAL_US_FIELDS.SERVED_IN_MILITARY_BOOL.TITLE}>
          <SegmentedInput
            fieldName={SOCIAL_US_FIELDS.SERVED_IN_MILITARY_BOOL.NAME}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={healthAssessment.servedInMilitary}
            itemSelected={(selectedItem): void => {
              const selectedValue = selectedItem as boolean;
              updateField('servedInMilitary', selectedValue);
            }}
          />
        </SectionField>

        {healthAssessment.servedInMilitary && (
          <SectionField
            htmlFor={SOCIAL_US_FIELDS.SERVED_IN_MILITARY.NAME}
            title={SOCIAL_US_FIELDS.SERVED_IN_MILITARY.TITLE}>
            <FreeTextField
              inputName={SOCIAL_US_FIELDS.SERVED_IN_MILITARY.NAME}
              inputType="text"
              defaultValue={healthAssessment.servedInMilitaryBranch}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                updateField('servedInMilitaryBranch', e.target.value);
              }}
            />
          </SectionField>
        )}

        <SectionField
          htmlFor={SOCIAL_US_FIELDS.PHYSICALLY_ACTIVE.NAME}
          title={SOCIAL_US_FIELDS.PHYSICALLY_ACTIVE.TITLE}>
          <SegmentedInput
            fieldName={SOCIAL_US_FIELDS.PHYSICALLY_ACTIVE.NAME}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={healthAssessment.physicallyActive}
            itemSelected={(selectedItem): void => {
              const selectedValue = selectedItem as boolean;
              updateField('physicallyActive', selectedValue);
            }}
          />
        </SectionField>

        {healthAssessment.physicallyActive && (
          <SectionField
            htmlFor={SOCIAL_US_FIELDS.PHYSICALLY_ACTIVE_EXERCISE.NAME}
            title={SOCIAL_US_FIELDS.PHYSICALLY_ACTIVE_EXERCISE.TITLE}>
            <FreeTextField
              inputName={SOCIAL_US_FIELDS.PHYSICALLY_ACTIVE_EXERCISE.NAME}
              inputType="text"
              defaultValue={healthAssessment.physicallyActiveExercise}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                updateField('physicallyActiveExercise', e.target.value);
              }}
            />
          </SectionField>
        )}

        <SectionField htmlFor={SOCIAL_US_FIELDS.CHEMICALS_BOOL.NAME} title={SOCIAL_US_FIELDS.CHEMICALS_BOOL.TITLE}>
          <SegmentedInput
            fieldName={SOCIAL_US_FIELDS.CHEMICALS_BOOL.NAME}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={healthAssessment.chemicals}
            itemSelected={(selectedItem): void => {
              const selectedValue = selectedItem as boolean;
              updateField('chemicals', selectedValue);
            }}
          />
        </SectionField>

        {healthAssessment.chemicals && (
          <SectionField htmlFor={SOCIAL_US_FIELDS.CHEMICALS.NAME} title={SOCIAL_US_FIELDS.CHEMICALS.TITLE}>
            <FreeTextField
              inputName={SOCIAL_US_FIELDS.CHEMICALS.NAME}
              inputType="text"
              defaultValue={healthAssessment.chemicalsExposed}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                updateField('chemicalsExposed', e.target.value);
              }}
            />
          </SectionField>
        )}

        <SectionField
          htmlFor={SOCIAL_US_FIELDS.RELIGIOUS_BELIEFS_BOOL.NAME}
          title={SOCIAL_US_FIELDS.RELIGIOUS_BELIEFS_BOOL.TITLE}>
          <SegmentedInput
            fieldName={SOCIAL_US_FIELDS.RELIGIOUS_BELIEFS_BOOL.NAME}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={healthAssessment.religiousBeliefs}
            itemSelected={(selectedItem): void => {
              const selectedValue = selectedItem as boolean;
              updateField('religiousBeliefs', selectedValue);
            }}
          />
        </SectionField>

        {healthAssessment.religiousBeliefs && (
          <SectionField
            htmlFor={SOCIAL_US_FIELDS.RELIGIOUS_BELIEFS.NAME}
            title={SOCIAL_US_FIELDS.RELIGIOUS_BELIEFS.TITLE}>
            <FreeTextField
              inputName={SOCIAL_US_FIELDS.RELIGIOUS_BELIEFS.NAME}
              inputType="text"
              defaultValue={healthAssessment.religiousBeliefsDescription}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                updateField('religiousBeliefsDescription', e.target.value);
              }}
            />
          </SectionField>
        )}
      </div>
    </>
  );
};

export default HASocialUS;
