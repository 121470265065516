import React from 'react';
import MuiPopper from '@mui/material/Popper';
import { Paper, Slide } from '@mui/material';
import { styled } from '@mui/system';
import { Button } from 'gc-ui';
import { CheckCircleOutlined, CloseOutlined } from '@mui/icons-material';

interface Props {
  open: boolean;
  anchorEl?: any;
  onCancel: () => void;
  onSave: () => void;
  saveDisabled?: boolean;
}

const StyledPaper = styled(Paper)`
  &.MuiPaper-outlined {
    border: 2px solid ${(props) => props.theme.palette.primary.main};
    position: relative;
    top: calc(100vh - 172px);
    border-radius: 8px;
    width: 327px;
    box-shadow: 0 0 8px lightgray;
  }
  .title {
    text-align: center;
    padding: 10px;
    color: gray;
  }
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 8px;
    button {
      :disabled {
        background-color: ${(props) => props.theme.palette.secondary.light};
      }
      span {
        display: flex;
        flex-direction: row;
        gap: 8px;
        fill: ${(props) => props.theme.palette.primary.main};
      }
    }
  }
  hr {
    padding: 0;
    margin: 0;
  }
`;

const SequencePopper = ({
  open,
  anchorEl = document.body,
  onSave,
  onCancel,
  saveDisabled = false,
}: Props): JSX.Element => {
  return (
    <MuiPopper open={open} placement={'bottom'} anchorEl={anchorEl} transition>
      <Slide direction="up" in={open} timeout={{ enter: 300, exit: 0 }}>
        <StyledPaper variant="outlined" elevation={3}>
          <div className="title">Sequence the drug before saving</div>
          <hr />
          <div className="actions">
            <Button mode="text" onClick={onCancel} size="auto" data-cy="add-supporting-drug-order-cancel">
              <CloseOutlined color="primary" />
              <div>Cancel</div>
            </Button>
            <Button
              mode="text"
              onClick={onSave}
              disabled={saveDisabled}
              size="auto"
              data-cy="add-supporting-drug-order-confirm">
              <CheckCircleOutlined color={saveDisabled ? 'inherit' : 'primary'} />
              <div>Save Drug</div>
            </Button>
          </div>
        </StyledPaper>
      </Slide>
    </MuiPopper>
  );
};

export default SequencePopper;
