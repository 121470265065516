// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'gc-ui';
import { styled } from '@mui/system';

interface Props {
  headerText?: string;
  icon?: JSX.Element;
  headerElement?: JSX.Element;
  bodyElements: JSX.Element;
  footerElements?: JSX.Element;
  isOpen: boolean;
  dismissFunction: (e?: any) => void;
  className?: string;
  maxWidth?: string;
  closeOnClickOutside?: boolean | 'static';
  centered?: boolean;
}

const ModalStyled = styled(Modal)<{ $maxWidth: string }>`
  &&& {
    height: calc(100vh - 3.5rem);
    max-width: ${(props) => props.$maxWidth};
    .modal-content {
      max-height: 100%;
    }
  }
`;

const BodyStyled = styled(ModalBody)`
  &&& {
    && p {
      margin: 8px 0;
    }
    && h2 {
      color: ${(props) => props.theme.palette.primary.main};
      margin: 8px 0;
    }
    && ul {
      margin-left: 0;
      list-style: none;
      li::before {
        margin-left: 0;
        content: '\\2022';
        width: 1em;
        font-size: 32px;
        font-weight: bold;
        vertical-align: middle;
        color: ${(props) => props.theme.palette.primary.main};
      }
      li {
        margin-bottom: 8px;
      }
    }
    padding: 0px 48px 32px 48px;
    line-height: 1.5rem;

    max-height: calc(100vh - 2rem);
    overflow: auto;
  }
`;

const HeaderIconStyled = styled('span')`
  &&& {
    margin-left: 8px;
  }
`;
const HeaderStyled = styled(ModalHeader)`
  &&& {
    padding: 48px 48px 16px 48px;
    border: none;
    && h5 {
      font-weight: 400;
      font-size: 24px;
      margin-right: 24px;
    }
  }
`;

const FooterStyled = styled(ModalFooter)`
  &&& {
    padding: 1.5rem 0.75rem;
  }
`;

const GenericModal = (props: Props) => {
  const {
    headerText,
    icon,
    headerElement,
    isOpen,
    dismissFunction,
    bodyElements,
    footerElements,
    className,
    maxWidth = '100%',
    closeOnClickOutside = true,
    centered = true,
  } = props;

  return (
    <ModalStyled
      $maxWidth={maxWidth}
      open={isOpen}
      onClose={dismissFunction}
      className={`${className}`}
      centered={centered}
      backdrop={closeOnClickOutside}
      sx={{
        '.MuiPaper-root': {
          maxWidth: '1200px',
        },
      }}>
      {headerElement}
      <HeaderStyled>
        {headerText} <HeaderIconStyled>{icon}</HeaderIconStyled>
      </HeaderStyled>
      <BodyStyled>{bodyElements}</BodyStyled>
      <FooterStyled>{footerElements}</FooterStyled>
    </ModalStyled>
  );
};

export default GenericModal;
