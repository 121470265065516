// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import classNames from 'classnames';
import GCButton from 'shared-components/components/FormFields/GCButton/GCButton';
import { GCButtonSize } from 'shared-components/enums';

import './ButtonRO.scss';

import { ButtonMode, ButtonType } from 'shared-components/enums';

interface Props {
  id?: string;
  title: string;
  type: ButtonType;
  name?: string;
  loading?: boolean;
  inputType?: 'submit' | 'button' | 'reset';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleHover?: () => void;
  disabled?: boolean;
  buttonSize: GCButtonSize;
  mode?: string;
  dataTestId?: string;
  backgroundColor?: string;
}

/**
 * RO styled button component.
 */

class ButtonRO extends Component<Props> {
  public static defaultProps = {
    type: ButtonType.WHITE,
    buttonSize: GCButtonSize.REGULAR,
    mode: ButtonMode.NORMAL,
  };

  public render(): JSX.Element {
    return (
      <div
        className={classNames('ro-button-style', {
          'ro-create-button': this.props.mode === ButtonMode.CREATE,
          'ro-remove-button': this.props.mode === ButtonMode.REMOVE,
          'ro-disabled-button': this.props.disabled,
          'ro-green-button': this.props.type === ButtonType.GREEN,
        })}>
        <GCButton {...this.props} />
      </div>
    );
  }
}

export default ButtonRO;
