import moment from 'moment';
import { Region } from 'shared-components/enums';
import { ListData, SelectOptions } from 'shared-components/interfaces';
import { DistressThermometerVersion } from 'op-enums';
import { PatientNote } from 'op-components/RO/Notes/Notes';

export const mapListData = (listData: ListData[]): SelectOptions[] =>
  listData?.map((data: ListData) => {
    return { label: data.name, value: data.name };
  }) || [];

export const sortRefDataById = (a: ListData, b: ListData) => {
  /**
   * This is a comparison function to be used with the sort() function for a ListData
   */
  let returnVal = 0;
  if (a.id > b.id) {
    returnVal = 1;
  } else if (a.id < b.id) {
    returnVal = -1;
  }
  return returnVal;
};

export const snakeToCamel = (str: string): string => {
  return str.replace(/([-_][a-z])/g, (group: string): string => {
    return group.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const isEmpty = (str: string): boolean => {
  return !str || 0 === str.length;
};

export const isBlank = (str: string): boolean => {
  return !str || /^\s*$/.test(str);
};

export const groupBy = (items: any, key: any) =>
  items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

// Specific rounding approach that is only used for ARIA DSS
export const ariaRounding = (value: any) => {
  // Business decision to have 1 decimal point
  /*
    Reading from the database can lead to floating point precision problems.
    This hasn been experienced at the Python level directly after the query.
    A db value of 25.25 being converted to 22.250000000000007
    Rounding that value to 1 dp will result in 22.3
    If the inaccuracy causes the value to become 22.249999999... then rounding to 1 dp
    will be 22.2. This isn't ideal because the value should be 22.3!

    Introducing an anchor point of accuracy such as 3 dp then using that value to round
    to 1 dp will improve rounding and mitigate a signifcant portion of the impact that
    floating point numbers bring

    Justification for 3dp over 2dp:
    3dp: 22.2455 --> 22.246 --> 22.2 = Accurate
    2dp: 22.2455 --> 22.25 --> 22.3 = Inaccurate

    This approach can increase the confidence in rounding
    the correct number even with inaccurate precision
  */
  const roundFloatingPointIssue = _round(value, 3);
  return _round(roundFloatingPointIssue, 1);
};

const _round = (value: any, precision: any) => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const wrapURLs = (text: string, newWindow: boolean): string => {
  const regex = /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi;

  return (text || '').replace(regex, (match, space, url) => {
    let hyperlink = url;

    if (!hyperlink.match('^https?://')) {
      hyperlink = 'http://' + hyperlink;
    }

    return space + `<a href="${hyperlink}" ${newWindow ? 'target="_blank"' : ''}>${url}</a>`;
  });
};

export const generateGreetingText = (): string => {
  const currHour = parseInt(moment().format('H'));
  let greeting = 'Good evening,';

  if (currHour >= 4 && currHour <= 11) {
    greeting = 'Good morning,';
  } else if (currHour > 11 && currHour <= 16) {
    greeting = 'Good afternoon,';
  }

  return greeting;
};

export const isUs = (): boolean => import.meta.env.REACT_APP_REGION === Region.US;
// Used to configure the app to use the demo features
export const isDemo: boolean = import.meta.env.REACT_APP_REGION === Region.DEMO;

export const appendZeroInFront = (valueToPad: string): string =>
  valueToPad?.length === 1 ? `0${valueToPad}` : valueToPad;

export const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const TO_FORM_DISTRESS_EOT = 'Distress Thermometer EOT';

export const toFormToVersion = (toForm: string): string => {
  if (toForm === TO_FORM_DISTRESS_EOT) return DistressThermometerVersion.EOT;
  return DistressThermometerVersion.INITIAL;
};

/**
 * Generates a formatted creation date (Prioritizes approvedAt/editedAt dates over createdAt) string for a patient note.
 *
 * @param {PatientNote} note - Instance of a note
 * @param {string} timezone - Practitioner's timezone for formatting the timestamp.
 * @param {string} format - Date formatting
 * @returns {string} Formatted creation date(approvedAt > editedAt > createdAt)
 */
export const formatNoteCreationDate = (note: PatientNote, timezone: string, format: string): string => {
  const noteDateTime = moment(note.approvedAt).isValid()
    ? moment(note.approvedAt)
    : moment(note.editedAt).isValid()
    ? moment(note.editedAt)
    : moment(note.createdAt);
  return moment(noteDateTime).tz(timezone).format(format).toString();
};
