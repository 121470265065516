import { useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { SMS_LOGS_PAGINATED } from './SmsLogsQueries';
import moment from 'moment';
import './smsLogs.scss';
import Pagination from 'shared-components/components/Pagination/Pagination';
import { LoadingSpinner } from 'shared-components/components';
import { SmsLogQueryData, SmsLogData } from './smsLogs.types';
import { MESSAGE_ERROR, STATUSES, DEPARTMENTS, SMS_TYPES } from './SmsLogsConstants';
import SmsLogsSearchFilter from './SmsLogsSearchFilter';
import classNames from 'classnames';
import { Table, TableBody, TableHead, TableCell, TableRow, styled, tableCellClasses } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
interface ISmsLogQuery {
  page: number;
  locationFilter: string[];
  smsTypeFilter: string[];
  statusFilter: string[];
}

export const tableHeaders = [
  { title: 'Patient Name', width: 17.89 },
  { title: 'Appointment', width: 13.16 },
  { title: 'SMS type', width: 12.32 },
  { title: 'Activity', width: 11.31 },
  { title: 'Department', width: 13.08 },
  { title: 'Doctor', width: 10.8 },
  { title: 'Status', width: 10.55 },
  { title: 'Sent', width: 10.89 },
];

const getFromStorage = (item: string) => JSON.parse(sessionStorage.getItem(item) || '[]');
const setInStorage = (item: string, value: string[]) => sessionStorage.setItem(item, JSON.stringify(value));

const renderLoadingIcon = () => {
  return (
    <div className="loading-icon">
      <LoadingSpinner />
    </div>
  );
};

const renderPatientDetails = (rowData: SmsLogData) => {
  const renderId = 'patient-details-section';
  const displayDob = moment(rowData.patientDob).format('DD MMM YYYY');
  return (
    <>
      <Link to={`/navigator/patient/${rowData.patientId}/summary`}>{rowData.patientFullName}</Link>
      <div className="minor-text">
        <span className={`${renderId}-patient-id`}>Patient ID: {rowData.patientId}</span>
        <br />
        <span className={`${renderId}-dob`}>Date of birth: {displayDob}</span>
      </div>
    </>
  );
};

const renderFormattedTime = (givenTime: string) => {
  const calendarFormat = moment(givenTime).calendar();
  const splitCalendarFormattedDate = calendarFormat.split(' ');

  const isTodayOrYesterday = splitCalendarFormattedDate[0] === 'Today' || splitCalendarFormattedDate[0] === 'Yesterday';
  return (
    <div>
      <span>{isTodayOrYesterday ? splitCalendarFormattedDate[0] : moment(givenTime).format('DD-MMM-YYYY')}</span>
      <br />

      <span>{moment(givenTime).format('LT')} </span>
    </div>
  );
};

const renderSmsSentStatus = (form: SmsLogData) => {
  return (
    <>
      {form.success ? (
        <>
          <span className="success"></span> <span>Sent</span>
        </>
      ) : (
        <>
          <span className="fail"></span> <span>Failed</span>
        </>
      )}
    </>
  );
};

const SmsLogs = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTotalPage, setCurrentTotalPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(0);
  const resetCurrentPage = () => setCurrentPage(1);
  const resetSelectedRow = () => setSelectedRow(0);

  const [selectedDepartments, setSelectedDepartments] = useState(getFromStorage(DEPARTMENTS));
  const [selectedSmsType, setSelectedSmsType] = useState(getFromStorage(SMS_TYPES));
  const [selectedStatus, setSelectedStatus] = useState(getFromStorage(STATUSES));

  useEffect(() => {
    resetCurrentPage();
    resetSelectedRow();
  }, [selectedDepartments, selectedSmsType, selectedStatus]);

  useEffect(() => {
    setInStorage(DEPARTMENTS, selectedDepartments);
  }, [selectedDepartments]);
  useEffect(() => {
    setInStorage(SMS_TYPES, selectedSmsType);
  }, [selectedSmsType]);
  useEffect(() => {
    setInStorage(STATUSES, selectedStatus);
  }, [selectedStatus]);

  const loadCurrentPage = (data: SmsLogQueryData) => {
    resetSelectedRow();
    data && setCurrentTotalPage(data.totalSmsLogPages);
  };
  const { data, loading, refetch } = useQuery<SmsLogQueryData, ISmsLogQuery>(SMS_LOGS_PAGINATED, {
    variables: {
      page: currentPage,
      locationFilter: selectedDepartments,
      smsTypeFilter: selectedSmsType,
      statusFilter: selectedStatus,
    },
    onCompleted: loadCurrentPage,
  });

  const scrollToTop = () => {
    const rows = document.querySelectorAll('#buzz-log-table tr');
    rows[1].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const onPageChange = (newPage: number) => {
    refetch({
      page: newPage,
      locationFilter: selectedDepartments,
      smsTypeFilter: selectedSmsType,
      statusFilter: selectedStatus,
    }).finally(() => scrollToTop());
    setCurrentPage(newPage);
  };

  const renderTableData = (paginatedSmsLogs: SmsLogData[]) => {
    return paginatedSmsLogs.map((form: SmsLogData, index: number): JSX.Element => {
      const isSelectedRow = index === selectedRow;
      const className = isSelectedRow ? 'selected-row' : undefined;
      return (
        <StyledTableRow
          key={index}
          className="buzz-table-row"
          id={`buzz-table-row-${index}`}
          onClick={() => setSelectedRow(index)}>
          <TableCell className={classNames(className, 'patient-name-col')}>{renderPatientDetails(form)}</TableCell>
          <TableCell className={classNames(className, 'appointment-dttm-col')}>
            {renderFormattedTime(form.bookingDttm)}
          </TableCell>
          <TableCell className={classNames(className, 'sms-type-col')}>
            <span>{form.templateName}</span>
          </TableCell>
          <TableCell className={classNames(className, 'activity-col')}>{form.activity}</TableCell>
          <TableCell className={classNames(className, 'department-col')}>{form.department}</TableCell>
          <TableCell className={classNames(className, 'doctor-col')}>{form.practitionerName}</TableCell>
          <TableCell className={classNames(className, 'sms-status-col')}>{renderSmsSentStatus(form)}</TableCell>
          <TableCell className={classNames(className, 'sent-col')}>
            <div style={{ textAlign: 'right' }}>{renderFormattedTime(form.sentDttm)}</div>
          </TableCell>
        </StyledTableRow>
      );
    });
  };

  const renderMessageDisplay = () => {
    const message = data?.paginatedSmsLogs[selectedRow]?.message;
    const hasError = data?.paginatedSmsLogs[selectedRow]?.success === false;
    return (
      <div className="message-container">
        <div className="message-header">Message</div>
        <div className="message-body">{message}</div>
        {hasError && <div className="message-error">{MESSAGE_ERROR}</div>}
      </div>
    );
  };

  const renderTable = () => (
    <div className="buzz-log-table-container">
      <Table className="buzz-log-table" id="buzz-log-table">
        <TableHead>
          <TableRow>
            {tableHeaders.map((header: { title: string; width: number }): JSX.Element => {
              const title = header.title;
              const width = header.width;
              return (
                <StyledTableCell key={title} id={title} style={{ width: `${width}%` }}>
                  {title === 'Sent' ? <div style={{ textAlign: 'right' }}>{title}</div> : title}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {!loading && data && data.paginatedSmsLogs.length > 0 && (
          <TableBody>{renderTableData(data.paginatedSmsLogs)}</TableBody>
        )}
      </Table>
    </div>
  );

  return (
    <div className="sms-logs-container">
      <div className="table-container">
        <div className="filter-title-container">
          <div className="buzz-table-title">Buzz SMS</div>
          <div className="title-info">This dashboard only displays SMS sent within the last 30 days</div>
          <SmsLogsSearchFilter
            selectedDepartments={selectedDepartments}
            selectedSmsType={selectedSmsType}
            selectedStatus={selectedStatus}
            setSelectedDepartments={setSelectedDepartments}
            setSelectedSmsType={setSelectedSmsType}
            setSelectedStatus={setSelectedStatus}
          />
        </div>
        {renderTable()}
        {loading && renderLoadingIcon()}
        {!loading && data && data.paginatedSmsLogs?.length < 1 && (
          <div className="no-patients">No results to display</div>
        )}
        {!loading && data && data.totalSmsLogPages > 1 && (
          <Pagination totalPages={currentTotalPage} currentPage={currentPage} onPageChange={onPageChange} />
        )}
      </div>
      {renderMessageDisplay()}
    </div>
  );
};

export default withRouter(SmsLogs);
