import React from 'react';
import { styled } from '@mui/system';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { ArrowBack } from 'shared-components/images';
import { CAREPLAN_STATUS, getSiteRepresentation } from 'op-pages/RO/Careplan/Common';
import { SiteGroupSet } from 'op-pages/RO/Careplan/Interface';
import { CustomTheme, useTheme } from '@mui/material';

export const StyledQuickAccessRibbon = styled('div')`
  width: 100%;
  height: 24px;
  color: ${(props) => props.theme.palette.secondary.dark};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  background-color: white;
  border: 1px ${(props) => props.theme.palette.secondary.main};
  border-style: solid none;
  margin-bottom: 15px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
`;

export const StyledTitle = styled('span')`
  font-size: ${getRemSize(14)};
  color: ${(props) => props.theme.palette.info.main};
  font-weight: bold;
  padding-left: 12px;
`;

export const StyledArrow = styled(ArrowBack)`
  color: ${(props) => props.theme.palette.info.main};
  path {
    fill: ${(props) => props.theme.palette.info.main};
    fill-opacity: 1;
  }
  height: 20px;
  width: 20px;
`;

export const generateCareplanName = (siteGroupsArray: SiteGroupSet[], separator?: string): string => {
  const orderedSiteGroupSet = [...siteGroupsArray].sort(
    (a, b) => parseFloat(a.orderRanking) - parseFloat(b.orderRanking),
  );
  const careplanNameList = orderedSiteGroupSet.map((siteGroup: any) => getSiteGroupRepresentation(siteGroup));
  return careplanNameList.join(separator ?? ', ');
};

export const getCareplanStatusColor = (status: string): string => {
  const theme: CustomTheme = useTheme();
  switch (status) {
    case CAREPLAN_STATUS.planAim:
      return theme.palette.statusColors.plan;
    case CAREPLAN_STATUS.prescriptionDirective:
      return theme.palette.statusColors.plan;
    case CAREPLAN_STATUS.prescription:
      return theme.palette.statusColors.prescription;
    case CAREPLAN_STATUS.simulation:
      return theme.palette.statusColors.simulation;
    case CAREPLAN_STATUS.cpot:
      return theme.palette.statusColors.cpot;
    case CAREPLAN_STATUS.voided:
      return theme.palette.error.main;
    default:
      return theme.palette.secondary.dark;
  }
};

// TODO Careplan Name will change in newer versions  of the dashboard
export const getSiteGroupRepresentation = (siteGroup: SiteGroupSet): string => {
  const values = siteGroup.siteSet
    .map((site: any): any => {
      return getSiteRepresentation(site);
    })
    .filter(Boolean); // removes any null/undefined from the array values..

  const siteString = siteGroup.siteSet.length - values.length === 1 ? 'site' : 'sites';

  return siteGroup.siteSet.length - values.length !== 0
    ? values.join(', ') + ` + ${siteGroup.siteSet.length - values.length} ${siteString}`
    : values.join(', ');
};

// This functional will convert a space or _ separated string to title case
// e.g. this is a string => This Is A_String
export function toTitleCase(str: string): string {
  return str.replace('_', ' ').replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
