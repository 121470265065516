import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { Checkbox } from 'shared-components/components/FormFields';

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  width?: string;
  marginTop?: string;
  marginBottom?: string;
  handleMutation?: (value: boolean) => void;
  onClick?: () => void;
  form?: any;
  field?: any;
  invalid?: boolean;
  required?: boolean;
}

interface CheckboxFieldProps extends React.HTMLAttributes<HTMLElement> {
  $isChecked?: boolean;
  $width: string;
  $marginTop: string;
  $marginBottom: string;
  $invalid: boolean;
  $disabled: boolean;
}

const StyledCheckboxField = styled('div')<CheckboxFieldProps>`
  cursor: pointer;
  width: ${({ $width }: CheckboxFieldProps): string => $width} !important;
  margin-top: ${({ $marginTop }: CheckboxFieldProps): string => $marginTop};
  margin-bottom: ${({ $marginBottom }: CheckboxFieldProps): string => $marginBottom};
  border: 1px solid
    ${({ $invalid, theme }): string => ($invalid ? theme.palette.error.main : theme.palette.secondary.main)} !important;
  border-radius: 10px !important;
  padding: 0.5em 1em;
  path {
    fill: ${(props) => props.theme.palette.secondary.main} !important;
  }

  :hover {
    border-color: ${({ $disabled, theme }): string =>
      $disabled ? theme.palette.secondary.main : theme.palette.primary.main} !important;

    background-color: ${({ $disabled, theme }): string =>
      $disabled ? theme.palette.secondary.light : theme.palette.primary.light} !important;
    path {
      fill: ${({ $disabled, theme }): string =>
        $disabled ? theme.palette.secondary.main : theme.palette.primary.main} !important;
    }
  }

  ${(props) =>
    ({ $isChecked, $disabled }: CheckboxFieldProps) =>
      $isChecked &&
      !$disabled &&
      `
      border-color: ${props.theme.palette.primary.main} !important;
      path {
        fill: ${props.theme.palette.primary.main} !important;
      }
    `}
  background-color: ${(props) =>
    ({ $disabled }: CheckboxFieldProps): string =>
      $disabled ? props.theme.palette.secondary.light : 'white'};
`;

const CheckboxField = ({
  name,
  label,
  disabled,
  width,
  marginTop,
  marginBottom,
  handleMutation,
  field,
  form,
  invalid,
  required,
  onClick = () => {},
}: Props): JSX.Element => {
  const inputName = name ? name : field?.name;
  const initialValue = field?.value ? field?.value : false;
  const [isChecked, setIsChecked] = useState<boolean>(initialValue);

  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      handleMutation && handleMutation(isChecked);
    } else {
      didMount.current = true;
    }
  }, [isChecked]);

  const handleChange = (updatedValue: boolean): void => {
    setIsChecked(updatedValue);
    if (form?.setFieldValue) {
      form.setFieldValue(inputName, updatedValue);
    }
  };

  const styledProps = {
    $isChecked: isChecked,
    $width: width || '256px',
    $marginBottom: marginBottom || '0px',
    $marginTop: marginTop || '0px',
    $invalid: invalid || false,
    $disabled: disabled || false,
  };

  return (
    <StyledCheckboxField
      {...styledProps}
      data-test-id={`${inputName}-checkbox`}
      onClick={(e: React.MouseEvent<HTMLInputElement>) => {
        e.stopPropagation();
        e.preventDefault();
        !disabled && handleChange(!isChecked);
        !disabled && onClick();
      }}>
      <Checkbox
        inputName={inputName}
        inputLabel={label}
        isChecked={isChecked}
        disabled={disabled}
        required={required}
        onChange={() => {}}
      />
    </StyledCheckboxField>
  );
};

export default CheckboxField;
