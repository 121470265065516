import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'gc-ui';

import { GCButton } from 'shared-components/components/FormFields';
import { ButtonType } from 'shared-components/enums';

import './ModalResolveConflicts.scss';

interface ModalResolveConflictsIProps {
  isOpen: boolean;
  dismissFunction: () => void;
  resolveConflictsPage: () => void;
  resolveConflictsText?: string;
}

const ModalResolveConflicts = (props: ModalResolveConflictsIProps): JSX.Element => {
  const { dismissFunction, isOpen, resolveConflictsPage, resolveConflictsText = 'Resolve conflicts' } = props;
  const [locked, setLocked] = useState(false);

  return (
    <div id="resolve-conflicts-modal">
      <Modal open={isOpen} onClose={dismissFunction} className="modal-gc resolve-conflicts-modal">
        <ModalHeader id="resolve-conflicts-header" toggle={dismissFunction}>
          Are you sure you want to resolve these conflicts?
        </ModalHeader>
        <ModalBody>
          <div className="modal-conflicts-body">
            <div>
              Please ensure all value selections are correct before resolving. When resolved, you will then be taken to
              the Review & Submit section.
            </div>
          </div>
          <div className="modal-conflicts-buttons" style={{ display: 'flex', flexDirection: 'row' }}>
            <GCButton
              name="resolveConflictsRegistration"
              title={resolveConflictsText}
              disabled={locked}
              onClick={(e): void => {
                e.preventDefault();
                setLocked(true);
                resolveConflictsPage();
              }}
              type={ButtonType.GREEN}
            />
            <GCButton
              name="cancel"
              title="Cancel"
              onClick={(e): void => {
                e.preventDefault();
                dismissFunction();
              }}
              type={ButtonType.WHITE}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalResolveConflicts;
