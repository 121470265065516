// eslint-disable-next-line no-use-before-define
import React from 'react';
import { AddOutlineCircle, InfoCircleBlueIcon } from 'shared-components/images';
import { ListData } from 'shared-components/interfaces';
import { styled } from '@mui/system';
import { isUs } from 'op-utils';

export const STATUS_IN_PROGRESS = 'In progress';
export const STATUS_SUBMITTED = isUs() ? 'Submitted' : 'Submitted to MQ';
export const STATUS_ARCHIVED = 'Inactive';
export const LEAVE_PAGE_WARNING = 'Are you sure you want to leave the page without submitting?';

export const FormWrapper = styled('div')`
  height: 100%;
  width: 100%;
  padding: 2px;
`;

export const HeaderDescription = styled('div')`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
`;

export const InfoWrapper = styled('div')`
  margin-top: 3px;
  font-size: 13px;
  line-height: 15px;
  color: ${(props) => props.theme.palette.info.main};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InfoIcon = styled(InfoCircleBlueIcon)`
  height: 14px;
  margin-right: 3px;
`;

export const SectionTitle = styled('div')`
  font-size: 13px;
  line-height: 15px;
  color: ${(props) => props.theme.palette.secondary.dark};
  font-weight: bold;
  white-space: nowrap;
`;

export const SectionTitleWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 28px;
`;

export const GreyLine = styled('div')`
  width: stretch;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.main};
  margin-left: 16px;
`;

export const HeaderTitle = styled('div')`
  font-size: 24px;
  line-height: 28px;
  color: black;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const AddReferralWrapper = styled('span')`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: bold;
`;

export const AddIcon = styled(AddOutlineCircle)`
  margin-right: 12px;
`;

export const SectionWrapper = styled('div')`
  border-left: 1px solid ${(props) => props.theme.palette.secondary.main};
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 20px;
`;

export const RowText = styled('div')`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
`;

const compare = (a: any, b: any): number => {
  if (Number(a.name) < Number(b.name)) return -1;
  if (Number(a.name) > Number(b.name)) return 1;
  return 0;
};

export const sortScoreOptions = (listData: ListData[]): ListData[] => {
  return [...listData].sort(compare);
};
