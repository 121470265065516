// eslint-disable-next-line no-use-before-define
import React, { Fragment, useReducer, useMemo, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { PatientCard, ROSideNav } from 'op-components';
import { Logger } from 'shared-components/utils';

import ROPatientCarePlanContext from './ROPatientCarePlanContext';
import ROPatientDiagnosisSteps from './ROStepper/ROStepper';
import CareplanApollo from './CareplanApollo';
import { ClinexProvider } from './DiagnosisPages/Clinex/context';
import { CarePlanActions } from './Reducer';
import { DEFAULT_CAREPLAN_STATE, ROPatientCarePlanRoute, CarePlanActionType } from './Interface';
import { CREATE_CAREPLAN, UPDATE_CAREPLAN_LAST_PAGE } from './Queries';
import { CAREPLAN_PAGES } from './Constants';
import { ROPatientContextProvider } from '../PatientSummary/context';

import { intakeValidation } from './DiagnosisPages/Intake/validation';

import { UserContext, UserContextType } from 'op-contexts';
import { PatientSummaryLink } from 'op-components/SideNav/PatientSummaryLink';
import { Diagnosis } from 'op-components/PatientCard/types';
import PatientEMR from 'op-components/SideNav/PatientEMR';

const logger = new Logger('ROPatientCarePlanPage');

export const getDiagnosisCode = (diagnosis: Diagnosis): any => {
  const diagnosisCodes = [];

  if (diagnosis?.isPrimaryDiagnosis) {
    diagnosisCodes.push({
      type: 'Primary',
      code: diagnosis?.diagnosisCode?.diagnosisCode,
      name: diagnosis?.diagnosisCode?.tumourStream?.name,
      description: diagnosis?.diagnosisCode?.diagnosisDescription,
    });
  }
  if (diagnosis?.isPrimaryDiagnosis === false) {
    diagnosisCodes.push({
      type: 'Metastasis',
      code: diagnosis?.diagnosisCode?.diagnosisCode,
      description: diagnosis?.diagnosisCode?.diagnosisDescription,
    });
    if (diagnosis?.relatedPrimaryDiagnosis) {
      diagnosisCodes.push({
        type: 'Primary',
        code: diagnosis?.relatedPrimaryDiagnosis?.diagnosisCode?.diagnosisCode,
        name: diagnosis?.relatedPrimaryDiagnosis?.diagnosisCode?.tumourStream?.name,
        description: diagnosis?.relatedPrimaryDiagnosis?.diagnosisCode?.diagnosisDescription,
      });
    }
  }
  return [...diagnosisCodes].sort((a) => (a.type === 'Primary' ? -1 : 1));
};

const ROPatientCarePlanPage = (): JSX.Element => {
  const { setPanelShow } = useContext<UserContextType>(UserContext);
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<ROPatientCarePlanRoute>();
  const { id: patientId, careplanId, oncologyType } = match.params;
  const [createCarePlan] = useMutation(CREATE_CAREPLAN);
  const [updateCarePlanLastPage] = useMutation(UPDATE_CAREPLAN_LAST_PAGE);

  // This will make it easier for the child nodes to call the creation of new careplans.
  const createCareplanWrapper = (diagnosisId: string, patientId: string): void => {
    createCarePlan({
      variables: { diagnosisId: diagnosisId, patientId: patientId },
    }).then(
      (result: any): void => {
        logger.info('New Careplan created: ' + result.data.createCareplan.careplan.id);
        const careplanId = result.data.createCareplan.careplan.id;
        history.push(`/${oncologyType}/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGES.TREATMENT_INFO}`);
      },
      (error: any): void => {
        //Error creating careplan
        logger.error(error);
      },
    );
  };
  //TODO: Replace reducer with Apollo cache
  const [state, dispatch] = useReducer(CarePlanActions, {
    ...DEFAULT_CAREPLAN_STATE,
    createCarePlan: createCareplanWrapper,
    history,
  });

  useEffect((): any => {
    // Enable side panel, this will set the css to adjust
    setPanelShow(true);
  }, []);

  useEffect((): any => {
    // Update the careplan last url
    if (careplanId && careplanId !== 'create') {
      updateCarePlanLastPage({ variables: { id: match.params.careplanId, latestPageUrl: location.pathname } });
    }
  }, [location.pathname]);

  useEffect((): void => {
    const { id: patientId, careplanId } = match.params;
    if (careplanId && careplanId !== 'create') {
      dispatch({ type: CarePlanActionType.SET_CAREPLAN, payload: { ...state.careplan, id: careplanId } });
    } else if (location.pathname === `/${oncologyType}/patient/${patientId}/careplan/create`) {
      //Reset the careplanId
      dispatch({ type: CarePlanActionType.SET_CAREPLAN, payload: { ...state.careplan, id: '' } });
    }
  }, [match.params.careplanId]);

  const contextValue = useMemo((): any => {
    return { state, dispatch };
  }, [state, dispatch]);

  const initialFormikValues = {
    diagnosis: null,
    intake: null,
    intakePage: '',
    treatmentInfo: null,
    user: null,
  };
  return (
    <>
      <ROPatientCarePlanContext.Provider value={contextValue}>
        <ClinexProvider>
          <ROPatientContextProvider>
            <Formik initialValues={initialFormikValues} onSubmit={() => {}} validationSchema={intakeValidation}>
              {(): JSX.Element => {
                return (
                  <div className="RO-home-wrapper">
                    <ROSideNav>
                      <PatientEMR patientId={patientId} />
                      <PatientSummaryLink />
                      <ROPatientDiagnosisSteps />
                    </ROSideNav>
                    <div className="RO-create-pat-content">
                      <PatientCard id={patientId} />
                      <CareplanApollo />
                    </div>
                  </div>
                );
              }}
            </Formik>
          </ROPatientContextProvider>
        </ClinexProvider>
      </ROPatientCarePlanContext.Provider>
      <ToastContainer limit={3} />
    </>
  );
};

export default ROPatientCarePlanPage;
