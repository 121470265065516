import _ from 'lodash';
import {
  FlatComponentConfig,
  FormBuilderComponentConfig,
  FlatFormConfigInterface,
  ListDataInterface,
  RawValue,
  NestedFormInstanceInterface,
} from './FormBuilderInterfaces';
import { FormikValues } from 'formik';

interface FieldMap {
  [key: string]: { dataType: string };
}

const getFieldMap = (components: FlatComponentConfig[]): FieldMap => {
  if (!components) return {};
  const result = {};
  components.forEach((component: any) => {
    if (component.type === 'Container') return;
    // @ts-ignore
    result[component.field.name] = component.field;
  });
  return result;
};

const preprocessValues = (values: RawValue[], fieldMap: FieldMap): FormikValues => {
  // Converts the values from the database into the correct format for the form
  if (!values) return {};
  const result: { [key: string]: string | Date | null | number | boolean } = {};
  values.forEach((value: any) => {
    const dataType = fieldMap[value.field].dataType;
    switch (dataType) {
      case 'text':
        result[value.field] = value.value || '';
        break;
      case 'date':
        if (value.value && value.value !== 'None') {
          result[value.field] = new Date(value.value);
        } else {
          result[value.field] = null;
        }
        break;
      case 'number':
        result[value.field] = Number(value.value);
        break;
      case 'boolean':
        result[value.field] = value.value === 'True';
        break;
      default:
        result[value.field] = value.value;
    }
  });
  return result;
};

function preprocessComponents(c: FlatComponentConfig[]): FormBuilderComponentConfig[] {
  const flatComponents = _.cloneDeep(c).reverse();

  const findParent = (flatComponent: FlatComponentConfig, nestedComponents: FormBuilderComponentConfig[]): boolean => {
    return nestedComponents.some((component) => {
      let found = false;
      if (component.id === flatComponent.parentId) {
        component.subComponents?.push({ ...(flatComponent as FormBuilderComponentConfig), subComponents: [] });
        return true;
      }

      if (component.subComponents) {
        found = findParent(flatComponent, component.subComponents);
      }

      return found;
    });
  };

  const nestedComponents: FormBuilderComponentConfig[] = [];

  let count = 0;
  while (flatComponents.length > 0 && count < 20) {
    count++;

    for (let i = flatComponents.length - 1; i >= 0; i--) {
      const component = flatComponents[i];

      if (component.visible && typeof component.visible === 'string') {
        component.visible = JSON.parse(component.visible);
      }

      if (component.validators && typeof component.validators === 'string') {
        component.validators = JSON.parse(component.validators);
      }

      if (component.tooltip && typeof component.tooltip === 'string') {
        component.tooltip = JSON.parse(component.tooltip);
      }

      if (component.parentId) {
        const foundParent = findParent(component, nestedComponents);
        if (foundParent) {
          flatComponents.splice(i, 1);
        }
      } else {
        nestedComponents.push({ ...(component as FormBuilderComponentConfig), subComponents: [] });
        flatComponents.splice(i, 1);
      }
    }
  }

  return nestedComponents;
}

interface ListDataCategory {
  category: string;
  references: {
    listWeight: number;
    data: {
      option: string;
    };
  }[];
}

const preprocessOptions = (categories: ListDataCategory[]): ListDataInterface => {
  if (!categories) return {};
  const result: { [key: string]: any } = {};

  categories.forEach((category: any) => {
    result[category.category] = [...category.references]
      .sort((a: any, b: any) => a.listWeight - b.listWeight)
      .map((r: any) => ({ text: r.data.option, value: r.data.option, label: r.data.option }));
  });

  // TEMP: yesNo list data is currently messed up in prod
  // TODO^
  result['yesNo'] = [
    { text: 'Yes', value: 'Yes', label: 'Yes' },
    { text: 'No', value: 'No', label: 'No' },
  ];
  return result;
};

export function preprocessConfig(instance: FlatFormConfigInterface): NestedFormInstanceInterface {
  const config = instance.formConfig;
  const fieldMap = getFieldMap(config.components);
  const components = preprocessComponents(config.components);
  const values = preprocessValues(instance.values, fieldMap);
  const options = preprocessOptions(instance.listData);
  return { config, values, components, options };
}
