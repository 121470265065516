import {
  OutcomeNoteContainer,
  NoteTitle,
  NoteAuthor,
  NoteDate,
  NoteType,
  NoteMessage,
  NoteMessageContainer,
  NoteSubHeading,
  NoteMetaDataContainer,
  NoteTypeWrapper,
} from './StyledComponents';
import moment from 'moment';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { OutcomeInterface } from './Interfaces';

interface Props {
  outcome: OutcomeInterface;
}

const OutcomeNote = ({ outcome }: Props): JSX.Element => {
  const note = outcome.note;
  const noteTypeAlias = note?.messageType.alias;
  const noteCreatedAt = note?.createdAt
    ? moment(outcome.submittedAt).format(CurrentAppConfig.OutcomesPage.DateTimeFormat)
    : '';
  const noteCreatedby = note?.createdBy ? `Dr ${note.createdBy.firstName} ${note.createdBy.lastName}` : '';
  const status = outcome.status;

  return (
    <OutcomeNoteContainer key={`outcome-note-${outcome.id}`}>
      <NoteTitle data-cy="note-title">{`Follow up note ${status === 'Submitted' ? 'submitted' : 'preview'}`}</NoteTitle>
      {status === 'Submitted' ? (
        <>
          <NoteMetaDataContainer>
            <NoteAuthor>{noteCreatedby}</NoteAuthor>
            <NoteDate>{noteCreatedAt}</NoteDate>
          </NoteMetaDataContainer>
          {noteTypeAlias && (
            <NoteTypeWrapper>
              <NoteType>{noteTypeAlias}</NoteType>
            </NoteTypeWrapper>
          )}
        </>
      ) : (
        <></>
      )}
      {status !== 'Submitted' ? (
        <NoteSubHeading data-cy="note-sub-heading">{'Note will appear in Mosaiq on submission.'}</NoteSubHeading>
      ) : (
        <></>
      )}
      <NoteMessageContainer status={status} data-cy="note-message-container">
        {outcome.notePreview?.split('\n')?.map((line: any, i: any, lines: any) => {
          return (
            <>
              {i === lines.length - 1 && <br />}
              <NoteMessage data-cy={line} status={status} key={i}>
                {line}
              </NoteMessage>
            </>
          );
        })}
      </NoteMessageContainer>
    </OutcomeNoteContainer>
  );
};

export default OutcomeNote;
