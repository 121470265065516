// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import classNames from 'classnames';

import './GCButton.scss';

import { ButtonType, Region } from 'shared-components/enums';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;

interface Props {
  title: string;
  type: ButtonType;
  name?: string;
  disabled?: boolean;
  loading?: boolean;
  extraClass?: string;
  inputType?: 'submit' | 'button' | 'reset';
  onClick?: (event: any) => void;
}

/**
 * GC styled button component.
 */
class GCButton extends Component<Props> {
  public static defaultProps = {
    type: ButtonType.WHITE,
    buttonType: 'button',
  };

  public render(): JSX.Element {
    const { onClick, name, inputType = 'button', loading = false, type, disabled = false, extraClass } = this.props;

    const spinner = (
      <div className="spinner-border" role="status">
        <div className="sr-only" />
      </div>
    );
    const contents = loading ? spinner : this.props.title;

    return (
      <React.Fragment>
        <button
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
          }}
          disabled={disabled}
          name={name}
          className={classNames('default-button', extraClass, {
            'white-button': type === ButtonType.WHITE,
            'green-button': type === ButtonType.GREEN,
            'warning-button': type === ButtonType.WARNING,
            'UK-gc-button-style': region === Region.UK,
          })}
          type={inputType}>
          {contents}
        </button>
      </React.Fragment>
    );
  }
}

export default GCButton;
