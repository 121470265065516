// eslint-disable-next-line no-use-before-define
import React from 'react';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import './ROSideNav.scss';
import { styled } from '@mui/system';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { ArrowBack } from 'shared-components/images';

interface ROPatientDashboardNavType {
  id: string;
  oncologyType: string;
}

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px 16px 16px;
  text-decoration: none;
  height: 63px;
  border-bottom: solid 1px ${(props) => props.theme.palette.secondary.main};
  transition: border-color 0.15s ease-in-out;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const StyledArrow = styled(ArrowBack)`
  color: ${(props) => props.theme.palette.info.main};
  path {
    fill: ${(props) => props.theme.palette.info.main};
    fill-opacity: 1;
  }
  height: 20px;
  width: 20px;
`;

const StyledTitle = styled('span')`
  font-size: ${getRemSize(14)};
  color: ${(props) => props.theme.palette.info.main};
  font-weight: bold;
  padding-left: 12px;
`;

export const PatientSummaryLink = (): JSX.Element => {
  const match = useRouteMatch<ROPatientDashboardNavType>();
  const { id: patientId, oncologyType } = match.params;
  const url = generatePath(`/${oncologyType}/patient/${patientId}/summary`);

  return (
    <StyledLink to={url}>
      <StyledArrow />
      <StyledTitle>Back to patient summary</StyledTitle>
    </StyledLink>
  );
};
