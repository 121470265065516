import React from 'react';
import { Delete } from 'shared-components/images';
import { useHistory } from 'react-router-dom';
import { RowText, STATUS_IN_PROGRESS, STATUS_ARCHIVED } from '../common';
import moment from 'moment';
import { Grid, useTheme, Button, styled } from '@mui/material';
import { ArchiveOutlined as ArchiveIcon, RateReviewOutlined as RateReviewOutlinedIcon } from '@mui/icons-material';

interface Props {
  plan: any;
  index: number;
  handleDuplicate: any;
  handleDelete: any;
  handleArchive: any;
  patient: any;
}

const StyledRow = styled(Grid)`
  margin: 16px 0;
  align-items: center;
  background-color: ${(props) => props.theme.palette.secondary.light};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.secondary.main};
  min-height: 56px;

  & > div {
    padding: 16px;
  }
`;

const ReviewIcon = styled(RateReviewOutlinedIcon)`
  width: 24px;
  height: 24px;
  margin-left: 4px;
  cursor: pointer;
`;

const DeleteIcon = styled(Delete)`
  width: 24px;
  height: 24px;
  margin-left: 4px;
  cursor: pointer;
  path {
    fill: ${(props) => props.theme.palette.error.main};
    fill-opacity: 1;
  }
`;

const StyledArchiveIcon = styled(ArchiveIcon)`
  width: 26px;
  height: 26px;
  margin-left: 4px;
  cursor: pointer;
`;

const IconButton = styled(Button)`
  fill: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  text-transform: none;
  font-weight: bold;
  color: black;
`;

const ButtonWrapper = styled('div')`
  width: 20%;
`;

const CircleDiv = styled('div')`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border-radius: 10px;
`;

const StatusDiv = styled('div')`
  display: flex;
`;

const LastModified = styled('div')`
  font-size: 13px;
  line-height: 15px;
`;

const ManagementPlanRow = (props: Props): JSX.Element => {
  const history = useHistory();
  const theme = useTheme();
  const { plan, index, handleDuplicate, handleDelete, handleArchive, patient } = props;
  const isArchived = plan.status === STATUS_ARCHIVED;
  const isInProgress = plan.status === STATUS_IN_PROGRESS;
  const reviewText = isInProgress ? (plan.isReview ? 'Continue review' : 'Continue') : 'Review';
  const discardText = isInProgress ? (plan.isReview ? 'Discard review' : 'Discard') : 'Deactivate';

  const goToPlan = (formId: string) => history.push(`/patient/${patient.id}/management/${formId}/consultation`);
  const formattedDate = moment(plan.updatedAt).format('DD/MM/YYYY HH:mm A');
  const getCircleBubble = (status: string) => {
    const statusColours = {
      'In progress': `${theme.palette.warning.dark}`,
      'Submitted to MQ': `${theme.palette.primary.main}`,
      Submitted: `${theme.palette.primary.main}`,
      Inactive: `${theme.palette.secondary.dark}`,
    };
    return (
      <CircleDiv
        style={{
          //@ts-ignore
          background: statusColours[status],
        }}
      />
    );
  };

  return (
    <StyledRow container>
      <Grid sm={4}>
        <div>
          <RowText key={`plan-${index}`} data-test-id={`plan-title-${index}`}>{`${plan.planType} ${
            plan.timepoint && `(${plan.timepoint})`
          }`}</RowText>
        </div>
        <div>
          <LastModified>{`Last modified: ${formattedDate}`}</LastModified>
        </div>
      </Grid>
      <Grid sm={3}>
        <StatusDiv>
          {getCircleBubble(plan.status)}{' '}
          <div key={`status-${index}`} data-test-id={`plan-status-${index}`} className="d-inline-block">
            {plan.status}
          </div>
        </StatusDiv>
      </Grid>
      <Grid sm={2}>
        {!isArchived && (
          <ButtonWrapper>
            <IconButton
              key={`management-review-${index}`}
              data-test-id={`management-review-${index}`}
              startIcon={<ReviewIcon color="primary" />}
              onClick={() => {
                isInProgress
                  ? goToPlan(plan.id)
                  : handleDuplicate(plan.id).then(({ data }: any) => {
                      goToPlan(data?.duplicateManagementPlan.managementPlan.id);
                    });
              }}
              size="large">
              {reviewText}
            </IconButton>
          </ButtonWrapper>
        )}
      </Grid>
      <Grid sm={2}>
        {!isArchived && (
          <ButtonWrapper>
            <IconButton
              key={`management-discard-${index}`}
              startIcon={discardText === 'Deactivate' ? <StyledArchiveIcon color="primary" /> : <DeleteIcon />}
              onClick={() => {
                isInProgress ? handleDelete(plan.id) : handleArchive(plan.id);
              }}
              size="large">
              {discardText}
            </IconButton>
          </ButtonWrapper>
        )}
      </Grid>
    </StyledRow>
  );
};

export default ManagementPlanRow;
