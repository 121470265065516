import React, { Fragment } from 'react';
import { styled } from '@mui/system';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material';

interface StatusProps {
  status: string;
}
const DOSE_STATUS = {
  ongoing: 'ONGOING',
  complete: 'COMPLETE',
};
const DOSE_SOURCE = {
  aria: 'ARIA',
  mq: 'MOSAIQ',
};
const getDoseStatus = (status: string): string => {
  const theme = useTheme();
  if (status.toUpperCase() === DOSE_STATUS.ongoing) return theme.palette.warning.dark;
  if (status.toUpperCase().startsWith(DOSE_STATUS.complete)) return theme.palette.primary.main;
  return theme.palette.secondary.dark;
};
const CardTitle = styled('div')`
  font-size: 12px;
  color: ${(props) => props.theme.palette.secondary.dark};
  display: flex;
  padding-top: 8px;
  line-height: 15px;
  font-weight: 700;
`;
const CardContainer = styled('div')`
  background: ${(props) => props.theme.palette.secondary.light};
  border-radius: 4px;
  padding: 0px 16px 16px;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;
export const CardValue = styled('div')`
  line-height: 20px;
  font-weight: bold;
`;
const StatusCircle = styled('span')<StatusProps>`
  min-height: 16px;
  min-width: 16px;
  background-color: ${(props: StatusProps) => getDoseStatus(props.status)};
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
`;
const SiteTitle = styled('div')`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 8px;
`;
const CardHeaderRow = styled('div')`
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.main};
  margin: 16px 0px 0px;
  padding-bottom: 8px;
`;
const CardStatusCol = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  min-width: 50%;
  flex-basis: auto;
`;
export const CardStatusHeader = styled('div')`
  font-size: 12px;
  color: ${(props) => props.theme.palette.secondary.dark};
  display: flex;
  line-height: 15px;
  margin-right: 8px;
`;

// Something is end of treatment if it starts with complete. This will encapsulate Completed Early
const isEndOfTreatment = (status: string): boolean => status.toUpperCase().startsWith(DOSE_STATUS.complete);

const RODoseCard = (props: any): JSX.Element => {
  const { siteName, status, source, course, actualDose, totalDose, startDate, endDate, lastTreatment, technique } =
    props.attribute;
  const isAria = source.toUpperCase() === DOSE_SOURCE.aria;
  return (
    <Fragment>
      <CardContainer>
        <CardHeaderRow>
          {isAria && (
            <CardStatusCol>
              <SiteTitle>{siteName}</SiteTitle>
            </CardStatusCol>
          )}
          <CardStatusCol>
            <CardStatusHeader>Status</CardStatusHeader>
            <StatusCircle status={status} />
            <CardValue data-test-id={`${siteName}-dose-status`}>{status}</CardValue>
          </CardStatusCol>
        </CardHeaderRow>
        <Grid container>
          <Grid xs={6}>
            <CardTitle>Source</CardTitle>
            <CardValue data-test-id={`${siteName}-dose-source`}>{source.toUpperCase()}</CardValue>
          </Grid>
          <Grid xs={6}>
            <CardTitle>Course</CardTitle>
            <CardValue data-test-id={`${siteName}-dose-course`}>{course}</CardValue>
          </Grid>
          <Grid xs={6}>
            <CardTitle>Actual Dose</CardTitle>
            <CardValue data-test-id={`${siteName}-dose-actual`}>{actualDose}</CardValue>
          </Grid>
          <Grid xs={6}>
            <CardTitle>Total Dose</CardTitle>
            <CardValue data-test-id={`${siteName}-dose-total`}>{totalDose}</CardValue>
          </Grid>
          <Grid xs={6}>
            <CardTitle>Start date</CardTitle>
            <CardValue data-test-id={`${siteName}-dose-start`}>{startDate}</CardValue>
          </Grid>
          <Grid xs={6}>
            <CardTitle>End date</CardTitle>
            <CardValue data-test-id={`${siteName}-dose-end`}>{endDate}</CardValue>
          </Grid>
          <Grid xs={6}>
            <CardTitle>{isEndOfTreatment(status) ? 'Last' : 'Latest'} treatment</CardTitle>
            <CardValue data-test-id={`${siteName}-dose-last`}>{lastTreatment}</CardValue>
          </Grid>
          <Grid xs={6}>
            <CardTitle>Technique</CardTitle>
            <CardValue data-test-id={`${siteName}-dose-technique`}>{technique}</CardValue>
          </Grid>
        </Grid>
      </CardContainer>
    </Fragment>
  );
};

export default RODoseCard;
