// eslint-disable-next-line no-use-before-define
import React, { useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import SimpleSummarySelectModal from './SimpleSummarySelectModal';
import SummaryPageViewInfoModal from './SummaryPageViewInfoModal';
import { UserContext } from 'op-contexts';
import {
  ONBOARDING_QUERY,
  ONBOARDING_MUTATION,
  USER_PREFERENCE_QUERY,
  UPDATE_USER_PREFERENCE_MUTATION,
} from './queries';

const FEATURE_NAME = 'PatientSummary';
const SELECTED = 'SELECTED';
const DISMISSED = 'DISMISSED';

const SimpleSummarySelectHOC = (): JSX.Element => {
  const { setFeatures } = useContext(UserContext);
  const { data: onboardingStatus, loading: loadingOnboarding } = useQuery(ONBOARDING_QUERY, {
    variables: { featureName: FEATURE_NAME },
    fetchPolicy: 'network-only',
  });
  const {
    data: userPreferences,
    loading: loadingPreference,
    refetch: refetchPreferences,
  } = useQuery(USER_PREFERENCE_QUERY, {
    variables: { featureName: FEATURE_NAME },
    onCompleted: (data: any) => setFeatures(data),
  });

  const [updateUserPreference] = useMutation(UPDATE_USER_PREFERENCE_MUTATION);
  const [updateUserOnboarding] = useMutation(ONBOARDING_MUTATION);
  const [showSummaryInfoModal, setShowSummaryInfoModal] = React.useState(false);

  if (loadingOnboarding || loadingPreference) {
    return <div></div>;
  }
  const patientSummaryFeature = userPreferences?.featureList?.find(
    (f: any): boolean => f.feature.name === FEATURE_NAME,
  );
  const featureId = patientSummaryFeature?.feature.id;
  const showModal = userPreferences?.userFeatureList.length || featureId;
  // If onboarding is dismissed and user preference is not new patient summary and not already shown today then show the modal
  // If onboarding is new then show the modal
  // Hide the modal if the patient summary onboarding is disabled (eg. UK)
  const hasOnboarding = onboardingStatus?.userOnboardingList.length;
  const currentOnboardingStatus = hasOnboarding && onboardingStatus.userOnboardingList[0].status;
  const currentOnboardingHideExpired = hasOnboarding && onboardingStatus.userOnboardingList[0].hideExpired;

  if (!patientSummaryFeature?.onboarding) return <div></div>;
  if (hasOnboarding && (currentOnboardingStatus !== DISMISSED || !currentOnboardingHideExpired)) return <div></div>;

  // Onboarding is ok but the user preference is disabled.
  if (!showModal) return <div></div>;

  const onKeepCurrentViewClick = () => {
    updateUserOnboarding({ variables: { featureId: featureId, status: SELECTED } });
    // If current view user preference is not set then set the default view as user preference.

    if (userPreferences.userFeatureList.length === 0) {
      const defaultView = patientSummaryFeature?.default;
      updateUserPreference({ variables: { featureId: featureId, featureOptionId: defaultView?.id } }).then(() =>
        refetchPreferences().then(({ data }: any) => setFeatures(data)),
      );
    }
    setShowSummaryInfoModal(true);
  };
  const onTryNewViewClick = () => {
    updateUserOnboarding({ variables: { featureId: featureId, status: SELECTED } });
    setShowSummaryInfoModal(true);

    const simpleView = patientSummaryFeature?.options?.find((f: any): boolean => f.name === 'Simple');
    updateUserPreference({ variables: { featureId: featureId, featureOptionId: simpleView?.id } }).then(() =>
      refetchPreferences().then(({ data }: any) => setFeatures(data)),
    );
  };
  const onCancelModal = () => {
    updateUserOnboarding({ variables: { featureId: featureId, status: DISMISSED } });
  };
  const onCloseSummaryInfoModal = (e: any) => {
    const closableClasses = ['close', 'got-it-button'];

    if (closableClasses.some((el: string): boolean => e?.currentTarget?.className?.includes(el))) {
      setShowSummaryInfoModal(false);
    }
  };
  return (
    <div>
      <SimpleSummarySelectModal
        setSimpleView={onTryNewViewClick}
        keepDefaultView={onKeepCurrentViewClick}
        cancelOnboarding={onCancelModal}
      />
      <SummaryPageViewInfoModal isOpen={showSummaryInfoModal} onCloseModal={onCloseSummaryInfoModal} />
    </div>
  );
};

export default SimpleSummarySelectHOC;
