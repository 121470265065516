import classNames from 'classnames';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { ButtonType } from 'shared-components/enums';
import { Stack, CircularProgress, useTheme } from '@mui/material';
import { GCButtonSize } from 'shared-components/enums';
import { styled } from '@mui/system';
import './GCButton.scss';

// TODO Delete this enum and update its usage to point to shared_components/enums/index.ts

export enum GCButtonTextSize {
  SMALLER,
}
interface Props {
  id?: string;
  title: string;
  type?: ButtonType;
  name?: string;
  loading?: boolean;
  inputType?: 'submit' | 'button' | 'reset';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
  disabled?: boolean;
  buttonSize?: GCButtonSize;
  textSize?: GCButtonTextSize;
  additionalId?: string;
  lhsIcon?: any;
  dataTestId?: string;
}

const StyledButton = styled('button')`
  color: ${(props) => props.theme.palette.text.primary};
`;

const StyledGreenButton = styled('button')`
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};

  &:hover,
  &:focus {
    border-width: 2px;
    background-color: ${(props) => props.theme.palette.primary.dark};
    outline: none;
  }
`;

const StyledWhiteButton = styled('button')`
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  background-color: ${(props) => props.theme.palette.primary.contrastText};
  color: ${(props) => props.theme.palette.text.primary};

  &:hover,
  &:focus {
    border-width: 2px;
    background-color: ${(props) => props.theme.palette.primary.light};
    outline: none;
  }
`;

const GCButton = (props: Props) => {
  const {
    onClick,
    handleMouseEnter,
    handleMouseLeave,
    inputType = 'button',
    loading = false,
    type = ButtonType.WHITE,
    buttonSize = GCButtonSize.REGULAR,
    textSize,
    additionalId,
    lhsIcon,
    title,
    dataTestId,
    ...rest
  } = props;

  const theme = useTheme();
  const spinner = (
    <Stack sx={{ color: theme.palette.primary.contrastText }}>
      <CircularProgress size="24px" color={type === ButtonType.GREEN ? 'inherit' : 'primary'} />
    </Stack>
  );

  const contents = loading ? spinner : title;
  const ButtonComponent =
    type === ButtonType.WHITE ? StyledWhiteButton : type === ButtonType.GREEN ? StyledGreenButton : StyledButton;

  return (
    <div
      className="form-fields-default-button"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      id={additionalId}>
      <ButtonComponent
        onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
          if (onClick) {
            onClick(e);
          }
        }}
        className={classNames('default-gc-button', {
          'transparent-button': type === ButtonType.TRANSPARENT,
          'disabled-button': type === ButtonType.DISABLED || rest?.disabled,
          'gc-button-auto': buttonSize === GCButtonSize.AUTO,
          'gc-button-regular': buttonSize === GCButtonSize.REGULAR,
          'gc-button-medium': buttonSize === GCButtonSize.MEDIUM,
          'gc-button-small': buttonSize === GCButtonSize.SMALL,
          'gc-button-text-smaller': textSize === GCButtonTextSize.SMALLER,
          'green-button': type === ButtonType.GREEN, // Here for outdated unit tests
          'white-button': type === ButtonType.WHITE, // Here for outdated unit tests
        })}
        style={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: lhsIcon ? 'unset' : 'center',
          textDecoration: 'none',
        }}
        type={inputType}
        data-testid={dataTestId || rest?.id}
        {...rest}>
        {lhsIcon && <Stack sx={{ paddingLeft: '20px', paddingRight: '8px' }}>{lhsIcon}</Stack>}
        {contents}
      </ButtonComponent>
    </div>
  );
};

export default GCButton;
