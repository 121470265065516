import React from 'react';
import { styled } from '@mui/system';
import { Modal } from 'shared-components/components/UIFormComponents';
import { useTheme } from '@mui/material';

const SubmitFormText = styled('div')`
  margin-bottom: 40px;
  line-height: 24px;
  padding: 0 20px;
`;

const PdfText = styled('div')`
  margin-top: 20px;
`;

interface Props {
  submitFormMutation: any;
  title?: string;
  isOpen: boolean;
  setIsOpen: any;
  submitText?: string;
  pdfText?: string;
  formText?: string;
}

const ModalSubmitForm = ({
  submitFormMutation,
  title = 'Confirm and Submit',
  isOpen,
  setIsOpen,
  submitText = 'Submit plan',
  formText = 'By submitting you confirm that you have reviewed all information on this form and ensured all details are correct.',
  pdfText,
}: Props): JSX.Element => {
  const theme = useTheme();
  const submitFormText = (
    <SubmitFormText>
      <div>{formText}</div>
      {pdfText && (
        <PdfText>
          Once submitted, a PDF of this registration from will be sent to EMR. Patient information will be updated in
          both systems.
        </PdfText>
      )}
    </SubmitFormText>
  );

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      dismissFunction={() => setIsOpen(false)}
      leftButtonText={'Cancel'}
      rightButtonText={submitText}
      rightButtonWidth={'170px'}
      rightButtonBackground={theme.palette.primary.main}
      rightButtonBorder={theme.palette.primary.main}
      rightButtonHandleClick={(e: any) => {
        e.preventDefault();
        submitFormMutation();
        setIsOpen(false);
      }}
      leftButtonHandleClick={(e: any) => {
        e.preventDefault();
        setIsOpen(false);
      }}>
      {submitFormText}
    </Modal>
  );
};

export default ModalSubmitForm;
