// eslint-disable-next-line no-use-before-define
import { useState } from 'react';
import { LoadingSpinner, Select } from 'shared-components/components';
import FormBuilderForm from './FormBuilderForm';

const FormBuilderPage = (): JSX.Element => {
  const [form, setForm] = useState('');

  return (
    <>
      {form.length === 0 ? (
        <>
          <Select
            key={'form-selector'}
            name={'form-selector'}
            options={[{ value: 'formBuilder', label: 'Form Builder' }]}
            value={form}
            onChange={(event): void => {
              setForm(event.target.value);
            }}
            placeholder="Please Choose:"
          />
          <LoadingSpinner />
        </>
      ) : (
        <FormBuilderForm
          formName={form}
          // debug={true} // for convenience
          displayFooter={true}
          onBack={() => {
            setForm('');
          }}
        />
      )}
    </>
  );
};

export default FormBuilderPage;
