// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import './ReferralField.scss';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { ButtonType } from 'shared-components/enums';
import GCButton from './../GCButton/GCButton';
import { gql } from '@apollo/client';
import moment from 'moment';
import { BaseTextField } from 'shared-components/components/FormFields';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {
  styled,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow as MuiTableRow,
  tableCellClasses,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface RowProps {
  referralPk: string;
  status: string;
  date?: any;
  encounterDate: any;
  dateCreated: any;
  removeReferral: (e: any) => void;
  onChange: any;
}

interface Props extends WithApolloClient<{}> {
  patient: any;
  callbackHandler: () => void;
}

interface State {
  selectedReferrals: any[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(MuiTableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const UpdateReferralMutation = gql`
  mutation changeServiceRequest($serviceRequestPk: String!, $referralStatus: String, $encounterDate: DateTime) {
    changeServiceRequest(
      serviceRequestPk: $serviceRequestPk
      referralStatus: $referralStatus
      encounterDate: $encounterDate
    ) {
      serviceRequest {
        id
        referralStatus
        date
        encounterDate
      }
    }
  }
`;

const CreateReferralMutation = gql`
  mutation createServiceRequest($patientPk: String!, $referralStatus: String, $encounterDate: DateTime) {
    createServiceRequest(patientPk: $patientPk, referralStatus: $referralStatus, encounterDate: $encounterDate) {
      serviceRequest {
        id
        referralStatus
        date
        encounterDate
      }
    }
  }
`;

const DeleteReferralMutation = gql`
  mutation deleteServiceRequest($serviceRequestPk: String!) {
    deleteServiceRequest(serviceRequestPk: $serviceRequestPk) {
      ok
    }
  }
`;

export class TableRow extends Component<RowProps> {
  public render(): JSX.Element {
    const { referralPk, status, encounterDate, removeReferral, onChange, dateCreated } = this.props;
    const disabledRow = moment(dateCreated).isBefore(moment(), 'day');
    return (
      <StyledTableRow
        id="table-header"
        className={classNames('pending-appts-table', { 'disabled-pending-appts-row': disabledRow })}>
        <TableCell>
          {!disabledRow && (
            <IconButton onClick={removeReferral}>
              <CloseIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell>{status}</TableCell>
        <TableCell>
          <div>
            <BaseTextField id={referralPk} disabled value={dayjs(encounterDate).format('MMM DD, YYYY')} />
          </div>
        </TableCell>
      </StyledTableRow>
    );
  }
}

class ReferralField extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      selectedReferrals: [],
    };
  }

  public handleReferralChange(args: any[any]) {
    const { callbackHandler } = this.props;

    const serviceRequestPk = args['serviceRequestPk'];
    const referralStatus = args['referralStatus'];
    const referralType = args['referralType'];
    const encounterDate = args['encounterDate'];
    const variables = { serviceRequestPk: serviceRequestPk };
    // @ts-ignore
    referralStatus && (variables['referralStatus'] = referralStatus);
    // @ts-ignore
    referralType && (variables['referralType'] = referralType);
    // @ts-ignore
    encounterDate && (variables['encounterDate'] = encounterDate);

    // @ts-ignore
    this.props.client
      .mutate({
        mutation: UpdateReferralMutation,
        variables: variables,
      })
      .then(() => callbackHandler());
  }

  private handleReferralDeletion(serviceRequestPk: string) {
    const { callbackHandler } = this.props;
    // @ts-ignore
    this.props.client
      .mutate({
        mutation: DeleteReferralMutation,
        variables: {
          serviceRequestPk,
        },
      })
      .then(() => callbackHandler());
  }

  private createReferral() {
    const { callbackHandler, patient } = this.props;
    // @ts-ignore
    this.props.client
      .mutate({
        mutation: CreateReferralMutation,
        variables: {
          patientPk: patient.id,
          encounterDate: moment(),
        },
      })
      .then(() => callbackHandler());
  }

  private handleChange = (args: any[]) => {
    this.handleReferralChange(args);
  };

  public render(): JSX.Element {
    const { patient } = this.props;

    let disabled = true;
    if (patient.primaryCenter && patient.oncologist && !patient.hasPendingAppointments && !patient.hasDraftCareplan) {
      disabled = false;
    }

    const requests = [];
    if (patient.serviceRequests) {
      for (let i = 0; i < patient.serviceRequests.length; i++) {
        if (patient.serviceRequests[i].referralStatus !== 'Cancelled') {
          requests.push(patient.serviceRequests[i]);
        }
      }
    }

    return (
      <Fragment>
        {requests && requests.length > 0 && (
          <Table>
            <TableHead>
              <MuiTableRow id="table-header">
                <StyledTableCell />
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
              </MuiTableRow>
            </TableHead>
            <TableBody>
              {requests.map((referral: any): JSX.Element => {
                return (
                  <TableRow
                    referralPk={referral.id}
                    status={referral.referralStatus}
                    encounterDate={moment(referral.encounterDate)}
                    dateCreated={moment(referral.dateCreated)}
                    removeReferral={() => {
                      this.handleReferralDeletion(referral.id);
                    }}
                    onChange={this.handleChange}
                  />
                );
              })}
            </TableBody>
          </Table>
        )}
        <GCButton
          title={'Add to Oncologist Referral list'}
          disabled={disabled}
          type={disabled ? ButtonType.DISABLED : ButtonType.GREEN}
          onClick={() => {
            this.createReferral();
          }}
        />
        {patient.hasPendingAppointments ||
          (patient.hasDraftCareplan && (
            <p className="helper-text">
              The patient has a pending referral that is not completed or cancelled. The Oncologist will need to
              finalise the existing referral before creating a new one.
            </p>
          ))}
      </Fragment>
    );
  }
}

export default withApollo<Props>(ReferralField);
