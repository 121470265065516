// eslint-disable-next-line no-use-before-define
import React from 'react';
import { ComposedModal } from 'gc-ui';
import { CPOT_MODAL } from './constants';
import './ModalCpotStart.scss';

interface Props {
  isOpen: boolean;
  dismissFunction: () => void;
  submitFunctionOne: () => void;
  submitFunctionTwo: () => void;
}

const ModalCpotStart = (props: Props): JSX.Element => {
  const { isOpen, dismissFunction, submitFunctionOne, submitFunctionTwo } = props;

  return (
    <div id="submit-modal">
      <ComposedModal
        headerText={CPOT_MODAL.HEADER || ''}
        isOpen={isOpen}
        dismissFunction={dismissFunction}
        secondaryRightButton={{
          buttonText: 'Simulation',
          onClick: () => {
            submitFunctionOne();
            dismissFunction();
          },
        }}
        primaryRightButton={{
          buttonText: 'Prescription',
          onClick: () => {
            submitFunctionTwo();
            dismissFunction();
          },
        }}
        width={'530px'}>
        <div>{CPOT_MODAL.BODY}</div>
      </ComposedModal>
    </div>
  );
};

export default ModalCpotStart;
