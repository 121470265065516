// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import GCButton from 'shared-components/components/FormComponents/GCButton/GCButton';
import { ButtonType } from 'shared-components/enums';
import { MandatoryPatient } from 'op-interfaces/PatientInterfaces';
import { Modal, ModalBody, ModalHeader } from 'gc-ui';
import './ArchiveButton.scss';
import { gql } from '@apollo/client';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { RouteComponentProps, withRouter } from 'react-router';
import { ARCHIVE } from './constants';

interface Props extends WithApolloClient<{}>, RouteComponentProps {
  patient: MandatoryPatient;
  redirectPath: string;
}

interface State {
  isOpen: boolean;
}

const mutation = gql`
  mutation archivePatient($patientId: ID!) {
    archivePatient(pk: $patientId) {
      ok
    }
  }
`;

class ArchiveButton extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  private toggleDialog = (): void => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  private archivePatient = (): void => {
    const { patient, client, history, redirectPath } = this.props;
    // @ts-ignore
    client
      .mutate({
        mutation,
        variables: {
          patientId: patient.id,
        },
      })
      .then(() => {
        history.push(redirectPath);
      })
      .catch(() => {
        history.push(redirectPath);
      });
  };

  public render(): JSX.Element {
    const { patient } = this.props;
    const { isOpen } = this.state;

    let confirmationMessage = ARCHIVE.CONFIRMATION.NO_NAME;
    if (patient.firstName || patient.lastName) {
      confirmationMessage = `${ARCHIVE.CONFIRMATION.NAME_PRE}${patient.firstName} ${patient.lastName}${ARCHIVE.CONFIRMATION.NAME_POST}`;
    }
    const disabled = !!patient.ida;

    return (
      <Fragment>
        <GCButton
          title={'Delete patient'}
          extraClass="delete-patient-button"
          type={disabled ? ButtonType.DISABLED : ButtonType.WARNING}
          onClick={(): void => {
            this.toggleDialog();
          }}
          disabled={disabled}
        />
        <Modal
          open={isOpen}
          onClose={this.toggleDialog}
          className="modal-gc pending-submit-modal"
          maxWidth="sm"
          centered={true}>
          <ModalHeader toggle={this.toggleDialog}>{ARCHIVE.MODAL.HEADER}</ModalHeader>
          <ModalBody className="red-text">
            <span id="modal-info-body">{confirmationMessage}</span>
            <div className="modal-submit-buttons">
              <GCButton
                name="delete"
                title={'Delete patient'}
                onClick={(e): void => {
                  e.preventDefault();
                  this.archivePatient();
                }}
                type={ButtonType.WARNING}
              />
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}
// @ts-ignore
export default withRouter(withApollo(ArchiveButton));
