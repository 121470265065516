// eslint-disable-next-line no-use-before-define
import React from 'react';
import { FormikTouched, FormikErrors, FormikProps, useFormikContext, FormikValues } from 'formik';
import { Grid } from '@mui/material';

interface DebugProps {
  values: FormikValues;
  debug?: boolean;
  touched?: FormikTouched<any>;
  errors?: FormikErrors<any>;
}

export default function DebugPanel(props: DebugProps): JSX.Element {
  const formikProps: FormikProps<any> = useFormikContext();
  return (
    <>
      {props.debug && (
        <Grid item style={{ width: '100%', overflow: 'auto' }}>
          <hr />
          <p>
            <b>Prop values</b>
          </p>
          <div>{JSON.stringify(props.values)}</div>
          <hr />
          <p>
            <b>Initial Values</b>
          </p>
          <div>{JSON.stringify(formikProps.initialValues)}</div>
          <hr />
          <p>
            <b>Formik Values</b>
          </p>
          <div>{JSON.stringify(formikProps.values)}</div>
          <hr />
          <p>
            <b>Touche Formik Touche</b>
          </p>
          <div>{JSON.stringify(props.touched)}</div>
          <hr />
          <p>
            <b>Formik Errors</b>
          </p>
          <div>{JSON.stringify(props.errors)}</div>
          <hr />
        </Grid>
      )}
    </>
  );
}
