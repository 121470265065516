import { useEffect } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import { useRouteMatch } from 'react-router';
import { LoadingSpinner } from 'shared-components/components';
import { ROPatientContextProvider } from '../PatientSummary/context';
import { GET_OUTCOME } from './Queries';
import OuterContainer from '../PatientSummary/OuterContainer';
import OutcomeNote from './OutcomeNote';
import OutcomeForm from './OutcomeForm';
import { useErrorModalContext } from 'op-contexts/index';
import { OutcomeInterface } from './Interfaces';

interface ROPatientOutcomesNavType {
  id: string;
  outcomeId: string;
}

const OutcomeFormPage = (): JSX.Element => {
  const match = useRouteMatch<ROPatientOutcomesNavType>();
  const { outcomeId } = match.params;

  interface OutcomeDataInterface {
    outcome: OutcomeInterface;
  }

  const { setError } = useErrorModalContext();
  const { data: outcomeData, error: outcomeError } = useQuery<OutcomeDataInterface, any>(GET_OUTCOME, {
    variables: { outcomeId },
    fetchPolicy: 'network-only', // MM - As we do not return the values in the autosave mutation, the cache will not be updated so we will ignore it here and refetch it.
  });

  useEffect(() => {
    if (outcomeError) return setError();
  }, [outcomeError]);

  return (
    <ROPatientContextProvider>
      <OuterContainer rowSpace="0">
        {/* The outer container adds a janky padding and has the sidebar and top panel built in so we need to do some fiddling here to get the layout right. */}
        {/* TODO EVE-10694 - remove inline styles*/}
        <div style={{ width: '100%', height: '100%', backgroundColor: 'white', display: 'flex', flexDirection: 'row' }}>
          {!outcomeData ? (
            <LoadingSpinner />
          ) : (
            <>
              <OutcomeForm outcome={outcomeData.outcome} />
              <OutcomeNote outcome={outcomeData.outcome} />
            </>
          )}
        </div>
      </OuterContainer>
    </ROPatientContextProvider>
  );
};

export default OutcomeFormPage;
