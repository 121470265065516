// eslint-disable-next-line no-use-before-define
import { useQuery } from '@apollo/client';
import { Fragment, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { HeaderBar } from 'op-components';
import { UserContext } from 'op-contexts';
import { RoleType } from 'op-enums';
import { GET_RECENT_PATIENTS, GET_USER_PROFILE } from 'op-graphql/queries';

import { useErrorModalContext } from 'op-contexts';
import { USER_FEATURE_QUERY } from './queries';
import { EMRFeature, FeaturesList, UserFeature } from 'op-interfaces/User';
import { UserLanguageQueryData } from 'op-interfaces/graph';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import './Base.scss';

const { RO, MO } = RoleType;

const ROBase = ({ children }: PropsWithChildren): JSX.Element => {
  const [hasStaffId, setHasStaffId] = useState(false);
  const [navShow, setNavShow] = useState(true);
  const [panelShow, setPanelShow] = useState(true);
  const [primaryRole, setPrimaryRole] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isPractitioner, setIsPractitioner] = useState(false);
  const [isRegistrar, setIsRegistrar] = useState(false);
  const [features, setFeatures] = useState({} as FeaturesList);
  const { setError } = useErrorModalContext();
  const [timezone, setTimezone] = useState(CurrentAppConfig?.DefaultTimezone);

  // User profile and recent patients data
  const { data: userData, error: userError, loading: userLoading } = useQuery<UserLanguageQueryData>(GET_USER_PROFILE);
  const { data: patientData, error: patientError } = useQuery(GET_RECENT_PATIENTS, {
    fetchPolicy: 'network-only',
    skip: ![RO, MO].includes(primaryRole),
  });
  const recentPatients = patientData && patientData.getRecentPatients ? patientData.getRecentPatients : [];

  const { data: featuresData } = useQuery(USER_FEATURE_QUERY, {
    skip: ![RO, MO].includes(primaryRole),
  });

  const isFeatureLoading = useCallback(() => {
    return !features;
  }, [features]);

  const getEMRFeature = useCallback(
    (featureName: string): EMRFeature | undefined => {
      if (features && features.featureList) {
        return features.featureList.find((item: any) => item?.feature?.name === featureName);
      }
      return undefined;
    },
    [features],
  );

  const getUserFeature = useCallback(
    (featureName: string): UserFeature | undefined => {
      if (features && features.userFeatureList) {
        return features.userFeatureList.find((item: any) => item?.feature?.name === featureName);
      }
      return undefined;
    },
    [features],
  );

  const hasFeature = useCallback(
    (featureName: string): boolean | undefined => {
      if (!features) {
        return undefined;
      }
      return Boolean(getEMRFeature(featureName)) || Boolean(getUserFeature(featureName));
    },
    [getEMRFeature, getUserFeature],
  );

  const toggleSearchModal = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  useEffect(() => {
    if (userError || patientError) return setError();
  }, [userError, patientError, primaryRole]);

  useEffect(() => {
    if (userData && userData.user) {
      setPrimaryRole(userData.user.primaryRole);
      setHasStaffId(userData.user.hasStaffId);
      setTimezone(userData.user.timezone);
      setIsPractitioner(userData.user.isPractitioner);
      setIsRegistrar(userData.user.isRegistrar);
    }
  }, [userData, userLoading]);

  useEffect(() => {
    if (featuresData) {
      setFeatures(featuresData);
    }
  }, [featuresData]);

  if (userLoading || !userData) return <></>;

  return (
    <UserContext.Provider
      value={{
        state: {
          hasStaffId,
          navShow,
          panelShow,
          primaryRole,
          timezone,
          isPractitioner,
          isRegistrar,
          features,
        },
        setHasStaffId,
        setNavShow,
        setPanelShow,
        setPrimaryRole,
        setTimezone,
        setFeatures,
        setIsPractitioner,
        setIsRegistrar,
        isFeatureLoading,
        getEMRFeature,
        getUserFeature,
        hasFeature,
      }}>
      <div
        className={classNames('ro-container', {
          'has-side-nav-active': navShow,
          'has-side-nav-inactive': !navShow,
          'has-side-panel-active': panelShow,
          'has-side-panel-inactive': !panelShow,
        })}
        id="ROContainer">
        <Fragment>
          <HeaderBar toggleSearchModal={toggleSearchModal} recentPatients={recentPatients} styledProps={true} />
          {children}
        </Fragment>
      </div>
    </UserContext.Provider>
  );
};

export default ROBase;
