import React from 'react';
import { styled } from '@mui/system';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { HelperMessage } from 'shared-components/components';
import { sanitizeNote } from 'op-pages/RO/Notes/sanitizeNote';

const Container = styled('section')`
  min-width: 320px;
  width: 320px;
  height: calc(100vh - 60px);
  border-left: 1px solid ${(props) => props.theme.palette.secondary.main};
  display: flex;
  flex-direction: column;
`;
const Header = styled('div')`
  display: flex;
  background: ${(props) => props.theme.palette.secondary.light};
`;
const Heading = styled('span')`
  font-size: ${getRemSize(18)};
  font-weight: 500;
  padding: 20px 16px;
`;
const NotesArea = styled('div')`
  padding: 15px;
  flex-grow: 1;
  resize: none;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.main};
  outline-color: ${(props) => props.theme.palette.primary.main};
`;
const InfoTextContainer = styled('section')`
  padding: 16px 18px;
`;

interface Props {
  onBlur: (value: React.ChangeEvent<any>) => void;
  onChange: (value: React.ChangeEvent<any>) => void;
  value: string;
  name: string;
  title: string;
  helperText: string;
  disabled: boolean;
}

const SideTextArea = ({ onBlur, onChange, value, name, title, helperText, disabled }: Props): JSX.Element => {
  const htmlDecode = (content: string) => {
    const e = document.createElement('div');

    e.innerHTML = content;
    return e.childNodes.length > 4 ? e.innerText : content;
  };

  return (
    <Container>
      <Header>
        <Heading>{title}</Heading>
      </Header>
      <NotesArea
        contentEditable={!disabled}
        dangerouslySetInnerHTML={sanitizeNote(htmlDecode(value))}
        onBlur={onBlur}
        onChange={onChange}
        data-testid={name}
      />
      <InfoTextContainer>
        <HelperMessage fieldName={name} fieldText={helperText} helperType="info" fontSize={13} iconHeight={'18px'} />
      </InfoTextContainer>
    </Container>
  );
};

export default SideTextArea;
