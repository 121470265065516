// eslint-disable-next-line no-use-before-define
import React from 'react';
import { FormBuilderComponentProps } from '../../FormBuilderInterfaces';
import { fieldMeetsConditions } from '../../common';
import TextField from '@mui/material/TextField';

export default function FBInput(props: FormBuilderComponentProps): JSX.Element {
  const { component, formikProps, handleUpdate } = props; //options,

  return (
    <TextField
      key={component.field.name}
      id={component.field.name}
      name={component.field.name}
      data-testid={component.field.name}
      type={component.field.dataType === 'number' ? component.field.dataType : 'text'}
      placeholder={component.placeholder ?? ''}
      value={formikProps.values[component.field.name] ?? ''} //console errors if it's null
      onChange={(event): void => {
        handleUpdate(formikProps, component, event.target.value);
      }}
      onBlur={formikProps.handleBlur}
      disabled={fieldMeetsConditions(formikProps.values, component.disabled || component.readOnly, false)}
      fullWidth
      inputProps={{ sx: { padding: '8px 8px' } }}
    />
  );
}
